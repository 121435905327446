var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.notFound)?_c('b-container',{staticClass:"px-4"},[_c('b-row',{staticClass:"text-left mb-4",attrs:{"cols":"1"}},[_c('router-link',{staticClass:"text-secondary no__hover",attrs:{"to":{ name: 'pazienti' }}},[_c('b-icon',{attrs:{"icon":"arrow-left-circle-fill","variant":"secondary"}}),_vm._v(" Pazienti ")],1)],1),(_vm.notFound)?_c('h5',{},[_vm._v("Nessun Paziente selezionato")]):_vm._e()],1):_c('div',{staticClass:"px-4"},[_c('b-row',{staticClass:"text-left mb-2",attrs:{"cols":"1"}},[_c('router-link',{staticClass:"text-secondary no__hover",attrs:{"to":{
        name: 'scheda paziente',
        params: { hash: _vm.$route.params.hash },
      }}},[_c('b-icon',{attrs:{"icon":"arrow-left-circle-fill","variant":"secondary"}}),_vm._v(" Impostazioni alerts del paziente ")],1)],1),(_vm.showDone)?_c('b-row',{staticClass:"text-center my-4",attrs:{"cols":"1"}},[_c('h4',{},[_vm._v("Setting notifiche completato")]),_c('b-button',{staticClass:"col-10 col-md-5 mx-auto my-2",attrs:{"variant":"secondary text-white "},on:{"click":function($event){return _vm.$router.push({
          name: 'scheda paziente',
          params: { hash: _vm.$route.params.hash },
          query: { section: 'alerts' },
        })}}},[_vm._v("Torna alle impostazioni del paziente")])],1):_c('b-row',{staticClass:"text-left my-4",attrs:{"cols":"1"}},[(_vm.paziente)?_c('h4',{staticClass:"text-prim-grad-1 text-left pb-1"},[_vm._v(" Modifica piano recall di "+_vm._s(_vm.paziente.anagrafica.name)+" "+_vm._s(_vm.paziente.anagrafica.surname)+" ")]):_vm._e(),(_vm.selectedPrestazione)?_c('b-row',{staticClass:"my-2 pt-1",attrs:{"cols":"1"}},[_c('div',{staticClass:"text-left"},[_c('p',{staticClass:"text-medium-grey pb-2"},[_vm._v(" Notifiche per il follow up per ricordare al paziente di prenotare la prestazione "+_vm._s(_vm.selectedPrestazione.name)+". "),_c('br'),_vm._v(" Piano impostato il "+_vm._s(_vm._f("moment")(_vm.form.start_at,"DD/MM/YYYY"))+". ")]),_c('b-col',{staticClass:"px-0"},[_c('b-row',{attrs:{"cols":"1"}},[_c('b-col',{staticClass:"my-1 px-0 pt-1"},[_c('b-form-group',{staticClass:"col-12 col-md-4 px-0"},[_c('label',{staticClass:"mb-3 fw-600",attrs:{"for":""}},[_vm._v("Data follow up prevista*")]),_c('b-form-input',{staticClass:"",attrs:{"name":"start_at","type":"date","required":""},model:{value:(_vm.form.end_at),callback:function ($$v) {_vm.$set(_vm.form, "end_at", $$v)},expression:"form.end_at"}})],1)],1)],1),_c('b-row',{staticClass:"my-3 mt-2",attrs:{"cols":"2"}},[_c('b-col',{staticClass:"px-0"},[_c('p',{staticClass:"fw-600"},[_vm._v("Invia notifica")]),_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-checkbox-group',{attrs:{"id":"checkbox-freq","options":_vm.scheduleOptions,"aria-describedby":ariaDescribedby,"name":"checkbox-freq","stacked":"","state":_vm.form.schedule.length > 3 ? false : null},model:{value:(_vm.form.schedule),callback:function ($$v) {_vm.$set(_vm.form, "schedule", $$v)},expression:"form.schedule"}}),_c('span',{staticClass:"font-size-075rem",class:_vm.form.schedule.length > 3
                      ? 'text-danger'
                      : 'text-font-grey'},[_vm._v("**min 1 opzione, max 3 opzioni")])]}}],null,false,1467320537)})],1),_c('b-col',[_c('p',{staticClass:"fw-600"},[_vm._v("Via")]),_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-checkbox-group',{attrs:{"id":"checkbox-channel","options":_vm.channels,"aria-describedby":ariaDescribedby,"name":"checkbox-channel","stacked":""},model:{value:(_vm.form.channels),callback:function ($$v) {_vm.$set(_vm.form, "channels", $$v)},expression:"form.channels"}}),_c('span',{staticClass:"text-font-grey font-size-075rem"},[_vm._v("**min 1 opzione")])]}}],null,false,1178882674)})],1),_c('b-col',{staticClass:"col-12 pt-4 px-0"},[_c('p',{staticClass:"fw-600"},[_vm._v("Il paziente riceverà la notifica")]),_c('b-form-group',{attrs:{"description":_vm.timingDescription}},[_c('b-form-radio-group',{attrs:{"id":"radio-timing","name":"radio-timing"},model:{value:(_vm.form.timing),callback:function ($$v) {_vm.$set(_vm.form, "timing", $$v)},expression:"form.timing"}},[_c('b-form-radio',{attrs:{"value":0}},[_vm._v("al mattino")]),_c('b-form-radio',{attrs:{"value":1}},[_vm._v("alla sera")])],1)],1)],1)],1)],1)],1),_c('b-row',{staticClass:"justify-content-around col-7 col-lg-8 col-md-9 col-12",attrs:{"cols":"2"}},[_c('b-col',{staticClass:"col-md-6 col-12 pb-3 pb-md-0 my-1 text-center"},[_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.push({
                name: 'scheda paziente',
                params: { hash: _vm.$route.params.hash },
                query: { section: 'alerts' },
              })}}},[_vm._v("Annulla")])],1),_c('b-col',{staticClass:"col-6 my-1 text-center"},[_c('b-button',{staticClass:"text-white",attrs:{"variant":"secondary"},on:{"click":_vm.saveEdits}},[_vm._v("Salva modifiche ")])],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }