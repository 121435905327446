<template>
  <b-card
    style="width: 250px; min-width: 250px; position-relative"
    class="d-flex flex-column align-items-center justify-content-center mb-2 general_hover point shadow_2 py-3"
  >
    <b-icon
      style="position: absolute; right: 10px; top: 10px; z-index: 1"
      @click="toggleInfo"
      font-scale="1.5"
      :icon="showInfo ? 'info-circle-fill' : 'info-circle'"
      variant="secondary"
    ></b-icon>
    <div v-if="azienda" class="d-flex flex-column align-items-center h-100">
      <div
        class="d-flex flex-column align-items-center justify-content-center position-relative h-100"
        :class="{ 'opacity-0': showInfo, 'opacity-1': !showInfo }"
      >
        <section class="flex-grow-1">
          <div
            class="company-card-header d-flex justify-content-end align-items-start mb-2"
            :class="{ '': !azienda.profile_pic }"
            :style="companyPicStyle"
            style="width: 230px; height: 100px"
          ></div>
          <h5 class="text-center text-secondary px-2 py-1">
            {{ azienda.denominazione }}
          </h5>
        </section>
        <router-link
          :to="{
            name: 'dettaglio centro erogatore',
            params: { hashid: azienda.hashid }
          }"
          class="btn btn-secondary"
          style="z-index: 1"
        >
          gestisci
        </router-link>
      </div>

      <div
        style="width: 230px"
        class="position-absolute h-100 justify-content-center align-items-center"
        :class="{ 'opacity-0': !showInfo, 'opacity-1': showInfo }"
      >
        <b-row align-v="center" class="mt-n3 h-100 justify-content-center">
          <h6 class="text-secondary text-center">
            <span class="font-weight-bolder d-block">{{
              azienda.ff_denominazione
            }}</span>
            <template v-if="aziendaHasReferente">
              <br />
              Referente: {{ azienda.referente_fullname }}<br />
              <span class="d-block mb-2 mt-5">{{
                azienda.referente_telefono
              }}</span>
              <span class="d-block">{{ azienda.referente_email }}</span>
            </template>
          </h6>
        </b-row>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapState } from "vuex"
export default {
  name: "CentriErogatoriCard",
  props: ["azienda", "isConvenzionata", "hashid"],
  computed: {
    ...mapState("utente", ["status", "user"]),
    companyPicStyle() {
      if (this.azienda.profile_pic) {
        return {
          backgroundImage: "url(" + this.azienda.profile_pic + ")",
          backgroundPosition: "center center",
          backgroundSize: "contain"
        }
      } else {
        return {
          backgroundImage: `url(${require("@/assets/no_avatar.svg")}`,
          backgroundPosition: "center center",
          backgroundSize: "contain"
        }
      }
    },
    aziendaHasReferente() {
      return this.azienda.referente_fullname
    }
  },
  data() {
    return {
      showInfo: false
    }
  },
  methods: {
    toggleInfo() {
      this.showInfo = !this.showInfo
    }
  }
}
</script>
<style lang="scss" scoped>
.company-card-header {
  width: 250px;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  border-radius: 8px;
}

.card-body {
  padding: 0;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.d-flex.flex-column.align-items-center.justify-content-center.mb-2.general_hover.point.shadow_2.py-2 {
  border-width: 3px;
}
</style>
