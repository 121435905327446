<template>
  <section class="px-2 px-lg-3" v-if="services[8]">
    <b-row align-v="center" class="justify-content-between mb-4 mt-n3">
      <b-col
        class="col-12 col-md-7 px-0 d-flex justify-content-start align-items-center flex-wrap mt-3"
      >
        <b-form-datepicker
          label-help="Utilizza i cursori per navigare tra le date"
          start-weekday="1"
          id="dashboard-croom-datepicker"
          v-model="currentDate"
          class="mb-2 mr-2 px-1 col-8 col-sm-6 pl-2"
          :date-format-options="dateFormat"
          locale="it"
        ></b-form-datepicker>
        <span class="ml-2">
          <b-icon
            id="dashboard-explain"
            icon="info-circle"
            variant="info-circle-dashboard"
            font-scale="2"
          ></b-icon>
        </span>
        <b-tooltip
          :placement="$root.isMobile ? 'bottom' : 'bottom'"
          target="dashboard-explain"
          :triggers="$root.isMobile ? 'click' : 'hover'"
        >
          <template>
            <p class="text-justify">
              Seleziona una data per visualizzare Alert, Appuntamenti e Liste
              Pazienti del giorno
            </p>
          </template>
        </b-tooltip>
      </b-col>
    </b-row>
    <b-row align-v="stretch" cols="1" cols-lg="2" cols-xl="3">
      <b-col class="px-1 my-2 order-1 order-lg-1 order-xl-1">
        <AlertList class="h-100" :seldate="currentDate"></AlertList>
      </b-col>
      <template v-if="company.level === 5">
        <b-col class="px-1 my-2 px-xl-4 order-2 order-lg-3 order-xl-2">
          <ProssimiImpegni
            class="h-100"
            @navigate="navigateToCalendar($event)"
            :seldate="currentDate"
          />
        </b-col>
        <b-col class="px-1 my-2 order-3 order-lg-2 order-xl-3">
          <PatientList class="h-100" :seldate="currentDate"></PatientList>
        </b-col>
        <b-col class="px-1 my-2 order-4 order-lg-4 order-xl-4">
          <b-row class="mt-3 justify-content-between text-left px-2 pb-4">
            <span class="col-6 my-1">
              <b-icon
                variant="dark-grey"
                font-scale="1.7"
                icon="journal-medical"
              ></b-icon>
              <span class="ml-1">Scheda Paziente</span>
            </span>
            <span class="col-6 my-1">
              <b-icon
                variant="dark-grey"
                font-scale="1.7"
                icon="check-circle-fill"
              ></b-icon>
              <span class="ml-1">Attività da effettuare</span>
            </span>
            <span class="col-6 my-1">
              <b-icon variant="dark-grey" font-scale="1.7" icon="eye"></b-icon>
              <span class="ml-1">Attività da visualizzare</span>
            </span>
            <span class="col-6 my-1">
              <b-icon
                variant="success"
                font-scale="1.7"
                icon="check-circle-fill"
              ></b-icon>
              <span class="ml-1">Attività effettuata</span>
            </span>
            <span class="col-6 my-1">
              <b-icon
                variant="dark-grey"
                font-scale="1.7"
                icon="eye-slash"
              ></b-icon>
              <span class="ml-1">Attività visualizzata</span>
            </span>
          </b-row>
        </b-col>
      </template>
    </b-row>
  </section>
  <h5 v-else-if="services[8] === null"><b-spinner></b-spinner></h5>
  <div v-else>
    <h4>Servizio non abilitato</h4>
    <h6>
      <a href="https://concura.online/contatti/"> <u> Contattaci</u></a>
      per saperne di più o per abilitare il servizio
    </h6>
  </div>
</template>


<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";
import PatientList from "@/components/croom/PatientList.vue";
import ProssimiImpegni from "@/components/croom/ProssimiImpegni.vue";
import AlertList from "@/components/croom/AlertList.vue";

export default {
  name: "CRoomHomeDashboard",
  components: {
    PatientList,
    ProssimiImpegni,
    AlertList,
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      currentHour: undefined,
      services: {
        // 1: false,
        // 2: false,
        8: null,
      },
      currentDate: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"]),
    dateFormat() {
      if (this.innerWidth < 850)
        return {
          day: "numeric",
          month: "short",
          year: "numeric",
        };
      else if (this.innerWidth < 950)
        return {
          weekday: "short",
          day: "numeric",
          month: "short",
        };
      else
        return {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
        };
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    innerWidthChanged() {
      // console.log("resize handler");
      this.innerWidth = window.innerWidth;
    },
    setCurrentHour() {
      this.currentHour = moment().format("HH:mm");
    },
    navigateToCalendar(navigationInfo) {
      const reservationType = navigationInfo.service;
      const reservationStatus = navigationInfo.status;
      const servicerif = reservationType === "teleconsulto" ? 2 : 1;
      const search = navigationInfo.search ? navigationInfo.search : null;
      let routeName = servicerif == 1 ? "prenotazioni" : "teleconsulto";
      //to do: handle rif 5 per gli appuntamenti se il level è>1
      this.$router.push({
        name: routeName,
        params: {
          section: "prenotazioni",
        },
        query: {
          startWithCalendar: "yes",
          startWithDate: this.currentDate,
          service: reservationType,
          status: reservationStatus,
          servicerif: servicerif,
          search: search,
        },
      });
    },
    verifyService(ref) {
      const self = this;
      const filter = { ref: ref, client: 2 };
      companyService
        .checkIfServiceIsActive(filter)
        .then(function (response) {
          self.services[ref] = response.data.data.active;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  created() {
    this.verifyService(8);
    this.currentDate = moment().format("YYYY-MM-DD");
    window.addEventListener("resize", this.innerWidthChanged);
  },
  destroyed() {
    window.removeEventListener("resize", this.innerWidthChanged);
  },
  mounted() {
    // this.verifyService(1);
    this.setCurrentHour();
    this.currentHourTimer = setInterval(() => {
      this.setCurrentHour();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.currentHourTimer);
    this.currentHourTimer = null;
  },
};
</script>
<style lang="scss">
.boxes_max_heigh_60 {
  max-height: 75vh;
}

.boxes_max_heigh_49 {
  max-height: 49vh;
}

.hide_overflow_y {
  overflow-y: auto;
}

#dashboard-croom-datepicker__value_ {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-weight: 600;
  /* color: black; */
  font-size: 20px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

button#dashboard-croom-datepicker {
  padding: 0 !important;
}

div#dashboard-croom-datepicker__outer_ {
  flex-basis: initial;
  padding-left: 15px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #00000022;
  border-radius: $border-radius;
  opacity: 1;
  transition: all 200ms ease-in;
  border: 0;
}

.form-control#dashboard-croom-datepicker__outer_ {
}

@media (min-width: 1500px) {
}

@media (max-width: 500px) {
  #current-hour {
    font-size: 1.6rem;
  }

  #dashboard-croom-datepicker__value_ {
    font-size: 0.8rem;
  }

  .form-control#dashboard-croom-datepicker__outer_ {
    min-width: 110px;
  }
}

@media (min-width: 501px) {
  #dashboard-croom-datepicker__value_ {
    font-size: 15px;
  }

  .form-control#dashboard-croom-datepicker__outer_ {
    min-width: 180px;
  }
}

@media (min-width: 950px) {
  #dashboard-croom-datepicker__value_ {
    font-size: 17px;
  }

  .form-control#dashboard-croom-datepicker__outer_ {
    min-width: 330px;
  }
}

#dashboard-croom-datepicker__value_ {
  font-size: 20px;
}
</style>
