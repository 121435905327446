<template>
  <div class="pl-3 mt-2 text-left">
    <h6 class="">Questionario associato:</h6>
    <div
      class="d-flex align-items-center align-content-center my-2 py-2"
      v-if="service"
    >
      <b-form-group size="sm" class="text-left col-7 px-0">
        <b-form-select
          v-capitalize-first-select
          class=" "
          v-model="selectedSurvey"
        >
          <b-form-select-option
            v-for="(surv, ind) in surveys"
            :key="ind"
            :value="surv.hashid"
            >{{ surv.name }}</b-form-select-option
          ><b-form-select-option :value="null"
            >Nessun questionario</b-form-select-option
          >
        </b-form-select>
      </b-form-group>
      <div class="col-2 px-0 text-center">
        <b-button
          size="sm"
          v-if="showBtn"
          variant="secondary  text-white "
          @click="sendSelection"
          >Conferma</b-button
        >
      </div>
      <div class="col-3 px-0 text-center">
        <b-button
          size="sm"
          variant="secondary text-white "
          @click="
            $router.push({
              name: 'attivita',
              params: {
                section: 'questionario/nuovo'
              }
            })
          "
          >Crea questionario <b-icon icon="arrow-up-right"></b-icon
        ></b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services"
import { mapState, mapActions } from "vuex"
export default {
  name: "QuestionarioPrestazione",
  props: ["prestazione"],
  data() {
    return {
      surveys: null,
      selectedSurvey: null,
      filterSetted: {
        position_ref: 1
      },
      showBtn: false,
      service: null,
      doFetch: false
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    })
  },
  watch: {
    selectedSurvey(val) {
      if (val == null) {
        this.showBtn = this.service.questionario !== null
      } else {
        this.showBtn =
          (this.service.questionario !== null &&
            this.service.questionario.hashid !== val) ||
          this.service.questionario == null
      }
    },
    doFetch: function (val) {
      if (this.surveys == null) {
        this.fetchData()
      }
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      var self = this
      supportService
        .getSurveys(this.filterSetted)
        .then(function (response) {
          self.surveys = response.data.data.data
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le attività")
          console.log(err)
        })
      supportService
        .getFreshHealthService(this.prestazione)
        .then(function (response) {
          self.service = response.data.data
          if (response.data.data.questionario) {
            self.selectedSurvey = response.data.data.questionario.hashid
          }
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Si è verificato un errore")
          console.log(err)
        })
    },
    sendSelection() {
      var formData = {
        hash: this.prestazione,
        form: {
          survey_id: this.selectedSurvey
        }
      }
      var self = this
      supportService
        .attachSurveyToHealthService(formData)
        .then(function (response) {
          self.showBtn = false
          self.service = response.data.data
          window.scrollTo(0, 0)
          self.successAlert("Questionario associato con successo")
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile associare il questionario")
          console.log(err)
        })
    }
  }
}
</script>
