<template>
  <section id="nuova-prenotazione-paziente">
    <p class="d-flex justify-content-start mb-5">
      <b-button
        variant="primary"
        class="mr-2 shadow-sm text-secondary p-2 py-md-3 px-md-5"
        >Richiesta di prenotazione</b-button
      >
    </p>

    <section
      id="new-patient-reservation"
      class="bg-white general_border shadow_2 pt-3 pt-md-4 px-4 pb-3"
    >
      <section class="d-flex align-items-center flex-grow-1 text-secondary">
        <transition-group class="w-100" name="slideside">
          <!-- PRIMO STEP: Deve selezionare come vuole procedere -->
          <PickNuovaPrenotazioneType
            :key="'step1'"
            :resType="form.type"
            v-model="wantsToSearchForCentroErogatore"
            v-if="step === 1"
          />

          <!-- SECONDO STEP: Se ha scelto di selezionare il centro erogatore
          ricerca e seleziona il centro erogatore
           -->
          <PickCentroErogatoreForNuovaPrenotazione
            :key="'step2'"
            @selectedCompany="handleSelectedCompany($event)"
            v-if="step === 2 && wantsToSearchForCentroErogatore"
          />

          <!-- TERZO STEP: Configura la prenotazione -->
          <SetNuovaPrenotazionePaziente
            class="col-12 px-0"
            v-if="step === 3"
            :key="'step3'"
            :reservationStep="reservationStep"
            :reservationData="form"
            :selectedData="selected"
            :isProxy="isProxy"
            :newReservation="newReservation"
            :wantsToSearchForCentroErogatore="wantsToSearchForCentroErogatore"
            @selected-new-health-service="
              handleSelectedNewHealthService($event)
            "
            @preferenze-changed="handlePreferenceSettings($event)"
            @date-selected="handleDateSelected($event)"
            @selected-product="handleSelectedProductOrder($event)"
            @selected-prestazione-name="selected.prestazioneName = $event"
            @selected-company="handleSelectedCompany($event)"
          />
        </transition-group>
      </section>

      <NavButtonsNuovaPrenotazionePaziente
        v-if="!(step === 3 && reservationStep === 4)"
        @goBack="goBack"
        @goOn="goOn"
        :disableBack="disableBack"
        :disableForward="disableForward"
      />
    </section>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex"
import * as moment from "moment/moment"
import PickNuovaPrenotazioneType from "@/components/prenotazioni/paziente/nuova/PickNuovaPrenotazioneType.vue"
import NavButtonsNuovaPrenotazionePaziente from "@/components/prenotazioni/paziente/nuova/NavButtonsNuovaPrenotazionePaziente.vue"
import PickCentroErogatoreForNuovaPrenotazione from "@/components/prenotazioni/paziente/nuova/PickCentroErogatoreForNuovaPrenotazione.vue"
import SetNuovaPrenotazionePaziente from "@/components/prenotazioni/paziente/nuova/SetNuovaPrenotazionePaziente.vue"
import { reservationService } from "@/_services"

export default {
  name: "NuovaPrenotazionePaziente",
  components: {
    PickNuovaPrenotazioneType,
    NavButtonsNuovaPrenotazionePaziente,
    PickCentroErogatoreForNuovaPrenotazione,
    SetNuovaPrenotazionePaziente
  },
  data() {
    return {
      wantsToSearchForCentroErogatore: undefined,
      step: 1,
      reservationStep: 1,
      form: {
        company_id: null,
        type: null,
        data_fine: null,
        // company_notes: null,
        // order: {
        //   health_service_id: null
        // },
        service_id: null,
        subject_id: null,
        subject_type: "user_in_app",
        // //impostare a "ordine" se ha data, altrimenti "appuntamento"
        tipologia: "ordine"
        // data_inizio: null,
        // user_notes: "null"
      },
      selected: {
        company: {},
        healthService: {},
        preferences: [],
        productOrder: {},
        fileOrder: {},
        prestazioneName: null
      },
      isProxy: false,
      durata: 15,
      newReservation: {},
      isSelectedProductOrderValid: false
    }
  },
  computed: {
    disableForward() {
      if (this.step === 1) {
        return this.wantsToSearchForCentroErogatore === undefined
      }

      if (this.step === 2) {
        if (this.wantsToSearchForCentroErogatore && !this.form.company_id) {
          return true
        } else return false
      }

      if (this.step === 3) {
        if (this.reservationStep === 1) {
          if (this.$route.params.tipo === "prestazione") {
            if (this.wantsToSearchForCentroErogatore) {
              return (
                !this.selected.healthService ||
                !this.form?.order?.health_service_id
              )
            } else {
              return !this.selected.prestazioneName
            }
          } else if (this.$route.params.tipo === "prodotto") {
            return !this.isSelectedProductOrderValid
          }
        }

        if (this.reservationStep === 2) {
          if (this.$route.params.tipo === "prestazione") {
            return !this.selected.company || !this.selected.healthService
          } else {
            return (
              !this.form.company_id ||
              !this.selected.company ||
              !Object.keys(this.selected.company).length
            )
          }
        }

        if (this.reservationStep === 3) {
          if (this.isProxy) {
            return this.selected.preferences.length < 3
          } else {
            return !this.form.data_inizio
          }
        }
      }

      return false
    },
    disableBack() {
      return (
        this.step === 1 ||
        (this.form.type === "prodotto" &&
          this.step === 3 &&
          this.reservationStep === 1) ||
        (this.form.type === "teleconsulto" &&
          this.step === 3 &&
          this.reservationStep === 2)
      )
    }
  },
  watch: {
    wantsToSearchForCentroErogatore(newValue, oldValue) {
      if (oldValue !== undefined) {
        this.resetAll()
      }
    },
    step(newValue, oldValue) {
      if (oldValue === 2 && newValue === 1) {
        this.resetAll()
      }
    }
  },
  methods: {
    goOn() {
      // console.log("STEP", this.step, "RESERVATION STEP", this.reservationStep)
      //se siamo al terzo step, gestisce gli step di configurazione della reservation,
      //altrimenti gestisce il flusso che contiene tutti gli step inclusi scelta tipo ricerca ecc.
      if (this.reservationStep < 4 && this.step == 3) {
        if (
          this.reservationStep === 1 &&
          this.form.company_id &&
          this.wantsToSearchForCentroErogatore
        ) {
          this.reservationStep += 2
        } else if (this.reservationStep === 3) {
          this.submitReservation()
        } else {
          this.reservationStep += 1
        }
      } else if (!this.wantsToSearchForCentroErogatore) {
        this.step += 2
      } else {
        this.step += 1
      }
    },
    goBack() {
      if (this.step === 2) {
        this.step -= 1
      }
      //se siamo al terzo step, gestisce gli step di configurazione della reservation,
      //altrimenti gestisce il flusso che contiene tutti gli step inclusi scelta tipo ricerca ecc.
      else if (this.step === 3) {
        if (this.reservationStep === 1) {
          if (this.wantsToSearchForCentroErogatore) {
            this.step -= 1
          } else {
            this.step -= 2
          }
        } else if (
          this.reservationStep === 3 &&
          this.form.company_id &&
          this.wantsToSearchForCentroErogatore
        ) {
          this.reservationStep -= 2
        } else {
          this.reservationStep -= 1
        }
      }
    },
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    handleSelectedCompany(company) {
      if (!company || Object.keys(company).length === 0) {
        this.$set(this.form, "company_id", null)
        this.$set(this.selected, "company", {})
        return
      }
      // console.log("SELECTED COMPANY", company)
      this.$set(this.form, "company_id", company ? company.hashid : null)
      //use a swtich on company.servizi_attivi for type and assign it to form.type
      this.$set(this.selected, "company", company)
      if (company && Object.keys(company).length) {
        this.validateService()
      }
    },
    validateService() {
      if (this.form.type !== "teleconsulto") {
        // console.log("SERVIZI ATTIVI", this.selected.company.servizi_attivi)
        this.$set(
          this.form,
          "service_id",
          this.selected?.company?.servizi_attivi["prenotazioni"]
        )
        this.isProxy = this.selected.company.stato_proxy_servizi["prenotazioni"]
          ? true
          : false
        if (!this.isProxy) {
          if (
            this.selected.company.stato_servizi["prenotazioni"] == undefined
          ) {
            window.scrollTo(0, 0)
            this.errorAlert("Servizio non attivato sulle impostazioni.")
          } else if (
            this.form.type === "prodotto" &&
            (this.selected.company.stato_servizi["prenotazioni"] == 0 ||
              this.selected.company.servizi_attivi_orari["prenotazioni"]
                .length == 0)
          ) {
            window.scrollTo(0, 0)
            this.errorAlert(
              "Non ha impostato gli orari di disponibilità per il servizio."
            )
          }
        }
      } else {
        this.$set(
          this.form,
          "service_id",
          this.selected.company.servizi_attivi["teleconsulto"]
        )
        this.selectTcOptionDisabled = false
        this.isProxy = this.selected.company.stato_proxy_servizi["teleconsulto"]
          ? true
          : false

        this.getDuration()
        if (!this.isProxy) {
          if (
            this.selected.company.stato_servizi["teleconsulto"] == undefined
          ) {
            window.scrollTo(0, 0)
            this.errorAlert("Servizio non attivato sulle impostazioni.")
            this.selectTcOptionDisabled = true
          } else if (
            this.selected.company.stato_servizi["teleconsulto"] == 0 ||
            this.selected.company.servizi_attivi_orari["teleconsulto"].length ==
              0
          ) {
            window.scrollTo(0, 0)
            this.errorAlert(
              "Non ha impostato gli orari di disponibilità per il servizio."
            )
            this.selectTcOptionDisabled = true
          }
        }
      }
    },
    handleSelectedNewHealthService(healthService) {
      // console.log("SELECTED HEALTH SERVICE", healthService)
      this.$set(this.form, "order", {
        health_service_id: healthService?.hashid
      })
      this.$set(this.selected, "healthService", healthService ?? {})
      if (healthService) {
        this.isProxy = this.selected.healthService.proxy_scheduling
      }
    },
    // selectNewHealthService(healthService) {
    //   console.log("HEALTH SERVICE", healthService)
    // },
    handlePreferenceSettings(preferences) {
      this.$set(this.selected, "preferences", preferences)
      const appointments = this.selected.preferences
        .map(
          (item) => `${moment(item.day).format("DD/MM/YYYY")} ${item.timeSlot}`
        )
        .join(", ")
      this.$set(this.form, "user_notes", `Preferenze scelte: ${appointments}`)
      this.$set(this.form, "data_inizio", null)
    },
    handleDateSelected(date) {
      //converted to yyyy-mm-dd hh:mm string
      this.$set(this.form, "data_inizio", date)
    },
    getDuration() {
      const formData = {
        hash: this.form.service_id,
        filters: {
          company: this.form.company_id,
          day: this.selectedCol
        }
      }
      const self = this
      reservationService
        .getServiceOpeningForDay(formData)
        .then(function (res) {
          if (
            res.data &&
            res.data.data &&
            res.data.data[0] &&
            res.data.data[0].interval
          ) {
            self.durata = res.data.data[0].interval
          }
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Si è verificato un errore.")
          console.log(err)
        })
    },
    submitReservation() {
      if (!this.form.subject_id) {
        if (this.$route.params.hash) {
          this.$set(this.form, "subject_id", this.$route.params.hash)
        } else {
          this.errorAlert("Nessun paziente selezionato.")
          return
        }
      }
      var order = this.form
      if (this.form.type === "prestazione") {
        this.$set(order, "order", {
          health_service_id: this.selected.healthService.hashid
        })
      } else if (this.form.type === "teleconsulto") {
        this.$set(order, "order", { durata: this.durata })
      } else if (this.form.type === "prodotto") {
        this.$set(order, "order", this.selected.productOrder)
      }
      if (this.isProxy) {
        this.$set(this.form, "tipologia", "appuntamento")
        this.$set(this.form, "data_inizio", undefined)
      } else {
        var dataF = this.form.data_inizio
        this.$set(order, "data_inizio", dataF.format("YYYY-MM-DD HH:mm"))
      }

      const self = this
      reservationService
        .storeReservation(order)
        .then(function (res) {
          window.scrollTo(0, 0)
          self.successAlert("Prenotazione inviata con successo!")
          self.$set(self, "newReservation", res.data.data)
          self.reservationStep += 1
        })
        .catch(function (error) {
          window.scrollTo(0, 0)
          var msg = "Non è stato possibile inviare la richiesta"
          if (error.data.errors && error.data.errors.duplicate) {
            msg = error.data.errors.duplicate
            self.setupCalendar()
          }
          self.errorAlert(msg)
        })
    },
    resetAll() {
      this.$set(this, "step", 1)
      this.$set(this, "reservationStep", 1)
      this.$set(this.form, "company_id", null)
      // this.$set(this.form, "type", null)
      this.$set(this.form, "data_fine", null)
      this.$set(this.form, "service_id", null)
      // this.$set(this.form, "subject_id", null)
      this.$set(this.form, "subject_type", "user_in_app")
      this.$set(this.form, "tipologia", "ordine")
      this.$set(this.selected, "company", {})
      this.$set(this.selected, "healthService", {})
      this.$set(this.selected, "preferences", [])
      this.$set(this.selected, "productOrder", {})
      this.$set(this.selected, "fileOrder", {})
      this.$set(this.selected, "prestazioneName", null)
      this.$set(this, "isProxy", false)
      this.$set(this, "durata", 15)
      this.$set(this, "newReservation", {})
    },
    handleSelectedProductOrder(product) {
      this.$set(this.selected, "productOrder", product)
      this.isSelectedProductOrderValid =
        Object.keys(product).length !== 0 &&
        !Object.values(product).every((val) => val === null)
    },
    handleSelectedFileOrder(file) {
      this.$set(this.selected, "fileOrder", file)
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize)
  },
  created() {
    this.$set(this.form, "type", this.$route.params.tipo)
    if (!["prestazione", "teleconsulto", "prodotto"].includes(this.form.type)) {
      this.errorAlert("Tipo di prenotazione non valido")
      setTimeout(() => {
        this.$router.push({ name: "Home" })
      }, 1000)
    } else if (!this.$route.params.hash) {
      this.errorAlert("Nessun centro erogatore selezionato")
      setTimeout(() => {
        this.$router.push({ name: "Home" })
      }, 1000)
    } else {
      this.form.subject_id = this.$route.params.hash
    }

    if (this.form.type === "prodotto") {
      // console.log(
      //   "WANTS TO SEARCH FOR CENTRO",
      //   this.wantsToSearchForCentroErogatore
      // )
      this.wantsToSearchForCentroErogatore = false
      this.step = 3
    } else if (this.form.type === "teleconsulto") {
      this.wantsToSearchForCentroErogatore = false
      this.step = 3
      this.reservationStep = 2
    }
    // console.log("FORM TYPE", this.form.type)
    // console.log("STEP", this.step)
  }
}
</script>
<style scoped>
#new-patient-reservation {
  min-height: 13rem;
  display: flex;
  flex-direction: column;
}

#nuova-prenotazione-paziente {
  max-width: 90vw !important;
}

.slideside-enter-active,
.slideside-leave-active {
  transition: all 0.3s ease;
}

.slideside-enter,
.slideside-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
