<template>
  <div class="text-left">
    <p class="text-justify">
      Premi il tasto qui sotto per attivare lo scanner. Una volta attivo,
      inquadra i due codici a barre presente in alto a destra nella ricetta, uno
      alla volta nell'ordine da sinistra a destra (prima il codice più breve e
      poi quello più lungo). Una volta rilevato il codice completo, conferma se
      corrisponde a quello scritto sulla richiesta.
    </p>
    <b-button v-if="!startCamera" variant="outline-secondary" @click="startCamera = true">Rileva codice tramite la
      videocamera del dispositivo</b-button>
    <div v-else class="text-center">
      <div v-if="!barcode">
        <h5 class="fb-bolder text-secondary">
          {{ barcode1 ? barcode1 : "" }}{{ barcode2 ? " - " + barcode2 : "" }}
        </h5>
        <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
        <b-button variant="outline-secondary mx-2" @click="clearAll">Annulla Operazione</b-button>
      </div>
      <div v-else class="text-center">
        <p>
          <strong>Codice Rilevato: </strong>{{ barcode1 ? barcode1 : ""
          }}{{ barcode2 ? " " + barcode2 : "" }}
        </p>
        <p class="text-danger" v-if="barcode.length !== 15">
          Codice non valido
        </p>
        <b-button variant="secondary  text-white  mx-2" @click="confirmNRE">Conferma</b-button>
        <b-button variant="outline-secondary mx-2" @click="resetAll">Riprova</b-button>
        <b-button variant="outline-secondary mx-2" @click="clearAll">Annulla Operazione</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
  name: "BarcodeComponent",
  data() {
    return {
      barcode: null,
      barcode1: null,
      barcode2: null,
      startCamera: false,
    };
  },
  components: { StreamBarcodeReader },
  methods: {
    onDecode(result) {
      // console.log("result", result);
      // if (this.barcode1 && this.barcode2) {
      //   this.barcode = this.barcode1 + this.barcode2;
      // }
      if (!this.barcode1) {
        // console.log("barcode1", result);
        this.barcode1 = result;
      } else if (this.barcode1 && !this.barcode2 && result !== this.barcode1) {
        // console.log("barcode2", result);
        this.barcode2 = result;
        this.barcode = this.barcode1 + this.barcode2;
      } else if (this.barcode1 && this.barcode2) {
        this.barcode = this.barcode1 + this.barcode2;
      }
    },
    onError(result) {
      // console.log("err", result);
    },
    onLoaded(result) {
      // console.log("onload", result);
    },
    resetAll() {
      this.barcode = null;
      this.barcode1 = null;
      this.barcode2 = null;
    },
    confirmNRE() {
      this.$emit("set-nrecode", this.barcode);
      this.clearAll();
    },
    clearAll() {
      this.startCamera = false;
      this.barcode = null;
      this.barcode1 = null;
      this.barcode2 = null;
    },
  },
};
</script>