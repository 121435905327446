<template>
  <div class="pb-5">
    <SearchCompanyFilters @update-filters="updateFilters" />
    <div class="d-flex flex-wrap mt-5">
      <div class="w-100 mt-3">
        <div class="d-flex flex-wrap">
          <b-spinner
            class="mx-auto"
            v-if="loading"
            label="Loading..."
          ></b-spinner>
          <CentriErogatoriCard
            v-for="company in availableConvenzionateCompanies"
            :key="company.hashid"
            :azienda="company"
            :hashid="company.hashid"
            class="mx-2"
          />
        </div>
      </div>
      <b-button
        class="mt-5 ml-2"
        variant="outline-secondary"
        @click="goToGestionePrenotazioni"
      >
        Vai a Gestione prenotazioni
      </b-button>
    </div>
  </div>
</template>
<script>
import CentriErogatoriCard from "@/components/centriErogatori/CentriErogatoriCard.vue"
import SearchCompanyFilters from "@/components/utilities/SearchCompanyFilters.vue"
import { supportService } from "@/_services"

export default {
  name: "CentriErogatoriList",
  components: { CentriErogatoriCard, SearchCompanyFilters },
  data() {
    return {
      availableConvenzionateCompanies: [],
      filters: {},
      loading: false
    }
  },
  mounted() {
    this.getCompanies()
  },
  methods: {
    updateFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters))
      this.getCompanies()
    },
    getCompanies() {
      this.loading = true
      const filters = {
        ...this.filters,
        convenzionata: true,
        grouped: "subtype"
      }
      supportService
        .searchCompactCompanyBySubtype(filters)
        .then((res) => {
          this.availableConvenzionateCompanies = res.data.data
          this.loading = false
        })
        .catch((error) => {
          this.availableConvenzionateCompanies = []
          this.loading = false
          console.error("Error fetching companies:", error)
        })
    },
    goToGestionePrenotazioni() {
      this.$router.push({
        name: "prenotazioni",
        params: { section: "prenotazioni" }
      })
    }
  }
}
</script>
