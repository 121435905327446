var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home pb-lg-2"},[_c('b-alert',{staticClass:"col-lg-8 col-sm-12 mr-0 ml-auto point",attrs:{"variant":"danger","show":!_vm.user}},[_c('span',[_vm._v("Effettua il login ")])]),(_vm.$route.name == 'CittadinoHome')?_c('b-container',{staticClass:"px-0 m-0",attrs:{"fluid":""}},[_c('b-row',{staticClass:"mt-0"},[_c('b-col',{staticClass:"col-12 col-lg-6 mt-3 px-lg-4"},[_c('h3',{staticClass:"text-secondary text-left py-0 pb-lg-4 pt-lg-1 mb-4 mb-lg-1 font-weight-bolder"},[_vm._v(" Richeste di prenotazione ")]),_c('b-row',{attrs:{"cols":"1"}},[_c('b-col',{staticClass:"border-bottom border-primary bg-white shadow_2 general_border border_bottom_thick mb-2 point",on:{"click":function($event){return _vm.$router.push({
                name: 'appuntamenti',
                params: { section: 'nuovo', type: 'prestazione' },
              })}}},[_c('h4',{staticClass:"pb-2 pt-3 text-secondary"},[_vm._v(" Visite e prestazioni "),_c('b-icon',{attrs:{"icon":"arrow-right"}})],1)]),_c('b-col',{staticClass:"border-bottom border-blue bg-white shadow_2 general_border border_bottom_thick mb-2 point",on:{"click":function($event){return _vm.$router.push({
                name: 'appuntamentiFarmaci',
                params: { section: 'nuovo', type: 'prodotto' },
              })}}},[_c('h4',{staticClass:"pb-2 pt-3 text-secondary"},[_vm._v(" Farmaci "),_c('b-icon',{attrs:{"icon":"arrow-right"}})],1)]),_c('b-col',{staticClass:"border-bottom border-secondary bg-white shadow_2 general_border border_bottom_thick mb-2 point",on:{"click":function($event){return _vm.$router.push({
                name: 'appuntamentiTeleconsulti',
                params: { section: 'nuovo', type: 'teleconsulto' },
              })}}},[_c('h4',{staticClass:"pb-2 pt-3 text-secondary"},[_vm._v(" Teleconsulti "),_c('b-icon',{attrs:{"icon":"arrow-right"}})],1)])],1)],1),_c('b-col',{staticClass:"col-12 col-lg-6 mt-3 px-lg-4 mb-5"},[_c('h3',{staticClass:"text-secondary text-left py-0 pb-lg-4 pt-lg-1 mb-4 mb-lg-1 font-weight-bolder"},[_vm._v(" Promemoria ")]),_c('PromemoriaHomeCittadino',{attrs:{"prenotazioni":_vm.prenotazioni,"loading":_vm.loading}})],1)],1),_c('RecapSottoscrizioniPaziente',{staticClass:"mt-4 px-3"})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }