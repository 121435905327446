<template>
  <div class="col-12 px-0">
    <b-col class="my-2 puntofarmaCard pt-3 pb-2 border border-light-purple">
      <b-row class="justify-content-between">
        <h5 class="px-0 col-12 col-md-6">
          {{ therapyName }}
        </h5>
        <span class="text-medium-grey"
          >Fine scorta prevista per
          <span class="font-weight-bolder">
            {{ formatData(alertGroup[0].countdown_ends_at) }}</span
          ></span
        >
      </b-row>
      <b-row cols="2">
        <p class="text-font-grey text-lowercase mb-1">
          {{ alertGroup[0].confezioni }}
          {{ alertGroup[0].confezioni > 1 ? "confezioni" : "confezione" }} da
          {{ alertGroup[0].uic }} {{ alertGroup[0].alertable.posology_type }}
        </p>
        <p
          class="text-secondary text-right mb-1"
          v-if="alertGroup[0].alertable.nome_patologia"
        >
          Terapia per
          <span class="text-lowercase">{{
            alertGroup[0].alertable.nome_patologia
          }}</span>
        </p>
      </b-row>
      <b-row cols="2">
        <b-col
          v-for="(alertSet, time) in alertGroup"
          :key="time"
          class="my-2 point col-12 col-md-6"
          @click="showDetail(alertSet)"
        >
          <strong class="mx-2 text-capitalize">{{
            formatData(alertSet.delivery_at)
          }}</strong>
          <b-row cols="1" class="mt-1">
            <template v-if="alertSet.via_mail">
              <b-col>
                <b-iconstack font-scale="1.6">
                  <b-icon
                    stacked
                    icon="circle-fill"
                    :variant="statusColors[alertSet.via_mail_status]"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="bell-fill"
                    scale="0.7"
                    variant="white"
                  ></b-icon>
                </b-iconstack>
                <span class="mx-2 text-prim-grad-1">via email</span>
                <span class="text-secondary">
                  alle {{ alertSet.delivery_at | moment("HH:mm") }}</span
                >
              </b-col>
            </template>
            <template v-if="alertSet.via_bot">
              <b-col>
                <b-iconstack font-scale="1.6">
                  <b-icon
                    stacked
                    icon="circle-fill"
                    :variant="statusColors[alertSet.via_bot_status]"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="bell-fill"
                    scale="0.7"
                    variant="white"
                  ></b-icon>
                </b-iconstack>
                <span class="mx-2 text-prim-grad-1">via telegram/whatsapp</span>
                <span class="text-secondary">
                  alle {{ alertSet.delivery_at | moment("HH:mm") }}</span
                >
              </b-col>
            </template>
            <template v-if="alertSet.via_sms">
              <b-col>
                <b-iconstack font-scale="1.6">
                  <b-icon
                    stacked
                    icon="circle-fill"
                    :variant="statusColors[alertSet.via_sms_status]"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="bell-fill"
                    scale="0.7"
                    variant="white"
                  ></b-icon>
                </b-iconstack>
                <span class="mx-2 text-prim-grad-1">via sms</span>
                <span class="text-secondary">
                  alle {{ alertSet.delivery_at | moment("HH:mm") }}</span
                >
              </b-col>
            </template>
            <template v-if="alertSet.via_call">
              <b-col>
                <b-iconstack font-scale="1.6">
                  <b-icon
                    stacked
                    icon="circle-fill"
                    :variant="statusColors[alertSet.via_call_status]"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="bell-fill"
                    scale="0.7"
                    variant="white"
                  ></b-icon>
                </b-iconstack>
                <span class="mx-2 text-prim-grad-1">via email</span>
                <span class="text-secondary">
                  alle {{ alertSet.delivery_at | moment("HH:mm") }}</span
                >
              </b-col>
            </template>
          </b-row>
        </b-col>
      </b-row>
      <b-row cols="1" class="text-right mt-1 align-items-end" v-if="!allDone">
        <b-col>
          <b-button
            @click="editAlert(alertGroup)"
            size="sm"
            class="px-2 mx-1"
            variant="secondary"
          >
            Modifica piano</b-button
          >
          <b-button
            @click="showDeleteConfirm(alertGroup)"
            size="sm"
            class="px-2 mx-1"
            variant="outline-secondary"
          >
            Elimina piano</b-button
          >
        </b-col>
      </b-row>
      <b-row cols="1" class="text-right mt-1 align-items-end" v-else>
        <b-col>
          <b-button
            @click="copyAlerts"
            size="sm"
            class="px-2 mx-1"
            variant="outline-secondary"
          >
            Replica piano</b-button
          >
        </b-col>
      </b-row>
    </b-col>
    <b-modal size="lg" hide-footer :id="'alert-detail-' + therapyName" centered>
      <template #modal-title v-if="selectedAlert">
        <span class="">
          Notifiche per
          <span class="text-capitalize">
            {{ formatData(selectedAlert.countdown_ends_at) }}
          </span>
        </span>
      </template>

      <div v-if="selectedAlert" class="px-2">
        <h6 class="text-uppercase text-secondary">
          {{ selectedAlert.t_minus }}
          {{ selectedAlert.t_minus > 1 ? "giorni" : "giorno" }}
          a fine scorta {{ selectedAlert.alertable.product_name }}
        </h6>
        <h5 class="text-prim-grad-1"></h5>
        <p class="text-font-grey">Stato attivazione notifiche</p>
        <p v-if="selectedAlert.via_mail">
          <span
            v-if="
              (selectedAlert.via_mail_status > 0) &
              (selectedAlert.via_mail_status !== 3)
            "
          >
            <b-icon
              icon="circle-fill"
              :variant="statusColors[selectedAlert.via_mail_status]"
            >
            </b-icon>
            <b class="text-font-grey">
              {{ status[selectedAlert.via_mail_status] }}
              via email alle
              {{ selectedAlert.mail_delivered_at | moment("HH:mm") }}
            </b>
          </span>
          <b-form-checkbox
            v-else
            v-model="selectedAlert.via_mail_status"
            :name="'-' + selectedAlert.hashid"
            :value="0"
            :unchecked-value="3"
            :state="selectedAlert.via_mail_status === 0 ? true : undefined"
            switch
            @change="handleStatusChange(selectedAlert)"
          >
            <b class="text-prim-grad-1"> via email </b>
            <b class="">
              alle
              {{ selectedAlert.delivery_at | moment("HH:mm") }}
            </b>
            <span
              v-if="selectedAlert.via_mail_status === 3"
              class="bg-secondary border-orange px-3 py-1 text-white ml-2"
            >
              Sospesa</span
            >
          </b-form-checkbox>
        </p>
        <p v-if="selectedAlert.via_bot">
          <span
            v-if="
              (selectedAlert.via_bot_status > 0) &
              (selectedAlert.via_bot_status !== 3)
            "
          >
            <b-icon
              icon="circle-fill"
              :variant="statusColors[selectedAlert.via_bot_status]"
            >
            </b-icon>
            <b class="text-font-grey">
              {{ status[selectedAlert.via_bot_status] }}
              via telegram/whatsapp alle
              {{ selectedAlert.bot_delivered_at | moment("HH:mm") }}
            </b>
          </span>
          <b-form-checkbox
            v-else
            v-model="selectedAlert.via_bot_status"
            :name="'-' + selectedAlert.hashid"
            :value="0"
            :unchecked-value="3"
            :state="selectedAlert.via_bot_status === 0 ? true : undefined"
            switch
            @change="handleStatusChange(selectedAlert)"
          >
            <b class="text-prim-grad-1"> via telegram/whatsapp </b>
            <b class="">
              alle
              {{ selectedAlert.delivery_at | moment("HH:mm") }}
            </b>
            <span
              v-if="selectedAlert.via_bot_status === 3"
              class="bg-secondary border-orange font-weight-bolder px-3 py-1 text-white ml-2"
            >
              Sospesa</span
            >
          </b-form-checkbox>
        </p>
        <p v-if="selectedAlert.via_sms">
          <span
            v-if="
              (selectedAlert.via_sms_status > 0) &
              (selectedAlert.via_sms_status !== 3)
            "
          >
            <b-icon
              icon="circle-fill"
              :variant="statusColors[selectedAlert.via_sms_status]"
            >
            </b-icon>
            <b class="text-font-grey">
              {{ status[selectedAlert.via_sms_status] }}
              via sms alle
              {{ selectedAlert.sms_delivered_at | moment("HH:mm") }}
            </b>
          </span>
          <b-form-checkbox
            v-else
            v-model="selectedAlert.via_sms_status"
            :name="'-' + selectedAlert.hashid"
            :value="0"
            :unchecked-value="3"
            :state="selectedAlert.via_sms_status === 0 ? true : undefined"
            switch
            @change="handleStatusChange(selectedAlert)"
          >
            <b class="text-prim-grad-1"> via sms </b>
            <b class="">
              alle
              {{ selectedAlert.delivery_at | moment("HH:mm") }}
            </b>
            <span
              v-if="selectedAlert.via_sms_status === 3"
              class="bg-warning border-orange font-weight-bolder px-3 py-1 text-white ml-2"
            >
              Sospesa</span
            >
          </b-form-checkbox>
        </p>
        <p v-if="selectedAlert.via_call">
          <span
            v-if="
              (selectedAlert.via_call_status > 0) &
              (selectedAlert.via_call_status !== 3)
            "
          >
            <b-icon
              icon="circle-fill"
              :variant="statusColors[selectedAlert.via_call_status]"
            >
            </b-icon>
            <b class="text-font-grey">
              {{ status[selectedAlert.via_call_status] }}
              via chiamata operatore alle
              {{ selectedAlert.call_delivered_at | moment("HH:mm") }}
            </b>
          </span>
          <b-form-checkbox
            v-else
            v-model="selectedAlert.via_call_status"
            :name="'-' + selectedAlert.hashid"
            :value="0"
            :unchecked-value="3"
            :state="selectedAlert.via_call_status === 0 ? true : undefined"
            switch
            @change="handleStatusChange(selectedAlert)"
          >
            <b class="text-prim-grad-1"> via chiamata operatore </b>
            <b class="">
              alle
              {{ selectedAlert.delivery_at | moment("HH:mm") }}
            </b>
            <span
              v-if="selectedAlert.via_call_status === 3"
              class="bg-warning border-orange font-weight-bolder px-3 py-1 text-white ml-2"
            >
              Sospesa</span
            >
          </b-form-checkbox>
        </p>
        <div class="col-12 text-right pt-4 pb-2">
          <b-button variant="popup-button" class="mr-3" @click="resetChanges"
            >Annulla</b-button
          >
          <b-button
            variant="popup-button"
            :disabled="updateForm == null"
            @click="saveUpdates"
            >Salva modifiche</b-button
          >
        </div>
      </div>
    </b-modal>
    <b-modal
      :id="'remove-alert-' + therapyName"
      size="lg"
      hide-footer
      title="Conferma operazione"
      centered
    >
      <div v-if="alertsToRemove" class="pt-2">
        <h5 class="text-center" style="font-weight: 400">
          <b-icon
            class="mr-2"
            icon="question-circle-fill"
            variant="warning"
          ></b-icon>
          Vuoi eliminare tutte le notifiche per la terapia con
          <span class="text-lowercase">
            {{ alertsToRemove[0].alertable.product_name }}
          </span>
          ?
        </h5>
        <div class="my-2 text-right pt-4">
          <b-button
            variant="popup-button"
            class="mr-2"
            @click="$bvModal.hide('remove-alert-' + therapyName)"
            >No, annulla</b-button
          >
          <b-button variant="popup-button" @click="deleteAlerts"
            >Sì, elimina
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "TherapyAlert",
  props: ["alertGroup", "therapyName"],
  data() {
    return {
      status: {
        0: "programmata",
        1: "invio in corso",
        2: "inviata",
        3: "annullata",
        4: "errore",
      },
      statusColors: {
        0: "secondary",
        1: "primary-darker",
        2: "success",
        3: "warning",
        4: "danger",
      },
      channels: {
        0: "email",
        1: "sms",
        2: "telegram/whatsapp",
        3: "call",
      },
      channelIcons: {
        0: "email",
        1: "sms",
        2: "telegram/whatsapp",
        3: "telephone-fill",
      },
      selectedAlert: null,
      updateForm: null,
      alertsToRemove: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    allDone: function () {
      var self = this;
      var done = false;
      var kys = Object.keys(this.alertGroup);
      for (let x = 0; x < kys.length; x++) {
        var bot =
          self.alertGroup[x].via_bot == false ||
          self.alertGroup[x].via_bot_status >= 2;
        var sms =
          self.alertGroup[x].via_sms == false ||
          self.alertGroup[x].via_sms_status >= 2;
        var mail =
          self.alertGroup[x].via_mail == false ||
          self.alertGroup[x].via_mail_status >= 2;
        var call =
          self.alertGroup[x].via_call == false ||
          self.alertGroup[x].via_call_status >= 2;
        done = bot && sms && mail && call;
        if (!done) {
          return done;
        }
      }
      return done;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    showDetail(alert) {
      this.selectedAlert = alert;
      this.$bvModal.show("alert-detail-" + this.therapyName);
    },
    showDeleteConfirm(alerts) {
      // console.log("opening del conf");
      this.alertsToRemove = alerts;
      this.$bvModal.show("remove-alert-" + this.therapyName);
    },
    editAlert(alert) {
      // console.log(alert);
      this.selectedAlert = alert[0];
      this.$router.push({
        name: "modifica piano notifiche",
        params: {
          hash: this.$route.params.hash,
        },
        query: {
          ter: alert[0].alertable.hashid,
        },
      });
    },
    handleStatusChange(alert) {
      this.updateForm = alert;
    },
    saveUpdates() {
      var self = this;
      // console.log(this.updateForm);
      var form = {
        alert_id: this.updateForm.hashid,
        via_call_status: this.updateForm.via_call_status,
        via_bot_status: this.updateForm.via_bot_status,
        via_sms_status: this.updateForm.via_sms_status,
        via_mail_status: this.updateForm.via_mail_status,
      };
      // console.log(form);
      supportService
        .updateUserAlerts(form)
        .then(function (response) {
          self.$bvModal.hide("alert-detail-" + self.therapyName);
          window.scrollTo(0, 0);
          self.successAlert("Modifiche salvate");
          self.updateForm = null;
          // self.$emit("do-update");
        })
        .catch(function (error) {
          self.$bvModal.hide("alert-detail-" + self.therapyName);
          self.errorAlert("Non è stato possibile salvare le modifiche");
        });
    },
    copyAlerts() {
      this.selectedAlert = this.alertGroup[0];
      this.$router.push({
        name: "replica piano notifiche",
        params: {
          hash: this.$route.params.hash,
        },
        query: {
          ter: this.alertGroup[0].alertable.hashid,
        },
      });
    },
    deleteAlerts() {
      var self = this;
      var ids = [];
      // this.alertsToRemove.map(function (el, ind) {
      //   ids.push(el.hashid);
      // });
      try {
        this.alertsToRemove.map(function (el, ind) {
          ids.push(el.hashid);
        });
      } catch (err) {
        Object.keys(this.alertsToRemove).map(function (el, ind) {
          ids.push(self.alertsToRemove[el].hashid);
        });
      }
      var form = {
        alerts: ids,
      };
      supportService
        .deleteUserAlerts(form)
        .then(function (response) {
          self.$bvModal.hide("remove-alert-" + self.therapyName);
          window.scrollTo(0, 0);
          self.successAlert("Notifiche eliminate");
          self.alertsToRemove = null;
          // self.$emit("do-update");
        })
        .catch(function (error) {
          self.$bvModal.hide("remove-alert-" + self.therapyName);
          self.errorAlert("Non è stato possibile eliminare le notifiche");
        });
    },
    resetChanges() {
      this.updateForm = null;
      // self.$emit("do-update");
      this.$bvModal.hide("alert-detail-" + this.therapyName);
    },
    formatData(data) {
      return moment(data).format("dddd DD MMMM YYYY");
    },
  },
  created() {
    //
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      this.updateForm = null;
      this.$emit("do-update");
    });
  },
};
</script>
