<template>
  <div class="col-12 m-0 p-0 bg-white shadow_2 general_border">
    <b-row cols="1" cols-lg="2">
      <h4
        class="text-left text-lg-left pb-2 pt-3 pl-4"
        :class="
          activePanel === 'therapy'
            ? 'border-bottom border-secondary text-secondary'
            : 'text-font-light-grey bg-line-grey point'
        "
        @click="activePanel = 'therapy'"
      >
        Notifiche per le terapie
      </h4>
      <h4
        class="text-left pb-2 pt-3 pl-4"
        :class="
          activePanel === 'h-service'
            ? 'border-bottom border-secondary text-secondary'
            : 'text-font-light-grey bg-line-grey point'
        "
        @click="activePanel = 'h-service'"
      >
        Notifiche per le prestazioni sanitarie
      </h4>
    </b-row>
    <transition-group name="slideside" mode="out-in">
      <b-row
        cols="1"
        key="therapy"
        v-if="activePanel === 'therapy'"
        class="px-2"
      >
        <!-- <p class="text-medium-grey text-justify">
          <b-icon icon="info-circle-fill" variant=""></b-icon>
          Clicca su un giorno per visualizzarne e gestirne lo stato delle
          notifiche. Clicca su "elimina piano" per eliminare tutte le notifiche
          previste per il piano terapeutico, "modifica piano" per modificare le
          informazioni relative a scorta e data inizio terapia.
        </p> -->
        <b-col class="text-left px-2 my-2">
          <b-button @click="goToNew('type1')">
            <b-icon icon="plus"></b-icon> nuova notifica terapia
          </b-button>
        </b-col>
        <b-row
          class="mx-0 px-2"
          cols="1"
          v-for="(alertGroup, ind) in therapyAlerts"
          :key="ind"
        >
          <TherapyAlert
            :alertGroup="alertGroup"
            :therapyName="ind"
            v-on:do-update="fetchData"
          />
        </b-row>
      </b-row>
      <b-row
        cols="1"
        key="h-service"
        v-if="activePanel === 'h-service'"
        class="px-2"
      >
        <!-- <p class="text-medium-grey text-justify">
          <b-icon icon="info-circle-fill" variant=""></b-icon>
          Clicca su un giorno per visualizzarne e gestirne lo stato delle
          notifiche. Clicca su "elimina piano" per eliminare tutte le notifiche
          previste per il piano terapeutico, "modifica piano" per modificare le
          informazioni relative a data di recall e pianificazione.
        </p> -->
        <b-col class="text-left px-2 my-2">
          <b-button @click="goToNew('type2')">
            <b-icon icon="plus"></b-icon> nuova notifica prestazione
          </b-button>
        </b-col>
        <b-row
          class="mx-0 px-2"
          cols="1"
          v-for="(alertGroup, ind) in prestazioniAlerts"
          :key="ind"
        >
          <PrestazioneAlert
            :alertGroup="alertGroup"
            :alertableName="ind"
            v-on:do-update="fetchData"
          />
        </b-row>
      </b-row>
    </transition-group>
    <b-col cols="11" class="my-2 bg-whitesmoke general_border py-3 mx-auto">
      <h5 class="ml-3">Legenda stati</h5>
      <p class="d-flex justify-content-around mt-2 mb-0">
        <span v-for="(stat, ind) in statusColors" :key="stat" class="">
          <b-icon
            font-scale="1.2"
            class=""
            icon="circle-fill"
            :variant="stat"
          ></b-icon>
          <span> {{ status[ind] }}</span>
        </span>
      </p>
    </b-col>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import TherapyAlert from "@/components/profile/sanitario/TherapyAlert";
import PrestazioneAlert from "@/components/profile/sanitario/PrestazioneAlert";
export default {
  name: "SettedAlerts",
  props: ["subject"],
  components: { TherapyAlert, PrestazioneAlert },
  data() {
    return {
      therapyAlerts: null,
      prestazioniAlerts: null,
      status: {
        0: "programmata",
        1: "invio in corso",
        2: "inviata",
        3: "annullata",
        4: "errore",
      },
      statusColors: {
        0: "secondary",
        1: "primary-danger",
        2: "success",
        3: "warning",
        4: "danger",
      },
      activePanel: "therapy",
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      const self = this;
      supportService
        .getUserAlerts(this.subject.hashid)
        .then(function (response) {
          self.therapyAlerts = response.data.data.therapy;
          self.prestazioniAlerts = response.data.data.prestazioni;
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le notifiche");
        });
    },
    goToNew(type) {
      this.$router.push({
        name: "nuova notifica paziente",
        params: { hash: this.$route.params.hash },
        query: { atype: type },
      });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
