<template>
  <div class="">
    <div class="d-flex justify-content-between">
      <router-link
        class="text-secondary no__hover d-flex align-items-center"
        :to="{ name: 'scheda paziente', params: { hash: $route.params.quest } }"
      >
        <b-icon
          icon="arrow-left-circle-fill"
          variant="secondary"
          class="mr-1"
        ></b-icon>
        Scheda Paziente
      </router-link>
    </div>
    <div class="px-4">
      <div class="py-3">
        <h4 class="text-prim-grad-1 text-left pb-1">
          Storico Questionari
          <span v-if="userQuest">{{
            "di " +
            userQuest.anagrafica.name +
            " " +
            userQuest.anagrafica.surname
          }}</span>
        </h4>

        <p class="text-justify text-medium-grey">
          Clicca su una "vedi" per visualizzare l'esito del questionario.
        </p>

        <b-row>
          <b-col class="text-left px-0 d-flex align-items-center">
            <b-form-group
              size="sm"
              class="text-left col-5 col-md-4 col-lg-3 pl-0"
            >
              <b-form-select
                v-capitalize-first-select
                class="align-items-center align-content-center border border-info"
                name="vedi"
                v-model="filterSetted.take"
                :options="takeOptions"
              >
              </b-form-select>
            </b-form-group>
            <span class="mr-1" v-if="total > 0"
              >{{ fromPage }}-{{ toPage }} di
            </span>
            <span class=""> {{ total }}</span>
          </b-col>
          <b-col class="d-flex px-0 align-items-center justify-content-end">
            <span class="mx-2">
              <label for="month_selector" class="mr-1 mb-0">Mese</label>
              <select
                v-capitalize-first-select
                class="form-select bg-white border border-info general_border py-1"
                v-model="filterSetted.month"
                name="month_selector"
                id="month_selector"
              >
                <option
                  v-for="month in historyFilterOptions.month"
                  :value="month.value"
                  :key="month.value"
                >
                  {{ month.label }}
                </option>
                <option :value="null">Tutti</option>
              </select>
            </span>
            <label for="pickedYear" class="mr-1 mb-0">Anno</label>
            <select
              v-capitalize-first-select
              class="form-select bg-white border border-info general_border"
              v-model="filterSetted.year"
              name="pickedYear"
              id="pickedYear"
            >
              <option
                v-for="year in yearsOptions"
                :value="year.value"
                :key="year.value"
              >
                {{ year.text }}
              </option>
            </select>
          </b-col>
        </b-row>
        <div class="mt-2">
          <b-table
            borderless
            hover
            responsive
            table-variant="white"
            :fields="fields"
            :items="surveys"
            :small="$root.isMobile"
          >
            <template #cell(created_at)="row">
              <span class="text-left">{{
                row.item.created_at | moment("DD/MM/YYYY")
              }}</span>
            </template>
            <template #cell(azioni)="row">
              <b-row align-v="center" class="d-flex justify-content-center">
                <b-iconstack
                  font-scale="3"
                  class="point m-1"
                  v-b-popover.hover.top="'Vedi'"
                  @click="showThis(row)"
                >
                  <b-icon
                    stacked
                    icon="circle-fill"
                    variant="mdm-light-grey"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="zoom-in"
                    scale="0.5"
                    variant="secondary"
                  ></b-icon>
                </b-iconstack>
                <template
                  v-if="
                    user.permessi.includes('gestire attività e questionari')
                  "
                >
                  <b-iconstack
                    font-scale="3"
                    class="point m-1"
                    v-if="!row.item.created_by"
                    v-b-popover.hover.top="'Modifica'"
                    @click="
                      $router.push({
                        name: 'attivita',
                        params: {
                          quest: row.item.hashid,
                          section: 'questionario',
                          action: 'edit'
                        },
                        query: { fromStorico: true }
                      })
                    "
                  >
                    <b-icon
                      stacked
                      icon="circle-fill"
                      variant="mdm-light-grey"
                    ></b-icon>
                    <b-icon
                      stacked
                      icon="pencil-square"
                      scale="0.5"
                      variant="secondary"
                    ></b-icon>
                  </b-iconstack>

                  <b-iconstack
                    font-scale="3"
                    class="point m-1"
                    v-b-popover.hover.top="'Elimina'"
                    @click="showDeleteThis(row)"
                  >
                    <b-icon
                      stacked
                      icon="circle-fill"
                      variant="mdm-light-grey"
                    ></b-icon>
                    <b-icon
                      stacked
                      icon="trash"
                      scale="0.5"
                      variant="secondary"
                    ></b-icon>
                  </b-iconstack>
                </template>

                <b-dropdown
                  size="sm"
                  variant="mdm-light-grey text-secondary"
                  right
                  class="secondary-dropdown m-1 py-2 cippalippa"
                  v-b-popover.hover.top="'Scarica'"
                >
                  <template #button-content>
                    <b-icon
                      variant="secondary"
                      icon="download"
                      font-scale="1.9"
                    ></b-icon>
                  </template>
                  <b-dropdown-item
                    class="secondary-li-hover"
                    @click="downloadOne(row)"
                    >PDF Standard</b-dropdown-item
                  >
                  <b-dropdown-item
                    class="secondary-li-hover"
                    @click="downloadPatientVersion(row)"
                    >PDF per il paziente</b-dropdown-item
                  >
                </b-dropdown>
              </b-row>
            </template>
          </b-table>
          <p v-if="surveys && surveys.length < 1">Nessun questionario qui</p>
          <b-pagination
            v-model="filterSetted.page"
            :total-rows="total"
            :per-page="per_page"
            align="center"
            :size="$root.isMobile ? 'sm' : 'lg'"
          ></b-pagination>
          <b-modal
            ref="show-filleditem"
            centered
            hide-footer
            title="Questionario"
            size="xl"
          >
            <FilledSurveyShower
              v-if="selectedItem"
              :response="selectedItem.response"
              :score="selectedItem.score"
            />
          </b-modal>

          <b-modal
            id="confirm-delete"
            centered
            title="Confermi di voler eliminare il questionario?"
            ok-title="Sì, elimina"
            ok-variant="popup-button"
            cancel-title="No, annulla"
            cancel-variant="popup-button"
            footer-border-variant="white"
            @ok="deleteSelectedItem"
            @cancel="cancelDelete"
          >
            <h6 v-if="selectedItem" class="px-2 py-3">
              Se confermi, il questionario selezionato compilato in data
              {{ selectedItem.created_at | moment("DD/MM/YYYY") }} verrà
              eliminato definitivamente.
            </h6>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { supportService, cittadinoService } from "@/_services"
import { MONTHS } from "@/_utils/constants.js"
import { mapState, mapActions } from "vuex"
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue"
import * as moment from "moment/moment"

export default {
  name: "ReportQuestionariUtente",
  components: { FilledSurveyShower },
  data() {
    return {
      filterSetted: {
        take: 10,
        page: 1,
        year: moment().year(),
        month: moment().month() + 1
      },
      mappedMonths: {
        "01": "gennaio",
        "02": "febbraio",
        "03": "marzo",
        "04": "aprile",
        "05": "maggio",
        "06": "giugno",
        "07": "luglio",
        "08": "agosto",
        "09": "settembre",
        10: "ottobre",
        11: "novembre",
        12: "dicembre"
      },
      historyFilterOptions: {
        year: null,
        month: MONTHS
      },
      currentYear: moment().year(),
      yearsOptions: [],
      surveys: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      per_page: 0,
      fields: [
        {
          key: "survey_name",
          label: "Questionario",
          tdClass: "align-middle"
        },
        { key: "created_at", label: "Data", tdClass: "align-middle" },
        // { key: "user_mail", label: "Utente" },
        // { key: "active", label: "Stato" },
        { key: "azioni", label: "", tdClass: "align-middle text-center" }
      ],
      takeOptions: [10, 25, 50, 100],
      selectedItem: null,
      userQuest: null
    }
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert
    })
  },
  watch: {
    filterSetted: {
      handler(prev, post) {
        if (prev.cf && prev.cf.length < 16) {
          return
        }
        this.fetchData()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      const self = this
      supportService
        .getUserFilledSurveys(this.$route.params.quest, this.filterSetted)
        .then(function (res) {
          self.surveys = res.data.data.data
          self.filterSetted.page = res.data.data.current_page
          self.total = res.data.data.total
          self.per_page = res.data.data.per_page
          self.fromPage = res.data.data.from
          self.toPage = res.data.data.to
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    fetchUser() {
      const self = this
      cittadinoService
        .getUserById(this.$route.params.quest)
        .then(function (res) {
          self.userQuest = res.data.data
        })
        .catch(function (err) {
          console.log(err)
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le informazioni.")
        })
    },
    showThis(row) {
      this.selectedItem = row.item
      this.$refs["show-filleditem"].show()
    },
    showDeleteThis(row) {
      this.selectedItem = row.item
      this.$bvModal.show("confirm-delete")
    },
    cancelDelete() {
      this.selectedItem = null
      this.$bvModal.hide("confirm-delete")
    },
    downloadOne(row) {
      const self = this
      var selected = row
      supportService
        .downloadFilledSurvey(selected.item.hashid)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = selected.item.survey_name + ".pdf"
          link.click()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    downloadPatientVersion(row) {
      const self = this
      var selected = row
      supportService
        .downloadFilledSurveyPatientVersion(selected.item.hashid)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download =
            "patient_" +
            self.userQuest.anagrafica.cf.toUpperCase() +
            "_" +
            selected.item.survey_name +
            ".pdf"
          link.click()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    deleteSelectedItem() {
      const self = this
      supportService
        .deleteFilledSurvey(this.selectedItem.hashid)
        .then(function (res) {
          self.successAlert("Questionario eliminato correttamente")
          self.fetchData()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile eliminare il questionario")
        })
    }
  },
  created() {
    const userHash = this.$route.params.quest
    this.startYear = 2022
    for (let i = this.currentYear; i >= this.startYear; i--) {
      let y = { value: `${i}`, text: `${i}` }
      this.yearsOptions.push(y)
    }
    this.fetchUser(userHash)
    this.fetchData(userHash)
  }
}
</script>
<style></style>
