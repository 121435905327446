var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.notFound)?_c('b-container',[_c('b-row',{staticClass:"text-left mb-4",attrs:{"cols":"1"}},[_c('router-link',{staticClass:"text-secondary no__hover",attrs:{"to":{ name: 'pazienti' }}},[_c('b-icon',{attrs:{"icon":"arrow-left-circle-fill","variant":"secondary"}}),_vm._v(" Pazienti")],1)],1),(_vm.notFound)?_c('h5',{},[_vm._v("Nessun Paziente selezionato")]):_vm._e()],1):_c('div',[_c('b-row',{staticClass:"text-left mb-2",attrs:{"cols":"1"}},[_c('router-link',{staticClass:"text-secondary no__hover",attrs:{"to":{
        name: 'scheda paziente',
        params: { hash: _vm.$route.params.hash },
        query: { section: 'alerts' },
      }}},[_c('b-icon',{attrs:{"icon":"arrow-left-circle-fill","variant":"secondary"}}),_vm._v(" Alerts per il paziente")],1)],1),(_vm.showDone)?_c('b-row',{staticClass:"text-center my-4",attrs:{"cols":"1"}},[_c('h4',{},[_vm._v(" Setting notifiche completato "),_c('b-icon',{attrs:{"icon":"check-circle-fill","variant":"secondary"}})],1),_c('b-button',{staticClass:"col-10 col-md-5 mx-auto my-2",attrs:{"variant":"secondary text-white "},on:{"click":function($event){return _vm.$router.push({
          name: 'scheda paziente',
          params: { hash: _vm.$route.params.hash },
          query: { section: 'alerts' },
        })}}},[_vm._v("Torna alle impostazioni del paziente")])],1):_c('b-row',{staticClass:"text-left mb-4",attrs:{"cols":"1"}},[_c('NewAlert',{attrs:{"subject":_vm.subject},on:{"save-alerts":function($event){return _vm.showRecap($event)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }