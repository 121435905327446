<template>
  <div class="home pb-lg-2">
    <b-alert
      variant="danger"
      :show="!user"
      class="col-lg-8 col-sm-12 mr-0 ml-auto point"
    >
      <span>Effettua il login </span>
    </b-alert>
    <b-container fluid class="px-0 m-0" v-if="$route.name == 'CittadinoHome'">
      <b-row class="mt-0">
        <b-col class="col-12 col-lg-6 mt-3 px-lg-4">
          <h3
            class="text-secondary text-left py-0 pb-lg-4 pt-lg-1 mb-4 mb-lg-1 font-weight-bolder"
          >
            Richeste di prenotazione
          </h3>
          <b-row cols="1">
            <b-col
              class="border-bottom border-primary bg-white shadow_2 general_border border_bottom_thick mb-2 point"
              @click="
                $router.push({
                  name: 'appuntamenti',
                  params: { section: 'nuovo', type: 'prestazione' },
                })
              "
            >
              <h4 class="pb-2 pt-3 text-secondary">
                Visite e prestazioni
                <b-icon icon="arrow-right"></b-icon>
              </h4>
            </b-col>
            <b-col
              class="border-bottom border-blue bg-white shadow_2 general_border border_bottom_thick mb-2 point"
              @click="
                $router.push({
                  name: 'appuntamentiFarmaci',
                  params: { section: 'nuovo', type: 'prodotto' },
                })
              "
            >
              <h4 class="pb-2 pt-3 text-secondary">
                Farmaci
                <b-icon icon="arrow-right"></b-icon>
              </h4>
            </b-col>
            <b-col
              class="border-bottom border-secondary bg-white shadow_2 general_border border_bottom_thick mb-2 point"
              @click="
                $router.push({
                  name: 'appuntamentiTeleconsulti',
                  params: { section: 'nuovo', type: 'teleconsulto' },
                })
              "
            >
              <h4 class="pb-2 pt-3 text-secondary">
                Teleconsulti
                <b-icon icon="arrow-right"></b-icon>
              </h4>
            </b-col>

            <!-- PRENOTAZIONI TEMPORANEAMENTE DISATTIVATE -->
            <!-- <b-col
              class="border-bottom border-primary bg-white shadow_2 general_border border_bottom_thick mb-2 point"
              @click="
                $router.push({
                  name: 'appuntamenti',
                  params: { section: 'nuovo', type: 'prestazione' },
                })
              "
            >
              <h4 class="pb-2 pt-3 text-secondary">
                Visite e prestazioni
                <b-icon icon="arrow-right"></b-icon>
              </h4>
            </b-col>
            <b-col
              class="border-bottom border-blue bg-white shadow_2 general_border border_bottom_thick mb-2 point"
              @click="
                $router.push({
                  name: 'appuntamenti',
                  params: { section: 'nuovo', type: 'prodotto' },
                })
              "
            >
              <h4 class="pb-2 pt-3 text-secondary">
                Farmaci
                <b-icon icon="arrow-right"></b-icon>
              </h4>
            </b-col>
            <b-col
              class="border-bottom border-secondary bg-white shadow_2 general_border border_bottom_thick mb-2 point"
              @click="
                $router.push({
                  name: 'appuntamenti',
                  params: { section: 'nuovo', type: 'teleconsulto' },
                })
              "
            >
              <h4 class="pb-2 pt-3 text-secondary">
                Teleconsulti
                <b-icon icon="arrow-right"></b-icon>
              </h4>
            </b-col> -->
            <!-- PRENOTAZIONI TEMPORANEAMENTE DISATTIVATE -->
          </b-row>
        </b-col>
        <b-col class="col-12 col-lg-6 mt-3 px-lg-4 mb-5">
          <h3
            class="text-secondary text-left py-0 pb-lg-4 pt-lg-1 mb-4 mb-lg-1 font-weight-bolder"
          >
            Promemoria
          </h3>
          <PromemoriaHomeCittadino
            :prenotazioni="prenotazioni"
            :loading="loading"
          />
        </b-col>
      </b-row>
      <RecapSottoscrizioniPaziente class="mt-4 px-3" />
    </b-container>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { reservationService } from "@/_services";
import PromemoriaHomeCittadino from "@/components/cittadino/PromemoriaHomeCittadino.vue";
import RecapSottoscrizioniPaziente from "@/components/cittadino/RecapSottoscrizioniPaziente.vue";

export default {
  name: "CittadinoHome",
  components: {
    PromemoriaHomeCittadino,
    RecapSottoscrizioniPaziente,
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      greeting: "Ciao",
      prenotazioni: [],
      loading: true,
      // menuOpen: false,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    welcomeText() {
      if (this.innerWidth < 900) return `${this.greeting}!`;
      else if (this.user.anagrafica) {
        return `${this.greeting}, ${this.user.anagrafica.name} ${this.user.anagrafica.surname}`;
      } else return `${this.greeting}!`;
    },
    // prenotazioniCount() {
    //   return this.prenotazioni.length;
    // },
  },
  created() {
    window.addEventListener("resize", this.innerWidthChanged);
    this.setGreeting();
  },
  mounted() {
    this.fetchData();
  },
  destroyed() {
    window.removeEventListener("resize", this.innerWidthChanged);
  },
  methods: {
    ...mapActions({
      errorAlert: "alert/error",
    }),
    innerWidthChanged() {
      this.innerWidth = window.innerWidth;
    },
    setGreeting() {
      const hour = moment().format("HH");
      if (hour >= 6 && hour < 12) {
        this.greeting = "Buongiorno";
      } else if (hour >= 14 && hour < 18) {
        this.greeting = "Buon pomeriggio";
      } else {
        this.greeting = "Buona sera";
      }
    },
    fetchData() {
      this.loading = true;
      const self = this;
      reservationService
        .todayReservations()
        .then(function (response) {
          self.prenotazioni = response.data.data;
        })
        .catch(function (error) {
          self.errorAlert(
            "non è stato possibile recuperare le prenotazioni recenti"
          );
          console.log("error fetch prenotazioni", error);
        })
        .finally(function () {
          self.loading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.prenotazioni-card {
  color: $secondary;
  font-weight: bolder;
  margin-bottom: 0px;
}

.prenotazioni-card-counter {
  font-size: 3rem;
}

.prenotazioni-card-label {
  font-size: 0.8rem;
}

.submenu__border {
  border-radius: 0.6rem;
}

.fs-4 {
  font-size: 92%;
}

.home__card {
  height: 66px;
}

.banner__padd {
  min-height: 40vh;
}
</style>
