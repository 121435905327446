import Vue from "vue"
import VueRouter from "vue-router"
import Home from "@/views/Home.vue"
import LoginPage from "@/views/LoginPage.vue"
import RegisterPage from "@/views/RegisterPage.vue"
import EmailVerification from "@/views/EmailVerification.vue"
import NumberVerification from "@/views/NumberVerification.vue"
import RecoverPassword from "@/views/RecoverPassword.vue"
import PasswordRecovery from "@/views/PasswordRecovery.vue"
import Profilo from "@/views/Profilo.vue"
import Disabled from "@/views/Disabled.vue"
import NotFoundPage from "@/views/NotFoundPage.vue"
import CittadinoHome from "@/views/cittadino/CittadinoHome.vue"
import CittadinoMain from "@/views/cittadino/CittadinoMain.vue"
import GuidaCittadino from "@/views/cittadino/GuidaCittadino.vue"
import PreviewConsulto from "@/views/cittadino/PreviewConsulto.vue"
import NuovaTerapiaAssistito from "@/views/cittadino/NuovaTerapiaAssistito.vue"
import CompanyHome from "@/views/operatore/CompanyHome.vue"
import Analitiche from "@/views/operatore/Analitiche.vue"
import GuidaOperatore from "@/views/operatore/GuidaOperatore.vue"
import CentriErogatoriList from "@/views/operatore/centriErogatori/CentriErogatoriList.vue"
import DettaglioCentroErogatore from "@/views/operatore/centriErogatori/DettaglioCentroErogatore.vue"
import PrenotazioniMain from "@/views/operatore/prenotazioni/PrenotazioniMain.vue"
import OperatoreMain from "@/views/operatore/OperatoreMain.vue"
import HomePazienti from "@/views/operatore/pazienti/HomePazienti.vue"
import NuovaTerapiaPaziente from "@/views/operatore/pazienti/NuovaTerapiaPaziente.vue"
import SchedaPaziente from "@/views/operatore/pazienti/SchedaPaziente.vue"
import OldNuovaPrenotazionePaziente from "@/views/operatore/pazienti/OldNuovaPrenotazionePaziente.vue"
import NuovaPrenotazionePaziente from "@/views/operatore/prenotazioni/NuovaPrenotazionePaziente.vue"
import CompanyAgenda from "@/views/operatore/agenda/CompanyAgenda.vue"
import NuovoAlertPaziente from "@/views/operatore/pazienti/NuovoAlertPaziente.vue"
import ModificaAlertPaziente from "@/views/operatore/pazienti/ModificaAlertPaziente.vue"
import ReplicaAlertPaziente from "@/views/operatore/pazienti/ReplicaAlertPaziente.vue"
import ProfiloAnagraficoPaziente from "@/views/operatore/pazienti/ProfiloAnagraficoPaziente.vue"
import ImpostazioniMain from "@/views/operatore/ImpostazioniMain.vue"
import AttivitaMain from "@/views/operatore/attivita/AttivitaMain.vue"
import TeleconsultoMain from "@/views/operatore/teleconsulto/TeleconsultoMain.vue"
import ModificaAlertPrestazionePaziente from "@/views/operatore/pazienti/ModificaAlertPrestazionePaziente.vue"
import ConfermaAnnullamento from "@/views/operatore/prenotazioni/ConfermaAnnullamento.vue"
import ControlRoomMain from "@/views/operatore/croom/ControlRoomMain.vue"
import ScreeningMain from "@/views/admin/attivita/ScreeningMain.vue"
import AdminAlerts from "@/views/admin/AdminAlerts.vue"
import NuovaCompany from "@/views/admin/NuovaCompany.vue"
import AdminDashboard from "@/views/admin/AdminDashboard.vue"
import AdminMain from "@/views/admin/AdminMain.vue"
import LogSistema from "@/views/admin/LogSistema.vue"
import AdminContattiPubblici from "@/views/admin/AdminContattiPubblici.vue"
import ImpostazioniSistema from "@/views/admin/ImpostazioniSistema.vue"
import GestioneAziende from "@/views/admin/GestioneAziende.vue"
import GestioneUtenti from "@/views/admin/GestioneUtenti.vue"
import GestionePrenotazioni from "@/views/admin/GestionePrenotazioni.vue"
import DettaglioAzienda from "@/views/admin/DettaglioAzienda.vue"
import GestioneServizi from "@/views/admin/GestioneServizi.vue"
import GestioneFacile from "@/views/admin/GestioneFacile.vue"
import GestioneTeleconsulto from "@/views/admin/GestioneTeleconsulto.vue"
import RefertoDimissioni from "@/views/operatore/pazienti/RefertoDimissioni.vue"
import BancaDati from "@/views/operatore/BancaDati.vue"
import AdminRegistrazioni from "@/views/admin/AdminRegistrazioni.vue"
import MainSottoscrizioniAdmin from "@/views/admin/sottoscrizioni/MainSottoscrizioniAdmin.vue"
import Help from "@/views/Help.vue"

Vue.use(VueRouter)
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
    // component: () => import('@/views/Home.vue'),
  },
  { path: "/login", name: "LoginPage", component: LoginPage },
  { path: "/register", name: "RegisterPage", component: RegisterPage },
  { path: "/disabled", name: "Disabled", component: Disabled },
  {
    path: "/password/reset",
    name: "RecoverPassword",
    component: RecoverPassword
  },
  {
    path: "/help",
    name: "Guida",
    component: Help
  },
  {
    path: "/password/recovery",
    name: "recupero password",
    component: PasswordRecovery
  },
  {
    path: "/email/verify/:status",
    name: "EmailVerification",
    component: EmailVerification
  },
  {
    path: "/number/verify",
    name: "NumberVerification",
    component: NumberVerification
  },
  {
    path: "/help",
    name: "GuidaAllUsers",
    component: Help
  },
  // {
  //   path: "/test/payment",
  //   name: "paymentTesting",
  //   component: () => import("@/views/TestPagamento.vue")
  // },
  {
    path: "/payment/:purchase/:outcome?",
    name: "PaymentOutcome",
    component: () => import("@/views/PaymentOutcome.vue")
  },
  {
    path: "/cittadino",
    component: CittadinoMain,
    children: [
      { path: "home", name: "CittadinoHome", component: CittadinoHome },
      {
        path: "familiari/:section/:hash?",
        name: "familiari",
        component: () => import("@/views/cittadino/FamiliariMain.vue")
      },
      {
        path: "appuntamenti/:section/:type",
        name: "appuntamenti",
        component: () =>
          import("@/views/cittadino/PrenotazioniCittadinoMain.vue")
      },
      {
        path: "appuntamenti/:section/:type",
        name: "appuntamentiFarmaci",
        component: () =>
          import("@/views/cittadino/PrenotazioniCittadinoMain.vue")
      },
      {
        path: "appuntamenti/:section/:type",
        name: "appuntamentiTeleconsulti",
        component: () =>
          import("@/views/cittadino/PrenotazioniCittadinoMain.vue")
      },
      {
        path: "agenda",
        name: "agenda",
        component: () => import("@/views/cittadino/AgendaCittadino.vue")
      },
      {
        path: "teleconsulto",
        name: "il tuo consulto",
        component: PreviewConsulto
      },
      {
        path: "circolocura",
        name: "circolo di cura",
        component: () => import("@/views/cittadino/CircoloCura.vue")
      },
      {
        path: "sottoscrizioni/:section/:action?",
        name: "sottoscrizioni paziente",
        component: () =>
          import("@/views/cittadino/SottoscrizioniCittadinoMain.vue")
      },
      {
        path: "progetti/:hashprogetto/:section/:subsection?",
        name: "progetti cittadino",
        component: () => import("@/views/cittadino/ProgettiMain.vue")
      },
      {
        path: "profilosanitario/:section/:action?",
        name: "profilo sanitario",
        component: () => import("@/views/cittadino/ProfiloSanitarioMain.vue")
      },
      {
        path: "impostazioni",
        name: "impostazioniCittadino",
        component: () => import("@/views/cittadino/ImpostazioniCittadino.vue")
      },
      { path: "profilo", name: "profilo cittadino", component: Profilo },
      { path: "help", name: "guida cittadino", component: GuidaCittadino },
      {
        path: "questionario/compila/:quest?",
        name: "completa questionario",
        component: () => import("@/views/cittadino/UserCompilaQuestionario.vue")
      },
      {
        path: "questionario/score/compila/:quest?",
        name: "score questionario",
        component: () => import("@/views/cittadino/UserFillScoreSurvey.vue")
      },
      {
        path: "familiare/assistito/terapia",
        name: "nuova terapia assistito",
        component: NuovaTerapiaAssistito
      },
      {
        path: "testCompanyResCard",
        name: "testCompanyResCard",
        component: () =>
          import("@/components/prenotazioni/CompanyReservationCard.vue")
      }
    ]
  },
  {
    path: "/operatore",
    component: OperatoreMain,
    children: [
      { path: "home", name: "CompanyHome", component: CompanyHome },
      { path: "analitiche", name: "analitiche", component: Analitiche },
      { path: "agenda", name: "agenda appuntamenti", component: CompanyAgenda },

      { path: "help", name: "guida", component: GuidaOperatore },
      { path: "pazienti", name: "pazienti", component: HomePazienti },
      { path: "profilo", name: "profilo operatore", component: Profilo },
      {
        path: "centriErogatori",
        name: "centri erogatori",
        component: CentriErogatoriList
      },
      {
        path: "centriErogatori/:hashid",
        name: "dettaglio centro erogatore",
        component: DettaglioCentroErogatore
      },
      {
        path: "teleconsulto/:section",
        name: "teleconsulto",
        component: TeleconsultoMain
      },
      {
        path: "attivita/:section/:action?/:quest?",
        name: "attivita",
        component: AttivitaMain
      },
      {
        path: "conferma-annullamento",
        name: "conferma annullamento",
        component: ConfermaAnnullamento
      },
      {
        path: "prenotazioni/:section",
        name: "prenotazioni",
        component: PrenotazioniMain
      },
      {
        path: "progetti/:hashprogetto/:section/:subsection?",
        name: "progetti operatore",
        component: () => import("@/views/cittadino/ProgettiMain.vue")
      },
      {
        path: "impostazioni/:section",
        name: "impostazioniOperatore",
        component: ImpostazioniMain
      },
      {
        path: "bancadati",
        name: "bancadati",
        component: BancaDati
      },
      {
        path: "pazienti/scheda/:hash",
        name: "scheda paziente",
        component: SchedaPaziente
      },
      {
        path: "pazienti/profilo/:hash",
        name: "profilo paziente",
        component: ProfiloAnagraficoPaziente
      },
      {
        path: "pazienti/terapia",
        name: "nuova terapia Paziente",
        component: NuovaTerapiaPaziente
      },
      {
        path: "pazienti/:hash/prenotazioni/nuovo/:tipo",
        name: "nuova prenotazione paziente",
        component: NuovaPrenotazionePaziente
      },
      {
        path: "pazienti/servizi/:hash",
        name: "prenota servizio",
        component: OldNuovaPrenotazionePaziente
      },
      {
        path: "pazienti/ordine/:hash",
        name: "prenota ordine",
        component: () =>
          import("@/views/operatore/pazienti/NuovoOrdinePaziente.vue")
      },
      {
        path: "pazienti/alerts/:hash",
        name: "nuova notifica paziente",
        component: NuovoAlertPaziente
      },
      {
        path: "pazienti/alerts/edit/:hash",
        name: "modifica piano notifiche",
        component: ModificaAlertPaziente
      },
      {
        path: "pazienti/recall/edit/:hash",
        name: "modifica piano recall",
        component: ModificaAlertPrestazionePaziente
      },
      {
        path: "pazienti/alerts/copy/:hash",
        name: "replica piano notifiche",
        component: ReplicaAlertPaziente
      },
      {
        path: "pazienti/referti/dimissioni",
        name: "dimissioni paziente",
        component: RefertoDimissioni
      },
      {
        path: "controlroom/:section?/:hash?",
        name: "control room",
        component: ControlRoomMain
      }
    ]
  },
  {
    path: "/admin",
    component: AdminMain,
    children: [
      { path: "home", name: "AdminDashboard", component: AdminDashboard },
      { path: "utenti", name: "gestione utenti", component: GestioneUtenti },
      {
        path: "prenotazioni",
        name: "gestione prenotazioni",
        component: GestionePrenotazioni
      },
      { path: "aziende", name: "gestione aziende", component: GestioneAziende },
      { path: "aziende/nuova", name: "nuova azienda", component: NuovaCompany },
      {
        path: "aziende/dettaglio/:company",
        name: "dettaglio azienda",
        component: DettaglioAzienda
      },
      { path: "servizi", name: "gestione servizi", component: GestioneServizi },

      {
        path: "prenotazioni",
        name: "servizio facile",
        component: GestioneFacile
      },
      {
        path: "teleconsulto",
        name: "servizio di teleconsulto",
        component: GestioneTeleconsulto
      },
      {
        path: "screening/:section/:action?/:quest?",
        name: "screening",
        component: ScreeningMain
      },
      {
        path: "alerts",
        name: "admin alerts",
        component: AdminAlerts
      },
      { path: "syslog", name: "log di sistema", component: LogSistema },
      {
        path: "contatti",
        name: "contattipubblici",
        component: AdminContattiPubblici
      },
      {
        path: "settingsys/nuovo",
        name: "impostazioni sistema",
        component: ImpostazioniSistema
      },
      {
        path: "registrazioni",
        name: "registrazioniAdmin",
        component: AdminRegistrazioni
      },
      {
        path: "sottoscrizioni/:section",
        name: "admin sottoscrizioni",
        component: MainSottoscrizioniAdmin
      }
    ]
  },
  {
    path: "/*",
    name: "pagina non trovata",
    component: NotFoundPage
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/register",
    "/password/reset",
    "/password/recovery",
    "/disabled",
    "/email/verify/already",
    "/email/verify/success"
  ]

  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem("user")

  // if (loggedIn && to.path === "/help") {
  //   const user = JSON.parse(localStorage.getItem("user"))
  //   if (user.type === 0) {
  //     return next("/cittadino/help")
  //   } else {
  //     return next("/operatore/help")
  //   }
  // }

  if (to.name === "PaymentOutcome") {
    return next()
  }

  if (authRequired && !loggedIn) {
    return next("/login")
  }

  if (!authRequired && loggedIn) {
    return next("/")
  }

  next()
})
router.afterEach((to, from) => {
  window.scrollTo(0, 0)
})

export default router
