<template>
  <div v-if="detail" class="w-100">
    <!-- <b-row
      align-v="stretch"
      id="row-dettaglio-teleconsulto"
      class="mt-n3 flex-wrap"
    >  -->
    <b-row align-v="stretch" class="mt-n3 flex-wrap">
      <!-- <b-col id="col-questionario" class="mt-3"> -->

      <b-col cols="12" xl="8" class="mt-3">
        <div
          class="p-3 detail-modal-card d-flex flex-column justify-content-start align-items-start h-100"
        >
          <p class="font-weight-bolder detail-modal-card-titles">
            QUESTIONARIO
          </p>
          <b-button
            v-if="survey !== null && survey.response"
            variant="secondary"
            class="px-4"
            @click="$refs['show-filleditem'].show()"
            >apri questionario</b-button
          >
          <p v-else class="m-0 p-0 text-dark-grey font-size-085rem">
            Nessun questionario
          </p>
        </div>
      </b-col>
      <b-col v-if="ricetta" cols="12" xl="8" class="mt-3">
        <div
          class="p-3 detail-modal-card d-flex flex-column justify-content-start align-items-start h-100"
        >
          <p class="font-weight-bolder detail-modal-card-titles">RICETTA</p>
          <p class="m-0 p-0 text-dark-grey font-size-085rem">
            <span
              @click="downloadRicetta"
              class="text-secondary font-weight-bolder point underlined"
              >Scarica</span
            >
          </p>
        </div>
      </b-col>
      <b-col
        cols="12"
        xl="8"
        class="mt-3"
        v-if="detail.durata !== null && detail.durata > 0"
      >
        <b-row class="p-3 detail-modal-card">
          <b-col class="px-0">
            <p>
              <span class="font-weight-bolder detail-modal-card-titles"
                >DURATA PREVISTA</span
              >
            </p>
            <p class="text-dark-grey font-size-085rem m-0 p-0">
              {{ detail.durata }} minuti
            </p>
          </b-col>
          <b-col class="px-0" v-if="detail.durata_effettiva">
            <p>
              <span class="font-weight-bolder detail-modal-card-titles"
                >DURATA EFFETTIVA</span
              >
            </p>
            <p class="text-dark-grey font-size-085rem m-0 p-0">
              {{ detail.durata_effettiva }} minuti
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        xl="8"
        class="mt-3"
        v-if="detail.details && detail.details !== ''"
      >
        <b-row class="p-3 detail-modal-card">
          <b-col class="px-0">
            <p>
              <span class="font-weight-bolder detail-modal-card-titles"
                >ALTRI DETTAGLI</span
              >
            </p>
            <p class="text-dark-grey font-size-085rem m-0 p-0">
              {{ detail.details }}
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" xl="4" class="mt-3" v-if="thereIsOneButton">
        <div
          class="px-0 mr-4 d-flex flex-column justify-content-center align-items-center h-100"
          v-if="company.level === 5 && accettata === null"
        >
          <b-button
            type="button"
            variant="secondary  mb-2"
            @click="$refs['accept-decline-modal'].show()"
            >Accetta/declina prenotazione</b-button
          >
        </div>
        <div
          v-else
          class="px-0 mr-4 d-flex flex-column justify-content-around align-items-center h-100"
        >
          <b-button
            v-if="editBtn"
            @click="updateRequest"
            type="button"
            variant="secondary"
            class="mb-2"
            >Invia Risposta</b-button
          >
          <b-button
            v-else-if="stato === 1 || stato === 6"
            @click="confirmRequest"
            type="button"
            variant="secondary"
            class="mb-2"
            >Conferma prenotazione</b-button
          >
          <b-button
            v-if="stato === 1"
            @click="setAction('reject')"
            type="button"
            variant="secondary"
            class="mb-2"
            >Declina prenotazione</b-button
          >
          <b-button
            v-if="stato === 2 || stato === 3"
            class="general_border mb-2"
            type="button"
            variant="secondary "
            @click="
              $router.push({
                name: 'teleconsulto',
                params: { section: 'videocall' },
                query: { reserv: hash }
              })
            "
            >Vai al Teleconsulto</b-button
          >
          <b-button
            v-if="stato > 1 && !nocancel"
            @click="setAction('cancel')"
            type="button"
            variant="outline-danger"
            >Annulla prenotazione</b-button
          >
        </div>
        <b-modal
          ref="accept-decline-modal"
          hide-footer
          title="Accetta o Declina Prenotazione"
          centered
        >
          <div class="d-flex justify-content-around">
            <b-button variant="secondary" @click="acceptBooking"
              >Accetta</b-button
            >
            <b-button variant="secondary" @click="declineBooking"
              >Declina</b-button
            >
          </div>
        </b-modal>
      </b-col>

      <b-col cols="12" xl="8" class="mt-3" v-if="detail.operator_notes">
        <b-row class="p-3 detail-modal-card">
          <b-col class="px-0">
            <p>
              <span class="font-weight-bolder detail-modal-card-titles"
                >APPUNTI TELECONSULTO</span
              >
            </p>
            <p class="text-dark-grey font-size-085rem m-0 p-0">
              {{ detail.operator_notes }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      size="xl"
      hide-footer
      centered
      ref="modal-confirm"
      id="modal-confirm"
      title="Conferma Annullamento"
    >
      <PickMessageForm
        :messages="cancellationMessages"
        confirm-message="Sei sicuro di voler annullare questo appuntamento?"
        @cancellation-confirmed="cancelRequest($event)"
        @refused-to-confirm="$bvModal.hide('modal-confirm')"
      />
    </b-modal>

    <b-modal
      ref="show-filleditem"
      centered
      hide-footer
      title="Questionario"
      size="xl"
    >
      <FilledSurveyShower
        v-if="survey"
        :response="survey.response"
        :score="survey.score"
      />
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { reservationService } from "@/_services"
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue"
import PickMessageForm from "@/components/prenotazioni/form/PickMessageForm.vue"
import { CANCELLATION_MESSAGES } from "@/_utils/constants.js"
import { mapState } from "vuex"

export default {
  name: "DetailTeleconsulto",
  props: [
    "dettaglio",
    "stato",
    "datainizio",
    "quest",
    "hash",
    "company_notes",
    "nocancel",
    "accettata",
    "ricetta"
  ],
  components: { FilledSurveyShower, PickMessageForm },
  data() {
    return {
      cancellationMessages: CANCELLATION_MESSAGES,
      actionName: null,
      loading: false,
      detail: this.dettaglio,
      editBtn: false,
      newDate: this.datainizio,
      newTime: moment(this.datainizio).format("HH:mm"),
      survey: null
    }
  },
  computed: {
    checkValidity: function () {
      var data = moment(this.datainizio)
      return moment().isBefore(data)
    },
    thereIsOneButton: function () {
      return Math.abs(this.stato) !== 4 && (this.editBtn || this.stato >= 1)
    },
    ...mapState("company", ["company"])
  },
  watch: {
    dettaglio: {
      handler(val) {
        this.$nextTick(function () {
          this.detail = val
          if (val.resource_id !== null) {
            this.getAllegato()
          }
        })
      },
      deep: true
    },
    detail: {
      handler(val) {
        // this.editBtn = true;
        this.editBtn =
          Math.abs(this.stato) !== 4 &&
          Math.abs(this.stato) !== 3 &&
          Math.abs(this.stato) !== 2
      },
      deep: true
    },
    newDate(val) {
      this.editBtn = true
    },
    newTime(val) {
      this.editBtn = true
    },
    // company_notes(val) {
    //   this.editBtn = true
    // },
    quest(val) {
      if (val) {
        this.survey = val
      } else {
        this.survey = null
      }
    }
  },
  methods: {
    getAllegato() {
      var self = this
      var endpoint =
        this.dettaglio.allegato.full_path + "/" + this.dettaglio.allegato.hashid
      reservationService
        .getAllegato(endpoint)
        .then(function (res) {
          self.picLink = window.URL.createObjectURL(new Blob([res.data]))
        })
        .catch(function (err) {
          console.log(err)
        })
    },
    confirmRequest() {
      var data = {
        confirmed: true,
        action: "confirm"
      }
      this.$emit("update-request-status", data)
    },
    updateRequest() {
      var daTe = moment(this.newDate)
      var tiMe = moment(this.newTime, "HH:mm")
      daTe.hours(tiMe.hours())
      daTe.minutes(tiMe.minutes())
      var datetime = daTe.format("YYYY-MM-DD HH:mm")
      // console.log(daTe.format("YYYY-MM-DD HH:mm"));
      var dataToSend = {
        detail: this.detail,
        newDateTime: datetime,
        company_notes: this.company_notes
      }
      this.$emit("update-request-response", dataToSend)
    },
    setAction(action) {
      this.actionName = action
      this.$refs["modal-confirm"].show()
    },
    cancelRequest(message = null) {
      this.loading = true
      var data = {
        confirmed: false,
        message,
        action: this.actionName
      }
      this.$refs["modal-confirm"].hide()
      this.$emit("update-request-status", data)
    },
    handleNotesUpdate(data) {
      this.company_notes = data.company_notes
    },
    acceptBooking() {
      var data = {
        confirmed: true,
        action: "accept",
        hashid: this.detail.hashid
      }
      this.$emit("update-request-status", data)
    },
    declineBooking() {
      this.setAction("reject")
      this.$refs["modal-confirm"].show()
    },
    downloadRicetta() {
      var self = this
      reservationService
        .downloadResource(this.ricetta.hashid)
        .then(function (response) {
          // console.log("response", response.headers)
          let contentType = response.headers["content-type"]
          let blob = new Blob([response.data], { type: contentType })
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)

          let contentDisposition = response.headers["content-disposition"]
          let fileName = contentDisposition.split("filename=")[1].split(";")[0]

          // Rimozione di eventuali underscore
          fileName = fileName.replace(/^_+|_+$/g, "").replace(/\"/g, "")

          link.download = fileName
          link.click()
        })
        .catch(function (err) {
          console.log(err)
          self.errorAlert("Non è stato possibile scaricare la risorsa")
        })
    }
  },
  created() {
    if (this.quest) {
      this.survey = this.quest
    }
  }
}
</script>

<style lang="scss" scoped>
#row-dettaglio-teleconsulto {
  .col {
    padding: 0px !important;
    max-width: 300px;
  }

  button {
    max-width: 90%;
  }
}

@media (min-height: 1200px) {
  #row-dettaglio-teleconsulto {
    .col {
      padding: 0px !important;
    }

    button {
      max-width: 90%;
    }
  }
}
</style>
