<template>
  <b-container fluid class="px-0">
    <!-- Riga filtri  -->
    <b-row
      class="mb-3 mt-n3 flex-wrap align-items-center"
      align-v="stretch"
      v-if="!hideFilters"
    >
      <b-col
        v-if="company"
        class="filter-column mt-3 col-12 col-sm-6 col-md-4 col-xl-3"
      >
        <div class="pl-0">
          <p :class="{ 'mb-1 pb-2': company.level === 1 }">Cerca</p>
          <LabeledSearchInput
            :only-second="company.level !== 1"
            first-label="Codice Fiscale"
            short-first-label="C. Fiscale"
            :first-value="cfSearch"
            :first-value-uppercase="true"
            first-placeholder="Codice Fiscale..."
            @first-value-changed="cfSearch = $event"
            @switched-to-first="surnameSearch = undefined"
            second-label="Cognome"
            short-second-label="Cogn."
            :second-value="surnameSearch"
            :second-value-uppercase="true"
            second-placeholder="Cognome..."
            @second-value-changed="surnameSearch = $event"
            @switched-to-second="cfSearch = undefined"
          />
        </div>
      </b-col>

      <b-col
        class="filter-column flex-grow-1 mt-3"
        v-if="thereIsAtLeastOneFilterBadge || pickedView === 'week'"
      >
        <div class="pl-4 d-flex flex-column w-100">
          <p class="mb-1 pb-2">Filtri applicati</p>
          <b-row class="d-flex align-items-center flex-wrap w-100 mt-n3">
            <b-badge
              v-if="filters.status"
              class="general_border mr-3 mt-3"
              :class="`${getColorClass(filters.status)}-bg`"
            >
              <div class="p-1 d-flex">
                <span @click="resetStatusFilter()" class="point">
                  <b-icon
                    icon="x"
                    variant="white"
                    font-scale="2"
                  ></b-icon> </span
                ><span class="d-inline-block d-flex align-items-center pr-2">{{
                  `${getBadgeLabel(filters.status)}`
                }}</span>
              </div></b-badge
            >
            <b-badge
              v-if="filters.service"
              class="general_border mr-3 mt-3"
              :class="`bg-${SERVIZI_VARIANTS[filters.service]}-accent`"
            >
              <div class="p-1 d-flex">
                <span @click="resetServiceFilter()" class="point">
                  <b-icon
                    icon="x"
                    variant="white"
                    font-scale="2"
                  ></b-icon> </span
                ><span class="d-inline-block d-flex align-items-center pr-2">{{
                  filters.service
                }}</span>
              </div></b-badge
            >
            <b-badge
              class="general_border mr-3 mt-3"
              v-if="selectedPrestazione?.name"
              :class="`bg-${SERVIZI_VARIANTS['prestazione']}-accent`"
            >
              <div class="p-1 d-flex">
                <span @click="resetPrestazioneFilter()" class="point">
                  <b-icon
                    icon="x"
                    variant="white"
                    font-scale="2"
                  ></b-icon> </span
                ><span class="d-inline-block d-flex align-items-center pr-2">{{
                  selectedPrestazione?.name
                }}</span>
              </div></b-badge
            >
            <b-badge
              v-if="filters.notCanceled"
              class="general_border mr-3 mt-3 bg-info text-black border border-darkgrey"
            >
              <div class="p-1 d-flex">
                <span @click="resetNotCanceledFilter()" class="point">
                  <b-icon icon="x" font-scale="2"></b-icon> </span
                ><span
                  class="d-inline-block d-flex align-items-center pr-2 text-black"
                  >Escludi annullati</span
                >
              </div></b-badge
            >
            <b-form-checkbox
              v-if="pickedView === 'week'"
              class="d-flex align-items-center mt-3"
              size="sm"
              name="stato"
              :value="false"
              :unchecked-value="true"
              v-model="compactView"
            >
              <span class="d-flex align-items-center"
                >Visualizzazione estesa</span
              >
            </b-form-checkbox>
          </b-row>
        </div>
      </b-col>
      <b-col
        class="filter-column mt-3 col-12 col-sm-3 col-md-2 col-lg-1 ml-auto"
      >
        <div class="ml-auto d-flex flex-column text-right">
          <p class="mb-1 text-left pb-2">Filtri</p>
          <div class="d-flex justify-content-end">
            <CalendarioFilters
              :filter-options="filterOptions"
              :status-filter-enabled="servicerif !== 2"
              :status-filter="filters.status"
              @status-filter-changed="(status) => (filters.status = status)"
              :not-canceled-filter="filters.notCanceled"
              @not-canceled-filter-changed="
                (notCanceled) => (filters.notCanceled = notCanceled)
              "
              :service-filter-enabled="servicerif !== 2"
              :service-filter="filters.service"
              :service-filter-options="filterOptions.service"
              @service-filter-changed="(service) => (filters.service = service)"
              :prestazione-filter-enabled="filters.service == 'prestazione'"
              :prestazione-filter="
                selectedPrestazione ? selectedPrestazione?.hashid : null
              "
              @prestazione-filter-changed="setPrestazioneFilter($event)"
              @fetch-data="fetchData()"
            />
          </div>
        </div>
      </b-col>
    </b-row>

    <!-- Prima riga calendario -->
    <b-row class="mb-2">
      <b-col class="col-4 px-0 d-flex align-items-center justify-content-start">
        <template v-if="pickedView !== 'day'">
          <span class="mr-3">
            <label for="month_selector" class="mr-1 mb-0">Mese</label>
            <select
              v-capitalize-first-select
              class="form-select bg-white border border-info general_border py-1"
              @change="onMonthYearChange"
              v-model="selectedMonth"
            >
              <option
                v-for="month in monthMapping"
                :key="month.id"
                :value="month.id"
                id="month_selector"
              >
                {{ month.name }}
              </option>
            </select>
          </span>
          <span>
            <label for="year_selector" class="mr-1 mb-0">Anno</label>
            <select
              v-capitalize-first-select
              class="form-select bg-white border border-info general_border py-1"
              v-model="selectedYear"
              id="year_selector"
              @change="onMonthYearChange"
            >
              <option v-for="year in years" :key="year" :value="year">
                {{ year }}
              </option>
            </select>
          </span>
        </template>
      </b-col>
      <b-col
        cols="4"
        class="px-0 d-flex align-items-center justify-content-center"
      >
        <b-icon
          v-if="pickedView !== 'day'"
          variant="secondary"
          font-scale="1.7"
          class="mx-2 point"
          @click="setPrev"
          icon="arrow-left-square-fill"
        ></b-icon>
        <h5 class="text-capitalize mb-0 mx-2">
          <template v-if="pickedView === 'week'">
            <span class="d-none d-xl-inline">
              {{ start | moment("D MMMM YYYY") }} -
              {{ end | moment("D MMMM YYYY") }}
            </span>
            <span class="d-inline d-xl-none">
              {{ start | moment("D MMM YY") }} - {{ end | moment("D MMM YY") }}
            </span>
          </template>
          <template v-if="pickedView === 'day'">
            {{ dayViewDate }}
          </template>
          <template v-if="pickedView === 'month'">
            {{ start | moment("MMMM YYYY") }}
          </template>
        </h5>
        <b-icon
          v-if="pickedView !== 'day'"
          @click="setPost"
          font-scale="1.5"
          class="mx-2"
          variant="secondary point"
          icon="arrow-right-square-fill"
        ></b-icon>
      </b-col>
      <b-col
        cols="4"
        class="px-0 d-flex aling-items-center justify-content-end"
      >
        <b-form-group
          v-if="pickedView !== 'day'"
          class="col-12 col-sm-11 col-md-12 col-lg-12 col-lgx-11 col-xl-8 px-0 text-right"
        >
          <b-form-select
            v-capitalize-first-select
            id="pick-view-select"
            size="sm"
            class="text-left"
            name="show"
            v-model="pickedView"
          >
            <b-form-select-option value="month"> Mese </b-form-select-option>
            <b-form-select-option value="week">
              Settimana
            </b-form-select-option>
            <!-- <b-form-select-option value="day"> Giorno </b-form-select-option> -->
          </b-form-select>
        </b-form-group>
        <b-button
          class="col-12"
          v-else
          @click="pickedView = previousView"
          size="sm"
        >
          Torna alla vista
          {{ previousView === "month" ? "mensile" : "settimanale" }}
        </b-button>
      </b-col>
    </b-row>
    <transition-group
      tag="div"
      name="slidefade"
      mode="out-in"
      appear
      class="my-4"
    >
      <CalendarDayDetail
        key="daily-view"
        v-if="pickedView === 'day'"
        :reservations="prenotazioni"
        :day="selectedDay"
        :colorStatus="colorStatusPositive"
        :icons="icons"
        @detail-toshow="setCurrentReserv($event)"
        @update-prenotazioni="fetchData"
      />

      <CalendarWeek
        v-if="pickedView === 'week'"
        key="weekly-view"
        :fields="fields"
        :items="prenotazioni"
        :all-visible="allVisible"
        :color-status="colorStatusPositive"
        @show-day-detail="showDayDetail($event)"
        @detail-toshow="setCurrentReserv($event)"
      />

      <CalendarMonth
        v-else-if="pickedView === 'month'"
        key="monthly-view"
        :picked-month="pickedMonth"
        :reservations="prenotazioni"
        @go-to-day="navigateToSingleDay($event)"
      />
    </transition-group>

    <b-modal
      size="xl"
      centered
      id="modal-resdetails-calendario"
      ref="modal-resdetails-calendario"
      hide-footer
      hide-header
      scrollable
      body-class="p-0"
      @hidden="currentReserv = null"
    >
      <DettaglioPrenotazioneOperatore
        v-if="currentReserv"
        :chat-recipient-hash="currentReserv.subjectable.hashid"
        chat-recipient-type="user"
        :reservation="currentReserv"
        @close-modal="closeModal()"
        @fetch-data="fetchData()"
        @handle-update-request="handleUpdateRequest()"
        @handle-status-update="handleStatusUpdate($event)"
        @update-request-response="sendOrderResponse($event)"
        @update-res-operator="updateResOperator($event)"
      />
    </b-modal>

    <b-modal
      size="lg"
      centered
      ref="modal-evasione"
      title="Evadi Richiesta"
      hide-footer
    >
      <div v-if="toEvadere">
        <p class="text-left text-font-grey">
          Numero Richiesta: #{{ toEvadere.numero }} <br />
        </p>
        <p class="text-left text-font-grey">
          Codice Richiesta:
          {{ toEvadere.codice_richiesta }} <br />
        </p>

        <b-form-input
          name="detail"
          class="mx-auto"
          v-model="evasione.hash"
          type="text"
          hidden
        >
        </b-form-input>
        <b-form-group
          label="Aggiungi Annotazione"
          label-for="detail"
          class="text-left text-secondary"
        >
          <b-form-input
            v-capitalize-first
            name="detail"
            class="mx-auto"
            v-model="evasione.details"
            type="text"
          >
          </b-form-input>
        </b-form-group>
        <div class="text-right my-2 pt-4">
          <b-button
            type="button"
            class="mr-3"
            variant="popup-button"
            @click="$refs['modal-evasione'].hide()"
            >Annulla
          </b-button>
          <b-button
            type="button"
            class="mr-3"
            variant="popup-button"
            @click="evadiThis"
          >
            Conferma</b-button
          >
        </div>
      </div>
    </b-modal>
    <b-row
      class="d-flex justify-content-start align-items-center flex-wrap mx-0 mt-n3 px-0"
      align-v="stretch"
      id="calendar-legend"
    >
      <b-col class="d-flex mr-3 mt-3">
        <span
          v-for="col in colorLegend"
          :key="col.label"
          class="d-flex align-items-center"
        >
          <b-icon
            :class="col.color"
            icon="circle-fill"
            font-scale="1.5"
          ></b-icon>
          <span class="mx-2">{{ col.label }}</span>
        </span>
      </b-col>
      <b-col class="mx-0 mt-3 d-flex">
        <p
          v-if="!servicerif || servicerif == 2"
          class="border general_border px-2 py-1 mr-2 my-auto d-flex align-items-center"
          :class="`bg-${SERVIZI_VARIANTS['teleconsulti']}-light border-${SERVIZI_VARIANTS['teleconsulti']}-accent`"
        >
          <b-icon :icon="icons[1]" font-scale="1.2"></b-icon>
          <span class="ml-2" style="font-weight: 300">Teleconsulto</span>
        </p>
        <p
          class="border general_border px-2 py-1 mr-2 my-auto d-flex align-items-center"
          :class="`bg-${SERVIZI_VARIANTS['prodotti']}-light border-${SERVIZI_VARIANTS['prodotti']}-accent`"
          v-if="!servicerif || servicerif == 1"
        >
          <b-icon :icon="icons[0]" font-scale="1.2"></b-icon>
          <span class="ml-2">Ordine</span>
        </p>
        <p
          class="border general_border px-2 py-1 mr-2 my-auto d-flex align-items-center"
          :class="`bg-${SERVIZI_VARIANTS['prestazioni']}-light border-${SERVIZI_VARIANTS['prestazioni']}-accent`"
          v-if="!servicerif || servicerif == 1"
        >
          <b-icon :icon="icons[2]" font-scale="1.2"></b-icon>
          <span class="mx-2">Prestazione sanitaria</span>
        </p>
      </b-col>
    </b-row>

    <br />
    <CalendarAddButton v-if="company.level === 1"></CalendarAddButton>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
import { SERVIZI_VARIANTS } from "@/_utils/constants.js";
import { mapActions, mapState } from "vuex";
import { reservationService } from "@/_services";
import CalendarDayDetail from "@/components/prenotazioni/calendario/CalendarDayDetail.vue";
import CalendarMonth from "@/components/prenotazioni/calendario/CalendarMonth.vue";
import CalendarWeek from "@/components/prenotazioni/calendario/CalendarWeek.vue";
import CalendarioFilters from "@/components/prenotazioni/calendario/CalendarioFilters.vue";
import CalendarAddButton from "@/components/prenotazioni/calendario/CalendarAddButton.vue";
import DettaglioPrenotazioneOperatore from "@/components/prenotazioni/DettaglioPrenotazioneOperatore.vue";
import LabeledSearchInput from "@/components/prenotazioni/calendario/LabeledSearchInput.vue";

export default {
  name: "Calendario",
  props: [
    "startWithDate",
    "hideFilters",
    "servicerif",
    "statusFilter",
    "serviceFilter",
    "prestazioneFilter",
    "searchFilter",
    "cfFilter",
    "compactView",
    "startDate",
  ],
  components: {
    CalendarDayDetail,
    CalendarAddButton,
    DettaglioPrenotazioneOperatore,
    CalendarMonth,
    CalendarWeek,
    LabeledSearchInput,
    CalendarioFilters,
  },
  data() {
    return {
      SERVIZI_VARIANTS: SERVIZI_VARIANTS,
      fields: [],
      previousView: null,
      prenotazioni: null,
      start: null,
      pickedView: "week",
      end: null,
      month: null,
      today: null,
      filters: {
        from: null,
        to: null,
        position_ref: this.servicerif,
        status: undefined,
        service: undefined,
        search: undefined,
        cf: undefined,
        surname: undefined,
        prestazione: undefined,
        prestazioneHash: undefined,
        notCanceled: false,
        unread: true,
      },
      filterOptions: {
        status: ["in corso", "in attesa", "evase", "annullate"],
        service: [
          { label: "prestazione", value: "prestazione" },
          { label: "prodotto", value: "prodotto" },
          { label: "teleconsulto", value: "teleconsulto" },
        ],
        search: null,
      },
      pickedMonth: null,
      statusPositive: {
        1: "secondary",
        2: "info",
        3: "info",
        4: "primary-darker",
        5: "ter-4",
        6: "ter-4",
      },
      statusNegative: {
        1: "danger",
        2: "ter-2",
        3: "ter-2",
        4: "dark",
      },
      colorStatusPositive: {
        1: "inoltrato-in-attesa",
        2: "confermato",
        3: "confermato",
        4: "evaso",
        5: "inoltrato-in-attesa",
        6: "inoltrato-in-attesa",
      },
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill",
      },
      evasione: {
        details: null,
        hash: null,
      },
      toEvadere: null,
      currentReserv: null,
      colorLegend: [
        {
          label: "Confermato",
          color: "confermato",
          val: "in corso",
        },
        {
          label: "Evaso/Concluso",
          color: "evaso",
          val: "evase",
        },
        {
          label: "Ricevuto/In attesa di risposta",
          color: "inoltrato-in-attesa",
          val: "in attesa",
        },
        {
          label: "Errore/Rifiutato/Annullato",
          color: "rifiutato-annullato",
          val: "annullate",
        },
      ],
      serviziLegend: [
        {
          label: "Prodotto",
          icon: `dashboardOrdini`,
        },
        {
          label: "Prestazione",
          icon: `dashboardPrestazioni`,
        },
        {
          label: "Teleconsulto",
          icon: `dashboardTeleconsulto`,
        },
      ],
      allVisible: false,
      selectedPrestazione: null,
      onlyOneDay: false,
      selectedDay: null,
      cfSearch: null,
      surnameSearch: null,
      searchByCf: false,
      years: [],
      selectedMonth: new Date().getMonth(),
      selectedYear: new Date().getFullYear(),
      monthMapping: [
        { id: 0, name: "Gennaio" },
        { id: 1, name: "Febbraio" },
        { id: 2, name: "Marzo" },
        { id: 3, name: "Aprile" },
        { id: 4, name: "Maggio" },
        { id: 5, name: "Giugno" },
        { id: 6, name: "Luglio" },
        { id: 7, name: "Agosto" },
        { id: 8, name: "Settembre" },
        { id: 9, name: "Ottobre" },
        { id: 10, name: "Novembre" },
        { id: 11, name: "Dicembre" },
      ],
      pickedHSFilterName: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("company", ["company"]),
    statusIcon: function () {
      switch (this.filters.status) {
        case "in corso":
          return "confermato";
        case "evase":
          return "evaso";
        case "in attesa":
          return "inoltrato-in-attesa";
        case "annullate":
          return "rifiutato-annullato";
        default:
          return "nostat";
      }
    },
    nextText: function () {
      return this.pickedView === "week" ? "Successiva" : "Successivo";
    },
    servIcon: function () {
      switch (this.filters.service) {
        case "prestazione":
          return "file-ppt";
        case "prodotto":
          return "bag";
        case "teleconsulto":
          return "camera-video";
        default:
          return "";
      }
    },
    dayViewDate: function () {
      return moment(this.start)
        .add(this.selectedDay[3], "days")
        .format("dddd D MMMM YYYY");
    },
    thereIsAtLeastOneFilterBadge: function () {
      return (
        (this.filters.status !== undefined && this.filters.status !== null) ||
        (this.filters.service !== undefined && this.filters.service !== null) ||
        (this.filters.prestazione !== undefined &&
          this.filters.prestazione !== null) ||
        this.filters.notCanceled === true
      );
    },
  },
  watch: {
    "filters.to": function (newValue, oldValue) {
      if (!oldValue) return;
      const newDate = this.parseDateString(newValue);

      this.selectedMonth = newDate.getMonth();

      this.selectedYear = newDate.getFullYear();
    },
    filters: {
      handler(value) {
        if (value.service !== "prestazione") {
          this.selectedPrestazione = null;
          this.pickedHSFilterName = null;
          this.pickHSFilterName = null;
          this.filters.prestazione = undefined;
        }
        if (value.notCanceled == true) {
          this.filters.status = undefined;
        }
        if (value.cf) {
          if (value.cf.length == 0 || value.cf.length == 16) {
            this.fetchData();
          }
        }
        if (value.surname) {
          if (value.surname.length == 0 || value.surname.length >= 3) {
            this.fetchData();
          }
        }
      },
      deep: true,
    },
    pickedView: function (nextValue, prevValue) {
      //se si va a un mese si va al mese a cui appartiene quel giorno
      if (nextValue === "month") {
        if (prevValue === "day") {
          this.start = moment(this.start)
            .add(this.selectedDay[3], "days")
            .startOf("month");
          this.end = moment(this.start).endOf("month");
        } else {
          this.start = moment(this.start).startOf("month");
          this.end = moment(this.start).endOf("month");
        }
        this.pickedMonth = Number(moment(this.start).month()) + 1;

        this.filters.from = moment(this.start).format("D/M/YY");
        this.filters.to = moment(this.end).format("D/M/YY");
        this.fetchData();
      }

      //se si va da un mese a settimana si torna alla settimana corrente
      if (prevValue == "month" && nextValue == "week") {
        this.prenotazioni = [];
        this.fields = [];
        this.start = moment().startOf("week");
        this.end = moment().endOf("week");
        this.filters.from = moment().startOf("week").format("D/M/YY");
        this.filters.to = moment().endOf("week").format("D/M/YY");
        this.selectedDay = "day0";
        this.setupCalendar();
      }

      this.previousView = prevValue;
    },
    statusFilter: {
      deep: true,
      handler(newVal) {
        this.filters.status = newVal;
        this.fetchData();
      },
    },
    serviceFilter: {
      deep: true,
      handler(newVal) {
        this.filters.service = newVal;
        this.fetchData();
      },
    },
    prestazioneFilter: {
      deep: true,
      handler(newVal) {
        this.filters.prestazione = newVal;
        this.fetchData();
      },
    },

    searchFilter: {
      deep: true,
      handler(newVal) {
        this.filters.search = newVal;
        this.fetchData();
      },
    },
    cfFilter: {
      deep: true,
      handler(newVal) {
        this.filters.cf = newVal;
        this.fetchData();
      },
    },
    compactView: {
      handler(newVal) {
        this.allVisible = !newVal;
      },
    },
    cfSearch: function (val) {
      if (val?.length === 16) {
        this.filters.cf = val.toUpperCase();
        // this.fetchData()
      }
      if (this.filters?.cf && val?.length !== 16) {
        this.filters.cf = undefined;
        this.fetchData();
      }
    },
    surnameSearch: function (val) {
      if (val?.length >= 3) {
        this.filters.surname = val;
        // this.fetchData()
      }
      if (this?.filters?.surname && !val) {
        this.filters.surname = undefined;
        this.fetchData();
      }
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    closeModal() {
      this.$bvModal.hide("modal-resdetails-calendario");
    },
    getColorClass(status) {
      return this.colorLegend.find((el) => el.val === status).color;
    },
    getBadgeLabel(status) {
      return this.filters.status;
    },
    resetStatusFilter() {
      this.filters.status = undefined;
      this.fetchData();
    },
    resetServiceFilter() {
      this.filters.service = undefined;
      this.fetchData();
    },
    resetPrestazioneFilter() {
      this.pickedHSFilterName = null;
      this.filters.prestazione = undefined;
      this.filters.prestazioneHash = undefined;
      this.fetchData();
    },
    resetNotCanceledFilter() {
      this.filters.notCanceled = undefined;
      this.fetchData();
    },
    setCurrentReserv(obj) {
      this.currentReserv = obj;
      this.$bvModal.show("modal-resdetails-calendario");
    },
    showDayDetail(day) {
      this.selectedDay = "day" + day;
      this.pickedView = "day";
    },
    setupCalendar() {
      var fields = [];
      fields.push({
        key: "time",
        label: " ",
        tdClass: "text-medium-grey mb-0 ",
      });
      for (let x = 0; x <= 6; x++) {
        let startDay = moment(this.start);
        let todayDay = moment(this.today).format("DD/M");
        let date = startDay.add(x, "days").format("DD/M");
        let variant = undefined;
        if (todayDay === date) {
          variant = "spl-blue";
        }
        let newField = {
          key: "day" + parseInt(x),
          label: date,
          variant: variant,
          trClass: "b-table-rows",
        };
        fields.push(newField);
      }
      this.fields = fields;
      this.fetchData();
    },
    navigateToSingleDay(day) {
      this.pickedView = "day";
      this.startCalendarWithDate(day, true);
    },
    startCalendarWithDate(startingDate, navigateFromMonth = false) {
      this.start = moment(startingDate).startOf("week");
      this.end = moment(startingDate).endOf("week");
      this.filters.from = moment(startingDate).startOf("week").format("D/M/YY");

      this.filters.to = moment(startingDate).endOf("week").format("D/M/YY");

      if (this.startWithDate || navigateFromMonth) {
        this.showDayDetail(
          Math.abs(this.start.diff(moment(startingDate), "days"))
        );
      }
      this.setupCalendar();
    },
    fetchData() {
      const month = this.pickedView === "month";
      var self = this;
      reservationService
        .fetchCalendar(this.filters, month)
        .then(function (response) {
          self.prenotazioni = response.data.data;
        })
        .catch(function (error) {
          self.errorAlert("non è stato possibile");
          //
        });
    },
    setPrev() {
      this.currentReserv = null;
      if (this.pickedView === "week") {
        this.start.subtract(1, "weeks");
        this.end.subtract(1, "weeks");
        this.filters.from = moment(this.start).startOf("week").format("D/M/YY");
        this.filters.to = moment(this.end).endOf("week").format("D/M/YY");
        this.setupCalendar();
      } else if (this.pickedView === "month") {
        if (this.pickedMonth === 1) {
          this.pickedMonth = 12;
        } else {
          this.pickedMonth = this.pickedMonth - 1;
        }
        this.start.subtract(1, "months");
        this.end.subtract(1, "months");
        this.filters.from = moment(this.start)
          .startOf("month")
          .format("D/M/YY");
        this.filters.to = moment(this.end).endOf("month").format("D/M/YY");
        this.fetchData();
      }
      //  if (this.pickedView === "day") {
      //   this.filters.from = moment(this.filters.from, "D/M/YY")
      //     .subtract(1, "days")
      //     .format("D/M/YY")
      //   if (this.selectedDay.at(3) === "0") {
      //     this.start.subtract(1, "weeks")
      //     this.end.subtract(1, "weeks")
      //     this.filters.from = moment(this.start).format("D/M/YY")
      //     this.filters.to = moment(this.end).format("D/M/YY")
      //     this.setupCalendar()
      //     this.selectedDay = "day6"
      //   } else {
      //     this.selectedDay = "day" + (parseInt(this.selectedDay.at(3)) - 1)
      //   }
      // }
    },
    setPost() {
      this.currentReserv = null;
      if (this.pickedView === "week") {
        this.start.add(1, "weeks");
        this.end.add(1, "weeks");
        this.filters.from = moment(this.start).startOf("week").format("D/M/YY");
        this.filters.to = moment(this.end).endOf("week").format("D/M/YY");
        this.setupCalendar();
      } else if (this.pickedView === "month") {
        if (this.pickedMonth === 12) {
          this.pickedMonth = 1;
        } else {
          this.pickedMonth = this.pickedMonth + 1;
        }
        this.start.add(1, "months");
        this.end.add(1, "months");
        this.filters.from = moment(this.start)
          .startOf("month")
          .format("D/M/YY");
        this.filters.to = moment(this.end).endOf("month").format("D/M/YY");
        this.fetchData();
      }

      // if (this.pickedView === "day") {
      //   this.filters.from = moment(this.filters.from, "D/M/YY")
      //     .add(1, "days")
      //     .format("D/M/YY")
      //   if (this.selectedDay.at(3) === "6") {
      //     this.start.add(1, "weeks")
      //     this.end.add(1, "weeks")
      //     this.filters.from = moment(this.start).format("D/M/YY")
      //     this.filters.to = moment(this.end).format("D/M/YY")
      //     this.setupCalendar()
      //     this.selectedDay = "day0"
      //   } else {
      //     this.selectedDay = "day" + (parseInt(this.selectedDay.at(3)) + 1)
      //   }
      // }
    },
    evadiThis(hash = null) {
      //
      hash = this.evasione.hash;
      var self = this;
      reservationService
        .closeOrder(this.evasione)
        .then(function (response) {
          self.$refs["modal-evasione"].hide();
          self.$refs["modal-resdetails-calendario"].hide();
          self.evasione.details = null;
          self.evasione.hash = null;
          window.scrollTo(0, 0);
          self.successAlert("prenotazione evasa con successo");
          //
          self.fetchData();
        })
        .catch(function (error) {
          self.$refs["modal-evasione"].hide();
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile evadere la prenotazione");
          //
        });
    },
    openEvadi(item) {
      this.toEvadere = item;
      this.evasione.hash = item.hashid;
      this.$refs["modal-evasione"].show();
    },
    setPrestazioneFilter(selectedPrestazione = null) {
      this.selectedPrestazione = selectedPrestazione;
      this.filters.prestazioneHash = this.selectedPrestazione?.hashid ?? null;
      this.pickedHSFilterName = this.selectedPrestazione?.name ?? null;
      this.fetchData();
    },
    // searchPrestazione(selectedPrestazione = null) {
    //   if (selectedPrestazione) {
    //     this.selectedPrestazione = selectedPrestazione;
    //   }
    //   this.filters.prestazione = this.selectedPrestazione
    //     ? this.selectedPrestazione.name
    //     : undefined;
    //   // this.fetchData()
    // },
    handleStatusUpdate(statusData) {
      var self = this;
      if (!statusData.confirmed) {
        var form = statusData.message ? { message: statusData.message } : {};
        reservationService
          .companyCancelOrReject(
            form,
            statusData.action,
            this.currentReserv.hashid
          )
          .then(function (re) {
            self.$bvModal.hide("modal-resdetails-calendario");
            window.scrollTo(0, 0);
            self.successAlert("Richiesta aggiornata con successo");
            self.fetchData();
          })
          .catch(function (error) {
            self.$bvModal.hide("modal-resdetails-calendario");
            window.scrollTo(0, 0);
            self.errorAlert("non è stato possibile effettuare l'operazione");
          });
      } else {
        reservationService
          .companyConfirmOrder(this.currentReserv.hashid)
          .then(function (re) {
            window.scrollTo(0, 0);
            self.successAlert("Richiesta aggiornata con successo");
            self.$bvModal.hide("modal-resdetails-calendario");
            self.fetchData();
          })
          .catch(function (error) {
            window.scrollTo(0, 0);
            self.errorAlert("non è stato possibile effettuare l'operazione");
            self.$bvModal.hide("modal-resdetails-calendario");
          });
      }
    },
    sendOrderResponse(data) {
      var form = {
        hashid: this.currentReserv.hashid,
        company_notes: data.company_notes,
        data_inizio: data.newDateTime,
        order: data.detail,
        status: 5,
      };
      //
      var self = this;
      reservationService
        .updateReservation(form)
        .then(function (response) {
          //
          self.$bvModal.hide("modal-resdetails-calendario");
          window.scrollTo(0, 0);
          self.successAlert("risposta inviata con successo");
          self.fetchData();
        })
        .catch(function (error) {
          //
          self.$bvModal.hide("modal-resdetails-calendario");
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile inviare la risposta");
        });
    },
    handleUpdateRequest() {
      this.$bvModal.hide("modal-resdetails-calendario");
      this.successAlert("richiesta inviata con successo");
      this.fetchData();
    },
    updateResOperator(operatorHash) {
      var form = {
        hashid: this.currentReserv.hashid,
        company_user_id: operatorHash,
      };
      // console.log(form);
      var self = this;
      reservationService
        .updateReservation(form)
        .then(function (response) {
          // console.log(response);
          // self.$bvModal.hide("modal-resdetails-prenotazioni")
          window.scrollTo(0, 0);
          self.successAlert("preferenza operatore aggiornata con successo");
          self.currentReserv.operator = response.data.data.operator;
          self.fetchData();
        })
        .catch(function (error) {
          // console.log(error);
          // self.$bvModal.hide("modal-resdetails-prenotazioni")
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile salvare la modifica");
        });
    },
    parseDateString(dateString) {
      const parts = dateString.split("/");
      let year = parseInt(parts[2], 10);

      year = year < 100 ? 2000 + year : year;

      return new Date(year, parts[1] - 1, parts[0]);
    },
    onMonthYearChange() {
      const firstDayOfMonth = new Date(
        this.selectedYear,
        this.selectedMonth,
        1
      );
      if (this.pickedView !== "month") {
        this.start = moment(firstDayOfMonth).startOf("week");
        this.end = moment(firstDayOfMonth).endOf("week");
        this.filters.from = moment(this.start).startOf("week").format("D/M/YY");
        this.filters.to = moment(this.end).endOf("week").format("D/M/YY");
      } else {
        this.start = moment(firstDayOfMonth).startOf("month");
        this.end = moment(firstDayOfMonth).endOf("month");
        this.filters.from = moment(this.start)
          .startOf("month")
          .format("D/M/YY");
        this.filters.to = moment(this.end).endOf("month").format("D/M/YY");
        this.pickedMonth = this.selectedMonth + 1;
      }
      this.setupCalendar();
    },
  },
  created() {
    this.today = moment();
    this.month = moment().month();
    this.filters.status = this.statusFilter;
    this.filters.service = this.serviceFilter;
    this.filters.search = this.searchFilter;

    let startingDate = this.startWithDate ? this.startWithDate : moment();
    this.startCalendarWithDate(startingDate);
    const currentYear = moment(startingDate).year();
    for (let i = 2023; i <= currentYear + 3; i++) {
      this.years.push(i);
    }
  },
  mounted() {
    if (this.servicerif) {
      this.allVisible = true;
      if (this.servicerif == 1) {
        this.filterOptions.service = [
          { label: "prestazione", value: "prestazione" },
          { label: "prodotto", value: "prodotto" },
        ];
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.switch-week-button:nth-child(1) {
  padding: 0.3rem 1rem;
  margin-left: 0.2rem;
}

.switch-week-button:last-child {
  padding: 0.3rem 1rem;
  margin-right: 0.2rem;
}

.modal-xl {
  min-width: 80vw !important;
}

#calendar-legend {
  font-size: 0.8rem;
}

// tr {
//   border-bottom: 2px solid $spl-blue !important;
//   // border-bottom: 2px solid $font-light-grey !important;
// }
// tr:last-of-type {
//   border-bottom: 0 solid $spl-blue !important;
// }
td[role="cell"] {
  max-width: 30px !important;
  background-color: blue !important;
}

.rifiutato-annullato {
  color: rgba(253, 39, 75, 1) !important;
  border-radius: 3px;
}

.rifiutato-annullato-bg {
  background-color: rgba(253, 39, 75, 0.1) !important;
}

.inoltrato-in-attesa {
  color: rgba(255, 151, 18, 1) !important;
}

.inoltrato-in-attesa-bg {
  background-color: rgba(255, 151, 18, 1) !important;
}

.confermato {
  color: rgba(32, 80, 114, 1) !important;
}

.confermato-bg {
  background-color: rgba(32, 80, 114, 1) !important;
}

.evaso {
  color: rgba(15, 148, 180, 1) !important;
}

.evaso-bg {
  background-color: rgba(15, 148, 180, 1) !important;
}

.nostat {
  color: #ffffff !important;
}

.slidefade-enter-active {
  transition: all 0.3s ease;
}

.slidefade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slidefade-enter,
.slidefade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

#pick-view-select {
  padding-left: 10px;
  padding-right: 0px;
  font-size: 1.2rem;
}

@media (min-width: 576px) {
  #pick-view-select {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  #pick-view-select {
    font-size: 0.9rem;
    max-width: 135px;
  }
}

@media (min-width: 992px) {
  #pick-view-select {
    font-size: 1.1rem;
  }
}

@media (min-width: 1200px) {
  #pick-view-select {
    font-size: 1.2rem;
  }
}
</style>
