<template>
  <p class="d-flex justify-content-start mt-4 col">
    <b-button
      :disabled="disableBack"
      variant="outline-secondary"
      class="mr-3"
      @click="$emit('goBack')"
      :style="{ visibility: buttonsVisibilityHidden ? 'hidden' : 'visible' }"
      >Torna indietro</b-button
    >
    <b-button
      :disabled="disableForward"
      variant="secondary"
      @click="$emit('goOn')"
      :style="{ visibility: buttonsVisibilityHidden ? 'hidden' : 'visible' }"
      >Continua</b-button
    >
  </p>
</template>

<script>
export default {
  props: {
    disableBack: {
      default: false
    },
    disableForward: {
      default: false
    },
    buttonsVisibilityHidden: {
      default: false
    }
  }
}
</script>
