<template>
  <b-row cols="1" class="">
    <b-col class="px-0" v-if="company && list">
      <b-form-group
        class="text-left"
        :label="hidetitles ? 'Scegli prestazione*' : 'Scegli prestazione'"
        label-for="subtype"
        :description="
          company.prestazioni_sanitarie.length > 0
            ? ''
            : 'Nessuna prestazione disponibile presso la sede selezionata'
        "
      >
        <b-form-select
          v-capitalize-first-select
          v-if="excludeByDisponibilita"
          name="subtype"
          class="mx-auto"
          v-model="prestazione"
          required
          @change="emitPrestazione"
        >
          <template v-for="(prestazione, name) in list">
            <template v-if="prestazione.proxy_scheduling">
              <b-form-select-option
                :key="name"
                v-if="prestazione.active === 1"
                :value="prestazione"
                >{{ prestazione.name }}
                {{ getServicePrice(prestazione) }}</b-form-select-option
              >
            </template>
            <template v-else>
              <b-form-select-option
                :key="name"
                v-if="prestazione.active === 1"
                :value="prestazione"
                :disabled="
                  !prestazione.disponibilita ||
                  prestazione.disponibilita.length < 1
                "
                >{{ prestazione.name }}
                {{
                  !prestazione.disponibilita ||
                  prestazione.disponibilita.length < 1
                    ? "-non disponibile"
                    : getServicePrice(prestazione)
                }}</b-form-select-option
              >
            </template>
          </template>
          <b-form-select-option :value="null" disabled></b-form-select-option>
        </b-form-select>
        <b-form-select
          v-capitalize-first-select
          v-else
          name="subtype"
          class="mx-auto"
          v-model="prestazione"
          required
          @change="emitPrestazione"
        >
          <template v-for="(prestazione, name) in list">
            <b-form-select-option
              :key="name"
              v-if="prestazione.active === 1"
              :value="prestazione"
              >{{ prestazione.name }}
            </b-form-select-option>
          </template>
          <b-form-select-option :value="null" disabled></b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-col>
    <b-col v-else-if="company">
      <b-form-group
        label="Scegli prestazione"
        label-for="subtype"
        class="text-left"
        :description="
          company.prestazioni_sanitarie.length > 0
            ? ''
            : 'Nessuna prestazione disponibile presso la sede selezionata'
        "
      >
        <b-form-select
          v-capitalize-first-select
          v-if="excludeByDisponibilita"
          name="subtype"
          class="mx-auto"
          v-model="prestazione"
          required
          @change="emitPrestazione"
        >
          <template
            v-for="(prestazione, name) in company.prestazioni_sanitarie"
          >
            <b-form-select-option
              :key="name"
              v-if="prestazione.active === 1"
              :value="prestazione"
              :disabled="
                !prestazione.disponibilita ||
                prestazione.disponibilita.length < 1
              "
              >{{ prestazione.name }}
              {{
                !prestazione.disponibilita ||
                prestazione.disponibilita.length < 1
                  ? "- non disponibile"
                  : ""
              }}</b-form-select-option
            >
          </template>
          <b-form-select-option :value="null" disabled></b-form-select-option>
        </b-form-select>
        <b-form-select
          v-capitalize-first-select
          v-else
          name="subtype"
          class="mx-auto"
          v-model="prestazione"
          required
          @change="emitPrestazione"
        >
          <template
            v-for="(prestazione, name) in company.prestazioni_sanitarie"
          >
            <b-form-select-option
              :key="name"
              v-if="prestazione.active === 1"
              :value="prestazione"
              >{{ prestazione.name }}</b-form-select-option
            >
          </template>
          <b-form-select-option :value="null" disabled></b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import { supportService } from "@/_services"

export default {
  name: "PrestazioneSelection",
  props: ["company", "preselected", "hidetitles", "donotexclude"],
  expose: ["reset"],
  data() {
    return {
      prestazione: null,
      list: null,
      excludeByDisponibilita: true
    }
  },
  watch: {
    preselected: {
      handler(value) {
        this.prestazione = value
      },
      deep: true
    },
    company(val) {
      if (val) {
        this.getFreshData()
      }
    }
  },
  methods: {
    emitPrestazione() {
      this.$emit("selected-prestazione", this.prestazione)
    },
    reset() {
      this.prestazione = null
    },
    getFreshData() {
      const self = this
      supportService
        .getCompanyHealthServices(this.company.hashid)
        .then(function (res) {
          self.list = res.data.data
        })
        .catch(function (err) {
          console.log(err)
        })
    },
    getServicePrice(prestazione) {
      if (prestazione.prezzo > 0) {
        const iva =
          prestazione.iva > 0
            ? " - iva " + prestazione.iva + "% esclusa"
            : " - iva inclusa"
        return (
          "- " +
          prestazione.prezzo.toLocaleString("it-IT", {
            style: "currency",
            currency: "EUR"
          }) +
          iva
        )
      }
      return ""
    }
  },
  created() {
    this.prestazione = this.preselected
    this.getFreshData()
  },
  mounted() {
    if (this.donotexclude) {
      this.excludeByDisponibilita = false
    }
  }
}
</script>
