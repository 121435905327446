var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.notFound)?_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"text-left mb-4",attrs:{"cols":"1"}},[_c('router-link',{staticClass:"text-secondary no__hover",attrs:{"to":{ name: 'familiari', params: { section: 'main' } }}},[_c('b-icon',{attrs:{"icon":"arrow-left-circle-fill","variant":"secondary"}}),_vm._v(" Pazienti")],1)],1),(_vm.notFound)?_c('h5',{staticClass:"font-weight-bolder"},[_vm._v(" Nessun Paziente selezionato ")]):_vm._e()],1):_c('b-container',{staticClass:"pb-5",attrs:{"fluid":""}},[_c('b-row',{staticClass:"text-left mb-4",attrs:{"cols":"1"}},[_c('router-link',{staticClass:"text-secondary no__hover",attrs:{"to":{
        name: 'familiari',
        params: {
          section: 'assistito',
          hash: _vm.$route.query.sub,
        },
      }}},[_c('b-icon',{attrs:{"icon":"arrow-left-circle-fill","variant":"secondary"}}),_vm._v(" Profilo sanitario")],1)],1),(_vm.therapy)?_c('div',{staticClass:"text-left"},[_c('h3',{staticClass:"font-weight-bolder text-center"},[_vm._v("Riepilogo")]),_c('RecapTherapy',{staticClass:"fs-5",attrs:{"therapy":_vm.therapy}}),_c('b-button',{attrs:{"variant":"secondary text-white mx-2"},on:{"click":_vm.downloadRecap}},[_vm._v("Scarica promemoria ")]),_c('b-button',{attrs:{"type":"button","variant":"secondary text-white  mx-2"},on:{"click":function($event){return _vm.$router.push({
          name: 'familiari',
          params: {
            section: 'assistito',
            hash: _vm.$route.query.sub,
          },
        })}}},[_vm._v("Torna al profilo sanitario "),_c('b-icon',{attrs:{"icon":"arrow-right","variant":"secondary"}})],1)],1):_c('NewTherapy',{staticClass:"col-12",attrs:{"subject":_vm.subject},on:{"save-therapy":function($event){return _vm.showRecap($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }