<template>
    <div>
      <b-row>
        <b-col>
          <h4 class="text-left pb-1">Giorni di Indisponibilità</h4>
          <p class="text-justify">
            Gestisci le date per conto della struttura per non ricevere prenotazioni per nessun
            servizio.
          </p>
        </b-col>
        <b-col class="text-right">
          <b-button variant="secondary" @click="$bvModal.show('new-prob')">
            <b-icon icon="plus"></b-icon>
            nuova data
          </b-button>
        </b-col>
      </b-row>
      <b-row
        cols="2"
        cols-md="3"
        cols-lg="4"
        v-if="prohibidates"
        class="puntofarmaCard py-4 px-3"
      >
        <b-col
          v-for="(date, ind) in prohibidates"
          :key="ind"
          class="text-left my-2"
        >
          <b-form-checkbox
            v-model="date.active"
            :name="'date' + ind"
            :value="1"
            :unchecked-value="0"
            @change="updateDateStatus(ind)"
          >
            <strong> {{ date.day }} {{ months[date.month] }}</strong>
            <span class="text-font-grey">{{ date.description }}</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-modal
        id="new-prob"
        hide-footer
        centered
        title="Nuova data di indisponibilità"
        size="lg"
      >
        <b-form @submit.prevent="saveNew" @reset="resetForm">
          <b-row>
            <b-col cols="5">
              <b-form-group label="Giorno:*">
                <b-form-input
                  focused
                  class=" "
                  type="number"
                  v-model="form.day"
                  :step="1"
                  :min="1"
                  :max="31"
                  placeholder="Giorno.."
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Mese:*">
                <b-form-select
                  v-capitalize-first-select
                  class=" "
                  v-model="form.month"
                  placeholder="mese"
                >
                  <b-form-select-option
                    v-for="(month, ind) in months"
                    :value="ind"
                    :key="ind"
                    required
                    >{{ month }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Nome:">
                <b-form-input
                  class=" "
                  type="text"
                  v-model="form.description"
                  placeholder="Occorrenza.."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <p class="mt-2 mx-2">*parametri obbligatori</p>
          <div class="text-right">
            <b-button type="reset" variant="popup-button" class="mr-3"
              >Annulla</b-button
            >
            <b-button type="submit" variant="popup-button">Salva</b-button>
          </div>
        </b-form>
      </b-modal>
    </div>
  </template>
  
  <script>
  import * as moment from "moment/moment"
  import { mapState, mapActions } from "vuex"
  import { companyService } from "@/_services"
  export default {
    name: "ProhibidatesComponentCentroErogatore",
    props: ["company"],
    computed: {
      ...mapState("utente", ["status", "user"]),
      ...mapState({
        alert: (state) => state.alert
      })
    },
    data() {
      return {
        prohibidates: null,
        form: {
          day: 1,
          month: 1,
          active: true,
          description: null
        },
        months: {
          1: "Gennaio",
          2: "Febbraio",
          3: "Marzo",
          4: "Aprile",
          5: "Maggio",
          6: "Giugno",
          7: "Luglio",
          8: "Agosto",
          9: "Settembre",
          10: "Ottobre",
          11: "Novembre",
          12: "Dicembre"
        }
      }
    },
    methods: {
      ...mapActions({
        clearAlert: "alert/clear",
        errorAlert: "alert/error",
        successAlert: "alert/success",
        infoAlert: "alert/info"
      }),
      fetchData() {
        var self = this
        const hashid = this.$route.params.hashid
        companyService
          .getCompanyProhibidates(hashid)
          .then(function (resp) {
            // //console.log(resp);
            self.prohibidates = resp.data.data
          })
          .catch(function (error) {
            self.errorAlert("Non è stato possibile recuperare le sottoscrizioni")
          })
      },
      updateDateStatus(ind) {
        var self = this
        var formData = this.prohibidates[ind]
        companyService
          .updateProhibidate(formData)
          .then(function (res) {
            console.log(res)
            self.fetchData()
            window.scrollTo(0, 0)
            self.successAlert("modifiche salvate correttamente")
          })
          .catch(function (err) {
            console.log(err)
            window.scrollTo(0, 0)
            self.errorAlert("Non è stato possibile salvare le modifiche")
          })
      },
      resetForm() {
        this.form = {
          day: 1,
          month: 1,
          active: true,
          description: null
        }
        this.$bvModal.hide("new-prob")
      },
      saveNew() {
        var self = this
        const hashid = this.company.hashid
        companyService
          .storeCompanyProhibidates(hashid, this.form)
          .then(function (res) {
            // console.log(res)
            self.fetchData()
            self.$bvModal.hide("new-prob")
            window.scrollTo(0, 0)
            self.successAlert("Nuova data salvata correttamente")
            self.resetForm()
            self.fetchData()
          })
          .catch(function (err) {
            console.log(err)
            window.scrollTo(0, 0)
            self.$bvModal.hide("new-prob")
            self.errorAlert("Non è stato possibile salvare la data")
            self.resetForm()
          })
      }
    },
    mounted() {
      this.fetchData()
    },
  }
  </script>
  