<template>
  <b-container fluid class="px-0 text-secondary">
    <b-row class="justify-content-bewteen pb-4" align-v="center">
      <b-col class="align-self-end px-0">
        <h5 class="text-left text-secondary py-1 mb-0 fw-600 mr-5">
          Dispositivi
        </h5>
      </b-col>
      <b-col
        class="pr-0"
        v-if="
          user.permessi.includes('configurare dispositivi utente') || canMonitor
        "
      >
        <p
          class="d-flex justify-content-center my-3 my-md-0 justify-content-md-end"
        >
          <b-button
            class="text-left"
            @click="$bvModal.show('new-patient-device')"
          >
            <b-icon icon="plus"></b-icon> nuovo dispositivo
          </b-button>
        </p>
      </b-col>
    </b-row>
    <b-row class="mt-n2">
      <b-col
        v-if="loaded && (!userDevices || userDevices.length === 0)"
        class="col-12 d-flex justify-content-center mt-3"
      >
        Nessun dispositivo associato all'utente.
      </b-col>
      <template v-else>
        <b-col
          class="col-12 px-0 mt-3"
          v-for="device in userDevices"
          :key="device.hashid"
        >
          <DeviceCard
            @request-data-update="requestDataUpdate($event)"
            @delete-device="deleteDevice($event)"
            @update-product-code="updateProductCode($event)"
            @update-exam-status="updateExamStatus($event)"
            :device="device"
          />
        </b-col>
      </template>
    </b-row>
    <b-modal
      @hidden="handleAnnulla"
      @keyup.esc="handleAnnulla"
      size="xl"
      id="new-patient-device"
      hide-footer
      centered
    >
      <template #modal-title>
        <h4>
          Nuovo dispositivo Utente
          <span v-if="deviceToAdd">
            |
            <span class="text-secondary"> {{ deviceToAdd.name }}</span></span
          >
        </h4>
      </template>
      <b-row cols="1" cols-md="2" cols-xl="3" v-if="!deviceToAdd">
        <b-col>
          <b-row cols="1" class="leveled__rows">
            <b-col
              class="text-center text-white fw-bolder bg-secondary general_border pb-2"
              >Trova dispositivo</b-col
            >
            <b-col class="shadow_1 general_border bg-white">
              <b-input-group class="border-0">
                <b-form-input
                  v-capitalize-first
                  placeholder="Cerca..."
                  style="box-shadow: none; border: none"
                  size="sm"
                  class="shadow_0 mt-1"
                  type="search"
                  v-model="searchFilter"
                ></b-form-input>
                <template #append>
                  <span class="m-2">
                    <b-icon
                      icon="search"
                      variant="secondary"
                      font-scale="1.5"
                    ></b-icon>
                  </span>
                </template>
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-form @submit.prevent="submitNewDevice">
        <transition-group
          tag="div"
          name="list"
          mode="out-in"
          appear
          class="col-12 px-0 d-flex flex-wrap justify-content-center align-items-stretch"
        >
          <div
            v-if="
              !filteredAssignableDevices ||
              filteredAssignableDevices.length === 0
            "
            key="no-devices"
          >
            <p class="mt-2">
              Nessun dispositivo disponibile per l'assegnazione.
            </p>
          </div>
          <template v-else>
            <div
              class="my-1 col-12 col-md-4 col-lgx-3 px-0 px-md-1"
              v-for="device in filteredAssignableDevices"
              :key="device.hashid"
            >
              <b-button
                block
                :variant="
                  deviceToAdd && deviceToAdd.hashid == device.hashid
                    ? 'secondary'
                    : 'outline-secondary'
                "
                v-if="!deviceToAdd"
                class="text-break m-2 text-capitalize font-weight-bold py-4 h-100 min__h__ril"
                @click="deviceToAdd = device"
                >{{ device.name }} - {{ device.model_code }}</b-button
              >
            </div>
          </template>
          <div
            v-if="deviceToAdd"
            class="text-left fs-6 text-font-grey mx-auto col-12 px-0"
            key="forminputs"
          >
            <b-row>
              <b-col class="px-0 col-12">
                <b-form-group
                  label="Codice Prodotto"
                  class="col-12 text-left text-capitalize text-secondary"
                >
                  <b-form-input
                    v-capitalize-first
                    class="col-12 col-md-6 col-lg-4"
                    v-model="form.product_code"
                    required
                    autofocus
                    placeholder="Inserisci codice*"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="mx-auto text-right col-12 px-0 px-md-2">
              <b-button
                type="button"
                variant="popup-button py-2 px-4 mr-3"
                @click.prevent="handleAnnulla"
                >Indietro</b-button
              >
              <b-button type="submit" variant="popup-button px-4 py-2"
                >Salva
              </b-button>
            </div>
          </div>
        </transition-group>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { monitoringService } from "@/_services"
import DeviceCard from "@/components/profile/sanitario/DeviceCard.vue"

export default {
  name: "PatientDevicesComponent",
  components: { DeviceCard },
  props: {
    subjectHash: {
      type: String,
      required: true
    },
    asCaregiver: {
      type: Boolean,
      default: false
    },
    canMonitor: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"]),
    assignableDevices() {
      return this.availableDevices.filter(
        (device) =>
          !this.userDevices.find(
            (userDevice) => userDevice.device.hashid == device.hashid
          )
      )
    },
    filteredAssignableDevices() {
      return this.assignableDevices.filter((device) =>
        device.name.toLowerCase().includes(this.searchFilter.toLowerCase())
      )
    }
  },

  watch: {},
  data() {
    return {
      availableDevices: [],
      deviceToAdd: null,
      searchFilter: "",
      userDevices: [],
      form: {
        device_id: null,
        product_code: null
      },
      loaded: false
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    handleAnnulla() {
      this.deviceToAdd = null
      this.form = {
        device_id: null,
        product_code: null
      }
    },
    getUserDevices() {
      let serviceCall =
        this.user.type !== 0 || this.asCaregiver
          ? monitoringService.getUserDevices(this.subjectHash)
          : monitoringService.getOwnDevices()

      serviceCall
        .then((response) => {
          // console.log(response)
          this.userDevices = response.data
          this.loaded = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getAvailableDevices() {
      monitoringService
        .getAvailableDevices()
        .then((response) => {
          console.log(response)
          this.availableDevices = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    submitNewDevice() {
      this.$bvModal.hide("new-patient-device")
      this.form.device_id = this.deviceToAdd.hashid
      monitoringService
        .storeNewUserDevice(this.subjectHash, this.form)
        .then((response) => {
          console.log(response)
          this.successAlert("Dispositivo aggiunto correttamente")
          this.getUserDevices()
          this.getAvailableDevices()
          this.deviceToAdd = null
        })
        .catch((error) => {
          console.log(error)
          this.errorAlert("Non è stato possibile aggiungere il dispositivo")
        })
    },
    deleteDevice(device) {
      monitoringService
        .deleteUserDevice(device.hashid)
        .then((response) => {
          console.log(response)
          this.successAlert("Dispositivo rimosso correttamente")
          this.getUserDevices()
          this.getAvailableDevices()
        })
        .catch((error) => {
          console.log(error)
          this.errorAlert("Non è stato possibile rimuovere il dispositivo")
        })
    },
    updateProductCode(updateData) {
      console.log("updateProductCode", updateData)
      monitoringService
        .updateUserDeviceProductCode(
          updateData.deviceHash,
          updateData.newProductCode
        )
        .then((response) => {
          console.log(response)
          this.successAlert("Codice prodotto aggiornato correttamente")
          this.getUserDevices()
          this.getAvailableDevices()
        })
        .catch((error) => {
          console.log(error)
          this.errorAlert("Non è stato possibile aggiornare il codice prodotto")
        })
    },
    updateExamStatus(updateData) {
      console.log("updateExamStatus", updateData)
      monitoringService
        .updateUserDeviceExamStatus(
          updateData.deviceHash,
          updateData.examHash,
          updateData.status
        )
        .then((response) => {
          console.log(response)
          let successMessage = updateData.status ? "abilitato" : "disabilitato"
          this.successAlert(`Esame ${successMessage} correttamente`)
          this.getUserDevices()
          this.getAvailableDevices()
        })
        .catch((error) => {
          console.log(error)
          this.errorAlert(
            "Non è stato possibile aggiornare lo stato dell'esame"
          )
        })
    },
    requestDataUpdate(hash) {
      monitoringService
        .requestDataUpdate(hash)
        .then((response) => {
          console.log(response)
          this.successAlert(
            "Aggiornamento richiesto. Riceverai una notifica ad operazione completata"
          )
          this.getUserDevices()
          this.getAvailableDevices()
        })
        .catch((error) => {
          console.log(error)
          this.errorAlert("Non è stato possibile richiedere l'aggiornamento")
        })
    }
  },
  mounted() {},
  created() {
    this.getUserDevices()
    if (
      this.user.permessi.includes("configurare dispositivi utente") ||
      this.canMonitor
    ) {
      this.getAvailableDevices()
    }
  }
}
</script>
<style scoped>
.min__h__ril {
  min-height: 10vh;
}

input.input-with-right-append.form-control {
  border-right: none !important;
  border-left: 1px solid $border-color !important;
  border-top: 1px solid $border-color !important;
  border-bottom: 1px solid $border-color !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: $input-border-radius !important;
  border-bottom-left-radius: $input-border-radius !important;
  box-shadow: none !important;
}
</style>
