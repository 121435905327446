var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white general_border py-2 mb-3 mt-4"},[_c('b-row',{staticClass:"justify-content-between"},[_c('b-col'),_c('b-col',{staticClass:"text-right mr-3"},[(!_vm.showCheckbox)?_c('b-button',{key:"fg",attrs:{"size":"sm","variant":"outline-secondary a__spacing"},on:{"click":function($event){_vm.showCheckbox = true}}},[_vm._v(" Evadi una o più richieste")]):_c('div',{key:"sg"},[(_vm.selectedReservations.length > 0)?_c('b-button',{attrs:{"variant":"secondary mx-2","size":"sm"},on:{"click":function($event){return _vm.$refs['modal-confirm'].show()}}},[_vm._v(" Evadi selezionate")]):_vm._e(),_c('b-button',{attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":_vm.cancelSelection}},[_vm._v("Annulla")])],1)],1)],1),_c('b-row',{staticClass:"mx-0 mt-2 mb-4"},_vm._l((_vm.reservations),function(res,ind){return _c('b-col',{key:ind,staticClass:"text-left border-bottom border-spl-blue",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{staticClass:"col-2 col-md-1"},[_c('span',{staticClass:"text-medium-grey"},[_vm._v(_vm._s(res.time))])]),_c('b-col',{staticClass:"px-1"},_vm._l((res[_vm.day]),function(el,ind){return _c('b-row',{key:ind},_vm._l((Object.values(el)),function(element,k){return _c('b-row',{key:k + k,staticClass:"point my-1 d-flex justify-content-between align-items-center col-12",class:element.status > 0
                  ? _vm.colors[element.status]
                  : 'rifiutato-annullato  disabled__card border-0',attrs:{"cols":_vm.$root.isMobile ? '1' : '4'},on:{"click":function($event){return _vm.showDetails(element)}}},[_c('span',{staticClass:"text-left"},[_c('b-icon',{staticClass:"mr-2",attrs:{"font-scale":"1.2","icon":_vm.icone[element.icona]}}),_c('b-icon',{attrs:{"icon":"circle-fill"}}),_c('span',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(_vm._f("moment")(element.data_inizio,"HH:mm"))+" ")]),(element.etichetta)?_c('strong',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(element.etichetta)+" ")]):_vm._e(),(element.unreadMessages)?_c('span',[_c('b-icon',{attrs:{"icon":"chat-dots-fill","font-scale":"1.3"}}),_c('b-badge',{staticClass:"fs_badge_xs align-self-start",attrs:{"variant":"danger"}},[_vm._v(_vm._s(element.unreadMessages))])],1):_vm._e()],1),(!element.subjectable)?[_c('span',{staticClass:"text-font-grey"},[_vm._v("**utenza rimossa**")])]:[_c('span',{class:_vm.$root.isMobile ? 'text-left' : 'text-center'},[_c('span',{staticClass:"text-capitalize text-font-grey"},[_vm._v(_vm._s(element.subjectable.anagrafica.name)+" "+_vm._s(element.subjectable.anagrafica.surname))]),_c('span',{staticClass:"text-uppercase text-font-grey"},[_vm._v(" "+_vm._s(element.subjectable.anagrafica.cf))])]),_c('span',{class:_vm.$root.isMobile ? 'text-left' : 'text-right'},[(element.subjectable.anagrafica.tel_number)?_c('span',{staticClass:"text-font-grey"},[_vm._v(" tel. "+_vm._s(element.subjectable.anagrafica.tel_number))]):_vm._e()])],_c('span',{staticClass:"text-left text-lg-right point justify-self-end"},[_c('span',{staticClass:"d-flex justify-content-end flex-wrap"},[_c('span',{staticClass:"mr-2"},[_vm._v(" #"+_vm._s(element.codice_richiesta)+" ")]),(!_vm.showCheckbox)?[_c('b-iconstack',{attrs:{"font-scale":"1.3"}},[_c('b-icon',{attrs:{"stacked":"","icon":"circle-fill","variant":"mdm-light-grey"}}),_c('b-icon',{attrs:{"stacked":"","icon":"info","variant":"secondary","scale":"1"}})],1)]:(
                      element.status &&
                      element.status > 0 &&
                      element.status !== 4
                    )?_c('b-form-checkbox',{attrs:{"size":"lg","value":element.hashid,"name":"check-button"},model:{value:(_vm.selectedReservations),callback:function ($$v) {_vm.selectedReservations=$$v},expression:"selectedReservations"}}):_c('b-iconstack',{attrs:{"font-scale":"1.3"}},[_c('b-icon',{attrs:{"stacked":"","icon":"circle-fill","variant":"transparent"}}),_c('b-icon',{attrs:{"stacked":"","icon":"transparent","variant":"white","scale":"1"}})],1)],2)])],2)}),1)}),1)],1)],1)}),1),_c('b-modal',{ref:"modal-confirm",attrs:{"size":"xl","hide-footer":"","centered":"","title":"Conferma operazione"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" Sei sicuro di voler evadere le prenotazioni selezionate? ")]),_c('div',[_c('b-form-group',{attrs:{"label":"Allega una nota:"},scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"value":6},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}},[_vm._v("nessuna nota ")]),_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"value":5},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}},[_vm._v("aggiungi nota ")]),(_vm.message == 5)?_c('b-form-input',{directives:[{name:"capitalize-first",rawName:"v-capitalize-first"}],staticClass:"mt-2",attrs:{"name":"freeText","type":"text","placeholder":"Nota...","autofocus":""},model:{value:(_vm.freeText),callback:function ($$v) {_vm.freeText=$$v},expression:"freeText"}}):_vm._e()]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end mt-3"},[_c('b-button',{attrs:{"type":"button","variant":"popup-button mr-3"},on:{"click":function($event){return _vm.$refs['modal-confirm'].hide()}}},[_vm._v("No, chiudi ")]),_c('b-button',{attrs:{"type":"submit","variant":"popup-button ml-2"},on:{"click":_vm.submitEvadi}},[(_vm.submitting)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v("Sì, procedi")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }