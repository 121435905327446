<template>
  <div v-if="isActive === null">
    <p class="text-center"><b-spinner></b-spinner></p>
  </div>
  <div v-else-if="isActive === true" class="px-2 pb-2">
    <h4 class="text-left mt-1 page-title">Pianifica recall paziente</h4>
    <p class="text-left text-medium-grey">
      Imposta una o più notifiche di promemoria per ricordare ad un paziente di
      prenotare una prestazione.
    </p>
    <b-row v-if="showDone" cols="1" class="text-center my-4">
      <h4 class="">Setting notifiche completato</h4>
      <b-button
        variant="secondary"
        class="col-10 col-md-5 mx-auto my-2"
        @click="doReset"
      >
        Nuova pianificazione</b-button
      >
    </b-row>

    <b-form v-else @submit.prevent="saveSchedule">
      <b-row cols="1" cols-lg="2">
        <b-col class="pl-0">
          <h4 class="text-left">Dati del paziente</h4>
          <!-- <p class="text-medium-grey mb-0 text-justify">
            Inserisci le informazioni anagrafiche del paziente
          </p> -->
          <b-overlay :show="searchingUser">
            <b-row
              cols="1"
              class="align-items-center align-content-center flex-wrap mb-2 puntofarmaCard py-4 px-4"
            >
              <b-col class="px-0">
                <b-row cols="1" cols-lg="2">
                  <b-col class="mb-2">
                    <b-form-group
                      label="Codice fiscale*"
                      label-for="cf"
                      class="text-left"
                    >
                      <b-form-input
                        @keyup="searchUser"
                        class="text-uppercase"
                        name="cf"
                        type="text"
                        placeholder="Codice fiscale..."
                        maxlength="16"
                        minlength="16"
                        v-model="anagrafica.cf"
                        :state="anagrafica.cf == null ? null : validCf"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="mb-2">
                    <b-form-group
                      label="Email*"
                      label-for="email"
                      class="text-left mx-auto"
                    >
                      <b-form-input
                        v-model="anagrafica.email"
                        placeholder="Email..."
                        class=" "
                        name="email"
                        :state="anagrafica.email == null ? null : validEmail"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="mb-2">
                    <b-form-group
                      label="Nome*"
                      label-for="name"
                      class="text-left mx-auto"
                    >
                      <b-form-input
                        v-capitalize-first
                        class="text-capitalize"
                        name="name"
                        type="text"
                        placeholder="Nome..."
                        v-model="anagrafica.name"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="mb-2">
                    <b-form-group
                      label="Cognome*"
                      label-for="surname"
                      class="text-left mx-auto"
                    >
                      <b-form-input
                        class="text-capitalize"
                        name="surname"
                        type="text"
                        placeholder="Cognome..."
                        v-model="anagrafica.surname"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="mb-2">
                    <b-form-group
                      label="Numero di cellulare*"
                      label-for="tel_number"
                      class="text-left mx-auto"
                    >
                      <b-form-input
                        class=" "
                        name="tel_number"
                        type="text"
                        placeholder="Telefono..."
                        v-model="anagrafica.tel_number"
                        minlength="9"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-overlay>
        </b-col>
        <b-col class="pl-0">
          <h4 class="text-left">Dettaglio Recall</h4>
          <!-- <p class="text-medium-grey mb-0 text-justify">
            Specifica la data in cui si prevede che il paziente debba tornare e
            la prestazione che dovrebbe prenotare.
          </p> -->
          <b-row
            class="text-left align-items-center align-content-center flex-wrap mb-2 puntofarmaCard py-4 px-4"
            cols="1"
            cols-xl="2"
          >
            <b-col class="mb-2">
              <b-form-group class="">
                <label class="" for="">Data follow up*</label>
                <b-form-input
                  class=" "
                  name="end_at"
                  type="date"
                  v-model="form.end_at"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col class="mb-2">
              <!-- <PrestazioneSelection
                ref="prestazioneSelectionRef"
                :company="selectedCompany"
                v-on:selected-prestazione="selectHealthService($event)"
                :hidetitles="true"
                :donotexclude="true"
              /> -->
              <SelectCompanyHealthServices
                v-model="selectedPrestazioneHash"
                @input="selectHealthService"
                :showDescriptions="true"
                :startWithHash="pickedHashFromRoute"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template v-if="selectedPrestazioneHash">
        <h4 class="text-left mt-3">Pianificazione</h4>
        <p class="text-medium-grey text-justify">
          Il sistema invierà al paziente una notifica in prossimità della data
          prevista per il follow up, invitandolo a prenotare la prestazione.
          Seleziona una o più opzioni relative a frequenza e canale di notifica,
          e indica la fascia oraria di preferenza.
        </p>
        <div class="text-left puntofarmaCard p-lg-4 p-md-3 p-2">
          <b-row cols="1" cols-md="2" cols-lg="3" cols-xl="3" class="">
            <b-col class="mb-1">
              <b-form-group
                label="Invia notifica*"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox-group
                  id="checkbox-freq"
                  v-model="form.schedule"
                  :options="scheduleOptions"
                  :aria-describedby="ariaDescribedby"
                  :state="form.schedule.length > 3 ? false : null"
                  name="checkbox-freq"
                  stacked
                ></b-form-checkbox-group>
                <span
                  :class="
                    form.schedule.length > 3 ? 'text-danger' : 'text-font-grey'
                  "
                  >**min 1 opzione, max 3 opzioni</span
                >
              </b-form-group>
            </b-col>
            <b-col class="mb-1">
              <b-form-group label="Via*" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="checkbox-channel"
                  v-model="form.channels"
                  :options="channels"
                  :aria-describedby="ariaDescribedby"
                  name="checkbox-channel"
                  stacked
                ></b-form-checkbox-group>
                <span class="text-font-grey">**min 1 opzione</span>
              </b-form-group>
            </b-col>
            <b-col class="mb-1">
              <b-form-group
                label="Il paziente riceverà la notifica* "
                :description="timingDescription"
              >
                <b-form-radio-group
                  id="radio-timing"
                  v-model="form.timing"
                  name="radio-timing"
                >
                  <b-form-radio :value="0">al mattino</b-form-radio>
                  <b-form-radio :value="1">alla sera</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-col>
            <p class="fw-600 text-left pt-3">Note per il paziente</p>
            <p class="text-medium-grey pb-2">
              L'eventuale nota sarà aggiunta alla notifica inviata al paziente,
              ad eccezione degli sms.
            </p>
          </b-col>
          <b-col class="col-12 col-md-10 mt-2">
            <b-form-group>
              <b-form-textarea
                v-capitalize-first
                style="overflow-y: auto"
                class=" "
                v-model="form.company_notes"
                placeholder="Se vuoi, aggiungi una nota per il paziente..."
                rows="2"
                max-rows="2"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </div>
        <!-- ALERT PRESTAZIONE-TYPE2 -->

        <div class="text-left my-3">
          <p>*parametri obbligatori</p>

          <b-row cols="2" class="d-flex justify-content-around col-10">
            <b-col class="col-12 col-md-6 text-center">
              <b-button
                type="button"
                variant="outline-secondary"
                class=""
                @click="navigateToPatient()"
                >Annulla</b-button
              >
            </b-col>
            <b-col class="col-12 col-md-6 text-center">
              <b-button type="submit" variant="secondary" class="text-white"
                >Salva pianificazione
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-form>
  </div>
  <div v-else>
    <h4>Servizio non abilitato</h4>
    <h6>
      <a href="https://concura.online/contatti/"> <u> Contattaci</u></a>
      per saperne di più o per abilitare il servizio
    </h6>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { utilityService, supportService, companyService } from "@/_services";
import SelectTherapy from "@/components/profile/sanitario/SelectTherapy.vue";
import SelectCompanyHealthServices from "@/components/prenotazioni/form/SelectCompanyHealthServices.vue";

export default {
  name: "RecallPrenotazione",
  props: ["subject", "atype"],
  components: { SelectTherapy, SelectCompanyHealthServices },
  data() {
    return {
      form: {
        type: 0,
        anagrafica: null,
        alertable_id: null,
        alertable_type: "prestazione sanitaria",
        channels: [0],
        schedule: [1],
        confezioni: 1,
        uic: 10,
        start_at: null,
        end_at: null,
        timing: 1,
        company_notes: null,
      },
      anagrafica: {
        name: null,
        surname: null,
        cf: null,
        tel_number: null,
        email: null,
      },
      selectedAlertable: "type2",
      alertableMappedTypes: {
        type1: "terapia",
        type2: "prestazione sanitaria",
      },
      alertableTypes: [
        { label: "alert esaurimento farmaco", value: "type1" },
        {
          label: "recall prestazione sanitaria",
          value: "type2",
        },
      ],
      alertableTypesDecriptions: {
        type1:
          "notifica al paziente quando la sua scorta di farmaco per una terapia sta per terminare",
        type2: "ricorda al paziente di prenotare una prestazione sanitaria",
      },
      selectedHealthService: null,
      selectedTherapy: null,
      channels: [
        { text: "email", value: 0, disabled: false },
        { text: "telegram/whatsapp", value: 2, disabled: true },
        { text: "sms", value: 1, disabled: true },
        { text: "chiamata operatore", value: 3, disabled: true },
      ],
      scheduleOptions: [
        { text: "1 giorno prima", value: 1, disabled: false },
        { text: "2 giorni prima", value: 2, disabled: false },
        { text: "3 giorni prima", value: 3, disabled: false },
        { text: "5 giorni prima", value: 5, disabled: false },
        { text: "10 giorni prima", value: 10, disabled: false },
        { text: "15 giorni prima", value: 15, disabled: false },
        { text: "20 giorni prima", value: 20, disabled: false },
        { text: "30 giorni prima", value: 30, disabled: false },
      ],
      typesMap: {
        Compresse: "compressa",
        Capsule: "capsula",
        Gocce: "goccia",
        Inalazioni: "inalazione",
        Buste: "busta",
        Supposte: "supposta",
        Scatole: "scatola",
        Cerotti: "cerotto",
        Fiale: "fiala",
        Fleboclisi: "fleboclisi",
        Ovuli: "ovulo",
        Sacche: "sacca",
        Flaconi: "flacone",
        Confezioni: "confezione",
        Kilogrammi: "kilogrammo",
        Kit: "kit",
        Millilitri: "millilitro",
        Milligrammi: "milligrammo",
        Applicazioni: "applicazione",
        UI: "UI",
      },
      forEveryUmsSing: {
        0: "mese",
        1: "settimana",
        2: "giorno",
      },
      forEveryUmsPlur: {
        0: "mesi",
        1: "settimane",
        2: "giorni",
      },
      selectedCompany: null,
      showDone: false,
      searchingUser: false,
      isActive: null,
      pickedHashFromRoute: null,
      selectedPrestazioneHash: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    posNum: function () {
      return this.selectedTherapy ? this.selectedTherapy.posology_num * 1 : 0;
    },
    timingDescription: function () {
      let time = this.form.timing === 1 ? "20:00" : "8:00";
      return "la notifica verrà inviata intorno alle ore " + time;
    },
    validEmail() {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.anagrafica.email.match(validRegex) ? true : false;
    },
    validCf() {
      var validRegex =
        /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
      // if (this.anagrafica.cf.length === 16) {
      //   return this.anagrafica.cf.match(validRegex) ? this.fillOthers() : false;
      // }
      return this.anagrafica.cf.match(validRegex) ? true : false;
    },
  },
  watch: {
    selectedAlertable: function (value) {
      this.form.alertable_type = this.alertableMappedTypes[value];
      // console.log(this.form.alertable_type);
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    resetAll() {
      this.selectedPrestazioneHash = null;
      this.anagrafica = {};
      this.selectedHealthService = null;
      this.selectTherapy = null;
      this.form = {};
      if (this.subject) {
        this.form.user_id = this.subject.hashid;
      }
      if (this.$route.query.atype) {
        this.selectedAlertable = this.$route.query.atype;
      }
    },
    navigateToPatient() {
      if (this.$route.params.hash) {
        this.$router.push({
          name: "scheda paziente",
          params: { hash: this.$route.params.hash },
        });
      } else this.resetAll();
    },
    formatData(data) {
      return moment(data).format("DD MMM YYYY");
    },
    selectTherapy(therapy) {
      this.selectedTherapy = therapy;
      this.form.alertable_id = therapy.hashid;
      this.form.alertable_type = "terapia";
      this.form.start_at = therapy.start_at;
      this.form.confezioni = 1;
      this.form.uic = 10;
    },
    selectHealthService() {
      this.form.alertable_id = this.selectedPrestazioneHash;
      this.form.confezioni = 0;
      this.form.uic = 0;
      this.form.start_at = moment().format("yyyy-MM-DD");
    },
    doReset() {
      var today = moment();
      this.form = {
        type: 0,
        anagrafica: null,
        alertable_id: null,
        alertable_type: "prestazione sanitaria",
        channels: [0],
        schedule: [1],
        confezioni: 1,
        uic: 10,
        start_at: today.format("YYYY-MM-DD"),
        end_at: null,
        timing: 1,
        company_notes: null,
      };
      this.showDone = false;
      this.anagrafica = {
        name: null,
        surname: null,
        cf: null,
        tel_number: null,
        email: null,
      };
    },
    saveSchedule() {
      window.scrollTo(0, 0);
      this.form.anagrafica = this.anagrafica;
      if (this.form.schedule.length > 3) {
        return false;
      }
      const self = this;
      supportService
        .storeAlertsWithUserData(this.form)
        .then(function (response) {
          // console.log(response.data.data);
          if (response.data.data.error) {
            window.scrollTo(0, 0);
            self.errorAlert(response.data.data.error);
          } else {
            self.$emit("save-alerts", response.data.data);
            self.successAlert("Notifica salvata");
            self.showDone = true;
          }
        })
        .catch(function (error) {
          console.log(error);
          self.errorAlert("Si è verificato un errore durante il salvataggio");
        });
    },
    searchUser() {
      if (
        this.anagrafica.cf &&
        this.anagrafica.cf.length === 16 &&
        this.validCf
      ) {
        this.searchingUser = true;
        var params = { cf: this.anagrafica.cf };
        var self = this;
        utilityService
          .searchUser(params)
          .then(function (res) {
            console.log(res.data.data);
            self.autofillAnagrafica(res.data.data);
          })
          .catch(function (err) {
            self.searchingUser = false;
            self.anagrafica.name = null;
            self.anagrafica.surname = null;
            self.anagrafica.tel_number = null;
            self.anagrafica.email = null;
          });
      }
    },
    autofillAnagrafica(data) {
      if (data.anagrafica.name) {
        this.anagrafica.name = data.anagrafica.name;
      }
      if (data.anagrafica.surname) {
        this.anagrafica.surname = data.anagrafica.surname;
      }
      if (data.anagrafica.tel_number) {
        this.anagrafica.tel_number = data.anagrafica.tel_number;
      }
      if (data.email) {
        this.anagrafica.email = data.email;
      }
      this.searchingUser = false;
    },
    verifyService() {
      const self = this;
      const filter = { ref: 4 };
      companyService
        .checkIfServiceIsActive(filter)
        .then(function (response) {
          self.isActive = response.data.data.active;
          if (response.data.data.active === true) {
            self.handleStart();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  created() {
    if (this.subject) {
      this.form.user_id = this.subject.hashid;
    }
    if (this.$route.query.atype) {
      this.selectedAlertable = this.$route.query.atype;
    }
  },
  mounted() {
    this.verifyService();
    var today = moment();
    this.form.start_at = today.format("YYYY-MM-DD");
    this.selectedCompany = this.user.company;
    if (this.$route.query.hserv) {
      this.pickedHashFromRoute = this.$route.query.hserv;
      // var hash = this.$route.query.hserv
      // var service = this.user.company.prestazioni_sanitarie.find(function (el) {
      //   return el.hashid === hash
      // })
      // if (service) {
      //   this.selectHealthService(service)
      // }
    }
  },
};
</script>
