<template>
  <b-container v-if="notFound" class="px-4">
    <b-row cols="1" class="text-left mb-4">
      <router-link class="text-secondary no__hover" :to="{ name: 'pazienti' }">
        <b-icon icon="arrow-left-circle-fill" variant="secondary"></b-icon>
        Pazienti
      </router-link>
    </b-row>
    <h5 class="" v-if="notFound">Nessun Paziente selezionato</h5>
  </b-container>
  <div class="px-4" v-else>
    <b-row cols="1" class="text-left mb-2">
      <router-link
        class="text-secondary no__hover"
        :to="{
          name: 'scheda paziente',
          params: { hash: $route.params.hash },
        }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="secondary"></b-icon>
        Impostazioni alerts del paziente
      </router-link>
    </b-row>

    <b-row v-if="showDone" cols="1" class="text-center my-4">
      <h4 class="">Setting notifiche completato</h4>
      <b-button
        variant="secondary text-white "
        class="col-10 col-md-5 mx-auto my-2"
        @click="
          $router.push({
            name: 'scheda paziente',
            params: { hash: $route.params.hash },
            query: { section: 'alerts' },
          })
        "
        >Torna alle impostazioni del paziente</b-button
      >
    </b-row>
    <b-row cols="1" class="text-left my-4" v-else>
      <h4 class="text-prim-grad-1 text-left pb-1" v-if="paziente">
        Modifica piano recall di {{ paziente.anagrafica.name }}
        {{ paziente.anagrafica.surname }}
      </h4>

      <b-row cols="1" class="my-2 pt-1" v-if="selectedPrestazione">
        <div class="text-left">
          <p class="text-medium-grey pb-2">
            Notifiche per il follow up per ricordare al paziente di prenotare la
            prestazione
            {{ selectedPrestazione.name }}. <br />
            Piano impostato il {{ form.start_at | moment("DD/MM/YYYY") }}.
          </p>
          <b-col class="px-0">
            <b-row cols="1" class="">
              <b-col class="my-1 px-0 pt-1">
                <b-form-group class="col-12 col-md-4 px-0">
                  <label class="mb-3 fw-600" for=""
                    >Data follow up prevista*</label
                  >
                  <b-form-input
                    class=" "
                    name="start_at"
                    type="date"
                    v-model="form.end_at"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row cols="2" class="my-3 mt-2">
              <b-col class="px-0">
                <p class="fw-600">Invia notifica</p>
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="checkbox-freq"
                    v-model="form.schedule"
                    :options="scheduleOptions"
                    :aria-describedby="ariaDescribedby"
                    name="checkbox-freq"
                    stacked
                    :state="form.schedule.length > 3 ? false : null"
                  ></b-form-checkbox-group>
                  <span
                    class="font-size-075rem"
                    :class="
                      form.schedule.length > 3
                        ? 'text-danger'
                        : 'text-font-grey'
                    "
                    >**min 1 opzione, max 3 opzioni</span
                  >
                </b-form-group>
              </b-col>
              <b-col>
                <p class="fw-600">Via</p>
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="checkbox-channel"
                    v-model="form.channels"
                    :options="channels"
                    :aria-describedby="ariaDescribedby"
                    name="checkbox-channel"
                    stacked
                  ></b-form-checkbox-group>
                  <span class="text-font-grey font-size-075rem"
                    >**min 1 opzione</span
                  >
                </b-form-group>
              </b-col>
              <b-col class="col-12 pt-4 px-0">
                <p class="fw-600">Il paziente riceverà la notifica</p>
                <b-form-group :description="timingDescription">
                  <b-form-radio-group
                    id="radio-timing"
                    v-model="form.timing"
                    name="radio-timing"
                  >
                    <b-form-radio :value="0">al mattino</b-form-radio>
                    <b-form-radio :value="1">alla sera</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </div>
        <b-row
          cols="2"
          class="justify-content-around col-7 col-lg-8 col-md-9 col-12"
        >
          <b-col class="col-md-6 col-12 pb-3 pb-md-0 my-1 text-center">
            <b-button
              type="button"
              variant="outline-secondary"
              class=""
              @click="
                $router.push({
                  name: 'scheda paziente',
                  params: { hash: $route.params.hash },
                  query: { section: 'alerts' },
                })
              "
              >Annulla</b-button
            >
          </b-col>
          <b-col class="col-6 my-1 text-center">
            <b-button @click="saveEdits" variant="secondary" class="text-white"
              >Salva modifiche
            </b-button>
          </b-col>
        </b-row>
      </b-row>
    </b-row>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { cittadinoService, supportService } from "@/_services";
import NewAlert from "@/components/profile/sanitario/NewAlert.vue";
export default {
  name: "ModificaAlertPrestazionePaziente",
  components: { NewAlert },
  data() {
    return {
      subject: {
        hashid: this.$route.params.hash,
        type: this.$route.query.type,
      },
      notFound: false,
      selectedPrestazione: null,
      alerts: null,
      showDone: false,
      paziente: null,
      everyUms: {
        0: "mesi",
        1: "settimane",
        2: "giorni",
      },
      everyPosologyTypes: {
        0: "dì",
        1: "ore",
        2: "bisogno",
      },
      everyPosologyUms: {
        1: "ore",
        2: "giorni",
      },
      typesMap: {
        Compresse: "compressa",
        Capsule: "capsula",
        Gocce: "goccia",
        Inalazioni: "inalazione",
        Buste: "busta",
        Supposte: "supposta",
        Scatole: "scatola",
        Cerotti: "cerotto",
        Fiale: "fiala",
        Fleboclisi: "fleboclisi",
        Ovuli: "ovulo",
        Sacche: "sacca",
        Flaconi: "flacone",
        Confezioni: "confezione",
        Kilogrammi: "kilogrammo",
        Kit: "kit",
        Millilitri: "millilitro",
        Milligrammi: "milligrammo",
        Applicazioni: "applicazione",
        UI: "UI",
      },
      forEveryUmsSing: {
        0: "mese",
        1: "settimana",
        2: "giorno",
      },
      forEveryUmsPlur: {
        0: "mesi",
        1: "settimane",
        2: "giorni",
      },
      scheduleOptions: [
        { text: "1 giorno prima", value: 1, disabled: false },
        { text: "2 giorni prima", value: 2, disabled: false },
        { text: "3 giorni prima", value: 3, disabled: false },
        { text: "5 giorni prima", value: 5, disabled: false },
        { text: "10 giorni prima", value: 10, disabled: false },
        { text: "15 giorni prima", value: 15, disabled: false },
        { text: "20 giorni prima", value: 20, disabled: false },
        { text: "30 giorni prima", value: 30, disabled: false },
      ],
      channels: [
        { text: "email", value: 0, disabled: false },
        { text: "telegram/whatsapp", value: 2, disabled: true },
        { text: "sms", value: 1, disabled: true },
        { text: "chiamata operatore", value: 3, disabled: true },
      ],
      deliveryChannelMap: {
        via_mail: 0,
        via_sms: 1,
        via_bot: 2,
        via_call: 3,
      },
      form: {
        type: 0,
        user_id: this.$route.params.hash,
        alertable_id: this.$route.query.ter,
        alertable_type: "prestazione sanitaria",
        channels: [0],
        schedule: [1],
        confezioni: 0,
        uic: 0,
        start_at: null,
        end_at: null,
        timing: 1,
        company_notes: null,
        alerts: null,
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    posNum: function () {
      return this.selectedPrestazione
        ? this.selectedPrestazione.posology_num * 1
        : 0;
    },
    timingDescription: function () {
      let time = this.form.timing === 1 ? "20:00" : "8:00";
      return "la notifica verrà inviata intorno alle ore " + time;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utente", ["me"]),
    showRecap(data) {
      this.showDone = true;
    },
    fetchUser() {
      var self = this;
      cittadinoService
        .getUserById(this.$route.params.hash)
        .then(function (res) {
          self.paziente = res.data.data;
          self.subject = {
            hashid: res.data.data.hashid,
            type: "user_in_app",
          };
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          self.notFound = true;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    fetchAlerts() {
      var self = this;
      supportService
        .getUserPrestazioneAlerts(this.$route.query.ter)
        .then(function (res) {
          self.selectedPrestazione = res.data.data.prestazione;
          self.alerts = res.data.data.alerts;
          if (res.data.data.alerts.length > 0) {
            self.setForm(res.data.data.alerts);
          }
        })
        .catch(function (err) {
          console.log(err);
          self.notFound = true;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    setForm(alerts) {
      // this.form.channels = [0];
      // this.form.schedule = [1];
      this.form.confezioni = alerts[0].confezioni;
      this.form.uic = alerts[0].uic;
      this.form.company_notes = alerts[0].company_notes;
      var start = moment(alerts[0].countdown_start_at).format("YYYY-MM-DD");
      this.form.start_at = start;
      var end = moment(alerts[0].countdown_ends_at).format("YYYY-MM-DD");
      this.form.end_at = end;
      var sched = [];
      var dels = [];
      var prevs = [];
      alerts.map((el, ind) => {
        sched.push(el.t_minus);
        prevs.push(el.hashid);
      });
      Object.keys(this.deliveryChannelMap).map((el, ind) => {
        if (alerts[0][el] == true) {
          dels.push(ind);
        }
      });
      this.form.alerts = prevs;
      this.form.schedule = sched;
      this.form.channels = dels;
      this.form.timing =
        moment(alerts[0].countdown_ends_at).format("HH") == "20" ? 1 : 0;
    },
    saveEdits() {
      if (this.form.schedule.length > 3) {
        return false;
      }
      const self = this;
      supportService
        .updateUserTherapyAlerts(this.form)
        .then(function (res) {
          if (res.data.data.error) {
            window.scrollTo(0, 0);
            self.errorAlert(res.data.data.error);
          } else {
            self.showRecap(res.data.data);
            window.scrollTo(0, 0);
            self.successAlert("Piano modificato con successo.");
          }
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
  },
  created() {
    if (this.$route.params.hash && this.$route.query.ter) {
      this.fetchUser();
      this.fetchAlerts();
    } else {
      this.notFound = true;
    }
  },
};
</script>
