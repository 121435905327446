<template>
  <span> </span>
</template>

<script>
import { mapState } from "vuex";
import { cittadinoService } from "@/_services";
export default {
  name: "BotManWidgetHandler",
  data() {
    return {
      observer: null,
      code: null,
    };
  },
  computed: {
    isUserLoggedIn() {
      return !!this.user;
    },
    ...mapState("utente", ["status", "user"]),
  },
  watch: {},
  methods: {
    setupWidgetVariables() {
      const endpoint = process.env.VUE_APP_API_BASE_URL.replace("/api/", "");
      const chatServer = endpoint + "/botman/concura";

      window.botmanWidget.chatServer = chatServer;
      window.botmanWidget.userId = this.code;
    },
    getCode() {
      const self = this;
      cittadinoService
        .getCode()
        .then(function (res) {
          self.code = res.data.data.code;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile recuperare il codice");
        });
    },
    setupBotmanWidget() {
      const botmanWidgetRoot = document.getElementById("botmanWidgetRoot");
      if (botmanWidgetRoot) {
        botmanWidgetRoot.style.display = "block";
      }
    },

    //con questo risolvo tre problemi del widget:
    //1. Imposto il testo custom in Bot di Concura
    //2. Imposto il colore del testo in bianco (non c'è modo di farlo da configurazione)
    //3. Risolvo il problema per cui il div prende troppo spazio e per esempio in fase
    //di prenotazione rende impossibile cliccare sul tasto avanti perché occupa 400px

    startMutationObserver() {
      const checkForBotManWidget = (mutationsList, observer) => {
        for (let mutation of mutationsList) {
          // const svgNode = node.querySelectorAll("svg");
          if (mutation.type === "childList") {
            mutation.addedNodes.forEach((node) => {

              if (node.nodeType === Node.ELEMENT_NODE) {
                const divs = node.querySelectorAll("div");
                divs.forEach((div) => {
                  if (div.textContent.trim() === "BotMan Widget") {
                    div.style.color = "#ffffff";
                    div.style.fontWeight = 500;
                    div.textContent = "Bot di Concura";
                  }
                });
              }
            });
          } else if (
            mutation.type === "attributes" &&
            mutation.attributeName === "style"
          ) {
            if (
              mutation.target.nodeName === "DIV" &&
              mutation.target.parentNode.id === "botmanWidgetRoot"
            ) {
              const style = mutation.target.style;
              const bottomValue = style.bottom;
              if (bottomValue === "0px") {
                style.minWidth = "100px";
              }
            }
          }
        }
      };

      const botmanWidgetRoot = document.getElementById("botmanWidgetRoot");
      if (botmanWidgetRoot) {
        this.observer = new MutationObserver(checkForBotManWidget);
        const config = {
          childList: true,
          subtree: true,
          attributes: true,
          attributeFilter: ["style"],
        };

        this.observer.observe(botmanWidgetRoot, config);
      }
    },
  },
  created() {
    this.getCode();
  },
  mounted() {
    const self = this;
    let count = 0;
    const interval = setInterval(() => {
      const botmanWidgetRoot = document.getElementById("botmanWidgetRoot");
      if (botmanWidgetRoot && this.code) {
        self.setupBotmanWidget();
        self.setupWidgetVariables();
        self.startMutationObserver();
        clearInterval(interval);
      }
      count++;
      if (count >= 20) {
        clearInterval(interval);
      }
    }, 600);
  },
  beforeDestroy() {
    const botmanWidgetRoot = document.getElementById("botmanWidgetRoot");
    if (botmanWidgetRoot) {
      botmanWidgetRoot.style.display = "none";
    }

    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>
