<template>
  <div v-if="detail" class="w-100">
    <!-- <b-row
      id="detail-prodotto-row"
      align-v="stretch"
      class="mt-n4 flex-wrap flex-lg-nowrap"
    >   -->
    <b-row align-v="stretch" class="mt-n4 flex-wrap">
      <!-- <b-col
        id="products-col"
        v-if="detail.chart.length > 0"
        class="col-md-4 px-0 mr-4 detail-modal-card d-flex flex-column justify-content-start mt-4"
      >  -->
      <b-col
        cols="12"
        xl="12"
        v-if="detail.chart.length > 0"
        class="px-0 detail-modal-card d-flex flex-column justify-content-start mt-4"
      >
        <!-- Elenco prodotti -->
        <div
          class="d-flex flex-column justify-content-start align-items-start p-3"
        >
          <p class="font-weight-bolder detail-modal-card-titles">
            PRODOTTI RICHIESTI
          </p>
          <div class="d-flex flex-wrap mt-n2">
            <div
              class="mt-2 col-12 col-lg-6"
              v-for="(item, ind) in detail.chart"
              :key="ind"
            >
              <p class="mb-0">
                <strong class="text-secondary text-uppercase">{{
                  item.nome
                }}</strong>
                <span v-if="item.aic"> - AIC: {{ item.aic }} </span>
              </p>
              <p class="pl-2 mb-0 font-weight-bold">
                <b>
                  {{ item.quantity }}
                  {{ item.quantity > 1 ? "confezioni " : "confezione " }}</b
                >
                <span v-if="item.generic"> - GENERICO </span>
              </p>
              <b-row cols="1" class="ml-2 mt-2">
                <b-form-group class="">
                  <b-form-checkbox
                    v-model="detail.chart[ind].prescription_required"
                    :name="'prescription_required' + ind"
                    :value="1"
                    :unchecked-value="null"
                    class="mx-auto col-12"
                    :disabled="
                      Math.abs(stato) === 4 ||
                      Math.abs(stato) === 3 ||
                      Math.abs(stato) === 2
                    "
                    >Prescrizione Necessaria</b-form-checkbox
                  >
                </b-form-group>
                <b-form-group class="">
                  <b-form-checkbox
                    v-model="detail.chart[ind].available"
                    :name="'available' + ind"
                    :value="0"
                    :unchecked-value="null"
                    class="mx-auto col-12"
                    :disabled="
                      Math.abs(stato) === 4 ||
                      Math.abs(stato) === 3 ||
                      Math.abs(stato) === 2
                    "
                    >Prodotto non disponibile</b-form-checkbox
                  >
                </b-form-group>
              </b-row>
              <b-row cols="2" class="ml-2">
                <span v-if="item.nre">
                  <strong class=""> NRE: </strong>
                  <BarcodeShower tipo="nre" :code="detail.nre" />
                </span>
                <span v-if="item.rev">
                  <strong class=""> REV: </strong>
                  <BarcodeShower tipo="rev" :code="detail.rev" />
                </span>
              </b-row>
            </div>
          </div>
        </div>
        <!-- Fine Elenco prodotti -->
      </b-col>
      <b-col
        cols="12"
        xl="6"
        v-if="detail.nre || detail.rev"
        class="detail-modal-card mt-4 d-flex flex-column justify-content-start"
      >
        <div class="p-3" align-v="end">
          <div v-if="detail.nre" class="">
            <p
              class="text-left mb-0 detail-modal-card-titles font-weight-bolder"
            >
              Codice NRE
            </p>
            <div class="d-flex justify-content-start">
              <BarcodeShower tipo="nre" :code="detail.nre" />
            </div>
          </div>
          <div v-else-if="detail.rev" class="">
            <p
              class="text-left mb-0 detail-modal-card-titles font-weight-bolder"
            >
              Codice REV
            </p>
            <div class="">
              <BarcodeShower tipo="rev" :code="detail.rev" />
            </div>
          </div>
          <b-form-group class="d-flex mt-2">
            <b-form-checkbox
              v-model="detail.available"
              name="availabled"
              :value="0"
              :unchecked-value="null"
              class="mx-auto col-12"
              :disabled="
                Math.abs(stato) === 4 ||
                Math.abs(stato) === 3 ||
                Math.abs(stato) === 2
              "
              >Prodotti non disponibili</b-form-checkbox
            >
          </b-form-group>
        </div>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="mt-3 px-0"
        v-if="detail.details && detail.details !== ''"
      >
        <b-row class="p-3 detail-modal-card">
          <b-col class="px-0">
            <p>
              <span class="font-weight-bolder detail-modal-card-titles"
                >ALTRI DETTAGLI</span
              >
            </p>
            <p class="text-dark-grey font-size-085rem m-0 p-0">
              {{ detail.details }}
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" xl="6" class="mt-3 px-0" v-if="ricetta">
        <b-row class="p-3 detail-modal-card">
          <b-col class="px-0">
            <p>
              <span class="font-weight-bolder detail-modal-card-titles"
                >RICETTA</span
              >
            </p>
            <p class="m-0 p-0 text-dark-grey text-left">
              <span
                @click="downloadRicetta"
                class="text-secondary font-weight-bolder point underlined"
                >Scarica</span
              >
            </p>
          </b-col>
        </b-row>
      </b-col>
      <!-- <b-col  cols="12" xl="8" v-if="detail.allegato !== null && picLink" class="mt-4">
        <p>
          <strong>Allegato</strong><br />
          <b-img
            ref="allegato"
            :src="picLink"
            no-body
            fluid-grow
            width="100%"
            height="100%"
          />
        </p>
      </b-col> -->

      <!-- <b-col id="buttons-col" class="mt-4 mr-4" v-if="thereIsOneButton"> -->
      <b-col cols="12" xl="4" class="mt-3" v-if="thereIsOneButton">
        <div
          class="px-0 mr-4 d-flex flex-column justify-content-center align-items-center h-100"
          v-if="company.level === 5 && accettata === null"
        >
          <b-button
            type="button"
            variant="secondary  mb-2"
            @click="$refs['accept-decline-modal'].show()"
            >Accetta/declina prenotazione</b-button
          >
        </div>

        <div v-else>
          <b-button
            v-if="editBtn"
            class=""
            @click="updateRequest"
            type="button"
            variant="secondary  m-1"
            >Invia Risposta</b-button
          >
          <b-button
            v-else-if="stato === 1 || stato === 6"
            @click="confirmRequest"
            class=""
            type="button"
            variant="secondary  m-1"
            >Conferma Ordine</b-button
          >
          <b-button
            class=""
            v-if="stato === 1"
            @click="setAction('reject')"
            type="button"
            variant="secondary m-1"
            >Declina Ordine</b-button
          >
          <b-button
            v-if="stato > 1 && !nocancel"
            class=""
            @click="setAction('cancel')"
            type="button"
            variant="outline-danger  m-1"
            >Annulla Ordine</b-button
          >
        </div>
      </b-col>
      <b-modal
        ref="accept-decline-modal"
        hide-footer
        title="Accetta o Declina Prenotazione"
        centered
      >
        <div class="d-flex justify-content-around">
          <b-button variant="secondary" @click="acceptBooking"
            >Accetta</b-button
          >
          <b-button variant="secondary" @click="declineBooking"
            >Declina</b-button
          >
        </div>
      </b-modal>

      <!-- <b-col cols="12" xl="8"
        class="mt-4 px-0 flex-grow-2 long__txt__under__lg"
        v-if="stato > 1"
      >
        <div
          class="p-3 detail-modal-card d-flex flex-column justify-content-start align-items-start h-100"        
        >
          <p class="font-size-085rem d-flex flex-column">
            <span class="font-weight-bolder detail-modal-card-titles"
              >NOTE OPERATORE
              <UpdateNotesComponent
                @note-updated="handleNotesUpdate($event)"
                class="d__innline ml-2"
                :hashid="hash"
                :reserdate="datainizio"
              >
              </UpdateNotesComponent
            ></span>
          </p>
          <p
            v-if="company_notes"
            class="m-0 p-0 text-dark-grey text-left font-size-085rem"
          >
            {{ company_notes }}
          </p>
          <p v-else class="m-0 p-0 text-left text-dark-grey font-size-085rem">
            Nessuna nota
          </p>
        </div>
      </b-col> -->
    </b-row>

    <b-modal
      size="xl"
      hide-footer
      centered
      id="modal-confirm"
      ref="modal-confirm"
      title="Conferma Annullamento"
    >
      <PickMessageForm
        :messages="cancellationMessages"
        confirm-message="Sei sicuro di voler annullare questo appuntamento?"
        @cancellation-confirmed="cancelRequest($event)"
        @refused-to-confirm="$bvModal.hide('modal-confirm')"
      />
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { reservationService } from "@/_services"
import BarcodeShower from "@/components/utilities/BarcodeShower.vue"
import UpdateNotesComponent from "@/components/prenotazioni/dettaglio/UpdateNotesComponent.vue"
import PickMessageForm from "@/components/prenotazioni/form/PickMessageForm.vue"
import { CANCELLATION_MESSAGES } from "@/_utils/constants.js"
import { mapState, mapActions } from "vuex"

export default {
  name: "DetailProdotto",
  props: [
    "dettaglio",
    "stato",
    "datainizio",
    "company_notes",
    "hash",
    "nocancel",
    "accettata",
    "ricetta"
  ],
  components: { BarcodeShower, UpdateNotesComponent, PickMessageForm },
  data() {
    return {
      cancellationMessages: CANCELLATION_MESSAGES,
      picLink: null,
      freeText: null,
      actionName: null,
      loading: false,
      detail: this.dettaglio,
      editBtn: false,
      newDate: this.datainizio,
      newTime: moment(this.datainizio).format("HH:mm")
    }
  },
  computed: {
    checkValidity: function () {
      var data = moment(this.datainizio)
      return moment().isBefore(data)
    },
    thereIsOneButton: function () {
      return Math.abs(this.stato) !== 4 && (this.editBtn || this.stato >= 1)
    },
    ...mapState("company", ["company"])
  },
  watch: {
    dettaglio: {
      handler(val) {
        this.$nextTick(function () {
          this.detail = val
          if (val.resource_id !== null) {
            this.getAllegato()
          }
        })
      },
      deep: true
    },
    detail: {
      handler(val) {
        var showBtn = false
        if (this.stato > 4) {
          this.editBtn = true
        } else {
          Object.keys(val.chart).forEach(function (el) {
            if (
              val.chart[el].prescription_required !==
                val.chart_items[el].prescription_required ||
              val.chart[el].available !== val.chart_items[el].available
            ) {
              showBtn = true
            }
          })
          if (val.available !== null || val.prescription_required !== null) {
            showBtn = true
          }
          this.editBtn = showBtn
        }
        // this.editBtn = true;
      },
      deep: true
    },
    newDate(val) {
      this.editBtn = true
    },
    newTime(val) {
      this.editBtn = true
    }
    // company_notes(val) {
    //   this.editBtn = true
    // }
  },
  methods: {
    getAllegato() {
      var self = this
      var endpoint =
        this.detail?.allegato?.full_path + "/" + this.detail?.allegato?.hashid
      reservationService
        .getAllegato(endpoint)
        .then(function (res) {
          self.picLink = window.URL.createObjectURL(new Blob([res.data]))
        })
        .catch(function (err) {
          console.log(err)
        })
    },
    confirmRequest() {
      var data = {
        confirmed: true,
        action: "confirm"
      }
      this.$emit("update-request-status", data)
    },
    updateRequest() {
      // console.log(this.detail.hashid);
      var daTe = moment(this.newDate)
      var tiMe = moment(this.newTime, "HH:mm")
      daTe.hours(tiMe.hours())
      daTe.minutes(tiMe.minutes())
      var datetime = daTe.format("YYYY-MM-DD HH:mm")
      // console.log(daTe.format("YYYY-MM-DD HH:mm"));
      var dataToSend = {
        detail: this.detail,
        newDateTime: datetime,
        company_notes: this.company_notes
      }
      this.$emit("update-request-response", dataToSend)
    },
    setAction(action) {
      this.actionName = action
      this.$refs["modal-confirm"].show()
    },
    cancelRequest(message = null) {
      this.loading = true
      var data = {
        confirmed: false,
        message,
        action: this.actionName
      }
      this.$refs["modal-confirm"].hide()
      this.$emit("update-request-status", data)
    },
    handleNotesUpdate(data) {
      this.company_notes = data.company_notes
    },
    acceptBooking() {
      var data = {
        confirmed: true,
        action: "accept"
      }
      this.$emit("update-request-status", data)
    },
    declineBooking() {
      this.setAction("reject")
      this.$refs["modal-confirm"].show()
    },
    downloadRicetta() {
      var self = this
      reservationService
        .downloadResource(this.ricetta.hashid)
        .then(function (response) {
          // console.log("response", response.headers)
          let contentType = response.headers["content-type"]
          let blob = new Blob([response.data], { type: contentType })
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)

          let contentDisposition = response.headers["content-disposition"]
          let fileName = contentDisposition.split("filename=")[1].split(";")[0]

          // Rimozione di eventuali underscore
          fileName = fileName.replace(/^_+|_+$/g, "").replace(/\"/g, "")

          link.download = fileName
          link.click()
        })
        .catch(function (err) {
          console.log(err)
          self.errorAlert("Non è stato possibile scaricare la risorsa")
        })
    }
  },
  created() {
    // console.log(moment(this.datainizio).format("HH:mm"));
    if (this.detail && this.detail.allegato !== null) {
      this.getAllegato()
    }
    // this.newTime = moment(this.datainizio).format("HH:mm");
  }
}
</script>
<style lang="scss" scoped>
#products-col {
  min-width: 350px;
}

#notes-col {
  min-width: 250px;
}

#detail-prodotto-row > .col {
  max-width: 450px;
  min-height: 120px;
}

#buttons-col {
  max-width: 220px !important;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#barcode-col {
  max-width: 250px !important;
}

@media (max-width: 1000px) {
  #detail-prodotto-row > .col {
    max-width: 300px;
  }

  #products-col {
    flex-basis: 100%;
    min-width: 250px;
  }

  #notes-col {
    flex-basis: 50%;
  }

  #barcode-col {
    flex-basis: 100%;
    max-width: 200px;
  }

  #buttons-col {
    flex-basis: 100%;
  }
}
</style>
