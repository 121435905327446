<template>
  <div class="py-4 text-secondary">
    <div>
      <div class="mt-2">
        <p class="text-left text-capitalize">
          <span class="text-secondary">Servizio: </span>
          <span class="font-weight-bolder">{{
            newReservation.tipo_ordine
          }}</span>
          <span
            class="font-weight-bolder text-secondary"
            v-if="newReservation.order.prestazione"
          >
            {{ newReservation.order.prestazione.name }}</span
          >
          <!-- <b-badge
            variant="info"
            class="px-2 py-2 text-lowercase"
            v-else-if="fileOrder"
          >
            {{ fileOrder.name }}</b-badge
          > -->
        </p>
        <template v-if="productOrder.products">
          <p
            class="text-left text-secondary fw-bolder"
            v-for="(prod, ind) in productOrder.products"
            :key="ind"
          >
            <b>N°{{ prod.quantity }}</b>
            <span>
              {{ prod.quantity > 1 ? "confezioni" : "confezione" }}
            </span>
            <span> {{ prod.Nome }} </span>
            <b v-if="prod.generic"> - generico</b>
          </p>
        </template>
        <p v-if="productOrder.nre" class="text-left">
          <span class="text-secondary">NRE:</span>
          {{ productOrder.nre }}
        </p>
        <template v-if="productOrder.rev">
          <p class="text-left">
            <span class="text-secondary"> REV:</span>
            {{ productOrder.rev }}
          </p>
          <p />
          <p class="text-uppercase text-left">
            <b-icon
              icon="exclamation-circle"
              variant="secondary"
              class="mr-2"
            ></b-icon>
            <span class=""
              >Ricorda di portare con te il pin della ricetta quando vai a
              ritirare l'ordine.
            </span>
          </p>
        </template>
        <p
          class="text-left text-capitalize"
          v-if="selectedData.healthService.prezzo"
        >
          <span class="text-secondary">Costo del servizio: </span>
          <span class="font-weight-bolder">{{
            getServicePrice(selectedData.healthService)
          }}</span>
        </p>
        <p class="text-left text-capitalize">
          <span class="text-secondary">Totale dovuto: </span>
          <span class="font-weight-bolder">{{
            parseFloat(
              (
                parseFloat(newReservation.costo || 0) +
                parseFloat(selectedData.healthService.prezzo || 0)
              ).toFixed(2)
            ).toLocaleString("it-IT", {
              style: "currency",
              currency: "EUR"
            })
          }}</span>
        </p>
        <p v-if="selectedData.company.nome === 'Qwince'" class="text-left">
          Scelta struttura affidata a
          <span class="font-weight-bolder">ConCura</span>
        </p>

        <p v-else class="text-left text-capitalize">
          <span class="text-secondary">Centro erogatore: </span>
          <span class="font-weight-bolder text-secondary">{{
            selectedData.company.ff_denominazione
          }}</span>
          <span class="font-weight-bolder text-secondary"
            >, {{ selectedData.company.indirizzo }}
            {{ selectedData.company.frazione }}
            {{ selectedData.company.comune }}</span
          >
        </p>
        <p class="text-left" v-if="newReservation.data_inizio">
          <span
            v-if="newReservation.tipo_ordine !== 'prodotto'"
            class="text-secondary"
            >Data ed ora selezionate:
          </span>
          <span v-else class="text-secondary">Data ed ora(stimata): </span>
          <span class="font-weight-bolder text-secondary">
            {{ newReservation.data_inizio | moment("D MMM YYYY HH:mm") }}</span
          >
        </p>
        <!-- <p class="text-left" v-if="newReservation.tipo_ordine !== 'prodotto'">
          <span class="text-secondary">Preferenza Operatore: </span>
          <span class="font-weight-bolder text-secondary">
            {{ pickedOperatorString || "Nessuna preferenza" }}</span>
        </p> -->
      </div>
    </div>
    <div
      class="pb-3 text-left"
      v-if="newReservation.resources && newReservation.resources.length > 0"
    >
      <h6 class="text-prim-grad-1 fw-bolder text-left pb-1 pt-4">
        Documenti Scaricabili
      </h6>
      <p class="text-left">
        Sono presenti documenti correlati a questa prenotazione, puoi
        visualizzarli cliccando su ciascuno di essi. I documenti contenenti
        campi da compilare vanno scaricati, stampati, compilati e presentati in
        sede di appuntamento.
      </p>
      <a
        v-for="(allegato, ind) in newReservation.resources"
        :key="ind"
        :href="allegato.full_path"
        target="__blank"
        class="mr-1"
      >
        <b-badge
          variant="secondary"
          class="my-2 py-2 px-3 py-md-1 px-md-2"
          v-b-tooltip.hover
          :title="'apri ' + allegato.name + ' in un\'altra scheda'"
          >{{ allegato.name }}</b-badge
        >
      </a>
    </div>
    <div
      class="pb-3 text-left"
      v-if="
        newReservation.tipo_ordine === 'prestazione' &&
        newReservation.orderable.health_service.custom_notification_text
      "
    >
      <h6 class="text-prim-grad-1 fw-bolder text-left pb-1 pt-4">
        Raccomandazioni
      </h6>
      <p class="text-left">
        {{ newReservation.orderable.health_service.custom_notification_text }}
      </p>
    </div>

    <p class="text-left">
      <strong>{{ newReservation.user_notes }}</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: "RiepilogoPrenotazione",
  props: [
    "newReservation",
    "selectedData",
    "productOrder",
    "pickedOperatorString",
    "fileOrder"
  ],
  methods: {
    getServicePrice(prestazione) {
      if (prestazione.prezzo > 0) {
        const iva =
          prestazione.iva > 0
            ? " - iva " + prestazione.iva + "% esclusa"
            : " - iva inclusa"
        return (
          "- " +
          prestazione.prezzo.toLocaleString("it-IT", {
            style: "currency",
            currency: "EUR"
          }) +
          iva
        )
      }
      return ""
    }
  }
}
</script>
