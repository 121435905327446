<template>
  <div class="w-100">
    <template v-if="resData.type === 'prestazione'">
      <PickHealthServiceFromCompany
        @selected-new-health-service="emitSelectedNewHealthService($event)"
        v-if="wantsToSearchForCentroErogatore"
        class="col-12"
        :resData="resData"
        :selectedCompany="selectedData.company"
      ></PickHealthServiceFromCompany>
      <NewPrestazioneSelection
        v-else
        @selected-prestazione="selectedPrestazioneName($event)"
        :startingValue="selectedData.prestazioneName"
      />
    </template>
    <ProductSelection
      v-else-if="resData.type === 'prodotto'"
      :preselected="selectedData.productOrder"
      @selected-product="handleSelectedProductOrder($event)"
      @uploaded-order="$emit('uploaded-order', $event)"
    />
  </div>
</template>

<script>
import PickHealthServiceFromCompany from "./PickHealthServiceFromCompany.vue"
import NewPrestazioneSelection from "@/components/prenotazioni/form/NewPrestazioneSelection.vue"
import ProductSelection from "@/components/prenotazioni/form/ProductSelection.vue"

export default {
  name: "DiCosaHaiBisogno",
  data() {
    return {}
  },
  props: {
    selectedData: {
      type: Object,
      required: true
    },
    resData: {
      type: Object,
      required: true
    },
    wantsToSearchForCentroErogatore: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    PickHealthServiceFromCompany,
    NewPrestazioneSelection,
    ProductSelection
  },
  methods: {
    emitSelectedNewHealthService(healthService) {
      this.$emit("selected-new-health-service", healthService)
    },
    selectedPrestazioneName(prestazione) {
      this.$emit("selected-prestazione-name", prestazione)
    },
    handleSelectedProductOrder(productOrder) {
      this.$emit("selected-product", productOrder)
    }
  }
}
</script>

<style scoped></style>
