<template>
  <div class="pb-4">
    <div v-if="user.type === 1">
      <div class="mx-auto">
        <transition-group name="list" mode="out-in" appear>
          <div
            v-if="
              $route.params.section === 'servizi' &&
              user.permessi.includes('gestire impostazioni dei servizi')
            "
            key="servizi"
            class="px-3 px-xl-4"
          >
            <ServiziCompany/>
          </div>
          <div
            v-else-if="
              $route.params.section === 'anagrafica' &&
              user.permessi.includes('visualizzare anagrafica azienda')
            "
            key="anagrafica"
            class="px-3 px-xl-4"
          >
            <AnagraficaCompany />
          </div>
          <div
            v-else-if="
              $route.params.section === 'operatori' &&
              user.permessi.includes('gestire operatori')
            "
            key="operatori"
            class="px-3 px-xl-4"
          >
            <!-- <h4 class="text-left pb-1">Operatori Azienda</h4>
            <p class="text-left">
              Per abilitare una nuova utenza alla gestione dei tuoi servizi
              DottorFarma puoi inviare un invito via email contenente il codice
              azienda necessario per la sua registrazione.
            </p> -->
            <OperatorsList @total-count="handleOperatorsCount($event)" />
            <template v-if="operatorCount !== null">
              <template
                v-if="
                  company.operators_limit === 0 ||
                  (company.operators_limit > 0 &&
                    operatorCount < company.operators_limit)
                "
              >
                <h4 class="text-left pb-1">Codice Azienda</h4>
                <p class="text-justify">
                  Per abilitare una nuova utenza alla gestione dei tuoi servizi
                  ConCura puoi inviare un invito via email contenente il
                  codice azienda necessario per la sua registrazione. Il codice
                  azienda andrà inserito dall'operatore che intende registrarsi
                  autononamente nel portale in qualità di operatore nella tua
                  Azienda. Se invece preferisci inviare il codice via email
                  inserisci l'indirizzo del destinatario nell'apposito campo.
                </p>
                <ShowCode />
              </template>
            </template>
          </div>
          <div
            class="mx-auto px-3 px-xl-4"
            v-else-if="$route.params.section === 'sottoscrizioni'"
            key="sottoscrizioni"
          >
            <Sottoscrizioni />
          </div>
          <div v-else key="nonexistingornotauthorized">
            <p>
              Pagina non esistente o non si ha il permesso di accedere a questa
              sezione.
            </p>
          </div>
        </transition-group>
      </div>
    </div>
    <div v-else>
      <h5>Nessuna impostazione per questa utenza</h5>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import SettingOrari from "@/components/impostazioni/SettingOrari.vue";
import ServiceStatus from "@/components/impostazioni/ServiceStatus.vue";
import SettingServiceOrari from "@/components/impostazioni/SettingServiceOrari.vue";
import AnagraficaCompany from "@/components/impostazioni/AnagraficaCompany.vue";
import Sottoscrizioni from "@/components/impostazioni/Sottoscrizioni.vue";
// import AnagraficaCompanyDF from "@/components/impostazioni/AnagraficaCompanyDF.vue";
import PrestazioniSanitarie from "@/components/impostazioni/PrestazioniSanitarie.vue";
import AccessoEPermessi from "@/components/profile/AccessoEPermessi.vue";
import ShowCode from "@/components/impostazioni/ShowCode.vue";
import ServiziCompany from "@/components/impostazioni/ServiziCompany.vue";
import OperatorsList from "@/components/impostazioni/OperatorsList.vue";
import ShowUserCode from "@/components/impostazioni/ShowUserCode.vue";

export default {
  name: "ImpostazioniMain",
  components: {
    AccessoEPermessi,
    SettingOrari,
    SettingServiceOrari,
    ServiceStatus,
    AnagraficaCompany,
    // AnagraficaCompanyDF,
    PrestazioniSanitarie,
    ShowCode,
    ServiziCompany,
    OperatorsList,
    Sottoscrizioni,
    ShowUserCode,
  },
  computed: {
    showOrari() {
      return this.company && this.company.company_openings ? true : false;
    },
    showServizi() {
      return this.company && this.company.servizi_attivi_orari ? true : false;
    },
    showStati() {
      return this.company && this.company.stato_servizi ? false : true;
    },
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    // ...mapGetters("company", ["getCompany", "getStatoServizi"]),
    ...mapGetters({
      getStatoServizi: "company/getStatoServizi",
      getServiceOpenings: "company/getServiceOpenings",
      getCompany: "company/getCompany",
    }),
  },
  data() {
    return {
      serviceStatus: null,
      serviceToUpdate: null,
      showInfoOperators: false,
      operatorCount: null,
    };
  },
  methods: {
    ...mapActions("utente", ["me"]),
    handleOperatorsCount(count) {
      console.log(count)
      this.operatorCount = count === 0 ? 1 : count;
    },
  },
  created() {
    this.me();
    // console.log("section", this.$route.params.section);
  },
};
</script>
<style>

</style>
