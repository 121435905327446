<template>
  <div>
    <div
      class="bg-white p-3 general_border_17 shadow_2 px-1 boxes_max_heigh_60 h-100"
    >
      <!-- <h3 class="text-left py-2">Alert</h3> -->
      <b-row class="justify-content-between" align-v="center">
        <h3 class="text-left py-2">Alert</h3>
        <b-form-group class="col-7 col-md-5 col-xl-4">
          <b-form-select
            v-capitalize-first-select
            v-model="filters.order"
            size="sm"
            @change="fetchData"
          >
            <b-form-select-option value="severity"
              >severità</b-form-select-option
            >
            <b-form-select-option value="desc"
              >più recenti</b-form-select-option
            >
            <b-form-select-option value="asc"
              >meno recenti</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
      </b-row>
      <b-row class="mt-2 mb-4 pb-4">
        <b-col
          @click="toggleHideFilter('high')"
          :class="this.filters.hide.includes('high') ? 'half_opacity' : null"
          class="point rounded-pill bg-severity-high text-white mx-2 px-1 py-1 d-flex justify-content-center align-items-center"
        >
          <strong> Alta Severità </strong>
        </b-col>
        <b-col
          @click="toggleHideFilter('medium')"
          :class="this.filters.hide.includes('medium') ? 'half_opacity' : null"
          class="point rounded-pill bg-severity-medium text-white mx-2 px-1 py-1"
        >
          <strong> Media Severità </strong>
        </b-col>
        <b-col
          @click="toggleHideFilter('low')"
          :class="this.filters.hide.includes('low') ? 'half_opacity' : null"
          class="point rounded-pill bg-severity-low text-white mx-2 px-1 py-1"
        >
          <strong> Bassa Severità </strong>
        </b-col>
      </b-row>
      <b-overlay :show="loading">
        <div class="hide_overflow_y boxes_max_heigh_49 pr-3 pl-1" v-if="list">
          <b-row
            v-for="(alert, ind) in list"
            :key="ind"
            class="justify-content-between border border-severity-high border__4 general_border_27m py-2 px-2 py-lg-4 px-lg-4 my-2 my-lg-3"
            :class="'border-' + severityMap[alert.threshold_level]"
            align-v="center"
          >
            <b-col class="text-left">
              <h5 v-if="alert.patient.anagrafica" class="text-capitalize">
                {{ alert.patient.anagrafica.name }}
                {{ alert.patient.anagrafica.surname }}
              </h5>
              <h5 v-else class="text-capitalize">Utente eliminato</h5>
            </b-col>
            <template v-if="alert.status < 3 && !alert.forwarded_to_company">
              <b-button
                variant="terz-grad-2 terz_bg_gradient rounded-pill fw-bolder"
                @click="
                  $router.push({
                    name: 'control room',
                    params: { section: 'detail', hash: alert.hashid }
                  })
                "
                >Visualizza</b-button
              >

              <span class="pl-3">
                <b-icon icon="square" variant="light"></b-icon>
              </span>
            </template>
            <template v-else-if="alert.forwarded_to_company">
              <span
                @click="
                  $router.push({
                    name: 'control room',
                    params: { section: 'detail', hash: alert.hashid }
                  })
                "
                class="btn rounded-pill fw-bolder border-primary text-secondary"
              >
                Inoltrato
              </span>
              <span class="pl-3">
                <b-icon icon="check-square-fill" variant="primary"></b-icon>
              </span>
            </template>
            <template v-else>
              <span
                @click="
                  $router.push({
                    name: 'control room',
                    params: { section: 'detail', hash: alert.hashid }
                  })
                "
                class="btn rounded-pill fw-bolder border-primary text-secondary"
              >
                Completato
              </span>
              <span class="pl-3">
                <b-icon icon="check-square-fill" variant="primary"></b-icon>
              </span>
            </template>
          </b-row>
          <p class="text-left" v-if="list.length === 0">Nessun alert</p>
        </div>
      </b-overlay>
    </div>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { mapState, mapActions } from "vuex"
import { croomService } from "@/_services"
export default {
  name: "AlertList",
  props: ["seldate"],
  data() {
    return {
      list: null,
      filters: {
        year: null,
        month: null,
        day: null,
        status: null,
        severity: null,
        order: "severity",
        hide: []
      },
      severityOptions: ["bassa", "media", "alta"],
      orderOptions: ["severity", "desc", "asc"],
      statusOptions: ["nuovi", "aperti", "chiusi", "posticipati"],
      thresholdValues: [
        { key: "1", label: "Bassa severità", class: "bg-severity-1" },
        { key: "2", label: "Media severità", class: "bg-severity-2" },
        { key: "3", label: "Alta severità", class: "bg-severity-3" }
      ],
      severityMap: {
        1: "severity-low",
        2: "severity-medium",
        3: "severity-high"
      },
      loading: false
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    })
  },
  watch: {
    seldate: function (value) {
      console.log("seldate", value)
      const date = moment(value, "YYYY-MM-DD")
      this.filters.year = date.year()
      this.filters.month = date.month() + 1
      this.filters.day = date.date()
      console.log(this.filters)
      this.fetchData()
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    padPatientNumber(ndx) {
      const pseudName = ndx + 1
      return pseudName.toString().padStart(3, 0)
    },
    fetchData() {
      const self = this
      this.loading = true
      croomService
        .getAlertEvents(this.filters)
        .then(function (response) {
          console.log(response)
          self.list = response.data.data
          self.loading = false
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile ottenere la lista di pazienti")
          console.log(error)
          self.loading = false
        })
    },
    toggleHideFilter(level) {
      const levelIndex = this.filters.hide.indexOf(level)
      if (levelIndex >= 0) {
        this.filters.hide.splice(levelIndex, 1)
      } else {
        this.filters.hide.push(level)
      }
      this.fetchData()
    }
  },
  created() {
    if (this.seldate) {
      const date = moment(this.seldate, "YYYY-MM-DD")
      this.filters.year = date.year()
      this.filters.month = date.month() + 1
      this.filters.day = date.date()
    }
    this.fetchData()
  }
}
</script>
