<template>
  <div class="col-12 px-0">
    <div>
      <section>
        <!-- <div class="puntofarmaCard p-4"> -->
        <b-row
          cols="2"
          cols-md="3"
          cols-lg="4"
          class="puntofarmaCard px-2 py-3"
        >
          <!-- <div class="col-12 px-0 d-flex flex-wrap"> -->
          <b-form-group
            label="Regione"
            label-for="regione"
            class="text-left mx-auto col mb-0"
          >
            <b-form-select
              v-capitalize-first-select
              class="my-1"
              :options="regioni"
              name="regione"
              v-model="selected.regione"
              @change="getProvince(selected)"
              required
            >
              <b-form-select-option :value="null"
                >Seleziona Regione</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Provincia"
            label-for="provincia"
            class="text-left mx-auto col mb-0"
          >
            <b-form-select
              v-capitalize-first-select
              class="my-1"
              :options="province"
              name="provincia"
              v-model="selected.provincia"
              @change="getComuni(selected)"
              required
            >
              <b-form-select-option :value="null"
                >Seleziona Provincia</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Comune"
            label-for="comune"
            class="text-left mx-auto col mb-0"
          >
            <b-form-select
              v-capitalize-first-select
              class="my-1"
              name="comune"
              v-model="selected.comune"
              :key="
                comuni && comuni.length
                  ? comuni[0].comune + comuni.length
                  : null
              "
              required
            >
              <b-form-select-option
                v-for="(com, ind) in comuni"
                :key="ind"
                :value="com"
                @change="setData"
                >{{ com.comune }}</b-form-select-option
              >
              <b-form-select-option :value="null"
                >Seleziona Comune</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <!-- </div> -->
          <!-- <div class="col-12 d-flex flex-wrap">
          <b-form-group
            label="CAP"
            label-for="cap"
            class="text-left fs-6  mx-auto col mb-0"
          >
            <b-form-input
              class="my-1"
              name="cap"
              type="text"
              placeholder="CAP..."
              v-model="filters.cap"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Frazione"
            label-for="frazione"
            class="text-left fs-6  mx-auto col mb-0"
          >
            <b-form-input
              class="my-1"
              name="frazione"
              type="text"
              placeholder="frazione..."
              v-model="filters.frazione"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Codice Titolare"
            label-for="codice_titolare"
            class="text-left fs-6  mx-auto col mb-0"
          >
            <b-form-input
              class="my-1"
              name="codice_titolare"
              type="text"
              placeholder="codice titolare..."
              v-model="filters.codice_titolare"
            ></b-form-input>
          </b-form-group>
        </div> -->
          <!-- <div class="col-12 px-0 d-flex flex-wrap"> -->
          <b-form-group
            label="Denominazione"
            label-for="denominazione"
            class="text-left mx-auto col mb-0"
          >
            <b-form-input
              v-capitalize-first
              class="my-1"
              name="denominazione"
              type="text"
              placeholder="denominazione..."
              v-model="filters.denominazione"
              minlength="5"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Cognome Titolare"
            label-for="surname"
            class="text-left mx-auto col mb-0"
          >
            <b-form-input
              v-capitalize-first
              class="my-1"
              name="surname"
              type="text"
              placeholder="cognome..."
              v-model="filters.surname"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label="Farmacia Comunale"
            label-for="comunale"
            class="text-left mx-auto col mb-0"
          >
            <b-form-select
              v-capitalize-first-select
              class="my-2"
              name="comunale"
              v-model="filters.comunale"
            >
              <b-form-select-option :value="true">sì</b-form-select-option>
              <b-form-select-option :value="false">no</b-form-select-option>
              <b-form-select-option :value="null">scegli</b-form-select-option>
            </b-form-select>
          </b-form-group>
          <!-- </div>
          <div class="col-12 px-0 d-flex flex-wrap"> -->
          <b-form-group
            label="Partita Iva"
            label-for="partita_iva"
            class="text-left mx-auto col mb-0"
          >
            <b-form-input
              class="my-1"
              name="partita_iva"
              type="text"
              placeholder="partita iva..."
              v-model="filters.partita_iva"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Codice Fiscale"
            label-for="codice_fiscale"
            class="text-left mx-auto col mb-0 text-uppercase"
          >
            <b-form-input
              class="my-1"
              name="codice_fiscale"
              type="text"
              placeholder="codice fiscale..."
              v-model="filters.codice_fiscale"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Codice Tracciabilità"
            label-for="codice_tracciabilita"
            class="text-left mx-auto col mb-0"
          >
            <b-form-input
              class="my-1"
              name="codice_tracciabilita"
              type="text"
              placeholder="codice tracciabilità..."
              v-model="filters.codice_tracciabilita"
            ></b-form-input>
          </b-form-group>
          <!-- </div> -->
          <b-form-group
            label="Ultimo login dal"
            label-for="logfrom"
            class="text-left mx-auto col mb-1"
          >
            <b-form-input
              class="my-1"
              name="logfrom"
              type="date"
              v-model="filters.logfrom"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label="Ultimo login al"
            label-for="logto"
            class="text-left mx-auto col mb-1"
          >
            <b-form-input
              class="my-1"
              name="logto"
              type="date"
              v-model="filters.logto"
            >
            </b-form-input>
          </b-form-group>
          <b-col></b-col>
          <b-col class="text-right ml-auto mr-0">
            <!-- <div class="col-12 my-2 text-right"> -->
            <b-button
              @click="clearFilters"
              size="sm"
              type="button"
              variant="outline-secondary my-2"
              >Pulisci campi
            </b-button>
            <b-button
              @click="submitSearch"
              size="sm"
              type="button"
              variant="secondary my-2 ml-2"
              >Cerca</b-button
            >
            <!-- </div> -->
          </b-col>
        </b-row>

        <!-- <div class="col-12 d-flex flex-wrap"></div> -->
        <p v-if="results && results.length == 0" class="text-danger">
          Nessun risultato con questi parametri di ricerca
        </p>
        <!-- </div> -->
      </section>
    </div>
    <b-spinner variant="primary" label="loading" v-if="loading"></b-spinner>
  </div>
</template>
<script>
import { supportService } from "@/_services"
import { mapState, mapActions, mapGetters } from "vuex"
export default {
  name: "CompactSearch",
  props: ["pagefilter", "takefilter"],
  data() {
    return {
      filters: {
        comune: null,
        provincia: null,
        regione: null,
        frazione: null,
        cap: null,
        denominazione: null,
        surname: null,
        partita_iva: null,
        codice_fiscale: null,
        codice_titolare: null,
        codice_tracciabilita: null,
        comunale: null,
        page: this.pagefilter,
        take: this.takefilter,
        logfrom: null,
        logto: null
      },
      selected: {
        comune: null,
        provincia: null,
        regione: null
        // partita_iva: null,
        // codice_fiscale: null,
        // codice_titolare: null,
        // codice_tracciabilita: null,
        // comunale: null,
      },
      results: null,
      loading: false
    }
  },
  computed: {
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    ...mapGetters({
      getCompany: "company/getCompany"
    })
  },
  watch: {
    selected: {
      handler(val) {
        this.setData()
      },
      deep: true
    },
    pagefilter: function (val) {
      this.submitSearch()
    },
    takefilter: function (val) {
      // console.log("take filter changed", val);
      this.filters.take = val

      this.submitSearch()
    },
    filters: {
      handler(val) {
        // console.log("take filter changed");
        this.submitSearch()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    setData() {
      if (this.selected.comune) {
        this.filters.comune = this.selected.comune.comune
      }
      this.filters.regione = this.selected.regione
      this.filters.provincia = this.selected.provincia
    },
    submitSearch() {
      this.loading = true
      this.filters.take = this.takefilter
      this.filters.page = this.pagefilter
      // console.log(this.filters);
      var self = this
      supportService
        .searchCompany(this.filters)
        .then(function (response) {
          self.results = response.data.data
          self.$emit("new-selection", self.results)
          self.loading = false
        })
        .catch(function (error) {
          // console.log(error);
        })
    },
    clearFilters() {
      this.filters = {
        comune: null,
        provincia: null,
        regione: null,
        frazione: null,
        cap: null,
        denominazione: null,
        surname: null,
        partita_iva: null,
        codice_fiscale: null,
        codice_titolare: null,
        codice_tracciabilita: null,
        comunale: null,
        page: 1,
        take: this.takefilter,
        logfrom: null,
        logto: null
      }
      this.selected.regione = null
      this.selected.provincia = null
      this.selected.comune = null
    }
  },
  created() {
    // if (!this.regioni) {
    this.getRegioni()
    this.submitSearch()
    // }
  }
}
</script>
