<template>
  <div class="mt-2 text-center mb-4 col-12 px-0">
    <b-row
      class="bg-white border border-secondary general_border my-3 col-12 col-md-9 col-lg-8 col-xl-7 px-0 bg-primary"
    >
      <b-col
        v-for="(stepText, index) in visibleSteps"
        :key="index"
        :class="getColumnClass(allSteps.indexOf(stepText) + 1)"
      >
        {{ stepText }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "SetNuovaPrenotazionePazienteNavbar",
  props: {
    step: {
      type: Number,
      required: true,
      validator: (value) => value >= 1 && value <= 4
    },
    showPresso: Boolean,
    showDiCosaHaiBisogno: Boolean
  },
  data() {
    return {
      allSteps: ["Di cosa hai bisogno", "Presso", "Data", "Riepilogo"]
    }
  },
  computed: {
    visibleSteps() {
      return this.allSteps.filter(
        (_, index) =>
          (index !== 0 || this.showDiCosaHaiBisogno) &&
          (index !== 1 || this.showPresso)
      )
    }
  },
  methods: {
    getColumnClass(stepNumber) {
      return [
        "px-0 border-right border-right-secondary py-2 px-2 d-flex justify-content-center align-items-center",
        { general_border_half_left: stepNumber === 1 },
        { general_border_half_right: stepNumber === 4 },
        { "text-white bg-secondary": this.step === stepNumber },
        { "text-secondary opacity_60": this.step !== stepNumber }
      ]
    }
  }
}
</script>
