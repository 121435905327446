<template>
  <div class="col-12 mt-1 mx-0">
    <h4 class="text-left pb-1 mb-4 page-title">Questionari</h4>
    <!-- 
    <p class="text-justify text-medium-grey">
      Clicca sull'icona "occhio" per vedere il template del questionario,
      sull'icona "raccolta" per vedere l'elenco dei relativi questionari
      compilati, oppure sull'icona "cartella" per somministrare il questionario.
      Lo stato di attivazione di ciascun questionario determina la sua
      disponibilità per gli utenti (nel caso di connessa) e la possibilità di
      essere somministrato (per tutti gli altri). Per creare un nuovo
      questionario clicca su "nuovo questionario". Nota: potrai formulare nuovi
      questionari solo per le raccolte da te create e per il servizio
      "connessa".
    </p>
     -->
    <div :class="$root.isMobile ? 'col-12' : 'd-flex justify-content-between'">
      <b-button
        v-if="user.permessi.includes('gestire attività e questionari')"
        variant="secondary   border-white"
        class="ml-0 mr-auto"
        @click="
          $router.push({
            name: 'attivita',
            params: { section: 'questionario/nuovo' }
          })
        "
      >
        <b-icon icon="plus"></b-icon> nuovo questionario
      </b-button>

      <b-input-group size="sm" class="mt-2 col-lg-4 col-sm-6 ml-auto mr-0">
        <b-form-input
          v-capitalize-first
          class="align-items-center align-content-center"
          v-model="filterSetted.search"
          type="search"
          placeholder="Cerca questionario.."
        >
        </b-form-input>

        <b-input-group-append>
          <span class="mx-2">
            <b-icon icon="search"></b-icon>
          </span>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div
      :class="$root.isMobile ? '' : 'd-flex justify-content-between '"
      class="mt-4 mb-4 align-content-center"
    >
      <div>
        <b-form-group size="sm" class="text-left">
          <b-form-select
            v-capitalize-first-select
            class="align-items-center align-content-center border border-info custom-select-2"
            name="vedi"
            v-model="filterSetted.take"
            :options="takeOptions"
          >
          </b-form-select>
        </b-form-group>
        <div class="mt-2">
          <span class="text-secondary mr-1" v-if="total > 0"
            >{{ fromPage }}-{{ toPage }} di
          </span>
          <span class="text-secondary"> {{ total }}</span>
        </div>
      </div>
      <b-form-group size="sm" class="text-left">
        <b-form-select
          v-capitalize-first-select
          name="type"
          class="align-items-center align-content-center border border-info custom-select-2"
          v-model="filterSetted.activity"
          required
        >
          <b-form-select-option :value="null"
            >tutte le raccolte</b-form-select-option
          >
          <b-form-select-option
            v-for="(activity, ind) in activities"
            :key="ind"
            :value="activity.name"
            >{{ activity.name }}</b-form-select-option
          >
        </b-form-select>
      </b-form-group>
      <b-form-group size="sm" class="text-left">
        <b-form-select
          v-capitalize-first-select
          name="type"
          class="align-items-center align-content-center border border-info custom-select-2"
          v-model="filterSetted.position_ref"
          required
        >
          <b-form-select-option :value="null"
            >tutti i servizi</b-form-select-option
          >
          <b-form-select-option :value="1">Prenotazioni</b-form-select-option>
          <b-form-select-option :value="2">Teleconsulto</b-form-select-option>
          <b-form-select-option :value="3">Screening</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </div>

    <div class="mt-3 shadow_2 bg-white general_border p-4">
      <b-table
        borderless
        class="text-left"
        hover
        responsive
        table-variant="white "
        :fields="fields"
        :items="surveys"
        :small="$root.isMobile"
      >
        <template #cell(name)="row">
          <span class="text-left text-capitalize">{{ row.item.name }}</span>
        </template>

        <template #cell(description)="row">
          <span class="text-justify">{{ row.item.description }}</span>
        </template>

        <template #cell(attivita)="row">
          <span class="text-justify text-capitalize">{{
            row.item.activity.name
          }}</span>
        </template>

        <template #cell(active)="row">
          <b-form-checkbox
            v-model="row.item.active"
            name="check-button"
            :state="row.item.active == 1"
            :value="1"
            :unchecked-value="0"
            switch
            @change="selectChangeStatus(row)"
            v-if="user.permessi.includes('gestire attività e questionari')"
          >
          </b-form-checkbox>
          <b-icon
            v-else
            icon="circle-fill"
            :variant="row.item.active == 1 ? 'success' : 'danger'"
          ></b-icon>
        </template>
        <template #cell(filled)="row">
          <span class="text-justify">{{ row.item.filled }}</span>
        </template>
        <template #cell(azioni)="row">
          <b-row class="mt-n2">
            <!-- Button Icon -->
            <b-iconstack
              v-if="
                user.permessi.includes('somministrare questionari') &&
                row.item.activity.prof2 != 1
              "
              @click="selectThis(row)"
              v-b-popover.hover.top="'Visualizza'"
              font-scale="2.2"
              class="point m-1"
            >
              <b-icon
                stacked
                icon="circle-fill"
                variant="mdm-light-grey"
              ></b-icon>
              <b-icon
                stacked
                icon="zoom-in"
                scale="0.5"
                variant="secondary"
              ></b-icon>
            </b-iconstack>
            <b-iconstack
              v-if="
                user.permessi.includes('gestire attività e questionari') &&
                row.item.activity.prof2 != 1
              "
              @click="
                $router.push({
                  name: 'attivita',
                  params: {
                    section: 'questionario',
                    action: 'report',
                    quest: row.item.hashid
                  }
                })
              "
              v-b-popover.hover.top="'Report'"
              font-scale="2.2"
              class="point m-1"
            >
              <b-icon
                stacked
                icon="circle-fill"
                variant="mdm-light-grey"
              ></b-icon>
              <b-icon
                stacked
                icon="collection"
                scale="0.5"
                variant="secondary"
              ></b-icon>
            </b-iconstack>
            <b-iconstack
              v-else-if="
                user.permessi.includes('gestire attività e questionari') &&
                row.item.activity.prof2 === 1
              "
              @click="
                $router.push({
                  name: 'progetto prof2',
                  query: { activity: row.item.activity.name }
                })
              "
              v-b-popover.hover.top="'Report'"
              font-scale="2.2"
              class="point m-1"
            >
              <b-icon
                stacked
                icon="circle-fill"
                variant="mdm-light-grey"
              ></b-icon>
              <b-icon
                stacked
                icon="collection-fill"
                scale="0.5"
                variant="secondary"
              ></b-icon>
            </b-iconstack>
            <template v-if="company && company.level !== 3">
              <b-iconstack
                v-if="user.permessi.includes('somministrare questionari')"
                v-b-popover.hover.top="'Somministra'"
                @click="
                  row.item.active
                    ? $router.push({
                        name: 'attivita',
                        params: {
                          section: 'questionario',
                          action: 'somministra',
                          quest: row.item.hashid
                        }
                      })
                    : null
                "
                font-scale="2.2"
                class="point m-1"
                :class="{ half_opacity: !row.item.active }"
              >
                <b-icon
                  stacked
                  icon="circle-fill"
                  variant="mdm-light-grey"
                ></b-icon>
                <b-icon
                  stacked
                  icon="clipboard-plus"
                  scale="0.5"
                  variant="secondary"
                ></b-icon>
              </b-iconstack>
            </template>
            <b-iconstack
              v-if="
                row.item.created_by &&
                user.permessi.includes('gestire attività e questionari')
              "
              @click="
                $router.push({
                  name: 'attivita',
                  params: {
                    section: 'questionario',
                    action: 'modifica',
                    quest: row.item.hashid
                  }
                })
              "
              v-b-popover.hover.top="'Modifica'"
              font-scale="2.2"
              class="point m-1"
            >
              <b-icon
                stacked
                icon="circle-fill"
                variant="mdm-light-grey"
              ></b-icon>
              <b-icon
                stacked
                icon="pencil-square"
                scale="0.5"
                variant="secondary"
              ></b-icon>
            </b-iconstack>
          </b-row>
          <!-- <router-link
            class="
              info_gradient
              btn btn-info btn-sm
              px-3
              
              
            "
            :to="{ name: 'questionari', params: { quest: row.item.hashid } }"
          >
            Dettaglio</router-link
          > -->
        </template>
      </b-table>

      <p v-if="surveys && surveys.length == 0">Nessun questionario qui</p>
      <b-pagination
        v-model="filterSetted.page"
        :total-rows="total"
        :per-page="perPage"
        align="center"
        :size="$root.isMobile ? 'sm' : 'lg'"
      ></b-pagination>
      <b-modal
        ref="modal-showsurvey"
        centered
        :title="modalTitle"
        size="xl"
        hide-footer
      >
        <SurveyShower
          v-if="selectedRow && selectedRow.item"
          :survey="selectedRow.item"
          class="px-3"
        />
      </b-modal>

      <b-modal
        ref="modal-statusconfirm"
        title="Conferma operazione"
        centered
        size="lg"
        hide-footer
        @hidden="cancelOp"
      >
        <div v-if="selectedItem !== null">
          <h5 class="">
            Sei sicuro di voler
            {{
              surveys[selectedItem].active === 1 ? "attivare " : "disattivare "
            }}
            "{{ surveys[selectedItem].name }}" ?
          </h5>
          <p v-if="surveys[selectedItem].active === 1">
            Attivando il questionario sarà disponibile per la compilazione.
          </p>
          <p v-else>
            Disattivando il questionario non sarà più disponibile per la
            compilazione.
          </p>
          <div class="mt-4 text-right">
            <b-button variant="popup-button mr-3" @click="cancelOp"
              >No, annulla</b-button
            >
            <b-button variant="popup-button" @click="changeStatus"
              >Sì, procedi</b-button
            >
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services"
import { mapState, mapActions } from "vuex"
import SurveyShower from "@/components/attivita/SurveyShower.vue"
export default {
  name: "HomeQuestionari",
  components: { SurveyShower },
  data() {
    return {
      filterSetted: {
        search: null,
        position_ref: null,
        page: 1,
        take: 10,
        activity: null
      },
      surveys: null,
      fields: [
        { key: "name", label: "Nome" },
        { key: "description", label: "Descrizione" },
        { key: "attivita", label: "Raccolta" },
        { key: "active", label: "Stato" },
        { key: "filled", label: "Somministrati" },
        { key: "azioni", label: "" }
      ],
      takeOptions: [10, 25, 50, 100],
      activities: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      perPage: 0,
      selectedRow: null,
      modalTitle: null,
      selectedItem: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["company"])
  },
  watch: {
    filterSetted: {
      handler(prev, post) {
        // console.log("changed prev", prev.status, post);
        // console.log("changed post", post.status);
        this.fetchData()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      var self = this
      supportService
        .getSurveys(this.filterSetted)
        .then(function (response) {
          self.surveys = response.data.data.data
          self.filterSetted.page = response.data.data.current_page
          self.total = response.data.data.total
          self.fromPage = response.data.data.from
          self.toPage = response.data.data.to
          self.perPage = response.data.data.per_page
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le attività")
          // console.log(err);
        })
    },
    fetchAttivita() {
      var self = this
      supportService
        .getActivities(this.filterSetted)
        .then(function (res) {
          self.activities = res.data.data
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le attività")
          // console.log(err);
        })
    },
    selectThis(row) {
      this.selectedRow = row
      this.modalTitle = row.item.name
      this.$refs["modal-showsurvey"].show()
    },
    changeStatus() {
      var formData = {
        hash: this.surveys[this.selectedItem].hashid,
        form: {
          active: this.surveys[this.selectedItem].active
        }
      }
      var self = this
      supportService
        .companyUpdateSurveyActive(formData)
        .then(function (res) {
          window.scrollTo(0, 0)
          self.successAlert("Stato questionario aggiornato con successo!")
          // self.fetchData();
          self.$refs["modal-statusconfirm"].hide()
        })
        .catch(function (err) {
          // console.log(err);
          self.$refs["modal-statusconfirm"].hide()
          window.scrollTo(0, 0)
          self.errorAlert(
            "Non è stato possibile aggiornare lo stato questionario"
          )
          self.fetchData()
        })
    },
    selectChangeStatus(row) {
      // console.log("here");
      this.selectedItem = row.index
      this.$refs["modal-statusconfirm"].show()
    },
    cancelOp() {
      this.$refs["modal-statusconfirm"].hide()
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
    this.fetchAttivita()
  }
}
</script>
