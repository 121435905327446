<template>
  <div>
    <b-row align-v="stretch">
      <b-col class="px-0">
        <div class="mr-lgx-2 d-flex flex-column h-100">
          <h3 class="mt-2 pt-4 text-left">
            <b-icon
              icon="upc-scan"
              variant="secondary"
              font-scale="1.2"
              class="mr-1"
            ></b-icon>
            Codice utente
          </h3>
          <section
            class="bg-white general_border puntofarmaCard p-2 p-md-3 p-lg-4 h-100"
          >
            <p class="text-left">
              Il tuo codice utente ti permette di essere identificato in modo
              sicuro da altri utenti in piattaforma.
            </p>
            <b-row cols="1" class="my-4">
              <b-col class="text-left">
                <b-button v-b-toggle.collapse-usercode variant="secondary"
                  >{{ codeVisible ? "Nascondi" : "Mostra" }}
                  codice
                  {{ btnLabel }}</b-button
                >
              </b-col>
              <b-collapse id="collapse-usercode" class="my-2 text-left">
                <div class="py-2 px-3">
                  <h5 class="">Il tuo codice utente:</h5>
                  <p class="card-text text-secondary" style="font-size: 1.9rem">
                    <span class="mx-auto pl-4"> {{ code }}</span>
                    <span class="point ml-4">
                      <b-iconstack
                        id="copier"
                        @click="copyCode"
                        font-scale="2"
                        class="point"
                      >
                        <b-icon
                          stacked
                          icon="circle-fill"
                          variant="mdm-light-grey"
                        ></b-icon>
                        <b-icon
                          stacked
                          icon="back"
                          scale="0.5"
                          variant="secondary"
                        ></b-icon>
                      </b-iconstack>
                    </span>
                  </p>
                  <p class="text-justify mb-0">
                    Mostra questo codice al tuo caregiver affinché possa
                    trovarti ed inserirti nella sua lista di assistiti.
                  </p>
                  <p class="text-justify mb-0">
                    Nota: questo codice sarà
                    <u>valido fino alla mezzanotte di oggi</u>.
                  </p>
                </div>
              </b-collapse>
              <b-tooltip target="copier">
                {{ toolTipText }}
              </b-tooltip>
            </b-row>
          </section>
        </div>
      </b-col>
    </b-row>
    <!-- <h3 class="mt-2 text-left py-2">
      <b-icon
        icon="upc-scan"
        variant="secondary"
        font-scale="1.2"
        class="mr-1"
      ></b-icon>
      Codice utente
    </h3> -->
    <!--  <b-col class="bg-white general_border puntofarmaCard p-2 p-md-3 p-lg-4">
       <p class="text-left px-3">
        Il tuo codice utente ti permette di essere identificato in modo sicuro
        da altri utenti in piattaforma.
      </p> -->

    <!-- <b-row cols="1" class="text-left mx-0">
        <b-col>
          <b-button v-b-toggle.collapse-usercode variant="secondary"
            >{{ codeVisible ? "Nascondi" : "Mostra" }}
            codice
            {{ btnLabel }}</b-button
          >
        </b-col>
      </b-row> -->
    <!-- <b-row cols="1" class="mx-0">
        <b-collapse id="collapse-usercode" class="my-2">
          <div
            class="puntofarmaCard mt-4 border border-light-panel general_border py-2 px-3 col-12"
          >
            <h5 class="">Il tuo codice utente:</h5>
            <p class="card-text text-secondary" style="font-size: 1.9rem">
              <span class="mx-auto"> {{ code }}</span>
              <span
                class="ml-auto mr-2 point"
                style="position: absolute; right: 0"
              >
                <b-iconstack
                  id="copier"
                  @click="copyCode"
                  v-b-popover.hover.top="''"
                  font-scale="2"
                  class="point"
                >
                  <b-icon
                    stacked
                    icon="circle-fill"
                    variant="mdm-light-grey"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="back"
                    scale="0.5"
                    variant="secondary"
                  ></b-icon>
                </b-iconstack>
              </span>
            </p>
            <p class="text-justify mb-0">
              Mostra questo codice al tuo caregiver affinché possa trovarti ed
              inserirti nella sua lista di assistiti.
            </p>
            <p class="text-justify">
              Nota: questo codice sarà
              <u>valido fino alla mezzanotte di oggi</u>.
            </p>
          </div>
        </b-collapse>
        <b-tooltip target="copier">
          {{ toolTipText }}
        </b-tooltip>
      </b-row> 
    </b-col>-->
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { cittadinoService } from "@/_services";
export default {
  name: "ShowUserCode",
  data() {
    return {
      codeVisible: false,
      btnLabel: "utente",
      code: null,
      email: null,
      csendcodeVisible: false,
      sendingMail: false,
      toolTipText: "Copia codice",
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
    validEmail() {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.email.match(validRegex) ? true : false;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    getCode() {
      var self = this;
      cittadinoService
        .getCode()
        .then(function (res) {
          self.code = res.data.data.code;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile recuperare il codice");
        });
    },
    copyCode() {
      navigator.clipboard.writeText(this.code);
      this.toolTipText = "Codice copiato!";
      var self = this;
      setTimeout(function () {
        self.toolTipText = "Copia codice";
      }, 3000);
    },
    // sendCode() {
    //   this.sendingMail = true;
    //   var form = {
    //     hash: this.user.company.hashid,
    //     form: { email: this.email },
    //   };
    //   var self = this;
    //   cittadinoService
    //     .sendCode(form)
    //     .then(function (res) {
    //       window.scrollTo(0, 0);
    //       self.successAlert("Codice inviato correttamente!");
    //       self.csendcodeVisible = false;
    //       self.sendingMail = false;
    //     })
    //     .catch(function (err) {
    //       window.scrollTo(0, 0);
    //       self.errorAlert("non è stato possibile recuperare il codice");
    //       self.sendingMail = false;
    //     });
    // },
  },
  mounted() {
    this.getCode();
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      if (collapseId == "collapse-usercode") {
        this.codeVisible = isJustShown;
      }
    });
  },
};
</script>
