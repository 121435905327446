<template>
  <div class="py-3">
    <div>
      <div
        class="puntofarmaCard p-4"
        :class="$root.isMobile ? 'col-12' : 'col-10'"
      >
        <b-form @submit="onSubmit">
          <b-form-group
            label="Nome questionario*"
            label-for="name"
            description=""
            class="text-left"
          >
            <b-form-input
              v-capitalize-first
              v-model="form.name"
              type="text"
              id="name"
              placeholder="Inserisci nome questionario..."
              class="my-2"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Descrizione"
            label-for="description"
            description=""
            class="text-left"
            v-capitalize-first
          >
            <b-form-input
              v-capitalize-first
              v-model="form.description"
              type="text"
              id="description"
              placeholder="Inserisci descrizione questionario..."
              class="my-2"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Informativa"
            label-for="info"
            description="Informativa/disclaimer per il questionario che dovrà essere accettata prima della compilazione. Lasciare vuoto questo campo se non necessario."
            class="text-left"
          >
            <b-form-textarea
              v-capitalize-first
              v-model="form.info"
              type="text"
              id="info"
              placeholder="inserisci informativa questionario"
              class="my-2"
              rows="4"
            ></b-form-textarea>
          </b-form-group>

          <p class="text-left mt-3 fw-600">Tipologia*</p>
          <b-form-radio-group
            label="Tipologia"
            class="text-left"
            name="q_type"
            v-model="form.type"
          >
            <b-form-radio :value="0">Anonimo</b-form-radio>
            <b-form-radio :value="1">Pseudo-anonimo</b-form-radio>
            <b-form-radio :value="2">In chiaro</b-form-radio>
          </b-form-radio-group>
          <p class="fs-6 text-muted text-left mt-1">
            {{ typeDescriptions[form.type] }}
          </p>
          <p class="text-left fw-600">Domande*:</p>
          <p class="fs-6 text-muted text-left mt-1">
            {{ typeMemo[form.type] }}
          </p>
          <b-overlay :show="loading" rounded="sm">
            <draggable v-model="form.template">
              <b-card
                v-for="(domanda, ind) in form.template"
                :key="ind"
                class="p-1 border border-secondary general_border text-left my-3"
                style="cursor: move"
              >
                <h5 class="text-secondary">{{ "Domanda " + (ind + 1) }}</h5>
                <b-form-group>
                  <b-form-input
                    v-capitalize-first
                    v-model="domanda.q"
                    type="text"
                    placeholder="Inserisci domanda..."
                    class="my-2"
                    required
                  ></b-form-input>
                  <b-form-radio-group
                    v-model="domanda.o"
                    :name="'domanda' + (ind + 1)"
                    :id="'domanda' + (ind + 1)"
                    class="mt-2"
                    required
                  >
                    <b-form-radio :value="null">Testo libero</b-form-radio>
                    <b-form-radio
                      :value="Array.isArray(domanda.o) ? domanda.o : []"
                      >Opzioni multiple</b-form-radio
                    >
                    <b-form-radio :value="'file'"
                      >Caricamento file</b-form-radio
                    >
                  </b-form-radio-group>
                  <b-form-tags
                    v-if="domanda.o !== null && Array.isArray(domanda.o)"
                    v-model="domanda.o"
                    class="my-2"
                    placeholder="aggiungi opzioni risposta"
                    remove-on-delete
                    duplicate-tag-text="Opzioni duplicate"
                    tag-variant="secondary px-3 "
                    add-button-text="aggiungi"
                  ></b-form-tags>
                </b-form-group>
                <template v-if="domanda.o === 'file'">
                  <b-row>
                    <span class="mt-2 mr-1 fw-bolder">Tipologia file: </span>
                    <b-form-radio-group
                      v-model="domanda.fa"
                      :name="'domanda_fa' + (ind + 1)"
                      :id="'domanda_fa' + (ind + 1)"
                      class="mt-2"
                      required
                    >
                      <b-form-radio :value="'.pdf,.zip'"
                        >zip e PDF</b-form-radio
                      >
                      <!-- <b-form-radio :value="'.csv,.xlsx,.xls'">excel</b-form-radio> -->
                      <b-form-radio :value="'image/*'">immagine</b-form-radio>
                      <b-form-radio :value="'*'"
                        >qualsiasi formato</b-form-radio
                      >
                    </b-form-radio-group>
                  </b-row>
                  <b-row>
                    <span class="mt-2 mr-1 fw-bolder">Caricamento: </span>
                    <b-form-radio-group
                      v-model="domanda.fm"
                      :name="'domanda_fm' + (ind + 1)"
                      :id="'domanda_fm' + (ind + 1)"
                      class="mt-2"
                      required
                    >
                      <b-form-radio :value="false"
                        >un file alla volta</b-form-radio
                      >
                      <b-form-radio :value="true">multiplo</b-form-radio>
                    </b-form-radio-group>
                  </b-row>
                </template>
                <div class="d-flex justify-content-end">
                  <b-button
                    variant="outline-secondary font-weight-bold"
                    size="sm"
                    @click="removeItem(ind)"
                    >Elimina domanda
                  </b-button>
                </div>
              </b-card>
            </draggable>
          </b-overlay>
          <div class="d-flex justify-content-center my-2 pb-3 pt-1">
            <b-button
              type="button"
              variant="outline-secondary"
              @click="addNew"
              class="mx-auto"
            >
              <b-icon icon="plus"></b-icon> Aggiungi domanda
            </b-button>
            <!-- <b-button
              type="button"
              variant="secondary"
              @click="addCompilate"
              class="mx-auto"
              >Aggiungi domande finali</b-button
            > -->

            <b-button
              type="submit"
              variant="secondary text-white "
              class="mx-auto"
              >Salva Questionario</b-button
            >
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  name: "NuovoQuestionario",
  props: ["activityhash"],
  components: {
    draggable,
  },
  data() {
    return {
      loading: false,
      opzione: null,
      precompilate: [
        {
          q: "Stai prendendo dei farmaci per questo problema?",
          o: ["si", "no"],
          a: "",
          //order: 1,
        },
        {
          q: "Se sì: quali?",
          o: null,
          a: "",
          //order: 2,
        },
        {
          q: "Stai prendendo altri dei farmaci non correlati al problema?",
          o: ["si", "no"],
          a: "",
          //order: 3,
        },
        {
          q: "Se sì: quali?",
          o: null,
          a: "",
          //order: 4,
        },
        {
          q: "Sei allergico a qualche farmaco?",
          o: ["si", "no"],
          a: "",
          // order: 5,
        },
        {
          q: "Se sì: Quali?",
          o: null,
          a: "",
          //order: 6,
        },
        {
          q: "Vuoi aggiungere qualcosa?",
          o: null,
          a: "",
          // order: 7,
        },
      ],
      typeDescriptions: {
        0: "*il questionario non prevede alcun dato personale",
        1: "*il questionario prevede un'associazione ad un paziente sottoforma di pseudonimo",
        2: "*il questionario contiene dati personali del paziente",
      },
      typeMemo: {
        0: "Ricorda di non inserire domande relative a dati anagrafici personali.",
        1: "Ricorda di inserire una domanda per lo pseudonimo. Non inserire domande relative a dati anagrafici personali.",
        2: "Se il questionario non viene compilato direttamente dall'utente, inserisci le domande relativi ai dati anagrafici necessari per il questionario.",
      },
      form: {
        name: null,
        description: null,
        info: "Attenzione: continuando ad usare l'app, acconsenti al trattamento dei tuoi dati sanitari per informare l'operatore sanitario per un consulto e per finalità di analisi in forma anonima. Maggiori dettagli saranno reperibili alla Informativa Privacy.",
        type: 0,
        activity_id: null,
        template: [],
      },
    };
  },
  computed: {
    len: function () {
      if (this.form && this.form.template) {
        return this.form.template.length;
      }
      return 0;
    },
  },
  watch: {
    activityhash(val) {
      this.form.activity_id = val;
    },
  },
  methods: {
    addNew() {
      this.loading = true;
      var newQ = {
        q: null,
        o: null,
        a: null,
      };
      this.form.template.push(newQ);

      this.loading = false;
    },
    addCompilate() {
      this.form.template.push(...this.precompilate);
    },
    removeItem(index) {
      var newArray = [];
      Object.keys(this.form.template).map((i) => {
        if (parseInt(i) != index) {
          newArray.push(this.form.template[i]);
        }
      });
      this.form.template = newArray;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$emit("send-questionario", this.form);
    },
  },
  created() {
    if (this.activityhash) {
      this.form.activity_id = this.activityhash;
    }
  },
};
</script>
