<template>
  <div>
    <SearchCompanyFilters
      @update-filters="handleUpdateFilters($event)"
    ></SearchCompanyFilters>
    <div v-if="loading" class="col-12 text-center">
      <b-spinner></b-spinner>
    </div>
    <b-row
      v-else-if="companiesOptions.length > 0 && hasAtLeastOneFilterSetted"
      class="mt-2 pl-3"
    >
      <b-form-group
        label-class="text-left fw-bolder d-flex flex-column align-items-start"
        label="Seleziona"
        class="fw-bolder"
      >
        <b-form-radio
          v-for="(company, index) in companiesOptions"
          :key="index"
          v-model="selectedCompany"
          :value="company.value"
          name="company"
          class="mb-2 text-uppercase col-12 text-left"
        >
          <span class="d-flex align-items-center"
            >{{ company.text }}
            <img
              v-if="company.convenzionata"
              src="@/assets/concura-logo.svg"
              alt="appLogo"
              width="35px"
              class="ml-2"
          /></span>
        </b-form-radio>
      </b-form-group>
    </b-row>
    <b-row
      v-else-if="companiesOptions.length === 0 && hasFetched"
      class="mt-2 ml-2"
    >
      <b-col class="col-12 text-center">
        <p class="text-secondary">Nessun centro erogatore trovato</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { utilityService, supportService } from "@/_services"
import { mapState, mapActions } from "vuex"
import SearchCompanyFilters from "@/components/utilities/SearchCompanyFilters.vue"
import axios from "axios"

export default {
  name: "PickCentroErogatoreForNuovaPrenotazione",
  components: {
    SearchCompanyFilters
  },
  data() {
    return {
      subtypesOptions: [],
      form: {
        // denominazione: null,
        // regione: null,
        // provincia: null,
        // comune: null,
        // subtype: null,
        // cap: null
      },
      loading: false,
      cancelTokenSource: null,
      companiesOptions: [],
      selectedCompany: null,
      hasFetched: false
    }
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        if (
          Object.values(newVal).every(
            (val) => val === null || val === "" || val === undefined
          )
        ) {
          this.$emit("selectedCompany", null)
          this.selectedCompany = null
          return
        }
        this.doSearch()
      },
      deep: true
    },
    selectedCompany: function (newVal, oldVal) {
      this.$emit(
        "selectedCompany",
        this.companiesOptions.find((item) => item.value === newVal)
          .companyObject
      )
    },
    companiesOptions: function (newVal, oldVal) {
      if (
        this.selectedCompany &&
        !newVal.map((item) => item.value).includes(this.selectedCompany)
      ) {
        this.selectedCompany = null
      }
    }
  },
  computed: {
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
    hasAtLeastOneFilterSetted() {
      return Object.values(this.form).some(
        (val) => val !== null && val !== "" && val !== undefined
      )
    }
  },
  methods: {
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    getSubtypes() {
      var self = this
      utilityService
        .getSubtypes()
        .then(function (response) {
          self.subtypesOptions = Object.values(response.data.data)
            .concat()
            .flat()
            .map(function (item) {
              return {
                value: item.hashid,
                text: item.name
              }
            })
        })
        .catch(function (error) {
          console.error("Error fetching subtypes:", error)
        })
    },
    removeNullOrEmptyDataBeforeSend(data) {
      var self = this
      var newData = {}
      Object.keys(data).forEach(function (key) {
        if (data[key] !== null && data[key] !== "") {
          newData[key] = data[key]
        }
      })
      return newData
    },
    doSearch() {
      var self = this
      this.loading = true
      // Annulla la richiesta precedente se esiste
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("Request canceled by the user.")
      }

      // Crea un nuovo CancelToken per la nuova richiesta
      this.cancelTokenSource = axios.CancelToken.source()

      supportService
        .searchCompanyBySubtype(
          this.removeNullOrEmptyDataBeforeSend(this.form),
          this.cancelTokenSource.token
        )
        .then(function (response) {
          self.companiesOptions = response.data.data.map(function (item) {
            return {
              value: item.hashid,
              text: item.denominazione,
              companyObject: item,
              convenzionata: item.convenzionata
            }
          })
        })
        .catch(function (error) {
          if (axios.isCancel(error) || error == "canceled") {
            console.log("Request canceled by the user")
          } else {
            self.errorAlert(
              "non è stato possibile completare la ricerca dei centri erogatori"
            )
          }
        })
        .finally(() => {
          self.loading = false
          self.hasFetched = true
        })
    },
    handleUpdateFilters(newVal) {
      this.form = JSON.parse(JSON.stringify(newVal))
    }
  },
  mounted() {
    // this.$emit("selectedCompany", null)
  },
  created() {
    this.getSubtypes()
    this.getRegioni()
    this.getProvince({})
    this.getComuni({})
  },
  beforeDestroy() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel("Request canceled by the user.")
    }
  }
}
</script>

<style scoped></style>
