<template>
  <div>
    <div class="d-flex justify-content-between">
      <router-link
        class="text-secondary no__hover"
        :to="{ name: 'screening', params: { section: 'raccolte' } }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="secondary"></b-icon>
        Raccolte
      </router-link>
    </div>
    <div class="py-3" v-if="template">
      <h4 class="text-prim-grad-1 text-left pb-2 mb-4">
        Questionari {{ template.name }} ({{ template.activity.name }})
        somministrati
      </h4>

      <p class="text-justify text-medium-grey">
        Clicca su una "vedi" per visualizzare l'esito del questionario. Per
        scaricare l'elenco completo in formato pdf, clicca su "scarica tutto in
        formato pdf".
      </p>
      <div class="d-flex mb-3">
        <b-button
          variant="secondary text-white "
          class="m-1"
          @click="getReport"
          :disabled="total == 0"
          >Scarica tutto in formato pdf</b-button
        >
        <b-button
          variant="secondary text-white "
          class="m-1"
          @click="getReportXsl"
          :disabled="total == 0"
          >Scarica tutto in formato excel</b-button
        >
      </div>
      <div class="d-flex align-items-center">
        <b-form-group size="sm" class="text-left mr-3">
          <b-form-select
            v-capitalize-first-select
            class="align-items-center align-content-center custom-select-2"
            name="vedi"
            v-model="filterSetted.take"
            :options="takeOptions"
          >
          </b-form-select>
        </b-form-group>
        <span class="text-info mr-1" v-if="total > 0"
          >{{ fromPage }}-{{ toPage }} di
        </span>
        <span class="text-info"> {{ total }}</span>
      </div>
      <div class="mt-2">
        <b-table
          borderless
          class="puntofarmaCard"
          hover
          responsive
          :fields="fields"
          :items="surveys"
          :small="$root.isMobile"
        >
          <template #cell(company_denominazione)="row">
            <span class="text-left text-capitalize">{{
              row.item.company_denominazione
            }}</span>
          </template>
          <template #cell(created_at)="row">
            <span class="text-left text-capitalize">{{
              row.item.created_at | moment("DD/MM/YYYY")
            }}</span>
          </template>
          <template #cell(user_mail)="row">
            <span class="text-left">{{
              row.item.user_mail ? row.item.user_mail : "---"
            }}</span>
          </template>
          <template #cell(azioni)="row">
            <b-button size="sm" variant="info px-3" @click="showThis(row)"
              >Vedi</b-button
            >
            <b-button
              size="sm"
              variant="secondary px-3"
              @click="downloadOne(row)"
              >Scarica</b-button
            >
          </template>
        </b-table>
        <p v-if="surveys && surveys.length < 1">Nessun questionario qui</p>
        <b-pagination
          v-model="filterSetted.page"
          variant="secondary text-secondary"
          :total-rows="total"
          :per-page="10"
          align="center"
          :size="$root.isMobile ? 'sm' : 'lg'"
        ></b-pagination>
        <b-modal
          ref="show-filleditem"
          hide-footer
          title="Questionario"
          size="xl"
        >
          <FilledSurveyShower
            v-if="selectedItem"
            :response="selectedItem.response"
            :score="selectedItem.score"
          />
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services"
import { mapState, mapActions } from "vuex"
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue"
export default {
  name: "QuestionariCompilati",
  components: { FilledSurveyShower },
  data() {
    return {
      filterSetted: {
        take: 10,
        page: 1
      },
      surveys: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      fields: [
        { key: "company_denominazione", label: "Azienda" },
        { key: "created_at", label: "Data" },
        { key: "user_mail", label: "Utente" },
        // { key: "active", label: "Stato" },
        { key: "azioni", label: "" }
      ],
      takeOptions: [10, 25, 50, 100],
      template: null,
      selectedItem: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    })
  },
  watch: {
    filterSetted: {
      handler(prev, post) {
        // console.log("changed prev", prev.status, post);
        // console.log("changed post", post.status);
        this.fetchData()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      var self = this
      var dat = {
        hash: this.$route.params.quest,
        filters: this.filterSetted
      }
      supportService
        .getFilledForSurvey(dat)
        .then(function (res) {
          // console.log(res);
          self.surveys = res.data.data.data.data
          self.filterSetted.page = res.data.data.data.current_page
          self.total = res.data.data.data.total
          self.fromPage = res.data.data.data.from
          self.toPage = res.data.data.data.to
          self.template = res.data.data.survey
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    showThis(row) {
      this.selectedItem = row.item
      this.$refs["show-filleditem"].show()
    },
    getReport() {
      var self = this
      supportService
        .surveyReport(this.$route.params.quest)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download =
            self.template.name + "_" + self.template.activity.name + ".pdf"
          link.click()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    getReportXsl() {
      var self = this
      supportService
        .surveyXlsReport(this.$route.params.quest)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download =
            self.template.name + "_" + self.template.activity.name + ".xlsx"
          link.click()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    downloadOne(row) {
      var self = this
      var selected = row
      supportService
        .downloadFilledSurvey(selected.item.hashid)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download =
            self.template.name + "_" + self.template.activity.name + ".pdf"
          link.click()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
