<template>
  <b-card
    style="width: 250px; min-width: 250px"
    class="d-flex flex-column align-items-center justify-content-center mb-2 general_hover point shadow_2 py-2"
    :class="isSelected ? 'border-secondary' : 'border-white'"
  >
    <div v-if="azienda" class="d-flex flex-column align-items-center h-100">
      <div
        class="d-flex flex-column align-items-center justify-content-center position-relative h-100"
        :class="{ 'opacity-0': showInfo, 'opacity-1': !showInfo }"
      >
        <div
          @click="selectThis()"
          class="company-card-header d-flex justify-content-end align-items-start mb-2"
          :class="{ '': !azienda.profile_pic }"
          :style="companyPicStyle"
          style="width: 230px; height: 100px"
        >
          <span
            @click="selectThis()"
            @click.stop="toggleInfo"
            class="mt-2 mr-2 d-flex justify-content-end align-items-start"
          >
            <b-icon
              font-scale="1.5"
              icon="info-circle"
              variant="secondary"
            ></b-icon>
          </span>
        </div>
        <h5 class="text-center text-secondary px-2 py-1">
          {{ azienda.denominazione }}
        </h5>
        <span
          v-if="showHeart"
          @click="$emit('toggle-preferito', azienda)"
          class="mt-auto mb-1"
          style="z-index: 1"
        >
          <b-icon
            font-scale="1.5"
            class="text-center"
            :icon="
              user.farmacia_preferita &&
              azienda.hashid === user.farmacia_preferita.hashid
                ? 'heart-fill'
                : 'heart'
            "
            variant="secondary"
          >
          </b-icon>
        </span>
        <span
          v-if="suggest"
          @click="$emit('add-suggestion', azienda)"
          style="z-index: 1"
        >
          <b-button class="text-left my-auto">
            <b-icon icon="plus"></b-icon> aggiungi
          </b-button>
        </span>
      </div>

      <div
        style="width: 230px"
        class="position-absolute h-100 justify-content-center align-items-center"
        :class="{ 'opacity-0': !showInfo, 'opacity-1': showInfo }"
      >
        <div class="justify-content-end align-items-start mb-2 py-0">
          <div
            @click="selectThis()"
            class="d-flex justify-content-end align-items-start"
          >
            <span @click.stop="toggleInfo" class="mt-2 mr-2">
              <b-icon
                font-scale="1.5"
                icon="info-circle-fill"
                variant="secondary"
              ></b-icon>
            </span>
          </div>
        </div>
        <b-row align-v="center" class="mt-n5 h-100 justify-content-center">
          <h6 @click="selectThis()" class="text-secondary text-center px-5">
            <span class="font-weight-bold">Indirizzo:</span><br />
            {{ azienda.indirizzo }}, {{ azienda.frazione }} <br />
            <span class="text-capitalize">{{ azienda.comune }}</span>
          </h6>
        </b-row>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapState } from "vuex"
export default {
  name: "CompanyCard",
  props: ["azienda", "isSelected", "showHeart", "suggest"],
  computed: {
    ...mapState("utente", ["status", "user"]),
    companyPicStyle() {
      if (this.azienda.profile_pic) {
        return {
          backgroundImage: "url(" + this.azienda.profile_pic + ")",
          backgroundPosition: "center center",
          backgroundSize: "contain"
        }
      } else {
        return {
          backgroundImage: `url(${require("@/assets/no_avatar.svg")}`,
          backgroundPosition: "center center",
          backgroundSize: "contain"
        }
      }
    }
  },
  data() {
    return {
      showInfo: false
    }
  },
  methods: {
    selectThis() {
      this.$emit("company-selection", this.azienda)
    },
    toggleInfo() {
      this.showInfo = !this.showInfo
    }
  }
}
</script>
<style lang="scss" scoped>
.company-card-header {
  width: 250px;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  // height: 130px;
  border-radius: 8px;
}

.card-body {
  padding: 0;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.d-flex.flex-column.align-items-center.justify-content-center.mb-2.general_hover.point.shadow_2.py-2 {
  border-width: 3px;
}
</style>
