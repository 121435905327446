<template>
  <div v-if="company">
    <h4 class="text-left pb-1">Sovrapposizione appuntamenti tra servizi</h4>
    <p class="text-justify">
      Se questa opzione è disattivata, il sistema impedirà la sovrapposizione di
      appuntamenti per servizi e prestazioni diverse nella stessa fascia oraria:
      non sarà possibile, ad esempio, ricevere prenotazioni per un ordine e un
      teleconsulto nello stesso orario.
    </p>

    <b-row cols="1">
      <b-col class="text-left">
        <b-form-checkbox
          v-model="form.overlap"
          name="overlap_settings"
          :value="true"
          :unchecked-value="false"
          @change="saveChanges"
        >
          {{ form.overlap ? "Consentita" : "Non consentita" }} sovrapposizione
          degli appuntamenti
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div class="mt-4">
      <h4 class="text-left pb-1">Autorizzazione per l'emissione dei referti</h4>
      <p class="text-justify">
        Puoi richiedere che gli operatori sanitari della struttura autorizzino
        l'emissione dei referti verificando un codice OTP inoltrato via SMS.
      </p>
      <b-row cols="1">
        <b-col class="text-left">
          <b-form-checkbox
            v-model="formReferti.clinical_report_verification"
            name="referti_settings"
            :value="1"
            :unchecked-value="0"
            @change="saveRefertiSettings"
          >
            {{
              formReferti.clinical_report_verification
                ? "Richiesta"
                : "Non richiesta"
            }}
            autorizzazione OTP per l'emissione dei referti
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";
export default {
  name: "OtherSettings",
  props: [],
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  data() {
    return {
      form: {
        overlap: false,
      },
      formReferti: {
        clinical_report_verification: false,
      },
    };
  },
  watch: {
    company: function (val) {
      if (val) {
        this.form.overlap = val.overlap_reservations;
        this.formReferti.clinical_report_verification =
          val.clinical_report_verification;
      }
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    saveChanges() {
      const self = this;
      companyService
        .changeOverlapReservationsSetting(this.form)
        .then(function (res) {
          console.log(res);
          window.scrollTo(0, 0);
          self.successAlert("Impostazioni modificate correttamente");
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile modificare le impostazioni");
        });
    },
    saveRefertiSettings() {
      const self = this;
      companyService
        .changeReportOption(this.formReferti)
        .then(function (res) {
          console.log(res);
          window.scrollTo(0, 0);
          self.successAlert("Impostazione referti modificata correttamente");
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile modificare le impostazioni");
        });
    },
  },
};
</script>
