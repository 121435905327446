import { render, staticRenderFns } from "./Analitiche.vue?vue&type=template&id=50cf0171&scoped=true&"
import script from "./Analitiche.vue?vue&type=script&lang=js&"
export * from "./Analitiche.vue?vue&type=script&lang=js&"
import style0 from "./Analitiche.vue?vue&type=style&index=0&id=50cf0171&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50cf0171",
  null
  
)

export default component.exports