<template>
  <div v-if="canBeShown">
    <span @click="$bvModal.show('note-update')" class="point">
      <b-iconstack v-if="buttonIconStyle" font-scale="1.8" class="point">
        <b-icon stacked icon="circle-fill" variant="mdm-light-grey"></b-icon>
        <b-icon
          stacked
          icon="pencil-fill"
          scale="0.5"
          variant="secondary"
        ></b-icon>
      </b-iconstack>
      <b-icon v-else icon="pencil-fill" variant="secondary"></b-icon>
    </span>
    <b-modal
      id="note-update"
      title="Aggiorna le tue note di prenotazione"
      hide-footer
      centered
      size="lg"
    >
      <p class="text-justify p-2 bg-extra-light general_border">
        In questo spazio puoi inserire informazioni o comunicazioni rilevanti
        per questa specifica prenotazione.
      </p>

      <b-form
        @submit.prevent="doNoteUpdate"
        @reset="resetForm"
        class="px-2 px-lg-3"
      >
        <label cols="2" for="notes" class="">Nuova nota:</label>
        <b-form-input
          v-capitalize-first
          cols="8"
          focused
          type="text"
          name="notes"
          v-model="newNotes"
          required
        >
        </b-form-input>
        <div class="text-right pt-3">
          <b-button
            variant="popup-button"
            title="salva"
            type="reset"
            class="mx-2"
            >Annulla</b-button
          >
          <b-button
            v-if="!submitting"
            cols="2"
            variant="popup-button"
            title="salva"
            type="submit"
            class="mx-2"
            >Salva</b-button
          >
          <b-button type="button" class="mx-2" variant="popup-button" v-else>
            <b-spinner small></b-spinner>
          </b-button>
        </div>
      </b-form>
      <p v-if="showError" class="text-danger">
        Non è stato possibile aggiornare le note
      </p>
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { reservationService } from "@/_services"

export default {
  name: "UpdateNotesComponent",
  props: {
    hashid: {
      type: String,
      required: true
    },
    reserdate: {
      type: String,
      required: false
    },
    buttonIconStyle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newNotes: null,
      showError: false,
      canBeShown: false,
      submitting: false
    }
  },
  methods: {
    doNoteUpdate() {
      //
      this.submitting = true
      const self = this
      const data = { notes: this.newNotes }
      reservationService
        .updateReservationNotes(data, this.hashid)
        .then(function (res) {
          self.resetForm()
          self.$emit("note-updated", res.data.data)
          self.submitting = false
        })
        .catch(function (err) {
          self.showError = true
          self.submitting = false
        })
    },
    resetForm() {
      this.newNotes = null
      this.$bvModal.hide("note-update")
    }
  },
  mounted() {
    if (this.reserdate) {
      var now = moment()
      var date = moment(this.reserdate)
      this.canBeShown = now.isSameOrBefore(date)
    } else {
      this.canBeShown = false
    }
  }
}
</script>
