<template>
  <b-container fluid>
    <b-container fluid class="px-0 text-left">
      <b-row class="mb-2">
        <b-col class="pl-0">
          <h4 class="text-left pb-1 mb-lg-4">Impostazioni di sistema</h4>
        </b-col>
      </b-row>
      <b-row>
        <ImpostazioniSistemaEsami />
      </b-row>
    </b-container>
  </b-container>
</template>
<script>
import ImpostazioniSistemaEsami from "@/components/admin/ImpostazioniSistemaEsami.vue"
export default {
  name: "ImpostazioniSistema",
  components: {
    ImpostazioniSistemaEsami
  }
}
</script>
