<template>
  <div>
    <b-form
      id="form_nuova_soap"
      @submit.prevent="storeNewAnnotation"
      @reset="resetEdit"
    >
      <b-row class="p-3 justify-content-between flex-wrap" align-v="start">
        <b-col class="px-0 col-12 col-md-2 col-xl-1 my-1">
          <h6 class="mt-2 pt-1">Soggettivo*</h6>
        </b-col>
        <b-col class="pl-0 point col-12 col-md-10 col-xl-11 my-1">
          <b-form-textarea
            v-capitalize-first
            v-model="localAnnotation.subjective"
            name="soap_subjective"
            placeholder="Impressioni, sintomi riportati dal paziente, storia familiare..."
            rows="3"
            required
          >
          </b-form-textarea>
        </b-col>
      </b-row>
      <b-row class="p-3 justify-content-between flex-wrap" align-v="start">
        <b-col class="px-0 col-12 col-md-2 col-xl-1 my-1">
          <h6 class="mt-2 pt-1">Obiettivo*</h6>
        </b-col>
        <b-col class="pl-0 point col-12 col-md-10 col-xl-11 my-1">
          <b-form-textarea
            v-capitalize-first
            v-model="localAnnotation.objective"
            name="soap_objective"
            placeholder="Segni, esame obbiettivo, indagini strumentali..."
            rows="3"
            required
          >
          </b-form-textarea>
        </b-col>
      </b-row>
      <b-row class="p-3 justify-content-between flex-wrap" align-v="start">
        <b-col class="px-0 col-12 col-md-2 col-xl-1 my-1">
          <h6 class="mt-2 pt-1">Valutazione*</h6>
        </b-col>
        <b-col class="pl-0 point col-12 col-md-10 col-xl-11 my-1">
          <b-form-textarea
            v-capitalize-first
            v-model="localAnnotation.assessment"
            name="soap_assessment"
            placeholder="Valutazione, diagnosi, progressi..."
            rows="3"
            required
          >
          </b-form-textarea>
        </b-col>
      </b-row>
      <b-row class="p-3 justify-content-between flex-wrap" align-v="start">
        <b-col class="px-0 col-12 col-md-2 col-xl-1 my-1">
          <h6 class="mt-2 pt-1">Piano*</h6>
        </b-col>
        <b-col class="pl-0 point col-12 col-md-10 col-xl-11 my-1">
          <b-form-textarea
            v-capitalize-first
            v-model="localAnnotation.plan"
            name="soap_plan"
            placeholder="Raccomandazioni, procedure o trattamenti indicati, variazioni al piano..."
            rows="3"
            required
          >
          </b-form-textarea>
        </b-col>
      </b-row>
      <b-row class="p-3 justify-content-between flex-wrap" align-v="start">
        <b-col class="px-0 col-12 col-md-2 col-xl-1 my-1">
          <h6 class="mt-2 pt-1">Altro</h6>
        </b-col>
        <b-col class="pl-0 point col-12 col-md-10 col-xl-11 my-1">
          <b-form-textarea
            v-capitalize-first
            v-model="localAnnotation.content"
            name="soap_content"
            placeholder="Altre osservazioni rilevanti..."
            rows="3"
          >
          </b-form-textarea>
        </b-col>
      </b-row>
      <b-row align-v="center" class="py-2 mt-2 justify-content-between">
        <b-col> </b-col>
        <b-col class="text-right">
          <b-button type="reset" variant="popup-button mx-1 my-1"
            >Annulla
          </b-button>
          <b-button type="submit" variant="popup-button mx-1 my-1">
            Salva nota
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { mapState, mapActions } from "vuex"
import { companyService } from "@/_services"

export default {
  name: "NewSoapAnnotationComponent",
  props: ["subjectHash"],
  computed: {
    nomeOperatore() {
      if (this.user && this.user.anagrafica) {
        return this.user.anagrafica.name[0] + this.user.anagrafica.surname[0]
      }
      return "Op"
    },
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"])
  },

  data() {
    return {
      todayDate: "",
      localAnnotation: {
        is_soap: true,
        subjective: null,
        objective: null,
        assessment: null,
        plan: null,
        content: null,
        shared: false,
        user_id: this.subjectHash
      },
      annotationHash: null,
      showContent: true,
      editMode: true
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    resetEdit() {
      this.localAnnotation = {
        is_soap: true,
        subjective: null,
        objective: null,
        assessment: null,
        plan: null,
        content: null,
        shared: false,
        user_id: this.subjectHash
      }
      this.$emit("handle-closure")
    },
    storeNewAnnotation() {
      const self = this
      companyService
        .storeNewAnnotation(self.localAnnotation)
        .then(function (response) {
          window.scrollTo(0, 0)
          self.resetEdit()
          self.successAlert("Nota inserita correttamente")
          self.$emit("inserted-note")
        })
        .catch(function (error) {
          window.scrollTo(0, 0)
          self.errorAlert("Errore nell'inserimento della nota")
        })
    }
  },
  created() {
    this.todayDate = moment().format("d MMMM")
  }
}
</script>
