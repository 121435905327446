<template>
  <div
    id="requested-by-person-header"
    class="m-0 p-3 detail-modal-card text-left"
  >
    <b-row>
      <b-col
        class="d-flex justify-content-center"
        id="requested-by-person-first-col"
      >
        <span class="d-flex justify-content-center">
          <b-avatar
            :src="info.requested_by.profile_pic"
            size="66px"
            variant="white"
            class="general_border"
            rounded="lg"
          ></b-avatar>
        </span>
      </b-col>
      <b-col class="ml-auto flex-grow-1 pl-3">
        <b-row cols="1" cols-sm="2">
          <b-col
            class="d-flex flex-column flex-nowrap justify-content-start px-0"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              NOME E COGNOME
            </p>
            <p
              class="anagraphic-data-text text-secondary underlined mb-1 mg-lg-2 point"
              @click="goToPatientProfile()"
            >
              {{ info.requested_by.anagrafica.name }}
              {{ info.requested_by.anagrafica.surname }}
            </p>
          </b-col>
          <b-col
            class="d-flex flex-column flex-nowrap justify-content-start px-0"
            v-if="info.requested_by.anagrafica && info.requested_by.anagrafica.cf"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              CODICE FISCALE
            </p>
            <p class="anagraphic-data-text mb-1 mg-lg-2">
              {{ info.requested_by.anagrafica.cf.toUpperCase() }}
            </p>
          </b-col>
        </b-row>
        <b-row cols="1" cols-sm="2">
          <b-col
            class="d-flex flex-column flex-nowrap justify-content-start px-0"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              EMAIL
            </p>
            <p class="anagraphic-data-text mb-1 mg-lg-2 long__txt">
              {{ info.requested_by.email }}
            </p>
          </b-col>
          <b-col
            class="d-flex flex-column flex-nowrap justify-content-start px-0"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              TELEFONO
            </p>
            <p class="anagraphic-data-text mb-1 mg-lg-2">
              {{ info.requested_by.anagrafica.tel_number }}
            </p>
          </b-col>
        </b-row>
        <b-row cols="1">
          <b-col
            class="d-flex flex-column flex-nowrap justify-content-start px-0"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              INDIRIZZO
            </p>
            <p class="anagraphic-data-text mb-1 mg-lg-2">
              {{ info.requested_by.anagrafica.address }}
              {{ info.requested_by.anagrafica.cap }}
              {{ info.requested_by.anagrafica.comune }}
              {{ info.requested_by.anagrafica.provincia }}
              {{ info.requested_by.anagrafica.regione }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "RequestedByPersonInfoCardComponent",
  props: {
    info: {
      type: Object,
      required: true,
    },
    userIsMyPatient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    goToPatientProfile() {
      this.$emit("close-modal");
      if (
        this.$route.name != "scheda paziente" ||
        this.$route.params.hash != this.info.requested_by.hashid
      )
        this.$router.push({
          name: "scheda paziente",
          params: { hash: this.info.requested_by.hashid },
        });
    },
  },
};
</script>
<style scoped>
#requested-by-person-header {
  width: 100%;
}

#requested-by-person-first-col {
  max-width: 80px;
}
</style>
