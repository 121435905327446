<template>
  <div class="py-2 px-1">
    <b-row align-v="stretch">
      <b-col class="col-12 col-md-6 my-2 px-0 pr-md-2 pr-xl-4">
        <div class="puntofarmaCard p-4 h-100 col-12 col-xl-10">
          <h5 class="text-left">Il tuo codice azienda di oggi</h5>
          <p class="card-text text-secondary" style="font-size: 1.9rem">
            {{ code }}
          </p>
          <p class="text-justify mb-0">
            Inserendo questo codice in fase di registrazione, i tuoi operatori
            potranno registrarsi nella piattaforma in totale autonomia.
            <u>Questo codice sarà valido fino alla mezzanotte di oggi.</u>
          </p>
        </div>
      </b-col>
      <b-col class="col-12 col-md-6 my-2 px-0 pl-md-2 pr-xl-4">
        <div class="puntofarmaCard p-4 h-100 col-12 col-xl-10">
          <h5 class="text-left">Invia codice azienda</h5>
          <b-form-group
            label="Inserisci email destinatario a cui inviare il codice:"
            class="text-left"
          >
            <b-form-input
              type="email"
              placeholder="Inserisci email.. "
              name="email"
              :state="email == null ? null : validEmail"
              v-model="email"
            ></b-form-input>
          </b-form-group>
          <b-button
            variant="secondary px-3 text-white mt-1 "
            v-show="email == null ? false : validEmail"
            @click="sendCode"
          >
            <span v-if="!sendingMail">Invia codice</span
            ><b-spinner v-else></b-spinner>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";
export default {
  name: "ShowCode",
  data() {
    return {
      codeVisible: false,
      btnLabel: "utente",
      code: null,
      email: null,
      csendcodeVisible: false,
      sendingMail: false,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
    validEmail() {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.email.match(validRegex) ? true : false;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    getCode() {
      var hash = this.user.company.hashid;
      var self = this;
      companyService
        .getCode(hash)
        .then(function (res) {
          self.code = res.data.data.code;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile recuperare il codice");
        });
    },
    sendCode() {
      this.sendingMail = true;
      var form = {
        hash: this.user.company.hashid,
        form: { email: this.email },
      };
      var self = this;
      companyService
        .sendCode(form)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Codice inviato correttamente!");
          self.csendcodeVisible = false;
          self.sendingMail = false;
          self.email = null;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile recuperare il codice");
          self.sendingMail = false;
        });
    },
  },
  created() {
    if (this.user && this.user.type === 1) {
      this.btnLabel = "azienda";
    }
  },
  mounted() {
    this.getCode();
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      // console.log("collapseId:", collapseId);
      // console.log("isJustShown:", isJustShown);
      if (collapseId == "collapse-companycode") {
        this.codeVisible = isJustShown;
      }
    });
  },
};
</script>