<template>
  <div class="home h-100 px-md-4">
    <b-container class="mb-3 h-100 px-0" fluid v-if="user">
      <h4 class="text-prim-grad-1 text-left pb-2 mb-4">
        Dashboard di Amministrazione
      </h4>

      <DatiApp />
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DatiApp from "@/components/admin/DatiApp.vue";

export default {
  name: "AdminHome",
  components: {
    DatiApp,
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
};
</script>
