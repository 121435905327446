<template>
  <b-container fluid class="m-0 p-0">
    <!-- Selezione tipologia di prenotazione -->
    <b-container fluid class="m-0 p-4 puntofarmaCard">
      <b-row class="mb-2 mb-lg-3 mt-n3">
        <b-col
          v-for="serviceOption in serviceOptions"
          :key="serviceOption.text"
          class="col-6 col-sm-3 col-lg-2 point align-self-start px-0 mt-3"
        >
          <div
            :class="
              filter.service === serviceOption.value
                ? 'bg-secondary text-white'
                : 'bg-white text-black'
            "
            class="h-100 py-1 py-lg-2 shadow_2 general_border general_hover mr-3"
            @click="filter.service = serviceOption.value"
          >
            {{ serviceOption.text }}
          </div>
        </b-col>
        <div class="ml-auto px-3 col-3 text-right mt-3">
          <span
            v-b-toggle.filter-collapse
            class="general_border border-info border px-2 py-1"
          >
            <span class="pt-1 d-lg-none">
              {{ showFilters ? "meno" : "più" }} filtri
            </span>
            <span class="pt-1 d-none d-lg-inline">
              mostra {{ showFilters ? "meno" : "più" }} filtri
            </span>
            <b-icon icon="funnel" variant="dark-grey"></b-icon>
          </span>
        </div>
      </b-row>
      <b-collapse
        :visible="showFilters"
        v-model="showFilters"
        id="filter-collapse"
      >
        <b-row class="d-flex align-items-center mt-n1">
          <b-col class="m-0 p-0 col-6 col-sm-4 col-lg-3 pr-2 mt-3 d-flex">
            <b-input-group size="sm" class="px-0 col-12">
              <b-form-input
                size="sm"
                class="m-0"
                type="search"
                v-model="filter.search"
                placeholder="Codice Richiesta..."
                @input="
                  (v) => {
                    filter.search = v.toUpperCase()
                  }
                "
              >
              </b-form-input>
              <!-- <b-icon
                icon="search"
                class="search-icon"
                variant="secondary"
                font-scale="1.2"
              ></b-icon> -->
            </b-input-group>
          </b-col>
          <b-col
            class="m-0 p-0 col-6 col-sm-4 col-lg-3 px-3 mt-3 d-flex align-items-center"
          >
            <span class="pr-2">Stato</span>
            <b-form-select
              v-capitalize-first-select
              size="sm"
              class="m-0 pr-0"
              name="stato"
              v-model="filter.status"
              :options="statusOptions"
            >
            </b-form-select>
          </b-col>
          <!-- </b-row>
        <b-row> -->
          <b-col
            class="col-6 col-sm-4 col-lg-3 d-flex px-3 mt-3 align-items-center"
          >
            <span class="mr-2">Periodo</span>
            <b-form-select
              v-capitalize-first-select
              size="sm"
              class="text-left"
              name="show"
              v-model="filter.range"
              :options="rangeOptions"
            >
            </b-form-select>
          </b-col>
          <b-col
            class="col-6 col-sm-4 col-lg-3 d-flex px-3 mt-3 d-flex align-items-center"
          >
            <span class="mr-2">Mostra</span>
            <b-form-select
              v-capitalize-first-select
              size="sm"
              class="text-left"
              name="show"
              v-model="pagination"
              :options="paginationOptions"
            >
            </b-form-select>
          </b-col>
        </b-row>
      </b-collapse>
    </b-container>
    <b-row class="justify-content-bewteen py-3" align-v="center">
      <b-col class="align-self-end col-3 col-xl-6">
        <h4 class="text-prim-grad-1 text-left py-1 mb-0">
          {{ vociServizi[filter.service] }}
        </h4>
      </b-col>
      <b-col class="col text-left text-md-right d-flex justify-content-md-end">
        <NuovaPrenotazionePassata
          :userinfo="userinfo"
          :subject="subject"
          @new-reservation="fetchData()"
        ></NuovaPrenotazionePassata>
        <b-button v-if="filter.service !== 'prodotto'" @click="goToNew">
          <b-icon icon="plus"></b-icon> nuova prenotazione
        </b-button>
        <b-button v-else @click="goToNewOrder">
          <b-icon icon="plus"></b-icon> nuovo ordine
        </b-button>
      </b-col>
    </b-row>

    <div class="mb-3">
      <template v-if="reservations.length">
        <b-row class="col-12 px-0">
          <template v-for="(res, ind) in reservations">
            <b-col
              :key="ind"
              v-if="res.tipo_ordine == 'prestazione'"
              class="col-lgx-6 col-12 my-2 wrapping-column-lgx"
            >
              <div class="puntofarmaCard d-flex col-12 py-3">
                <div
                  class="m-0 px-2 flex-column justify-content-center align-items-center"
                  style="flex: 0 0 125px; min-width: 0"
                >
                  <template v-if="res.status > 0">
                    <template v-if="res.attesa_pagamento">
                      <p class="mb-0 text-danger font-weight-bolder">
                        attesa pagamento
                      </p>
                    </template>
                    <template v-else-if="!res.data_inizio">
                      <p class="mb-0 text-warning font-weight-bolder">
                        attesa conferma
                      </p>
                    </template>
                    <template v-else>
                      <b-icon
                        :icon="statusModalIcons[res.stato]"
                        font-scale="2"
                        :variant="statusModalVariants[res.stato]"
                      >
                      </b-icon>
                      <p class="mb-0">{{ res.stato }}</p>
                    </template>
                  </template>
                  <template v-else>
                    <b-icon
                      :icon="statusModalIcons[res.stato]"
                      font-scale="2"
                      :variant="statusModalVariants[res.stato]"
                    >
                    </b-icon>
                    <p class="mb-0">{{ res.stato }}</p>
                  </template>
                </div>
                <b-row class="p-0 text-left" style="flex: 1">
                  <b-col class="col-12 m-0 p-0 f-w-410"
                    >{{ res.orderable.prestazione.name }}
                  </b-col>
                  <b-col
                    class="col-12 m-0 p-0 d-flex justify-content-between align-items-center"
                  >
                    <span>{{
                      res.data_inizio ? formatData(res.data_inizio) : ""
                    }}</span>
                    <!-- <span class="text-uppercase">{{ res.stato }}</span> -->

                    <span>
                      <span class="mr-3">
                        <!-- <b-icon
                      icon="check-circle"
                      variant="secondary"
                      font-scale="1.5"
                    ></b-icon> -->
                      </span>
                      <span class="point ml-auto" @click="showDetail(res)">
                        <b-iconstack font-scale="2.2" class="point">
                          <b-icon
                            stacked
                            icon="circle-fill"
                            variant="mdm-light-grey"
                          ></b-icon>
                          <b-icon
                            stacked
                            icon="zoom-in"
                            title="Vedi"
                            scale="0.5"
                            variant="secondary"
                          ></b-icon>
                        </b-iconstack>
                      </span>
                    </span>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              :key="ind"
              v-else-if="res.tipo_ordine == 'prodotto'"
              class="col-lgx-6 col-12 my-2 wrapping-column-lgx"
            >
              <div class="puntofarmaCard d-flex col-12 py-3">
                <div
                  class="m-0 px-2 flex-column justify-content-center align-items-center"
                  style="flex: 0 0 125px; min-width: 0"
                >
                  <template v-if="res.status > 0">
                    <template v-if="res.attesa_pagamento">
                      <p class="mb-0 text-danger font-weight-bolder">
                        attesa pagamento
                      </p>
                    </template>
                    <template v-else-if="!res.data_inizio">
                      <p class="mb-0 text-warning font-weight-bolder">
                        attesa conferma
                      </p>
                    </template>
                    <template v-else>
                      <b-icon
                        :icon="statusModalIcons[res.stato]"
                        font-scale="2"
                        :variant="statusModalVariants[res.stato]"
                      >
                      </b-icon>
                      <p class="mb-0">{{ res.stato }}</p>
                    </template>
                  </template>
                  <template v-else>
                    <b-icon
                      :icon="statusModalIcons[res.stato]"
                      font-scale="2"
                      :variant="statusModalVariants[res.stato]"
                    >
                    </b-icon>
                    <p class="mb-0">{{ res.stato }}</p>
                  </template>
                </div>
                <b-row class="p-0 text-left" style="flex: 1">
                  <b-col class="col-12 m-0 p-0"
                    ><span class="f-w-410"
                      >ordine
                      <span class=""> {{ res.codice_richiesta }}</span></span
                    >
                  </b-col>
                  <b-col
                    class="col-12 m-0 p-0 d-flex justify-content-between align-items-center"
                  >
                    <span v-if="res.data_inizio">{{
                      formatData(res.data_inizio)
                    }}</span>

                    <span class="point ml-auto" @click="showDetail(res)">
                      <b-iconstack font-scale="2.2" class="point">
                        <b-icon
                          stacked
                          icon="circle-fill"
                          variant="mdm-light-grey"
                        ></b-icon>
                        <b-icon
                          stacked
                          icon="zoom-in"
                          title="Vedi"
                          scale="0.5"
                          variant="secondary"
                        ></b-icon>
                      </b-iconstack>
                    </span>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              :key="ind"
              v-else-if="res.tipo_ordine == 'teleconsulto'"
              class="col-lgx-6 col-12 my-2 wrapping-column-lgx"
            >
              <div class="puntofarmaCard d-flex col-12 py-3">
                <div
                  class="m-0 px-2 flex-column justify-content-center align-items-center"
                  style="flex: 0 0 125px; min-width: 0"
                >
                  <template v-if="res.status > 0">
                    <template v-if="res.attesa_pagamento">
                      <p class="mb-0 text-danger font-weight-bolder">
                        attesa pagamento
                      </p>
                    </template>
                    <template v-else-if="!res.data_inizio">
                      <p class="mb-0 text-warning font-weight-bolder">
                        attesa conferma
                      </p>
                    </template>
                    <template v-else>
                      <b-icon
                        :icon="statusModalIcons[res.stato]"
                        font-scale="2"
                        :variant="statusModalVariants[res.stato]"
                      >
                      </b-icon>
                      <p class="mb-0">{{ res.stato }}</p>
                    </template>
                  </template>
                  <template v-else>
                    <b-icon
                      :icon="statusModalIcons[res.stato]"
                      font-scale="2"
                      :variant="statusModalVariants[res.stato]"
                    >
                    </b-icon>
                    <p class="mb-0">{{ res.stato }}</p>
                  </template>
                </div>
                <b-row class="p-0 text-left" style="flex: 1">
                  <b-col class="col-12 m-0 p-0"
                    ><span class="f-w-410"
                      ><span class="">
                        durata {{ res.orderable.durata }} minuti</span
                      ></span
                    >
                  </b-col>
                  <b-col
                    class="col-12 m-0 p-0 d-flex justify-content-between align-items-center"
                  >
                    <span v-if="res.data_inizio">{{
                      formatData(res.data_inizio)
                    }}</span>

                    <span class="point ml-auto" @click="showDetail(res)">
                      <b-iconstack font-scale="2.2" class="point">
                        <b-icon
                          stacked
                          icon="circle-fill"
                          variant="mdm-light-grey"
                        ></b-icon>
                        <b-icon
                          stacked
                          icon="zoom-in"
                          title="Vedi"
                          scale="0.5"
                          variant="secondary"
                        ></b-icon>
                      </b-iconstack>
                    </span>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </template>
        </b-row>
      </template>
      <p v-else class="my-5 general_border text-left pl-3">
        Non sono state trovate prenotazioni con i criteri selezionati.
      </p>

      <div v-if="total > 0 && reservations.length" class="d-flex text-left">
        <span class="mr-1"> {{ fromPage }}-{{ toPage }} di </span>
        <span class=""> {{ total }}</span>
      </div>

      <b-row
        class="my-1 d-flex justify-content-center"
        v-if="reservations.length"
      >
        <div
          v-if="reservations && reservations.length > 0"
          class="d-flex align-items-center justify-content-center text-center my-3"
        >
          <b-pagination
            class="mb-sm-4 mb-md-0 d-flex justify-content-center align-items-center"
            v-model="filter.page"
            :total-rows="total"
            :per-page="15"
            align="center"
            :size="$root.isMobile ? 'sm' : 'lg'"
          ></b-pagination>
        </div>
      </b-row>

      <b-modal
        size="xl"
        centered
        id="modal-resdetails-paziente"
        ref="modal-resdetails-paziente"
        hide-footer
        hide-header
        scrollable
        body-class="p-0"
        @hidden="selectedReservation = null"
      >
        <DettaglioPrenotazioneOperatore
          v-if="selectedReservation"
          :scheda-paziente-view="true"
          :chat-recipient-hash="subject.hashid"
          chat-recipient-type="user"
          :reservation="selectedReservation"
          :richiedente="userinfo"
          :show-company="user.permessi.includes('gestire liste pazienti')"
          @close-modal="closeModal()"
          @fetch-data="fetchData()"
          @handle-update-request="handleUpdateRequest()"
          @handle-status-update="handleStatusUpdate($event)"
          @update-request-response="sendOrderResponse($event)"
          @download-pdf="downloadPdf()"
          @open-evadi="openEvadi()"
        />
      </b-modal>

      <b-modal
        size="xl"
        centered
        id="modal-evasione"
        title="Evadi Richiesta"
        hide-footer
      >
        <div v-if="toEvadere">
          <p class="text-left text-font-grey">
            Numero Richiesta: #{{ toEvadere.numero }} <br />
          </p>

          <p class="text-left text-font-grey">
            Codice Richiesta:
            {{ toEvadere.codice_richiesta }} <br />
          </p>

          <b-form-input
            name="detail"
            class="mx-auto"
            v-model="evasione.hash"
            type="text"
            hidden
          >
          </b-form-input>
          <b-form-group
            label="Aggiungi Annotazione"
            label-for="detail"
            class="text-left"
          >
            <b-form-input
              v-capitalize-first
              name="detail"
              class="mx-auto"
              v-model="evasione.details"
              type="text"
            >
            </b-form-input>
          </b-form-group>
          <div class="text-right my-2 pt-4">
            <b-button
              type="button"
              class="mx-3"
              variant="popup-button"
              @click="$bvModal.hide('modal-evasione')"
            >
              Annulla</b-button
            >
            <b-button type="button" variant="popup-button" @click="evadiThis">
              Conferma</b-button
            >
          </div>
        </div>
      </b-modal>
    </div>
  </b-container>
</template>
<script>
import * as moment from "moment/moment"
import { mapState, mapActions } from "vuex"
import { reservationService } from "@/_services"
import DettaglioPrenotazioneOperatore from "@/components/prenotazioni/DettaglioPrenotazioneOperatore.vue"
import NuovaPrenotazionePassata from "@/components/pazienti/NuovaPrenotazionePassata.vue"

export default {
  name: "PrenotazioniPaziente",
  props: ["subject", "userinfo"],
  components: {
    DettaglioPrenotazioneOperatore,
    NuovaPrenotazionePassata
  },
  data() {
    return {
      showFilters: true,
      vociServizi: {
        prodotto: "Ordini",
        prestazione: "Prestazioni",
        teleconsulto: "Teleconsulti"
      },
      rangeOptions: [
        { value: null, text: "tutti" },
        "oggi",
        "domani",
        "settimana",
        "mese"
      ],
      pagination: 15,
      paginationOptions: [15, 20, 50],
      reservations: [],
      countPrev: 0,
      count: 0,
      total: 0,
      fromPage: 0,
      toPage: 0,
      filter: {
        page: 1,
        take: 10,
        status: "tutte",
        service: "prestazione",
        range: null,
        search: null
      },
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill"
      },
      statusModalIcons: {
        confermato: "check-circle",
        ricevuto: "archive",
        annullato: "x-circle",
        rifiutato: "x-circle",
        "in attesa di risposta": "question-circle",
        evaso: "calendar2-check"
      },
      statusModalVariants: {
        confermato: "secondary",
        ricevuto: "primary-darker",
        annullato: "danger",
        rifiutato: "danger",
        "in attesa di risposta": "warning",
        evaso: "primary-darker"
      },
      serviceOptions: [
        { value: "prestazione", text: "Prestazioni" },
        { value: "teleconsulto", text: "Teleconsulti" },
        { value: "prodotto", text: "Ordini" }
      ],
      statusOptions: [
        { value: "tutte", text: "Tutte" },
        { value: "in attesa", text: "In Attesa" },
        { value: "confermate", text: "Confermate" },
        { value: "annullate", text: "Annullate" },
        { value: "evase", text: "Evase" }
      ],
      selectedReservation: null,
      evasione: {
        details: null,
        hash: null
      },
      toEvadere: null
    }
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert
    })
  },
  watch: {
    filter: {
      handler(prev, post) {
        // console.log("changed prev", prev.page, post);
        // console.log("changed post", post.page);
        this.prenotazioni = []
        this.fetchData()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    closeModal() {
      this.$bvModal.hide("modal-resdetails-paziente")
    },
    openEvadi(item = this.selectedReservation) {
      this.toEvadere = item
      this.evasione.hash = item.hashid
      this.$bvModal.show("modal-evasione")
    },
    evadiThis(hash = null) {
      // console.log("richiesta da evadere ", hash);
      hash = this.evasione.hash
      var self = this
      reservationService
        .closeOrder(this.evasione)
        .then(function (response) {
          self.$bvModal.hide("modal-evasione")
          self.$bvModal.hide("modal-resdetails-paziente")
          self.evasione.details = null
          self.evasione.hash = null
          window.scrollTo(0, 0)
          self.successAlert("prenotazione evasa con successo")
          // console.log("response fetch prenotazioni", response);
          self.fetchData()
        })
        .catch(function (error) {
          self.$bvModal.hide("modal-evasione")
          self.$bvModal.hide("modal-resdetails-paziente")
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile evadere la prenotazione")
          // console.log("error fetch prenotazioni", error);
        })
    },
    handleStatusUpdate(statusData, reservation = this.selectedReservation) {
      var self = this
      if (!statusData.confirmed) {
        this.loading = true
        var form = statusData.message ? { message: statusData.message } : {}
        reservationService
          .companyCancelOrReject(form, statusData.action, reservation.hashid)
          .then(function (re) {
            self.$bvModal.hide("modal-resdetails-paziente")
            window.scrollTo(0, 0)
            self.successAlert("Richiesta aggiornata con successo")
            self.fetchData()
          })
          .catch(function (error) {
            self.$bvModal.hide("modal-resdetails-paziente")
            window.scrollTo(0, 0)
            self.errorAlert("non è stato possibile effettuare l'operazione")
            self.loading = false
          })
      } else {
        this.loading = true
        reservationService
          .companyConfirmOrder(reservation.hashid)
          .then(function (re) {
            self.$bvModal.hide("modal-evasione")
            self.$bvModal.hide("modal-resdetails-paziente")

            window.scrollTo(0, 0)
            self.successAlert("Richiesta aggiornata con successo")
            self.fetchData()
          })
          .catch(function (error) {
            self.$bvModal.hide("modal-evasione")
            self.$bvModal.hide("modal-resdetails-paziente")
            window.scrollTo(0, 0)
            self.errorAlert("non è stato possibile effettuare l'operazione")
            self.loading = false
          })
      }
    },
    sendOrderResponse(data, reservation = this.selectedReservation) {
      var form = {
        hashid: reservation.hashid,
        company_notes: data.company_notes,
        data_inizio: data.newDateTime,
        order: data.detail,
        status: 5
      }
      // console.log(form);
      var self = this
      reservationService
        .updateReservation(form)
        .then(function (response) {
          // console.log(response);
          self.$bvModal.hide("modal-resdetails-paziente")
          window.scrollTo(0, 0)
          self.successAlert("risposta inviata con successo")
          self.fetchData()
        })
        .catch(function (error) {
          self.$bvModal.hide("modal-resdetails-paziente")
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile inviare la risposta")
        })
    },
    goToNew() {
      if (!this.user.permessi.includes("gestire liste pazienti")) {
        this.$router.push({
          name: "prenota servizio",
          params: { hash: this.subject.hashid }
        })
      } else {
        this.$router.push({
          name: "nuova prenotazione paziente",
          params: {
            tipo:
              this.filter.service === "prestazione"
                ? "prestazione"
                : "teleconsulto",
            hash: this.subject.hashid
          }
        })
      }
    },
    goToNewOrder() {
      if (!this.user.permessi.includes("gestire liste pazienti")) {
        this.$router.push({
          name: "prenota ordine",
          params: {
            hash: this.subject.hashid
          }
        })
      } else {
        this.$router.push({
          name: "nuova prenotazione paziente",
          params: {
            tipo: "prodotto",
            hash: this.subject.hashid
          }
        })
      }
    },
    fetchData() {
      var self = this
      reservationService
        .getUsersReservsInCompany(this.filter, this.subject.hashid)
        .then(function (res) {
          self.reservations = res.data.data.data
          self.count = res.data.data.length
          self.filter.page = res.data.data.current_page
          self.total = res.data.data.total
          self.fromPage = res.data.data.from
          self.toPage = res.data.data.to
        })
        .catch(function (error) {
          self.reservations = []
          console.table(self.reservations)
          window.scrollTo(0, 0)
          // self.errorAlert("Non è stato possibile recuperare le prenotazioni");
        })
    },
    formatData(data) {
      return moment(data).format("DD/MM/YYYY HH:mm")
    },
    showDetail(res) {
      // console.log(res.orderable);
      this.selectedReservation = res
      this.$bvModal.show("modal-resdetails-paziente")
    },
    downloadPdf() {
      const hash = this.selectedReservation.hashid
      const fName = this.selectedReservation.codice_richiesta
      var self = this
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".pdf"
          link.click()
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile scaricare il promemoria")
        })
    },
    handleUpdateRequest() {
      this.$bvModal.hide("modal-resdetails-paziente")
      this.successAlert("richiesta inviata con successo")
      this.fetchData()
    },

    updateResOperator(operatorHash) {
      var form = {
        hashid: this.selectedReservation.hashid,
        company_user_id: operatorHash
      }
      // console.log(form);
      var self = this
      reservationService
        .updateReservation(form)
        .then(function (response) {
          // console.log(response);
          // self.$bvModal.hide("modal-resdetails-prenotazioni")
          window.scrollTo(0, 0)
          self.successAlert("preferenza operatore aggiornata con successo")
          self.selectedReservation.operator = response.data.data.operator
          self.fetchData()
        })
        .catch(function (error) {
          // console.log(error);
          // self.$bvModal.hide("modal-resdetails-prenotazioni")
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile salvare la modifica")
        })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
<style lang="scss" scoped>
.ul-mb-0 {
  ul {
    margin-bottom: 0px !important;
  }
}

.modal-body.no-padding-top {
  padding: 0px !important;
}

.ul-mb-0 ul {
  margin-bottom: 0px !important;
}
</style>
