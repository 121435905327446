<template>
  <b-spinner v-if="!user || !company"></b-spinner>
  <div v-else>
    <div
      class="px-2 pb-2"
      v-if="user.permessi.includes('scaricare report attività di Facile')"
    >
      <h4 class="text-prim-grad-1 text-left pb-1 mt-1 mb-4 page-title">
        Report Prenotazioni
      </h4>

      <p class="text-left text-medium-grey mt-2 mb-4">
        Scarica report delle prenotazioni in formato csv, xlsx o pdf.
      </p>

      <b-form
        method="post"
        @submit.prevent="submitRequest"
        class="puntofarmaCard py-4 px-4"
      >
        <b-row class="">
          <b-col class="col-12 col-md-6 my-2 my-md-3 my-xl-4">
            <p class="text-left">Report prenotazioni con stato*</p>
            <b-form-group label-for="type" class="text-left text-secondary">
              <b-form-select
                v-capitalize-first-select
                name="type"
                class="mx-auto"
                :options="filterOptions.status"
                v-model="form.status"
                required
              >
                <b-form-select-option :value="'tutti'"
                  >tutti gli stati</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col class="col-12 col-md-6 my-2 my-md-3 my-xl-4">
            <p class="text-left">Formato download</p>
            <b-form-group label-for="type" class="text-left text-secondary">
              <b-form-select
                v-capitalize-first-select
                name="type"
                class="mx-auto"
                v-model="form.format"
                required
              >
                <b-form-select-option value="csv">csv</b-form-select-option>
                <b-form-select-option value="xlsx">xlsx</b-form-select-option>
                <b-form-select-option value="pdf">pdf</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="col-12 col-md-6 my-2 my-md-3 my-xl-4">
            <p class="text-left">Di tipo*</p>
            <b-form-group label-for="type" class="text-left text-secondary">
              <b-form-select
                v-capitalize-first-select
                name="type"
                class="mx-auto bg-white"
                :options="filterOptions.service"
                v-model="form.order_type"
                required
                disabled
              >
                <b-form-select-option :value="null"
                  >scegli tipo</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col class="col-12 col-md-6 my-2 my-md-3 my-xl-4">
            <template v-if="form.order_type === 'prestazione'">
              <p class="text-left">Tipo prestazione*</p>
              <SelectCompanyHealthServices
                v-model="form.subtype_hash"
                :hideDefaultOption="true"
                :appendToEndOptions="[{ text: 'tutte', value: null }]"
              />
              <!-- <b-form-group
                v-if="
                  form.order_type === 'prestazione' &&
                  company.prestazioni_attive
                "
                label-for="subtype"
                class="text-left text-secondary"
              >
                <b-form-select
                  v-capitalize-first-select
                  name="subtype"
                  class="mx-auto"
                  v-model="form.subtype_hash"
                >
                  <b-form-select-option
                    v-for="(hash, name) in company.prestazioni_attive"
                    :key="name"
                    :value="hash"
                    >{{ name }}</b-form-select-option
                  >
                  <b-form-select-option :value="null"
                    >tutte</b-form-select-option
                  >
                </b-form-select>
              </b-form-group> -->
            </template>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12 col-md-6 my-2 my-md-3 my-xl-4">
            <p class="text-left">Per i giorni dal*</p>
            <b-form-group label-for="data_from">
              <b-form-input
                name="data_from"
                class="mx-auto"
                type="date"
                v-model="form.data_from"
                :state="validateDate"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-12 col-md-6 my-2 my-md-3 my-xl-4">
            <p class="text-left">Al*</p>
            <b-form-group label-for="data_to" class="">
              <b-form-input
                name="data_to"
                class="mx-auto"
                type="date"
                v-model="form.data_to"
                :state="validateDate"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12 text-left">
            <p class="mb-0 mt-4">(*)parametri obbligatori</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12 text-center">
            <b-button class="mt-3" variant="secondary text-white " type="submit"
              >Conferma</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div v-else>
      <h4>Funzionalità non abilitata per questa utenza.</h4>
    </div>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { reservationService } from "@/_services";
import { mapState, mapActions } from "vuex";
import SelectCompanyHealthServices from "@/components/prenotazioni/form/SelectCompanyHealthServices.vue";
export default {
  name: "ReportPrenotazioni",
  components: {
    SelectCompanyHealthServices,
  },
  data() {
    return {
      form: {
        status: "ricevute",
        data_from: null,
        data_to: null,
        order_type: "prestazione",
        subtype_hash: null,
        format: "csv",
      },
      filterOptions: {
        status: ["ricevute", "confermate", "evase"],
        service: ["prestazione"],
        // service: ["prestazione", "ritiro", "prodotto", "tutte"],
      },
    };
  },
  computed: {
    ...mapState("company", ["company"]),
    ...mapState("utente", ["status", "user"]),
    validateDate: function () {
      if (this.form.data_from) {
        if (!this.form.data_to) {
          return null;
        }
        var from = moment(this.form.data_from);
        var to = moment(this.form.data_to);
        return moment(from).isSameOrBefore(to);
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    submitRequest(e) {
      console.log(
        "this.data",
        e.target[2].options[e.target[2].options.selectedIndex].textContent
      );
      var servName =
        e.target[2].options[e.target[2].options.selectedIndex].textContent;
      var dal = moment(this.form.data_from).format("DD/MM/YYYY");
      var al = moment(this.form.data_to).format("DD/MM/YYYY");
      var self = this;
      reservationService
        .massiveReport(this.form)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.form.status +
            "_" +
            servName +
            "_dal" +
            dal +
            "_al" +
            al +
            "." +
            self.form.format;
          link.click();
          self.form = {
            status: "in corso",
            data_from: null,
            data_to: null,
            order_type: "prestazione",
            subtype_hash: null,
            format: "csv",
          };
        })
        .catch(function (error) {
          if (error.status === 413) {
            window.scrollTo(0, 0);
            self.infoAlert(
              "Il report verrà inviato via mail ad elaborazione completata"
            );
            self.form = {
              status: "in corso",
              data_from: null,
              data_to: null,
              order_type: "prestazione",
              subtype_hash: null,
              format: "csv",
            };
          } else {
            window.scrollTo(0, 0);
            self.errorAlert("Nessuna prenotazione da scaricare");
          }
        });
    },
  },
};
</script>
