<template>
  <b-form-group
    :label="
      asOperator
        ? 'Operatore da assegnare alla prenotazione'
        : 'Preferenza Operatore Sanitario'
    "
    class="text-left"
  >
    <b-form-select
      v-capitalize-first-select
      :size="small ? 'sm' : ''"
      :value="value"
      @input="handleInput($event)"
      :options="companyOperatorsOptions"
    >
    </b-form-select>
  </b-form-group>
</template>

<script>
import { utilityService } from "@/_services"
import { companyService } from "@/_services"
import { mapState, mapActions } from "vuex"

export default {
  name: "PickCompanyOperator",
  props: {
    value: {
      type: String,
      default: null
    },
    companyHash: {
      type: String
    },
    small: {
      type: Boolean,
      default: false
    },
    asOperator: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("utente", ["user"])
  },
  data() {
    return {
      companyOperatorsOptions: [
        {
          value: null,
          text: "Nessuna Preferenza"
        }
      ]
    }
  },
  created() {
    if (!this.asOperator) {
      this.fetchCompanyOptions()
    } else {
      this.fetchOwnCompanyOperators()
    }
  },
  watch: {
    companyHash: function (newVal, oldVal) {
      if (newVal) {
        this.fetchCompanyOptions()
      }
    }
  },
  methods: {
    fetchCompanyOptions() {
      var self = this
      utilityService
        .getCompanyOperators(this.companyHash)
        .then((res) => {
          self.companyOperatorsOptions = res.data.data.map((op) => {
            return {
              value: op.hashid,
              text: op.title ? op.title + " " + op.fullname : op.fullname
            }
          })

          self.companyOperatorsOptions.unshift({
            value: null,
            text: "Nessuna Preferenza"
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    fetchOwnCompanyOperators() {
      var self = this
      companyService
        .getOperatorsList({
          all: true
        })
        .then((res) => {
          self.companyOperatorsOptions = res.data.data.map((op) => {
            return {
              value: op.hashid,
              text: op.fullname
            }
          })

          self.companyOperatorsOptions.unshift({
            value: null,
            text: "Nessuna Preferenza"
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleInput(value) {
      this.$emit("input", value)
      //if value != null emit the operator title + fullname
      if (value) {
        var operator = this.companyOperatorsOptions.find(
          (op) => op.value == value
        )
        this.$emit("operator-selected", operator.text)
      }
    }
  }
}
</script>
