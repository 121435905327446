var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{attrs:{"cols":"1"}},[(_vm.company && _vm.list)?_c('b-col',{staticClass:"px-0"},[_c('b-form-group',{staticClass:"text-left",attrs:{"label":_vm.hidetitles ? 'Scegli prestazione*' : 'Scegli prestazione',"label-for":"subtype","description":_vm.company.prestazioni_sanitarie.length > 0
          ? ''
          : 'Nessuna prestazione disponibile presso la sede selezionata'}},[(_vm.excludeByDisponibilita)?_c('b-form-select',{directives:[{name:"capitalize-first-select",rawName:"v-capitalize-first-select"}],staticClass:"mx-auto",attrs:{"name":"subtype","required":""},on:{"change":_vm.emitPrestazione},model:{value:(_vm.prestazione),callback:function ($$v) {_vm.prestazione=$$v},expression:"prestazione"}},[_vm._l((_vm.list),function(prestazione,name){return [(prestazione.proxy_scheduling)?[(prestazione.active === 1)?_c('b-form-select-option',{key:name,attrs:{"value":prestazione}},[_vm._v(_vm._s(prestazione.name)+" "+_vm._s(_vm.getServicePrice(prestazione)))]):_vm._e()]:[(prestazione.active === 1)?_c('b-form-select-option',{key:name,attrs:{"value":prestazione,"disabled":!prestazione.disponibilita ||
                prestazione.disponibilita.length < 1}},[_vm._v(_vm._s(prestazione.name)+" "+_vm._s(!prestazione.disponibilita || prestazione.disponibilita.length < 1 ? "-non disponibile" : _vm.getServicePrice(prestazione)))]):_vm._e()]]}),_c('b-form-select-option',{attrs:{"value":null,"disabled":""}})],2):_c('b-form-select',{directives:[{name:"capitalize-first-select",rawName:"v-capitalize-first-select"}],staticClass:"mx-auto",attrs:{"name":"subtype","required":""},on:{"change":_vm.emitPrestazione},model:{value:(_vm.prestazione),callback:function ($$v) {_vm.prestazione=$$v},expression:"prestazione"}},[_vm._l((_vm.list),function(prestazione,name){return [(prestazione.active === 1)?_c('b-form-select-option',{key:name,attrs:{"value":prestazione}},[_vm._v(_vm._s(prestazione.name)+" ")]):_vm._e()]}),_c('b-form-select-option',{attrs:{"value":null,"disabled":""}})],2)],1)],1):(_vm.company)?_c('b-col',[_c('b-form-group',{staticClass:"text-left",attrs:{"label":"Scegli prestazione","label-for":"subtype","description":_vm.company.prestazioni_sanitarie.length > 0
          ? ''
          : 'Nessuna prestazione disponibile presso la sede selezionata'}},[(_vm.excludeByDisponibilita)?_c('b-form-select',{directives:[{name:"capitalize-first-select",rawName:"v-capitalize-first-select"}],staticClass:"mx-auto",attrs:{"name":"subtype","required":""},on:{"change":_vm.emitPrestazione},model:{value:(_vm.prestazione),callback:function ($$v) {_vm.prestazione=$$v},expression:"prestazione"}},[_vm._l((_vm.company.prestazioni_sanitarie),function(prestazione,name){return [(prestazione.active === 1)?_c('b-form-select-option',{key:name,attrs:{"value":prestazione,"disabled":!prestazione.disponibilita ||
              prestazione.disponibilita.length < 1}},[_vm._v(_vm._s(prestazione.name)+" "+_vm._s(!prestazione.disponibilita || prestazione.disponibilita.length < 1 ? "- non disponibile" : ""))]):_vm._e()]}),_c('b-form-select-option',{attrs:{"value":null,"disabled":""}})],2):_c('b-form-select',{directives:[{name:"capitalize-first-select",rawName:"v-capitalize-first-select"}],staticClass:"mx-auto",attrs:{"name":"subtype","required":""},on:{"change":_vm.emitPrestazione},model:{value:(_vm.prestazione),callback:function ($$v) {_vm.prestazione=$$v},expression:"prestazione"}},[_vm._l((_vm.company.prestazioni_sanitarie),function(prestazione,name){return [(prestazione.active === 1)?_c('b-form-select-option',{key:name,attrs:{"value":prestazione}},[_vm._v(_vm._s(prestazione.name))]):_vm._e()]}),_c('b-form-select-option',{attrs:{"value":null,"disabled":""}})],2)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }