<template>
  <div class="col-12 mt-1 mx-0">
    <h4 class="text-left pb-1 mb-4 page-title">Raccolte</h4>

    <!-- <p class="text-justify text-medium-grey">
      Questa sezione raccoglie tutte le iniziative che comprendono la
      formulazione e la compilazione di questionari, dalle campagne di screening
      ai questionari legati ai servizi Puntofarma "Prenotazioni" e
      "Teleconsulto". Clicca su una riga per vederne i dettagli. Per creare una nuova
      raccolta clicca su "nuova raccolta"; potrai gestire lo stato di
      attivazione solo delle iniziative create da te e dei questionari ad esse
      associate.
    </p> -->

    <div :class="$root.isMobile ? 'col-12' : 'd-flex justify-content-between'">
      <b-button
        v-if="user.permessi.includes('gestire attività e questionari')"
        variant="secondary  border-white"
        class="ml-0 mr-auto"
        :class="$root.isMobile ? 'col-12' : ''"
        @click="$refs['modal-newactivity'].show()"
      >
        <b-icon icon="plus"></b-icon> nuova raccolta
      </b-button>

      <b-input-group
        size="sm"
        class="mt-2 ml-auto mr-0"
        :class="$root.isMobile ? 'col-12' : ' col-lg-4 col-sm-6'"
      >
        <b-form-input
          v-capitalize-first
          class="align-items-center align-content-center"
          v-model="filterSetted.search"
          type="search"
          placeholder="Cerca raccolta..."
          @input="fetchData"
        >
        </b-form-input>

        <b-input-group-append>
          <span class="mx-2">
            <b-icon icon="search"></b-icon>
          </span>
        </b-input-group-append>
      </b-input-group>
    </div>

    <h5
      v-if="!selectedActivity && !(activities && activities.length == 0)"
      class="text-left border border-primary py-4 general_border px-4 my-2"
    >
      Seleziona una raccolta per visualizzarne i dettagli
    </h5>
    <!-- <h4 v-else class="text-left">Raccolte</h4> -->

    <div class="mt-2 shadow_2 bg-white general_border p-4">
      <b-table
        borderless
        hover
        responsive
        :fields="fields"
        :items="activities"
        :small="$root.isMobile"
        selectable
        select-mode="single"
        @row-selected="onRowSelected"
        ref="actable"
        class="text-left"
      >
        <template #cell(name)="row">
          <span class="text-justify text-capitalize">{{ row.item.name }}</span>
        </template>

        <template #cell(description)="row">
          <span class="text-justify">{{ row.item.description }}</span>
        </template>
        <template #cell(tot_surveys)="row">
          <span class="text-justify">Tot: {{ row.item.tot_surveys }}</span>
        </template>
        <template #cell(active)="row">
          <b-icon
            v-if="row.item.created_by == null"
            icon="circle-fill"
            :variant="row.item.active == 1 ? 'success' : 'danger'"
          ></b-icon>
          <b-icon
            v-else-if="
              !user.permessi.includes('gestire attività e questionari')
            "
            icon="circle-fill"
            :variant="row.item.active == 1 ? 'success' : 'danger'"
          ></b-icon>

          <b-form-checkbox
            v-else
            v-model="row.item.active"
            name="check-button"
            :state="row.item.active == 1"
            :value="1"
            :unchecked-value="0"
            switch
            @change="selectChangeStatus(row)"
          >
          </b-form-checkbox>
        </template>
        <!-- <template #cell(azioni)="row">
          <router-link
            class="
              info_gradient
              btn btn-info btn-sm
              px-3
              
              
            "
            :to="{ name: 'questionari', params: { quest: row.item.hashid } }"
          >
            Dettaglio</router-link
          >
        </template> -->
      </b-table>
      <p v-if="activities && activities.length == 0">Nessuna raccolta qui</p>
      <b-modal
        ref="modal-newactivity"
        title="Crea nuova raccolta"
        size="lg"
        hide-footer
        centered
      >
        <NuovaAttivita
          v-on:activity-created="handleNewActivity($event)"
          class="px-4 py-3"
        />
      </b-modal>

      <b-modal
        ref="modal-statusconfirm"
        title="Conferma operazione"
        size="lg"
        hide-footer
        @hidden="cancelOp"
        centered
      >
        <div v-if="selectedItem !== null">
          <h5 class="">
            Sei sicuro di voler
            {{
              activities[selectedItem].active === 1
                ? "attivare "
                : "disattivare "
            }}
            "{{ activities[selectedItem].name }}" ?
          </h5>
          <p v-if="activities[selectedItem].active === 1">
            Attivando la raccolta, i relativi questionari saranno disponibili
            per la compilazione.
          </p>
          <p v-else>
            Disattivando la raccolta, i relativi questionari non saranno
            disponibili per la compilazione.
          </p>
          <div class="mt-4 text-right">
            <b-button class="popup-button mr-4" @click="cancelOp"
              >No, annulla</b-button
            >
            <b-button class="popup-button" @click="changeStatus"
              >Sì, procedi</b-button
            >
          </div>
        </div>
      </b-modal>
    </div>
    <div class="mt-5" v-if="showDetails">
      <ActivityDetails
        v-if="
          selectedActivity &&
          user.permessi.includes('gestire attività e questionari')
        "
        :activity="selectedActivity"
        :asAdmin="false"
        v-on:do-delete="deleteActivity($event)"
        v-on:do-update="updateActivity($event)"
        :level="company.level"
      />
      <p v-else>Non puoi visualizzare i dettagli</p>
      <span ref="activitydetail"></span>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services"
import { mapState, mapActions } from "vuex"
import NuovaAttivita from "@/components/attivita/NuovaAttivita.vue"
import ActivityDetails from "@/components/attivita/ActivityDetails.vue"
export default {
  name: "HomeAttivita",
  components: { NuovaAttivita, ActivityDetails },
  data() {
    return {
      filterSetted: { search: null },
      fields: [
        { key: "name", label: "Nome" },
        { key: "description", label: "Descrizione" },
        { key: "tot_surveys", label: "Questionari" },
        // { key: "data", label: "Data", sortable: true },
        { key: "active", label: "Stato" }
        // { key: "azioni", label: "" },
      ],
      activities: null,
      selectedActivity: null,
      showDetails: false,
      selectedItem: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["company"])
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      var self = this
      supportService
        .getActivities(this.filterSetted)
        .then(function (res) {
          self.activities = res.data.data
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le raccolte")
          // console.log(err);
        })
      //
    },
    handleSearch() {
      if (this.filterSetted.search && this.filterSetted.search.length >= 3) {
        this.fetchData()
      }
    },
    deleteActivity(hash) {
      //console.log("hash", hash);
      var self = this
      supportService
        .deleteActivity(hash)
        .then(function (res) {
          window.scrollTo(0, 0)
          self.successAlert("Raccolta eliminata con successo!")
          self.fetchData()
          self.selectedActivity = null
          self.showDetails = false
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile eliminare la raccolta")
          self.fetchData()
        })
    },
    updateActivity(data) {
      //console.log("data", data);
      var formData = {
        hash: data.hashid,
        form: data.form
      }
      var self = this
      supportService
        .updateActivity(formData)
        .then(function (res) {
          window.scrollTo(0, 0)
          self.successAlert("Raccolta aggiornata con successo!")
          self.fetchData()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile aggiornare la raccolta")
          self.fetchData()
        })
    },
    handleNewActivity(resp) {
      this.$refs["modal-newactivity"].hide()
      window.scrollTo(0, 0)
      if (resp == true) {
        this.successAlert("Raccolta creata con successo!")
        this.fetchData()
      } else {
        this.errorAlert("Non è stato possibile creare la raccolta")
      }
    },
    selectChangeStatus(row) {
      this.selectedItem = row.index
      this.$refs["modal-statusconfirm"].show()
    },
    cancelOp() {
      this.$refs["modal-statusconfirm"].hide()
      this.fetchData()
    },
    changeStatus() {
      var formData = {
        hash: this.activities[this.selectedItem].hashid,
        form: {
          active: this.activities[this.selectedItem].active
        }
      }
      var self = this
      supportService
        .updateActivity(formData)
        .then(function (res) {
          window.scrollTo(0, 0)
          self.successAlert("Stato raccolta aggiornato con successo!")
          self.cancelOp()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert(
            "Non è stato possibile aggiornare lo stato della raccolta"
          )
          self.cancelOp()
        })
    },
    onRowSelected(items) {
      this.selectedActivity = items[0]
      this.showDetails = true
      this.$nextTick(function () {
        this.$refs["activitydetail"].scrollIntoView()
      })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
