<template>
  <div class="px-0 px-md-4">
    <h4 class="text-left pb-1 mb-lg-4">Contatti Pubblici</h4>
    <div v-if="fetching">
      <b-spinner variant="primary" label="loading"></b-spinner>
    </div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <span>
        <span class="mr-1" v-if="total > 0"
          >{{ fromPage }}-{{ toPage }} di
        </span>
        <span class=""> {{ total }}</span>
      </span>
      <b-button
        variant="secondary text-white "
        class="m-1"
        @click="getExcelContattiPubblici()"
        >Scarica tutto in formato excel</b-button
      >
      <b-button
        variant="secondary text-white "
        class="m-1"
        @click="getExcelRegistratiOggi()"
        >Report Utenti Registrati Oggi</b-button
      >
      <b-button
        variant="secondary text-white "
        class="m-1"
        @click="getExcelRegistratiCompleto()"
        >Report Utenti Registrati Completo</b-button
      >
      <b-form-group label="" label-for="takes" class="text-left text-secondary">
        <b-form-select
          v-capitalize-first-select
          :options="takeOptions"
          class=" "
          name="takes"
          v-model="filters.take"
          required
        >
        </b-form-select>
      </b-form-group>
    </div>
    <b-table
      borderless
      hover
      responsive
      class="text-center puntofarmaCard"
      :fields="fields"
      :items="contacts"
    >
    </b-table>
    <div>
      <b-pagination
        v-model="filters.page"
        pills
        :total-rows="total"
        :per-page="filters.take"
        align="center"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { adminService } from "@/_services"
import * as moment from "moment/moment"
import { mapActions, mapState } from "vuex"
export default {
  name: "AdminContattiPubblici",
  data() {
    return {
      filters: {
        page: 1,
        take: 10
      },
      contacts: null,
      fetching: false,
      fromPage: 0,
      toPage: 0,
      fields: [
        {
          key: "nome_cognome",
          label: "Nome"
        },
        {
          key: "email",
          label: "Email"
        },
        {
          key: "città",
          label: "Città"
        },
        {
          key: "indirizzo",
          label: "Indirizzo"
        },
        {
          key: "registered",
          label: "Si è registrato",
          formatter: (value) => {
            return value ? "Sì" : "No"
          }
        },
        {
          key: "is_associato_airp",
          label: "É associato AIRP",
          formatter: (value) => {
            switch (value) {
              case 1:
                return "Sì"
              case 0:
                return "No"
              default:
                return "Non specificato"
            }
          }
        },
        {
          key: "created_at",
          label: "Creato il",

          formatter: (value) => {
            return moment(value).format("DD/MM/YYYY HH:mm")
          }
        }
      ],
      total: 0,
      takeOptions: [
        { text: "mostra 10 elementi", value: 10 },
        { text: "mostra 25 elementi", value: 25 },
        { text: "mostra 50 elementi", value: 50 },
        { text: "mostra 75 elementi", value: 75 },
        { text: "mostra 100 elementi", value: 100 },
        { text: "mostra 200 elementi", value: 200 },
        { text: "mostra 300 elementi", value: 300 }
      ]
    }
  },
  watch: {
    "filters.page": function () {
      this.fetchData()
    },
    "filters.take": function () {
      this.fetchData()
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      var self = this
      adminService
        .getContattiPubblici(this.filters)
        .then(function (resp) {
          self.contacts = resp.data.data.data
          self.filters.page = resp.data.data.current_page
          self.total = resp.data.data.total
          self.fromPage = resp.data.data.from
          self.toPage = resp.data.data.to
        })
        .catch(function (err) {
          console.log(err)
        })
    },
    getExcelContattiPubblici() {
      this.fetching = true
      const self = this
      adminService
        .getExcelContattiPubblici()
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = `Contatti Publici ${moment().format(
            "DD-MM-YYYY"
          )}.xlsx`
          link.click()
          self.fetching = false
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.fetching = false
          self.errorAlert("Non è stato possibile scaricare il file")
        })
    },
    getExcelRegistratiOggi() {
      this.fetching = true
      const self = this
      adminService
        .getExcelRegistratiOggi()
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = `Registrati_oggi_ ${moment().format(
            "DD-MM-YYYY"
          )}.xlsx`
          link.click()
          self.fetching = false
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.fetching = false
          self.errorAlert("Non è stato possibile scaricare il file")
        })
    },
    getExcelRegistratiCompleto() {
      this.fetching = true
      const self = this
      adminService
        .getExcelRegistratiCompleto()
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = `Registrati_completo_ ${moment().format(
            "DD-MM-YYYY"
          )}.xlsx`
          link.click()
          self.fetching = false
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.fetching = false
          self.errorAlert("Non è stato possibile scaricare il file")
        })
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    })
  },
  mounted() {},
  created() {
    this.fetchData()
  }
}
</script>

<style scoped></style>
