<template>
  <div class="home">
    <b-alert
      variant="danger"
      :show="!companyLoggedIn"
      class="col-lg-8 col-sm-12 mr-0 ml-auto point"
    >
      <span>Azienda non trovata </span>
    </b-alert>
    <h1></h1>
    <b-container
      fluid
      class="mb-3 px-4"
      v-if="company && $route.name == 'analitiche' && statistics"
    >
      <!-- <b-row class="bg-sec-2"> {{ statistics }}</b-row> -->
      <b-row class="mt-2 mb-4 my-xl-1">
        <b-col class="col-3 px-xl-4 mb-2">
          <b-row class="h-100 shadow_2 general_border">
            <b-col class="col-12 text-left px-4 pt-4">
              <b-iconstack font-scale="3.5">
                <b-icon
                  stacked
                  icon="circle-fill"
                  variant="light-purple"
                ></b-icon>
                <b-icon
                  stacked
                  icon="people"
                  scale="0.5"
                  variant="dark-purple"
                ></b-icon>
                <b-icon stacked icon="circle" variant="light-purple"></b-icon>
              </b-iconstack>
            </b-col>
            <b-col class="col-12 text-left mt-3 px-4">
              <h5 v-if="statistics">{{ statistics.pazienti }}</h5>
            </b-col>
            <b-col class="col-12 text-left px-4">
              <span>Pazienti</span>
            </b-col>
            <b-img fluid-grow :src="require('@/assets/graph1.svg')"></b-img>
          </b-row>
        </b-col>
        <b-col class="col-3 px-xl-4 mb-2">
          <b-row class="h-100 shadow_2 general_border">
            <b-col class="col-12 text-left px-4 pt-4">
              <b-iconstack font-scale="3.5">
                <b-icon
                  stacked
                  icon="circle-fill"
                  variant="light-green"
                ></b-icon>
                <b-icon
                  stacked
                  icon="journal-medical"
                  scale="0.5"
                  variant="dark-green"
                ></b-icon>
                <b-icon stacked icon="circle" variant="light-green"></b-icon>
              </b-iconstack>
            </b-col>
            <b-col class="col-12 text-left mt-3 px-4">
              <h5 v-if="statistics.terapie !== null">
                {{ statistics.terapie }}
              </h5>
            </b-col>
            <b-col class="col-12 text-left px-4">
              <span>Terapie inserite</span>
            </b-col>
            <b-img fluid-grow :src="require('@/assets/graph2.svg')"></b-img>
          </b-row>
        </b-col>
        <b-col class="col-3 px-xl-4 mb-2">
          <b-row class="h-100 shadow_2 general_border">
            <b-col class="col-12 text-left px-4 pt-4">
              <b-iconstack font-scale="3.5">
                <b-icon stacked icon="circle-fill" variant="light-red"></b-icon>
                <b-icon
                  stacked
                  icon="cart2"
                  scale="0.5"
                  variant="dark-red"
                ></b-icon>
                <b-icon stacked icon="circle" variant="light-red"></b-icon>
              </b-iconstack>
            </b-col>
            <b-col class="col-12 text-left mt-3 px-4">
              <h5>
                {{ statistics.prenotazioni.servizi.prodotto.tot }}
              </h5>
            </b-col>
            <b-col class="col-12 text-left px-4">
              <span>Ordini gestiti</span>
            </b-col>
            <b-img fluid-grow :src="require('@/assets/graph3.svg')"></b-img>
          </b-row>
        </b-col>
        <b-col class="col-3 px-xl-4 mb-2">
          <b-row class="h-100 shadow_2 general_border">
            <b-col class="col-12 text-left px-4 pt-4">
              <b-iconstack font-scale="3.5">
                <b-icon
                  stacked
                  icon="circle-fill"
                  variant="light-orange"
                ></b-icon>
                <b-icon
                  stacked
                  icon="layout-text-sidebar-reverse"
                  scale="0.5"
                  variant="dark-orange"
                ></b-icon>
                <b-icon stacked icon="circle" variant="light-orange"></b-icon>
              </b-iconstack>
            </b-col>
            <b-col class="col-12 text-left mt-3 px-4">
              <h5 v-if="serviziSanitari">
                {{ Object.keys(serviziSanitari).length }}
              </h5>
              <h5 v-else>0</h5>
              <!-- <h5>{{ company.health_services.length }}</h5> -->
            </b-col>
            <b-col class="col-12 text-left px-4">
              <span>Servizi erogati</span>
            </b-col>
            <b-img fluid-grow :src="require('@/assets/graph4.svg')"></b-img>
          </b-row>
        </b-col>
      </b-row>

      <b-row cols="2" class="my-2 my-xl-1">
        <b-col cols="8" class="my-2 my-xl-3 px-xl-4" v-if="ordiniPerMese">
          <LineChart
            :idappend="'line-chart-1'"
            :datatoshow="ordiniPerMese"
            title="Andamento Ordini"
          />
        </b-col>
        <b-col cols="4" v-if="ordiniGeneral" class="my-2 my-xl-3 px-xl-4">
          <PieChart
            :idappend="'pie-chart-1'"
            :datatoshow="ordiniGeneral"
            title="Ordini"
          />
        </b-col>
      </b-row>
      <b-row cols="3" class="my-2 my-xl-1">
        <b-col cols="4" v-if="prenotazioniGeneral" class="my-2 my-xl-3 px-xl-4">
          <DoughnutChart
            :idappend="'dough-chart-2'"
            :datatoshow="prenotazioniGeneral"
            title="Prestazioni"
          />
        </b-col>
        <b-col cols="4" v-if="teleconsulto" class="my-2 my-xl-3 px-xl-4">
          <DoughnutChart
            :idappend="'dough-chart-1'"
            :datatoshow="teleconsulto"
            title="Teleconsulto"
          />
        </b-col>
        <b-col cols="4" class="my-2 my-xl-3 px-xl-4" v-if="proportional">
          <PolarChart
            :idappend="'polar-chart-1'"
            :datatoshow="proportional"
            title="Attività in app"
          />
        </b-col>
      </b-row>
      <b-row cols="2" class="my-2 my-xl-1">
        <b-col
          cols="8"
          class="my-2 my-xl-3 px-xl-4"
          v-if="alertPerMese && terapiePerMese"
        >
          <LineBarChart
            :idappend="'bar-line-chart-1'"
            :datatoshow="terapiePerMese"
            :bardatatoshow="alertPerMese"
            title="Alert e Terapie"
          />
        </b-col>
        <b-col cols="4" v-if="alertPerStato" class="my-2 my-xl-3 px-xl-4">
          <SplittedPieChart
            :idappend="'splitted-pie-chart-1'"
            :datatoshow="alertPerStato"
            title="Alerts"
          />
        </b-col>
      </b-row>
      <b-row cols="2" class="my-2 my-xl-1">
        <b-col cols="4" v-if="serviziSanitari" class="my-2 my-xl-3 px-xl-4">
          <ProgressChart
            :idappend="'progress-chart-1'"
            :datatoshow="serviziSanitari"
            title="Prestazioni offerte"
          />
        </b-col>
        <b-col cols="8" v-if="prenotazioniPerMese" class="my-2 my-xl-3 px-xl-4">
          <BarChart
            :idappend="'bar-chart-1'"
            :datatoshow="prenotazioniPerMese"
            title="Totale prenotazioni 2022"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { mapState, mapActions } from "vuex"
import { companyService } from "@/_services"
import BarChart from "@/components/dashboard/BarChart.vue"
import LineChart from "@/components/dashboard/LineChart.vue"
import DoughnutChart from "@/components/dashboard/DoughnutChart.vue"
import PieChart from "@/components/dashboard/PieChart.vue"
import PolarChart from "@/components/dashboard/PolarChart.vue"
import ProgressChart from "@/components/dashboard/ProgressChart.vue"
import LineBarChart from "@/components/dashboard/LineBarChart.vue"
import SplittedPieChart from "@/components/dashboard/SplittedPieChart.vue"
export default {
  name: "Analitiche",
  components: {
    BarChart,
    LineChart,
    DoughnutChart,
    PieChart,
    PolarChart,
    ProgressChart,
    LineBarChart,
    SplittedPieChart
  },
  data() {
    return {
      statistics: null,
      teleconsulto: null,
      prenotazioniGeneral: null,
      ordiniGeneral: null,
      proportional: null,
      prenotazioniPerMese: null,
      ordiniPerMese: null,
      serviziSanitari: null,
      terapiePerMese: null,
      alertPerMese: null,
      alertPerStato: null
    }
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["companyLoggedIn", "company"])
  },
  methods: {
    ...mapActions("company", ["logUserCompanyIn", "companyLogin"]),
    fetchData() {
      var self = this
      companyService
        .getStatistics()
        .then(function (response) {
          self.statistics = response.data.data
          self.setTc()
          self.setPrenotazioni()
          self.setOrdini()
          self.setProportional()
          self.prenotazioniPerMese = response.data.data.prenotazioniPerMese
          self.ordiniPerMese = response.data.data.ordiniPerMese
          self.serviziSanitari = response.data.data.serviziSanitari
          self.terapiePerMese = response.data.data.terapiePerMese
          self.alertPerMese = response.data.data.alertPerMese
          self.alertPerStato = response.data.data.alertPerStato
        })
        .catch(function (err) {})
    },
    setTc() {
      let tcArraydata = [
        this.statistics.prenotazioni.servizi[2].annullate,
        this.statistics.prenotazioni.servizi[2].evase,
        this.statistics.prenotazioni.servizi[2].in_corso
      ]
      this.teleconsulto = tcArraydata
    },
    setPrenotazioni() {
      let arrayData = [
        this.statistics.prenotazioni.servizi[1].annullate,
        this.statistics.prenotazioni.servizi[1].evase,
        this.statistics.prenotazioni.servizi[1].in_corso
      ]
      this.prenotazioniGeneral = arrayData
    },
    setOrdini() {
      let arrayData = [
        this.statistics.prenotazioni.servizi.prodotto.annullate,
        this.statistics.prenotazioni.servizi.prodotto.evase,
        this.statistics.prenotazioni.servizi.prodotto.in_corso
      ]
      this.ordiniGeneral = arrayData
    },
    setProportional() {
      let arrayData = [
        this.statistics.prenotazioni.servizi[2].tot,
        this.statistics.prenotazioni.servizi.prestazione.tot,
        this.statistics.prenotazioni.servizi.prodotto.tot,
        this.statistics.terapie,
        this.statistics.questionari.questionari.somministrate
      ]
      // this.proportional = arrayData;
      let total = arrayData.reduce((accumulator, curr) => accumulator + curr)

      this.proportional = arrayData.map((el) =>
        el > 0 ? Math.round((el / total) * 100 * 100) / 100 : 0
      )
    }
  },
  created() {
    if (this.$route.query.token) {
      this.companyLogin(this.$route.query.token)
    } else {
      this.logUserCompanyIn()
    }
    this.fetchData()
    // console.log(this.company);
  }
}
</script>
<style scoped>
.min__height {
  min-height: 130px;
  height: 130px;
}
</style>
