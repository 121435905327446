<template>
  <div class="mt-4">
    <template v-if="subscriptions">
      <div v-if="subscriptions.pending" class="text-left px-2">
        <h5>
          Sottoscrizione in attesa di pagamento:
          <span class="text-secondary"
            >{{ subscriptions.pending.abbonamento.nome }}
            {{ subscriptions.pending.abbonamento.durata }} mesi</span
          >
          <p class="text-left">
            <b-button
              @click.prevent="$bvModal.show('confirm-send-subscription')"
              type="button"
              variant="secondary m-1 mt-2"
              size="sm"
            >
              Invia link pagamento
            </b-button>
          </p>
        </h5>
        <p class="text-justify">
          {{ subscriptions.pending.abbonamento.descrizione }}
        </p>
      </div>
      <div v-else-if="subscriptions.attivo" class="text-left px-2">
        <h5>
          Sottoscrizione attiva:
          <span class="text-secondary"
            >{{ subscriptions.attivo.abbonamento.nome }}
            {{ subscriptions.attivo.abbonamento.durata }} mesi</span
          >
        </h5>
        <p class="text-justify">
          {{ subscriptions.attivo.abbonamento.descrizione }}
        </p>
      </div>
      <b-row
        align-v="center"
        class="px-2"
        v-else-if="subscriptions.disponibile"
      >
        <h6 class="text-left mb-0">
          Sottoscrizione non attiva
          <b-icon icon="x-circle" variant="danger"></b-icon>
        </h6>
        <SetPatientSubscriptionAdmin
          :user="user"
          :subscriptions="subscriptions?.lettura"
          @set-patient-subscription="requestSubscriptionForUser($event)"
        />
      </b-row>
    </template>
    <b-spinner v-else></b-spinner>
  </div>
</template>

<script>
import { croomService, adminService } from "@/_services";
import SetPatientSubscriptionAdmin from "@/components/profile/SetPatientSubscriptionAdmin.vue";
import { mapActions } from "vuex";

export default {
  name: "SeeAndActivateFreeSubscriptionsAsAdmin",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    SetPatientSubscriptionAdmin,
  },
  data() {
    return {
      subscriptions: null,
    };
  },
  methods: {
    getUserSubscriptions() {
      const self = this;
      const filters = {
        service_ref: 8,
      };
      croomService
        .getUserSubscriptions(this.user.hashid, filters)
        .then(function (res) {
          self.subscriptions = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Non è stato possibile completare l'operazione.");
        });
    },
    requestSubscriptionForUser(subscriptionSlug) {
      const self = this;
      adminService
        .requestUserSubscription(this.user.hashid, {
          slug: subscriptionSlug,
        })
        .then(function (res) {
          self.successAlert("Attivazione eseguita correttamente!");
          self.getUserSubscriptions();
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Non è stato possibile completare l'operazione.");
        });
    },
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
  },
  mounted() {
    this.getUserSubscriptions();
  },
};
</script>

<style scoped></style>
