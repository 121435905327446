<template>
  <b-container fluid class="px-0">
    <SetNuovaPrenotazionePazienteNavbar
      :step="reservationStep"
      :showPresso="!wantsToSearchForCentroErogatore"
      :showDiCosaHaiBisogno="reservationData.type !== 'teleconsulto'"
    />

    <transition-group class="w-100" name="slideside">
      <DiCosaHaiBisogno
        v-if="reservationStep === 1"
        :resData="reservationData"
        :wantsToSearchForCentroErogatore="wantsToSearchForCentroErogatore"
        @selected-new-health-service="
          $emit('selected-new-health-service', $event)
        "
        @selected-prestazione-name="$emit('selected-prestazione-name', $event)"
        @selected-product="$emit('selected-product', $event)"
        @uploaded-order="$emit('uploaded-order', $event)"
        :key="'step1'"
        :selectedData="selectedData"
      />

      <Presso
        :key="'step2'"
        :selectedData="selectedData"
        :resData="reservationData"
        v-if="reservationStep === 2"
        @selected-company="$emit('selected-company', $event)"
        @selected-new-health-service="
          $emit('selected-new-health-service', $event)
        "
      />

      <Data
        :key="'step3'"
        v-if="reservationStep === 3"
        :selectedCompany="selectedData.company"
        @preferenze-changed="$emit('preferenze-changed', $event)"
        @date-selected="$emit('date-selected', $event)"
        :isProxy="isProxy"
        :resData="reservationData"
        :selectedData="selectedData"
      />

      <Riepilogo
        v-if="reservationStep === 4"
        :key="'ste4'"
        :newReservation="newReservation"
        :selectedData="selectedData"
      >
      </Riepilogo>
    </transition-group>
  </b-container>
</template>

<script>
import { utilityService, supportService } from "@/_services"
import { mapState, mapActions } from "vuex"

import axios from "axios"
import SetNuovaPrenotazionePazienteNavbar from "@/components/prenotazioni/paziente/nuova/SetNuovaPrenotazionePazienteNavbar.vue"
import DiCosaHaiBisogno from "@/components/prenotazioni/paziente/reservationSettingSteps/DiCosaHaiBisogno.vue"

import Presso from "@/components/prenotazioni/paziente/reservationSettingSteps/Presso.vue"
import Data from "@/components/prenotazioni/paziente/reservationSettingSteps/Data.vue"
import Riepilogo from "@/components/prenotazioni/paziente/reservationSettingSteps/Riepilogo.vue"

export default {
  name: "SetNuovaPrenotazionePaziente",
  data() {
    return {
      selectedHealthService: null
    }
  },
  props: {
    reservationStep: {
      type: Number,
      required: true
    },
    selectedData: {
      type: Object,
      required: false
    },
    reservationData: {
      type: Object,
      required: true
    },
    isProxy: {
      type: Boolean,
      required: false,
      default: false
    },
    newReservation: {
      type: Object,
      required: false
    },
    wantsToSearchForCentroErogatore: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    SetNuovaPrenotazionePazienteNavbar,
    DiCosaHaiBisogno,
    Presso,
    Data,
    Riepilogo
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {},
      deep: true
    },
    selectedCompany: function (newVal, oldVal) {
      this.$emit("selectedCompany", newVal)
    },
    companiesOptions: function (newVal, oldVal) {
      if (
        this.selectedCompany &&
        !newVal.map((item) => item.value).includes(this.selectedCompany)
      ) {
        this.selectedCompany = null
      }
    }
  },
  computed: {
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
    hasAtLeastOneFilterSetted() {
      return Object.values(this.form).some(
        (val) => val !== null && val !== "" && val !== undefined
      )
    }
  },
  methods: {
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    getSubtypes() {
      var self = this
      utilityService
        .getSubtypes()
        .then(function (response) {
          self.subtypesOptions = Object.values(response.data.data)
            .concat()
            .flat()
            .map(function (item) {
              return {
                value: item.hashid,
                text: item.name
              }
            })
        })
        .catch(function (error) {
          console.error("Error fetching subtypes:", error)
        })
    },
    removeNullOrEmptyDataBeforeSend(data) {
      var self = this
      var newData = {}
      Object.keys(data).forEach(function (key) {
        if (data[key] !== null && data[key] !== "") {
          newData[key] = data[key]
        }
      })
      return newData
    },
    doSearch() {
      var self = this
      this.loading = true
      // Annulla la richiesta precedente se esiste
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("Request canceled by the user.")
      }

      // Crea un nuovo CancelToken per la nuova richiesta
      this.cancelTokenSource = axios.CancelToken.source()

      supportService
        .searchCompanyBySubtype(
          this.removeNullOrEmptyDataBeforeSend(this.form),
          this.cancelTokenSource.token
        )
        .then(function (response) {
          self.companiesOptions = response.data.data.map(function (item) {
            return {
              value: item.hashid,
              text: item.denominazione
            }
          })
        })
        .catch(function (error) {
          if (axios.isCancel(error) || error == "canceled") {
            console.log("Request canceled by the user")
          } else {
            self.errorAlert(
              "non è stato possibile completare la ricerca dei centri erogatori"
            )
          }
        })
        .finally(() => {
          self.loading = false
          self.hasFetched = true
        })
    },
    handleSelectedProductOrder(productOrder) {
      console.log(
        "Emitting handle selected product order in SetNuovaPrenotazionePaziente"
      )
      this.$emit("selected-product", productOrder)
    }
  },
  mounted() {},
  created() {
    this.getSubtypes()
    this.getRegioni()
    this.getProvince({})
    this.getComuni({})
  },
  beforeDestroy() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel("Request canceled by the user.")
    }
  }
}
</script>

<style scoped></style>
