<template>
  <b-container
    ref="navmain"
    fluid
    class="shadow_1 h-100 px-0 text-font-grey bg-white"
  >
    <transition-group name="slideside" mode="out-in">
      <b-row align-v="center" class="px-0" key="logo-row">
        <b-col
          v-if="$root.isMobile"
          class="text-right"
          cols="3"
          lg="2"
          key="padspace"
        >
        </b-col>
        <b-col class="px-1 py-2 mt-2 text-left" cols="6" lg="12" sm="12">
          <router-link :to="{ name: 'Home' }" class="">
            <img
              v-if="menuIsCompact && !$root.isMobile"
              src="@/assets/concura-logo.svg"
              alt="appLogo"
              class="px-lg-2"
              style="max-width: 75px"
              key="icon_logo"
            />
            <img
              v-else
              src="@/assets/concura-logo.svg"
              alt="appLogo"
              key="full_logo"
              :style="
                $root.isCollapsed ? 'max-width: 80px' : 'max-width: 100px'
              "
            />
          </router-link>
        </b-col>
        <b-col
          key="mobile_ham"
          v-if="$root.isMobile"
          class="text-right"
          cols="3"
          lg="2"
          @click="menuVisible = !menuVisible"
        >
          <b-icon font-scale="1.3" icon="filter-left"></b-icon>
        </b-col>
      </b-row>
      <b-row cols="1" class="px-0" key="menu-row" v-if="menuVisible">
        <b-col
          class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left"
          v-if="!$root.isMobile"
          key="desk_ham"
        >
          <p class="pb_15 nav__item bg-white">
            <span
              class="d-flex align-items-center pl-1 py-1 general_border_3 point pt-3"
              @click="handleMenuSize"
            >
              <b-icon font-scale="1.7" icon="filter-left"></b-icon>
              <span class="mb-0 ml-2"></span>
              <span v-if="!menuIsCompact" class="text-font-light-grey"
                >MENU</span
              >
            </span>
          </p>
        </b-col>
        <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
          <!-- <p class="text-font-light-grey">
            <span :class="menuIsCompact ? 'text-white' : ''">APP</span>
          </p> -->
          <!-- <p>
            <router-link
              class="my-3 mx-auto text-font-grey"
              :to="positions[clientiSet.position_ref]"
            >
              <b-icon font-scale="1.3" :icon="clientiSet.logo"></b-icon>
              <span class="mb-0 ml-2">{{ clientiSet.name }}</span>
            </router-link>
          </p> -->
          <p class="pb_15 nav__item bg-white">
            <router-link
              class="d-flex align-items-center pl-1 py-1 general_border_3"
              :class="
                this.$route.name === 'AdminDashboard'
                  ? 'router-link-exact-active'
                  : ''
              "
              :to="{ name: 'AdminDashboard' }"
            >
              <b-icon font-scale="1.3" icon="house-door"></b-icon>
              <span class="mb-0 ml-2">Dashboard</span>
            </router-link>
          </p>
        </b-col>
        <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
          <!-- <p class="text-font-light-grey">
            <span :class="menuIsCompact ? 'invisible' : ''">STRUMENTI</span>
          </p> -->
          <p
            class="pb_15 nav__item"
            :class="service.submenu ? 'bg-mdm-light-grey' : 'bg-white'"
            v-for="(service, ind) in activeServices"
            :key="ind"
          >
            <template v-if="service.submenu">
              <span
                :key="'sub-m-' + service.routeName"
                class="d-flex align-items-center pl-1 py-1 general_border_3 point"
                :class="
                  $route.name === service.routeName
                    ? 'router-link-exact-active'
                    : ''
                "
                @click="service.submenuVisible = !service.submenuVisible"
              >
                <b-icon font-scale="1.3" :icon="service.logo"></b-icon>
                <span class="mb-0 ml-2 text-capitalize">{{
                  service.name
                }}</span>
                <b-icon
                  font-scale="1"
                  :icon="service.submenuVisible ? 'chevron-up' : 'chevron-down'"
                  class="mr-1 ml-auto"
                ></b-icon>
              </span>
              <b-collapse
                class="mt-1"
                :id="service.name + '-sub'"
                v-model="service.submenuVisible"
              >
                <p
                  v-for="rt in service.subroutes"
                  :key="'sub-' + rt.name"
                  class="mb-1"
                >
                  <router-link
                    class="d-flex align-items-center pl-1 py-1 general_border_3 align-items-center"
                    :to="{
                      name: service.routeName,
                      params: { section: rt.path },
                    }"
                    exact-active-class="terz_bg_gradient text-white submenu__active__class"
                  >
                    <b-iconstack font-scale="1.3">
                      <b-icon
                        stacked
                        icon="circle"
                        variant="font-grey"
                        scale="0.55"
                      ></b-icon>
                      <b-icon
                        stacked
                        icon="circle"
                        variant="extra-light"
                      ></b-icon>
                    </b-iconstack>
                    <span class="mb-0 ml-2 text-capitalize">{{ rt.name }}</span>
                  </router-link>
                </p>
              </b-collapse>
            </template>
            <template v-else>
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border_3"
                :to="{
                  name: service.routeName,
                }"
              >
                <b-icon font-scale="1.3" :icon="service.logo"></b-icon>
                <span class="mb-0 ml-2 text-capitalize">{{
                  service.name
                }}</span>
              </router-link>
            </template>
          </p>
        </b-col>

        <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
          <p class="text-font-light-grey"></p>

          <p class="pb_15 nav__item bg-white">
            <span
              class="d-flex align-items-center pl-1 py-1 general_border_3 point"
              @click.prevent="$bvModal.show('logout-confirm')"
            >
              <b-icon font-scale="1.3" icon="door-open"></b-icon>
              <span class="mb-0 ml-2 text-capitalize">Esci</span>
            </span>
          </p>
        </b-col>
      </b-row>
    </transition-group>
    <b-modal
      id="logout-confirm"
      centered
      title="Confermi di voler uscire dall'area privata?"
      ok-title="Sì, esci"
      ok-variant="popup-button"
      cancel-title="No, annulla"
      cancel-variant="popup-button"
      footer-border-variant="white"
      @ok="onSubmit"
      @cancel="$bvModal.hide('logout-confirm')"
    >
      <!-- <h3>Confermi di voler uscire dall'area privata?</h3> -->
    </b-modal>
  </b-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "NavigationAdmin",
  props: ["wlistener"],
  watch: {
    $route(to, from) {
      if (this.$root.isMobile) {
        this.menuVisible = false;
      }
    },
    wlistener: function () {
      this.menuIsCompact = this.$refs.navmain.clientWidth <= 80;
    },
  },
  computed: {
    welcomeName() {
      if (
        this.user &&
        this.user.anagrafica &&
        this.user.anagrafica.name &&
        this.user.anagrafica.surname
      ) {
        return (
          this.user.anagrafica.name.toLowerCase() +
          " " +
          this.user.anagrafica.surname.toLowerCase()
        );
      }
      return "Profilo";
    },
    canSeeImpostazioni() {
      if (this.user.type === 1) {
        return (
          this.user.permessi.includes("visualizzare anagrafica azienda") ||
          this.user.permessi.includes("gestire impostazioni dei servizi") ||
          this.user.permessi.includes("gestire operatori")
        );
      }
      return true;
    },
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      menuVisible: true,
      positions: {
        1: "prenotazioni",
        2: "teleconsulto",
        3: "attivita",
        4: "pazienti",
        5: "agenda",
        6: "clienti",
      },
      activeServices: [
        {
          name: "Aziende",
          logo: "briefcase",
          disabled: false,
          // position_ref: 4,
          routeName: "gestione aziende",
          scope: undefined,
          submenu: false,
        },
        {
          name: "Utenti",
          logo: "people",
          disabled: false,
          // position_ref: 1,
          routeName: "gestione utenti",
          scope: undefined,
          submenu: false,
        },
        {
          name: "Contatti",
          logo: "telephone",
          disabled: false,
          // position_ref: 3,
          routeName: "contattipubblici",
          scope: undefined,
          submenu: false,
        },
        {
          name: "Registrazioni",
          logo: "person-check",
          disabled: false,
          // position_ref: 3,
          routeName: "registrazioniAdmin",
          scope: undefined,
          submenu: false,
        },
        {
          name: "Prenotazioni",
          logo: "calendar2",
          disabled: false,
          // position_ref: 1,
          routeName: "gestione prenotazioni",
          scope: undefined,
          submenu: false,
          submenuVisible: false,
        },
        {
          name: "Sottoscrizioni",
          logo: "credit-card",
          disabled: false,
          // position_ref: 3,
          routeName: "admin sottoscrizioni",
          scope: undefined,
          submenu: true,
          submenuVisible: false,
          subroutes: [
            {
              name: "Voucher",
              path: "voucher",
            },
          ],
        },
        {
          name: "Screening",
          logo: "check2-square",
          disabled: false,
          // position_ref: 3,
          routeName: "screening",
          scope: undefined,
          submenu: true,
          submenuVisible: false,
          subroutes: [
            {
              name: "Raccolte",
              path: "raccolte",
            },
            {
              name: "Questionari",
              path: "questionari",
            },
            {
              name: "Crea",
              path: "questionari/nuovo",
            },
          ],
        },
        {
          name: "Alerts",
          logo: "bell",
          disabled: false,
          // position_ref: 3,
          routeName: "admin alerts",
          scope: undefined,
          submenu: false,
        },
        {
          name: "Logs",
          logo: "graph-up",
          disabled: false,
          // position_ref: 3,
          routeName: "log di sistema",
          scope: undefined,
          submenu: false,
        },
        {
          name: "Impostazioni",
          logo: "gear",
          disabled: false,
          // position_ref: 3,
          routeName: "impostazioni sistema",
          scope: undefined,
          submenu: false,
        },
      ],

      menuIsCompact: false,
    };
  },
  methods: {
    ...mapActions("utente", ["logout"]),
    onSubmit() {
      this.logout();
    },
    checkPath() {
      if (this.user) {
        return "/" + this.user.i_am + "/home";
      }
    },
    handleMenuSize() {
      var navById = document.getElementById("nav-desktop");
      var currentWid = navById.clientWidth;
      if (currentWid <= 80) {
        //
        navById.style.minWidth = "inherit";
        navById.style.flex = "0 0 15em";
        this.menuIsCompact = false;
      } else {
        //
        navById.style.minWidth = "0";
        navById.style.flex = "0 0 79px";
        this.menuIsCompact = true;
      }
    },
  },
  created() {
    if (this.$root.isMobile) {
      this.menuVisible = false;
    }
  },
  mounted() {
    // console.log(this.$refs.navmain.clientWidth);
    this.menuIsCompact = this.$refs.navmain.clientWidth <= 80;
  },
  updated() {},
};
</script>
