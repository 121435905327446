var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"p-0",attrs:{"fluid":"","id":"month-calendar-container"}},[_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.reservations),expression:"reservations"}],attrs:{"id":"month-calendar-row"}},_vm._l((_vm.reservations),function(dayRes,date,index){return _c('b-col',{key:index,staticClass:"border border-light-grey bg-white px-2 pb-4",class:_vm.hasBorder(index),on:{"click":function($event){return _vm.$emit('go-to-day', date)}}},[(_vm.dayIsInPickMonth(date))?[_c('h5',{staticClass:"d-flex align-items-center justify-content-center pt-2 pb-2"},[_c('span',{staticClass:"weekday-label"},[_vm._v(_vm._s(_vm.getSingleDayWeekDay(date)))]),_c('span',{staticClass:"day-label"},[_vm._v(" "+_vm._s(_vm.getSingleDayDay(date)))])]),(Object.keys(dayRes).includes('teleconsulto'))?_c('p',{staticClass:"calendar-month-card my-3",class:dayRes.teleconsulto.all_inactive
              ? 'disabled__card'
              : `bg-${_vm.SERVIZI_VARIANTS['teleconsulto']}-light`},[_c('span',[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"camera-video"}}),_c('span',{staticClass:"font-weight-bolder"})],1),_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(dayRes.teleconsulto.count)+" ")])]):_vm._e(),(Object.keys(dayRes).includes('prodotto'))?_c('p',{staticClass:"calendar-month-card my-3",class:dayRes.prodotto.all_inactive
              ? 'disabled__card'
              : `bg-${_vm.SERVIZI_VARIANTS['prodotto']}-light`},[_c('span',[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"bag"}}),_c('span',{staticClass:"font-weight-bolder"})],1),_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(dayRes.prodotto.count)+" ")])]):_vm._e(),(Object.keys(dayRes).includes('prestazione'))?_vm._l((dayRes.prestazione),function(prestazioneInfo,prestazioneName){return _c('p',{key:prestazioneName,staticClass:"calendar-month-card my-3",class:prestazioneInfo.all_inactive
                ? 'disabled__card'
                : `bg-${_vm.SERVIZI_VARIANTS['prestazione']}-light`},[_c('span',[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":prestazioneInfo.icona}}),_c('span',{staticClass:"text-uppercase font-weight-bolder"},[_vm._v(" "+_vm._s(prestazioneInfo.tag)+" ")])],1),_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(prestazioneInfo.count)+" ")])])}):_vm._e()]:_vm._e()],2)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }