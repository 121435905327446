<template>
  <div class="bg-white p-3 general_border_17 shadow_2 px-1 h-100">
    <h3 class="text-left py-2">Pazienti presi in carico</h3>
    <b-overlay :show="loading">
      <div class="border border-light general_border__2" v-if="list">
        <b-row
          v-for="(patient, ind) in list"
          class="justify-content-between py-2 border-bottom border-light"
          align-v="center"
          :key="ind"
        >
          <b-col class="col-6 col-lg-7 text-left">
            <!-- <span>
              <b-icon variant="terz-grad-2" icon="circle-fill"></b-icon>
              Paziente {{ padPatientNumber(ind) }}
            </span>   -->
            <span class="text-capitalize">
              <b-icon variant="terz-grad-2" icon="circle-fill"></b-icon>
              {{ patient.anagrafica.name }} {{ patient.anagrafica.surname }}
            </span>
          </b-col>
          <b-col class="text-right pl-0">
            <span class="point ml-2" @click="flagActivityAsView(patient)">
              <b-icon
                v-b-tooltip.hover="'vai a scheda paziente'"
                variant="dark-grey"
                font-scale="1.7"
                icon="journal-medical"
              ></b-icon>
            </span>
            <span class="ml-2">
              <b-icon
                variant="dark-grey"
                font-scale="1.7"
                :icon="patient.seen_today ? 'eye-slash' : 'eye'"
              ></b-icon>
            </span>
            <span
              class="ml-2 point"
              title="Completa"
              v-if="!patient.completed_today && patient.seen_today"
              @click="openCheckModal(patient)"
            >
              <b-icon
                variant="dark-grey"
                font-scale="1.7"
                icon="check-circle-fill"
              ></b-icon>
            </span>
            <span
              class="ml-2"
              v-else-if="!patient.completed_today && !patient.seen_today"
            >
              <b-icon
                variant="dark-grey"
                font-scale="1.7"
                icon="check-circle-fill"
              ></b-icon>
            </span>
            <span class="ml-2" v-else>
              <b-icon
                variant="success"
                font-scale="1.7"
                icon="check-circle-fill"
              ></b-icon>
            </span>
          </b-col>
        </b-row>
        <p class="text-left" v-if="list.length === 0">
          Nessun paziente a carico
        </p>
      </div>
    </b-overlay>
    <b-modal
      id="close-activity"
      title="Completa attività"
      centered
      @hide="resetForm"
      size="lg"
      hide-footer
    >
      <div class="px-3">
        <b-form
          v-if="selectedPatient"
          class="px-3 pb-3"
          @submit.prevent="flagPatientAsDoneToday"
        >
          <b-form-group>
            <label class="pb-2"
              >Completa l'attività di oggi per
              <span class="text-capitalize">
                {{ selectedPatient.anagrafica.name }}
                {{ selectedPatient.anagrafica.surname }}</span
              >:</label
            >
            <b-form-textarea
              v-capitalize-first
              v-model="checkForm.note"
              placeholder="Note fine attività..."
              rows="20"
              required
            >
            </b-form-textarea>
          </b-form-group>
          <b-row>
            <b-button
              type="submit"
              variant="popup-button"
              block
              class="rounded-pill text-secondary mt-4 mb-2 px-1 py-2 point fw-bolder shadow_8"
              >Salva</b-button
            >
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex"
import { croomService } from "@/_services"
export default {
  name: "PatientList",
  props: ["seldate"],
  data() {
    return {
      list: null,
      loading: false,
      filters: {
        date: null
      },
      checkForm: {
        note: null
      },
      selectedPatient: null,
      today: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    canEdit: function () {
      // if (this.today) {
      //     return this.today === this.filters.date
      // }
      return this.today === this.filters.date
    }
  },
  watch: {
    seldate: function (value) {
      this.filters.date = value
      this.fetchData()
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    padPatientNumber(ndx) {
      const pseudName = ndx + 1
      return pseudName.toString().padStart(3, 0)
    },
    fetchData() {
      this.loading = true
      const self = this
      croomService
        .getOwnPatients(this.filters)
        .then(function (response) {
          self.list = response.data.data
          self.loading = false
        })
        .catch(function (error) {
          self.loading = false
          self.errorAlert("Non è stato possibile ottenere la lista di pazienti")
          console.log(error)
        })
    },
    goToPatientChart(hashid) {
      this.$router.push({ name: "scheda paziente", params: { hash: hashid } })
    },
    flagActivityAsView(patient) {
      const hashid = patient.hashid
      if (patient.seen_today || !this.canEdit) {
        return this.goToPatientChart(hashid)
      }
      const self = this
      croomService
        .flagPatientAsView(hashid, {})
        .then(function (response) {
          self.goToPatientChart(hashid)
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile accedere alla scheda paziente")
          console.log(error)
        })
    },
    flagPatientAsDoneToday() {
      const self = this
      croomService
        .flagPatientAsDoneToday(this.selectedPatient.hashid, this.checkForm)
        .then(function (response) {
          self.successAlert("Attività completata correttamente")
          self.$bvModal.hide("close-activity")
          self.selectedPatient = null
          self.fetchData()
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile accedere alla scheda paziente")
          console.log(error)
        })
    },
    openCheckModal(patient) {
      if (this.canEdit) {
        this.selectedPatient = patient
        this.$bvModal.show("close-activity")
      }
    },
    resetForm() {
      this.selectedPatient = null
      this.checkForm.note = null
    }
  },
  created() {
    if (this.seldate) {
      this.filters.date = this.seldate
      this.today = this.seldate
    }
    this.fetchData()
  }
}
</script>
