<template>
  <div class="px-2 mb-4">
    <!-- <div class="px-4 mb-4" v-if="
    company &&
    (company.servizi_attivi['teleconsulto'] ||
      company.servizi_attivi['connessa'])
  "> -->
    <div v-if="next">
      <h4 class="text-left pb-1 mt-1 mb-3 page-title">Teleconsulto</h4>
      <div class="col-sm-10 col-lg-8 mx-auto py-3" v-if="!hasStarted">
        <b-row
          :cols="$root.isMobile ? '1' : '2'"
          class="align-items-centerr bg-white puntofarmaCard general_border justify-content-center py-4"
        >
          <b-col
            class="d-flex flex-column align-content-center"
            :order="$root.isMobile ? '2' : '1'"
          >
            <p class="text-capitalize m-auto">
              {{ next.subjectable.anagrafica.name }}
              {{ next.subjectable.anagrafica.surname }}
            </p>
            <div class="m-auto">
              <b-button
                v-if="canStart"
                variant="secondary  text-white "
                @click="startTeleconsulto"
                >Avvia
              </b-button>
            </div>
          </b-col>
          <!-- <b-col cols="1" class="px-0 border-left border-sec-4 py-3"></b-col> -->
          <b-col
            :class="$root.isMobile ? '' : 'border-left border-sec-4'"
            class="pl-2"
            :order="$root.isMobile ? '1' : '2'"
          >
            <p class="text-prim-grad-1">
              Consulto
              <strong v-if="next.filled_survey">{{
                next.filled_survey.survey_name
              }}</strong>
            </p>
            <p class="">
              {{ next.data_inizio | moment("DD/MM/YYYY H:mm") }}
            </p>
            <p v-if="next.filled_survey" class="point" @click="showThis(next)">
              <u>Vedi questionario</u>
            </p>
          </b-col>
        </b-row>
      </div>
      <div v-else class="d-flex flex-column justify-content-between">
        <div class="text-white" style="min-height: 50vh">
          <VideoCaller
            v-if="showVCaller"
            :tcchannel="next.orderable.channel"
            v-on:tc-end="handleEndTc($event)"
            v-on:tc-start="handleStartTc($event)"
          />
        </div>
        <div class="col-12mx-auto py-2">
          <b-row
            :cols="$root.isMobile ? '2' : '3'"
            class="align-items-centerr bg-white shadow_7 general_border justify-content-center py-2"
          >
            <b-col class="d-flex flex-column justify-content-center">
              <p class="text-capitalize">
                {{ next.subjectable.anagrafica.name }}
                {{ next.subjectable.anagrafica.surname }}
              </p>
              <!-- <div class="m-auto"> -->
              <p
                v-if="next.filled_survey"
                class="text-info point"
                @click="showThis(next)"
              >
                <u>Vedi questionario</u>
              </p>
              <!-- </div> -->
            </b-col>
            <b-col class="pr-0 border-left border-sec-4 align-self-center">
              <p class="text-prim-grad-1 text-left">
                <strong v-if="next.filled_survey">{{
                  next.filled_survey.survey_name
                }}</strong>
              </p>
              <p class="text-left">
                <strong v-if="duration !== null"
                  >Durata: {{ duration | moment("HH:mm:ss") }}</strong
                >
              </p>
            </b-col>
            <b-col
              class="align-self-center"
              :class="$root.isMobile ? 'text-center col-12' : ''"
            >
              <b-button variant="outline-secondary " @click="endTeleconsulto"
                >Termina</b-button
              >
            </b-col>
          </b-row>
        </div>
        <b-row>
          <div class="col-12 col-lg-6 mx-auto py-2 mt-3">
            <b-row
              cols="1"
              class="col-12 align-items-centerr bg-white shadow_7 general_border justify-content-center py-2"
            >
              <MessagingComponent
                v-if="showVCaller"
                :tcchannel="next.orderable.channel + 'MSG'"
              />
            </b-row>
          </div>
          <div class="col-12 col-lg-6 mx-auto py-2 mt-3">
            <b-row
              cols="1"
              class="align-items-centerr bg-white shadow_7 general_border justify-content-center py-2"
            >
              <b-col>
                <p class="text-left">Appunti sul consulto</p>
              </b-col>
              <b-col>
                <b-form-textarea
                  v-capitalize-first
                  id="notes"
                  v-model="notes"
                  placeholder="..."
                  rows="4"
                ></b-form-textarea>
              </b-col>
            </b-row>
          </div>
        </b-row>
      </div>
      <p class="mt-2" v-if="hasEnded">Consulto concluso.</p>
    </div>
    <div v-if="prenotazioni && !hasStarted">
      <h4 class="text-left pb-1 mt-1 mb-3 page-title">
        Consulti previsti per oggi
      </h4>
      <p v-for="(pren, ind) in prenotazioni" :key="ind" class="text-left">
        <strong>Ore {{ pren.data_inizio | moment("H:mm") }}</strong>
        <strong v-if="pren.filled_survey">
          {{ pren.filled_survey.survey_name }}
        </strong>
        con
        <span class="text-capitalize text-prim-grad-1">
          {{ pren.subjectable.anagrafica.name }}
          {{ pren.subjectable.anagrafica.surname }}
        </span>
        <span v-if="pren.status === 4">
          durato {{ pren.orderable.durata_effettiva }} minuti
        </span>
        <span v-else> durata prevista {{ pren.orderable.durata }} minuti </span>
      </p>
      <p v-if="prenotazioni.length == 0" class="">
        Nessun consulto previsto per oggi
      </p>
    </div>
    <b-modal
      ref="show-filleditem"
      centered
      hide-footer
      title="Questionario"
      size="xl"
    >
      <FilledSurveyShower
        v-if="selectedSurvey"
        :response="selectedSurvey.response"
        :score="selectedSurvey.score"
      />
    </b-modal>
  </div>
  <!-- <div v-else class="my-4">
    <h4 class="text-danger">
      Per utilizzare le funzionalità di teleconsulto è necessario acquistare
      l'abbonamento .
    </h4>
  </div> -->
</template>
<script>
import * as moment from "moment/moment"
import { mapState, mapActions } from "vuex"
import { reservationService } from "@/_services"
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue"
import VideoCaller from "@/components/teleconsulto/VideoCaller.vue"
import MessagingComponent from "@/components/teleconsulto/MessagingComponent.vue"
export default {
  name: "PreConsulto",
  components: { FilledSurveyShower, VideoCaller, MessagingComponent },
  data() {
    return {
      prenotazioni: null,
      filterSetted: {
        status: "in corso",
        service: undefined,
        search: undefined,
        cf: undefined,
        position_ref: 2,
        page: 1,
        take: 10,
        range: "oggi",
        indesc: true,
        prestazione: null
      },
      next: null,
      selectedSurvey: null,
      background: null,
      hasStarted: false,
      showVCaller: false,
      timer: null,
      duration: null,
      canStart: false,
      hasEnded: false,
      notes: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"])
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchNext() {
      var self = this
      reservationService
        .getReservationDetails(this.$route.query.reserv)
        .then(function (response) {
          self.next = response.data.data
          self.valiDate(response.data.data)
        })
        .catch(function (error) {
          // self.updatingData = false;
          self.errorAlert("non è stato possibile prelevare le richieste")
          // console.log("error fetch prenotazioni", error);
          // self.loading = false;
        })
    },
    fetchData() {
      // this.loading = true;
      var self = this
      reservationService
        .fetchPrenotazioni(this.filterSetted)
        .then(function (response) {
          self.prenotazioni = response.data.data.data
        })
        .catch(function (error) {
          // self.updatingData = false;
          self.errorAlert("non è stato possibile prelevare le richieste")
          // console.log("error fetch prenotazioni", error);
          // self.loading = false;
        })
    },
    valiDate(reserv) {
      var startDate = moment(reserv.data_inizio)
      var endDate = moment(reserv.data_inizio)
      var end = moment(endDate).add(reserv.orderable.durata + 5, "minutes")
      var start = moment(startDate).subtract(5, "minutes")
      var now = moment()
      this.canStart = now.isAfter(start) && now.isBefore(end)
      // this.canStart = true;
    },
    showThis(selected) {
      this.selectedSurvey = selected.filled_survey
      this.$refs["show-filleditem"].show()
    },
    addBlackBg() {
      this.background.classList.remove("bg-white")
      this.background.classList.add("bg-black")
    },
    removeBlackBg() {
      this.background.classList.add("bg-white")
      this.background.classList.remove("bg-black")
    },
    startTeleconsulto() {
      this.hasStarted = true
      this.showVCaller = true
      this.addBlackBg()
    },
    endTeleconsulto() {
      this.hasStarted = false
      this.removeBlackBg()
    },
    handleEndTc(data) {
      // console.log("TC ENDED", data);
      clearInterval(this.timer)
      this.timer = null
      var newDuration = this.duration !== null ? this.duration.minutes() : 0
      var formToSend = {
        hash: this.next.hashid,
        form: {
          // status: 4,
          durata_effettiva: newDuration,
          operator_notes: this.notes
        }
      }
      reservationService
        .updateTcDuration(formToSend)
        .then(function (response) {
          self.hasEnded = true
        })
        .catch(function (error) {
          // console.log(error);
        })
    },
    handleStartTc(data) {
      console.log("TC STARTED", data)
      if (data === false) {
        this.endTeleconsulto()
        return
      }
      this.startTimer()
    },
    startTimer() {
      var mom = moment().hour(0).minute(0).second(0)
      this.duration = mom
      this.timer = setInterval(() => {
        var dur = moment(this.duration).add(1, "second")
        this.duration = dur
      }, 1000)
    }
  },
  created() {
    // this.fetchData();
    if (this.$route.query.reserv) {
      this.fetchNext()
    } else {
      this.fetchData()
    }
  },
  mounted() {
    var container = document.getElementsByClassName("custom__content__df")
    this.background = container.length > 0 ? container[0] : null
    var lib = document.createElement("script")
    lib.setAttribute("id", "ls_lib")
    lib.setAttribute("type", "text/javascript")
    lib.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/fm.liveswitch@latest/fm.liveswitch.js"
    )
    document.head.appendChild(lib)
  },
  beforeDestroy() {
    this.removeBlackBg()
    var lib = document.getElementById("ls_lib")
    document.head.removeChild(lib)
  }
}
</script>
<style scoped>
.custom__content__df {
  background-color: #000 !important;
}
</style>
