<template>
  <div class="ml-lgx-2">
    <h3 class="mt-2 pt-4 text-left">
      <b-icon
        icon="people"
        variant="secondary"
        font-scale="1.2"
        class="mr-1"
      ></b-icon>
      Contatti Utili
    </h3>
    <b-row class="flex-wrap" cols="1" cols-lg="2">
      <b-col
        class="pl-0"
        v-for="(contact, indx) in contacts"
        :key="contact.hashid"
      >
        <section class="puntofarmaCard p-2 p-md-3 p-lg-4 mt-2 general_border">
          <EditEmergencyContact
            :contact="contact"
            @saved-contact="handleSavedContact"
          >
          </EditEmergencyContact>
        </section>
      </b-col>
    </b-row>
    <p v-if="contacts && contacts.length == 0" class="text-left mt-2 py-2">
      Nessun contatto inserito. <br />
      <b-button class="mt-1" @click="$bvModal.show('add-contact')">
        <b-icon icon="plus"></b-icon>
        nuovo contatto
      </b-button>
    </p>
    <b-row v-else class="mt-2" cols="1" cols-lg="2">
      <b-col class="text-right">
        <b-button @click="$bvModal.show('add-contact')">
          <b-icon icon="plus"></b-icon>
          nuovo contatto
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="add-contact"
      size="lg"
      centered
      title="Aggiungi contatto utile"
      button-size="sm"
      hide-footer
      cancel-title="Annulla"
      cancel-variant="popup-button mr-2"
      ok-title="Salva contatto"
      ok-variant="popup-button"
      footer-class="border-0"
    >
      <NewEmergencyContact
        :hashid="user.hashid"
        @saved-contact="handleSavedContact"
      ></NewEmergencyContact>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { cittadinoService } from "@/_services";
import NewEmergencyContact from "@/components/profile/contatti/NewEmergencyContact.vue";
import EditEmergencyContact from "@/components/profile/contatti/EditEmergencyContact.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "ContattiEmergenza",
  props: ["user"],
  // computed: {
  //   ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
  // },
  components: {
    NewEmergencyContact,
    EditEmergencyContact,
  },
  data() {
    return {
      editIndexes: [false, false, false],
      avatarPath: null,
      selected: {
        regione: null,
        provincia: null,
        comune: null,
      },
      anagrafica: {
        regione: null,
        provincia: null,
        comune: null,
        cap: null,
        address: null,
        name: null,
        surname: null,
        cf: null,
        gender: null,
        birthday: null,
        ts_number: null,
        tel_number: null,
      },
      enableBtn: false,
      isPost: true,
      selectingImage: false,
      contacts: null,
    };
  },
  methods: {
    // ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    ...mapActions("utente", ["setAnagrafica", "me"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchUserContacts() {
      var self = this;
      cittadinoService
        .getUserEmergencyContacts(this.user.hashid)
        .then(function (res) {
          self.contacts = res.data.data;
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          self.notFound = true;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    handleSavedContact() {
      this.fetchUserContacts();
      this.$bvModal.hide("add-contact");
    },
  },
  created() {
    this.fetchUserContacts();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
input.form-control:read-only {
  border-bottom: 1px solid darkgrey;
  border-radius: 0px !important;
  box-shadow: none !important;
  color: $body-color;
}
</style>
