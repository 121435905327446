<template>
  <b-col class="col-12 col-md-6 col-xl-4 px-0 mt-3">
    <div class="rec-res-card-wrapper h-100">
      <section class="puntofarmaCard py-3 px-3 h-100 d-flex flex-column">
        <div class="flex-grow-1">
          <p class="text-left mb-0">
            <template v-if="res.orderable_type === 'teleconsulto'">
              <span class="f-w-410">Teleconsulto</span>
            </template>
            <template v-else>
              <span class="f-w-410">{{ res.orderable.name }} </span></template
            >
            presso {{ res.company.ff_denominazione }}
          </p>
          <p class="text-left mb-0">
            Ogni {{ res.every_num === 1 ? "" : res.every_num }}
            {{
              res.every_num > 1
                ? frequencies[res.every_um]
                : frequenciesSingular[res.every_um]
            }}

            <span v-if="res.operator"
              >con {{ res.operator.anagrafica.title }}
              {{ res.operator.anagrafica.name }}
              {{ res.operator.anagrafica.surname }}</span
            >
          </p>
        </div>
        <p
          class="text-left mb-0 d-flex justify-content-between align-items-center"
        >
          <span>
            <span v-if="res.enabled" class="text-secondary">
              <b-icon
                icon="arrow-repeat"
                font-scale="1.5"
                class="pt-1"
              ></b-icon>
              <span class="ml-2">Attiva</span>
            </span>
            <span v-else class="text-warning">
              <b-icon icon="pause" font-scale="1.5" class="pt-1"></b-icon>
              <span class="ml-2">Sospesa</span>
            </span>
          </span>
          <span>
            <b-iconstack
              font-scale="2.2"
              class="mx-1 point"
              title="Modifica"
              @click="$emit('edit-freq', res.hashid)"
            >
              <b-icon
                stacked
                icon="circle-fill"
                variant="mdm-light-grey"
              ></b-icon>
              <b-icon
                stacked
                icon="pencil-fill"
                scale="0.5"
                variant="secondary"
              ></b-icon>
            </b-iconstack>

            <template v-if="activesub">
              <template v-if="res.enabled">
                <b-iconstack
                  font-scale="2.2"
                  class="mx-1 point"
                  title="Sospendi"
                  @click="$emit('suspend-freq', res.hashid)"
                >
                  <b-icon
                    stacked
                    icon="circle-fill"
                    variant="mdm-light-grey"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="pause-fill"
                    scale="0.5"
                    variant="secondary"
                  ></b-icon>
                </b-iconstack>
              </template>
              <template v-else>
                <b-iconstack
                  font-scale="2.2"
                  title="riprendi"
                  class="point mx-1"
                  @click="$emit('unsuspend-freq', res.hashid)"
                >
                  <b-icon
                    stacked
                    icon="circle-fill"
                    variant="mdm-light-grey"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="play-fill"
                    scale="0.5"
                    variant="secondary"
                  ></b-icon>
                </b-iconstack>
              </template>
            </template>

            <b-iconstack v-else font-scale="2.2" class="mx-1" title="Modifica">
              <b-icon
                stacked
                icon="circle-fill"
                variant="mdm-light-grey"
              ></b-icon>
              <b-icon
                stacked
                icon="play-fill"
                scale="0.5"
                variant="grey"
              ></b-icon>
            </b-iconstack>
            <b-iconstack
              font-scale="2.2"
              class="mx-1 point"
              title="Elimina"
              @click="$emit('delete-freq', res.hashid)"
            >
              <b-icon
                stacked
                icon="circle-fill"
                variant="mdm-light-grey"
              ></b-icon>
              <b-icon
                stacked
                icon="trash-fill"
                scale="0.5"
                variant="secondary"
              ></b-icon>
            </b-iconstack>
          </span>
        </p>
      </section>
    </div>
  </b-col>
</template>

<script>
export default {
  name: "PrenotazioneRicorrenteCard",
  props: ["res", "activesub"],
  computed: {},
  data() {
    return {
      frequenciesSingular: {
        0: "mese",
        1: "settimana",
        2: "giorno",
      },
      frequencies: {
        0: "mesi",
        1: "settimane",
        2: "giorni",
      },
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.rec-res-card-wrapper {
  padding-left: 15px;
  padding-right: 15px;
}
</style>
