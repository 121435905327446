<template>
  <div class="col-sm-12 col-md-8 mx-auto mt-4">
    <h1 class=" text-danger">Utenza disabilitata</h1>
    <div class="col-lg-4 col-sm-7 mx-auto my-3 pb-4">
      <span class="text-secondary fs-6">La tua utenza è stata disabilitata</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Disabled",
  data() {
    return {};
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
};
</script>

<style>
header.modal-header {
  border-color: transparent;
}
</style>