<template>
  <div class="col-12 px-0">
    <b-row
      cols="1"
      cols-md="2"
      class="justify-content-bewteen pb-2"
      align-v="center"
    >
      <b-col class="align-self-end px-0">
        <h4 class="text-left py-1 mb-0">Alert registrati</h4>
      </b-col>
      <b-col class="text-center my-3 my-md-0 text-md-right">
        <b-button
          @click="$bvModal.show('new_alert')"
          class="mr-2"
          v-if="user.type === 1 && company.level === 5"
        >
          <b-icon icon="plus"></b-icon>
          nuovo alert
        </b-button>
        <b-button @click="$bvModal.show('protocols')">
          Configurazione protocollo
        </b-button>
      </b-col>
    </b-row>
    <template v-if="!activesub">
      <h5 class="text-center mt-4">Servizio di monitoraggio non attivo.</h5>
    </template>
    <template v-else>
      <b-row class="justify-content-end">
        <span class="mx-2">
          <label for="month_selector" class="mr-1">Mese</label>
          <select
            v-capitalize-first-select
            class="form-select bg-white border border-info general_border py-1"
            v-model="filters.month"
            @change="fetchData()"
            name="month_selector"
            id="month_selector"
          >
            <option v-for="month in filterOptions.month" :value="month.value">
              {{ month.label }}
            </option>
            <option :value="null">Tutti</option>
          </select>
        </span>
        <span>
          <label for="pickedYear" class="mr-1">Anno</label>
          <select
            v-capitalize-first-select
            class="form-select bg-white border border-info general_border"
            v-model="filters.year"
            @change="fetchData()"
            name="pickedYear"
            id="pickedYear"
          >
            <option
              v-for="year in filterOptions.year"
              :value="year.value"
              :key="year.value"
            >
              {{ year.text }}
            </option>
          </select>
        </span>
      </b-row>
      <template v-if="alertEvents">
        <b-row>
          <!-- <b-row class="boxes_max_heigh_60 hide_overflow_y"> -->
          <b-col
            v-for="alert in alertEvents"
            :key="alert.hashid"
            class="col-12 mx-auto"
          >
            <div
              class="general_border pb-4 pt-3 shadow_2 bg-white my-2 mx-lg-1 point"
              :class="
                alert.threshold_level !== null
                  ? 'border border__15__2 border-' +
                    valueLevelColors[Math.abs(alert.threshold_level)]
                  : ''
              "
               @click="redirectToDetail(alert.hashid)"
            >
              <b-row class="justify-content-between px-3">
                <p class="fs-5 mb-0">
                  {{ alert.created_at | moment("DD/MM/YYYY HH:mm") }}
                </p>
                <h6
                  v-if="alert.status === 1"
                  class="text-uppercase text-secondary"
                >
                  {{ alert.stato }}
                  <span v-if="alert.opener_operator" class=""
                    >da
                    <span class="text-capitalize">
                      {{ alert.opener_operator.anagrafica.name }}
                      {{ alert.opener_operator.anagrafica.surname }}
                    </span>
                  </span>
                </h6>
                <h6 v-else class="text-uppercase text-secondary">
                  {{ alert.stato }}
                  <span v-if="alert.closer_operator" class=""
                    >da
                    <span class="text-capitalize">
                      {{ alert.closer_operator.anagrafica.name }}
                      {{ alert.closer_operator.anagrafica.surname }}
                    </span>
                  </span>
                </h6>
              </b-row>
              <b-row class="justify-content-end px-3">
                <h6 v-if="alert.forwarded_to_operator" class="">
                  Inoltrato a
                  <span class="text-capitalize">
                    {{ alert.forwarded_to_operator.anagrafica.name }}
                    {{ alert.forwarded_to_operator.anagrafica.surname }}
                  </span>
                </h6>
              </b-row>

              <b-row class="px-2">
                <b-col class="col-12 col-md-6 col-lg-5 col-xl-5 text-justify">
                  <div v-if="alert.alertable_type === 'rilevazione'">
                    <h5>
                      <b-icon
                        font-scale="1.5"
                        icon="exclamation-triangle-fill"
                        :variant="
                          valueLevelColors[
                            Math.abs(alert.alertable.value_level)
                          ]
                        "
                      ></b-icon>
                      {{ alert.alertable.exam.name }}
                      <span>
                        {{ alert.alertable.value * 1 }}
                        {{ alert.alertable.exam.um }}
                      </span>
                    </h5>
                    <p>{{ alert.alertable.details }}</p>
                  </div>
                  <div v-else-if="alert.alertable_type === 'exam-frequency'">
                    <h5>
                      <b-icon
                        font-scale="1.5"
                        icon="exclamation-triangle-fill"
                        :variant="
                          valueLevelColors[Math.abs(alert.threshold_level)]
                        "
                      ></b-icon>
                      Il paziente non ha rilevato il valore relativo al
                      parametro
                      <span class="text-lowercase text-secondary">
                        {{ alert.alertable.exam.name }}.
                      </span>
                    </h5>
                    <p>{{ alert.alertable.details }}</p>
                  </div>
                  <div v-else-if="alert.alertable_type === 'score'">
                    <h5>
                      <b-icon
                        font-scale="1.5"
                        icon="exclamation-triangle-fill"
                        :variant="
                          valueLevelColors[Math.abs(alert.threshold_level)]
                        "
                      ></b-icon>
                      {{ alert.alertable.score.name }}:
                      <span> {{ alert.alertable.value * 1 }}. </span>
                    </h5>
                    <p>{{ alert.alertable.details }}</p>
                  </div>
                  <div v-else-if="alert.alertable_type === 'score-frequency'">
                    <h5>
                      <b-icon
                        font-scale="1.5"
                        icon="exclamation-triangle-fill"
                        :variant="
                          valueLevelColors[Math.abs(alert.threshold_level)]
                        "
                      ></b-icon>
                      Il paziente non ha rilevato il valore relativo allo score
                      <span class="text-lowercase text-secondary">
                        {{ alert.alertable.score.name }}.
                      </span>
                    </h5>
                    <p>{{ alert.alertable.details }}</p>
                  </div>
                  <div v-else-if="alert.alertable_type === 'annotation'">
                    <h5>
                      <b-icon
                        font-scale="1.5"
                        icon="exclamation-triangle-fill"
                        :variant="
                          valueLevelColors[Math.abs(alert.threshold_level)]
                        "
                      ></b-icon>
                      <span class="text-justify">
                        {{ alert.alertable.content }}.
                      </span>
                    </h5>
                    <p>{{ alert.alertable.details }}</p>
                  </div>
                  <h6>{{ alert.cause }}</h6>
                </b-col>
                <b-col>
                  <b-form-textarea
                    v-capitalize-first
                    v-model="alert.operator_notes"
                    placeholder="Annotazioni alert..."
                    class="cursor_regular"
                    rows="3"
                    readonly
                  >
                  </b-form-textarea>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <p class="text-left" v-if="alertEvents.length === 0">
          Nessun alert registrato
        </p>
      </template>
    </template>

    <b-modal
      id="protocols"
      title="Configurazione protocolli alert"
      size="xl"
      hide-footer
      centered
    >
      <AlertEventProtocols
        :subject="subject"
        @close-modal="$bvModal.hide('protocols')"
      ></AlertEventProtocols>
    </b-modal>

    <b-modal
      id="new_alert"
      centered
      hide-footer
      size="lg"
      title="Attiva nuovo alert"
    >
      <div class="px-3 pb-2">
        <b-form @submit.prevent="newAlertEvent" class="px-4">
          <b-form-group class="mb-4 col-8 mx-auto">
            <b-form-select
              v-capitalize-first-select
              v-model="newEventForm.threshold_level"
              class="border border-secondary"
              required
            >
              <b-form-select-option
                v-for="(label, level) in severityLabelMap"
                :key="label"
                :value="level"
                >{{ label }}</b-form-select-option
              >
              <b-form-select-option :value="null" disabled
                >Severità</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <b-form-group class="mb-4 col-8 mx-auto">
            <b-form-input
              v-capitalize-first
              placeholder="Causa"
              type="text"
              v-model="newEventForm.cause"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group class="mb-4 col-8 mx-auto">
            <b-form-textarea
              v-capitalize-first
              v-model="newEventForm.operator_notes"
              placeholder="Annotazioni..."
              rows="10"
              required
            >
            </b-form-textarea>
          </b-form-group>
          <br />
          <b-button
            type="submit"
            variant="popup-button"
            block
            class="rounded-pill text-secondary mt-4 mb-2 px-1 py-2 point fw-bolder shadow_8"
            >Salva</b-button
          >
        </b-form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { MONTHS } from "@/_utils/constants.js"
import { croomService } from "@/_services"
import { mapState, mapActions } from "vuex"

import AlertEventProtocols from "@/components/profile/sanitario/AlertEventProtocols.vue"
export default {
  name: "UserAlertEvents",
  props: ["subject", "activesub"],
  components: {
    AlertEventProtocols
  },
  data() {
    const currentYear = moment().year()
    const currentMonth = moment().month() + 1
    return {
      alertEvents: null,
      loading: false,
      filters: {
        year: currentYear,
        month: currentMonth,
        day: null
      },
      startYear: 2022,
      currentYear: currentYear,
      filterOptions: {
        year: [],
        month: MONTHS
      },
      valueLevelColors: {
        0: "success",
        1: "sec-3",
        2: "warning",
        3: "danger"
      },
      newEventForm: {
        user_id: null,
        threshold_level: null,
        operator_notes: null,
        cause: null
      },
      severityLabelMap: {
        1: "Bassa",
        2: "Media",
        3: "Alta"
      }
      // user: {
      //   type:null,
      //   my_company:{
      //     level: null,
      //   }
      // }
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"])
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      this.loading = true
      const self = this
      croomService
        .getUserAlertEvents(this.subject.hashid, this.filters)
        .then(function (response) {
          self.alertEvents = response.data.data
          self.loading = false
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le informazioni")
          console.log(error)
        })
    },
    setUser(data) {
      this.user = {
        type: data.type,
        my_company: {
          level: data.my_company.level
        }
      }
    },
    newAlertEvent() {
      this.newEventForm.user_id = this.subject.hashid
      const self = this
      croomService
        .storeNewAlertEvent(this.newEventForm)
        .then(function (response) {
          self.fetchData()
          self.$bvModal.hide("new_alert")
          self.successAlert("Alert salvato correttamente!")
          self.newEventForm = {
            threshold_level: null,
            operator_notes: null,
            cause: null
          }
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile salvare l'alert")
          console.log(error)
        })
    },
    redirectToDetail(hashid) {
    if(this.user.type === 1 && this.company.level === 5){
      this.$router.push({ 
        name: 'control room', 
        params: { section: 'detail', hash: hashid }
      });
    }
    },
  },
  created() {
    for (let i = this.currentYear; i >= this.startYear; i--) {
      let y = { value: `${i}`, text: `${i}` }
      this.filterOptions.year.push(y)
    }
    if (this.activesub) {
      this.fetchData()
    }
  }
}
</script>
<style>
.cursor_regular {
  cursor: auto;
}
</style>
