<template>
  <b-row class="header__shadow bg-secondary text-white p-4 mb-3">
    <h1 class="pl-2 pl-lg-4 py-2 py-lg-3 py-xl-4">
      {{ routeLabel }}
    </h1>
    <div class="ml-auto text-right d-flex align-items-center">
      <h5 class="mr-2">{{ currentTime }}</h5>
      <h5>{{ currentDate }}</h5>
    </div>
  </b-row>
</template>
<script>
import { mapState } from "vuex"
import { OPERATORE_ROUTE_NAMES } from "@/_utils/constants.js"

export default {
  name: "OperatoreLeftHeader",
  components: {
  },
  methods: {
    updateTime() {
    const now = new Date();
    this.currentTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    this.currentDate = now.toLocaleDateString(undefined, {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  }
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    welcomeName() {
      if (this.user && this.user.anagrafica) {
        return `${this.user.anagrafica.name} ${this.user.anagrafica.surname}`
      }
      return this.user.username
    },
    routeLabel() {
      try {
        const label = this.routeNames[this.$route.name]
        if (label === undefined) {
          throw "label not found"
        }
        return label
      } catch (err) {
        return `Benvenuto su ConCura`
      }
    }
  },
  data() {
    return {
      routeNames: OPERATORE_ROUTE_NAMES,
      currentTime: '',
      currentDate: ''
    }
  },
  mounted() {
    this.updateTime();
    setInterval(this.updateTime, 1000); // Aggiorna l'orario ogni secondo
  }
}
</script>