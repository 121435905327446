<template>
  <b-container fluid class="m-0 p-0">
    <h4 class="text-prim-grad-1 text-left pb-1">Indicazione</h4>

    <div class="my-2">
      <h6 v-if="therapy.pathology" class="my-4 pb-2">
        {{ therapy.pathology.name }} - patologia {{ therapy.pathology.tipo }}
      </h6>
      <p v-else class="my-4 pb-2">Patologia non indicata</p>
    </div>
    <h4 class="text-prim-grad-1 text-left pb-1">Posologia</h4>

    <div class="my-2">
      <p class="my-4 pb-2">
        {{ posNum }}
        {{
          posNum > 1 ? therapy.posology_type : typesMap[therapy.posology_type]
        }}
        di {{ therapy.product_name }} {{ therapy.posologia.tipo }}
        <span v-if="therapy.posologia.frequenza">
          {{ therapy.posology_every_num }} {{ therapy.posologia.frequenza }}
        </span>
        per via
        <span class="text-lowercase">{{ therapy.posology_via }}. </span> <br />
        <span v-if="assunzioni">Da assumere: {{ assunzioni }}.</span>
      </p>
    </div>

    <h4 class="text-prim-grad-1 text-left pb-1">Durata</h4>

    <div class="my-2">
      <p v-if="therapy.type === 2" class="my-4 pb-2">
        Da {{ formatData(therapy.start_at) }} fino a
        {{ formatData(therapy.end_at) }} .
      </p>
      <p v-else-if="therapy.type === 1" class="my-4 pb-2">
        La terapia va seguita ogni {{ therapy.every_num }}
        {{
          therapy.every_num > 1
            ? forEveryUmsPlur[therapy.every_um]
            : forEveryUmsSing[therapy.every_um]
        }}
        per
        {{ therapy.for_num }}
        {{
          therapy.for_num > 1
            ? forEveryUmsPlur[therapy.for_um]
            : forEveryUmsSing[therapy.for_um]
        }}
        a partire da {{ formatData(therapy.start_at) }}.
      </p>
      <p v-else class="my-4 pb-2">cronica</p>
    </div>

    <h4 class="text-prim-grad-1 text-left pb-1">Dettagli</h4>

    <div class="my-2">
      <p class="mt-4 pb-2">
        Prescrittore:
        {{ therapy.prescrittore ? therapy.prescrittore : "non indicato" }}
      </p>
      <p class="mb-4 pb-2">
        Fonte informativa:
        {{
          therapy.fonte_informativa ? therapy.fonte_informativa : "non indicata"
        }}
      </p>
      <p class="mb-4 pb-2">
        Effetti collaterali:
        {{
          therapy.effetti_collaterali
            ? therapy.effetti_collaterali
            : "non indicati"
        }}
      </p>
      <p class="mb-4 pb-2">
        Reazioni allergiche:
        {{
          therapy.reazione_allergica
            ? therapy.reazione_allergica
            : "non indicate"
        }}
      </p>
      <p class="mb-4 pb-2">
        Interazioni:
        {{ therapy.interazioni ? therapy.interazioni : "non indicate" }}
      </p>
    </div>
    <h4 class="text-prim-grad-1 text-left pb-1">Annotazioni</h4>

    <div class="pb-4">
      <p class="my-4 pb-2">
        {{ therapy.details ? therapy.details : "nessuna nota" }}
      </p>
    </div>
  </b-container>
</template>
<script>
import * as moment from "moment/moment"
export default {
  name: "RecapTherapy",
  props: ["therapy"],
  data() {
    return {
      typesMap: {
        Compresse: "compressa",
        Capsule: "capsula",
        Gocce: "goccia",
        Inalazioni: "inalazione",
        Buste: "busta",
        Supposte: "supposta",
        Scatole: "scatola",
        Cerotti: "cerotto",
        Fiale: "fiala",
        Fleboclisi: "fleboclisi",
        Ovuli: "ovulo",
        Sacche: "sacca",
        Flaconi: "flacone",
        Confezioni: "confezione",
        Kilogrammi: "kilogrammo",
        Kit: "kit",
        Millilitri: "millilitro",
        Milligrammi: "milligrammo",
        Applicazioni: "applicazione",
        UI: "UI"
      },
      forEveryUmsSing: {
        0: "mese",
        1: "settimana",
        2: "giorno"
      },
      forEveryUmsPlur: {
        0: "mesi",
        1: "settimane",
        2: "giorni"
      }
    }
  },
  computed: {
    posNum: function () {
      return this.therapy.posology_num * 1
    },
    assunzioni() {
      const assunzioniKeys = [
        "mattina",
        "meta_mattina",
        "pranzo",
        "pomeriggio",
        "sera",
        "notte"
      ]
      let assunzioniString = assunzioniKeys.reduce((acc, key) => {
        if (this.therapy[key]) {
          acc += key + ", "
        }
        return acc
      }, "")

      if (assunzioniString === ", ") return null

      assunzioniString = assunzioniString.replace(
        "meta_mattina",
        "metà mattina"
      )

      return assunzioniString.slice(0, -2) || null
    }
  },
  methods: {
    formatData(data) {
      return moment(data).format("dddd DD MMMM YYYY")
    },
    formatDataShort(data) {
      return moment(data).format("DD/MM/YYYY")
    }
  },
  created() {
    //
  }
}
</script>
