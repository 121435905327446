<template>
  <div>
    <b-iconstack
      font-scale="3"
      class="point m-1"
      v-b-popover.hover.top="'Modifica'"
      @click="showModal"
    >
      <b-icon stacked icon="circle-fill" variant="mdm-light-grey"></b-icon>
      <b-icon
        stacked
        icon="pencil-square"
        scale="0.5"
        variant="secondary"
      ></b-icon>
    </b-iconstack>

    <b-modal
      :id="modalId"
      title="Modifica voucher"
      centered
      hide-footer
      size="lg"
      @show="populateForm"
      @hidden="resetForm"
    >
      <section class="p-2 text-left col-12 text-left">
        <p>
          Modificare i dettagli del voucher. È possibile aggiornare la
          sottoscrizione, la percentuale di sconto e le date di validità.
        </p>
        <b-form @submit.prevent="submitForm" class="px-2">
          <b-form-group label="Sottoscrizione *">
            <b-form-select
              v-capitalize-first-select
              v-model="selectedSubscription"
              required
            >
              <b-form-select-option
                v-for="subscription in subscriptions"
                :value="subscription"
                :key="subscription.hashid"
                >{{ subscription.nome }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <b-form-group
            class="mt-4"
            label="Riduzione da applicare sul prezzo iniziale €: *"
          >
            <b-form-input
              v-model="form.riduzione"
              required
              type="number"
              :min="1"
              :max="selectedSubscription ? selectedSubscription.prezzo : 100"
              :step="0.01"
              :state="
                selectedSubscription
                  ? selectedSubscription.prezzo >= form.riduzione
                  : null
              "
            ></b-form-input>
          </b-form-group>
          <b-form-group class="mt-4" label="Data di inizio validità">
            <b-form-input
              v-model="form.valid_from"
              type="date"
              :min="minDate"
              :max="form.valid_to"
            ></b-form-input>
          </b-form-group>
          <b-form-group class="mt-4" label="Data di fine validità">
            <b-form-input
              v-model="form.valid_to"
              type="date"
              :min="form.valid_from || minDate"
            ></b-form-input>
          </b-form-group>
          <p class="text-muted mt-4">*parametri obbligatori</p>
          <div class="text-right mt-4 mb-0">
            <b-button
              type="button"
              variant="popup-button"
              class="mx-2"
              @click="hideModal"
              >Annulla</b-button
            >
            <b-button type="submit" variant="popup-button"
              >Aggiorna Voucher</b-button
            >
          </div>
        </b-form>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { adminService } from "@/_services";

export default {
  name: "VoucherEditForm",
  props: {
    voucher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        subscription_id: null,
        riduzione: 1,
        valid_from: null,
        valid_to: null,
      },
      subscriptions: [],
      selectedSubscription: null,
      modalId: `edit-voucher-${this.voucher.hashid}`,
      minDate: new Date().toISOString().split("T")[0],
    };
  },
  watch: {
    selectedSubscription: function (value) {
      if (value) {
        this.form.subscription_id = value.hashid;
      }
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    showModal() {
      this.$bvModal.show(this.modalId);
    },
    hideModal() {
      this.$bvModal.hide(this.modalId);
    },
    populateForm() {
      this.selectedSubscription = this.voucher.subscription;
      this.form.subscription_id = this.voucher.subscription.hashid;
      this.form.riduzione = this.voucher.riduzione;
      this.form.valid_from = this.voucher.valid_from;
      this.form.valid_to = this.voucher.valid_until;
      this.fetchSubscriptions();
    },
    submitForm() {
      const self = this;
      adminService
        .updateVoucher(this.voucher.hashid, this.form)
        .then(function (resp) {
          self.hideModal();
          self.successAlert("Voucher aggiornato correttamente");
          self.$emit("refresh");
        })
        .catch(function (err) {
          self.errorAlert("Non è stato possibile aggiornare il voucher");
        });
    },
    resetForm() {
      this.form.subscription_id = null;
      this.form.riduzione = 1;
      this.form.valid_from = null;
      this.form.valid_to = null;
    },
    fetchSubscriptions() {
      const self = this;
      adminService
        .getAvailableSubscriptions()
        .then(function (res) {
          self.subscriptions = res.data.data;
        })
        .catch(function (err) {
          self.errorAlert("Non è stato possibile recuperare le sottoscrizioni");
        });
    },
  },
};
</script>
