<template>
  <div>
    <p class="text-medium-grey text-justify">
      Inserisci almeno nome e servizio di riferimento per la nuova raccolta.
    </p>
    <b-form @submit.prevent="sendForm" class="text-left">
      <b-form-group id="name" label="Nome*:" label-for="name">
        <b-form-input
          id="name"
          v-model="form.name"
          v-capitalize-first
          type="text"
          placeholder="Nome raccolta.."
          class=" "
          required
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        id="description"
        label="Descrizione:"
        label-for="description"
      >
        <b-form-input
          v-capitalize-first
          id="description"
          v-model="form.description"
          type="text"
          placeholder="Descrizione raccolta.."
          class=" "
        ></b-form-input>
      </b-form-group>
      <b-form-group id="service" label="Servizio*:" label-for="description">
        <b-form-select
          v-capitalize-first-select
          name="service"
          class="text-capitalize"
          v-model="form.position_ref"
          required
        >
          <b-form-select-option :value="1">Prenotazioni</b-form-select-option>
          <b-form-select-option :value="2">Teleconsulto</b-form-select-option>
          <b-form-select-option :value="3">Screening</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <p class="text-medium-grey text-justify" v-if="form.position_ref == 1">
        I questionari per il servizio "Prenotazioni" possono essere associati
        alle prestazioni (tramite la pagina impostazioni->gestione
        servizi->prestazioni). Le prenotazioni di prestazioni associate a
        questionario proporranno all'utente che desidera prenotare il
        questionario associato come step obbligatorio per la conclusione della
        procedura.
      </p>
      <p
        class="text-medium-grey text-justify"
        v-else-if="form.position_ref == 2"
      >
        I questionari per il servizio "Teleconsulto", quando abilitati, vengono
        proposti ai pazienti che richiedono un teleconsulto: in fase di
        prenotazione per finalizzare la richiesta l'utente dovrà selezionare un
        questionario e compilarlo .
      </p>
      <p class="text-medium-grey text-justify" v-else>
        I questionari del servizio "Screening" sono pensati per le attività di
        screening: puoi somministrarli ai tuoi pazienti compilandoli di persona.
      </p>
      <b-form-group label="Attiva:">
        <b-form-checkbox
          id="enabled"
          v-model="form.enabled"
          name="enabled"
          :checked-value="true"
        >
          Raccolta abilitata
        </b-form-checkbox>
      </b-form-group>
      <div class="text-center">
        <b-button type="submit" variant="secondary" class=""
          >Crea raccolta</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
import { supportService } from "@/_services"
import { mapState, mapActions } from "vuex"
export default {
  name: "NuovaAttivita",
  props: ["position"],
  data() {
    return {
      form: {
        name: null,
        description: null,
        enabled: true,
        position_ref: 3
      }
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    })
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    sendForm(e) {
      e.preventDefault()
      var self = this
      supportService
        .storeActivity(this.form)
        .then(function (res) {
          // console.log(res);
          self.$emit("activity-created", true)
        })
        .catch(function (err) {
          // console.log(err);

          self.$emit("activity-created", false)
        })
    }
  },
  created() {
    if (this.position) {
      this.form.position_ref = this.position
    }
  }
}
</script>
