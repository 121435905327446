<template>
  <div>
    <b-form @submit.prevent="sendForm" class="text-left">
      <b-form-group id="name" label="Nome*:" label-for="name">
        <b-form-input
          id="name"
          v-model="form.name"
          v-capitalize-first
          type="text"
          placeholder="Nome attività.."
          class=" "
          required
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        id="description"
        label="Descrizione:"
        label-for="description"
      >
        <b-form-input
          id="description"
          v-model="form.description"
          v-capitalize-first
          type="text"
          placeholder="Descrizione attività.."
          class=" "
        ></b-form-input>
      </b-form-group>

      <b-form-group id="service" label="Servizio*:" label-for="description">
        <b-form-select
          v-capitalize-first-select
          name="service"
          class="text-capitalize"
          v-model="form.position_ref"
          required
        >
          <b-form-select-option :value="1">prestazioni</b-form-select-option>
          <b-form-select-option :value="2">teleconsulto</b-form-select-option>
          <b-form-select-option :value="3">screening</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
        label="Attiva:"
        description="*La nuova raccolta sarà disponibile solo per le aziende a cui deciderai di assegnarla."
      >
        <b-form-checkbox
          id="enabled"
          v-model="form.enabled"
          name="enabled"
          :checked-value="true"
        >
          Attività abilitata
        </b-form-checkbox>
      </b-form-group>
      <div class="text-center">
        <b-button type="submit" variant="popup-button" class="mt-3"
          >Crea attività</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
import { supportService } from "@/_services"
import { mapState, mapActions } from "vuex"
export default {
  name: "NuovaAttivita",
  props: ["position"],
  data() {
    return {
      form: {
        name: null,
        description: null,
        enabled: true,
        position_ref: 3
      }
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    })
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    sendForm(e) {
      e.preventDefault()
      var self = this
      supportService
        .storeActivity(this.form)
        .then(function (res) {
          // console.log(res);
          self.$emit("activity-created", true)
        })
        .catch(function (err) {
          // console.log(err);

          self.$emit("activity-created", false)
        })
    }
  },
  created() {
    if (this.position) {
      this.form.position_ref = this.position
    }
  }
}
</script>
