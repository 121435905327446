<template>
  <div class="">
    <h3 class="mt-2 pt-4 text-left">
      <b-icon
        icon="person"
        variant="secondary"
        font-scale="1.2"
        class="mr-1"
      ></b-icon>
      Profilo
      <b-icon
        class="ml-2 mb-1"
        v-if="canEditAnagrafica && !editIndexes[0]"
        font-scale="0.8"
        :size="$root.isMobile ? 'sm' : ''"
        type="submit"
        variant="secondary"
        @click="setEditIndex(0, true)"
        icon="pencil-fill"
      />
    </h3>

    <b-form @submit.prevent="onSubmit">
      <section
        class="puntofarmaCard border general_border border-white p-2 p-md-3 p-lg-4 mt-1"
      >
        <div
          class="d-flex flex-column flex-md-row h-100 align-items-md-stretch"
        >
          <div
            class="d-flex flex-md-column justify-content-md-between text-center col-12 col-md-3 col-xl-3 mb-4 mb-md-0 p-3 p-md-0"
          >
            <b-avatar
              variant="white"
              :src="avatarPath"
              rounded
              :size="$root.isMobile ? '5rem' : '10rem'"
              class="mx-md-auto"
            >
            </b-avatar>
          </div>
          <b-row cols="1" cols-md="2" cols-xl="3">
            <b-col>
              <b-row>
                <b-form-group
                  label="Titolo"
                  label-for="title"
                  class="text-left col-3 col-xl-2 p-0 pr-1"
                >
                  <b-form-input
                    v-if="!editIndexes[0]"
                    class="my-3 text-capitalize"
                    name="title"
                    type="text"
                    placeholder="Sig..."
                    v-model="anagrafica.title"
                    :readonly="!editIndexes[0]"
                  ></b-form-input>
                  <b-form-select
                    v-capitalize-first-select
                    v-else
                    class="mb-3 mt-2 custom-select text-capitalize"
                    name="title"
                    v-model="anagrafica.title"
                    :options="titleOptions"
                  >
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Nome*"
                  label-for="name"
                  class="text-left col p-0"
                >
                  <b-form-input
                    class="my-3 text-capitalize"
                    name="name"
                    type="text"
                    placeholder="Nome..."
                    v-model="anagrafica.name"
                    :readonly="!editIndexes[0]"
                  ></b-form-input>
                </b-form-group>
              </b-row>
            </b-col>

            <b-col>
              <b-form-group
                label="Cognome*"
                label-for="surname"
                class="text-left"
              >
                <b-form-input
                  class="my-3 text-capitalize"
                  name="surname"
                  type="text"
                  placeholder="Cognome..."
                  v-model="anagrafica.surname"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Data di nascita*"
                label-for="birthday"
                class="text-left"
              >
                <b-form-input
                  class="my-3"
                  name="birthday"
                  type="date"
                  v-model="anagrafica.birthday"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Genere"
                label-for="gender"
                class="text-left d-flex flex-column justify-content-around"
              >
                <b-form-radio-group
                  v-model="anagrafica.gender"
                  name="gender-options"
                  class="d-flex mt-2"
                >
                  <b-form-radio value="maschile">uomo</b-form-radio>
                  <b-form-radio value="femminile">donna</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Codice fiscale*"
                label-for="cf"
                class="text-left"
              >
                <b-form-input
                  class="my-3 text-uppercase"
                  name="cf"
                  type="text"
                  placeholder="Codice fiscale..."
                  maxlength="16"
                  minlength="16"
                  v-model="anagrafica.cf"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Numero Tessera Sanitaria"
                label-for="ts_number"
                class="text-left"
              >
                <b-form-input
                  class="my-3"
                  name="ts_number"
                  type="text"
                  placeholder="Numero tessera..."
                  v-model="anagrafica.ts_number"
                  maxlength="20"
                  minlength="20"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Nazione di nascita*"
                label-for="nazione_nascita"
                class="text-left"
              >
                <b-form-input
                  v-if="!editIndexes[0]"
                  class="my-3 text-capitalize"
                  name="nazione_nascita"
                  type="text"
                  placeholder="..."
                  v-model="anagrafica.nazione_nascita"
                  readonly
                ></b-form-input>
                <b-form-select
                  v-capitalize-first-select
                  v-else
                  class="mb-3 mt-2 text-capitalize"
                  :options="nazioniOptions"
                  name="nazione_nascita"
                  v-model="anagrafica.nazione_nascita"
                  required
                >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Medico di base"
                label-for="medico_base"
                class="text-left"
              >
                <b-form-input
                  v-capitalize-first
                  class="my-3"
                  name="ts_number"
                  type="text"
                  placeholder="Medico di base..."
                  v-model="anagrafica.medico_base"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                v-if="editIndexes[0]"
                label="Asl/Asp/Ats di riferimento"
                label-for="denominazione_asl"
                class="text-left"
              >
                <b-form-select
                  v-capitalize-first-select
                  class="mb-3 mt-2 custom-select text-capitalize"
                  name="denominazione_asl"
                  v-model="anagrafica.denominazione_asl"
                  :disabled="!editIndexes[0]"
                >
                  <b-form-select-option
                    v-for="aslOption in asl"
                    :key="aslOption"
                    :value="aslOption"
                    >{{ aslOption }}</b-form-select-option
                  >
                  <b-form-select-option :value="null"
                    >Scegli azienda sanitaria locale</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
              <b-form-group
                v-else
                label="Asl/Asp/Ats di riferimento"
                label-for="denominazione_asl"
                class="text-left"
              >
                <b-form-input
                  readonly
                  placeholder="Asl/Asp/Ats di riferimento..."
                  class="my-3 text-capitalize"
                  name="denominazione_asl"
                  v-model="anagrafica.denominazione_asl"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <p class="mb-0 w-100 text-right pt-3" v-if="editIndexes[0]">
          <b-button
            type="button"
            variant="outline-secondary mx-2"
            @click.prevent="handleAnnulla()"
            >Annulla</b-button
          >
          <b-button
            type="submit"
            variant="secondary mx-2"
            :disabled="!enableBtn"
            >Salva Modifiche
          </b-button>
        </p>
      </section>

      <b-row>
        <b-col class="px-0 col-12 col-lg-6 col-xl-8">
          <div class="mr-lgx-2">
            <h3 class="mt-2 pt-4 text-left">
              <b-icon
                icon="geo"
                variant="secondary"
                font-scale="1.2"
                class="mr-1"
              ></b-icon>
              Residenza
              <b-icon
                class="ml-2 mb-1"
                v-if="canEditAnagrafica && !editIndexes[1]"
                font-scale="0.8"
                :size="$root.isMobile ? 'sm' : ''"
                type="submit"
                variant="secondary"
                @click="setEditIndex(1, true)"
                icon="pencil-fill"
              />
            </h3>
            <section
              class="bg-white general_border puntofarmaCard p-2 p-md-3 p-lg-4 mt-2"
            >
              <b-row cols="1" cols-md="2" cols-xl="3">
                <template v-if="!editIndexes[1]">
                  <b-col>
                    <b-form-group
                      label="Regione*"
                      label-for="regione"
                      class="text-left"
                    >
                      <b-form-input
                        class="my-3 text-capitalize"
                        name="regione"
                        type="text"
                        placeholder="Regione..."
                        v-model="anagrafica.regione"
                        readonly
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Provincia*"
                      label-for="provincia"
                      class="text-left"
                    >
                      <b-form-input
                        class="my-3 text-capitalize"
                        name="provincia"
                        type="text"
                        placeholder="Provincia..."
                        v-model="anagrafica.provincia"
                        readonly
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Comune*"
                      label-for="comune"
                      class="text-left"
                    >
                      <b-form-input
                        class="my-3 text-capitalize"
                        name="comune"
                        type="text"
                        placeholder="Comune..."
                        v-model="anagrafica.comune"
                        readonly
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </template>
                <template v-else>
                  <b-col>
                    <b-form-group
                      label="Regione*"
                      label-for="regione"
                      class="text-left"
                    >
                      <b-form-select
                        v-capitalize-first-select
                        class="my-3 custom-select text-capitalize"
                        :options="regioni"
                        name="regione"
                        v-model="selected.regione"
                        @change="getProvince(selected)"
                        :disabled="!editIndexes[1]"
                      >
                        <b-form-select-option :value="null"
                          >Seleziona Regione</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Provincia*"
                      label-for="provincia"
                      class="text-left"
                    >
                      <b-form-select
                        v-capitalize-first-select
                        class="my-3 text-capitalize"
                        :options="province"
                        name="provincia"
                        v-model="selected.provincia"
                        @change="getComuni(selected)"
                        :disabled="!editIndexes[1]"
                      >
                        <b-form-select-option :value="null"
                          >Seleziona Provincia</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Comune*"
                      label-for="comune"
                      class="text-left"
                    >
                      <b-form-select
                        v-capitalize-first-select
                        class="my-3 text-capitalize"
                        name="comune"
                        v-model="selected.comune"
                        :key="
                          comuni && comuni.length
                            ? comuni[0].comune + comuni.length
                            : null
                        "
                        :disabled="!editIndexes[1]"
                      >
                        <b-form-select-option
                          v-for="(com, ind) in comuni"
                          :key="ind"
                          :value="com"
                          @change="setDomicilioData"
                          :selected="findComune(com)"
                          >{{ com.comune }}</b-form-select-option
                        >
                        <b-form-select-option :value="null"
                          >Seleziona Comune</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </template>
                <b-col>
                  <b-form-group
                    label="Indirizzo e numero civico*"
                    label-for="address"
                    class="text-left"
                  >
                    <b-form-input
                      class="my-3 text-capitalize"
                      name="address"
                      type="text"
                      placeholder="Indirizzo..."
                      v-model="anagrafica.address"
                      :readonly="!editIndexes[1]"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="CAP*" label-for="cap" class="text-left">
                    <b-form-input
                      class="my-3"
                      name="cap"
                      type="text"
                      placeholder="CAP..."
                      v-model="anagrafica.cap"
                      minlength="5"
                      maxlength="5"
                      :readonly="!editIndexes[1]"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <p class="mb-0 w-100 text-right pt-3" v-if="editIndexes[1]">
                <b-button
                  type="button"
                  variant="outline-secondary mx-2"
                  @click.prevent="handleAnnulla()"
                  >Annulla</b-button
                >
                <b-button
                  type="submit"
                  variant="secondary mx-2"
                  :disabled="!enableBtn"
                  >Salva Modifiche
                </b-button>
              </p>
            </section>
          </div>
        </b-col>
        <b-col class="px-0 col-12 col-lg-6 col-xl-4">
          <div class="ml-lgx-2">
            <h3 class="mt-2 pt-4 text-left">
              <b-icon
                icon="telephone"
                variant="secondary"
                font-scale="1.2"
                class="mr-1"
              ></b-icon>
              Contatti
              <b-icon
                class="ml-2 mb-1"
                v-if="canEditAnagrafica && !editIndexes[2]"
                font-scale="0.8"
                :size="$root.isMobile ? 'sm' : ''"
                type="submit"
                variant="secondary"
                @click="setEditIndex(2, true)"
                icon="pencil-fill"
              />
            </h3>
            <section
              class="puntofarmaCard p-2 p-md-3 p-lg-4 mt-2 general_border"
            >
              <b-row cols="1" cols-md="2">
                <b-col>
                  <b-form-group
                    label="Numero di telefono"
                    label-for="tel_number"
                    class="text-left"
                  >
                    <b-form-input
                      class="my-3"
                      name="tel_number"
                      type="text"
                      placeholder="Telefono..."
                      v-model="anagrafica.tel_number"
                      minlength="9"
                      :readonly="!editIndexes[2]"
                      :state="editIndexes[2] ? validNumber : null"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Telefono Domicilio"
                    label-for="home_number"
                    class="text-left"
                  >
                    <b-form-input
                      class="my-3"
                      name="tel_number"
                      type="text"
                      placeholder="Telefono domicilio..."
                      v-model="anagrafica.home_number"
                      minlength="9"
                      :readonly="!editIndexes[2]"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Telefono di Riserva"
                    label-for="tel_number_secondary"
                    class="text-left"
                  >
                    <b-form-input
                      class="my-3"
                      name="tel_number_secondary"
                      type="text"
                      placeholder="Telefono di riserva ..."
                      v-model="anagrafica.tel_number_secondary"
                      minlength="9"
                      :readonly="!editIndexes[2]"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col v-if="this.user.email">
                  <b-form-group
                    label="Email"
                    label-for="email"
                    class="text-left"
                  >
                    <b-form-input
                      class="my-3"
                      name="email"
                      type="email"
                      placeholder="Email ..."
                      v-model="user.email"
                      minlength="9"
                      :readonly="!editIndexes[2]"
                      :state="!editIndexes[2] ? null : validEmail"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <p class="mb-0 w-100 text-right pt-3" v-if="editIndexes[2]">
                <b-button
                  type="button"
                  variant="outline-secondary  mx-2"
                  @click.prevent="handleAnnulla()"
                  >Annulla</b-button
                >
                <b-button
                  type="submit"
                  variant="secondary  mx-2"
                  :disabled="!enableBtn"
                  >Salva Modifiche
                </b-button>
              </p>
            </section>
          </div>
        </b-col>
        <b-col class="px-0 col-12">
          <ContattiEmergenza :user="user"></ContattiEmergenza>
        </b-col>
      </b-row>
    </b-form>
    <b-modal
      id="delete-propic-confirm"
      size="lg"
      centered
      title="Conferma eliminazione"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="popup-button mr-2"
      ok-title="Sì, Conferma"
      ok-variant="popup-button"
      footer-class="border-0"
      @ok="deleteUserPropic()"
      @cancel="$bvModal.hide('delete-propic-confirm')"
    >
      Confermi di voler eliminare l'immagine del profilo?
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue"
import * as moment from "moment/moment"
import UploadComponent from "@/components/utilities/UploadComponent.vue"
import ContattiEmergenza from "@/components/profile/contatti/ContattiEmergenza.vue"
import { mapState, mapActions } from "vuex"
import { supportService } from "@/_services"
import { utilityService } from "@/_services"

export default {
  name: "AnagraficaPazienteComponent",
  props: ["user"],
  computed: {
    canEditAnagrafica() {
      if (this.user && this.user.is_my_patient) {
        if (this.user.email_verified_at || this.user.number_verified_at) {
          return false
        }
        return this.user.all_consenses === false
      }
      if (this.user && this.user.created_by_me) {
        if (this.user.email_verified_at || this.user.number_verified_at) {
          return false
        }
        return this.user.all_consenses === false
      }
      return false
    },
    validEmail() {
      if (this.user) {
        var validRegex =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        return this.user.email.match(validRegex) ? true : false
      }
      return null
    },
    validNumber() {
      if (this.anagrafica.tel_number) {
        const prefix = this.anagrafica.tel_number.slice(0, 2)
        const hasPrefix =
          prefix === "00" || this.anagrafica.tel_number.indexOf("+") >= 0
        return !isNaN(this.anagrafica.tel_number) && !hasPrefix
      }
      return false
    },
    nazioniOptions() {
      if (this.nazioni && this.nazioni.length > 0) {
        return [
          { value: null, text: "Seleziona Nazione" },
          { value: "italia", text: "Italia" }
        ].concat(
          this.nazioni.map((nazione) => {
            return {
              value: nazione.nazione,
              text: nazione.nazione
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")
            }
          })
        )
      } else
        return [
          {
            value: null,
            text: "Seleziona Nazione"
          }
        ]
    },
    ...mapState("utility", [
      "searching",
      "comuni",
      "province",
      "regioni",
      "asl"
    ])
  },
  components: {
    UploadComponent,
    ContattiEmergenza
  },
  data() {
    return {
      editIndexes: [false, false, false],
      avatarPath: null,
      selected: {
        regione: null,
        provincia: null,
        comune: null
      },
      anagrafica: {
        regione: null,
        provincia: null,
        comune: null,
        cap: null,
        address: null,
        name: null,
        surname: null,
        cf: null,
        gender: null,
        birthday: null,
        ts_number: null,
        tel_number: null,
        home_number: null,
        tel_number_secondary: null,
        medico_base: null,
        denominazione_asl: null,
        title: null
      },
      titleOptions: ["Sig.", "Sig.ra", "Dott.", "Dott.ssa"],
      enableBtn: false,
      isPost: true,
      selectingImage: false
    }
  },
  methods: {
    ...mapActions("utility", [
      "getComuni",
      "getProvince",
      "getRegioni",
      "getAsl"
    ]),
    ...mapActions("utente", ["setAnagrafica", "me"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    setEditIndex(index, value) {
      console.log("index", index)
      this.editIndexes.forEach((el, i) => {
        Vue.set(this.editIndexes, i, false)
      })
      Vue.set(this.editIndexes, index, value)
    },
    setDomicilioData() {
      // console.log("on change", this.selected.comune);
      if (this.selected.comune) {
        this.anagrafica.comune = this.selected.comune.comune
        this.anagrafica.provincia = this.selected.comune.provincia
        this.anagrafica.regione = this.selected.comune.regione
      }
    },
    onSubmit() {
      if (this.anagrafica.tel_number && !this.validNumber) {
        this.errorAlert("Numero di telefono non valido")
        return false
      }
      const self = this
      var formData = { anagrafica: this.anagrafica }
      const fisCode = this.anagrafica.cf
      if (this.anagrafica.cf === this.user.anagrafica.cf) {
        // formData.anagrafica.cf = undefined;
        delete formData.anagrafica.cf
      }
      supportService
        .updateAssistitoAnagrafica(this.user.hashid, formData)
        .then((res) => {
          self.successAlert("Modifiche effettuate correttamente")
          self.$emit("edit-done", res.data.data)
          self.editIndexes = [false, false, false]
          self.anagrafica.cf = fisCode
        })
        .catch((error) => {
          console.log(error)
          var mesg = "Non è stato possibile completare l'operazione"
          if (error.status == 422) {
            mesg = ""
            Object.keys(error.data.errors).map(function (el) {
              mesg += error.data.errors[el]
            })
          } else if (error.status == 400) {
            mesg = error.data.message
          }
          self.errorAlert(mesg)
        })
    },
    setupPrevs() {
      if (this.user.anagrafica.regione) {
        this.selected.regione = this.user.anagrafica.regione
        this.getProvince(this.selected)
      }
      if (this.user.anagrafica.provincia) {
        this.selected.provincia = this.user.anagrafica.provincia
        this.getComuni(this.selected)
      }
    },
    findComune(comune) {
      if (this.user.anagrafica && this.user.anagrafica.comune) {
        if (comune.comune === this.user.anagrafica.comune) {
          this.selected.comune = comune
          return true
        }
      }
      return false
    },
    handleAnnulla() {
      this.editIndexes.forEach((el, index) => {
        Vue.set(this.editIndexes, index, false)
      })
      this.$emit("edit-done")
    },
    getNazioni() {
      var self = this
      utilityService
        .nazioni()
        .then((res) => {
          self.nazioni = res.data
          // console.log(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  watch: {
    anagrafica: {
      handler(prev, post) {
        this.enableBtn = true
        // console.log(prev);
      },
      deep: true
    },
    selected: {
      handler(val) {
        if (val.comune !== null) {
          this.anagrafica.comune = this.selected.comune.comune
          this.anagrafica.provincia = this.selected.comune.provincia
          this.anagrafica.regione = this.selected.comune.regione
        }
      },
      deep: true
    }
  },
  created() {
    this.getRegioni()
    this.getNazioni()
    this.getAsl({
      denominazione: true,
      regione: this.user.anagrafica.regione
    })
    if (this.user.anagrafica !== null) {
      if (this.user.profile_pic) {
        this.avatarPath = this.user.profile_pic
      }
      this.isPost = false
      this.editIndexes = [false, false, false]
      this.anagrafica = this.user.anagrafica
      this.enableBtn = false
      this.setupPrevs()
    }
  }
  // mounted() {
  //   if (this.user.anagrafica !== null) {
  //     this.setupPrevs();
  //   }
  // },
}
</script>
<style lang="scss" scoped>
input.form-control:read-only {
  border-bottom: 1px solid darkgrey;
  border-radius: 0px !important;
  box-shadow: none !important;
  color: $body-color;
}
</style>
