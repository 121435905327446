<template>
  <div>
    <b-button
      :size="small ? 'sm' : undefined"
      variant="outline-secondary"
      type="button"
      @click="startConversation"
      >{{ canStart ? "Chatta" : "Vedi conversazione" }}
      <UnreadChatBadge
        :hashid="chatChannel"
        :updateCounter="messagesUpdated"
        class="d-inline"
      />
    </b-button>
    <b-modal
      :id="'chat_' + chatChannel"
      size="xl"
      centered
      title="Conversazione"
      @close="stopConversation"
      :no-close-on-backdrop="canStart"
      hide-footer
    >
      <b-overlay :show="loading" class="chatbox__main">
        <b-row v-if="conversationArray.length === 0">...</b-row>
        <b-row cols="1" class="">
          <b-col
            class="d-flex flex-column-reverse w-100 px-3"
            :id="'chatbox_' + chatChannel"
          >
            <b-row v-for="(mess, ind) in conversationArray" :key="ind">
              <p
                v-if="mess.sender === clientName"
                class="my-1 text-right w-100 bg-light-primary px-2 py-1 general_border"
              >
                <strong class="text-uppercase">{{ mess.sender }}</strong>
                <br />
                <span class="text-lowercase">{{ mess.msg }}</span>
                <span
                  v-if="mess.attachment"
                  class="text-lowercase text-secondary font-weight-bold point"
                  @click="downloadResource(mess.attachment)"
                >
                  <b-icon icon="paperclip"></b-icon
                  >{{ mess.attachment.name }}</span
                >
              </p>
              <p
                v-else
                class="my-1 text-left w-100 px-2 py-1 general_border"
                :class="
                  mess.read === false ? 'bg-light-green' : 'bg-light-panel'
                "
              >
                <strong class="text-uppercase">{{ mess.sender }}</strong>
                <b-icon
                  icon="circle-fill"
                  :variant="isOtherOnline ? 'success' : 'danger'"
                ></b-icon
                ><br />
                <span class="text-lowercase">{{ mess.msg }}</span>
                <span
                  v-if="mess.attachment"
                  class="text-lowercase text-secondary font-weight-bold point"
                  @click="downloadResource(mess.attachment)"
                >
                  <b-icon icon="paperclip"></b-icon
                  >{{ mess.attachment.name }}</span
                >
              </p>
            </b-row>
          </b-col>
          <b-col
            v-if="canStart"
            class="align-self-end general_border mt-2 px-0"
            :class="showUploader ? 'bg-mdm-light-grey ' : ''"
          >
            <b-form @submit.prevent="sendMessage">
              <b-row align-v="center" class="justify-content-between">
                <Transition name="list" mode="out-in" appear>
                  <b-col
                    cols="8"
                    md="9"
                    lg="10"
                    v-if="!showUploader"
                    class="my-2"
                  >
                    <b-form-input
                      v-capitalize-first
                      type="text"
                      v-model="textMessage"
                      placeholder=". . ."
                      required
                    ></b-form-input>
                  </b-col>
                  <b-col cols="8" md="9" lg="10" class="ml-3" v-else>
                    <!-- <p class="mb-0 fs-5">Formati ammessi: pdf,zip</p> -->
                    <RefertoUploader
                      dest="reservation"
                      accepts=".pdf,.zip"
                      @upload-result="handleUploadResult($event)"
                    ></RefertoUploader>
                  </b-col>
                </Transition>
                <b-col class="text-right px-0">
                  <b-button-group>
                    <b-button
                      type="button"
                      variant="outline-info text-dark"
                      size="sm"
                      @click="showUploader = !showUploader"
                    >
                      <b-icon v-if="!showUploader" icon="paperclip"></b-icon>
                      <b-icon v-else icon="textarea-t"></b-icon>
                    </b-button>
                    <b-button
                      type="button"
                      variant="secondary"
                      class="custom__border_radius"
                      @click="sendMessage"
                    >
                      <b-icon icon="arrow-return-left"></b-icon>
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
              <b-row> </b-row>
            </b-form>
          </b-col>
        </b-row>
      </b-overlay>
      <!-- <b-spinner v-else></b-spinner> -->
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex"
import { reservationService } from "@/_services"
import RefertoUploader from "@/components/utilities/RefertoUploader.vue"
import UnreadChatBadge from "@/components/prenotazioni/dettaglio/UnreadChatBadge.vue"
import moment from "moment"
export default {
  name: "ChatComponent",
  props: ["chatChannel", "small", "recipient", "expirationDate", "stillActive"],
  components: { RefertoUploader, UnreadChatBadge },
  data() {
    return {
      canStart: false,
      loading: true,
      fm: null,
      client: null,
      clientName: null,
      clientId: null,
      token: null,
      textMessage: null,
      conversationArray: [],
      mediaRecorder: null,
      isOtherOnline: false,
      form: {
        recipient_user_id: null,
        recipient_company_id: null,
        message: null,
        read: false,
        attachment_id: null
      },
      previousMessages: null,
      messagesToUpdate: [],
      showUploader: false,
      messagesUpdated: 0
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["user"])
  },
  watch: {
    previousMessages: function (val) {
      if (val && val.length > 0) {
        val.forEach((element) => {
          this.conversationArray.push(element)
          if (!element.read && element.sender !== this.clientName) {
            this.messagesToUpdate.push(element.hashid)
          }
        })
      }
    },
    messagesToUpdate: function (val) {
      if (val && val.length > 0) {
        const self = this
        setTimeout(self.markAsRead(), 3000)
      }
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    sendMessage() {
      if (this.channel) {
        this.channel.sendMessage(this.textMessage)
        this.storeMessage(this.textMessage)
        this.textMessage = null
      }
    },
    start() {
      var fm = window.fm
      var gateway = process.env.VUE_APP_LIVEWITCH_GATEWAY
      var secret = process.env.VUE_APP_LIVEWITCH_SECRET
      var applicationId = process.env.VUE_APP_LIVEWITCH_APP_ID
      var clientId = this.clientId
      var channelId = this.chatChannel
      var client = null
      var token = null
      // var userId = fm.liveswitch.Guid.newGuid().toString().replace(/-/g, "");
      var userId = this.clientId
      var deviceId = this.clientName
      client = new fm.liveswitch.Client(
        gateway,
        applicationId,
        userId,
        deviceId,
        clientId
      )
      this.client = client
      token = fm.liveswitch.Token.generateClientRegisterToken(
        applicationId,
        client.getUserId(),
        client.getDeviceId(),
        client.getId(),
        null,
        [new fm.liveswitch.ChannelClaim(channelId)],
        secret
      )
      var self = this
      client
        .register(token)
        .then(function (channels) {
          var joinToken = fm.liveswitch.Token.generateClientJoinToken(
            applicationId,
            client.getUserId(),
            client.getDeviceId(),
            client.getId(),
            new fm.liveswitch.ChannelClaim(channelId),
            secret
          )

          // self.client
          client
            .join(channelId, joinToken)
            .then(function (channel) {
              self.channel = channel
              var myself = self
              const totalCurrentConnections = channel.getRemoteClientInfos()
              if (
                totalCurrentConnections &&
                totalCurrentConnections.length > 1
              ) {
                const otherPartecipant =
                  totalCurrentConnections[totalCurrentConnections.length - 1]
                var newElem = {
                  msg: "...connesso.",
                  sender: otherPartecipant._deviceId
                }
                myself.conversationArray.unshift(newElem)
                myself.isOtherOnline = true
                myself.form.read = true
                myself.form.recipient_user_id =
                  totalCurrentConnections[0]._userId
              } else if (totalCurrentConnections.length === 1) {
                const otherPartecipant = totalCurrentConnections[0]
                if (otherPartecipant._deviceId !== myself.clientId) {
                  myself.isOtherOnline = true
                  myself.form.read = true
                  myself.form.recipient_user_id = otherPartecipant._userId
                }
              }
              channel.addOnMessage((senderClientInfo, message) => {
                if (message !== "3F843C41D91DACD5BBB006322CCD9DEB") {
                  var newElem = {
                    msg: message,
                    sender: senderClientInfo._deviceId
                  }
                  myself.conversationArray.unshift(newElem)
                  var chatbox = document.getElementById(
                    "chatbox_" + myself.chatChannel
                  )
                  if (chatbox) {
                    chatbox.scrollTop = chatbox.scrollHeight + 50
                  }
                } else {
                  myself.getAllMessages()
                }
              })
              channel.sendMessage("...connesso.")
              self.loading = false
              channel.addOnRemoteClientJoin((senderClientInfo) => {
                if (senderClientInfo._userId !== myself.clientId) {
                  myself.isOtherOnline = true
                  myself.form.read = true
                  myself.form.recipient_user_id = senderClientInfo._userId
                }
              })
              channel.addOnRemoteClientLeave((senderClientInfo) => {
                if (senderClientInfo._userId !== myself.clientId) {
                  myself.isOtherOnline = false
                  myself.form.read = false
                }
              })
            })
            .fail(function (ex) {
              console.log("failed to join channel", ex)
              self.errorAlert("Non è stato possibile collegarsi")
            })
        })
        .fail(function (ex) {
          console.log("registration failed", ex)
          self.errorAlert("Non è stato possibile collegarsi")
        })
    },
    stopConversation() {
      this.previousMessages = null
      this.conversationArray = []
      const self = this
      if (this.client) {
        this.client
          .leave(this.chatChannel)
          .then(function (channel) {
            console.log("left the channel")
          })
          .fail(function (ex) {
            console.log("failed to leave the channel")
          })

        this.client
          .closeAll()
          .then(function (result) {
            console.log("all connections closed")
          })
          .fail(function (ex) {
            console.log("failed to close all connections")
          })
        this.client
          .unregister()
          .then(function (result) {
            console.log("unregistration succeeded")
          })
          .fail(function (ex) {
            console.log("unregistration failed")
          })
      }
    },
    startConversation() {
      this.getAllMessages()
      if (this.canStart) {
        this.start()
      }
      this.$bvModal.show("chat_" + this.chatChannel)
    },
    storeMessage(text) {
      this.form.message = text
      var self = this
      const doUpdate = this.form.attachment_id !== null
      reservationService
        .storeReservationMessage(this.form, this.chatChannel)
        .then(function (response) {
          self.form.attachment_id = null
          if (doUpdate) {
            self.loading = true
            self.conversationArray = []
            self.getAllMessages()
          }
        })
        .catch(function (error) {
          self.errorAlert("non è stato possibile inoltrare il messaggio")
          // console.log("error fetch prenotazioni", error);
          // self.loading = false;
        })
      //
    },
    markAsRead() {
      const formData = { messages: this.messagesToUpdate }
      const self = this
      reservationService
        .markMessagesAsRead(formData, this.chatChannel)
        .then(function (response) {
          self.messagesUpdated = self.messagesToUpdate.length
          self.messagesToUpdate = []
          self.getAllMessages()
          // self.$emit("read-messages");
        })
        .catch(function (error) {
          self.errorAlert("non è stato possibile aggiornare la conversazione")
          // console.log("error fetch prenotazioni", error);
          // self.loading = false;
        })
    },
    getAllMessages() {
      const self = this
      this.previousMessages = null
      this.conversationArray = []
      reservationService
        .getReservationMessage(this.chatChannel)
        .then(function (response) {
          self.previousMessages = response.data.data
          self.loading = false
        })
        .catch(function (error) {
          self.errorAlert(
            "non è stato possibile recuperare i messaggi precedenti"
          )
        })
    },
    checkExpirationDate() {
      if (this.expirationDate) {
        var endDate = moment(this.expirationDate)
        var end = moment(endDate).add(1, "hours")
        var now = moment()
        this.canStart = now.isBefore(end)
      }
    },
    handleUploadResult(upload) {
      this.form.attachment_id = upload.hashid
      this.channel.sendMessage("3F843C41D91DACD5BBB006322CCD9DEB")
      this.storeMessage(this.textMessage)
      this.textMessage = null
      this.showUploader = false
    },
    downloadResource(resource) {
      const fName = resource.name
      var self = this
      reservationService
        .downloadResource(resource.hashid)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName
          link.click()
        })
        .catch(function (err) {
          console.log(err)
          self.errorAlert("Non è stato possibile scaricare la risorsa")
        })
    }
  },
  mounted() {
    this.canStart = this.stillActive
    if (this.stillActive) {
      this.checkExpirationDate()
    }
    this.fm = window.fm
    this.clientName =
      this.user.anagrafica.name + " " + this.user.anagrafica.surname
    this.clientId = this.user.hashid
    if (this.recipient.type === "user") {
      this.form.recipient_user_id = this.recipient.hashid
    } else if (this.recipient.type === "company") {
      this.form.recipient_company_id = this.recipient.hashid
    }
  },
  beforeDestroy() {
    this.stopConversation()
    // console.log("beforeDestroy");
    this.$emit("read-messages")
  }
}
</script>

<style>
#chatbox {
  width: 100%;
  height: 8vh;
  overflow: auto;
}

#call__btns {
  position: absolute;
  top: 50vh;
}

.fm-video > video {
  position: initial !important;
}

.custom__border_radius {
  border-radius: 3px !important;
}

.no__shadow {
  box-shadow: none !important;
}
</style>
