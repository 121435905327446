<template>
  <div class="text-left pb-4">
    <h3
      class="text-secondary text-left py-0 py-lg-4 mb-4 mb-lg-1 mt-5 font-weight-bolder"
    >
      Sottoscrizioni
    </h3>
    <section class="mb-4">
      <b-col class="mb-2 ml-n3 px-0 px-md-3" :sm="12" :md="10" :lg="3" :xl="3">
      </b-col>

      <template v-if="availableSubscriptions">
        <b-row align-v="stretch" class="flex-wrap">
          <b-col
            class="mb-2 ml-n3 px-0 px-md-3"
            :sm="12"
            :md="10"
            :lg="3"
            :xl="3"
          >
            <template v-if="currentSubscription">
              <b-card
                :title="currentSubscription.abbonamento.nome"
                :sub-title="`Durata minima: 1 ${frequenzaRinnovo(
                  currentSubscription.abbonamento
                )}`"
                tag="article"
                class="mb-2 puntofarmaCard bg-secondary"
                text-variant="white fw-bolder"
                :class="{ 'h-100': allCollapsesAreClosed }"
                body-class="d-flex flex-column pb-0"
                sub-title-text-variant="white fw-bolder"
              >
                <b-row class="justify-content-end price_negative_m">
                  <div class="text-right">
                    <h1 class="price_huge_font mt-5 mb-0">
                      {{ currentSubscription.abbonamento.prezzo }}€
                      <span class="fs-5" style="font-size: 0.9rem !important"
                        >/
                        {{ frequenzaRinnovo(currentSubscription.abbonamento) }}
                      </span>
                    </h1>
                  </div>
                </b-row>
                <!--<b-row class="mb-2">
                  <template v-for="(
                      residuo, reservationType
                    ) in currentSubscription.residui">
                     <span v-if="residuo.free > 0">
                      <span class="text-capitalize"
                        >{{ reservationType }}
                      </span>
                      <span v-if="residuo.free - residuo.used <= 0"
                        >gratuite disponibili: 0/{{ residuo.free }}</span
                      >
                      <span v-else
                        >gratuite disponibili:
                        {{ residuo.free - residuo.used }}/{{
                          residuo.free
                        }}</span
                      >
                    </span> 
                  </template>
</b-row>-->
                <div
                  class="mb-0 fs-5 d-flex align-items-center justify-content-between"
                >
                  <div class="flex-grow-1">
                    <p
                      class="mb-0"
                      v-if="
                        currentSubscription.data_prossimo_pagamento &&
                        currentSubscription.rinnovo_abilitato &&
                        !currentSubscription.data_fine
                      "
                    >
                      Prossimo rinnovo:
                      {{
                        currentSubscription.data_prossimo_pagamento
                          | moment("DD/MM/YYYY")
                      }}
                    </p>
                    <p
                      v-else-if="currentSubscription.data_fine"
                      class="text-danger mb-0"
                    >
                      Scadrà il
                      {{ currentSubscription.data_fine | moment("DD/MM/YYYY") }}
                    </p>
                    <p
                      class="mb-0 text-danger"
                      v-else-if="!currentSubscription.rinnovo_abilitato"
                    >
                      <!-- <span v-if="currentSubscription.data_prossimo_pagamento">
                        La sottoscrizione scadrà il
                        {{
                          currentSubscription.data_prossimo_pagamento
                            | moment("DD/MM/YYYY")
                        }}
                      </span>
                      <span v-else> Il rinnovo è stato disattivato </span> -->
                    </p>
                  </div>
                </div>

                <!--  <div class="mb-2">
                  <template>
                    <p class="m-0">
                      Prenotazione veloce per visite e prestazioni: 10€
                    </p>
                    <p class="m-0">
                      Servizio di Televisita o Teleconsulto: 10€
                    </p>
                    <p class="m-0">Prenotazione farmaci: 4€</p>
                  </template> 
                </div> -->

                <b-row
                  class="justify-content-between align-items-center pt-0 mb-0"
                >
                  <!-- <b-button v-b-toggle="'collapse-current-free'"
                    class="d-flex align-items-center justify-content-center pl-0" 
                    variant="link text-white" 
                    size="sm"
                    @click="currentCollapse = !currentCollapse"> -->
                  <span
                    class="fs-5 mr-2 mt-3"
                    style="text-decoration: underline; cursor: pointer"
                    @click="showInfoCurrentSubscription"
                    >Leggi di più</span
                  >

                  <!-- <span v-else class="fs-5 mr-2">Nascondi</span> -->
                  <!-- <b-icon
                      icon="chevron-down"
                      v-if="!currentCollapse"
                    ></b-icon>
                    <b-icon icon="chevron-up" v-else></b-icon> -->
                  <!-- </b-button> -->
                  <!-- <b-button size="lg" variant="primary"
                    @click="$router.push({ name: 'sottoscrizioni paziente' })">Upgrade</b-button> -->
                  <span>
                    <h4 class="mt-4">
                      Attiva
                      <b-icon
                        icon="check-circle-fill"
                        variant="success"
                      ></b-icon>
                    </h4>
                  </span>
                </b-row>
                <b-collapse
                  :id="'collapse-current-free'"
                  class="mt-2 fw-bolder"
                >
                  <b-card-text
                    >Sottoscrizione ConCura Free per i pazienti</b-card-text
                  >
                </b-collapse>
              </b-card>
            </template>

            <template v-else>
              <b-card
                :title="ConCuraFree.title"
                sub-title=""
                tag="article"
                class="mb-2 puntofarmaCard bg-secondary fw-bolder"
                text-variant="white  fw-bolder"
                :class="{ 'h-100': allCollapsesAreClosed }"
                body-class="d-flex flex-column pb-0 text-white  fw-bolder"
              >
                <b-row class="justify-content-end price_negative_m">
                  <div class="text-right">
                    <h1 class="price_huge_font mt-5 mb-4">GRATIS</h1>
                  </div>
                </b-row>
                <!--  <div class="mb-2">
                  <template>
                    <p class="m-0">
                      Prenotazione veloce per visite e prestazioni: 10€
                    </p>
                    <p class="m-0">
                      Servizio di Televisita o Teleconsulto: 10€
                    </p>
                    <p class="m-0">Prenotazione farmaci: 4€</p>
                  </template>
</div> -->

                <b-row
                  class="justify-content-between align-items-center pt-0 mb-0"
                >
                  <!-- <b-button v-b-toggle="'collapse-current-free'"
                    class="d-flex align-items-center justify-content-center pl-0" variant="link text-white" size="sm"
                    @click="currentCollapse = !currentCollapse"> -->
                  <span
                    @click="showInfoFreeSubscription"
                    class="fs-5 mr-2"
                    style="text-decoration: underline; cursor: pointer"
                    >Leggi di più</span
                  >
                  <!-- <b-icon
                      icon="chevron-down"
                      v-if="!currentCollapse"
                    ></b-icon>
                    <b-icon icon="chevron-up" v-else></b-icon> -->
                  <!-- </b-button> -->
                  <!-- <b-button size="lg" variant="primary"
                    @click="$router.push({ name: 'sottoscrizioni paziente' })">Upgrade</b-button> -->
                  <span>
                    <h4 class="mt-4">
                      Attiva
                      <b-icon
                        icon="check-circle-fill"
                        variant="primary"
                      ></b-icon>
                    </h4>
                  </span>
                </b-row>
                <b-collapse
                  :id="'collapse-current-free'"
                  class="mt-2 fw-bolder"
                >
                  <b-card-text></b-card-text>
                </b-collapse>
              </b-card>
            </template>
          </b-col>
          <b-col
            v-for="(subscription, index) in availableSubscriptions"
            :key="index"
            class="mb-2 ml-n3"
            :sm="12"
            :md="8"
            :lg="3"
            :xl="3"
          >
            <b-card
              :title="subscription.nome"
              :sub-title="`Durata minima: 1 ${frequenzaRinnovo(subscription)}`"
              tag="article"
              :class="{ 'h-100': allCollapsesAreClosed }"
              class="mb-2 justify-content-between puntofarmaCard"
              text-variant="secondary"
              body-class="d-flex flex-column pb-0 "
            >
              <b-row class="justify-content-end price_negative_m">
                <div class="text-right">
                  <h1 class="price_huge_font mt-5 mb-0">
                    {{ subscription.prezzo }}€
                    <span class="fs-5" style="font-size: 0.9rem !important"
                      >/
                      {{ frequenzaRinnovo(subscription) }}
                    </span>
                  </h1>
                </div>
              </b-row>
              <b-row>
                <b-col class="col-7 pl-0">
                  <!-- <strong
                    class="text-black"
                    v-if="subscription.enable_monitoring"
                    >Sottoscrizione consentita solo tramite il tuo
                    medico</strong> -->
                </b-col>
              </b-row>

              <b-row
                class="justify-content-between pt-0 mb-0 mt-0"
                align-v="end"
              >
                <b-col class="col-4 px-0">
                  <!-- <b-button v-b-toggle="'collapse-' + index" size="sm" variant="link" class="pl-0"
                    @click="toggleCollapse(index)"> -->
                  <span
                    class="fs-5 mr-2"
                    style="text-decoration: underline; cursor: pointer"
                    @click="showInfoAvailableSubscriptions(index)"
                    >Leggi di più</span
                  >
                  <!-- <span v-else class="fs-5 mr-2">Nascondi</span> -->
                  <!-- <b-icon
                      :icon="collapses[index] ? 'chevron-up' : 'chevron-down'"
                    ></b-icon> -->
                  <!-- </b-button> -->
                </b-col>
                <b-col class="px-0 text-right">
                  <template>
                    <b-button
                      v-if="subscription.enable_monitoring"
                      variant="outline-secondary"
                      size="lg"
                      @click="$bvModal.show('callsupport')"
                    >
                      Contattaci
                    </b-button>
                    <b-button
                      v-else
                      variant="primary"
                      size="lg"
                      @click="initializePurchase(subscription)"
                    >
                      Acquista
                    </b-button>
                  </template>
                  <!-- 
                    Se invece c'è una sottoscrizione attiva o pending, mostro il pulsante per completare l'acquisto se è in pending
                    altrimenti mostro il prezzo e la frequenza di rinnovo
                 -->
                  <template
                    v-if="
                      !!pendingSubscription &&
                      pendingSubscription.slug === subscription.slug
                    "
                  >
                    <span class="d-flex align-items-center mt-2">
                      <b-button
                        variant="secondary"
                        class="mr-1"
                        @click="
                          $router.push({
                            name: 'sottoscrizioni paziente',
                            params: { section: 'purchase' },
                            query: {
                              hashid: pendingHash,
                              type: 'sottoscrizione-paziente'
                            }
                          })
                        "
                      >
                        Completa l'acquisto
                      </b-button>
                      <b-iconstack
                        title="Elimina"
                        font-scale="3"
                        class="point mx-1"
                        @click="$bvModal.show('delete-sub-confirm')"
                      >
                        <b-icon
                          stacked
                          icon="circle-fill"
                          variant="mdm-light-grey"
                        ></b-icon>
                        <b-icon
                          stacked
                          icon="trash-fill"
                          scale="0.5"
                          variant="secondary"
                        ></b-icon>
                      </b-iconstack>
                    </span>
                  </template>
                </b-col>
              </b-row>
              <b-collapse :id="'collapse-' + index" class="mt-2">
                <b-card-text class="dext-dark pt-3">
                  {{ subscription.descrizione }}</b-card-text
                >
                <b-card-text
                  class="dext-dark"
                  v-if="subscription.dettaglio"
                  v-html="subscription.dettaglio"
                ></b-card-text>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </template>
    </section>

    <h3
      class="text-secondary text-left py-0 pt-lg-1 pb-lg-4 mb-4 mb-lg-1 font-weight-bolder"
    >
      Convenzioni
    </h3>

    <section class="mb-4">
      <b-row align-v="stretch" class="flex-wrap">
        <b-col
          v-for="(convenzione, index) in convenzioni"
          :key="index"
          class="mb-2 ml-n3"
          :sm="12"
          :md="8"
          :lg="3"
          :xl="3"
        >
          <b-card
            :title="convenzione.name"
            :sub-title="convenzione.description"
            tag="article"
            :class="{ 'h-100': allCollapsesAreClosed }"
            class="mb-2 justify-content-between puntofarmaCard"
            text-variant="secondary"
            body-class="d-flex flex-column pb-0"
          >
            <!-- <p>Voucher</p> -->
            <b-row class="justify-content-end">
              <div
                class="text-right col-12 col lg-8 col-md-8 col-sm-12 col-xs-12"
              >
                <b-img
                  class="img-fluid"
                  height="70px"
                  alt="logo"
                  center
                  v-if="convenzione.logo"
                  :src="convenzione.logo"
                  :style="{ width: '100%' }"
                ></b-img>
              </div>
            </b-row>

            <b-row
              class="justify-content-between pt-0 mb-2 mt-auto"
              align-v="end"
            >
              <b-col class="col-4 px-0">
                <span
                  class="fs-5 mr-2"
                  style="text-decoration: underline; cursor: pointer"
                  @click="$bvModal.show('callsupport')"
                  >Leggi di più</span
                >
              </b-col>
              <b-col class="px-0 text-right mt-2">
                <b-button
                  variant="primary"
                  size="lg"
                  @click="$bvModal.show('callsupport')"
                >
                  Richiedi il voucher
                </b-button>
              </b-col>
            </b-row>
            <b-collapse :id="'collapse-conv-' + index" class="mt-2">
              <b-card-text class="dext-dark pt-3"> info</b-card-text>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <b-modal
      id="delete-sub-confirm"
      centered
      :title="`Confermi di voler annullare la sottoscrizione ${pendingSubscription?.nome}?`"
      ok-title="Sì, cancella"
      ok-variant="popup-button"
      cancel-title="No, annulla"
      cancel-variant="popup-button"
      footer-border-variant="white"
      @ok="deleteSubscription"
      @cancel="$bvModal.hide('logout-confirm')"
    >
    </b-modal>

    <b-modal
      id="subs-info"
      size="xl"
      title="Piani ConCura"
      centered
      hide-footer
      scrollable
    >
      <div
        v-if="innerWidth >= 1600"
        class="d-flex align-items-center justify-content-center"
      >
        <img
          src="@/assets/Centro-Servizi-ConCura-4.svg"
          alt="Piani ConCura"
          style="width: 100%"
        />
      </div>
      <div v-else class="d-flex align-items-center justify-content-center">
        <img
          v-if="selectedSubscription === 'con-cura-easy-24'"
          src="@/assets/ConCura-Easy.svg"
          alt="ConCura Easy"
        />
        <img
          v-if="selectedSubscription === 'con-cura-smart-24'"
          src="@/assets/ConCura-Smart.svg"
          alt="ConCura Smart"
        />
        <img
          v-if="selectedSubscription === 'con-cura-monitor-24'"
          src="@/assets/ConCura-Monitoraggio.svg"
          alt="ConCura Monitor"
        />
        <img
          v-if="selectedSubscription === 'con-cura-pazienti-12'"
          src="@/assets/ConCura-Free.svg"
          alt="ConCura Free"
        />
        <!-- <img v-else src="@/assets/ConCura-Free.svg" alt="ConCura Free"> -->
      </div>
      <!-- <div>{{ currentSubscription }}</div> -->
    </b-modal>
  </div>
</template>

<script>
import { cittadinoService } from "@/_services"
import { mapActions } from "vuex"
import moment from "moment"

export default {
  name: "RecapSottoscrizioniPaziente",
  data() {
    return {
      currentSubscription: null,
      pendingSubscription: null,
      availableSubscriptions: null,
      collapses: {},
      currentCollapse: false,
      allCollapsesAreClosed: true,
      innerWidth: window.innerWidth,
      selectedSubscription: null,
      convenzioni: [
        {
          name: "ConCura & UnoBravo",
          description: "Voucher",
          logo: require(`@/assets/unobravo_logo.svg`)
        },
        {
          name: "ConCura & Parentsmile",
          description: "Voucher",
          logo: require(`@/assets/parentsmile_logo.jpg`)
        }
      ],
      ConCuraFree: {
        title: "ConCura FREE",
        slug: "con-cura-pazienti-12"
      }
    }
  },
  methods: {
    toggleCollapse(index) {
      this.$set(this.collapses, index, !this.collapses[index])

      //if all collapses are closed and i am opening one the allCollapsesAreClosed should be updated instantly
      //instead if i am closing one, i should wait for the transition to end
      if (this.collapses[index]) {
        this.allCollapsesAreClosed = false
      } else {
        setTimeout(() => {
          this.allCollapsesAreClosed = !Object.values(this.collapses).some(
            (v) => v
          )
        }, 1000)
      }
      this.$forceUpdate()
    },
    frequenzaRinnovo(subscription) {
      return subscription.mesi_frequenza_rinnovo === 1 ? "mese" : "anno"
    },
    getUserSubscriptions() {
      const self = this
      const filters = { service_ref: 8 }
      cittadinoService
        .getOwnSubscriptions(filters, this.$route.query.hashid)
        .then(function (res) {
          const subscriptions = res.data.data
          self.currentSubscription = subscriptions.attivo
          // const slug = subscriptions.lettura.map(el => el.slug)
          // console.log(slug)

          self.pendingSubscription = subscriptions?.pending?.abbonamento

          self.pendingHash = subscriptions.pending?.hashid ?? null

          if (!!self.currentSubscription || !!self.pendingSubscription) {
            self.availableSubscriptions = subscriptions.lettura.filter((s) => {
              return s.slug !== self.currentSubscription?.abbonamento?.slug
            })
          } else {
            self.availableSubscriptions = subscriptions.disponibile
            if (
              !self.availableSubscriptions.some(
                (s) => s.enable_monitoring === 1
              )
            ) {
              self.availableSubscriptions.push(
                subscriptions.lettura.find((s) => s.enable_monitoring === 1)
              )
            }
          }

          self.collapses = {}
          self.availableSubscriptions.forEach((_, index) => {
            self.collapses[index] = false
          })
        })

        .catch(function (err) {
          console.error(err)
          self.errorAlert("Non è stato possibile completare l'operazione.")
        })
    },
    initializePurchase(subscription) {
      const self = this
      cittadinoService
        .storeOwnSubscription(subscription.hashid)
        .then(function (res) {
          self.$router.push({
            name: "sottoscrizioni paziente",
            params: { section: "purchase" },
            query: {
              hashid: res.data.data.hashid,
              type: "sottoscrizione-paziente"
            }
          })
        })
        .catch(function (err) {
          console.error(err)
          self.errorAlert("Non è stato possibile completare l'operazione.")
        })
    },
    deleteSubscription() {
      const self = this
      cittadinoService
        .deleteOwnSubscription(this.pendingHash)
        .then(function (res) {
          self.successAlert("Sottoscrizione eliminata con successo.")
          self.getUserSubscriptions()
          self.$bvModal.hide("delete-sub-confirm")
        })
        .catch(function (err) {
          console.error(err)
          self.errorAlert("Non è stato possibile completare l'operazione.")
        })
    },
    changeSubscriptionRenewalStatus(status) {
      const self = this
      cittadinoService
        .changeSubscriptionRenewalStatus(
          this.currentSubscription.hashid,
          status
        )
        .then(function (res) {
          self.successAlert(
            `Rinnovo sottoscrizione ${
              status ? "attivato" : "disattivato"
            } con successo.`
          )
          self.getUserSubscriptions()
        })
        .catch(function (err) {
          console.error(err)
          self.errorAlert("Non è stato possibile completare l'operazione.")
        })
    },
    changeSubscriptionDataFine(data) {
      const form = data
        ? {
            data_fine: data
          }
        : { reset_data: true }
      const self = this
      cittadinoService
        .changeSubscriptionDataFine(this.currentSubscription.hashid, form)
        .then(function (res) {
          self.successAlert(`Data fine sottoscrizione modificata con successo.`)
          self.getUserSubscriptions()
        })
        .catch(function (err) {
          console.error(err)
          self.errorAlert("Non è stato possibile completare l'operazione.")
        })
    },
    showInfoAvailableSubscriptions(index) {
      if (index >= 0 && index < this.availableSubscriptions.length) {
        this.selectedIndex = index
        const clickedSub = this.availableSubscriptions[index]
        this.selectedSubscription = clickedSub.slug
        console.log(this.selectedSubscription)
        this.$bvModal.show("subs-info")
      }
    },
    showInfoCurrentSubscription() {
      this.selectedSubscription = this.currentSubscription.abbonamento.slug
      this.$bvModal.show("subs-info")
    },
    showInfoFreeSubscription() {
      this.selectedSubscription = this.ConCuraFree.slug
      console.log(this.selectedSubscription)
      this.$bvModal.show("subs-info")
    },
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    })
  },
  created() {
    this.getUserSubscriptions()
  }
}
</script>

<style scoped>
@media (max-width: 768px) {
  .price_huge_font,
  .fs-5 {
    /* display: block; */
    text-align: right;
    margin-top: 1rem;
  }
}
</style>
