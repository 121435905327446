<template>
  <div v-if="user.type === 1">
    <template v-if="isActive === null">
      <p class="text-center"><b-spinner></b-spinner></p>
    </template>
    <template v-else-if="isActive === true">
      <transition-group name="list" mode="out-in" appear>
        <div v-if="$route.params.section === 'prenotazioni'" key="prenotazioni">
          <HomeTeleconsulto />
        </div>

        <div v-else-if="$route.params.section === 'videocall'" key="videocall">
          <PreConsulto />
        </div>
      </transition-group>
    </template>
    <template v-else>
      <h4>Servizio non abilitato</h4>
     <h6> <a href="https://concura.online/contatti/"> <u> Contattaci</u></a> per saperne di più o per abilitare il servizio</h6>
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";
import HomeTeleconsulto from "@/components/teleconsulto/sections/HomeTeleconsulto.vue";
import PreConsulto from "@/components/teleconsulto/sections/PreConsulto.vue";
import { companyService } from "@/_services";
export default {
  name: "TeleconsultoMain",
  components: {
    HomeTeleconsulto,
    PreConsulto,
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      isActive: null,
    };
  },
  mounted() {
    this.verifyService();
    var lib = document.createElement("script");
    lib.setAttribute("id", "ls_lib");
    lib.setAttribute("type", "text/javascript");
    lib.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/fm.liveswitch@latest/fm.liveswitch.js"
    );
    document.head.appendChild(lib);
  },
  beforeDestroy() {
    var lib = document.getElementById("ls_lib");
    document.head.removeChild(lib);
  },
  methods: {
    verifyService() {
      const self = this;
      const filter = { ref: 2 };
      companyService.checkIfServiceIsActive(filter).then(function (response) {
        self.isActive = response.data.data.active;
      }).catch(function (error) {
        console.log(error)
      });;
    },
  },
};
</script>
