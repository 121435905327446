var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-4"},[(_vm.user.type === 1)?_c('div',[_c('div',{staticClass:"mx-auto"},[_c('transition-group',{attrs:{"name":"list","mode":"out-in","appear":""}},[(
            _vm.$route.params.section === 'servizi' &&
            _vm.user.permessi.includes('gestire impostazioni dei servizi')
          )?_c('div',{key:"servizi",staticClass:"px-3 px-xl-4"},[_c('ServiziCompany')],1):(
            _vm.$route.params.section === 'anagrafica' &&
            _vm.user.permessi.includes('visualizzare anagrafica azienda')
          )?_c('div',{key:"anagrafica",staticClass:"px-3 px-xl-4"},[_c('AnagraficaCompany')],1):(
            _vm.$route.params.section === 'operatori' &&
            _vm.user.permessi.includes('gestire operatori')
          )?_c('div',{key:"operatori",staticClass:"px-3 px-xl-4"},[_c('OperatorsList',{on:{"total-count":function($event){return _vm.handleOperatorsCount($event)}}}),(_vm.operatorCount !== null)?[(
                _vm.company.operators_limit === 0 ||
                (_vm.company.operators_limit > 0 &&
                  _vm.operatorCount < _vm.company.operators_limit)
              )?[_c('h4',{staticClass:"text-left pb-1"},[_vm._v("Codice Azienda")]),_c('p',{staticClass:"text-justify"},[_vm._v(" Per abilitare una nuova utenza alla gestione dei tuoi servizi ConCura puoi inviare un invito via email contenente il codice azienda necessario per la sua registrazione. Il codice azienda andrà inserito dall'operatore che intende registrarsi autononamente nel portale in qualità di operatore nella tua Azienda. Se invece preferisci inviare il codice via email inserisci l'indirizzo del destinatario nell'apposito campo. ")]),_c('ShowCode')]:_vm._e()]:_vm._e()],2):(_vm.$route.params.section === 'sottoscrizioni')?_c('div',{key:"sottoscrizioni",staticClass:"mx-auto px-3 px-xl-4"},[_c('Sottoscrizioni')],1):_c('div',{key:"nonexistingornotauthorized"},[_c('p',[_vm._v(" Pagina non esistente o non si ha il permesso di accedere a questa sezione. ")])])])],1)]):_c('div',[_c('h5',[_vm._v("Nessuna impostazione per questa utenza")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }