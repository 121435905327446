<template>
  <b-container fluid class="px-0">
    <b-container
      fluid
      class="px-0 d-flex justify-content-between align-items-center mb-3"
    >
      <h5 class="text-left mb-0">Esami, parametri e referti</h5>
      <CreateEditDeleteParametroForm action="create" @refresh="fetchData" />
    </b-container>

    <b-row align-v="center" class="my-4">
      <b-col class="text-left px-0">
        <span class="mr-1" v-if="total > 0"
          >{{ fromPage }}-{{ toPage }} di
        </span>
        <span class=""> {{ total }}</span>
      </b-col>
      <b-col class="text-left px-0 col-6 col-md-4">
        <b-form-group
          label=""
          label-for="takes"
          class="text-left text-secondary mx-auto"
        >
          <b-form-select
            :options="takeOptions"
            class=" "
            name="takes"
            v-model="take"
            required
          >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table
      class="puntofarmaCard p-4 text-center general_border_17"
      borderless
      hover
      responsive
      :items="items"
      :fields="fields"
      :busy="loading"
      foot-clone
    >
      <template #cell(actions)="row">
        <CreateEditDeleteParametroForm
          :item="row.item"
          action="editDelete"
          @refresh="fetchData"
        />
      </template>

      <template #table-busy>
        <div class="text-center my-3">
          <b-spinner
            small
            variant="secondary"
            type="grow"
            label="Aggiornamento in corso"
          ></b-spinner>
          <span class="ml-2">Aggiornamento in corso</span>
        </div>
      </template>
    </b-table>

    <b-pagination
      class="customPagination general_border mt-2"
      v-model="page"
      :total-rows="total"
      :per-page="per_page"
      align="center"
      @change="paginationChanged"
      :size="$root.isMobile ? 'sm' : 'lg'"
    >
    </b-pagination>
  </b-container>
</template>

<script>
import CreateEditDeleteParametroForm from "@/components/admin/CreateEditDeleteParametroForm.vue"
import { adminService } from "@/_services"
import { mapActions } from "vuex"

export default {
  name: "ImpostazioniSistemaEsami",
  components: {
    CreateEditDeleteParametroForm
  },
  watch: {
    take: {
      handler(oldValue, newValue) {
        if (oldValue !== newValue) {
          this.page = 1
        }
        this.fetchData()
      }
    },
    page: "fetchData"
  },
  data() {
    return {
      fields: [
        {
          key: "type",
          label: "Tipo",
          sortable: true,
          thStyle: { width: "30%" }
        },
        {
          key: "name",
          label: "Nome",
          sortable: true,
          thStyle: { width: "40%" }
        },
        { key: "um", label: "Unità di Misura", thStyle: { width: "15%" } },
        { key: "actions", label: "", thStyle: { width: "15%" } }
      ],
      page: 1,
      take: 10,
      fromPage: 0,
      toPage: 0,
      per_page: 10,
      total: 0,
      items: [],
      loading: true,
      takeOptions: [
        { text: "mostra 10 elementi", value: 10 },
        { text: "mostra 25 elementi", value: 25 },
        { text: "mostra 50 elementi", value: 50 },
        { text: "mostra 100 elementi", value: 100 }
      ]
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      var self = this
      self.loading = true
      const filter = {
        page: this.page,
        take: this.take,
        per_page: this.per_page,
        total: this.total,
        paginate: true
      }
      adminService
        .getAllExams(filter)
        .then(function (response) {
          self.total = response.data.data.total
          self.fromPage = response.data.data.from
          self.per_page = response.data.data.per_page
          self.toPage = response.data.data.to
          self.items = response.data.data.data.map(function (item) {
            var type = ""
            if (!item.instrumental && !item.has_attachment) {
              type = "Parametro"
            } else if (item.instrumental && !item.has_attachment) {
              type = "Esame"
            } else if (item.instrumental && item.has_attachment) {
              type = "Referto"
            }
            return { ...item, type }
          })
          self.loading = false
        })
        .catch(function () {
          self.loading = false
          self.errorAlert("Errore durante il caricamento dei dati")
        })
    },
    paginationChanged() {}
  },
  mounted() {
    this.fetchData()
  }
}
</script>

<style scoped></style>
