<template>
  <b-container fluid class="p-0 m-0">
    <div class="my-3">
      <h4 class="text-prim-grad-1 text-left mt-4 py-2 pb-3">Terapia</h4>
      <b-row
        align-v="stretch"
        class="ml-n3 pl-1 my-2 col-12 px-0 align-items-center align-content-center flex-wrap my-2"
      >
        <b-col
          class="col-12 col-md-4 py-2"
          v-for="(ter, ind) in therapies.active"
          :key="ind"
          :class="
            selectedTherapy && selectedTherapy.hashid === ter.hashid
              ? 'bg-secondary'
              : ''
          "
        >
          <div
            class="text-left py-2 px-3 puntofarmaCard col-12 h-100 d-flex flex-column point"
            @click="selectTherapy(ter, false)"
          >
            <h6 class="mb-0 pt-1 font-weight-bolder text-uppercase long__txt">
              {{ ter.product_name }}
            </h6>
            <p
              class="mb-0 mt-auto pt-1 font-weight-bolder d-flex justify-items-between text-font-grey"
            >
              <span
                v-if="ter.pathology"
                class="mr-auto text-uppercase font-weight-normal"
                >{{ ter.pathology.name }}</span
              >
              <span v-else class="mr-auto text-white">-</span>
              <span class="ml-auto point">
                <b-iconstack
                  @click="selectTherapy(ter, true)"
                  font-scale="2.2"
                  title="Vedi"
                  class="point"
                >
                  <b-icon
                    stacked
                    icon="circle-fill"
                    variant="mdm-light-grey"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="zoom-in"
                    scale="0.5"
                    variant="secondary"
                  ></b-icon>
                </b-iconstack>
              </span>
            </p>
          </div>
        </b-col>

        <b-col class="text-left col-md-3 align-content-center d-flex">
          <b-button class="text-left my-auto" @click="goToNew">
            <b-icon icon="plus"></b-icon> nuova
          </b-button>
        </b-col>
      </b-row>
    </div>
    <b-modal
      centered
      size="xl"
      id="edit-therapy"
      hide-footer
      :title="modalTitle"
    >
      <div v-if="selectedTherapy">
        <div>
          <RecapTherapy :therapy="selectedTherapy" />
        </div>
        <h6 class="mb-0 mt-1 text-capitalize">
          inserita il {{ formatData(selectedTherapy.created_at) }}
        </h6>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import { mapState, mapActions } from "vuex"
import { cittadinoService } from "@/_services"
import * as moment from "moment/moment"
import RecapTherapy from "@/components/profile/sanitario/RecapTherapy.vue"
export default {
  name: "SelectTherapy",
  props: ["subject"],
  components: { RecapTherapy },
  data() {
    return {
      therapies: { active: [], ended: [] },
      typesColors: ["ter-4", "ter-1", "ter-2"],
      selectedTherapy: null,
      editing: false,
      isSuspending: false,
      preselected: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    modalTitle: function () {
      if (this.selectedTherapy && this.selectedTherapy.pathology) {
        return "Terapia per " + this.selectedTherapy.pathology.name
      }
      return "Terapia"
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    selectTherapy(ter, showDetail) {
      this.selectedTherapy = ter
      if (showDetail) {
        this.$bvModal.show("edit-therapy")
      }
      this.$emit("selected-therapy", ter)
    },
    fetchTherapies() {
      if (this.subject) {
        return this.fetchUserTherapies()
      }
      // this.fetchOwnTherapies();
    },
    // fetchOwnTherapies() {
    //   var self = this;
    //   cittadinoService
    //     .getTherapies()
    //     .then(function (response) {
    //       self.therapies = response.data.data;
    //     })
    //     .catch(function (error) {
    //       self.errorAlert("Non è stato possibile recuperare le terapie");
    //     });
    // },
    fetchUserTherapies() {
      var self = this
      var filters = {
        user: this.subject.hashid
      }
      cittadinoService
        .getTherapies(filters)
        .then(function (response) {
          self.therapies = response.data.data
          // if (
          //   self.preselected &&
          //   self.preselected < response.data.data.active.length
          // ) {
          //   self.selectTherapy(
          //     response.data.data.active[self.preselected],
          //     false
          if (self.$route.query.therapyhash) {
            let index = response.data.data.active.findIndex(
              (therapy) => therapy.hashid == self.$route.query.therapyhash
            )

            if (index >= 0)
              self.selectTherapy(response.data.data.active[index], false)
          }
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le terapie")
        })
    },
    goToNew() {
      var destination = {
        name: "nuova terapia"
      }
      if (this.subject) {
        destination = {
          name: "nuova terapia Paziente",
          query: {
            sub: this.subject.hashid,
            type: "user_in_app"
            // type: this.subject.type,
          }
        }
      }
      this.$router.push(destination)
    },
    formatData(data) {
      return moment(data).format("DD/MM/YYYY")
    },
    inProgress() {
      this.$bvModal.hide("edit-therapy")
      this.infoAlert("Funzionalità in sviluppo")
    }
  },
  created() {
    // console.log("Here");
    this.fetchTherapies()
    if (this.$route.query.tax != undefined) {
      this.preselected = parseInt(this.$route.query.tax)
    }
  },
  mounted() {
    // this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
    //   this.selectedTherapy = null;
    //   this.editing = false;
    // });
  }
}
</script>
