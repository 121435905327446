<template>
  <b-form-group>
    <b-form-radio-group
      class="d-flex flex-column"
      id="searchTypeOptions"
      :value="value"
      @input="$emit('input', $event)"
      name="search-options"
    >
      <b-form-radio :value="false" class="mb-3" 
        >Ricerca per visita o prestazione</b-form-radio
      >
      <b-form-radio :value="true">Ricerca per centro erogatore</b-form-radio>
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: {
      default: undefined
    },
    resType: {
      type: String,
    }
  }
}
</script>
