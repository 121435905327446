<template>
  <b-container class="mb-4" fluid>
    <b-form @submit.prevent="saveTherapyUpdates">
      <!-- <b-row cols="1" class="my-2">
        <h6
          class="
            text-prim-grad-1
            
            text-left
            pb-1
            border-bottom border-line-grey
          "
        >
          Patologia per cui è stata indicata la terapia
        </h6>
        <div class="text-left">
          <b-form-radio-group
            v-model="hasPathology"
            name="pat-options"
            class="d-flex my-3 font-weight-bolder"
          >
            <b-form-radio :value="1">indica patologia</b-form-radio>
            <b-form-radio :value="0">non indicare patologia</b-form-radio>
          </b-form-radio-group>

          <b-row
            v-if="hasPathology === 1"
            class="
              my-2
              col-12
              px-0
              align-items-center align-content-center
              flex-wrap
              my-2
            "
          >
            <PatologieComponent
              :subject="subject"
              v-on:selected-pathology="selectPathology($event)"
            />
          </b-row>
        </div>
      </b-row> -->
      <b-row cols="1">
        <h5 class="text-prim-grad-1 text-left pb-2">Farmaco indicato</h5>
        <div class="text-left mb-4">
          <b-row cols="1" class="puntofarmaCard general_border py-4 px-2 mx-0">
            <b-col>
              <b-form-group label="Cerca farmaco" class="col-12 col-md-10 pl-0">
                <b-form-input
                  class=" "
                  v-capitalize-first
                  type="search"
                  name="pharmasearch"
                  placeholder="Cerca farmaco per nome.."
                  v-model="prodSearch.q"
                  v-debounce:500ms="searchProds"
                  :debounce-events="'keyup'"
                >
                </b-form-input>
              </b-form-group>
              <b-spinner
                v-show="isSearching"
                class="ml-4"
                variant="primary"
                label="Loading..."
              ></b-spinner>
            </b-col>

            <b-col v-if="products">
              <b-form-group
                label="Seleziona farmaco*"
                class="col-12 col-md-10 pl-0 mt-2"
              >
                <b-form-select
                  v-capitalize-first-select
                  v-model="selectedProduct"
                  class=""
                >
                  <b-form-select-option
                    v-for="(prod, ind) in products"
                    :key="ind"
                    :value="prod"
                    >{{ prod.Nome }}</b-form-select-option
                  >
                  <b-form-select-option :value="null" disabled
                    >---</b-form-select-option
                  >
                </b-form-select>
              </b-form-group></b-col
            >
            <b-col v-if="showNotFound">
              <p class="text-danger font-weight-bolder">Prodotto non trovato</p>
            </b-col>
          </b-row>
        </div>
      </b-row>
      <b-row cols="1" class="my-2" v-if="selectedProduct">
        <h5 class="text-prim-grad-1 text-left pb-1">
          Frequenza, modalità e dosaggio somministrazione
        </h5>
        <div class="text-left">
          <div class="mt-2 puntofarmaCard p-2">
            <b-col class="d-flex align-items-center px-2">
              <h6
                v-if="selectedProduct.Nome"
                class="text-dark-grey d-flex align-items-center border general_border border-whitesmoke text-uppercase px-1 ml-2 py-2 bg-whitesmoke"
                style="width: 100%"
              >
                <span class="pl-2">
                  {{ selectedProduct.Nome }}
                </span>
              </h6>
            </b-col>

            <b-row class="align-items-end px-1 my-2">
              <b-col class="col-6 col-md-3 col-lg-2 align-items-end ml-2">
                <b-form-group class="" label="Dosaggio*">
                  <b-form-input
                    class=" "
                    type="number"
                    step="0.25"
                    min="0.25"
                    v-model="form.posology_num"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="ml-1">
                <b-form-group label="" class="">
                  <b-form-select
                    v-capitalize-first-select
                    id="posology-type-select"
                    v-model="form.posology_type"
                    class=" "
                  >
                    <b-form-select-option
                      v-for="type in posologyTypes"
                      :key="type"
                      :value="type"
                      >{{ type }}
                    </b-form-select-option>
                    <b-form-select-option :value="null" disabled
                      >---</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-end px-1 my-2">
              <b-col class="ml-2 col-12 col-md-3 col-lg-2">
                <b-form-group class="mt-2" label="Frequenza*">
                  <b-form-radio-group
                    v-model="form.posology_every_type"
                    name="posology-every-type-options"
                  >
                    <b-form-radio :value="0">al dì</b-form-radio>
                    <b-form-radio :value="2">al bisogno</b-form-radio>
                    <b-form-radio :value="1">ogni</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col
                v-if="form.posology_every_type === 1"
                class="ml-0 col-11 col-md-7 d-flex align-items-center"
              >
                <b-form-group class="my-auto ml-0">
                  <b-input-group class="align-items-center">
                    <b-form-input
                      class=" "
                      type="number"
                      step="1"
                      min="1"
                      max="36"
                      v-model="form.posology_every_num"
                    >
                    </b-form-input>
                    <!-- <span class="ml-3">Ore</span>
                  <b-input-group-append></b-input-group-append> -->
                  </b-input-group>
                </b-form-group>
                <b-form-group class="my-auto ml-4" label="">
                  <b-form-radio-group
                    v-model="form.posology_every_um"
                    name="posology-every-um-options"
                  >
                    <b-form-radio :value="1">ore</b-form-radio>
                    <b-form-radio :value="0">giorni</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="px-1 my-2">
              <b-col class="ml-2 mt-2">
                <b-form-group
                  label="Per via*"
                  class="col-12 col-md-5 col-lg-3 px-0 max-width-150px"
                >
                  <b-form-select
                    v-capitalize-first-select
                    v-model="form.posology_via"
                    class=" "
                  >
                    <b-form-select-option
                      v-for="via in posologyVias"
                      :key="via"
                      :value="via"
                      >{{ via }}
                    </b-form-select-option>
                    <b-form-select-option :value="null" disabled
                      >---</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="ml-2 mt-2">
                <b-form-group label="Da assumere">
                  <div class="d-flex flex-wrap">
                    <b-form-checkbox
                      v-model="form.mattina"
                      :disabled="
                        blockMoreThanOneAssunzioni &&
                        (form.meta_mattina ||
                          form.pranzo ||
                          form.pomeriggio ||
                          form.sera ||
                          form.notte)
                          ? true
                          : false
                      "
                      class="mr-4"
                      >Mattina</b-form-checkbox
                    >

                    <b-form-checkbox
                      v-model="form.meta_mattina"
                      :disabled="
                        blockMoreThanOneAssunzioni &&
                        (form.mattina ||
                          form.pranzo ||
                          form.pomeriggio ||
                          form.sera ||
                          form.notte)
                          ? true
                          : false
                      "
                      class="mr-4"
                      >Meta mattina</b-form-checkbox
                    >

                    <b-form-checkbox
                      v-model="form.pranzo"
                      :disabled="
                        blockMoreThanOneAssunzioni &&
                        (form.mattina ||
                          form.meta_mattina ||
                          form.pomeriggio ||
                          form.sera ||
                          form.notte)
                          ? true
                          : false
                      "
                      class="mr-4"
                      >Pranzo</b-form-checkbox
                    >

                    <b-form-checkbox
                      v-model="form.pomeriggio"
                      :disabled="
                        blockMoreThanOneAssunzioni &&
                        (form.mattina ||
                          form.meta_mattina ||
                          form.pranzo ||
                          form.sera ||
                          form.notte)
                          ? true
                          : false
                      "
                      class="mr-4"
                      >Pomeriggio</b-form-checkbox
                    >

                    <b-form-checkbox
                      v-model="form.sera"
                      :disabled="
                        blockMoreThanOneAssunzioni &&
                        (form.mattina ||
                          form.meta_mattina ||
                          form.pranzo ||
                          form.pomeriggio ||
                          form.notte)
                          ? true
                          : false
                      "
                      class="mr-4"
                      >Sera</b-form-checkbox
                    >

                    <b-form-checkbox
                      v-model="form.notte"
                      :disabled="
                        blockMoreThanOneAssunzioni &&
                        (form.mattina ||
                          form.meta_mattina ||
                          form.pranzo ||
                          form.pomeriggio ||
                          form.sera)
                          ? true
                          : false
                      "
                      class="mr-4"
                      >Notte</b-form-checkbox
                    >
                  </div>
                </b-form-group>

                <p
                  class="text-danger fs-5"
                  v-if="
                    blockMoreThanOneAssunzioni &&
                    (form.mattina ||
                      form.meta_mattina ||
                      form.pranzo ||
                      form.pomeriggio ||
                      form.sera ||
                      form.notte)
                  "
                >
                  Con questa frequenza è possibile selezionare al massimo un
                  momento di assunzione
                </p>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-row>

      <b-row cols="1" class="mt-4">
        <h5 class="text-prim-grad-1 text-left pt-2 pb-3">
          Durata della terapia
        </h5>
        <div class="m-0 p-0 puntofarmaCard p-2 pl-4">
          <div class="text-left">
            <b-form-radio-group
              v-model="form.type"
              name="end-options"
              class="d-flex ml-2 my-3"
            >
              <b-form-radio :value="0">per sempre</b-form-radio>
              <b-form-radio :value="1">periodica</b-form-radio>
              <b-form-radio :value="2">occasionale</b-form-radio>
            </b-form-radio-group>
            <b-row
              v-if="form.type === 1"
              cols="2"
              class="my-3 col-12 px-0 align-items-center"
            >
              <b-col class="col-md-6 col-12">
                <b-row cols="2" class="align-items-end">
                  <b-col class="col-md-5 col-12">
                    <b-form-group class="" label="Per ">
                      <b-form-input
                        class=" "
                        type="number"
                        step="1"
                        min="1"
                        max="60"
                        v-model="form.for_num"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="ml-2 col-md-6 col-12">
                    <b-form-radio-group
                      v-model="form.for_um"
                      name="for-ums-options"
                      class="my-3"
                    >
                      <b-form-radio :value="0">mesi</b-form-radio>
                      <b-form-radio :value="1">settimane</b-form-radio>
                      <b-form-radio :value="2">giorni</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="col-md-6 col-12">
                <b-row cols="2" class="align-items-end">
                  <b-col class="col-md-5 col-12">
                    <b-form-group class="" label="Ogni ">
                      <b-form-input
                        class=" "
                        type="number"
                        step="1"
                        min="1"
                        max="60"
                        v-model="form.every_num"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="ml-2 col-md-6 col-12">
                    <b-form-radio-group
                      v-model="form.every_um"
                      name="every-ums-options"
                      class="my-3"
                    >
                      <b-form-radio :value="0">mesi</b-form-radio>
                      <b-form-radio :value="1">settimane</b-form-radio>
                      <b-form-radio :value="2">giorni</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              cols="1"
              cols-md="2"
              class="text-left mt-3"
              v-if="form.type >= 1"
            >
              <b-col>
                <b-form-group
                  label="A partire dal*"
                  label-for="start_at"
                  class="text-left mx-auto"
                >
                  <b-form-input
                    class="my-3"
                    name="start_at"
                    type="date"
                    v-model="form.start_at"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col v-if="form.type > 1">
                <b-form-group
                  label="Fino al*"
                  label-for="end_at"
                  class="text-left mx-auto"
                >
                  <b-form-input
                    class="my-3"
                    name="end_at"
                    type="date"
                    v-model="form.end_at"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-row>
      <div v-if="selectedProduct" class="mt-2">
        <b-row>
          <h5 class="text-prim-grad-1 text-left pt-2 pb-3 mt-4">
            Dettagli della terapia
          </h5>
          <b-row cols="2" class="puntofarmaCard p-4 w-100">
            <b-col>
              <b-form-group
                label="Prescrittore terapia*"
                label-for="prescrittore"
                class="text-left mx-auto"
              >
                <b-form-select
                  v-capitalize-first-select
                  class="my-3"
                  name="prescrittore"
                  type="date"
                  v-model="form.prescrittore"
                  required
                >
                  <b-form-select-option
                    v-for="ikey in irstKeys.prescr"
                    :key="ikey.descrizione"
                    :value="ikey.descrizione"
                    >{{ ikey.descrizione }}</b-form-select-option
                  >
                  <b-form-select-option :value="null" disabled
                    >indica chi ha prescritto la terapia</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="pl-2" v-if="user.type === 1">
              <b-form-group
                label="Fonte informativa*"
                label-for="fonte"
                class="text-left mx-auto"
              >
                <b-form-select
                  v-capitalize-first-select
                  class="my-3"
                  name="fonte"
                  type="date"
                  v-model="form.fonte_informativa"
                  required
                >
                  <b-form-select-option
                    v-for="ikey in irstKeys.fonte"
                    :key="ikey.descrizione"
                    :value="ikey.descrizione"
                    >{{ ikey.descrizione }}</b-form-select-option
                  >
                  <b-form-select-option :value="null" disabled
                    >indica fonte informativa</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Effetti collaterali"
                label-for="effetti_collaterali"
                class="text-left mx-auto"
              >
                <b-form-textarea
                  v-capitalize-first
                  class="my-3"
                  name="effetti_collaterali"
                  type="text"
                  placeholder="eventuali effetti collaterali attribuite al farmaco.."
                  v-model="form.effetti_collaterali"
                >
                </b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Reazioni allergiche"
                label-for="reazione_allergica"
                class="text-left mx-auto"
              >
                <b-form-textarea
                  v-capitalize-first
                  class="my-3"
                  name="reazione_allergica"
                  type="text"
                  placeholder="eventuali reazioni allergiche attribuite al farmaco.."
                  v-model="form.reazione_allergica"
                >
                </b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Interazioni"
                label-for="interazioni"
                class="text-left mx-auto"
              >
                <b-form-textarea
                  v-capitalize-first
                  class="my-3"
                  name="interazioni"
                  type="text"
                  placeholder="possibili interazioni con farmaci o alimenti individuate.."
                  v-model="form.interazioni"
                >
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-row>
        <b-row cols="1" class="mt-2">
          <b-col class="px-0" v-if="form.own && user.type === 0">
            <b-form-group
              label=""
              description="**se selezioni questa opzione soltanto tu potrai vedere questa terapia"
              label-for="private"
              class="text-left puntofarmaCard py-3 px-3"
            >
              <b-form-checkbox
                id="private"
                class="ml-2"
                v-model="form.private"
                name="private"
                :value="1"
                :unchecked-value="0"
              >
                visibile solo a me**
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col class="px-0">
            <h5 class="text-prim-grad-1 text-left mt-4">Annotazioni</h5>
            <b-form-group class="text-left w-100 puntofarmaCard p-4"
              ><b-form-textarea
                v-capitalize-first
                class="my-3"
                name="details"
                type="text"
                v-model="form.details"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row cols="1" class="my-3">
          <b-col class="text-right m-1">
            <b-button
              type="button"
              @click="$emit('cancel-operation')"
              variant="popup-button"
              class="mr-3"
              >Annulla</b-button
            >
            <b-button type="submit" variant="popup-button" class="m-1"
              >Salva modifiche</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-form>
  </b-container>
</template>
<script>
import * as moment from "moment/moment"
import { utilityService, cittadinoService } from "@/_services"
import { mapState, mapActions } from "vuex"
export default {
  name: "EditTherapy",
  props: ["therapy"],
  data() {
    return {
      form: {
        type: 0,
        start_at: null,
        end_at: null,
        details: null,
        own: true,
        private: false,
        subjectable_id: null,
        subjectable_type: null,
        chronic: true,
        pathology_id: null,
        every_num: 1,
        every_um: 0,
        for_num: 1,
        for_um: 0,
        posology_num: 1,
        posology_type: "Compresse",
        posology_via: "Orale",
        posology_every_num: 1,
        posology_every_um: 0,
        posology_every_type: 0,
        product_name: null,
        product_aic: null,
        prescrittore: null,
        fonte_informativa: null,
        effetti_collaterali: null,
        reazione_allergica: null,
        interazioni: null,
        mattina: false,
        meta_mattina: false,
        pranzo: false,
        pomeriggio: false,
        sera: false,
        notte: false
      },
      hasPathology: 0,
      everyUms: {
        0: "mesi",
        1: "settimane",
        2: "giorni"
      },
      everyPosologyTypes: {
        0: "dì",
        1: "ore",
        2: "bisogno"
      },
      everyPosologyUms: {
        1: "ore",
        2: "giorni"
      },
      posologyTypes: [
        "Compresse",
        "Capsule",
        "Gocce",
        "Inalazioni",
        "Buste",
        "Supposte",
        "Scatole",
        "Cerotti",
        "Fiale",
        "Fleboclisi",
        "Ovuli",
        "Sacche",
        "Flaconi",
        "Confezioni",
        "Kilogrammi",
        "Kit",
        "Millilitri",
        "Milligrammi"
      ],
      posologyVias: [
        "Orale",
        "Transdermica",
        "Nasale",
        "Endovena",
        "Intramuscolo",
        "Oftalmica",
        "Rettale",
        "Uretale",
        "Vaginale",
        "Sottocute"
      ],
      pathologies: null,
      typesColors: ["ter-4", "ter-1", "ter-2"],
      prodSearch: {
        q: null
      },
      products: null,
      selectedProduct: null,
      isSearching: false,
      showNotFound: false,
      irstKeys: {
        prescr: [],
        fonte: []
      }
    }
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert
    }),
    blockMoreThanOneAssunzioni() {
      return this.posologyIsAlDi || this.posologyIsOgniNgiorni
    },
    posologyIsAlDi() {
      return this.form.posology_every_type === 0
    },
    posologyIsOgniNgiorni() {
      return (
        this.form.posology_every_type === 1 && this.form.posology_every_um === 0
      )
    }
  },
  watch: {
    prodSearch: {
      handler(val) {
        if (val.q == null || val.q.length < 3) {
          this.products = null
          this.selectedProduct = null
        }
      },
      deep: true
    },
    selectedProduct: {
      handler(val) {
        if (val !== null) {
          this.form.product_name = val.Nome
          this.form.product_aic = val.Aic
          this.form.product_principio_attivo = val.PrincipioAttivo
          this.form.product_codice_principio_attivo = val.CodicePrincipioAttivo
        }
      },
      deep: true
    },
    "form.posology_every_type": function (newVal, oldVal) {
      this.checkMaxAssunzioni()
    },
    "form.posology_assunzioni": function (newVal, oldVal) {
      console.log("assunzioni", newVal, oldVal)
      this.checkMaxAssunzioni()
    },
    "form.posology_every_um": function (newVal, oldVal) {
      this.checkMaxAssunzioni()
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    formatData(data) {
      return moment(data).format("DD MMM YYYY")
    },
    selectPathology(pathology) {
      this.form.pathology_id = pathology.hashid
      this.form.chronic = pathology.type === 0
      this.form.type = pathology.type !== 0 ? 2 : 0
    },
    searchProds() {
      if (this.prodSearch.q && this.prodSearch.q.length >= 3) {
        this.isSearching = true
        var self = this
        this.showNotFound = false
        utilityService
          .products(this.prodSearch)
          .then(function (response) {
            self.products = response.data
            if (!(response.data instanceof Array)) {
              self.products = [response.data]
            }
            self.isSearching = false
          })
          .catch(function (error) {
            self.showNotFound = true
            self.isSearching = false
          })
      } else if (!this.selectedProduct) {
        this.products = null
      }
    },
    saveTherapyUpdates() {
      var self = this
      cittadinoService
        .updateTherapy(this.form)
        .then(function (response) {
          self.$emit("save-therapy", response.data.data)
          self.successAlert("Nuova terapia salvata")
        })
        .catch(function (error) {
          console.log(error)
          self.errorAlert("Si è verificato un errore durante il salvataggio")
        })
    },
    getKeys() {
      var filters = {
        key: "prescr,fonte"
      }
      var self = this
      utilityService
        .getIrstKeys(filters)
        .then(function (res) {
          self.irstKeys = res.data.data
        })
        .catch(function (err) {
          {
            self.errorAlert("Si è verificato un errore.")
          }
        })
    },
    checkMaxAssunzioni() {
      if (this.blockMoreThanOneAssunzioni) {
        const assunzioni = [
          this.form.mattina,
          this.form.meta_mattina,
          this.form.pranzo,
          this.form.pomeriggio,
          this.form.sera,
          this.form.notte
        ]

        if (assunzioni.filter((a) => a).length > 1) {
          this.form.mattina = false
          this.form.meta_mattina = false
          this.form.pranzo = false
          this.form.pomeriggio = false
          this.form.sera = false
          this.form.notte = false
        }
      }
    }
  },
  created() {
    if (this.therapy) {
      this.form = this.therapy

      this.form.mattina === 1 ? (this.form.mattina = true) : false
      this.form.meta_mattina === 1 ? (this.form.meta_mattina = true) : false
      this.form.pranzo === 1 ? (this.form.pranzo = true) : false
      this.form.pomeriggio === 1 ? (this.form.pomeriggio = true) : false
      this.form.sera === 1 ? (this.form.sera = true) : false
      this.form.notte === 1 ? (this.form.notte = true) : false

      this.selectedProduct = {
        Nome: this.therapy.product_name,
        Aic: this.therapy.product_aic
      }
    }
    this.getKeys()
    this.searchProds()
  }
}
</script>

<style scoped>
#posology-type-select {
  max-width: 150px;
}
</style>
