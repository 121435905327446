<template>
  <div class="text-left px-md-4">
    <h4 class="text-prim-grad-1 text-left pb-2 mb-4">Gestione Prenotazioni</h4>
    <section class="puntofarmaCard px-4 py-2">
      <section class="d-flex align-items-center justify-content-start">
        <p class="text-secondary mb-1" style="width: 200px">
          ricerca prenotazioni per
        </p>
        <article class="d-flex" style="flex-grow: 1">
          <b-form-radio-group v-model="searchType" @change="nullReservations">
            <b-form-radio name="searchTypeRadio" value="reservcode" class="pr-4"
              >codice di prenotazione</b-form-radio
            >
            <b-form-radio
              name="searchTypeRadio"
              value="company"
              disabled
              class="pr-4"
              >farmacia*</b-form-radio
            >
            <b-form-radio
              class="pr-4"
              name="searchTypeRadio"
              disabled
              value="cf"
              >codice fiscale*</b-form-radio
            >
          </b-form-radio-group>
        </article>
      </section>

      <section></section>
      <b-form v-if="searchType == 'resevcode'" @submit.prevent="sendResevCode">
        <b-form-group>
          <b-form-input
            required
            v-model="params.reservcode"
            placeholder="Enter your name"
          >
          </b-form-input>
          <b-button type="submit">Cerca</b-button>
        </b-form-group>
      </b-form>

      <b-row v-if="searchType === 'reservcode'" class="mt-3 pb-2 d-flex">
        <b-form
          class="px-0 d-flex col-12"
          @submit.prevent="searchReservByResevCode"
        >
          <b-col class="col-12 col-md-6 col-lg-4 px-0">
            <b-form-group class="text-left col-12 mr-auto px-0 m-0">
              <b-form-input
                name="cf"
                type="text"
                required
                placeholder="Codice di prenotazione"
                v-model="params.reservcode"
                maxlength="12"
                minlength="12"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-1 pl-0 d-flex align-self-end">
            <b-button type="submit" class="mt-2 mt-md-0 ml-0 ml-md-2"
              >Cerca</b-button
            >
          </b-col>
        </b-form>
      </b-row>
      <b-row v-if="searchType === 'company'" class="pb-2 d-flex">
        <article v-if="!params.companyHash">
          <span class="py-2">Seleziona la farmacia:</span>
          <SearchCompanyAdmin
            v-on:company-saved="companySaved($event)"
            class="px-0"
          />
        </article>
        <article v-else class="mt-4">
          <div class="d-flex">
            <p class="">
              <span class="text-secondary mt-2">Farmacia Selezionata</span>:
              {{ selectedCompany.name }} &nbsp;
            </p>
            <p
              @click="resetCompany"
              class="border point border-primary px-2 general_border"
            >
              Cambia
            </p>
          </div>
          <p>Seleziona almeno una delle due date seguenti:</p>
          <b-form class="px-0 col-12" @submit.prevent="searchReservByResevCode">
            <section class="d-flex">
              <b-col class="col-12 col-lgx-10 px-0">
                <b-form-group
                  class="text-left col-12 mr-auto px-0 m-0 fs-6 text-secondary"
                >
                  <label for="data-appuntamento">data appuntamento</label>
                  <b-form-datepicker
                    label-no-date-selected="Nessuna data"
                    size=""
                    id="data-appuntamento"
                    v-model="params.dataAppuntamento"
                    class=""
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
            </section>
            <section class="d-flex">
              <b-col class="col-12 col-lgx-10 px-0">
                <b-form-group
                  class="text-left col-12 mr-auto px-0 m-0 text-secondary"
                >
                  <label for="data-inoltro" class="mt-2"
                    >data inoltro prenotazione</label
                  >
                  <b-form-datepicker
                    label-no-date-selected="Nessuna data"
                    size=""
                    id="data-inoltro"
                    v-model="params.dataInoltro"
                    class=""
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
            </section>
            <b-row>
              <b-button
                :disabled="companySearchDisabled"
                type="submit"
                class="mt-3"
                variant="secondary"
                >Cerca</b-button
              >
            </b-row>
          </b-form>
          <b-form
            class="px-0 d-flex col-12 pt-2"
            @submit.prevent="searchReservByResevCode"
          >
          </b-form>
        </article>
      </b-row>
      <p class="mt-2">*Richiede inserimento di una data</p>
    </section>

    <section class="puntofarmaCard mt-4 general_border" v-if="!notFound">
      <b-table
        :fields="fields"
        :items="searchResult"
        v-if="searchResult && searchResult.length > 0"
      >
        <template #cell(icona)="row">
          <b-icon variant="primary" :icon="icons[row.item.icona]"></b-icon>
        </template>
        <template #cell(data)="row">
          <span v-if="row.item.data_inizio">
            {{ row.item.data_inizio | moment("DD/MM/YY HH:mm") }}</span
          ><span v-else> da definire</span>
        </template>

        <template #cell(utente)="row">
          {{
            row.item.requested_by.anagrafica.name.charAt(0).toUpperCase() +
            row.item.requested_by.anagrafica.name.slice(1)
          }}
          {{
            row.item.requested_by.anagrafica.surname.charAt(0).toUpperCase() +
            row.item.requested_by.anagrafica.surname.slice(1)
          }}
        </template>
        <template #cell(codicefiscale)="row">
          {{ row.item.anagrafica.cf }}
        </template>
        <template #cell(azioni)="row">
          <b-button class="mr-2">Vedi storico</b-button>
        </template>
      </b-table>
      <p
        class="p-4 border border-danger general_border text-danger text-center"
        v-else
      >
        Nessun risultato per i parametri di ricerca.
      </p>
    </section>
    <p v-else-if="!notYetSearched" class="mt-4 text-center">
      Non è stato trovato nessun risultato con i criteri di ricerca selezionati.
    </p>
  </div>
</template>
<script>
import { adminService } from "@/_services";
import { mapState, mapActions } from "vuex";
import SearchCompanyAdmin from "@/components/home/SearchCompanyAdmin.vue";

// import CompactSearch from "@/components/admin/CompactSearch.vue";
export default {
  name: "GestioneUtenti",
  // components: { CompactSearch, CompanyReport },
  components: {
    SearchCompanyAdmin,
  },
  data() {
    return {
      textVModel: "",
      selectedCompany: {
        name: "abcde",
        hashid: "fhilmn",
      },
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill",
      },

      searchType: "reservcode",

      params: {
        cf: null,
        email: null,
        surname: null,
        dataAppuntamento: null,
        dataInoltro: null,
        companyHash: 123,
      },

      searchResult: null,
      notFound: true,
      notYetSearched: true,
      //   companies: null,
      fields: [
        { key: "icona", label: "" },
        { key: "tipo", label: "" },
        { key: "codice_richiesta", label: "C.Richiesta" },
        { key: "data", label: "Data", sortable: true },
        { key: "utente", label: "Cliente" },
        // { key: "contatti", label: "Contatti" },
        { key: "stato", label: "Stato" },
        { key: "azioni", label: "" },
      ],
      //   filterSetted: {
      //     page: 1,
      //     take: 10,
      //   },
      //   total: 0,
      //   fromPage: 0,
      //   toPage: 0,
      //   selectedCompanies: [],
      //   takeOptions: [10, 25, 50, 100],
      //   modalKind: null,
      //   modalTitle: "",
      //   activityFilter: {
      //     inarray: true,
      //   },
      //   surveyFilter: {
      //     activity: null,
      //     inarray: true,
      //   },
      //   activities: null,
      //   surveys: null,
      //   selectedActivity: null,
      //   selectedSurvey: null,
      //   companyArray: [],
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    validCf() {
      var validRegex =
        /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
      return this.params.cf.match(validRegex) ? true : false;
    },
    validEmail() {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.params.email.match(validRegex) ? true : false;
    },
    // validCR() {
    //   return this.params.reservcode ? this.params.reservcode.length == 16 : true;
    // }
    companySearchDisabled() {
      return !(this.params.dataAppuntamento || this.params.dataInoltro);
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    resetCompany() {
      this.selectedCompany.name = null;
      this.params.companyHash = null;
    },
    companySaved(selectedCompany) {
      this.selectedCompany.name = selectedCompany.name;
      this.params.companyHash = selectedCompany.hashid;
    },
    nullReservations() {
      this.searchResult = null;
      this.notFound = true;
      this.notYetSearched = true;
    },
    searchReservByResevCode() {
      this.notFound = false;
      this.searchResult = null;
      var self = this;
      adminService
        .getReservationByCR(this.params.reservcode)
        .then(function (res) {
          self.searchResult = Array.isArray(res.data.data)
            ? res.data.data
            : [res.data.data];
          self.notYetSearched = false;
        })
        .catch(function (err) {
          self.notFound = true;
          self.notYetSearched = false;
        });
    },
    sendResevForm(e) {
      e.preventDefault();
      this.notFound = false;
      this.searchResult = null;
      // var self = this;
      adminService
        .getReservationByCR(this.params.reservcode)
        .then(function (res) {
          // self.searchResult = Array.isArray(res.data.data)
          //   ? res.data.data
          //   : [res.data.data];
          // self.notYetSearched = false;
        })
        .catch(function (err) {
          // self.notFound = true;
          // self.notYetSearched = false;
        });
    },
  },
  created() {
    // setInterval(() => console.log(JSON.stringify(this.params)), 5000);
  },
};
</script>
