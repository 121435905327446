<template>
    <b-row class="">
      <b-col class="py-1 col-12 px-0">
        <h6>Delega gestione calendario</h6>
        <div class="">
          <b-form-checkbox
            class="fs-5"
            name="service_proxystatus"
            :value="1"
            :unchecked-value="0"
            v-model="formData.form.proxy_scheduling"
            switch
            size="lg"
            @change="handleChange"
            disabled
          >
            {{ statoservizio === 1 ? "Attiva" : "Inattiva" }}
          </b-form-checkbox>
        </div>
        <b-modal
          centered
          hide-footer
          :id="'modal-confirm-update-' + this.servicename"
          title="Conferma Operazione"
          header-text-variant="text-center"
          content-class="text-center"
          @hidden="cancelOp"
        >
          <p class="mb-4 text-center">
            Sei sicuro di voler
            {{ formData.form.proxy_scheduling ? "attivare" : "disattivare" }} la
            delega alla gestione calendario per il servizio
            <span class="text-capitalize">{{ servicename }}</span
            >?
          </p>
          <div class="text-right">
            <b-button type="button" variant="popup-button mr-3" @click="cancelOp"
              >No, Annulla</b-button
            >
            <b-button variant="popup-button" type="button" @click="submitChanges"
              >Sì, Conferma
            </b-button>
          </div>
        </b-modal>
      </b-col>
    </b-row>
  </template>
  <script>
  export default {
    name: "ServiceProxySchedulingStatusCentriErogatori",
    props: ["servicename", "statoservizio", "company"],
    computed: {},
    data() {
      return {
        proxyStatus: null,
        formData: {
          form: {
            proxy_scheduling: this.statoservizio,
          },
          service_name: this.servicename,
          service_hash: null,
        },
      };
    },
    // methods: {
    //   handleChange() {
    //     // console.log("form changed", this.formData.form);
    //     this.$bvModal.show("modal-confirm-update-" + this.servicename);
    //   },
    //   submitChanges() {
    //     if (this.formData) {
    //       var data = this.formData;
    //       this.$emit("store-service-proxy-status", data);
    //     }
    //     this.$bvModal.hide("modal-confirm-update-" + this.servicename);
    //   },
    //   cancelOp() {
    //     this.$bvModal.hide("modal-confirm-update-" + this.servicename);
    //     this.formData.form.proxy_scheduling = this.proxyStatus;
    //   },
    // },
    methods: {
      handleChange() {
        console.log("OH");
        this.$bvModal.show("modal-confirm-update-" + this.servicename);
      },
      submitChanges() {
        if (this.formData) {
          const data = this.formData;
          this.$emit("store-service-status", data);
        }
        this.$bvModal.hide("modal-confirm-update-" + this.servicename);
      },
      cancelOp() {
        this.$bvModal.hide("modal-confirm-update-" + this.servicename);
        this.formData.form.proxy_scheduling = this.statoservizio;
      },
    },
    watch: {
      statoservizio(val) {
        if (val) {
          console.log("statoservizio proxy", val);
          this.proxyStatus = val;
          this.formData.form.proxy_scheduling = val;
        }
      },
    },
  };
  </script>
  