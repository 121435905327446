<template>
  <div class="pb-2" v-if="form !== null">
    <div class="d-flex justify-items-between">
      <b-col class="col-12 pl-0 d-flex align-items-center">
        <h4 class="text-left page-title mb-0">Anagrafica Azienda</h4>
      </b-col>
    </div>

    <!-- <h4 class="text-left pb-2">Anagrafica Azienda</h4> -->
    <b-form
      @submit.prevent="submitAnagrafica"
      id="anagrafica_form"
      class="pb-3 text-left"
    >
      <b-row class="d-flex">
        <b-col class="px-0 col-12 col-lgx-6 d-flex flex-column">
          <div
            class="mr-lgx-2 d-flex flex-column"
            :class="{ 'flex-grow-1': !editIndexes[1] }"
          >
            <h3 class="mt-2 pt-4 text-left">
              <b-icon
                icon="person"
                variant="secondary"
                font-scale="1.2"
                class="mr-1"
              ></b-icon>
              Profilo
              <b-icon
                class="ml-2 mb-1"
                v-if="!editIndexes[0]"
                font-scale="0.8"
                :size="$root.isMobile ? 'sm' : ''"
                type="submit"
                variant="secondary"
                @click="setEditIndex(0, true)"
                icon="pencil-fill"
              />
               <!-- <b-iconstack
                v-if="!editIndexes[0]"
                font-scale=“2.2”
                class="ml-2 mb-1"
                @click="setEditIndex(0, true)"
              >
                <b-icon
                  stacked
                  icon=“circle-fill”
                  variant="mdm-light-grey"
                ></b-icon>
                <b-icon
                  stacked
                  icon="pencil-fill"
                  scale="0.5"
                  variant="secondary"
                ></b-icon>
              </b-iconstack> -->
            </h3>
            <section
              class="puntofarmaCard h-100 general_border p-2 p-md-3 p-lg-4 flex-grow-11"
            >
              <div class="d-flex" :class="{ 'flex-grow-1': editIndexes[0] }">
                <b-col
                  class="col-4 px-0 d-flex flex-column justify-content-around"
                >
                  <label for="" class="text-center mb-0">Logo Azienda</label>
                  <b-avatar
                    variant="white"
                    :src="avatarPath"
                    rounded
                    size="6rem"
                    class="mx-md-auto"
                  >
                  </b-avatar>
                  <div class="d-flex flex-column justify-content-around">
                    <b-button
                      v-if="avatarPath"
                      @click="eliminaAvatarAzienda"
                      size="sm"
                      variant="outline-secondary my-2"
                    >
                      Elimina
                    </b-button>
                    <b-button
                      @click="$refs.uploadComponentRef.click()"
                      size="sm"
                      variant="secondary"
                    >
                      Scegli immagine
                    </b-button>
                    <div class="d-none">
                      <UploadComponent
                        dest="profilo"
                        class="w-100 mb-n2 pb-1"
                        @show-carica-button="handleFileSelection($event)"
                        v-on:upload-result="handleUpload($event)"
                        ref="uploadComponentRef"
                      />
                    </div>
                    <b-button
                      v-if="showCaricaButton"
                      @click="callDoUploadOnUploadComponent()"
                      size="sm"
                      class="mb-md-3 mt-2"
                      variant="secondary"
                    >
                      Carica logo azienda
                    </b-button>
                  </div>
                </b-col>
                <b-row cols="1" class="col-8 px-0">
                  <b-col>
                    <div class="d-flex flex-column justify-content-start ml-2">
                      <b-form-group
                        label="Nome Titolare"
                        label-for="name"
                        class="mb-2"
                      >
                        <b-form-input
                          v-capitalize-first
                          name="name"
                          type="text"
                          placeholder="Nome..."
                          v-model="form.nome"
                          :readonly="!editIndexes[0]"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Cognome Titolare"
                        label-for="surname"
                        class="mt-3"
                      >
                        <b-form-input
                          v-capitalize-first
                          name="surname"
                          type="text"
                          placeholder="Cognome..."
                          v-model="form.cognome"
                          :readonly="!editIndexes[0]"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <p class="mb-0 w-100 text-right pt-3" v-if="editIndexes[0]">
                <b-button
                  type="button"
                  variant="outline-secondary mx-2"
                  @click.prevent="handleAnnulla()"
                  >Annulla</b-button
                >
                <b-button type="submit" variant="secondary mx-2"
                  >Salva Modifiche
                </b-button>
              </p>
            </section>
          </div>
        </b-col>
        <b-col class="px-0 col-12 col-lgx-6">
          <div class="ml-lgx-2">
            <h3 class="mt-2 pt-4 text-left">
              <b-icon
                icon="telephone"
                variant="secondary"
                font-scale="1.2"
                class="mr-1"
              ></b-icon>
              Contatti
              <b-icon
                class="ml-2 mb-1"
                v-if="!editIndexes[1]"
                font-scale="0.8"
                :size="$root.isMobile ? 'sm' : ''"
                type="submit"
                variant="secondary"
                @click="setEditIndex(1, true)"
                icon="pencil-fill"
              />
            </h3>
            <section
              class="bg-white general_border puntofarmaCard p-2 p-md-3 p-lg-4 mt-2"
            >
              <b-row>
                <b-row cols="1" cols-md="2" cols-xl="2">
                  <b-col>
                    <b-form-group label="Email" label-for="email">
                      <b-form-input
                        class="my-3"
                        name="email"
                        type="email"
                        placeholder="Email..."
                        v-model="form.email"
                        :readonly="!editIndexes[1]"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Pec" label-for="emailpec">
                      <b-form-input
                        class="my-3"
                        name="emailpec"
                        type="email"
                        placeholder="Pec..."
                        v-model="form.emailpec"
                        :readonly="!editIndexes[1]"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Telefono" label-for="telefono">
                      <b-form-input
                        class="my-3"
                        name="telefono"
                        type="text"
                        placeholder="Telefono..."
                        v-model="form.telefono"
                        :readonly="!editIndexes[1]"
                        minlength="8"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Sito web" label-for="sito_web">
                      <b-form-input
                        class="my-3"
                        name="sito_web"
                        type="text"
                        placeholder="Sito web..."
                        v-model="form.sito_web"
                        :readonly="!editIndexes[1]"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-row>
              <p class="mb-0 w-100 text-right pt-3" v-if="editIndexes[1]">
                <b-button
                  type="button"
                  variant="outline-secondary mx-2"
                  @click.prevent="handleAnnulla()"
                  >Annulla</b-button
                >
                <b-button type="submit" variant="secondary mx-2"
                  >Salva Modifiche
                </b-button>
              </p>
            </section>
          </div>
        </b-col>
      </b-row>

      <h3 class="mt-2 pt-4 text-left">
        <b-icon
          icon="credit-card-2-front"
          variant="secondary"
          font-scale="1.2"
          class="mr-1"
        ></b-icon>
        Informazioni Fiscali
        <b-icon
          class="ml-2 mb-1"
          v-if="!editIndexes[2]"
          font-scale="0.8"
          :size="$root.isMobile ? 'sm' : ''"
          type="submit"
          variant="secondary"
          @click="setEditIndex(2, true)"
          icon="pencil-fill"
        />
      </h3>
      <section class="puntofarmaCard general_border p-2 p-md-3 p-xl-4">
        <b-row cols="1" cols-md="3">
          <b-col>
            <b-form-group label="Denominazione" label-for="denominazione">
              <b-form-input
                class="my-3"
                name="denominazione"
                type="text"
                placeholder="Denominazione..."
                v-model="form.denominazione"
                :readonly="!editIndexes[2]"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Codice Tracciabilità"
              label-for="codice_tracciabilita"
            >
              <b-form-input
                class="my-3"
                name="codice_tracciabilita"
                type="text"
                placeholder="Codice tracciabilità..."
                v-model="form.codice_tracciabilita"
                :readonly="!editIndexes[2]"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Codice asl" label-for="codice_asl">
              <b-form-input
                v-capitalize-first
                class="my-3"
                name="codice_asl"
                type="text"
                placeholder="Codice asl..."
                v-model="form.codice_asl"
                :readonly="!editIndexes[2]"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Codice Fiscale" label-for="codice_fiscale">
              <b-form-input
                class="my-3 text-uppercase"
                name="codice_fiscale"
                type="text"
                placeholder="Codice fiscale..."
                v-model="form.codice_fiscale"
                :readonly="!editIndexes[2]"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Partita Iva" label-for="partita_iva">
              <b-form-input
                class="my-3"
                name="partita_iva"
                type="text"
                placeholder="Partita Iva..."
                v-model="form.partita_iva"
                :readonly="!editIndexes[2]"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
         <b-col>
            <b-form-group label="Contatto DPO" label-for="contatto_dpo">
              <b-form-input
                v-capitalize-first
                class="my-3"
                name="contatto_dpo"
                type="text"
                placeholder="Contatto DPO..."
                v-model="form.contatto_dpo"
                :readonly="!editIndexes[2]"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <p class="mb-0 w-100 text-right pt-3" v-if="editIndexes[2]">
          <b-button
            type="button"
            variant="outline-secondary mx-2"
            @click.prevent="handleAnnulla()"
            >Annulla</b-button
          >
          <b-button type="submit" variant="secondary mx-2"
            >Salva Modifiche
          </b-button>
        </p>
      </section>
      <h3 class="mt-2 pt-4 text-left">
        <b-icon
          icon="person-badge"
          variant="secondary"
          font-scale="1.2"
          class="mr-1"
        ></b-icon>
        Referente per la struttura
        <b-icon
          class="ml-2 mb-1"
          v-if="!editIndexes[3]"
          font-scale="0.8"
          :size="$root.isMobile ? 'sm' : ''"
          type="submit"
          variant="secondary"
          @click="setEditIndex(3, true)"
          icon="pencil-fill"
        />
      </h3>
      <section class="puntofarmaCard general_border p-2 p-md-3 p-xl-4">
  <b-row cols="1" cols-md="3">
    <b-col>
      <b-form-group label="Nome Referente" label-for="referente_fullname">
        <b-form-input
          class="my-3"
          name="referente_fullname"
          type="text"
          placeholder="Nome Referente..."
          v-model="form.referente_fullname"
          :readonly="!editIndexes[3]"
          required
        ></b-form-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="Email Referente" label-for="referente_email">
        <b-form-input
          class="my-3"
          name="referente_email"
          type="email"
          placeholder="Email Referente..."
          v-model="form.referente_email"
          :readonly="!editIndexes[3]"
          required
        ></b-form-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="Telefono Referente" label-for="referente_telefono">
        <b-form-input
          class="my-3"
          name="referente_telefono"
          type="tel"
          placeholder="Telefono Referente..."
          v-model="form.referente_telefono"
          :readonly="!editIndexes[3]"
          required
        ></b-form-input>
      </b-form-group>
    </b-col>
  </b-row>
  <p class="mb-0 w-100 text-right pt-3" v-if="editIndexes[3]">
    <b-button
      type="button"
      variant="outline-secondary mx-2"
      @click.prevent="handleAnnulla()"
    >Annulla</b-button>
    <b-button type="submit" variant="secondary mx-2">Salva Modifiche</b-button>
  </p>
</section>


      <h3 class="mt-2 pt-4 text-left">
        <b-icon
          icon="geo"
          variant="secondary"
          font-scale="1.2"
          class="mr-1"
        ></b-icon>
        Indirizzo
        <b-icon
          class="ml-2 mb-1"
          v-if="!editIndexes[4]"
          font-scale="0.8"
          :size="$root.isMobile ? 'sm' : ''"
          type="submit"
          variant="secondary"
          @click="setEditIndex(4, true)"
          icon="pencil-fill"
        />
      </h3>
      <section class="puntofarmaCard general_border p-2 p-md-3 p-xl-4">
        <b-row cols="1" cols-md="3">
          <b-col>
            <b-form-group label="Regione" label-for="regione">
              <b-form-select
                v-capitalize-first-select
                class="my-3"
                :options="regioni"
                name="regione"
                v-model="selected.regione"
                @change="getProvince(selected)"
                :disabled="!editIndexes[4]"
                required
              >
                <b-form-select-option :value="null"
                  >Seleziona Regione</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Provincia" label-for="provincia">
              <b-form-select
                v-capitalize-first-select
                class="my-3"
                :options="province"
                name="provincia"
                v-model="selected.provincia"
                @change="getComuni(selected)"
                :disabled="!editIndexes[4]"
                required
              >
                <b-form-select-option :value="null"
                  >Seleziona Provincia</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Comune" label-for="comune">
              <b-form-select
                v-capitalize-first-select
                class="my-3"
                name="comune"
                v-model="selected.comune"
                :key="
                comuni && comuni.length
                  ? comuni[0].comune + comuni.length
                  : null
              "
                :disabled="!editIndexes[4]"
                required
              >
                <b-form-select-option
                  v-for="(com, ind) in comuni"
                  :key="ind"
                  :value="com"
                  @change="setDomicilioData"
                  :selected="findComune(com)"
                  >{{ com.comune }}</b-form-select-option
                >
                <b-form-select-option :value="null"
                  >Seleziona Comune</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Frazione" label-for="frazione">
              <b-form-input
                v-capitalize-first
                class="my-3"
                name="frazione"
                type="text"
                placeholder="Frazione..."
                v-model="form.frazione"
                :readonly="!editIndexes[4]"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Indirizzo e numero civico"
              label-for="indirizzo"
            >
              <b-form-input
                v-capitalize-first
                class="my-3"
                name="indirizzo"
                type="text"
                placeholder="Indirizzo..."
                v-model="form.indirizzo"
                :readonly="!editIndexes[4]"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="CAP" label-for="cap">
              <b-form-input
                v-capitalize-first
                class="my-3"
                name="cap"
                type="text"
                placeholder="CAP..."
                v-model="form.cap"
                minlength="5"
                maxlength="5"
                :readonly="!editIndexes[4]"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <p class="mb-0 w-100 text-right pt-3" v-if="editIndexes[4]">
          <b-button
            type="button"
            variant="outline-secondary mx-2"
            @click.prevent="handleAnnulla()"
            >Annulla</b-button
          >
          <b-button type="submit" variant="secondary mx-2"
            >Salva Modifiche
          </b-button>
        </p>
      </section>
    </b-form>
    <b-modal
      id="delete-propic-confirm"
      size="lg"
      centered
      title="Conferma eliminazione"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="popup-button mr-2"
      ok-title="Sì, Conferma"
      ok-variant="popup-button"
      footer-class="border-0"
      @ok="deleteCompanyPropic()"
      @cancel="$bvModal.hide('delete-propic-confirm')"
    >
      Confermi di voler eliminare l'immagine del profilo?
    </b-modal>
  </div>
</template>
<script>
import Vue from "vue"
import { mapState, mapActions, mapGetters } from "vuex"
import UploadComponent from "@/components/utilities/UploadComponent.vue"
import { companyService } from "@/_services"

export default {
  name: "AnagraficaCompany",
  props: [],
  components: {
    UploadComponent
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    ...mapGetters({
      getCompany: "company/getCompany"
    })
  },
  data() {
    return {
      editIndexes: [false, false, false, false, false],
      showCaricaButton: false,
      form: null,
      selected: {
        regione: null,
        provincia: null,
        comune: null
      },
      noEdit: false,
      avatarPath: null,
      selectingImage: false
    }
  },
  methods: {
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    ...mapActions("company", ["updateAnagrafica", "logUserCompanyIn"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    setEditIndex(index, value) {
      console.log("index", index)
      this.editIndexes.forEach((el, i) => {
        Vue.set(this.editIndexes, i, false)
      })
      Vue.set(this.editIndexes, index, value)
    },
    callDoUploadOnUploadComponent() {
      this.showCaricaButton = false
      this.$refs.uploadComponentRef.doUpload()
    },
    setDomicilioData() {
      // console.log("on change", this.selected.comune);
      if (this.selected.comune) {
        this.form.comune = this.selected.comune.comune
        this.form.provincia = this.selected.comune.provincia
        this.form.regione = this.selected.comune.regione
      }
    },
    handleUpload(data) {
      var self = this
      this.avatarPath = data.full_path
      companyService
        .attachUploadedResourceToCompany(data.hashid)
        .then(function (res) {
          self.selectingImage = false
          self.successAlert("Immagine del profilo caricata correttamente")
          self.logUserCompanyIn()
          // console.log("TEST");
        })
        .catch(function (err) {
          // console.log(err);
          self.errorAlert("Errore nel caricamento dell'immagine del profilo")
        })
    },

    deleteCompanyPropic() {
      var self = this
      companyService
        .deleteCompanyPropic()
        .then(function (res) {
          // console.log(res);
          self.logUserCompanyIn()
          self.successAlert("Immagine del profilo eliminata correttamente")
          self.avatarPath = null
        })
        .catch(function (err) {
          // console.log(err);
          self.errorAlert("Errore nell'eliminazione dell'immagine del profilo")
        })
    },

    findComune(comune) {
      if (this.form && this.form.comune) {
        if (comune.comune === this.form.comune) {
          this.selected.comune = comune
          return true
        }
      }
      return false
    },
    setupPrevs() {
      if (this.form.regione) {
        this.selected.regione = this.form.regione
        this.getProvince(this.selected)
      }
      if (this.form.provincia) {
        this.selected.provincia = this.form.provincia
        this.getComuni(this.selected)
      }
    },
    handleAnnulla() {
      this.editIndexes.forEach((el, i) => {
        Vue.set(this.editIndexes, i, false)
      })
      this.setup()
      // console.log("this.getCompany", this.getCompany);
    },
    setup() {
      var data = this.company
      this.form = {}
      this.form.nome = data.nome
      this.form.cognome = data.cognome
      this.form.denominazione = data.denominazione
      this.form.codice_tracciabilita = data.codice_tracciabilita
      this.form.codice_asl = data.codice_asl
      this.form.codice_fiscale = data.codice_fiscale
      this.form.partita_iva = data.partita_iva
      this.form.contatto_dpo = data.contatto_dpo 
      this.form.regione = data.regione
      this.form.provincia = data.provincia
      this.form.comune = data.comune
      this.form.frazione = data.frazione
      this.form.indirizzo = data.indirizzo
      this.form.cap = data.cap
      this.form.email = data.email
      this.form.emailpec = data.emailpec
      this.telefono = data.telefono
      this.sito_web = data.sito_web
      this.form.referente_fullname = data.referente_fullname  
      this.form.referente_email = data.referente_email  
      this.form.referente_telefono = data.referente_telefono  

      this.setupPrevs()
      // console.log("SETUP", this.form);
    },
    submitAnagrafica() {
      // console.log("Submitting ", this.form);
      var toIgnore = [
        "dottorfarma",
        "enabled",
        "ff_username",
        "hashid",
        "laravel_through_key"
      ]
      var dataToSend = {}
      var self = this
      Object.keys(self.form).map(function (el, ind) {
        if (typeof self.form[el] !== "object" && !toIgnore.includes(el)) {
          dataToSend[el] = self.form[el]
        }
      })
      this.updateAnagrafica(dataToSend).then(function () {
        window.scrollTo(0, 0)
        self.editIndexes.forEach((el, i) => {
          Vue.set(self.editIndexes, i, false)
        })
      })
      // console.log("formdata after map", dataToSend);
    },
    handleFileSelection(file) {
      console.log("file", file)
      this.showCaricaButton = true
      this.selectingImage = true
      this.avatarPath = URL.createObjectURL(file)
    },
    eliminaAvatarAzienda() {
      if (this.selectingImage) {
        this.avatarPath = null
        this.showCaricaButton = false
        return
      }
      this.$bvModal.show("delete-propic-confirm")
    }
  },
  watch: {
    selected: {
      handler(val) {
        // console.log("selected changed");
        if (val.comune !== null) {
          this.form.comune = this.selected.comune.comune
          this.form.provincia = this.selected.comune.provincia
          this.form.regione = this.selected.comune.regione
        }
      },
      deep: true
    }
  },
  created() {
    if (this.company !== null) {
      if (this.company.profile_pic) this.avatarPath = this.company.profile_pic
      this.setup()
    }
    this.getRegioni()
  }
}
</script>
<style lang="scss" scoped>
input.form-control:read-only {
  border-bottom: 1px solid darkgrey;
  border-radius: 0px !important;
  box-shadow: none !important;
  color: $body-color;
}
</style>
</script>