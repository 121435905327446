<template>
  <div class="pb-4 mb-4 text-left">
    <h4 class="pb-1 mt-1 mb-4">Manuale d'uso</h4>
    <b-row>
      <b-col>
        <h5>
          <a :href="manualeUrl" target="_blank" class="text-secondary point">
            <u>Scarica la versione aggiornata</u></a
          >
        </h5>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "GuidaCittadino",
  data() {
    return {
      manualeUrl: process.env.VUE_APP_URL_MANUALE_PAZIENTE,
    };
  },
};
</script>
