<template>
  <div class="pt-2">
    <h3 class="mt-2 pt-4 text-left">
      Timeout Sessione
      <b-icon
        class="ml-2 mb-1"
        v-if="!isEditing"
        font-scale="0.8"
        :size="$root.isMobile ? 'sm' : ''"
        type="submit"
        variant="secondary"
        @click="isEditing = true"
        icon="pencil-fill"
      />
    </h3>

    <p class="text-left">
      Stabilisci quando far scadere la sessione. A sessione scaduta, verrai
      automaticamente sloggato.
    </p>

    <b-container fluid class="puntofarmaCard p-4">
      <b-form @submit.prevent="submitNewValue" v-if="isEditing">
        <b-row class="d-flex align-items-center">
          <b-row>
            <b-col class="col-12 d-flex flex-wrap">
              <b-form-checkbox
                class="px-4"
                v-model="isTimeoutAtLogout"
                name="localUserSessionTimeout"
                :readonly="!isEditing"
                :value="true"
              >
                La sessione si chiude con il logout
              </b-form-checkbox>
              <b-form-checkbox
                class="px-4"
                v-model="isTimeoutAtLogout"
                name="localUserSessionTimeout"
                :value="false"
                :readonly="!isEditing"
              >
                La sessione scade dopo
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-input-group
            v-if="!isTimeoutAtLogout"
            class="col-12 col-md-11 col-lg-10 mt-3"
          >
            <b-form-input
              v-if="!isTimeoutAtLogout"
              type="number"
              min="5"
              max="60"
              v-model="localUserSessionTimeout"
              :readonly="!isEditing"
              class="border-top-right-radius-0 border-bottom-right-radius-0"
            ></b-form-input>
            <template #append>
              <b-input-group-text
                class="
                        "
                >Minuti di inattività</b-input-group-text
              >
            </template>
          </b-input-group>
          <p class="mb-0 w-100 text-right pt-3">
            <b-button
              type="button"
              variant="outline-secondary mx-2"
              @click="handleAnnulla()"
              >Annulla</b-button
            >
            <b-button type="submit" variant="secondary mx-2"
              >Salva Modifiche
            </b-button>
          </p>
        </b-row>
      </b-form>
      <div v-else>
        <b-form-input
          type="text"
          v-model="timeoutState"
          readonly
          class="mr-2 col-12 col-md-11 col-lg-10"
        />
      </div>
    </b-container>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { userService } from "@/_services";

export default {
  name: "SessionLengthSetter",
  props: {
    userHash: {
      type: String,
      required: true,
    },
    userSessionTimeout: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isEditing: false,
      isTimeoutAtLogout: false,
      timeoutState: "",
      localUserSessionTimeout: 0,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  watch: {
    isTimeoutAtLogout: function (val) {
      this.timeoutState = val
        ? "La sessione si chiude con il logout"
        : "La sessione scade dopo";

      //   if (val) {
      //     // this.localUserSessionTimeout = 0
      //   } else {
      //     // this.localUserSessionTimeout = this.userSessionTimeout ?? 5
      //   }
    },
    userSessionTimeout: function (val) {
      this.localUserSessionTimeout = val;

      this.timeoutState =
        val === 0
          ? "La sessione si chiude con il logout"
          : `La sessione scade dopo ${this.localUserSessionTimeout} minuti di inattività`;
    },
  },

  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utente", ["setAnagrafica", "me"]),
    handleAnnulla() {
      this.isEditing = false;
      this.localUserSessionTimeout = this.userSessionTimeout;
      this.isTimeoutAtLogout = this.userSessionTimeout === 0;
    },
    submitNewValue() {
      var self = this;
      userService
        .updateUser(this.userHash, {
          session_timeout_minutes: this.isTimeoutAtLogout
            ? 0
            : this.localUserSessionTimeout,
        })
        .then(function (res) {
          self.successAlert("Timeout sessione aggiornato con successo");
          self.isEditing = false;
          self.me();
        })
        .catch(function (err) {
          self.errorAlert(
            "Errore durante l'aggiornamento del timeout della sessione"
          );
        });
    },
  },
  created() {
    this.isTimeoutAtLogout = this.userSessionTimeout === 0;
    this.timeoutState = this.isTimeoutAtLogout
      ? "La sessione si chiude con il logout"
      : `La sessione scade dopo ${this.userSessionTimeout} minuti di inattività`;
    this.localUserSessionTimeout = this.userSessionTimeout;
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
input.form-control:read-only {
  border-bottom: 1px solid darkgrey;
  border-radius: 0px !important;
  box-shadow: none !important;
  color: $body-color;
}
</style>
