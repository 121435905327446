<template>
  <div v-if="subs" class="py-2">
    <h4 class="text-left pb-1">Le tue sottoscrizioni</h4>
    <div v-if="subs.sottoscrizioni">
      <b-row
        cols="2"
        v-for="(sott, ind) in subs.sottoscrizioni"
        :key="ind"
        class="my-2 py-3 puntofarmaCard"
        style="align-items: flex-end !important"
      >
        <b-col class="">
          <img :src="sott.abbonamento.service.logo" alt="service logo" />
          <p class="mb-0 mt-2 text-uppercase">{{ sott.abbonamento.nome }}</p>
          <p class="mb-0">{{ sott.abbonamento.descrizione }}</p>
        </b-col>
        <b-col class="text-left">
          <p class="mb-0" v-if="sott.attivo == 1">
            Abbonamento attivo
            <b-icon icon="circle-fill" variant="success"></b-icon>
          </p>
          <p class="mb-0 text-danger" v-else>
            Abbonamento scaduto
            <b-icon icon="circle-fill" variant="danger"></b-icon>
          </p>
          <p class="mb-0">
            Sottoscritto il
            <strong>{{ sott.data_inizio | moment("DD/MM/YYYY") }}</strong
            >, scadenza il
            <strong>{{ sott.data_fine | moment("DD/MM/YYYY") }}</strong>
          </p>
          <p
            class="mb-0"
            v-if="
              sott.attivo == 1 && sott.abbonamento.service.position_ref == 2
            "
          >
            {{ subs.connessa }} minuti spesi questo mese
          </p>
        </b-col>
      </b-row>
      <p class="text-left" v-if="subs.sottoscrizioni.length == 0">
        Nessuna sottoscrizione.
      </p>
    </div>
    <div v-if="subs.opzioni" class="pt-4">
      <h5 class="text-left pb-1">Opzioni di acquisto</h5>
      <b-row
        :cols="$root.isMobile ? '1' : '2'"
        style="align-items: flex-end !important"
      >
        <b-col v-for="(opt, ind) in subs.opzioni" :key="ind" class="my-2">
          <div class="puntofarmaCard py-2">
            <img :src="opt.service.logo" alt="service logo" />
            <p class="mb-0">{{ opt.nome }}</p>
            <p class="mb-0">{{ opt.descrizione }}</p>
            <p class="mb-0">Valido {{ opt.durata }} mesi</p>
            <p class="mb-0">{{ opt.prezzo }}€ iva esclusa</p>
            <b-button @click="setAcquistoPost(ind)" variant="secondary">
              <span :ref="'spn-' + ind"> Vai all'acquisto </span>
              <b-spinner :ref="'spin-' + ind" hidden></b-spinner>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
  <div v-else>
    <h4>Nessuna sottoscrizione attualmente disponibile</h4>
    <h6>
      <a href="https://concura.online/contatti/"> <u> Contattaci</u></a>
      per avere informazioni.
    </h6>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";
export default {
  name: "Sottoscrizioni",
  props: [],
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  data() {
    return {
      subs: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      companyService
        .getSubscriptions()
        .then(function (resp) {
          // console.log(resp);
          self.subs = resp.data.data;
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le sottoscrizioni");
        });
    },
    setAcquistoPost(ind) {
      var hash = this.subs.opzioni[ind].hashid;
      this.$refs["spin-" + ind][0].hidden = false;
      this.$refs["spn-" + ind][0].hidden = true;
      var self = this;
      companyService
        .gotToBuy(hash)
        .then(function (resp) {
          if (resp.data.data.already) {
            window.scrollTo(0, 0);
            self.infoAlert("Sottoscrizione già attiva!");
            self.$refs["spin-" + ind][0].hidden = true;
            self.$refs["spn-" + ind][0].hidden = false;
          }
          if (resp.data.data.redirect) {
            window.location.href = resp.data.data.redirect;
          }
        })
        .catch(function (error) {
          self.$refs["spin-" + ind][0].hidden = true;
          self.$refs["spn-" + ind][0].hidden = false;
          self.errorAlert("Non è stato possibile recuperare le sottoscrizioni");
        });
    },
  },
  created() {
    // Disattivato fino a determinazione piano tariffario
    // this.fetchData();
  },
};
</script>
