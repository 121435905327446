<template>
  <span>
    <b-button
      class="mr-3 d-flex flex-nowrap align-items-center"
      @click="showModal()"
    >
      <b-icon icon="plus"></b-icon> <span> prenotazione passata</span>
    </b-button>
    <b-modal
      size="xl"
      id="nuova-prenotazione-passata"
      ref="nuova-prenotazione-passata"
      @hidden="resetForm()"
      @close="resetForm()"
      centered
      hide-footer
      title="Nuova prenotazione passata"
      body-class="px-3 pt-0"
    >
      <p>
        Seleziona una prestazione, una data e una fascia oraria per cui inserire
        una prestazione già eseguita.
      </p>

      <b-form @submit.prevent="submitPastReservation()" class="mx-n1 mt-n2">
        <b-row class="flex-wrap">
          <b-form-group
            class="col-12 col-md-6 px-2 mt-2"
            label="Prestazione da inserire*"
          >
            <SelectCompanyHealthServices
              required
              v-model="form.order.health_service_id"
            />
          </b-form-group>
          <b-form-group
            class="col-12 col-md-6 px-2 mt-2"
            label="Data di inserimento*"
          >
            <b-form-datepicker
              label-help="Utilizza i cursori per navigare tra le date"
              placeholder="Seleziona una data"
              start-weekday="1"
              class="mb-2 mr-2"
              locale="it"
              :max="yesterdayDate"
              v-model="pickedDate"
            ></b-form-datepicker>
          </b-form-group>
          <b-form-group
            class="col-12 col-md-6 px-2 mt-2"
            label="Fascia oraria*"
          >
            <b-form-select
              v-capitalize-first-select
              v-model="pickedSlot"
              :options="pickedSlotOptions"
            ></b-form-select>
          </b-form-group>
        </b-row>
        <p class="mb-0 text-right">
          <b-button
            type="submit"
            :disabled="!validForm"
            class="popup-button mt-3"
          >
            Salva
          </b-button>
        </p>
      </b-form>
    </b-modal>
  </span>
</template>

<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { reservationService } from "@/_services";
import SelectCompanyHealthServices from "@/components/prenotazioni/form/SelectCompanyHealthServices.vue";

export default {
  name: "NuovaPrenotazionePassata",
  components: {
    SelectCompanyHealthServices,
  },
  props: ["userinfo", "subjectinfo"],
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      operatore: (state) => state.utente.user,
    }),
    yesterdayDate() {
      return moment().subtract(1, "days").format("YYYY-MM-DD");
    },
    validForm() {
      return (
        this.form.order.health_service_id &&
        this.form.data_inizio &&
        this.pickedDate &&
        this.pickedSlot
      );
    },
  },
  watch: {
    pickedSlot(val) {
      this.form.data_inizio = this.pickedDate + " " + val;
    },
    pickedDate(val) {
      this.form.data_inizio = val + " " + this.pickedSlot;
    },
  },
  data() {
    return {
      type: "prestazione",
      form: {
        company_id: null,
        service_id: null,
        data_inizio: null,
        data_fine: null,
        tipologia: "ordine",
        type: "prestazione",
        order: {
          health_service_id: null,
        },
        subject_id: null,
        subject_type: "user_in_app",
      },
      pickedDate: null,
      pickedSlot: null,
      pickedSlotOptions: [
        {
          value: null,
          text: "Seleziona una fascia oraria",
        },
        {
          value: "09:00",
          text: "mattina",
        },
        {
          value: "14:00",
          text: "pomeriggio",
        },
        {
          value: "18:00",
          text: "sera",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utente", ["me"]),
    submitPastReservation() {
      this.$bvModal.hide("nuova-prenotazione-passata");
      var self = this;
      reservationService
        .storeReservation(self.form)
        .then((res) => {
          self.successAlert("Prenotazione inserita con successo");
          self.$emit("new-reservation");
        })
        .catch((err) => {
          self.errorAlert("Errore nell'inserimento della prenotazione");
        });
    },
    showModal() {
      this.$refs["nuova-prenotazione-passata"].show();
    },
    resetForm() {
      this.form.data_inizio = null;
      this.form.order.health_service_id = null;
      this.pickedDate = null;
      this.pickedSlot = null;
    },
  },
  created() {},
  mounted() {
    this.form.service_id =
      this.operatore.company.servizi_attivi["prenotazioni"];
    this.form.company_id = this.operatore.company.hashid;
    this.form.subject_id = this.userinfo.hashid;
  },
};
</script>
<style lang="scss">
.select__dis:disabled {
  background-color: #fff !important;
  color: inherit !important;
}
.table-text-grey {
  background-color: #fff !important;
}
</style>
