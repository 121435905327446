<template>
  <b-overlay :show="loading" rounded="sm">
    <b-row class="my-2">
      <template v-if="frequencies !== null">
        <b-col cols="12" class="px-0" v-if="frequencies.length > 0">
          <b-row
            v-for="freq in frequencies"
            :key="freq.hashid"
            class="justify-content-between"
          >
            <span v-if="freq.enabled">
              <span>
                <b-icon
                  font-scale="1.3"
                  icon="circle-fill"
                  variant="success"
                ></b-icon>
              </span>
              Frequenza rilevazione: una valutazione ogni
              <span v-if="freq.every_num > 1">
                {{ freq.every_num }} {{ everyUmsPlural[freq.every_um] }}
              </span>
              <span v-else>{{ everyUmsSingular[freq.every_um] }} </span>
            </span>
            <span v-else>
              <span>
                <b-icon
                  font-scale="1.3"
                  icon="circle-fill"
                  variant="orange"
                ></b-icon>
              </span>
              Valutazione sospesa
            </span>
            <span @click="selectedFrequency = freq">
              <!-- <b-button
                variant="outline-secondary"
                size="sm"
                class="mx-1"
                title="modifica"
                @click="$bvModal.show('update-freq')"
              >
                <b-icon icon="pencil"></b-icon>
              </b-button> -->
              <b-iconstack
                title="modifica"
                font-scale="2.2"
                class="point mx-1"
                @click="$bvModal.show('update-freq')"
              >
                <b-icon
                  stacked
                  icon="circle-fill"
                  variant="mdm-light-grey"
                ></b-icon>
                <b-icon
                  stacked
                  icon="pencil-fill"
                  scale="0.5"
                  variant="secondary"
                ></b-icon>
              </b-iconstack>
              <template v-if="freq.enabled">
                <b-iconstack
                  @click="$bvModal.show('update-freqstats')"
                  title="sospendi"
                  font-scale="2.2"
                  class="point mx-1"
                >
                  <b-icon
                    stacked
                    icon="circle-fill"
                    variant="mdm-light-grey"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="pause-fill"
                    scale="0.5"
                    variant="secondary"
                  ></b-icon>
                </b-iconstack>
              </template>
              <template v-else>
                <b-iconstack
                  font-scale="2.2"
                  class="point"
                  title="riprendi"
                  @click="$bvModal.show('update-freqstats')"
                >
                  <b-icon
                    stacked
                    icon="circle-fill"
                    variant="mdm-light-grey"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="play-fill"
                    scale="0.5"
                    variant="secondary"
                  ></b-icon>
                </b-iconstack>
              </template>
              <b-iconstack
                font-scale="2.2"
                class="point ml-1"
                title="elimina"
                @click="$bvModal.show('delete-freq')"
              >
                <b-icon
                  stacked
                  icon="circle-fill"
                  variant="mdm-light-grey"
                ></b-icon>
                <b-icon
                  stacked
                  icon="trash-fill"
                  scale="0.5"
                  variant="secondary"
                ></b-icon>
              </b-iconstack>
            </span>
          </b-row>
        </b-col>
        <b-button
          v-else
          variant="secondary"
          class="ml-auto mr-0"
          size="sm"
          @click="$bvModal.show('add-freq')"
          >Imposta frequenza valutazione</b-button
        >
      </template>
      <b-modal
        id="add-freq"
        title="Nuova frequenza monitoraggio"
        centered
        @ok="storeReference"
        @cancel="$bvModal.hide('add-freq')"
        :ok-disabled="newFrequency.every_num == 0"
        ok-title="Salva frequenza"
        ok-variant="popup-button"
        cancel-title="Annulla"
        cancel-variant="popup-button"
        footer-border-variant="white"
      >
        <h6>E' necessario rilevare il parametro ogni</h6>
        <b-row align-v="end">
          <b-col>
            <b-form-input
              type="number"
              v-model="newFrequency.every_num"
            ></b-form-input>
          </b-col>
          <b-col class="cols-12 cols-md-6">
            <b-form-select
              v-capitalize-first-select
              v-model="newFrequency.every_um"
            >
              <b-form-select-option
                v-for="k in Object.keys(everyUmsPlural)"
                :value="k"
                :key="everyUmsPlural[k]"
              >
                {{ everyUmsPlural[k] }}
              </b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        id="delete-freq"
        title="Modifica operazione"
        centered
        @ok="doDelete"
        @cancel="$bvModal.hide('delete-freq')"
        :ok-disabled="selectedFrequency == null"
        ok-title="Sì, elimina"
        ok-variant="popup-button"
        cancel-title="No, annulla"
        cancel-variant="popup-button"
        footer-border-variant="white"
      >
        <div>
          <h6>Vuoi eliminare questa frequenza di monitoraggio?</h6>
        </div>
      </b-modal>
      <b-modal
        id="update-freq"
        title="Modifica impostazione"
        centered
        @ok="updateFrequency"
        @cancel="$bvModal.hide('update-freq')"
        :ok-disabled="selectedFrequency == null"
        ok-title="Salva modifiche"
        ok-variant="popup-button"
        cancel-title="Annulla"
        cancel-variant="popup-button"
        footer-border-variant="white"
      >
        <h6>E' necessario rilevare il parametro ogni</h6>
        <b-row align-v="end">
          <b-col>
            <b-form-input
              type="number"
              v-model="updateForm.every_num"
            ></b-form-input>
          </b-col>
          <b-col class="cols-12 cols-md-6">
            <b-form-select
              v-capitalize-first-select
              v-model="updateForm.every_um"
            >
              <b-form-select-option
                v-for="k in Object.keys(everyUmsPlural)"
                :value="k"
                :key="everyUmsPlural[k]"
              >
                {{ everyUmsPlural[k] }}
              </b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        id="update-freqstats"
        title="Conferma operazione"
        centered
        @ok="updateStatus"
        @cancel="$bvModal.hide('update-freqstats')"
        :ok-disabled="selectedFrequency == null"
        ok-title="Conferma"
        ok-variant="popup-button"
        cancel-title="Annulla"
        cancel-variant="popup-button"
        footer-border-variant="white"
      >
        <div v-if="selectedFrequency">
          <h6>
            Vuoi
            {{ selectedFrequency.enabled ? "disattivare" : "attivare" }} questa
            frequenza di monitoraggio?
          </h6>
        </div>
      </b-modal>
    </b-row>
  </b-overlay>
</template>
<script>
import { monitoringService } from "@/_services"
import { mapState, mapActions } from "vuex"
export default {
  name: "FrequenzaValutazioneScore",
  props: ["score", "subject"],
  data() {
    return {
      params: {
        user: this.subject
      },
      loading: true,
      frequencies: null,
      selectedFrequency: null,
      everyUmsPlural: {
        0: "mesi",
        1: "settimane",
        2: "giorni",
        3: "ore",
        4: "minuti"
      },
      everyUmsSingular: {
        0: "mese",
        1: "settimana",
        2: "giorno",
        3: "ora",
        4: "minuto"
      },
      updateForm: {
        every_num: null,
        every_um: null
      },
      newFrequency: {
        every_num: 1,
        every_um: 2,
        user_id: this.subject
      }
    }
  },
  watch: {
    selectedFrequency: function (val) {
      this.updateForm.every_um = val.every_um
      this.updateForm.every_num = val.every_num
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    })
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      const self = this
      monitoringService
        .getUserScoreFrequencies(this.score, this.params)
        .then(function (response) {
          self.frequencies = response.data.data
          self.loading = false
        })
        .catch(function (error) {
          self.loading = false
          if (error.status !== 404) {
            window.scrollTo(0, 0)
            self.errorAlert("qualcosa è andata storta")
          }
        })
    },
    storeReference() {
      console.log("STORE", this.newFrequency)
      const self = this
      monitoringService
        .storeUserScoreFrequency(this.score, this.newFrequency)
        .then(function (response) {
          self.fetchData()
          self.successAlert("Valori impostati correttamente")
          // self.$emit('need-update')
        })
        .catch(function (error) {
          window.scrollTo(0, 0)
          self.errorAlert("qualcosa è andata storta")
        })
    },
    updateFrequency() {
      this.loading = true
      const self = this
      monitoringService
        .updateUserScoreFrequency(
          this.selectedFrequency.hashid,
          this.updateForm
        )
        .then(function (response) {
          self.fetchData()
          // self.$emit('need-update');
          self.infoAlert("Impostazione di frequenza modificate correttamente")
          self.loading = false
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile modificare la frequenza")
        })
    },
    updateStatus() {
      this.loading = true
      const self = this
      const formData = {
        enabled: !this.selectedFrequency.enabled
      }
      monitoringService
        .updateUserScoreFrequencyStatus(this.selectedFrequency.hashid, formData)
        .then(function (response) {
          self.fetchData()
          // self.$emit('need-update');
          self.infoAlert("Impostazione di frequenza modificate correttamente")
          self.loading = false
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile modificare la frequenza")
        })
    },
    doDelete() {
      this.loading = true
      const self = this
      monitoringService
        .deleteUserScoreFrequency(this.selectedFrequency.hashid)
        .then(function (response) {
          self.fetchData()
          // self.$emit('need-update');
          self.infoAlert("Impostazione di frequenza eliminata correttamente")
          self.loading = false
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile eliminare la frequenza")
        })
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {}
}
</script>
