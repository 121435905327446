<template>
  <b-container fluid class="text-left px-1">
    <b-row class="d-flex justify-content-between">
      <h5 class="text-left mb-4" style="font-size: 1.2rem">
        Altri Questionari
      </h5>
      <span
        @click="
          $router.push({
            name: 'attivita',
            params: {
              section: 'questionari',
              quest: subject.hashid,
              action: 'utente',
            },
          })
        "
        class="ml-auto text-secondary"
        style="font-size: 1.2rem"
      >
        <span class="underlined point mr-2"> Vedi storico questionari</span>
        <b-icon icon="collection"></b-icon>
      </span>
    </b-row>

    <b-row class="mb-4">
      <b-col
        v-if="surveys.length > 0"
        class="col-12 col-xl-9 puntofarmaCard bg-white pt-2 pl-4 pr-4 pb-2"
      >
        <div v-for="survey in surveys" :key="survey.id">
          <b-row class="d-flex align-items-center">
            <b-col class="col-12 col-md-8">
              <h6 class="pt-3" font-size="1rem;">{{ survey.survey_name }}</h6>

              <span class="text-secondary fs-5">
                {{ survey.created_at | moment("DD/MM/YYYY HH:mm") }}
              </span>
            </b-col>
            <b-col class="col-12 col-md-4">
              <b-row
                align-v="center"
                class="d-flex justify-content-start justify-content-md-end mb-4 mb-md-0"
              >
                <b-iconstack
                  font-scale="2.5"
                  class="point m-1"
                  v-b-popover.hover.top="'Vedi'"
                  @click="showThis(survey)"
                >
                  <b-icon
                    stacked
                    icon="circle-fill"
                    variant="mdm-light-grey"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="zoom-in"
                    scale="0.5"
                    variant="secondary"
                  ></b-icon>
                </b-iconstack>
                <template
                  v-if="
                    user.permessi.includes('gestire attività e questionari')
                  "
                >
                  <b-iconstack
                    font-scale="2.5"
                    class="point m-1"
                    v-b-popover.hover.top="'Modifica'"
                    @click="
                      $router.push({
                        name: 'attivita',
                        params: {
                          quest: survey.hashid,
                          section: 'questionario',
                          action: 'edit',
                        },
                        query: { fromStorico: true },
                      })
                    "
                  >
                    <b-icon
                      stacked
                      icon="circle-fill"
                      variant="mdm-light-grey"
                    ></b-icon>
                    <b-icon
                      stacked
                      icon="pencil-square"
                      scale="0.5"
                      variant="secondary"
                    ></b-icon>
                  </b-iconstack>

                  <b-iconstack
                    font-scale="2.5"
                    class="point m-1"
                    v-b-popover.hover.top="'Elimina'"
                    @click="showDeleteThis(survey)"
                  >
                    <b-icon
                      stacked
                      icon="circle-fill"
                      variant="mdm-light-grey"
                    ></b-icon>
                    <b-icon
                      stacked
                      icon="trash"
                      scale="0.5"
                      variant="secondary"
                    ></b-icon>
                  </b-iconstack>
                </template>

                <b-dropdown
                  size="sm"
                  variant="mdm-light-grey text-secondary"
                  right
                  class="secondary-dropdown m-1 py-2 cippalippa"
                  v-b-popover.hover.top="'Scarica'"
                >
                  <template #button-content>
                    <b-icon
                      variant="secondary"
                      icon="download"
                      font-scale="1.5"
                    ></b-icon>
                  </template>
                  <b-dropdown-item
                    class="secondary-li-hover"
                    @click="downloadOne(survey)"
                    >PDF Standard</b-dropdown-item
                  >
                  <b-dropdown-item
                    class="secondary-li-hover"
                    @click="downloadPatientVersion(survey)"
                    >PDF per il paziente</b-dropdown-item
                  >
                </b-dropdown>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col v-else class="col-12 col-xl-9 puntofarmaCard bg-white p-4">
        <p>Nessun questionario compilato</p>
      </b-col>
    </b-row>
    <b-modal
      ref="show-filleditem"
      centered
      hide-footer
      title="Questionario"
      size="xl"
    >
      <FilledSurveyShower
        v-if="selectedItem"
        :response="selectedItem.response"
        :score="selectedItem.score"
      />
    </b-modal>

    <b-modal
      id="confirm-delete"
      centered
      title="Confermi di voler eliminare il questionario?"
      ok-title="Sì, elimina"
      ok-variant="popup-button"
      cancel-title="No, annulla"
      cancel-variant="popup-button"
      footer-border-variant="white"
      @ok="deleteSelectedItem"
      @cancel="cancelDelete"
    >
      <h6 v-if="selectedItem" class="px-2 py-3">
        Se confermi, il questionario selezionato compilato in data
        {{ selectedItem.created_at | moment("DD/MM/YYYY") }} verrà eliminato
        definitivamente.
      </h6>
    </b-modal>
  </b-container>
</template>

<script>
import { supportService, cittadinoService } from "@/_services";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue";

export default {
  name: "UserLastSurveys",
  props: {
    subject: {
      type: Object,
      required: true,
    },
    subjectAnagrafica: {
      type: Object,
    },
  },
  components: {
    FilledSurveyShower,
  },
  data() {
    return {
      surveys: [],
      filters: {
        take: 5,
        noscore: true,
      },
      selectedItem: null,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    showThis(row) {
      this.selectedItem = row;
      this.$refs["show-filleditem"].show();
    },
    fetchSurveys() {
      var self = this;
      cittadinoService
        .getUserFilledSurveys(this.subject.hashid, this.filters)
        .then(function (res) {
          self.surveys = res.data.data.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    convertDateTime(dateTime) {
      return moment(dateTime).format("DD/MM/YYYY [alle] HH:mm");
    },
    showDeleteThis(row) {
      this.selectedItem = row;
      this.$bvModal.show("confirm-delete");
    },
    deleteSelectedItem() {
      console.log("deleteSelectedItem", this.selectedItem);
      const self = this;
      supportService
        .deleteFilledSurvey(this.selectedItem.hashid)
        .then(function (res) {
          self.successAlert("Questionario eliminato correttamente");
          self.fetchSurveys();
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile eliminare il questionario");
        });
    },
    cancelDelete() {
      this.selectedItem = null;
      this.$bvModal.hide("confirm-delete");
    },
    downloadOne(row) {
      const self = this;
      var selected = row;
      supportService
        .downloadFilledSurvey(selected.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = selected.survey_name + ".pdf";
          link.click();
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    downloadPatientVersion(row) {
      const self = this;
      var selected = row;
      supportService
        .downloadFilledSurveyPatientVersion(selected.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            "patient_" +
            self.subjectAnagrafica.cf.toUpperCase() +
            "_" +
            selected.survey_name +
            ".pdf";
          link.click();
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
  },
  created() {
    this.fetchSurveys();
  },
};
</script>
