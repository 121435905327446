<template>
  <b-container fluid>
    <b-form @submit.prevent="saveSchedule">
      <b-row
        cols="1"
        class="my-2 col-12 px-0 align-items-center align-content-center flex-wrap my-2"
      >
        <b-col class="px-0">
          <h4 class="text-left pt-1 pb-4 mb-2">Tipologia Alert</h4>
          <b-row
            class="puntofarmaCard text-left puntofarmaCard p-lg-4 p-md-3 p-2"
          >
            <b-col>
              <b-form-group
                class=""
                label="Seleziona la tipologia di notifica da impostare* "
                :description="alertableTypesDecriptions[selectedAlertable]"
              >
                <b-form-radio-group
                  id="radio-alertable"
                  v-model="selectedAlertable"
                  name="radio-alertable"
                >
                  <b-form-radio
                    class="mb-2 mb-lg-"
                    v-for="(type, ind) in alertableTypes"
                    :key="ind"
                    :value="type.value"
                    >{{ type.label }}</b-form-radio
                  >
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <SelectTherapy
          v-if="selectedAlertable === 'type1'"
          :subject="subject"
          v-on:selected-therapy="selectTherapy($event)"
        />
        <b-col v-if="selectedAlertable === 'type2'" class="px-0">
          <h4 class="text-prim-grad-1 text-left mt-4 py-2 pb-3">
            Seleziona una prestazione sanitaria
          </h4>
          <!-- 
          <PrestazioneSelection
            class="px-2"
            :company="selectedCompany"
            :donotexclude="true"
            v-on:selected-prestazione="selectHealthService($event)"
            :preselected="preselectedService"
          /> -->

          <SelectCompanyHealthServices
            v-model="selectedPrestazioneHash"
            @input="selectHealthService"
            @selected-new-health-service="selectHealthService($event)"
            :showDescriptions="true"
            :startWithHash="pickedHashFromRoute"
          />
        </b-col>
      </b-row>
      <!-- ALERT TERAPIA-TYPE1 -->
      <template v-if="selectedTherapy">
        <b-row cols="1" class="my-2 pt-1">
          <h4 class="text-prim-grad-1 text-left mt-4 pt-1 pb-4 mb-2">
            Dettaglio Terapia
          </h4>
          <div class="text-left puntofarmaCard p-lg-4 p-md-3 p-2">
            <b-col>
              <p class="text-medium-grey mb-0 underlined text-justify">
                Specifica la quantità di farmaco di cui dispone il paziente ed
                indica la data della prima somministrazione affinché il sistema
                possa calcolare correttamente i giorni in cui inviare la
                notifica.
              </p>
              <p class="pt-4 pb-2 text-secondary text-justify">
                <strong>
                  La terapia selezionata prevede
                  {{ posNum }}
                  {{
                    posNum > 1
                      ? selectedTherapy.posology_type
                      : typesMap[selectedTherapy.posology_type]
                  }}
                  di {{ selectedTherapy.product_name }}
                  {{ selectedTherapy.posologia.tipo }}
                  <span v-if="selectedTherapy.posologia.frequenza">
                    {{ selectedTherapy.posology_every_num }}
                    {{ selectedTherapy.posologia.frequenza }}
                  </span>
                  per via
                  <span class="text-lowercase"
                    >{{ selectedTherapy.posology_via }}.
                  </span>
                </strong>
              </p>
            </b-col>
            <b-col class="px-0">
              <b-row cols="1" class="">
                <b-col class="my-1">
                  <b-form-group class="col-12 col-md-5 col-lg-3 px-0">
                    <label class="mb-3" for=""
                      >Confezioni consegnate al paziente*</label
                    >
                    <b-input-group class=" " style="border-radius: 0.7rem">
                      <b-form-input
                        id="num-conf"
                        name="num-conf"
                        type="number"
                        min="1"
                        v-model="form.confezioni"
                        required
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col class="my-1">
                  <b-form-group class="col-12 col-md-5 col-lg-3 px-0">
                    <label class="mb-3" for=""
                      >{{ selectedTherapy.posology_type }} per
                      confezione*</label
                    >
                    <b-input-group class=" " style="border-radius: 0.7rem">
                      <b-form-input
                        id="num-conf"
                        name="num-conf"
                        type="number"
                        min="1"
                        v-model="form.uic"
                        required
                      >
                      </b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col class="my-1">
                  <b-form-group class="col-12 col-md-5 col-lg-3 px-0">
                    <label class="mb-3" for="">Data inizio terapia*</label>
                    <b-form-input
                      class=" "
                      name="start_at"
                      type="date"
                      v-model="form.start_at"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-row>
        <h4 class="text-prim-grad-1 text-left pb-4 mt-5">Pianificazione</h4>
        <div class="m-0 p-0 puntofarmaCard p-lg-4 p-md-3 p-2">
          <b-row cols="1" class="my-2 pt-1">
            <b-col>
              <p class="text-medium-grey text-justify">
                Il sistema invierà al paziente una notifica in prossimità
                dell'esaurimento delle sue scorte di farmaco. Seleziona una o
                più opzioni relative a frequenza e canale di notifica, e indica
                la fascia oraria di preferenza.
              </p>
            </b-col>
          </b-row>
          <b-row cols="2" class="my-3 mt-2">
            <b-col class="">
              <b-form-group
                class=""
                label="Invia notifica*"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox-group
                  id="checkbox-freq"
                  v-model="form.schedule"
                  :options="
                    selectedAlertable === 'type2'
                      ? scheduleOptions
                      : scheduleTherapyOptions
                  "
                  :aria-describedby="ariaDescribedby"
                  :state="form.schedule.length > 3 ? false : null"
                  name="checkbox-freq"
                  stacked
                ></b-form-checkbox-group>
                <span
                  :class="
                    form.schedule.length > 3 ? 'text-danger' : 'text-font-grey'
                  "
                  >**min 1 opzione, max 3 opzioni</span
                >
              </b-form-group>
            </b-col>
            <b-col class="">
              <b-form-group class="" label="Via*" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="checkbox-channel"
                  v-model="form.channels"
                  :options="channels"
                  :aria-describedby="ariaDescribedby"
                  name="checkbox-channel"
                  stacked
                ></b-form-checkbox-group>
                <span class="text-font-grey">**min 1 opzione</span>
              </b-form-group>
            </b-col>
            <b-col class="col-12 mt-4">
              <b-form-group
                class=""
                label="Il paziente riceverà la notifica* "
                :description="timingDescription"
              >
                <b-form-radio-group
                  id="radio-timing"
                  v-model="form.timing"
                  name="radio-timing"
                >
                  <b-form-radio :value="0">al mattino</b-form-radio>
                  <b-form-radio :value="1">alla sera</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-col>
            <p class="fw-600 text-left pt-3">Note per il paziente</p>
            <p class="text-medium-grey pb-2">
              L'eventuale nota sarà aggiunta alla notifica inviata al paziente,
              ad eccezione degli sms.
            </p>
          </b-col>
          <b-col class="col-12 col-md-10 mt-2">
            <b-form-group>
              <b-form-textarea
                v-capitalize-first
                style="overflow-y: auto"
                class=" "
                v-model="form.company_notes"
                placeholder="Se vuoi, aggiungi una nota per il paziente..."
                rows="2"
                max-rows="2"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col>
            <div class="text-left my-3">
              <p>*parametri obbligatori</p>
            </div>
          </b-col>
          <b-row cols="2" class="d-flex justify-content-around col-10">
            <b-col class="col-12 col-md-6 my-3 text-center">
              <b-button
                type="button"
                variant="outline-secondary"
                class=""
                @click="
                  $router.push({
                    name: 'scheda paziente',
                    params: { hash: $route.params.hash }
                  })
                "
                >Annulla</b-button
              >
            </b-col>
            <b-col class="col-12 col-md-6 my-3 text-center">
              <b-button type="submit" variant="secondary" class="text-white"
                >Salva pianificazione
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
      <!-- ALERT TERAPIA-TYPE1 -->
      <!-- ALERT PRESTAZIONE-TYPE2 -->
      <template v-if="selectedPrestazioneHash">
        <b-row cols="1" class="my-2 pt-1">
          <h4 class="text-prim-grad-1 text-left mt-4 pt-1 pb-4 mb-2">
            Dettaglio Recall
          </h4>
          <div class="text-left puntofarmaCard p-lg-4 p-md-3 p-2">
            <b-col>
              <p class="text-medium-grey mb-0 underlined text-justify">
                Specifica la data in cui si prevede che il paziente debba
                tornare per la prestazione.
              </p>
            </b-col>
            <b-col class="px-0">
              <b-row cols="1" class="">
                <b-col class="my-1">
                  <b-form-group class="col-12 col-md-5 col-lg-3 px-0">
                    <label class="mb-3" for="">Data follow up*</label>
                    <b-form-input
                      class=" "
                      name="end_at"
                      type="date"
                      v-model="form.end_at"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-row>
        <h4 class="text-prim-grad-1 text-left pb-4 mt-5">Pianificazione</h4>
        <div class="m-0 p-0 puntofarmaCard p-lg-4 p-md-3 p-2">
          <b-row cols="1" class="my-2 pt-1">
            <b-col>
              <p class="text-medium-grey text-justify">
                Il sistema invierà al paziente una notifica in prossimità della
                data prevista per il follow up, invitandolo a prenotare la
                prestazione. Seleziona una o più opzioni relative a frequenza e
                canale di notifica, e indica la fascia oraria di preferenza.
              </p>
            </b-col>
          </b-row>
          <b-row cols="2" class="my-3 mt-2">
            <b-col class="">
              <b-form-group
                class=""
                label="Invia notifica*"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox-group
                  id="checkbox-freq"
                  v-model="form.schedule"
                  :options="scheduleOptions"
                  :aria-describedby="ariaDescribedby"
                  :state="form.schedule.length > 3 ? false : null"
                  name="checkbox-freq"
                  stacked
                ></b-form-checkbox-group>
                <span
                  :class="
                    form.schedule.length > 3 ? 'text-danger' : 'text-font-grey'
                  "
                  >**min 1 opzione, max 3 opzioni</span
                >
              </b-form-group>
            </b-col>
            <b-col class="">
              <b-form-group class="" label="Via*" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="checkbox-channel"
                  v-model="form.channels"
                  :options="channels"
                  :aria-describedby="ariaDescribedby"
                  name="checkbox-channel"
                  stacked
                ></b-form-checkbox-group>
                <span class="text-font-grey">**min 1 opzione</span>
              </b-form-group>
            </b-col>
            <b-col class="col-12 mt-4">
              <b-form-group
                class=""
                label="Il paziente riceverà la notifica* "
                :description="timingDescription"
              >
                <b-form-radio-group
                  id="radio-timing"
                  v-model="form.timing"
                  name="radio-timing"
                >
                  <b-form-radio :value="0">al mattino</b-form-radio>
                  <b-form-radio :value="1">alla sera</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-col>
            <p class="fw-600 text-left pt-3">Note per il paziente</p>
            <p class="text-medium-grey pb-2">
              L'eventuale nota sarà aggiunta alla notifica inviata al paziente,
              ad eccezione degli sms.
            </p>
          </b-col>
          <b-col class="col-12 col-md-10 mt-2">
            <b-form-group>
              <b-form-textarea
                v-capitalize-first
                style="overflow-y: auto"
                class=" "
                v-model="form.company_notes"
                placeholder="Se vuoi, aggiungi una nota per il paziente..."
                rows="2"
                max-rows="2"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col>
            <div class="text-left my-3">
              <p>*parametri obbligatori</p>
            </div>
          </b-col>
          <b-row cols="2" class="d-flex justify-content-around col-10">
            <b-col class="col-12 col-md-6 my-3 text-center">
              <b-button
                type="button"
                variant="outline-secondary"
                class=""
                @click="
                  $router.push({
                    name: 'scheda paziente',
                    params: { hash: $route.params.hash }
                  })
                "
                >Annulla</b-button
              >
            </b-col>
            <b-col class="col-12 col-md-6 my-3 text-center">
              <b-button type="submit" variant="secondary" class="text-white"
                >Salva pianificazione
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
      <!-- ALERT PRESTAZIONE-TYPE2 -->
    </b-form>
  </b-container>
</template>
<script>
import * as moment from "moment/moment"
import { utilityService, supportService } from "@/_services"
import SelectTherapy from "@/components/profile/sanitario/SelectTherapy.vue"
import PrestazioneSelection from "@/components/prenotazioni/form/PrestazioneSelection.vue"
import { mapState, mapActions } from "vuex"
import SelectCompanyHealthServices from "@/components/prenotazioni/form/SelectCompanyHealthServices.vue"

export default {
  name: "NewAlert",
  props: ["subject", "atype"],
  components: { SelectTherapy, SelectCompanyHealthServices },
  data() {
    return {
      form: {
        type: 0,
        user_id: null,
        alertable_id: null,
        alertable_type: null,
        channels: [0],
        schedule: [1],
        confezioni: 1,
        uic: 10,
        start_at: null,
        end_at: null,
        timing: 1,
        company_notes: null
      },
      selectedAlertable: "type1",
      alertableMappedTypes: {
        type1: "terapia",
        type2: "prestazione sanitaria"
      },
      alertableTypes: [
        { label: "alert esaurimento farmaco", value: "type1" },
        {
          label: "recall prestazione sanitaria",
          value: "type2"
        }
      ],
      alertableTypesDecriptions: {
        type1:
          "notifica al paziente quando la sua scorta di farmaco per una terapia sta per terminare",
        type2: "ricorda al paziente di prenotare una prestazione sanitaria"
      },
      selectedHealthService: null,
      selectedTherapy: null,
      channels: [
        { text: "email", value: 0, disabled: false },
        { text: "telegram/whatsapp", value: 2, disabled: true },
        { text: "sms", value: 1, disabled: true },
        { text: "chiamata operatore", value: 3, disabled: true }
      ],
      scheduleOptions: [
        { text: "1 giorno prima", value: 1, disabled: false },
        { text: "2 giorni prima", value: 2, disabled: false },
        { text: "3 giorni prima", value: 3, disabled: false },
        { text: "5 giorni prima", value: 5, disabled: false },
        { text: "10 giorni prima", value: 10, disabled: false },
        { text: "15 giorni prima", value: 15, disabled: false },
        { text: "20 giorni prima", value: 20, disabled: false },
        { text: "30 giorni prima", value: 30, disabled: false }
      ],
      scheduleTherapyOptions: [
        { text: "1 giorno prima", value: 1, disabled: false },
        { text: "2 giorni prima", value: 2, disabled: false },
        { text: "3 giorni prima", value: 3, disabled: false },
        { text: "5 giorni prima", value: 5, disabled: false }
      ],
      typesMap: {
        Compresse: "compressa",
        Capsule: "capsula",
        Gocce: "goccia",
        Inalazioni: "inalazione",
        Buste: "busta",
        Supposte: "supposta",
        Scatole: "scatola",
        Cerotti: "cerotto",
        Fiale: "fiala",
        Fleboclisi: "fleboclisi",
        Ovuli: "ovulo",
        Sacche: "sacca",
        Flaconi: "flacone",
        Confezioni: "confezione",
        Kilogrammi: "kilogrammo",
        Kit: "kit",
        Millilitri: "millilitro",
        Milligrammi: "milligrammo",
        Applicazioni: "applicazione",
        UI: "UI"
      },
      pickedHashFromRoute: null,
      forEveryUmsSing: {
        0: "mese",
        1: "settimana",
        2: "giorno"
      },
      forEveryUmsPlur: {
        0: "mesi",
        1: "settimane",
        2: "giorni"
      },
      selectedCompany: null,
      preselectedService: null,
      selectedPrestazioneHash: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"]),
    posNum: function () {
      return this.selectedTherapy ? this.selectedTherapy.posology_num * 1 : 0
    },
    timingDescription: function () {
      let time = this.form.timing === 1 ? "20:00" : "8:00"
      return "la notifica verrà inviata intorno alle ore " + time
    }
  },
  watch: {
    selectedAlertable: function (value) {
      this.form.alertable_type = this.alertableMappedTypes[value]
      if (this.selectedAlertable === "type1") {
        this.selectedHealthService = null
      } else if (this.selectedAlertable === "type2") this.selectedTherapy = null

      // console.log(this.form.alertable_type);
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    formatData(data) {
      return moment(data).format("DD MMM YYYY")
    },
    selectTherapy(therapy) {
      this.selectedTherapy = therapy
      this.form.alertable_id = therapy.hashid
      this.form.alertable_type = "terapia"
      this.form.start_at = therapy.start_at
      this.form.confezioni = 1
      this.form.uic = 10
    },
    selectHealthService() {
      this.form.alertable_id = this.selectedPrestazioneHash
      this.form.confezioni = 0
      this.form.uic = 0
      this.form.start_at = moment().format("yyyy-MM-DD")
    },
    saveSchedule() {
      if (this.form.schedule.length > 3) {
        return false
      }
      const self = this
      supportService
        .storeAlerts(this.form)
        .then(function (response) {
          // console.log(response.data.data);
          if (response.data.data.error) {
            window.scrollTo(0, 0)
            self.errorAlert(response.data.data.error)
          } else {
            self.$emit("save-alerts", response.data.data)
            self.successAlert("Notifica salvata")
          }
        })
        .catch(function (error) {
          console.log(error)
          self.errorAlert("Si è verificato un errore durante il salvataggio")
        })
    }
  },
  created() {
    if (this.subject) {
      this.form.user_id = this.subject.hashid
    }
    if (this.$route.query.atype) {
      this.selectedAlertable = this.$route.query.atype
    }
  },
  mounted() {
    var today = moment()
    this.form.start_at = today.format("YYYY-MM-DD")
    this.selectedCompany = this.user.company
    if (this.$route.query.hserv) {
      this.pickedHashFromRoute = this.$route.query.hserv
    }
  }
}
</script>
