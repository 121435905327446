<template>
  <div>
    <SearchCompanyFilters @update-filters="handleUpdateFilters($event)" />
    <div v-if="!loading" class="company-cards-container" ref="cardContainer">
      <template v-if="availableResCompanies.length > 0">
        <CompanyReservationCard
          v-for="(companyresdata, ind) in availableResCompanies"
          :key="ind"
          class="company-card"
          :data="companyresdata"
          :company="
            resData.type === 'prestazione'
              ? companyresdata.company
              : companyresdata
          "
          :pickedHSName="selectedData.prestazioneName"
          :show-heart="false"
          :showConvenzionataBadge="true"
          :is-selected="enlight(companyresdata)"
          @selected-rescompany="selectedResCompany($event)"
        />
        <!-- :is-selected="
            resData.type === 'prestazione'
              ? companyresdata.company.hashid ===
                  selectedData?.company?.hashid &&
                companyresdata.healthService.hashid ===
                  selectedData?.healthService?.hashid
              : companyresdata?.hashid === selectedData?.company?.hashid
          " -->
      </template>
      <template v-else>
        <div class="text-center w-100">
          <p class="text-secondary">
            Nessuna struttura disponibile per la prenotazione
          </p>
        </div>
      </template>
    </div>
    <b-spinner v-else variant="secondary" class="ml-2"></b-spinner>
  </div>
</template>

<script>
import { reservationService, supportService } from "@/_services"
import CompanyReservationCard from "@/components/prenotazioni/CompanyReservationCard.vue"
import SearchCompanyFilters from "@/components/utilities/SearchCompanyFilters.vue"

export default {
  name: "Presso",
  props: {
    selectedData: {
      type: Object,
      required: true
    },
    resData: {
      type: Object,
      required: true
    }
  },
  components: {
    CompanyReservationCard,
    SearchCompanyFilters
  },
  data() {
    return {
      availableResCompanies: [],
      filters: {},
      loading: false
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustContainerHeight)
  },
  updated() {
    this.$nextTick(this.adjustContainerHeight)
  },
  methods: {
    getCompaniesForRequestedHealthService() {
      var self = this
      this.loading = true
      const filters = {
        match: this.selectedData.prestazioneName,
        ...this.filters
      }
      reservationService
        .getCompaniesForRequestedHealthService(filters)
        .then(function (res) {
          self.availableResCompanies = res.data.data
          if (
            self.selectedData.company &&
            !self.availableResCompanies.find(
              (comp) => comp.company.hashid === self.selectedData.company.hashid
            )
          ) {
            self.$emit("selected-company", null)
          }
          self.$nextTick(self.adjustContainerHeight)
        })
        .catch(function (error) {
          self.availableResCompanies = []
          window.scrollTo(0, 0)
          self.errorAlert(
            "Non è stato possibile trovare aziende per il servizio richiesto"
          )
        })
        .finally(function () {
          self.loading = false
        })
    },
    handleUpdateFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters))
      if (this.resData.type === "prestazione") {
        this.getCompaniesForRequestedHealthService()
      } else {
        this.getCompaniesForService()
      }
    },
    adjustContainerHeight() {
      const container = this.$refs.cardContainer
      if (!container) return

      const cards = container.querySelectorAll(".company-card")
      if (cards.length === 0) return

      // Resetta l'altezza del container per ottenere il layout naturale
      container.style.maxHeight = "none"

      // Trova l'altezza della seconda riga
      let secondRowTop = 0
      let maxHeight = 0

      for (let i = 0; i < cards.length; i++) {
        const card = cards[i]
        const cardTop = card.offsetTop

        if (i === 0) {
          secondRowTop = cardTop
        } else if (cardTop > secondRowTop) {
          // Abbiamo trovato l'inizio della seconda riga
          secondRowTop = cardTop
          break
        }
      }

      // Trova l'altezza massima delle card nella seconda riga
      for (let i = 0; i < cards.length; i++) {
        const card = cards[i]
        if (card.offsetTop === secondRowTop) {
          maxHeight = Math.max(maxHeight, card.offsetHeight)
        } else if (card.offsetTop > secondRowTop) {
          break // Abbiamo superato la seconda riga
        }
      }

      // Imposta l'altezza del container alla somma dell'altezza della prima e della seconda riga
      const totalHeight = secondRowTop + maxHeight - container.offsetTop

      // Aggiungi 20px extra e imposta l'altezza massima
      container.style.maxHeight = `${totalHeight + 10}px`
    },
    selectedResCompany(data) {
      this.$emit(
        "selected-company",
        this.resData.type === "prestazione" ? data.company : data
      )
      if (this.resData.type === "prestazione") {
        this.$emit("selected-new-health-service", data.prestazione)
      }
    },
    getCompaniesForService() {
      this.loading = true
      supportService
        .searchCompanyBySubtype({
          ...this.filters,
          "tipo-servizio": this.resData.type === "prodotto" ? "prodotto" : "teleconsulto"
        })
        .then((res) => {
          this.availableResCompanies = res.data.data
          if (
            this.selectedData.company &&
            !this.availableResCompanies.find(
              (comp) => comp.hashid === this.selectedData.company.hashid
            )
          ) {
            this.$emit("selected-company", null)
          }
          this.$nextTick(this.adjustContainerHeight)
        })
        .catch((err) => {
          this.availableResCompanies = []
          this.errorAlert(
            "Non è stato possibile trovare aziende per il servizio richiesto"
          )
        })
        .finally(() => {
          this.loading = false
        })
    },
    enlight(comp) {
      if (this.selectedData.company) {
        if (this.resData.type === "prestazione") {
          return (
            comp.company.hashid === this.selectedData.company.hashid &&
            comp.prestazione.hashid === this.selectedData.healthService.hashid
          )
        } else {
          return comp.hashid === this.selectedData.company.hashid
        }
      }
    }
  },
  mounted() {
    if (this.resData.type === "prestazione") {
      this.getCompaniesForRequestedHealthService()
    } else {
      this.getCompaniesForService()
    }
    window.addEventListener("resize", this.adjustContainerHeight)
  }
}
</script>

<style scoped>
.company-cards-container {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  padding: 0.5rem;
  background-color: #f8f9fa; /* bg-extra-light */
  border: 1px solid #dee2e6; /* border-extra-light */
}

.company-card {
  margin: 0.5rem;
}
</style>
