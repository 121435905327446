<template>
  <div v-if="user.type === 1">
    <template v-if="isActive === null">
      <p class="text-center"><b-spinner></b-spinner></p>
    </template>
    <template v-else-if="isActive === true">
      <transition-group name="list" mode="out-in" appear>
        <div v-if="$route.params.section === 'prenotazioni'" key="prenotazioni">
          <HomePrenotazioni />
        </div>

        <div v-else-if="$route.params.section === 'strumenti'" key="strumenti">
          <h1 class="text-left pb-1 mb-4 page-title">Strumenti</h1>
          <CancelUtility />
        </div>
        <div v-else-if="$route.params.section === 'report'" key="report">
          <ReportPrenotazioni />
        </div>
        <div v-else-if="$route.params.section === 'recall'" key="recall">
          <RecallPrenotazione />
        </div>
      </transition-group>
    </template>
    <template v-else>
      <h4>Servizio non abilitato</h4>
      <h6>
        <a href="https://concura.online/contatti/"> <u> Contattaci</u></a>
        per saperne di più o per abilitare il servizio
      </h6>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { companyService } from "@/_services";
import CancelUtility from "@/components/prenotazioni/sections/CancelUtility.vue";
import HomePrenotazioni from "@/components/prenotazioni/sections/HomePrenotazioni.vue";
import ReportPrenotazioni from "@/components/prenotazioni/sections/ReportPrenotazioni.vue";
import RecallPrenotazione from "@/components/prenotazioni/sections/RecallPrenotazione.vue";
export default {
  name: "PrenotazioniMain",
  components: {
    CancelUtility,
    HomePrenotazioni,
    ReportPrenotazioni,
    RecallPrenotazione,
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      isActive: null,
    };
  },
  mounted() {
    this.verifyService();
  },
  methods: {
    verifyService() {
      const self = this;
      const filter = { ref: 1 };
      companyService
        .checkIfServiceIsActive(filter)
        .then(function (response) {
          self.isActive = response.data.data.active;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
