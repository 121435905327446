var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user.type === 1)?_c('div',[(_vm.isActive === null)?[_c('p',{staticClass:"text-center"},[_c('b-spinner')],1)]:(_vm.isActive === true)?[_c('transition-group',{attrs:{"name":"list","mode":"out-in","appear":""}},[(!_vm.$route.params.section || _vm.$route.params.section === 'raccolte')?_c('div',{key:"raccolte"},[_c('HomeAttivita')],1):(
          _vm.$route.params.section === 'questionari' &&
          _vm.$route.params?.action !== 'utente'
        )?_c('div',{key:"questionari"},[_c('HomeQuestionari')],1):(_vm.$route.params.section === 'questionario/nuovo')?_c('div',{key:"nuovoquestionario"},[_c('CreaNuovoQuestionario')],1):(
          _vm.$route.params.section === 'questionario' &&
          _vm.$route.params.action === 'modifica'
        )?_c('div',{key:"editquestionario"},[_c('ModificaQuestionario')],1):(
          _vm.$route.params.section === 'questionario' &&
          _vm.$route.params.action === 'somministra'
        )?_c('div',{key:"compilaquestionario"},[_c('CompilazioneQuestionario')],1):(
          _vm.$route.params.section === 'questionario' &&
          _vm.$route.params.action === 'edit'
        )?_c('div',{key:"modificacompilato"},[_c('ModificaQuestionarioCompilato')],1):(
          _vm.$route.params.section === 'questionario' &&
          _vm.$route.params.action === 'report'
        )?_c('div',{key:"reportquestionario"},[_c('ReportQuestionariCompilati')],1):(
          _vm.$route.params.section === 'questionari' &&
          _vm.$route.params.action === 'utente'
        )?_c('div',{key:"questionariUtente"},[_c('ReportQuestionariUtente')],1):_c('div',[_c('h4',[_vm._v("Sezione riservata o non trovata.")])])])]:[_c('h4',[_vm._v("Servizio non abilitato")]),_vm._m(0)]],2):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('a',{attrs:{"href":"https://concura.online/contatti/"}},[_c('u',[_vm._v(" Contattaci")])]),_vm._v(" per saperne di più o per abilitare il servizio ")])
}]

export { render, staticRenderFns }