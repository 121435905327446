<template>
  <b-container fluid class="m-0 p-0">
    <b-row class="border-bottom border-info pb-4">
      <b-col cols="3">
        <div
          class="m-0 mr-2 d-none d-sm-flex flex-column justify-content-center align-items-center"
          style="flex: 0 0 100px; min-width: 0"
        >
          <b-icon
            variant="secondary"
            :icon="cycleIcons[therapy.tipo]"
            font-scale="3"
          ></b-icon>
          <span class="text-center text-capitalize text-secondary pt-2">{{
            therapy.tipo
          }}</span>
        </div></b-col
      >
      <b-col>
        <b-row cols="1">
          <b-col>
            <div class="my-2 text-secondary text-uppercase">
              <h4 class="">
                {{ therapy.product_name }}
              </h4>
            </div>
          </b-col>
          <b-col>
            <div class="my-2 text-uppercase">
              <h6 v-if="therapy.pathology" class="">
                {{ therapy.pathology.name }} - patologia
                {{ therapy.pathology.tipo }}
              </h6>
              <h6 v-else class="">Patologia non indicata</h6>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="3"></b-col>
    </b-row>

    <b-row cols="2" class="pt-4" align-v="stretch">
      <b-col>
        <h5 class="text-secondary text-left pb-1">Posologia:</h5>
        <div class="p-3">
          <h6 class="text-uppercase">
            {{ posNum }}
            {{
              posNum > 1
                ? therapy.posology_type
                : typesMap[therapy.posology_type]
            }}
            di {{ therapy.product_name }} {{ therapy.posologia.tipo }}
            <span v-if="therapy.posologia.frequenza">
              {{ therapy.posology_every_num }}
              {{ therapy.posologia.frequenza }}
            </span>
            per via
            <span>{{ therapy.posology_via }}. </span>
            <br />
            <span v-if="assunzioni">Da assumere: {{ assunzioni }}.</span>
          </h6>
        </div>
      </b-col>
      <b-col>
        <h5 class="text-secondary text-left pb-1">Durata:</h5>
        <div class="p-3 text-uppercase">
          <!-- Terapia di tipo occasionale -->
          <h6 v-if="therapy.type === 2" class="">
            Da {{ formatData(therapy.start_at) }} fino a
            {{ formatData(therapy.end_at) }} .
          </h6>
          <!-- Terapia di tipo periodica -->
          <h6 v-else-if="therapy.type === 1" class="">
            La terapia va seguita ogni {{ therapy.every_num }}
            {{
              therapy.every_num > 1
                ? forEveryUmsPlur[therapy.every_um]
                : forEveryUmsSing[therapy.every_um]
            }}
            per
            {{ therapy.for_num }}
            {{
              therapy.for_num > 1
                ? forEveryUmsPlur[therapy.for_um]
                : forEveryUmsSing[therapy.for_um]
            }}
            a partire da {{ formatData(therapy.start_at) }}.
          </h6>
          <!-- Terapia di tipo cronica -->
          <h6 v-else class="">indeterminata</h6>
        </div>
      </b-col>
    </b-row>

    <b-row cols="2" class="pt-4 mb-5" align-v="stretch">
      <b-col>
        <h5 class="text-secondary text-left pb-1">Annotazioni:</h5>
        <div class="bg-white general_border shadow_2 p-3">
          <p class="my-4 pb-2">
            {{ therapy.details ? therapy.details : "nessuna nota" }}
          </p>
        </div>
      </b-col>
      <b-col>
        <h5 class="text-secondary text-left pb-1">Dettagli:</h5>
        <div class="p-3 text-uppercase">
          <h6 class="">
            PRESCRITTORE:
            {{ therapy.prescrittore ? therapy.prescrittore : "non indicato" }}
          </h6>
          <h6 class="">
            FONTE INFORMATIVA:
            {{
              therapy.fonte_informativa
                ? therapy.fonte_informativa
                : "non indicata"
            }}
          </h6>
          <h6 class="">
            EFFETTI COLLATERALI:
            {{
              therapy.effetti_collaterali
                ? therapy.effetti_collaterali
                : "non indicati"
            }}
          </h6>
          <h6 class="">
            REAZIONI ALLERGICHE:
            {{
              therapy.reazione_allergica
                ? therapy.reazione_allergica
                : "non indicate"
            }}
          </h6>
          <h6 class="">
            INTERAZIONI:
            {{ therapy.interazioni ? therapy.interazioni : "non indicate" }}
          </h6>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
export default {
  name: "RecapTherapy",
  props: ["therapy"],
  data() {
    return {
      typesMap: {
        Compresse: "compressa",
        Capsule: "capsula",
        Gocce: "goccia",
        Inalazioni: "inalazione",
        Buste: "busta",
        Supposte: "supposta",
        Scatole: "scatola",
        Cerotti: "cerotto",
        Fiale: "fiala",
        Fleboclisi: "fleboclisi",
        Ovuli: "ovulo",
        Sacche: "sacca",
        Flaconi: "flacone",
        Confezioni: "confezione",
        Kilogrammi: "kilogrammo",
        Kit: "kit",
        Millilitri: "millilitro",
        Milligrammi: "milligrammo",
        Applicazioni: "applicazione",
        UI: "UI",
      },
      forEveryUmsSing: {
        0: "mese",
        1: "settimana",
        2: "giorno",
      },
      forEveryUmsPlur: {
        0: "mesi",
        1: "settimane",
        2: "giorni",
      },
      cycleIcons: {
        periodica: "calendar",
        cronica: "watch",
        occasionale: "lightning",
      },
    };
  },
  computed: {
    posNum: function () {
      const num = parseFloat(this.therapy.posology_num);
      return num % 1 === 0 ? parseInt(num, 10) : num;
    },
    assunzioni: function () {
      const assunzioniKeys = [
        "mattina",
        "meta_mattina",
        "pranzo",
        "pomeriggio",
        "sera",
        "notte",
      ];
      let assunzioniString = assunzioniKeys.reduce((acc, key) => {
        if (this.therapy[key]) {
          acc += key + ", ";
        }
        return acc;
      }, "");

      if (assunzioniString === ", ") return null;

      assunzioniString = assunzioniString.replace(
        "meta_mattina",
        "metà mattina"
      );

      return assunzioniString.slice(0, -2) || null;
    },
  },
  methods: {
    formatData(data) {
      return moment(data).format("dddd DD MMMM YYYY");
    },
    formatDataShort(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
  created() {
    //
  },
};
</script>
