<template>
  <b-spinner v-if="!loadedComuni"></b-spinner>
  <b-row v-else class="mt-n2 ml-n3 mb-4">
    <b-col v-if="!hideDenominazione" cols="12" md="6" lg="4" class="mt-2">
      <b-form-group
        label-class="text-secondary text-left fw-bolder"
        label="Cerca centro erogatore"
      >
        <b-form-input
          v-model="form.denominazione"
          v-capitalize-first
          placeholder="Denominazione"
        ></b-form-input>
      </b-form-group>
    </b-col>

    <b-col cols="12" md="6" lg="3" class="mt-2">
      <b-form-group
        label-class="text-secondary text-left fw-bolder"
        label="Tipologia"
      >
        <b-form-select
          v-model="form.subtype"
          v-capitalize-first-select
          :options="subtypesOptions"
        >
          <template #first>
            <b-form-select-option :value="null"> - </b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
    </b-col>

    <b-col class="d-none d-lg-block" lg="3" v-if="!hideDenominazione"></b-col>

    <b-col cols="12" md="4" lg="3" class="mt-2">
      <b-form-group
        label-class="text-secondary text-left fw-bolder"
        label="Regione"
      >
        <b-form-select
          v-model="form.regione"
          :options="regioni"
          @change="handleRegionChange($event)"
          v-capitalize-first-select
        >
          <template #first>
            <b-form-select-option :value="null"> - </b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
    </b-col>

    <b-col cols="12" md="4" lg="3" class="mt-2">
      <b-form-group
        label-class="text-secondary text-left fw-bolder"
        label="Provincia"
      >
        <b-form-select
          v-model="form.provincia"
          :options="province"
          v-capitalize-first-select
          @change="handleProvinceChange($event)"
        >
          <template #first>
            <b-form-select-option :value="null"> - </b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
    </b-col>

    <b-col cols="12" md="4" lg="3" class="mt-2">
      <b-form-group
        label-class="text-secondary text-left fw-bolder"
        label="Comune"
      >
        <b-form-select
          v-model="form.comune"
          :key="
            comuni && comuni.length ? comuni[0].comune + comuni.length : null
          "
          v-capitalize-first-select
        >
          <template #first>
            <b-form-select-option :value="null"> - </b-form-select-option>
          </template>
          <b-form-select-option
            v-for="(com, ind) in comuni"
            :key="com.comune + ind"
            :value="com.comune"
            >{{ com.comune }}</b-form-select-option
          >
        </b-form-select>
      </b-form-group>
    </b-col>

    <b-col cols="12" md="4" lg="3" class="mt-2">
      <b-form-group
        label-class="text-secondary text-left fw-bolder"
        label="CAP"
      >
        <b-form-input
          v-model="form.cap"
          v-capitalize-first
          placeholder="CAP"
        ></b-form-input>
      </b-form-group>
    </b-col>

    <b-col
      v-if="showBadges"
      cols="12"
      md="8"
      class="mt-2 d-flex flex-wrap align-items-center justify-content-start"
    >
      <b-badge
        v-for="(filter, index) in activeFilters"
        :key="index"
        variant="secondary"
        style="border-radius: 16px"
        class="mr-1 px-3 py-1 mt-3 d-flex align-items-center"
      >
        <span>
          {{ getFilterBadgeLabel(filter) }}
        </span>
        <span>
          <b-icon
            @click="handleFilterRemoval(filter[0])"
            icon="x"
            font-scale="2.5"
            class="ml-3 point font-weight-bolder"
          ></b-icon>
        </span>
      </b-badge>
    </b-col>
  </b-row>
</template>

<script>
import { utilityService } from "@/_services"
import { mapState, mapActions } from "vuex"

export default {
  name: "SearchCompanyFilters",
  props: {
    debounceMode: {
      type: Boolean,
      default: true
    },
    hideDenominazione: {
      type: Boolean,
      default: false
    },
    showBadges: {
      type: Boolean,
      default: false
    },
    startWithFilters: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      subtypesOptions: [],
      form: {
        denominazione: null,
        subtype: null,
        regione: null,
        provincia: null,
        comune: null,
        cap: null
      },
      oldForm: {}
    }
  },
  watch: {
    form: {
      handler: function (newVal) {
        if (this.debounceMode) {
          const isDenominazioneValid =
            newVal.denominazione && newVal.denominazione.length >= 4
          const isCapValid = newVal.cap && newVal.cap.length >= 3

          const isDenominazioneChanged =
            newVal.denominazione !== this.oldForm.denominazione
          const isCapChanged = newVal.cap !== this.oldForm.cap

          if (
            (isDenominazioneChanged &&
              (isDenominazioneValid || newVal.denominazione === "")) ||
            (isCapChanged && (isCapValid || newVal.cap === "")) ||
            (!isDenominazioneChanged && !isCapChanged)
          ) {
            this.$emit("update-filters", JSON.parse(JSON.stringify(newVal)))
          }
        } else {
          this.$emit("update-filters", JSON.parse(JSON.stringify(newVal)))
        }

        this.oldForm = JSON.parse(JSON.stringify(newVal))
      },
      deep: true
    }
  },
  computed: {
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
    activeFilters() {
      const orderArray = [
        "denominazione",
        "subtype",
        "regione",
        "provincia",
        "comune",
        "cap"
      ]
      return orderArray.reduce((acc, key) => {
        if (this.form[key]) {
          acc.push([key, this.form[key]])
        }
        return acc
      }, [])
    },
    loadedComuni() {
      return this.comuni && this.comuni.length > 0
    }
  },
  methods: {
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    getSubtypes() {
      var self = this
      return new Promise(function (resolve, reject) {
        utilityService
          .getSubtypes()
          .then(function (response) {
            self.subtypesOptions = Object.values(response.data.data)
              .concat()
              .flat()
              .map(function (item) {
                return {
                  value: item.hashid,
                  text: item.name
                }
              })
            resolve()
          })
          .catch(function (error) {
            console.error("Error fetching subtypes:", error)
            reject(error)
          })
      })
    },
    getFilterBadgeLabel(filter) {
      if (!filter || !filter[0] || !filter[1]) {
        return ""
      }
      let label = ""
      if (filter[0] === "subtype") {
        label = this.subtypesOptions.find(
          (item) => item.value === filter[1]
        )?.text
      } else if (filter[0] === "cap") {
        label = "CAP: " + filter[1]
      } else {
        label = filter[1]
      }
      return label[0].toUpperCase() + label.slice(1)
    },
    handleFilterRemoval(key) {
      if (key === "regione") {
        this.form.regione = null
        this.form.provincia = null
        this.form.comune = null
      } else if (key === "provincia") {
        this.form.provincia = null
        this.form.comune = null
      } else {
        this.form[key] = null
      }
      this.$emit("update-filters", JSON.parse(JSON.stringify(this.form)))
    },
    handleRegionChange(event) {
      this.getProvince({ regione: event })
      this.form.provincia = null
      this.form.comune = null
    },
    handleProvinceChange(event) {
      this.getComuni({
        regione: this.form.regione,
        provincia: event
      })
      this.form.comune = null
    },
    initializeForm() {
      if (this.startWithFilters) {
        Object.keys(this.startWithFilters).forEach(function (key) {
          if (this.form[key] !== undefined) {
            this.form[key] = this.startWithFilters[key]
          }
        }, this)
      }
      this.oldForm = JSON.parse(JSON.stringify(this.form))
    },
    prepareParams() {
      let provinceParams = {}
      let comuniParams = {}

      if (this.form.regione && this.form.provincia && this.form.comune) {
        provinceParams = { regione: this.form.regione }
        comuniParams = {
          regione: this.form.regione,
          provincia: this.form.provincia
        }
      }

      return { provinceParams, comuniParams }
    },
    loadFilters() {
      const { provinceParams, comuniParams } = this.prepareParams()
      const promises = [
        this.getRegioni(),
        this.getSubtypes(),
        this.getProvince(provinceParams),
        this.getComuni(comuniParams)
      ]

      return Promise.all(promises)
    }
  },
  created() {
    this.$emit("update-is-loading-filters", true)

    this.initializeForm()
    var self = this
    this.loadFilters().finally(function () {
      self.$emit("update-is-loading-filters", false)
    })
  }
}
</script>

<style scoped></style>
