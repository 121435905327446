<template>
  <div class="py-2">
    <div v-if="openings">
      <b-container class="col-12 ml-1 mb-1 px-0">
        <b-row
          cols="2"
          cols-sm="2"
          cols-md="4"
          cols-lg="5"
          cols-lgx="6"
          cols-xl="6"
          align-v="stretch"
        >
          <b-col v-for="(giorno, ind) in openings" :key="ind" class="my-2 px-1">
            <div
              class="my-2 puntofarmaCard h-100 d-flex flex-wrap align-content-start"
            >
              <b-col class="my-1 col-12 pt-3">
                <h5 class="text-left text-capitalize">
                  {{ days[giorno.day] }}
                  <span class="ml-2">
                    <b-icon icon="calendar4"></b-icon>
                  </span>
                </h5>
              </b-col>
              <b-col class="my-3 col-12" v-if="giorno.closed == true">
                <b-row cols="1" class="text-left">
                  <b-col class="px-1 mb-1">
                    <h6 class="text-left mr-3">Chiuso</h6>
                  </b-col>
                  <b-col class="px-1 mb-1"> </b-col>
                </b-row>
              </b-col>
              <b-col v-else class="my-3 col-12">
                <b-row cols="1" class="text-left">
                  <b-col class="px-1 mb-1">
                    <template v-if="giorno.ora_inizio_am || giorno.ora_fine_am">
                      <h6 class="text-left">
                        <span class="mr-2">
                          <b-icon icon="clock"></b-icon>
                        </span>
                        mattina:
                      </h6>
                      <p class="">
                        <span class="mr-2">
                          <b-icon icon="clock" variant="white"></b-icon>
                        </span>
                        {{ giorno.ora_inizio_am }} -
                        {{ giorno.ora_fine_am }}
                      </p>
                    </template>
                  </b-col>
                  <b-col class="px-1 mb-1">
                    <template v-if="giorno.ora_inizio_pm || giorno.ora_fine_pm">
                      <h6 class="text-left">
                        <span class="mr-2">
                          <b-icon icon="clock"></b-icon>
                        </span>
                        pomeriggio:
                      </h6>
                      <p class="">
                        <span class="mr-2">
                          <b-icon icon="clock" variant="white"></b-icon>
                        </span>
                        {{ giorno.ora_inizio_pm }} -
                        {{ giorno.ora_fine_pm }}
                      </p>
                    </template>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="col-12 absolute__bottom__custom">
                <b-row class="my-2 text-right justify-content-end">
                  <b-col class="col-3 px-0">
                    <!-- Button Icon -->
                    <b-iconstack
                      v-b-tooltip.hover.bottom
                      title="Rimuovi giorno"
                      font-scale="2.2"
                      class="point"
                      @click="removeDay(ind)"
                    >
                      <b-icon
                        stacked
                        icon="circle-fill"
                        variant="mdm-light-grey"
                      ></b-icon>
                      <b-icon
                        stacked
                        icon="trash-fill"
                        scale="0.5"
                        variant="secondary"
                      ></b-icon>
                    </b-iconstack>
                  </b-col>
                  <b-col class="col-3 px-0">
                    <!-- Button Icon -->
                    <b-iconstack
                      v-b-tooltip.hover.bottom
                      title="Modifica orari"
                      font-scale="2.2"
                      class="point"
                      @click="openEditDay(ind)"
                    >
                      <b-icon
                        stacked
                        icon="circle-fill"
                        variant="mdm-light-grey"
                      ></b-icon>
                      <b-icon
                        stacked
                        icon="pencil-fill"
                        scale="0.5"
                        variant="secondary"
                      ></b-icon>
                    </b-iconstack>
                  </b-col>
                  <b-col class="col-3 px-0">
                    <b-iconstack
                      v-b-tooltip.hover.bottom
                      title="Duplica giorno"
                      font-scale="2.2"
                      class="point"
                      @click="openDuplicateDay(ind)"
                    >
                      <b-icon
                        stacked
                        icon="circle-fill"
                        variant="mdm-light-grey"
                      ></b-icon>
                      <b-icon
                        stacked
                        icon="files"
                        scale="0.5"
                        variant="secondary"
                      ></b-icon>
                    </b-iconstack>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="mt-3 text-left" v-if="openings.length < 7">
        <!-- <div class="d-flex align-items-center point" @click="openNewDay(1)">
          <span class="ml-2">
            <b-iconstack font-scale="2.5">
              <b-icon stacked icon="circle-fill" variant="secondary"></b-icon>
              <b-icon stacked icon="plus" scale="0.8" variant="white"></b-icon>
              <b-icon stacked icon="circle" variant="secondary"></b-icon>
            </b-iconstack>
          </span>
          <span class="text-secondary ml-2">Aggiungi fascia oraria</span>
        </div> -->
        <b-button variant="secondary" @click="openNewDay(1)">
          <b-icon icon="plus"></b-icon>
          Aggiungi fascia oraria
        </b-button>
      </div>
      <div v-if="showSave" class="mt-3 text-left">
        <b-button
          type="button"
          variant="outline-secondary mx-2"
          @click="showSave = false"
          >Annulla</b-button
        >
        <b-button
          variant="secondary text-white  mx-2"
          type="button"
          @click="submitChanges"
          >Salva
        </b-button>
      </div>
    </div>
    <div>
      <b-modal centered ref="modal-edit" title="" hide-footer>
        <div v-if="dayToEdit">
          <div>
            <b-form-group
              label="Giorno"
              label-for="day"
              class="text-left mx-auto col"
            >
              <b-form-select
                v-capitalize-first-select
                class="my-3"
                :options="stringDays"
                name="day"
                v-model="dayToEdit"
                disabled
              >
              </b-form-select>
            </b-form-group>
          </div>
          <b-form-group
            label=""
            label-for="aperto"
            class="text-left mx-auto col"
          >
            <b-form-checkbox
              name="aperto"
              :value="0"
              :unchecked-value="1"
              v-model="form[dayToEdit].closed"
              :state="form[dayToEdit].closed == false"
            >
              {{ form[dayToEdit].closed == 1 ? "Chiuso" : "Aperto" }}
            </b-form-checkbox>
          </b-form-group>
          <div v-show="form[dayToEdit].closed == false">
            <div class="col-12 d-flex px-0 flex-wrap">
              <b-form-group
                label="Mattina dalle"
                label-for="day"
                class="text-left mx-auto col-sm-12 col-md-5 px-0"
                ><span
                  v-if="form[dayToEdit].ora_inizio_am != null"
                  class="text-danger absolute_icon_left"
                  @click="form[dayToEdit].ora_inizio_am = null"
                >
                  <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                </span>
                <b-form-input
                  class="mx-auto my-3"
                  type="time"
                  placeholder="Enter your name"
                  v-model="form[dayToEdit].ora_inizio_am"
                  :state="
                    validateTime(
                      form[dayToEdit].ora_fine_am,
                      form[dayToEdit].ora_inizio_am
                    )
                  "
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Mattina alle"
                label-for="day"
                class="text-left mx-auto col-sm-12 col-md-5 px-0"
              >
                <span
                  v-if="form[dayToEdit].ora_fine_am != null"
                  @click="form[dayToEdit].ora_fine_am = null"
                  class="text-danger absolute_icon_left"
                >
                  <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                </span>
                <b-form-input
                  class="mx-auto my-3"
                  type="time"
                  placeholder="Enter your name"
                  v-model="form[dayToEdit].ora_fine_am"
                  :state="
                    validateTime(
                      form[dayToEdit].ora_fine_am,
                      form[dayToEdit].ora_inizio_am
                    )
                  "
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12 d-flex flex-wrap px-0">
              <b-form-group
                label="Pomeriggio dalle"
                label-for="day"
                class="text-left mx-auto col-sm-12 col-md-5 px-0"
                ><span
                  class="text-danger absolute_icon_left"
                  v-if="form[dayToEdit].ora_inizio_pm != null"
                  @click="form[dayToEdit].ora_inizio_pm = null"
                >
                  <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                </span>
                <b-form-input
                  class="mx-auto my-3"
                  type="time"
                  placeholder="Enter your name"
                  v-model="form[dayToEdit].ora_inizio_pm"
                  :state="
                    validateTime(
                      form[dayToEdit].ora_fine_pm,
                      form[dayToEdit].ora_inizio_pm
                    )
                  "
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Pomeriggio alle"
                label-for="day"
                class="text-left mx-auto col-sm-12 col-md-5 px-0"
                ><span
                  class="text-danger absolute_icon_left"
                  v-if="form[dayToEdit].ora_fine_pm != null"
                  @click="form[dayToEdit].ora_fine_pm = null"
                >
                  <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                </span>
                <b-form-input
                  class="mx-auto my-3"
                  type="time"
                  placeholder="Enter your name"
                  v-model="form[dayToEdit].ora_fine_pm"
                  :state="
                    validateTime(
                      form[dayToEdit].ora_fine_pm,
                      form[dayToEdit].ora_inizio_pm
                    )
                  "
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="text-right">
            <b-button
              type="button"
              variant="popup-button m-3"
              @click="saveChanges"
              >Ok
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-modal centered ref="modal-duplicate" title="" hide-footer>
        <div v-if="dayToEdit">
          <div>
            <b-form-group
              label="Duplica per"
              label-for="daydupl"
              class="text-left mx-auto col"
            >
              <b-form-select
                v-capitalize-first-select
                class="my-3 text-secondary bg-white border-secondary"
                name="daydupl"
                v-model="duplicateForDay"
              >
                <b-form-select-option
                  v-for="(day, val) in days"
                  :key="val"
                  :value="val"
                  :disabled="dayToEdit == day"
                  >{{ day }}</b-form-select-option
                >
                <b-form-select-option :value="null"
                  >Tutta la settimana</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div>
          <div class="text-right">
            <b-button
              type="button"
              variant="popup-button m-3"
              @click="duplicateDay"
              >Ok
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-modal centered ref="modal-new" title="Nuova fascia oraria" hide-footer>
        <div v-if="creatingNew">
          <div>
            <b-form-group
              label="Giorno"
              label-for="day"
              class="text-left mx-auto col"
            >
              <b-form-select
                v-capitalize-first-select
                class="my-3 text-secondary bg-white border-secondary"
                :options="stringDays"
                name="day"
                v-model="dayToEdit"
              >
              </b-form-select>
            </b-form-group>
          </div>
          <b-form-group
            label=""
            label-for="aperto"
            class="text-left mx-auto col"
          >
            <b-form-checkbox
              name="aperto"
              :value="0"
              :unchecked-value="1"
              v-model="form[dayToEdit].closed"
              :state="form[dayToEdit].closed == false"
            >
              {{ form[dayToEdit].closed == 1 ? "Chiuso" : "Aperto" }}
            </b-form-checkbox>
          </b-form-group>
          <div v-show="form[dayToEdit].closed == false">
            <div class="col-12 d-flex px-0">
              <b-form-group
                label="Mattina dalle"
                label-for="day"
                class="text-left mx-auto col-5 px-0"
                ><span
                  v-if="form[dayToEdit].ora_inizio_am != null"
                  class="text-danger absolute_icon_left"
                  @click="form[dayToEdit].ora_inizio_am = null"
                >
                  <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                </span>
                <b-form-input
                  class="mx-auto my-3"
                  type="time"
                  placeholder="Enter your name"
                  v-model="form[dayToEdit].ora_inizio_am"
                  :state="
                    validateTime(
                      form[dayToEdit].ora_fine_am,
                      form[dayToEdit].ora_inizio_am
                    )
                  "
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Mattina alle"
                label-for="day"
                class="text-left mx-auto col-5 px-0"
              >
                <span
                  v-if="form[dayToEdit].ora_fine_am != null"
                  @click="form[dayToEdit].ora_fine_am = null"
                  class="text-danger absolute_icon_left"
                >
                  <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                </span>
                <b-form-input
                  class="mx-auto my-3"
                  type="time"
                  placeholder="Enter your name"
                  v-model="form[dayToEdit].ora_fine_am"
                  :state="
                    validateTime(
                      form[dayToEdit].ora_fine_am,
                      form[dayToEdit].ora_inizio_am
                    )
                  "
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12 d-flex px-0">
              <b-form-group
                label="Pomeriggio dalle"
                label-for="day"
                class="text-left mx-auto col-5 px-0"
                ><span
                  class="text-danger absolute_icon_left"
                  v-if="form[dayToEdit].ora_inizio_pm != null"
                  @click="form[dayToEdit].ora_inizio_pm = null"
                >
                  <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                </span>
                <b-form-input
                  class="mx-auto my-3"
                  type="time"
                  placeholder="Enter your name"
                  v-model="form[dayToEdit].ora_inizio_pm"
                  :state="
                    validateTime(
                      form[dayToEdit].ora_fine_pm,
                      form[dayToEdit].ora_inizio_pm
                    )
                  "
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Pomeriggio alle"
                label-for="day"
                class="text-left mx-auto col-5 px-0"
                ><span
                  class="text-danger absolute_icon_left"
                  v-if="form[dayToEdit].ora_fine_pm != null"
                  @click="form[dayToEdit].ora_fine_pm = null"
                >
                  <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                </span>
                <b-form-input
                  class="mx-auto my-3"
                  type="time"
                  placeholder="Enter your name"
                  v-model="form[dayToEdit].ora_fine_pm"
                  :state="
                    validateTime(
                      form[dayToEdit].ora_fine_pm,
                      form[dayToEdit].ora_inizio_pm
                    )
                  "
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="text-right">
            <b-button type="button" variant="popup-button m-3" @click="saveNew"
              >Ok</b-button
            >
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { mapState, mapActions } from "vuex"
import { companyService } from "@/_services";
export default {
  name: "SettingOrariCentroErogatore",
  props: ["orari","company"],
  components: {},
  computed: {
    ...mapState("utente", ["status", "user"]),
    // ...mapState("company", ["companyLoggedIn", "company"]),
  },
  data() {
    return {
      stringDays: [
        "lunedì",
        "martedì",
        "mercoledì",
        "giovedì",
        "venerdì",
        "sabato",
        "domenica"
      ],
      showSave: false,
      dayToEdit: 0,
      newDay: "lunedì",
      creatingNew: false,
      duplicateForDay: null,
      days: {
        1: "lunedì",
        2: "martedì",
        3: "mercoledì",
        4: "giovedì",
        5: "venerdì",
        6: "sabato",
        7: "domenica"
      },
      openings: [],
      form: {
        lunedì: {
          day: 1,
          ora_inizio_am: null,
          ora_fine_am: null,
          ora_inizio_pm: null,
          ora_fine_pm: null,
          closed: 0
        },
        martedì: {
          day: 2,
          ora_inizio_am: null,
          ora_fine_am: null,
          ora_inizio_pm: null,
          ora_fine_pm: null,
          closed: 0
        },
        mercoledì: {
          day: 3,
          ora_inizio_am: null,
          ora_fine_am: null,
          ora_inizio_pm: null,
          ora_fine_pm: null,
          closed: 0
        },
        giovedì: {
          day: 4,
          ora_inizio_am: null,
          ora_fine_am: null,
          ora_inizio_pm: null,
          ora_fine_pm: null,
          closed: 0
        },
        venerdì: {
          day: 5,
          ora_inizio_am: null,
          ora_fine_am: null,
          ora_inizio_pm: null,
          ora_fine_pm: null,
          closed: 0
        },
        sabato: {
          day: 6,
          ora_inizio_am: null,
          ora_fine_am: null,
          ora_inizio_pm: null,
          ora_fine_pm: null,
          closed: 0
        },
        domenica: {
          day: 7,
          ora_inizio_am: null,
          ora_fine_am: null,
          ora_inizio_pm: null,
          ora_fine_pm: null,
          closed: 0
        }
      }
    }
  },
  methods: {
    ...mapActions("utente", ["me"]),
    setForm() {
      var self = this
      this.openings.map(function (item, index) {
        var name = self.days[item.day]
        if (item.closed == 1) {
          self.form[name].ora_inizio_am = null
          self.form[name].ora_fine_am = null
          self.form[name].ora_inizio_pm = null
          self.form[name].ora_fine_pm = null
        } else {
          self.form[name].ora_inizio_am = item.ora_inizio_am
          self.form[name].ora_fine_am = item.ora_fine_am
          self.form[name].ora_inizio_pm = item.ora_inizio_pm
          self.form[name].ora_fine_pm = item.ora_fine_pm
        }
        self.form[name].closed = item.closed
      })
    },
    resetOpenings() {
      this.openings.sort(function (a, b) {
        return a.day - b.day
      })
      this.duplicateForDay = null
    },
    removeDay(ind) {
      let dayToRemove = this.openings[ind].day
      this.openings.splice(ind, 1)
      this.showSave = true
      this.form[this.days[dayToRemove]].closed = true
      this.form[this.days[dayToRemove]].ora_inizio_am = null
      this.form[this.days[dayToRemove]].ora_inizio_pm = null
      this.form[this.days[dayToRemove]].ora_fine_am = null
      this.form[this.days[dayToRemove]].ora_fine_pm = null
    },
    openEditDay(ind) {
      this.dayToEdit = this.days[this.openings[ind].day]
      this.$refs["modal-edit"].show()
    },
    openNewDay(ind) {
      this.creatingNew = true
      // this.dayToEdit = this.days[ind];
      this.dayToEdit = this.openings[ind]
        ? this.days[this.openings[ind].day]
        : this.days[ind]
      this.$refs["modal-new"].show()
    },
    saveChanges() {
      this.openings.forEach((element, index) => {
        if (element.day === this.form[this.dayToEdit].day) {
          this.openings[index].closed = this.form[this.dayToEdit].closed
          this.openings[index].ora_inizio_am =
            this.form[this.dayToEdit].ora_inizio_am
          this.openings[index].ora_inizio_pm =
            this.form[this.dayToEdit].ora_inizio_pm
          this.openings[index].ora_fine_am =
            this.form[this.dayToEdit].ora_fine_am
          this.openings[index].ora_fine_pm =
            this.form[this.dayToEdit].ora_fine_pm
        }
      })
      this.$refs["modal-edit"].hide()
      this.showSave = true
    },
    saveNew() {
      var dayNew = this.form[this.dayToEdit].day
      var indexMatch = null
      for (let dd = 0; dd <= 6; dd++) {
        if (this.openings[dd] && this.openings[dd].day == dayNew) {
          indexMatch = dd
          this.openings[dd].ora_inizio_am =
            this.form[this.dayToEdit].ora_inizio_am
          this.openings[dd].ora_fine_am = this.form[this.dayToEdit].ora_fine_am
          this.openings[dd].ora_inizio_pm =
            this.form[this.dayToEdit].ora_inizio_pm
          this.openings[dd].ora_fine_pm = this.form[this.dayToEdit].ora_fine_pm
          this.openings[dd].closed = this.form[this.dayToEdit].closed
        }
      }
      if (indexMatch === null) {
        var planning = {
          day: dayNew,
          ora_inizio_am: this.form[this.dayToEdit].ora_inizio_am,
          ora_fine_am: this.form[this.dayToEdit].ora_fine_am,
          ora_inizio_pm: this.form[this.dayToEdit].ora_inizio_pm,
          ora_fine_pm: this.form[this.dayToEdit].ora_fine_pm,
          closed: this.form[this.dayToEdit].closed
        }
        this.openings.push(planning)
        this.resetOpenings()
      }
      this.showSave = true
      this.creatingNew = false
      this.$refs["modal-new"].hide()
    },
    formatTime(time) {
      if (time) {
        return moment(time, "HH:mm").format("HH:mm")
      }
      return ""
    },
    validateTime(timeFrom, timeTo) {
      if (!timeFrom && !timeTo) {
        return null
      }
      var from = moment(timeFrom, "HH:mm")
      var to = moment(timeTo, "HH:mm")
      if (
        moment(to).isSameOrBefore(from) == false &&
        this.form[this.dayToEdit].ora_inizio_am != null &&
        this.form[this.dayToEdit].ora_fine_pm != null
      ) {
        if (
          !this.form[this.dayToEdit].ora_inizio_pm &&
          !this.form[this.dayToEdit].ora_fine_am
        ) {
          return true
        }
        return false
      }
      return moment(to).isSameOrBefore(from)
    },
    openDuplicateDay(index) {
      this.dayToEdit = this.days[this.openings[index].day]
      this.$refs["modal-duplicate"].show()
    },
    duplicateDay() {
      if (this.duplicateForDay === null) {
        var newOpenings = []
        var self = this
        Object.keys(this.form).map((element, index) => {
          self.form[element].closed = self.form[self.dayToEdit].closed
          self.form[element].ora_inizio_am =
            self.form[self.dayToEdit].ora_inizio_am
          self.form[element].ora_inizio_pm =
            self.form[self.dayToEdit].ora_inizio_pm
          self.form[element].ora_fine_am = self.form[self.dayToEdit].ora_fine_am
          self.form[element].ora_fine_pm = self.form[self.dayToEdit].ora_fine_pm
          var planning = {
            day: self.form[element].day,
            ora_inizio_am: self.form[self.dayToEdit].ora_inizio_am,
            ora_fine_am: self.form[self.dayToEdit].ora_fine_am,
            ora_inizio_pm: self.form[self.dayToEdit].ora_inizio_pm,
            ora_fine_pm: self.form[self.dayToEdit].ora_fine_pm,
            closed: self.form[self.dayToEdit].closed
          }
          newOpenings.push(planning)
        })
        this.openings = newOpenings
      } else {
        var dayName = this.days[this.duplicateForDay]
        this.form[dayName].closed = this.form[this.dayToEdit].closed
        this.form[dayName].ora_inizio_am =
          this.form[this.dayToEdit].ora_inizio_am
        this.form[dayName].ora_inizio_pm =
          this.form[this.dayToEdit].ora_inizio_pm
        this.form[dayName].ora_fine_am = this.form[this.dayToEdit].ora_fine_am
        this.form[dayName].ora_fine_pm = this.form[this.dayToEdit].ora_fine_pm
        var indexMatch = null
        for (let dd = 0; dd <= 6; dd++) {
          if (
            this.openings[dd] &&
            this.openings[dd].day == this.duplicateForDay
          ) {
            indexMatch = dd
            this.openings[dd].ora_inizio_am =
              this.form[this.dayToEdit].ora_inizio_am
            this.openings[dd].ora_fine_am =
              this.form[this.dayToEdit].ora_fine_am
            this.openings[dd].ora_inizio_pm =
              this.form[this.dayToEdit].ora_inizio_pm
            this.openings[dd].ora_fine_pm =
              this.form[this.dayToEdit].ora_fine_pm
            this.openings[dd].closed = this.form[this.dayToEdit].closed
          }
        }
        if (indexMatch === null) {
          var planning = {
            day: parseInt(this.duplicateForDay),
            ora_inizio_am: this.form[this.dayToEdit].ora_inizio_am,
            ora_fine_am: this.form[this.dayToEdit].ora_fine_am,
            ora_inizio_pm: this.form[this.dayToEdit].ora_inizio_pm,
            ora_fine_pm: this.form[this.dayToEdit].ora_fine_pm,
            closed: this.form[this.dayToEdit].closed
          }
          this.openings.push(planning)
        }
      }
      this.$refs["modal-duplicate"].hide()
      this.resetOpenings()
      this.showSave = true
    },
    submitChanges() {
      if (this.form) {
        this.$emit("store-openings", this.form)
      }
      this.showSave = false
    },
    loadOpenings() {
        const hashid = this.$route.params.hashid
        const self=this
        companyService.getCompanyOpenings(hashid)
        .then(response => {
          self.openings = response.data.data
          self.setForm()
        })
        .catch(error => {
          console.error("Errore nel caricamento degli orari di apertura:", error)
        })
    }
  },
  watch: {
    orari(val) {
      // this.openings = val;
      // this.setForm();
      if (val) {
        // console.log("orari val", val);
        this.openings = val
        this.setForm()
      }
    }
  },
  created() {
    // console.log("azienda in settind", this.orari);
  },
  mounted() {
    // this.setForm();
    this.loadOpenings()
  }
}
</script>
