<template>
  <div>
    <b-button v-if="action === 'create'" variant="secondary" @click="addNew">
      <b-icon icon="plus" class="mr-1"></b-icon>aggiungi
    </b-button>
    <div v-else-if="action === 'editDelete'">
      <b-iconstack @click="editItem()" font-scale="2.2" class="point mr-1">
        <b-icon stacked icon="circle-fill" variant="mdm-light-grey"></b-icon>
        <b-icon
          stacked
          icon="pencil-fill"
          scale="0.5"
          variant="secondary"
        ></b-icon>
      </b-iconstack>
      <b-iconstack @click="openDeleteModal()" font-scale="2.2" class="point">
        <b-icon stacked icon="circle-fill" variant="mdm-light-grey"></b-icon>
        <b-icon
          stacked
          icon="trash-fill"
          scale="0.5"
          variant="secondary"
        ></b-icon>
      </b-iconstack>
    </div>
    <b-modal
      id="modal-parametro"
      ref="modal-parametro"
      :title="modalTitle"
      centered
      hide-footer
    >
      <section class="p-2 text-left col-12 text-left">
        <b-form @submit.prevent="submitForm" v-if="action !== 'delete'">
          <b-form-group label="Tipologia *">
            <b-form-select
              v-model="form.type"
              :options="options"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group class="mt-4" label="Nome">
            <b-form-input v-model="form.name" required></b-form-input>
          </b-form-group>
          <div v-if="form.type && form.type !== 'referti'">
            <b-form-group class="mt-4" label="Unità di misura *">
              <b-form-input v-model="form.um" required></b-form-input>
            </b-form-group>
          </div>
          <p class="text-muted mt-4">*parametri obbligatori</p>
          <p class="d-flex justify-content-end mt-4 mb-0">
            <b-button
              :disabled="!form.type || (!form.um && form.type !== 'referti')"
              type="submit"
              variant="popup-button"
              >Invia</b-button
            >
          </p>
        </b-form>
      </section>
    </b-modal>
    <b-modal
      id="modal-delete"
      ref="modal-delete"
      :title="deleteModalTitle"
      ok-variant="popup-button"
      cancel-variant="popup-button mr-2"
      ok-title="Elimina"
      cancel-title="Annulla"
      footer-border-variant="white"
      @ok="confirmDelete"
      @cancel="closeDeleteModal"
      centered
    >
      <h6>{{ deleteModalMessage }}</h6>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { adminService } from "@/_services"

export default {
  name: "CreateEditDeleteParametroForm",
  props: {
    action: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        name: null,
        type: null,
        um: null,
        instrumental: false,
        has_attachment: false
      },
      options: [
        { value: null, text: "Seleziona tipologia" },
        { value: "parametri", text: "Parametro" },
        { value: "esami", text: "Esame" },
        { value: "referti", text: "Referto" }
      ],
      modalTitle: "",
      modalDescription: "",
      itemToDelete: null
    }
  },
  computed: {
    deleteModalTitle() {
      return `Conferma eliminazione ${this.getItemTypeName()}`
    },
    deleteModalMessage() {
      return `Sei sicuro di voler eliminare ${this.getItemTypeArticle()} ${this.getItemTypeName()} ${
        this.itemToDelete?.name
      }?`
    }
  },
  watch: {
    "form.type": function (newType) {
      if (newType === "parametri") {
        this.form.instrumental = false
        this.form.has_attachment = false
      } else if (newType === "esami") {
        this.form.instrumental = true
        this.form.has_attachment = false
      } else if (newType === "referti") {
        this.form.instrumental = true
        this.form.has_attachment = true
      }
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    addNew() {
      this.resetForm()
      this.updateModalText("create")
      this.$refs["modal-parametro"].show()
    },
    editItem() {
      this.populateForm()
      this.updateModalText("edit")
      this.$refs["modal-parametro"].show()
    },
    openDeleteModal() {
      this.itemToDelete = {
        ...this.item,
        type: this.determineType(
          this.item.instrumental,
          this.item.has_attachment
        )
      }
      this.$refs["modal-delete"].show()
    },
    closeDeleteModal() {
      this.$refs["modal-delete"].hide()
    },
    deleteItem() {
      this.updateModalText("delete")
      this.$refs["modal-parametro"].show()
    },
    populateForm() {
      if (this.item && Object.keys(this.item).length > 0) {
        this.form.name = this.item.name
        this.form.type = this.determineType(
          this.item.instrumental,
          this.item.has_attachment
        )
        this.form.um = this.item.um
        this.form.instrumental = this.item.instrumental
        this.form.has_attachment = this.item.has_attachment
      }
    },
    determineType(instrumental, has_attachment) {
      if (!instrumental && !has_attachment) {
        return "parametri"
      } else if (instrumental && !has_attachment) {
        return "esami"
      } else if (instrumental && has_attachment) {
        return "referti"
      }
      return null
    },
    updateModalText(action) {
      if (action === "create") {
        this.modalTitle = "Nuovo parametro"
      } else if (action === "edit") {
        this.modalTitle = `Modifica ${this.getItemTypeName()}`
      } else if (action === "delete") {
        this.modalTitle = `Elimina ${this.getItemTypeName()}`
      }
    },
    getItemTypeName() {
      switch (this.itemToDelete?.type || this.form.type) {
        case "parametri":
          return "parametro"
        case "esami":
          return "esame"
        case "referti":
          return "referto"
        default:
          return "elemento"
      }
    },
    getItemTypeArticle() {
      return (this.itemToDelete?.type || this.form.type) === "esami"
        ? "l'"
        : "il"
    },
    submitForm() {
      const form = {
        name: this.form.name,
        um: this.form.type !== "referti" ? this.form.um : null,
        instrumental: this.form.instrumental,
        has_attachment: this.form.has_attachment
      }
      const self = this
      if (this.action === "create") {
        adminService
          .addNewExam(form)
          .then(function (resp) {
            self.successAlert(
              ` ${self.form.name} è stato inserito correttamente`
            )
            self.$refs["modal-parametro"].hide()
            self.resetForm()
            self.$emit("refresh")
          })
          .catch(function (err) {
            self.errorAlert("Valore già presente a sistema")
          })
      } else if (this.action === "editDelete") {
        adminService
          .updateExam(this.item.hashid, form)
          .then(function (resp) {
            self.successAlert(
              ` ${self.form.name} è stato modificato correttamente`
            )
            self.$refs["modal-parametro"].hide()
            self.resetForm()
            self.$emit("refresh")
          })
          .catch(function (response) {
            console.log("response", response)
            const alert =
              response?.data?.message ?? "Errore durante la modifica del valore"
            console.log("alert", alert)
            self.errorAlert(alert)
          })
      }
    },
    confirmDelete() {
      const self = this
      adminService
        .deleteExam(this.itemToDelete.hashid)
        .then(function () {
          self.successAlert("Elemento eliminato con successo")
          self.$refs["modal-delete"].hide()
          self.$emit("refresh")
        })
        .catch(function (response) {
          const alert =
            response?.data?.message ??
            "Errore durante l'eliminazione dell'elemento"
          self.errorAlert(alert)
        })
    },
    resetForm() {
      this.form.name = null
      this.form.type = null
      this.form.um = null
      this.form.instrumental = false
      this.form.has_attachment = false
    }
  }
}
</script>
