<template>
  <div
    v-if="data && company"
    class="general_border_17 d-flex flex-column general_hover point shadow_2 fs-6 position-relative mr-3"
    :class="isSelected ? 'bg-secondary text-white' : 'bg-white text-secondary'"
    style="
      min-width: 280px;
      width: 280px;
      border-radius: 8px;
      min-height: 200px;
    "
    @click="selectThis"
  >
    <div
      v-if="company.convenzionata && showConvenzionataBadge"
      class="res-card-logo-container py-2"
    >
      <img
        src="@/assets/concura-logo-con-white.svg"
        alt="appLogo"
        width="30px"
      />
    </div>
    <div
      :class="{
        'd-flex flex-column align-items-center text-center h-100': true,
        invisible: showInfo,
        visible: !showInfo
      }"
    >
      <div class="d-flex justify-content-center w-100 position-relative">
        <img
          v-if="company.profile_pic"
          :src="company.profile_pic"
          alt="Profile Picture"
          class="company-img"
        />
        <img
          v-else
          src="@/assets/no_avatar.svg"
          alt="No Avatar"
          class="company-img"
        />
      </div>
      <div class="text-center p-2 mt-3 flex-grow-1">
        <p class="mb-2 fs-1rem">
          {{ company.denominazione }}
        </p>

        <div v-if="pickedHSName">
          <p class="fw-bolder">
            {{ trimmedHSName }}
          </p>

          <p v-if="data.prestazione.description">
            {{ data.prestazione.description }}
          </p>
          <p v-if="costoPrevistoInSede" class="mb-1 fw-bolder">
            {{ costoPrevistoInSede }}
          </p>
        </div>
      </div>
      <p class="mb-0 mt-auto pb-3">
        <span v-if="showHeart" @click.stop="$emit('toggle-preferito', company)">
          <b-icon
            font-scale="1.3"
            class="text-center"
            :icon="
              user.farmacia_preferita &&
              company.hashid === user.farmacia_preferita.hashid
                ? 'heart-fill'
                : 'heart'
            "
            :variant="isSelected ? 'white' : 'secondary'"
          ></b-icon>
        </span>
        <span v-if="suggest" @click.stop="$emit('add-suggestion', company)">
          <b-button class="text-left my-auto">
            <b-icon icon="plus"></b-icon> aggiungi
          </b-button>
        </span>
      </p>
    </div>
    <div
      class="d-flex flex-column p-2 align-items-center text-center position-absolute w-100 h-100"
      :class="{ visible: showInfo, invisible: !showInfo }"
      style="top: 0; left: 0"
    >
      <div class="d-flex justify-content-center align-items-center flex-grow-1">
        <h6 :class="isSelected ? 'text-white' : 'text-secondary'">
          <span class="font-weight-bold">Indirizzo:</span><br />
          {{ company.indirizzo }}, {{ company.frazione }}<br />
          <span class="text-capitalize">{{ company.comune }}</span>
        </h6>
      </div>
    </div>
    <div
      class="d-flex justify-content-end position-absolute w-100"
      style="top: 10px; right: 10px"
    >
      <span @click.stop="toggleInfo">
        <b-icon
          font-scale="1.5"
          icon="info-circle"
          :variant="isSelected ? 'white' : 'secondary'"
        ></b-icon>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  name: "CompanyReservationCard",
  props: {
    data: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    showHeart: {
      type: Boolean,
      default: true
    },
    suggest: {
      type: Boolean,
      default: false
    },
    pickedHSName: {
      type: String,
      default: null
    },
    company: {
      type: Object,
      required: true
    },
    showConvenzionataBadge: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    priceInEuros() {
      return parseFloat(this.data.prezzo).toLocaleString("it-IT", {
        style: "currency",
        currency: "EUR"
      })
    },
    trimmedHSName() {
      if (this.pickedHSName) {
        return this.pickedHSName.length > 60
          ? this.pickedHSName.substring(0, 60) + "..."
          : this.pickedHSName
      }
      return null
    },
    costoPrevistoInSede: function () {
      if (this.data.prestazione && this.data.prestazione.estremi_fattura) {
        return parseFloat(
          this.data.prestazione.estremi_fattura.imponibile +
            this.data.prestazione.estremi_fattura.ivatot
        ).toLocaleString("it-IT", {
          style: "currency",
          currency: "EUR"
        })
      }
      return null
    }
  },
  data() {
    return {
      showInfo: false
    }
  },
  methods: {
    selectThis() {
      this.$emit("selected-rescompany", this.data)
    },
    toggleInfo() {
      this.showInfo = !this.showInfo
    }
  }
}
</script>

<style lang="scss" scoped>
.company-img {
  min-height: 60px;
  min-width: 60px;
  max-width: 150px;
  max-height: 100px;
  border-radius: $border-radius;
  margin-top: 20px;
  object-fit: contain;
}

.general_border_17 {
  border-radius: 8px !important;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.res-card-logo-container {
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #033d68;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
</style>
