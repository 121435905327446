<template>
    <div>
        <b-row class="mb-4 align-items-center pl-3">
            <b-button variant="secondary text-white" @click="fetchRegisteredUsersExcel">
                Scarica tutto in formato Excel
            </b-button>
            <b-button variant="outline-secondary text-secondary custom-button ml-4" @click="resetTotale">
                Reset
            </b-button>
            <!-- Inserisci un v-if nel button fonte -->
            <b-button v-if="filters.fonte" variant="primary" class="ml-4" size="sm">
                Fonte: {{ filters.fonte }} <b-icon icon="x" class="ml-1" @click="resetFonte"></b-icon>
            </b-button>
            <b-button v-if="filters.progetti" variant="primary" class="ml-4" size="sm">
                {{ filters.progetti }} <b-icon icon="x" class="ml-1" @click="resetProgetti"></b-icon>
            </b-button>
            <span class="font-weight-bold ml-4">Totale: <b>{{ total }}</b></span>
        </b-row>

        <b-row class="d-flex align-items-center">
            <b-col cols="3">
                <b-form-select :options="takeOptions" name="takes" v-model="filters.take" required>
                </b-form-select>
            </b-col>
            <span>Filtra per:</span>
            <b-col cols="3">
                <b-form-select v-model="filters.fonte" :options="fonti" class="mx-1">
                </b-form-select>
            </b-col>
            <b-col cols="3">
                <b-form-select v-model="selectedProgetto" :options="progetti" class="mx-1">
                </b-form-select>
            </b-col>
        </b-row>
        <b-table borderless hover responsive class="text-center puntofarmaCard mt-4" :fields="fields"
            :items="registeredUsers">
        </b-table>
        <div>
            <b-pagination v-model="filters.page" pills :total-rows="total" :per-page="filters.take" align="center">
            </b-pagination>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { adminService, utilityService } from '../../_services';
import * as moment from "moment/moment";

export default {
    name: "AdminRegistrazioni",
    data() {
        return {
            filters: {
                page: 1,
                take: 10,
                fonte: null,
                progetti: null,
            },
            total: 0,
            selectedProgetto: null,
            takeOptions: [
                { text: "mostra 10 elementi", value: 10 },
                { text: "mostra 25 elementi", value: 25 },
                { text: "mostra 50 elementi", value: 50 },
                { text: "mostra 75 elementi", value: 75 },
                { text: "mostra 100 elementi", value: 100 },
                { text: "mostra 200 elementi", value: 200 },
                { text: "mostra 300 elementi", value: 300 },
            ],
            fields: [
                {
                    key: "created_at",
                    label: "Data",
                    formatter: value => moment(value).format('DD/MM/YY')
                },
                {
                    key: "anagrafica.fullname",
                    label: "Nominativo",
                },
                {
                    key: "anagrafica.comune",
                    label: "Città",
                },
                {
                    key: "anagrafica.address",
                    label: "Indirizzo",
                },
                {
                    key: "progetti",
                    label: "Progetto",
                },
                {
                    key: "fonte",
                    label: "Fonte",
                },
            ],
            registeredUsers: null,
            progetti: null,
            fonti: null,
        }
    },
    methods: {
        ...mapActions({
            clearAlert: "alert/clear",
            errorAlert: "alert/error",
            successAlert: "alert/success",
            infoAlert: "alert/info",
        }),
        fetchData() {
            var self = this;
            adminService
                .getRegisteredUsers(this.filters)
                .then(function (resp) {
                    console.log(resp.data.data.data)
                    self.registeredUsers = resp.data.data.data;
                    self.filters.page = resp.data.data.current_page;
                    self.total = resp.data.data.total;
                })
                .catch(function (err) {
                    console.log(err);
                });
        },
        fetchProgetti() {
            var self = this;
            utilityService
                .getProjects()
                .then(function (resp) {
                    self.progetti = [{ text: "Progetti", value: null, disabled: true }, ...resp.data.data.map(el => ({ text: el.name, value: el.name }))];
                })
                .catch(function (err) {
                    console.log(err);
                });
        },
        fetchFonti() {
            var self = this;
            const ref = 1
            utilityService
                .getFonti(ref)
                .then(function (resp) {
                    self.fonti = [{ text: "Fonte", value: null, disabled: true }, ...resp.data.data.map(el => ({ text: el, value: el }))];
                })
                .catch(function (err) {
                    console.log(err);
                });
        },
        fetchRegisteredUsersExcel() {
            var self = this;
            adminService
                .getRegisteredUserExcel(this.filters)
                .then(function (response) {
                    let blob = new Blob([response.data]);
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `Utenti registrati ${moment().format(
                        "DD-MM-YYYY"
                    )}.xlsx`;
                    link.click();
                })
                .catch(function (err) {
                    // console.log(err);
                    window.scrollTo(0, 0);
                    self.errorAlert("Non è stato possibile scaricare il file");
                });
        },

        resetFonte() {
            this.filters.fonte = null;
            this.fetchData();
        },
        resetProgetti() {
            this.filters.progetti = null;
            this.selectedProgetto = null
            this.fetchData();
        },
        resetTotale() {
            this.filters.progetti = null;
            this.filters.fonte = null;
            this.selectedProgetto = null
            this.fetchData();
        },
        handleProgettoSelection() {
            let progettiArray = this.filters.progetti ? this.filters.progetti.split(', ') : [];
            if (progettiArray.includes(this.selectedProgetto)) return;
            if (progettiArray.length < 2) {
                progettiArray.push(this.selectedProgetto);
            } else {
                progettiArray.shift();
                progettiArray.push(this.selectedProgetto);
            }
            this.filters.progetti = progettiArray.join(', ');
            this.fetchData();
        },
    },
    created() {
        this.fetchData();
        this.fetchProgetti();
        this.fetchFonti();
    },
    watch: {
        "filters.page": function () {
            this.fetchData();
        },
        "filters.take": function () {
            this.fetchData();
        },
        "filters.fonte": function () {
            this.fetchData();
        },
        "filters.progetti": function () {
            this.fetchData();
        },
        "selectedProgetto": function () {
            if (this.selectedProgetto) {
                this.handleProgettoSelection();
            }
        }

    },
}
</script>

<style>
.custom-button:hover {
    color: white !important;
}
</style>
