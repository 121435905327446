<template>
  <div>
    <b-iconstack font-scale="4" class="point" @click="toggleModal()">
      <b-icon stacked icon="circle-fill" variant="mdm-light-grey"></b-icon>
      <b-icon stacked icon="sliders" scale="0.5" variant="secondary"></b-icon>
    </b-iconstack>

    <transition-group name="slideside" mode="out-in">
      <b-row
        v-if="modalOpen"
        class="w-100 col-12 align-content-start px-0"
        key="nm-opened"
        style="
          z-index: 99999;
          position: fixed;
          top: 0px;
          right: 0px;
          height: 100vh;
        "
      >
        <b-col class="d-none d-md-block bg__g h-100" @click="toggleModal()">
        </b-col>
        <b-col
          class="bg-whitesmoke px-0 col-12 col-md-5 col-lg-4 col-lgx-3 col-xl-2 bg__w h-100 general_border z-index-9999 d-flex flex-column"
        >
          <b-row
            class="px-0 d-flex justify-content-between align-items-start mt-4 mt-2 px-4 mx-4"
          >
            <h1>Filtri</h1>
            <b-icon
              class="point"
              variant="secondary"
              icon="x"
              font-scale="3"
              @click="toggleModal()"
            ></b-icon>
          </b-row>
          <b-row
            class="px-0 d-flex justify-content-between align-items-start mt-4 mt-2 px-4 mx-4"
          >
            <div class="col-12 px-0 mb-4" v-if="statusFilterEnabled">
              <h5 class="mb-3 font-weight-bolder text-left">
                Filtra per Stato
              </h5>
              <b-form-select
                v-capitalize-first-select
                size="sm"
                class=" "
                v-model="localStatusFilter"
              >
                <b-form-select-option :value="undefined"
                  >tutti</b-form-select-option
                >
                <b-form-select-option
                  v-for="(stat, ind) in filterOptions.status"
                  :key="ind"
                  :value="stat"
                  >{{ stat }}
                </b-form-select-option>
              </b-form-select>
            </div>
            <div class="col-12 px-0 mb-4 text-left">
              <b-form-checkbox
                id="hide-canceled"
                name="hide-canceled"
                size="sm"
                :value="true"
                :unchecked-value="false"
                v-model="localNotCanceledFilter"
              >
                Escludi appuntamenti annullati
              </b-form-checkbox>
            </div>
            <div class="col-12 px-0 mb-4" v-if="serviceFilterEnabled">
              <h5 class="mb-3 font-weight-bolder text-left">
                Filtra per Servizio
              </h5>
              <b-form-select
                v-capitalize-first-select
                size="sm"
                class=" "
                v-model="localServiceFilter"
              >
                <b-form-select-option :value="undefined"
                  >tutti</b-form-select-option
                >
                <b-form-select-option
                  v-for="(service, ind) in filterOptions.service"
                  :key="ind"
                  :value="service.value"
                  >{{ service.label }}</b-form-select-option
                >
              </b-form-select>
            </div>
            <div class="col-12 px-0 mb-4" v-if="prestazioneFilterEnabled">
              <h5 class="mb-3 font-weight-bolder text-left">
                Filtra per Prestazione
              </h5>
              <SelectCompanyHealthServices
                v-model="localPrestazioneFilter"
                ref="selectCompanyHealthServices"
                :hideDefaultOption="true"
                :showDescriptions="true"
                size="sm"
                :appendToEndOptions="[{ text: 'tutte', value: null }]"
              />
              <!-- <b-form-select
                size="sm"
                class=" "
                v-model="localPrestazioneFilter"
              >
                <b-form-select-option :value="null">tutte</b-form-select-option>
                <b-form-select-option
                  v-for="(prestazione, ind) in prestazioniSanitarie"
                  :key="ind"
                  :value="prestazione"
                  >{{ prestazione.name }}
                  <b-icon :icon="icons[prestazione.icon]"></b-icon>
                </b-form-select-option>
              </b-form-select> -->
            </div>
          </b-row>
          <b-col class="text-center my-3 px-4 mx-">
            <b-button size="sm" class="" block @click="modalOpen = false"
              >Chiudi</b-button
            >
          </b-col>
        </b-col>
      </b-row>
    </transition-group>
  </div>
</template>

<script>
import SelectCompanyHealthServices from "@/components/prenotazioni/form/SelectCompanyHealthServices.vue";
export default {
  name: "CalendarioFiltersComponent",
  props: [
    "filterOptions",
    "statusFilterEnabled",
    "statusFilter",
    "notCanceledFilter",
    "serviceFilterEnabled",
    "serviceFilter",
    "prestazioneFilterEnabled",
    "prestazioneFilter",
    "prestazioniSanitarie",
  ],
  components: {
    SelectCompanyHealthServices,
  },
  data() {
    return {
      modalOpen: false,
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill",
      },
    };
  },
  computed: {
    localStatusFilter: {
      get() {
        return this.statusFilter;
      },
      set(value) {
        this.$emit("status-filter-changed", value);
        this.$emit("fetch-data");
      },
    },
    localNotCanceledFilter: {
      get() {
        return this.notCanceledFilter;
      },
      set(value) {
        this.$emit("not-canceled-filter-changed", value);
        this.$emit("fetch-data");
      },
    },
    localServiceFilter: {
      get() {
        return this.serviceFilter;
      },
      set(value) {
        this.$emit("service-filter-changed", value);
        this.$emit("fetch-data");
      },
    },
    localPrestazioneFilter: {
      get() {
        return this.prestazioneFilter ?? null;
      },
      set(value) {
        if (!value) {
          this.$emit("prestazione-filter-changed", value);
        } else {
          const hashid = value?.hashid ?? value;
          if (value && this.$refs.selectCompanyHealthServices) {
            const prestazione =
              this.$refs.selectCompanyHealthServices.originalData.find(
                (el) => el.hashid === hashid
              );
            this.$emit("prestazione-filter-changed", prestazione);
          }
        }
        return this.prestazioneFilter;
      },
      // set(value) {
      //   this.$emit("prestazione-filter-changed", value)
      //   this.$emit("fetch-data")
      // }
    },
  },
  methods: {
    toggleModal() {
      this.modalOpen = !this.modalOpen;
    },
  },
};
</script>
<style scoped>
.slideside-enter-active {
  transition: transform 0.01s;
}

.slideside-enter {
  transform: translateX(100%);
}

.slideside-leave-active {
  transition: transform 0.1s;
}

.slideside-leave-to {
  transform: translateX(-100%);
}
</style>
