<template>
  <div class="pb-3">
    <div v-if="template" class="">
      <b-form @submit.prevent="sendForm">
        <b-row cols="1" class="align-items-end">
          <transition-group name="list" mode="out-in" appear>
            <b-row
              cols="1"
              cols-md="2"
              class="mb-3"
              v-for="(domanda, ind) in template.template.domande"
              :key="'d' + ind"
            >
              <b-col md="10" lg="6" xl="5" cols="12" class="text-justify">
                <h6 :for="'q_' + ind">
                  <span class="text-secondary"> {{ ind + 1 }}.</span>
                  {{ domanda.q }}
                  <span v-if="domanda.h" class="mb-0 text-secondary">
                    - domanda per l'operatore</span
                  >
                </h6>
              </b-col>
              <b-col md="8" lg="6" xl="7" cols="12" class="text-justify">
                <template v-if="domanda.t">
                  <!-- questionario con definizione di tipo input -->
                  <b-form-group :key="'fgr_' + ind">
                    <template v-if="domanda.t === 'text'">
                      <b-form-input
                        v-capitalize-first
                        class="align-self-center"
                        placeholder="..."
                        type="text"
                        :name="'q_' + ind"
                        v-model="domanda.a"
                      ></b-form-input>
                    </template>
                    <template v-else-if="domanda.t === 'select'">
                      <b-form-select
                        v-capitalize-first-select
                        class="px-3 align-self-center"
                        v-model="domanda.a"
                        :name="'q_' + ind"
                        required
                      >
                        <b-form-select-option
                          v-for="opt in domanda.o"
                          :key="opt"
                          :value="opt"
                          >{{ opt }}
                        </b-form-select-option>
                        <!-- <b-form-select-option :value="null" disabled>seleziona</b-form-select-option> -->
                      </b-form-select>
                    </template>
                    <template v-else-if="domanda.t === 'select-multi'">
                      <b-form-tags
                        :name="'q_' + ind"
                        v-model="domanda.a"
                        size="lg"
                        class="mb-2"
                        add-on-change
                        no-outer-focus
                        @focus="hasFocus = ind"
                        @focusin="hasFocus = ind"
                      >
                        <template
                          v-slot="{
                            tags,
                            inputAttrs,
                            inputHandlers,
                            disabled,
                            removeTag,
                            addTag
                          }"
                        >
                          <b-form-select
                            v-capitalize-first-select
                            v-bind="inputAttrs"
                            v-on="inputHandlers"
                            :disabled="disabled || domanda.o.length === 0"
                            :options="domanda.o"
                          >
                            <template #first>
                              <!-- This is required to prevent bugs with Safari -->
                              <option disabled value="">
                                scegli una o più opzioni...
                              </option>
                            </template>
                          </b-form-select>
                          <ul
                            v-if="tags.length > 0"
                            class="list-inline d-inline-block mt-2"
                          >
                            <li
                              v-for="tag in tags"
                              :key="tag"
                              class="list-inline-item"
                            >
                              <b-form-tag
                                @remove="removeTag(tag)"
                                :title="tag"
                                :disabled="disabled"
                                variant="secondary"
                                >{{ tag }}</b-form-tag
                              >
                            </li>
                          </ul>
                        </template>
                      </b-form-tags>

                      <template
                        v-if="
                          Array.isArray(domanda.a) &&
                          domanda.a.includes('altro')
                        "
                      >
                        <b-input-group v-if="hasFocus === ind">
                          <b-form-input
                            v-capitalize-first
                            class="align-self-center input_with_append"
                            placeholder="altra opzione personalizzata"
                            type="text"
                            :name="'qti_' + ind"
                            v-model="tempTag"
                          ></b-form-input>
                          <b-input-group-append>
                            <b-button @click="addCustom(ind)"
                              >aggiungi altro</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </template>
                    </template>
                    <template v-else-if="domanda.t === 'date'">
                      <b-form-input
                        class="align-self-center"
                        placeholder="GG/MM/AAAA"
                        type="date"
                        :name="'q_' + ind"
                        v-model="domanda.a"
                      ></b-form-input>
                    </template>
                  </b-form-group>

                  <!-- questionario con definizione di tipo input -->
                </template>
                <template v-else>
                  <b-form-group :key="'fgr_' + ind">
                    <div
                      v-if="domanda.o && domanda.o === 'file'"
                      class="align-self-center"
                    >
                      <b-button
                        class="general_border"
                        size="sm"
                        v-if="domanda.fm || !domanda.a || !domanda.a.length"
                        @click="openUploadModal(domanda)"
                      >
                        Aggiungi file
                        <b-icon icon="plus"></b-icon>
                      </b-button>
                      <b-row
                        v-for="(element, fileIndex) in domanda.a"
                        :key="fileIndex"
                        class="mt-2 d-flex align-items-center"
                      >
                        <b-col class="mb-0 col-2"
                          >File {{ fileIndex + 1 }}</b-col
                        >
                        <b-col
                          class="d-flex col-10 pl-0 justify-content-start text-left align-items-center"
                        >
                          <b-button
                            size="sm"
                            variant="secondary general_border"
                            class="mr-3"
                            @click="downloadResource(element)"
                          >
                            Scarica
                            <b-icon icon="download"></b-icon>
                          </b-button>
                          <b-button
                            size="sm"
                            class="mr-3"
                            variant="outline-secondary general_border"
                            @click="deleteResource(element, domanda)"
                          >
                            Elimina
                          </b-button>

                          <span class="ml-3 point" @click="openShowModal(hist)">
                            <b-icon
                              stacked
                              icon="eye-fill"
                              scale="0.5"
                              variant="secondary"
                            ></b-icon>
                          </span>
                        </b-col>
                      </b-row>
                    </div>
                    <b-form-checkbox-group
                      v-else-if="domanda.cb && domanda.o"
                      :name="'q_' + ind"
                      v-model="domanda.a"
                      :options="domanda.o"
                      name="flavour-1"
                    ></b-form-checkbox-group>

                    <b-form-select
                      v-capitalize-first-select
                      class="px-3 align-self-center"
                      v-else-if="domanda.o"
                      v-model="domanda.a"
                      :name="'q_' + ind"
                      required
                    >
                      <b-form-select-option
                        v-for="opt in domanda.o"
                        :key="opt"
                        :value="opt"
                        >{{ opt }}
                      </b-form-select-option>
                    </b-form-select>
                    <b-form-input
                      v-else
                      v-capitalize-first
                      class="align-self-center"
                      placeholder="..."
                      type="text"
                      :name="'q_' + ind"
                      v-model="domanda.a"
                      required
                    ></b-form-input>
                  </b-form-group>
                </template>
              </b-col>
              <b-col
                cols="12"
                v-if="
                  domanda.o &&
                  domanda.o === 'file' &&
                  domanda.r &&
                  (!domanda.a || !domanda.a.length)
                "
              >
                <p class="text-danger text-left">
                  Questo campo è obbligatorio.
                </p>
              </b-col>
            </b-row>
            <b-col class="my-4 py-2" key="btsubmt">
              <b-button
                type="button"
                variant="outline-secondary fw-bolder spacing_1"
                class="mx-1"
                @click="$router.back()"
              >
                Annulla</b-button
              >
              <b-button
                type="submit"
                variant="secondary fw-bolder spacing_1"
                class="mx-1"
                :disabled="filesAreMissing"
              >
                Salva Modifiche</b-button
              >
            </b-col>
          </transition-group>
        </b-row>
      </b-form>
    </div>
    <b-modal
      id="delete-resource"
      title="Elimina risorsa"
      button-size="sm"
      @ok="doDelete"
      @cancel="resetDeleteFile()"
      ok-variant="popup-button"
      cancel-variant="popup-button mr-2"
      cancel-title="Annulla"
      ok-title="Elimina"
      footer-border-variant="white"
      centered
    >
      <h6>Sei sicuro di voler eliminare la risorsa selezionata?</h6>
    </b-modal>
    <b-modal
      id="upload-modal"
      title="Carica file"
      size="lg"
      hide-footer
      centered
    >
      <SurveyUploader
        v-if="uploadingToDomanda"
        @upload-result="handleUpload($event)"
        @remove-result="removeUpload(ind)"
        :accepts="uploadingToDomanda.fa"
        :isMulti="uploadingToDomanda.fm"
      />
    </b-modal>
  </div>
</template>
<script>
import SurveyUploader from "@/components/utilities/SurveyUploader.vue"
import { utilityService } from "@/_services"
import { mapActions } from "vuex"
import Vue from "vue"

export default {
  name: "EditQuestionarioCompilato",
  props: ["survey"],
  components: { SurveyUploader },
  data() {
    return {
      template: null,
      consent: false,
      proceed: false,
      file: null,
      tempTag: null,
      hasFocus: null,
      resourceToDelete: {
        hash: null,
        domanda: null
      },
      filesAreMissing: false,
      uploadingToDomanda: null,
      lastUpdatedHash: null
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    openUploadModal(domanda) {
      this.uploadingToDomanda = domanda
      this.$bvModal.show("upload-modal")
    },
    resetDeleteFile() {
      this.resourceToDelete.hash = null
      this.resourceToDelete.domanda = null
    },
    // deleteResource(element, domanda) {
    //   console.log("deleting resource", element)

    //   const newArray = domanda.a.filter((item) => item !== element)

    //   Vue.set(domanda, "a", newArray)
    // },
    deleteResource(element, domanda) {
      this.resourceToDelete.hash = element
      this.resourceToDelete.domanda = domanda
      this.$bvModal.show("delete-resource")
    },
    doDelete() {
      const index = this.resourceToDelete.domanda.a.indexOf(
        this.resourceToDelete.hash
      )
      this.$delete(this.resourceToDelete.domanda.a, index)
      this.checkRequiredFiles()

      this.$forceUpdate()
    },
    checkRequiredFiles() {
      let check = false

      this.template.template.domande.forEach((domanda) => {
        if (domanda.o === "file" && domanda.r === true) {
          if (!Array.isArray(domanda.a) || domanda.a.length === 0) {
            check = true
          }
        }
      })

      this.filesAreMissing = check
    },
    downloadResource(resourceHash) {
      var self = this
      utilityService
        .downloadResource(resourceHash)
        .then(function (response) {
          // console.log("response", response.headers)
          let contentType = response.headers["content-type"]
          let blob = new Blob([response.data], { type: contentType })
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)

          let contentDisposition = response.headers["content-disposition"]
          let fileName = contentDisposition.split("filename=")[1].split(";")[0]

          // Rimozione di eventuali underscore
          fileName = fileName.replace(/^_+|_+$/g, "").replace(/\"/g, "")

          link.download = fileName
          link.click()
        })
        .catch(function (err) {
          console.log(err)
          self.errorAlert("Non è stato possibile scaricare la risorsa")
        })
    },

    onFileChange(e) {
      this.file = e.target.files[0]
    },
    sendForm() {
      this.$emit("filled-form", this.template)
    },
    handleUpload(data) {
      this.$bvModal.hide("upload-modal")
      const newHashids = []
      if (Array.isArray(data)) {
        data.forEach((el) => {
          newHashids.push(el.hashid)
        })
      } else {
        newHashids.push(data.hashid)
      }
      console.log("data", data)
      console.log("newHashids", newHashids)
      if (this.uploadingToDomanda.a == null) {
        this.uploadingToDomanda.a = []
      }
      this.uploadingToDomanda.a.push(...newHashids)
      this.lastUpdatedHash = data.hashid
      this.checkRequiredFiles()
    },
    removeUpload() {
      const index = this.uploadingToDomanda.a.indexOf(this.lastUpdatedHash)
      this.$delete(this.uploadingToDomanda.a, index)
      this.checkRequiredFiles()
    },
    addCustom(ind) {
      this.template.template.domande[ind].a.push(this.tempTag)
      this.tempTag = null
    }
  },
  created() {
    if (this.survey) {
      this.template = JSON.parse(JSON.stringify(this.survey))
      try {
        var parsed = JSON.parse(this.survey.response)
        this.template.template = parsed
      } catch {
        //
      }
    }
  }
}
</script>
