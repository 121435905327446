<template>
    <div v-if="company">
        <h4 class="text-left pb-1">Sovrapposizione appuntamenti tra servizi</h4>
        <p class="text-justify">
            Se questa opzione è disattivata, il sistema impedirà la sovrapposizione di
            appuntamenti per servizi e prestazioni diverse nella stessa fascia oraria:
            non sarà possibile, ad esempio, ricevere prenotazioni per un ordine e un
            teleconsulto nello stesso orario.
        </p>

        <b-row cols="1">
            <b-col class="text-left">
                <b-form-checkbox v-model="form.overlap" name="overlap_settings" :value="true" :unchecked-value="false"
                    @change="saveChanges">
                    {{ form.overlap ? "Consentita" : "Non consentita" }} sovrapposizione
                    degli appuntamenti
                </b-form-checkbox>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";

export default {
    name: "OtherSettingsCentriErogatori",
    props: ["company"],
    computed: {
        ...mapState("utente", ["status", "user"]),
        ...mapState({
            alert: (state) => state.alert,
        }),
    },
    data() {
        return {
            form: {
                overlap: false,
            },
        };
    },
    watch: {
        company: {
            handler(val) {
                if (val) {
                    this.form.overlap = val.overlap_reservations;
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        ...mapActions({
            clearAlert: "alert/clear",
            errorAlert: "alert/error",
            successAlert: "alert/success",
            infoAlert: "alert/info",
        }),
        saveChanges() {
        const self = this;
        const hashid = this.$route.params.hashid;
        companyService
            .changeCompanyOverlapOption(hashid, this.form)
            .then(function (res) {
                window.scrollTo(0, 0);
                self.successAlert("Impostazioni modificate correttamente");
                self.$emit('updateCompany');
            })
            .catch(function (err) {
                console.log(err);
                window.scrollTo(0, 0);
                self.errorAlert("Non è stato possibile modificare le impostazioni");
            });
    },
    },
};
</script>
