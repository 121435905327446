import { render, staticRenderFns } from "./Data.vue?vue&type=template&id=9def2756&scoped=true&"
import script from "./Data.vue?vue&type=script&lang=js&"
export * from "./Data.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9def2756",
  null
  
)

export default component.exports