var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user.type === 2)?_c('div',[_c('transition-group',{attrs:{"name":"list","mode":"out-in","appear":""}},[(!_vm.$route.params.section || _vm.$route.params.section === 'raccolte')?_c('div',{key:"raccolte"},[_c('AdminHomeAttivita')],1):(
        _vm.$route.params.section === 'questionari' ||
        _vm.$route.params.section === 'questionari/nuovo'
      )?_c('div',{key:"questionari"},[(
          !_vm.$route.params.action &&
          _vm.$route.params.section !== 'questionari/nuovo'
        )?[_c('AdminQuestionari')]:(_vm.$route.params.action === 'report')?[_c('QuestionariCompilati')]:[_c('CreaQuestionario')]],2):(
        _vm.$route.params.section === 'questionario' &&
        _vm.$route.params.action === 'modifica'
      )?_c('div',{key:"editquestionario"},[_c('AggiornaQuestionario')],1):_c('div',{key:"crea"})])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }