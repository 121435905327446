<template>
  <div class="home" v-if="user">
    <b-alert
      variant="danger"
      :show="showVerificationAlert"
      class="col-lg-8 col-sm-12 mr-0 ml-auto point"
    >
      <span id="ver__al"
        >Non hai ancora verificato il tuo indirizzo email

        <b-icon icon="info-circle" variant="info"></b-icon>
      </span>
      <b-tooltip target="ver__al"
        >Per potere utilizzare la piattaforma è necessario verificare
        l'indirizzo email</b-tooltip
      >
    </b-alert>
    <b-alert
      variant="danger"
      :show="showOtpVerificationAlert"
      class="col-lg-8 col-sm-12 mr-0 ml-auto point"
    >
      <span id="ver__al"
        >Non hai ancora verificato il tuo numero di telefono

        <b-icon icon="info-circle" variant="info"></b-icon>
      </span>
      <b-tooltip target="ver__al"
        >Per potere utilizzare la piattaforma è necessario verificare il numero
        di telefono</b-tooltip
      >
    </b-alert>

    <b-alert
      variant="danger"
      :show="showAnagraficaAlert"
      class="col-lg-8 col-sm-12 mr-0 ml-auto point"
    >
      <span id="angr__al"
        >Non hai ancora compilato la tua anagrafica

        <b-icon icon="info-circle" variant="info"></b-icon>
      </span>
      <b-tooltip target="angr__al"
        >Per usufruire a pieno di tutti i servizi messi a disposizione da questa
        piattaforma è necessario compilare la propria scheda
        anagrafica</b-tooltip
      >
    </b-alert>
    <ConsenseComponent
      v-if="showConsensesAlert"
      @consense-ok="handleConsense"
    ></ConsenseComponent>
  </div>
  <b-spinner v-else></b-spinner>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { userService } from "@/_services";
import ConsenseComponent from "@/components/home/ConsenseComponent.vue";

export default {
  name: "Home",
  components: { ConsenseComponent },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      showVerificationAlert: false,
      showAnagraficaAlert: false,
      showConsensesAlert: false,
      showOtpVerificationAlert: false,
      loading: false,
    };
  },
  mounted() {
    // if (this.$route.name === "Home") {
    this.checks();
    // }
  },
  methods: {
    ...mapActions("utente", ["unMe", "me"]),
    // checks() {
    //   if (!this.user) {
    //     return this.unMe();
    //   }
    //   this.showOtpVerificationAlert = this.user.number_verified_at
    //     ? false
    //     : true;

    //   this.showConsensesAlert = this.user.all_consenses ? false : true;
    //   if (!this.user.number_verified_at) {
    //     this.showOtpVerificationAlert = true;
    //     this.$router.push({
    //       name: "NumberVerification",
    //     });
    //     return;
    //   }
    //   if (this.user.email && !this.user.email_verified_at) {
    //     this.showVerificationAlert = true;
    //     this.$router.push({
    //       name: "EmailVerification",
    //       params: { status: "invalid" },
    //     });
    //   }
    //   if (!this.user.all_consenses) {
    //     this.showConsensesAlert = true;
    //     this.loading = false;
    //     return;
    //   }
    //   if (!this.user.anagrafica && this.user.type !== 2) {
    //     this.showAnagraficaAlert = true;
    //     return;
    //   }
    //   if (this.user.i_am !== "admin" && this.user.temporary_password) {
    //     if (this.user.i_am === "operatore") {
    //       this.$router.push("/operatore/profilo?scope=temporary");
    //       return;
    //     }
    //     return this.$router.push(
    //       "/" + this.user.i_am + "/impostazioni?scope=temporary"
    //     );
    //   } else if (this.user.expired_password == 1) {
    //     var routeName = "Impostazioni";
    //     if (this.user.i_am === "operatore") {
    //       this.$router.push("/operatore/profilo?scope=expired");
    //       return;
    //     }
    //     this.$router.push("/" + this.user.i_am + "/impostazioni?scope=expired");
    //   }
    //   if (this.$route.name === "Home") {
    //     this.$router.push("/" + this.user.i_am + "/home");
    //   }
    // },
    handleConsense() {
      this.showConsensesAlert = false;
      this.checks();
    },
    checks() {
      if (!this.user) {
        return this.unMe();
      }
      const self = this;
      userService
        .validateEmail()
        .then(function (res) {
          const utente = res.data.data;
          self.showOtpVerificationAlert = self.user.number_verified_at
            ? false
            : true;

          //verifica validazione numero
          if (!utente.number_verified_at) {
            self.showOtpVerificationAlert = true;
            console.log(self.showOtpVerificationAlert);
            self.$router.push({
              name: "NumberVerification",
            });
            return;
          }
          // verifica validazione email
          if (utente.email && !utente.email_verified_at) {
            self.showVerificationAlert = true;
            self.$router.push({
              name: "EmailVerification",
              params: { status: "invalid" },
            });
            return;
          }
          //verifica compilazione anagrafica
          if (!utente.anagrafica && utente.type !== 2) {
            self.showAnagraficaAlert = true;
            self.loading = false;
            return;
          }
          self.showConsensesAlert = self.user.all_consenses ? false : true;
          //verifica accettazione informative
          if (!utente.all_consenses) {
            self.showConsensesAlert = true;
            self.loading = false;
            return;
          }
          if (utente.i_am !== "admin" && utente.temporary_password) {
            if (utente.i_am === "operatore") {
              self.$router.push("/operatore/profilo?scope=temporary");
              return;
            }
            return self.$router.push(
              "/" + utente.i_am + "/impostazioni?scope=temporary"
            );
          } else if (utente.expired_password == 1) {
            var routeName = "Impostazioni";
            if (utente.i_am === "operatore") {
              self.$router.push("/operatore/profilo?scope=expired");
              return;
            }
            self.$router.push(
              "/" + utente.i_am + "/impostazioni?scope=expired"
            );
          }
          self.redirectToOwnHome();
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    redirectToOwnHome() {
      if (this.$route.name === "Home") {
        this.$router.push("/" + this.user.i_am + "/home");
      }
    },
  },
};
</script>
