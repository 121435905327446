<template>
  <div>
    <b-card
      no-body
      border-variant="white"
      class="puntofarmaCard general_border mb-3"
    >
      <b-row class="p-3 justify-content-between flex-wrap" align-v="center">
        <b-col class="px-0 col-2 col-md-1 my-1">
          <!-- <span class="point text-left"> -->
          <b-avatar
            :src="user.profile_pic"
            size="lg"
            :text="nomeOperatore"
            variant="secondary"
            rounded
          >
          </b-avatar>
          <!-- </span> -->
        </b-col>
        <b-col
          class="pl-0 point col-10 col-md-11 my-1"
          @click="textFocused = true"
        >
          <b-form-textarea
            v-capitalize-first
            v-model="localAnnotation.content"
            id="textarea-plaintext"
            placeholder="Aggiungi nota semplice"
            rows="1"
          >
          </b-form-textarea>
        </b-col>
      </b-row>
      <b-row
        align-v="center"
        class="pb-2 mb-2 justify-content-between"
        v-if="textFocused"
      >
        <b-col>
          <b-form-radio-group
            v-if="editMode"
            label="Visibilità Paziente"
            class="text-left pt-2"
            v-model="localAnnotation.shared"
          >
            <b-form-radio :value="0">Non visibile al paziente</b-form-radio>
            <b-form-radio :value="1">Visibile al paziente</b-form-radio>
          </b-form-radio-group>
        </b-col>
        <b-col class="text-right">
          <b-button
            @click="storeNewAnnotation()"
            size="sm"
            variant="secondary mx-1 my-1"
            :disabled="
              localAnnotation.content == null || localAnnotation.content == ''
            "
          >
            Salva nota
          </b-button>
          <b-button
            @click="resetEdit()"
            size="sm"
            variant="outline-secondary mx-1 my-1"
            >Annulla
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { mapState, mapActions } from "vuex"
import { companyService } from "@/_services"

export default {
  name: "newAnnotationComponent",
  props: ["subjectHash"],
  computed: {
    nomeOperatore() {
      if (this.user && this.user.anagrafica) {
        return this.user.anagrafica.name[0] + this.user.anagrafica.surname[0]
      }
      return "Op"
    },
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"])
  },

  data() {
    return {
      todayDate: "",
      localAnnotation: {
        content: null,
        shared: 0,
        is_soap: false,
        user_id: this.subjectHash
      },
      startingAnnotation: {},
      annotationHash: null,
      showContent: true,
      editMode: true,
      textFocused: false
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    resetEdit() {
      this.localAnnotation.content = null
      this.localAnnotation.shared = 0
      this.textFocused = false
    },
    storeNewAnnotation() {
      var self = this
      // console.log("Update");
      companyService
        .storeNewAnnotation(self.localAnnotation)
        .then(function (response) {
          window.scrollTo(0, 0)
          self.resetEdit()
          self.successAlert("Nota inserita correttamente")
          self.$emit("inserted-note")
        })
        .catch(function (error) {
          window.scrollTo(0, 0)
          self.errorAlert("Errore nell'inserimento della nota")
        })
    }
  },
  created() {
    this.todayDate = moment().format("d MMMM")
  }
}
</script>
