<template>
  <b-container fluid class="px-0">
    <b-row cols="1" v-if="showDisponibilita" class="my-2" ref="calendarchoice">
      <h6
        v-if="!notitle"
        class="mt-2 text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Scegli data ed ora
      </h6>
    </b-row>
    <b-row class="justify-content-end">
      <span class="mx-2">
        <label for="month_selector" class="mr-1">Mese</label>
        <select
          v-capitalize-first-select
          class="form-select bg-white border border-info general_border py-1"
          @change="onMonthYearChange"
          v-model="selectedMonth"
        >
          <option
            v-for="month in monthMapping"
            :key="month.id"
            :value="month.id"
            id="month_selector"
            :disabled="isMonthDisabled(month.id)"
          >
            {{ month.name }}
          </option>
        </select>
      </span>
      <span>
        <label for="year_selector" class="mr-1">Anno</label>
        <select
          v-capitalize-first-select
          class="form-select bg-white border border-info general_border py-1"
          v-model="selectedYear"
          id="year_selector"
          @change="onMonthYearChange"
        >
          <option v-for="year in years" :key="year" :value="year">
            {{ year }}
          </option>
        </select>
      </span>
    </b-row>
    <b-row
      cols="1"
      v-if="showDisponibilita && calendarChoice"
      class="my-2 text-center"
    >
      <b-col class="px-0">
        <div
          class="d-flex justify-content-between align-items-center pt-1 pb-3"
        >
          <b-button
            v-if="!$root.isMobile"
            variant="outline-secondary  border-white"
            :size="$root.isMobile ? 'sm' : ''"
            type="button"
            @click="setPrev"
            :disabled="blockPrecedente"
          >
            <b-icon icon="chevron-double-left" class="mr-2"></b-icon>Precedente
          </b-button>
          <b-iconstack
            v-else
            @click="setPrev"
            :class="{ half_opacity: blockPrecedente }"
            font-scale="2.2"
            class="point"
          >
            <b-icon
              stacked
              icon="circle-fill"
              variant="mdm-light-grey"
            ></b-icon>
            <b-icon
              stacked
              icon="chevron-double-left"
              scale="0.5"
              variant="secondary"
            ></b-icon>
          </b-iconstack>
          <h5 class="mb-0 text-capitalize">
            {{ start | moment("D MMM") }} - {{ end | moment("D MMM") }}
          </h5>
          <b-button
            v-if="!$root.isMobile"
            variant="outline-secondary  border-white"
            type="button"
            :disabled="blockSuccessivo"
            :size="$root.isMobile ? 'sm' : ''"
            @click="setPost"
          >
            Successiva
            <b-icon icon="chevron-double-right"></b-icon>
          </b-button>
          <b-iconstack
            v-else
            @click="setPost"
            v-b-popover.hover.top="''"
            font-scale="2.2"
            class="point"
            :class="{ half_opacity: blockSuccessivo }"
          >
            <b-icon
              stacked
              icon="circle-fill"
              variant="mdm-light-grey"
            ></b-icon>
            <b-icon
              stacked
              icon="chevron-double-right"
              scale="0.5"
              variant="secondary"
            ></b-icon>
          </b-iconstack>
        </div>
        <b-table
          borderless
          responsive
          class="no-bold-th"
          table-variant="white"
          :fields="fields"
          :items="disponibilita"
          foot-clone
          small
          show-empty
          :sticky-header="isinsidemodal ? '40vh' : '550px'"
        >
          <template #cell(1)="row">
            <p
              @click="selectDate(1, slot)"
              class="general_border point slot__tm"
              v-for="slot in row.item['1']"
              :key="slot"
              :class="
                checkIfSelected(1, slot)
                  ? 'bg-secondary text-white border border-secondary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span>{{ slot }}</span>
            </p>
          </template>
          <template #cell(2)="row">
            <p
              @click="selectDate(2, slot)"
              class="general_border point slot__tm"
              v-for="slot in row.item['2']"
              :key="slot"
              :class="
                checkIfSelected(2, slot)
                  ? 'bg-secondary text-white border border-secondary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(3)="row">
            <p
              @click="selectDate(3, slot)"
              class="general_border point slot__tm"
              v-for="slot in row.item['3']"
              :key="slot"
              :class="
                checkIfSelected(3, slot)
                  ? 'bg-secondary text-white border border-secondary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(4)="row">
            <p
              @click="selectDate(4, slot)"
              class="general_border point slot__tm"
              v-for="slot in row.item['4']"
              :key="slot"
              :class="
                checkIfSelected(4, slot)
                  ? 'bg-secondary text-white border border-secondary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(5)="row">
            <p
              @click="selectDate(5, slot)"
              class="general_border point slot__tm"
              v-for="slot in row.item['5']"
              :key="slot"
              :class="
                checkIfSelected(5, slot)
                  ? 'bg-secondary text-white border border-secondary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(6)="row">
            <p
              @click="selectDate(6, slot)"
              class="general_border point slot__tm"
              v-for="slot in row.item['6']"
              :key="slot"
              :class="
                checkIfSelected(6, slot)
                  ? 'bg-secondary text-white border border-secondary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(7)="row">
            <p
              @click="selectDate(7, slot)"
              class="general_border point slot__tm"
              v-for="slot in row.item['7']"
              :key="slot"
              :class="
                checkIfSelected(7, slot)
                  ? 'bg-secondary text-white border border-secondary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #empty="scope">
            <h6 class="text-secondary" v-if="scope">
              Nessuna disponibilità per questa settimana. Prova a verificare le
              disponibilità per le prossime settimane.
            </h6>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import * as moment from "moment/moment"
import { mapState, mapActions } from "vuex"
import { reservationService } from "@/_services"
export default {
  name: "DatetimeSelection",
  props: {
    company: {
      type: String,
      required: true
    },
    service: {
      type: String,
      required: true
    },
    hservice: {
      type: String,
      required: false
    },
    restype: {
      type: String,
      required: true
    },
    prevdate: {
      type: String,
      required: false
    },
    notitle: {
      type: Boolean,
      required: false
    },
    isinsidemodal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    blockPrecedente: function () {
      const today = moment().startOf("day")
      const filterFrom = moment(this.filters.from, "D/M/YY").startOf("day")
      return filterFrom.isSameOrBefore(today)
    },
    blockSuccessivo: function () {
      const filterFrom = moment(this.filters.from, "D/M/YY").startOf("day")
      const nextWeek = moment(filterFrom).add(7, "days")
      const nextWeekYear = nextWeek.year()
      return !this.years.includes(nextWeekYear)
    },
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"]),
    currentMonth() {
      return new Date().getMonth() + 1
    }
  },
  watch: {
    form: {
      handler(val) {
        if (val.data_inizio !== null) {
          this.$nextTick(function () {
            this.$refs["orderConfirm"].scrollIntoView()
          })
        }
      },
      deep: true
    },
    "filters.to": function (newValue, oldValue) {
      if (!oldValue) return
      const newDate = this.parseDateString(newValue)

      this.selectedMonth = newDate.getMonth()

      this.selectedYear = newDate.getFullYear()
    },
    hservice: function (newValue, oldValue) {
      if (!newValue) return
      this.formatDisponibilitaPrestazione()
    },
    service: function (newValue, oldValue) {
      // console.log("service changed")
      // console.log("new service value: " + newValue)
      if (!oldValue) return
      this.formatDisponibilitaService()
    },
    restype: function (newValue, oldValue) {
      if (!oldValue) return
      // console.log("restype changed")
      // console.log("new restype value: " + newValue)
      if (newValue === "prestazione") {
        if (this.hservice) this.formatDisponibilitaPrestazione()
      } else {
        this.formatDisponibilitaService()
      }
    }
  },
  data() {
    return {
      form: {
        company_id: this.company,
        service_id: this.service
      },
      throttlingPrevent: false,
      selectedCompany: null,
      start: null,
      end: null,
      month: null,
      today: null,
      fields: [],
      disponibilita: null,
      selectedSlot: null,
      selectedCol: null,
      filters: {
        from: null,
        to: null
      },
      showDisponibilita: false,
      sending: false,
      calendarChoice: true,
      errorMsg: null,
      selectedMonth: new Date().getMonth(),
      selectedYear: new Date().getFullYear(),
      monthMapping: [
        { id: 0, name: "Gennaio" },
        { id: 1, name: "Febbraio" },
        { id: 2, name: "Marzo" },
        { id: 3, name: "Aprile" },
        { id: 4, name: "Maggio" },
        { id: 5, name: "Giugno" },
        { id: 6, name: "Luglio" },
        { id: 7, name: "Agosto" },
        { id: 8, name: "Settembre" },
        { id: 9, name: "Ottobre" },
        { id: 10, name: "Novembre" },
        { id: 11, name: "Dicembre" }
      ],
      years: []
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    ...mapActions("utente", ["me"]),
    onMonthYearChange() {
      if (this.isMonthDisabled(this.selectedMonth)) {
        this.selectedMonth = new Date().getMonth()
        this.start = moment().startOf("week")
        this.end = moment().endOf("week")
        this.filters.from = moment().startOf("week").format("D/M/YY")
        this.filters.to = moment().endOf("week").format("D/M/YY")
        this.setupCalendar()
        return
      }
      const firstDayOfMonth = new Date(this.selectedYear, this.selectedMonth, 1)
      this.start = moment(firstDayOfMonth).startOf("week")
      this.end = moment(firstDayOfMonth).endOf("week")
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY")

      this.setupCalendar()
    },
    parseDateString(dateString) {
      const parts = dateString.split("/")
      let year = parseInt(parts[2], 10)

      year = year < 100 ? 2000 + year : year

      return new Date(year, parts[1] - 1, parts[0])
    },
    isMonthDisabled(month) {
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getMonth() + 1
      if (this.selectedYear === currentYear) {
        return month < currentMonth
      }
      return false
    },
    formatDisponibilitaService() {
      var data = {
        hash: this.service,
        company: this.company,
        params: this.filters
      }
      var self = this
      reservationService
        .disponibilitaServizio(data)
        .then(function (res) {
          self.disponibilita = [res.data.data]
          if (res.data.data.length == 0) {
            self.disponibilita = []
            self.errorMsg =
              "Nessuna disponibilità per la settimana e la prestazione scelta"
          }
        })
        .catch(function (error) {
          self.disponibilita = []
          self.errorMsg =
            "Non è stato possibile trovare disponibilità per questo servizio"
        })
    },
    formatDisponibilitaPrestazione() {
      if (!this.hservice) return
      var data = {
        hash: this.hservice,
        params: this.filters
      }
      var self = this
      reservationService
        .disponibilitaPrestazione(data)
        .then(function (res) {
          self.disponibilita = [res.data.data]
          if (res.data.data.length == 0) {
            self.disponibilita = []
            self.infoAlert(
              "La prestazione scelta non ha nessuna disponibilità per la settimana corrente"
            )
          }
        })
        .catch(function (error) {
          self.disponibilita = []
          window.scrollTo(0, 0)
          self.errorAlert(
            "Non è stato possibile trovare disponibilità per questo servizio"
          )
        })
    },
    getDuration() {
      var formData = {
        hash: this.service,
        filters: {
          company: this.company,
          day: this.selectedCol
        }
      }
      var self = this
      reservationService
        .getServiceOpeningForDay(formData)
        .then(function (res) {
          // console.log(res);
          self.$emit("update-durata", res.data.data[0].interval)
          // window.scrollTo(0, 0);
          // self.survey = res.data.data;
          // self.consent = self.survey.info == null;
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert(
            "Errore nel recupero della durata del teleconsulto. Riprova più tardi."
          )
          // console.log(err);
        })
    },
    setPrev() {
      if (this.blockPrecedente || this.throttlingPrevent) {
        return
      }
      this.start.subtract(1, "weeks")
      this.end.subtract(1, "weeks")
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
      this.selectedSlot = null
      this.selectedCol = null
      this.setupCalendar()
      this.throttlingPrevent = true
      setTimeout(() => {
        this.throttlingPrevent = false
      }, 600)
    },
    setPost() {
      if (this.blockSuccessivo || this.throttlingPrevent) {
        return
      }
      this.start.add(1, "weeks")
      this.end.add(1, "weeks")
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
      this.selectedSlot = null
      this.selectedCol = null
      this.setupCalendar()
      this.throttlingPrevent = true
      setTimeout(() => {
        this.throttlingPrevent = false
      }, 600)
    },
    setupCalendar() {
      this.selectedSlot = null
      this.selectedCol = null
      var fields = []
      for (let x = 0; x <= 6; x++) {
        let startDay = moment(this.start)
        let todayDay = moment(this.today).format("ddd DD/M")
        let date = startDay.add(x, "days").format("ddd DD/M")
        let variant = undefined
        if (todayDay === date) {
          variant = "sec-4"
        }
        let chiave = x + 1
        let newField = {
          key: chiave.toString(),
          label: date,
          variant: variant
        }
        fields.push(newField)
      }
      this.fields = fields

      if (this.restype === "prestazione") {
        this.formatDisponibilitaPrestazione()
      } else {
        this.formatDisponibilitaService()
      }

      this.showDisponibilita = true
    },
    selectDate(col, slot) {
      this.selectedCol = col
      this.selectedSlot = slot
      let startDay = moment(this.start)
      let date = startDay.add(col - 1, "days").format("ddd DD/MM/YYYY")
      date += " " + slot
      this.form.data_inizio = moment(date, "ddd DD/MM/YYYY HH:mm")
      this.$emit("date-selected", this.form.data_inizio)
      if (this.restype === "teleconsulto") {
        this.getDuration()
      }
    },
    checkIfSelected(col, slot) {
      if (!this.selectedCol || !this.selectedSlot) {
        return false
      }
      if (this.selectedCol == col && this.selectedSlot == slot) {
        return true
      }
    }
  },
  created() {
    this.start = moment().startOf("week")
    this.end = moment().endOf("week")
    this.today = moment()
    this.month = moment().month()
    this.filters.from = moment().startOf("week").format("D/M/YY")
    this.filters.to = moment().endOf("week").format("D/M/YY")

    const currentYear = new Date().getFullYear()

    for (let i = 0; i < 6; i++) {
      this.years.push(currentYear + i)
    }
  },
  mounted() {
    this.setupCalendar()
  }
}
</script>

<style scoped lang="scss"></style>
