<template>
  <div>
    <div v-if="!loading">
      <div class="text-left" v-if="userPlan">
        <h5>
          Percorso di cura:
          <span class="text-secondary">{{ userPlan.name }}</span>
        </h5>
        <b-button
          v-if="!block"
          type="button"
          size="sm"
          variant="secondary m-1"
          @click="openModal('edit')"
        >
          Modifica percorso
        </b-button>
        <p class="text-justify">{{ userPlan.descrizione }}</p>
      </div>
      <div v-else class="text-left d-flex align-items-center">
        <h6 class="text-left mb-0">
          Nessun percorso di cura selezionato
          <b-icon icon="x-circle" variant="danger"></b-icon>
        </h6>
        <b-button
          v-if="!block"
          type="button"
          size="sm"
          variant="secondary m-1"
          @click="openModal('create')"
        >
          Seleziona percorso
        </b-button>
      </div>
    </div>
    <b-spinner v-else></b-spinner>

    <b-modal
      id="plan-modal"
      :title="modalTitle"
      size="lg"
      centered
      scrollable
      hide-footer
      @cancel="cancelAndClose"
    >
      <div class="px-3">
        <h6>
          {{ modalDescription }}
        </h6>
        <b-form @submit.prevent="submitForm" @reset="cancelAndClose" class="pb-4 text-left">
          <b-form-group
            label="Percorso di cura*"
            label-for="plan_id"
            class="col-10 col-md-8 col-lg-6"
          >
            <b-form-select
              v-capitalize-first-select
              class="shadow_6 text-capitalize"
              name="plan_id"
              v-model="selectedPlan"
              required
            >
              <b-form-select-option
                v-for="planOption in plans"
                :value="planOption"
                :key="planOption.hashid"
              >{{ planOption.name }}</b-form-select-option>
              <b-form-select-option :value="null" disabled>scegli</b-form-select-option>
            </b-form-select>
          </b-form-group>
          <div v-if="selectedPlan" class="mt-3 bg-extra-light py-2 px-3 general_border">
            <h5>Dettagli percorso per <span class="text-lowercase">{{ selectedPlan.name }}</span></h5>
            <p class="text-justify">{{ selectedPlan.description }}</p>
            <h6>Il piano include:</h6>
            <p class="mb-1" v-if="selectedPlan.teleconsulto_every_num !== null">
              <i>Teleconsulti</i> programmati ogni <span v-if="selectedPlan.teleconsulto_every_num === 1">{{ tc_every_um[selectedPlan.teleconsulto_every_um].singular }}</span> 
              <template v-else>
                {{ selectedPlan.teleconsulto_every_num }} {{ tc_every_um[selectedPlan.teleconsulto_every_um].plural }}  
              </template>    
            </p>
            <p>
              <i>Questionari e valutazioni</i>: <br />
              <ul>
                <template v-for="score in selectedPlan.scores">
                <li :key="score.hashid">{{ score.name }}
                <span v-if="score.default_frequency"> ogni <span v-if="score.default_frequency.every_num === 1">{{ tc_every_um[score.default_frequency.every_um].singular }}</span> 
                <template v-else>
                {{ score.default_frequency.every_num }} {{ tc_every_um[score.default_frequency.every_um].plural }}  
              </template>  
              </span>
            </li> 
              </template>
                <template v-for="survey in selectedPlan?.activity?.surveys">
                <li v-if="!survey.has_score"  :key="survey.name">{{ survey.name }}</li>
              </template>
              </ul>            
            </p>
            <p>
              <i>Monitoraggio dei parametri</i>: <br />
              <ul>
                <li v-for="exam in selectedPlan.exams"  :key="exam.name">{{ exam.name }} ogni giorno</li>
              </ul>            
            </p>
            <p>
              <i>Dispositivi indossabili suggeriti</i>: <br />
              <ul>
                <li v-for="wearable_device in selectedPlan.wearable_devices"  :key="wearable_device.name">
                  {{ wearable_device.name }}, <span class="text-lowercase">{{ wearable_device.description }}</span>
                </li>
              </ul>            
            </p>
          </div>
          <b-row class="justify-content-end pt-4 mt-4">
            <b-button type="reset" variant="popup-button">Annulla</b-button>
            <b-button type="submit" variant="popup-button" class="ml-2">{{ submitButtonText }}</b-button>
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { monitoringService } from "@/_services";
import { mapState, mapActions } from "vuex";
import { FREQUENCYMAP } from "@/_utils/constants.js";

export default {
  name: "PercorsoCuraPaziente",
  props: ["userid", "block"],
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    modalTitle() {
      return this.isEditing ? "Modifica percorso di cura" : "Seleziona percorso di cura";
    },
    modalDescription() {
      return this.isEditing
        ? "Modifica il percorso di cura del paziente."
        : "Seleziona il percorso di cura adeguato alle esigenze del paziente.";
    },
    submitButtonText() {
      return this.isEditing ? "Conferma Modifica" : "Conferma Selezione";
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    getAvailablePlans() {
      const self = this;
      monitoringService
        .getAvailableTreatmentPlans()
        .then(function (res) {
          self.plans = res.data;
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert(
            "Non è stato possibile prelevare la lista dei piani disponibili."
          );
        });
    },
    getUserPlans() {
      const self = this;
      monitoringService
        .getUserTreatmentPlans(this.userid)
        .then(function (res) {
          self.userPlan = res.data;
          self.loading = false;
        })
        .catch(function (err) {
          console.log(err);
          self.loading = false;
          self.errorAlert(
            "Non è stato possibile prelevare la lista dei piani disponibili."
          );
        });
    },
    submitForm() {
      this.form.plan_id = this.selectedPlan.hashid;
      const self = this;
      
      let apiCall;
      if (this.isEditing) {
        apiCall = monitoringService.updateUserTreatmentPlan(this.form, this.userid);
      } else {
        apiCall = monitoringService.storeUserTreatmentPlan(this.form, this.userid);
      }

      apiCall
        .then(function (res) {
          self.userPlan = res.data;
          self.loading = true;
          self.getUserPlans();
          self.$bvModal.hide('plan-modal');
          self.successAlert("Percorso di cura aggiornato");
        })
        .catch(function (err) {
          console.log(err);
          self.loading = false;
          self.$bvModal.hide('plan-modal');
          self.errorAlert(
            "Non è stato possibile completare l'operazione."
          );
        });
    },
    cancelAndClose() {
      this.$bvModal.hide('plan-modal');
      this.selectedPlan = null;
    },
    openModal(mode) {
      this.isEditing = mode === 'edit';
      if (this.isEditing && this.userPlan) {
        this.selectedPlan = this.plans.find(plan => plan.hashid === this.userPlan.hashid) || null;
      } else {
        this.selectedPlan = null;
      }
      this.$bvModal.show('plan-modal');
    },
  },
  data() {
    return {
      plans: null,
      userPlan: null,
      loading: true,
      form: {
        plan_id: null,
      },
      selectedPlan: null,
      tc_every_um: FREQUENCYMAP,
      isEditing: false,
    };
  },
  created() {
    this.getAvailablePlans();
  },
  mounted() {
    this.getUserPlans();
  },
};
</script>