<template>
  <div style="max-width: 800px">
    <b-container
      class="px-3 pt-3 pb-4 mx-0 w-100 border border-info bg-extra-light general_border"
    >
      <b-row class="justify-content-start px-0 w-100">
        <b-col class="col-9 px-0">
          <b-form-group
            label="Cerca utente per codice fiscale"
            label-for="cf"
            class="text-left m-0 text-secondary mx-auto"
          >
            <b-form-input
              :size="$root.isMobile || $root.isTablet ? 'sm' : ''"
              name="cf"
              placeholder="Codice Fiscale"
              maxlength="16"
              minlength="16"
              v-model="params.cf"
              :state="params.cf == null ? null : validCf"
              @input="
                (v) => {
                  params.cf = v.toUpperCase()
                  this.nullCf()
                }
              "
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col
          class="col-3 d-flex align-self-end text-left"
          :class="$root.isMobile ? 'px-1' : 'px-3'"
        >
          <b-button
            class="height: 100%"
            :size="$root.isMobile || $root.isTablet ? 'sm' : ''"
            variant="secondary text-white  "
            :disabled="params.cf == null ? true : !validCf"
            @click="searchUser"
            >Cerca</b-button
          >
        </b-col>
      </b-row>
      <b-row class="justify-content-start pt-4 px-0 w-100">
        <b-col class="col-9 px-0">
          <b-form-group
            label="Cerca utente per email"
            label-for="email"
            class="text-left my-0 text-secondary mx-auto"
          >
            <b-form-input
              :size="$root.isMobile || $root.isTablet ? 'sm' : ''"
              name="email"
              type="email"
              placeholder="E-mail"
              v-model="params.email"
              :state="params.email == null ? null : validEmail"
              @input="nullEmail"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col
          class="col-3 align-self-end text-left"
          :class="$root.isMobile ? 'px-1' : 'px-3'"
        >
          <b-button
            :size="$root.isMobile || $root.isTablet ? 'sm' : ''"
            variant="secondary text-white  "
            :disabled="params.email == null ? true : !validEmail"
            @click="searchUser"
            >Cerca</b-button
          >
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="hasSearched & !hideSearchResult" class="mt-3">
      <b-row v-if="!notFound && searchResult" class="justify-content-center">
        <b-col class="col-12 text-center my-3">
          <div>
            <span> Trovato un utente con </span>
            <span v-if="searchedEmail">
              email <strong>{{ searchedEmail }}</strong>
            </span>
            <span v-if="searchedCF">
              codice fiscale <strong>{{ searchedCF }}</strong>
            </span>
            <br />
            <br />
            <span class="text-capitalize font-weight-bolder"
              >{{ searchResult.anagrafica.name }}
              {{ searchResult.anagrafica.surname }}</span
            >
          </div>
        </b-col>
        <div v-if="searchResult.is_my_patient">
          <b-col class="col-12 d-flex justify-content-center mt-3">
            <template v-if="actionType === 'createNewReservation'">
              <b-button class="mx-2" variant="popup-button" @click="resetForm()"
                >Annulla</b-button
              >
              <b-button
                class="mx-2"
                variant="popup-button"
                @click="navigateToReservation()"
                >Prenota
              </b-button>
            </template>
            <template v-else-if="actionType === 'createNewTherapy'">
              <b-button class="mx-2" variant="popup-button" @click="resetForm()"
                >Annulla</b-button
              >
              <b-button
                class="mx-2"
                variant="popup-button"
                @click="navigateToTherapy()"
                >Crea Terapia
              </b-button>
            </template>
            <template v-else-if="actionType === 'insertNewPatient'">
              <b-button class="mx-2" variant="popup-button" @click="resetForm()"
                >Annulla</b-button
              >
              <b-button
                class="mx-2"
                variant="popup-button"
                @click="navigateToPatientCard()"
                >Vai a scheda paziente</b-button
              >
            </template>
          </b-col>
        </div>
        <div v-else>
          <b-col class="col-12 d-flex justify-content-center mt-3">
            <template v-if="actionType === 'createNewReservation'">
              <b-button class="mx-2" variant="popup-button" @click="resetForm()"
                >Annulla</b-button
              >
              <b-button
                class="mx-2"
                variant="popup-button"
                @click="navigateToReservation()"
                >Prenota
              </b-button>
            </template>
            <template v-else-if="actionType === 'createNewTherapy'">
              <b-button class="mx-2" variant="popup-button" @click="resetForm()"
                >Annulla</b-button
              >
              <b-button
                class="mx-2"
                variant="popup-button"
                @click="navigateToTherapy()"
                >Crea Terapia
              </b-button>
            </template>
            <template v-else-if="actionType === 'insertNewPatient'">
              <b-button class="mx-2" variant="popup-button" @click="resetForm()"
                >Annulla</b-button
              >
              <b-button
                class="mx-2"
                variant="popup-button"
                @click="navigateToPatientCard()"
                >Vai a scheda paziente</b-button
              >
            </template>
          </b-col>
        </div>
      </b-row>
      <b-row
        v-else-if="!loading && !hideSearchResult"
        class="col-12 text-center"
      >
        <p class="w-100 text-center">Nessun utente trovato.</p>
        <b-col class="col-12 d-flex justify-content-center mt-3">
          <b-button class="mx-2" variant="popup-button" @click="resetForm()"
            >Annulla</b-button
          >
          <b-button class="mx-2" variant="popup-button" @click="sendInvite"
            >Registra</b-button
          >
        </b-col>
      </b-row>
    </b-container>

    <b-modal size="lg" id="member-found" hide-footer> </b-modal>
  </div>
</template>
<script>
import { utilityService } from "@/_services"
export default {
  name: "SearchUtenteDashboard",
  props: ["type", "actionType", "newReservationType"],
  data() {
    return {
      hideSearchResult: false,
      pazienteHash: null,
      searchedEmail: "",
      searchedCF: "",
      hasSearched: false,
      params: {
        cf: null,
        email: null
      },
      searchResult: null,
      notFound: false
    }
  },
  computed: {
    validEmail() {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      return this.params.email.match(validRegex) ? true : false
    },
    validCf() {
      var validRegex =
        /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/
      return this.params.cf.match(validRegex) ? true : false
    }
  },
  methods: {
    navigateToPatientCard() {
      // http://localhost:8080/operatore/pazienti/scheda/L39J4q2VolejRejNmGQBW71g
      this.$router.push({
        name: "scheda paziente",
        params: { hash: this.searchResult.hashid }
      })
    },
    navigateToTherapy() {
      this.$router.push({
        name: `nuova terapia Paziente`,
        query: { type: "user_in_app", sub: this.searchResult.hashid }
      })
    },
    navigateToReservation() {
      this.$router.push({
        path: `pazienti/servizi/${this.searchResult.hashid}`,
        query: { reservationType: this.newReservationType }
      })
    },
    searchUser() {
      this.loading = true
      this.notFound = false
      this.searchResult = null
      var self = this
      utilityService
        .searchUser(this.params)
        .then(function (res) {
          if (self.params.email) {
            self.searchedEmail = self.params.email
            self.searchedCF = null
          } else {
            self.searchedCF = self.params.cf
            self.searchedEmail = null
          }
          self.searchResult = res.data.data
          self.hasSearched = true
          self.loading = false
          self.hideSearchResult = false
        })
        .catch(function (err) {
          self.notFound = true
          self.hasSearched = true
          self.loading = false
          self.hideSearchResult = false
        })
    },
    sendInvite() {
      if (this.searchResult) {
        this.$emit("invite-user", this.searchResult.hashid)
      } else {
        this.$emit("arruola-user", this.params)
      }
      this.$bvModal.hide("member-found")
    },
    nullCf() {
      if (this.params.cf.length == 0) {
        this.params.cf = null
      } else {
        this.params.email = null
      }
    },
    nullEmail() {
      if (this.params.email.length == 0) {
        this.params.email = null
      } else {
        this.params.cf = null
      }
    },
    resetForm() {
      this.params = {
        cf: null,
        email: null
      }
      this.hideSearchResult = true
      // console.log("form reset");
    }
  }
}
</script>
