<template>
  <div v-if="!isModal">
    <p class="text-left">Seleziona giorni e orari di preferenza</p>
    <div class="text-left">
      <b-form-group label="">
        <b-form-checkbox
          v-model="noPreference"
          aria-describedby="ignora preferenza"
          name="preference-ignore"
          :value="true"
          :unchecked-value="false"
        >
          Non ho particolari preferenze
        </b-form-checkbox>
        <b-form-checkbox
          v-model="noPreference"
          aria-describedby="ignora preferenza"
          name="preference-ignore"
          :value="false"
          :unchecked-value="true"
        >
          Voglio indicare le preferenze
        </b-form-checkbox>
      </b-form-group>
      <b-form @submit.prevent="addPreference">
        <b-row v-if="!noPreference" align-v="end">
          <b-form-group
            class="col-12 col-md-6 col-lg-4 px-2 mt-2"
            label="Data di preferenza*"
          >
            <b-form-datepicker
              label-help="Utilizza i cursori per navigare tra le date"
              placeholder="Scegli una data"
              start-weekday="1"
              locale="it"
              :min="yesterdayDate"
              v-model="form.date"
              required
            ></b-form-datepicker>
          </b-form-group>

          <b-form-group
            class="col-12 col-md-6 col-lg-4 px-2 mt-2"
            label="Fascia oraria di preferenza*"
          >
            <b-form-select
              v-capitalize-first-select
              :options="timeOptions"
              v-model="form.time"
              required
            ></b-form-select>
          </b-form-group>
          <b-col>
            <b-button class="mb-2" type="submit">Aggiungi preferenza</b-button>
          </b-col>
        </b-row>
      </b-form>
      <p class="mt-3">
        <span v-for="(preference, index) in preferences" class="mx-1">
          <b-button @click="removePreference(index)">
            {{ preference }} <b-icon icon="x"></b-icon
          ></b-button>
        </span>
      </p>
      <b-row v-if="noPreference" class="mt-3 pt-4">
        <b-button @click="setNoPreferences">Salva e procedi</b-button>
      </b-row>
      <b-row v-else-if="preferences.length > 0" class="mt-3 pt-4">
        <b-button @click="setPreferences">Salva selezione</b-button>
      </b-row>
    </div>
  </div>
  <div v-else>
    <p class="text-left pb-2">Seleziona giorni e orari di preferenza</p>
    <div class="text-left">
      <b-form-group label="">
        <b-form-checkbox
          v-model="noPreference"
          aria-describedby="ignora preferenza"
          name="preference-ignore"
          :value="true"
          :unchecked-value="false"
        >
          Non ho particolari preferenze
        </b-form-checkbox>
        <b-form-checkbox
          v-model="noPreference"
          aria-describedby="ignora preferenza"
          name="preference-ignore"
          :value="false"
          :unchecked-value="true"
        >
          Voglio indicare le preferenze
        </b-form-checkbox>
      </b-form-group>
      <b-form @submit.prevent="addPreference">
        <template v-if="!noPreference">
          <b-row align-v="end">
            <b-form-group
              class="col-12 col-lg-7 px-2 mt-2"
              label="Data di preferenza*"
            >
              <b-form-datepicker
                label-help="Utilizza i cursori per navigare tra le date"
                placeholder="Scegli una data"
                start-weekday="1"
                locale="it"
                :min="yesterdayDate"
                v-model="form.date"
                required
              ></b-form-datepicker>
            </b-form-group>

            <b-form-group
              class="col-12 col-lg-7 px-2 mt-2"
              label="Fascia oraria di preferenza*"
            >
              <b-form-select
                v-capitalize-first-select
                :options="timeOptions"
                v-model="form.time"
                required
              ></b-form-select>
            </b-form-group>
          </b-row>
          <b-row class="mt-4 px-2">
            <b-button type="submit">Aggiungi preferenza</b-button>
          </b-row>
        </template>
      </b-form>
      <p class="mt-3">
        <span v-for="(preference, index) in preferences" class="mx-1">
          <b-button @click="removePreference(index)">
            {{ preference }} <b-icon icon="x"></b-icon
          ></b-button>
        </span>
      </p>
      <b-row v-if="noPreference" class="d-flex justify-content-end">
        <b-button variant="popup-button" @click="setNoPreferences"
          >Salva e procedi</b-button
        >
      </b-row>
      <b-row
        v-else-if="preferences.length > 0"
        class="mt-3 pt-4 d-flex justify-content-end"
      >
        <b-button variant="popup-button" @click="setPreferences"
          >Salva selezione</b-button
        >
      </b-row>
    </div>
  </div>
</template>
<script>
import * as moment from "moment/moment"
export default {
  name: "PreferenzeSelection",
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      noPreference: true,
      preferences: [],
      form: {
        date: null,
        time: "tutto il giorno"
      },
      timeOptions: ["mattina", "pomeriggio", "sera", "tutto il giorno"]
    }
  },
  methods: {
    setPreferences() {
      const preferenceData =
        "Preferenze giorni e orari appuntamento: " + this.preferences.join(", ")
      this.$emit("preference-setted", preferenceData)
    },
    addPreference() {
      const stringPreference =
        moment(this.form.date).format("DD/MM/YYYY") + " " + this.form.time
      this.preferences.push(stringPreference)
      this.form.date = null
      this.form.time = "tutto il giorno"
    },
    removePreference(index) {
      this.preferences.splice(index, 1)
    },
    setNoPreferences() {
      const preferenceData =
        "Preferenze giorni e orari appuntamento: nessuna preferenza"
      this.$emit("preference-setted", preferenceData)
    }
  },
  computed: {
    yesterdayDate() {
      return moment().add(1, "days").format("YYYY-MM-DD")
    }
  }
}
</script>
