<template>
  <div>
    <NewPreferenzeSelection
      v-if="isProxy"
      @selections-changed="$emit('preferenze-changed', $event)"
      :lastPreferenze="selectedData.preferences ?? []"
    ></NewPreferenzeSelection>
    <!-- :lastPreferenze="lastPreferenze"
      @selections-changed="handlePreferenceSettings"
      @no-preferences="preferenzeNote = 'Non ho particolari preferenze'" -->

    <DatetimeSelection
      v-else-if="resData.type === 'prestazione'"
      :notitle="true"
      :company="selectedData.company.hashid"
      :service="resData.service_id"
      :hservice="selectedData.healthService.hashid"
      :restype="resData.type"
      @date-selected="$emit('date-selected', $event)"
    ></DatetimeSelection>

    <DatetimeSelection
      v-else
      :notitle="true"
      :company="selectedData.company.hashid"
      :service="resData.service_id"
      :restype="resData.type"
      @date-selected="$emit('date-selected', $event)"
    ></DatetimeSelection>
  </div>
</template>

<script>
import DatetimeSelection from "@/components/prenotazioni/form/DatetimeSelection.vue"
import NewPreferenzeSelection from "@/components/prenotazioni/form/NewPreferenzeSelection.vue"

export default {
  name: "Data",
  props: {
    resData: {
      type: Object,
      required: true
    },
    selectedData: {
      type: Object,
      required: true
    },
    isProxy: {
      type: Boolean,
      required: true
    }
  },
  components: {
    NewPreferenzeSelection,
    DatetimeSelection
  },
  methods: {}
}
</script>

<style scoped></style>
