<template>
  <div class="px-0 px-md-4">
    <b-row class="mb-2">
      <b-col class="pl-0">
        <h4 class="text-left pb-1 mb-lg-4">Log di sistema</h4>
      </b-col>
      <b-col class="text-right pr-0"> </b-col>
    </b-row>
    <div>
      <b-row
        class="text-left puntofarmaCard p-2"
        cols="2"
        cols-lg="3"
        cols-xl="4"
      >
        <b-col>
          <b-form-group
            label="Dal"
            label-for="fromDate"
            class="text-left mx-auto text-secondary"
          >
            <b-form-input
              class=" "
              name="fromDate"
              type="date"
              :max="dateLimit"
              locale="it"
              v-model="filters.fromDate"
              required
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Al"
            label-for="toDate"
            class="text-left text-secondary mx-auto"
          >
            <b-form-input
              class=" "
              name="toDate"
              type="date"
              :max="dateLimit"
              locale="it"
              v-model="filters.toDate"
              required
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Evento"
            label-for="event"
            class="text-left text-secondary mx-auto"
          >
            <b-form-select
              v-capitalize-first-select
              :options="eventTypes"
              class=" "
              name="event"
              v-model="filters.eventType"
              required
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Soggetto"
            label-for="subject"
            class="text-left text-secondary mx-auto"
          >
            <b-form-select
              v-capitalize-first-select
              :options="eventSubjects"
              class=" "
              name="subject"
              v-model="filters.eventSubject"
              required
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Id soggetto"
            label-for="triggeredOn"
            class="text-left text-secondary mx-auto"
          >
            <b-form-input
              class=" "
              placeholder="id..."
              name="triggeredOn"
              type="text"
              v-model="filters.triggeredOn"
              required
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Scatenato da"
            label-for="triggeredBy"
            class="text-left text-secondary mx-auto"
          >
            <b-form-input
              class=" "
              placeholder="id..."
              name="triggeredBy"
              type="text"
              v-model="filters.triggeredBy"
              required
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-v="center" class="mt-3 mb-2">
        <b-col class="text-left">
          <span class="mr-1" v-if="total > 0"
            >{{ fromPage }}-{{ toPage }} di
          </span>
          <span class=""> {{ total }}</span>
        </b-col>
        <b-col class="text-left">
          <b-form-group
            label=""
            label-for="takes"
            class="text-left text-secondary mx-auto"
          >
            <b-form-select
              v-capitalize-first-select
              :options="takeOptions"
              class=" "
              name="takes"
              v-model="filters.take"
              required
            >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        borderless
        hover
        responsive
        class="text-left puntofarmaCard"
        :fields="fields"
        :items="logs"
        selectable
        selected-variant=""
        thead-class="text-left"
        tbody-class=""
        ref="logTable"
        tbody-tr-class=""
      >
        <template #cell(description)="row">
          <div class="d-flex text-center">
            {{ mappedEvent(row.item.description) }}
          </div>
        </template>
        <template #cell(azioni)="row">
          <div class="d-flex text-center">
            <b-button
              size="sm"
              variant="info px-3  mx-1"
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? "Nascondi" : "Mostra" }} Dettagli
            </b-button>
          </div>
        </template>

        <template #row-details="row">
          <b-row
            cols="2"
            v-if="
              row.item.properties &&
              (row.item.properties?.old || row.item.properties?.attributes)
            "
            class="bg-extra-light"
          >
            <b-col class="py-2">
              <template v-if="row.item.properties.old">
                <h6 class="text-secondary">Stato precedente</h6>
                <b-row cols="2" cols-xl="3">
                  <template v-for="(val, key) in row.item.properties.old">
                    <b-col v-if="key !== 'password' && key !== 'push_token'">
                      <span> {{ key }}: {{ val }}</span>
                    </b-col>
                  </template>
                </b-row>
              </template>
            </b-col>
            <b-col
              v-if="row.item.properties.attributes"
              class="bg-white shadow_2 py-2"
            >
              <h6 class="text-secondary">Stato attuale</h6>
              <b-row cols="2" cols-xl="3">
                <template v-for="(val, key) in row.item.properties.attributes">
                  <b-col v-if="key !== 'password' && key !== 'push_token'">
                    <span>{{ key }}: {{ val }}</span>
                  </b-col>
                </template>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-else class="bg-extra-light"> {{ row.item }}</b-row>
        </template>
      </b-table>
      <div>
        <b-pagination
          v-model="filters.page"
          pills
          :total-rows="total"
          :per-page="filters.take"
          align="center"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { adminService } from "@/_services"
export default {
  name: "LogSistema",
  data() {
    var today = moment().format("YYYY-MM-DD")
    console.log(today)
    return {
      dateLimit: today,
      logs: null,
      filters: {
        fromDate: today,
        toDate: today,
        eventType: null,
        eventSubject: null,
        triggeredBy: null,
        triggeredOn: null,
        page: 1,
        take: 10
      },
      total: 0,
      fromPage: 0,
      toPage: 0,
      eventTypes: [
        { text: "scegli evento", value: null },
        { text: "creazione", value: "created" },
        { text: "aggiornamento", value: "updated" },
        { text: "cancellazione", value: "deleted" },
        { text: "accesso scheda paziente", value: "accesso anagrafica utente" },
        { text: "chatbot login", value: "User logged in via chabot" },
        { text: "chatbot logout", value: "User Logged Put Via Chabot" },
        { text: "download risorsa", value: "download risorsa" }
      ],
      eventSubjects: [
        { text: "scegli soggetto", value: null },
        { text: "utente", value: "utente" },
        { text: "company", value: "company" },
        { text: "prenotazione", value: "prenotazione" },
        { text: "terapia", value: "terapia" },
        { text: "questionario", value: "questionario" },
        { text: "bot", value: "bot" },
        { text: "alert", value: "alert" },
        { text: "attività", value: "attività" },
        { text: "patologia", value: "patologia" },
        { text: "misurazione", value: "misurazione" }
      ],
      colors: ["info", "prim-grad-1", "prim-grad-2", "success"],
      takeOptions: [
        { text: "mostra 10 elementi", value: 10 },
        { text: "mostra 25 elementi", value: 25 },
        { text: "mostra 50 elementi", value: 50 },
        { text: "mostra 75 elementi", value: 75 },
        { text: "mostra 100 elementi", value: 100 },
        { text: "mostra 200 elementi", value: 200 },
        { text: "mostra 300 elementi", value: 300 }
      ],
      fields: [
        { label: "Evento", key: "description" },
        { label: "Soggetto", key: "subject_type" },
        // { label: "ID soggetto", key: "subject_id" },
        // { label: "scatenato da ID", key: "causer_id" },
        { label: "Data", key: "created_at" },
        { label: "", key: "azioni" }
      ],
      selectedline: null
    }
  },
  watch: {
    filters: {
      handler(prev, post) {
        this.fetchData()
      },
      deep: true
    }
  },
  methods: {
    fetchData() {
      var self = this
      adminService
        .getActivityLog(this.filters)
        .then(function (resp) {
          self.logs = resp.data.data.data
          self.filters.page = resp.data.data.current_page
          self.total = resp.data.data.total
          console.log(resp.data.data)
          self.fromPage = resp.data.data.from
          self.toPage = resp.data.data.to
        })
        .catch(function (err) {
          console.log(err)
        })
    },
    mappedEvent(val) {
      var filtered = this.eventTypes.filter((el) => el.value == val)
      return filtered[0] ? filtered[0].text : "generico"
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>
