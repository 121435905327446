<template>
  <div class="px-0 px-md-4">
    <b-row class="justify-content-between">
      <h4 class="text-left pb-1 mb-lg-4">Voucher sottoscrizioni</h4>
      <VoucherStoreForm @refresh="fetchData"></VoucherStoreForm>
    </b-row>
    <h5>Voucher disponibili</h5>
    <b-table
      id="voucher-sottoscrizioni-table"
      borderless
      hover
      responsive
      :fields="fields"
      :items="vouchers"
      class="general_border puntofarmaCard py-2 mt-2 text-center align-middle"
      show-empty
    >
      <template #empty>
        <p>Nessun voucher disponibile</p>
      </template>

      <template #cell(final_price)="row">
        <span v-if="row.item.final_price && row.item.final_price !== 0">{{
          row.item.final_price.toLocaleString("it-IT", {
            style: "currency",
            currency: "EUR"
          })
        }}</span>
        <span v-else>0 €</span>
      </template>

      <template #cell(created_at)="row">
        {{ row.item.created_at | moment("DD/MM/YYYY") || "-" }}
      </template>

      <template #cell(valid_from)="row">
        {{ row.item.valid_from | moment("DD/MM/YYYY") || "-" }}
      </template>

      <template #cell(valid_until)="row">
        {{ row.item.valid_until | moment("DD/MM/YYYY") || "-" }}
      </template>

      <template #cell(enabled)="row">
        <b-row align-v="center" no-gutters class="justify-content-center">
          <b-icon
            :icon="row.item.enabled ? 'check-circle-fill' : 'x-circle-fill'"
            :variant="row.item.enabled ? 'success' : 'danger'"
            font-scale="1.5"
            class="ml-2"
          ></b-icon>
          <b-iconstack
            font-scale="3"
            class="point m-1 d-inline-block align-middle"
            v-b-popover.hover.top="row.item.enabled ? 'Disattiva' : 'Attiva'"
            @click="showStatusChangeModal(row.item)"
          >
            <b-icon
              stacked
              icon="circle-fill"
              variant="mdm-light-grey"
            ></b-icon>
            <b-icon
              stacked
              :icon="row.item.enabled ? 'pause-fill' : 'play-fill'"
              scale="0.5"
              variant="secondary"
            ></b-icon>
          </b-iconstack>
        </b-row>
      </template>

      <template #cell(azioni)="row">
        <b-row align-v="center" no-gutters class="justify-content-center">
          <VoucherEditForm
            :voucher="row.item"
            @refresh="fetchData"
            class="d-inline-block align-middle"
          />
          <b-iconstack
            font-scale="3"
            class="point m-1 d-inline-block align-middle"
            v-b-popover.hover.top="'Elimina'"
            @click="showDeleteThis(row.item)"
          >
            <b-icon
              stacked
              icon="circle-fill"
              variant="mdm-light-grey"
            ></b-icon>
            <b-icon
              stacked
              icon="trash"
              scale="0.5"
              variant="secondary"
            ></b-icon>
          </b-iconstack>
        </b-row>
      </template>
    </b-table>

    <b-modal
      id="confirm-delete"
      centered
      title="Confermi di voler eliminare il voucher?"
      ok-title="Sì, elimina"
      ok-variant="popup-button"
      cancel-title="No, annulla"
      cancel-variant="popup-button"
      footer-border-variant="white"
      @ok="deleteSelectedItem"
      @cancel="cancelDelete"
    >
      <h6 v-if="selectedVoucher" class="px-2 py-3">
        Se confermi, il voucher selezionato verrà eliminato definitivamente.
      </h6>
    </b-modal>

    <b-modal
      id="confirm-status-change"
      centered
      :title="statusChangeModalTitle"
      ok-title="Sì, conferma"
      ok-variant="popup-button"
      cancel-title="No, annulla"
      cancel-variant="popup-button"
      footer-border-variant="white"
      @ok="updateEnabled"
      @cancel="cancelStatusChange"
    >
      <h6 v-if="selectedVoucher" class="px-2 py-3">
        {{ statusChangeModalMessage }}
      </h6>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { adminService } from "@/_services"
import VoucherStoreForm from "@/components/admin/sottoscrizioni/VoucherStoreForm.vue"
import VoucherEditForm from "@/components/admin/sottoscrizioni/VoucherEditForm.vue"

export default {
  name: "VoucherSottoscrizioni",
  components: { VoucherStoreForm, VoucherEditForm },
  data() {
    return {
      vouchers: [],
      fields: [
        { key: "vaucher", label: "Codice", class: "text-center align-middle" },
        {
          key: "subscription.nome",
          label: "Sottoscrizione",
          class: "text-center align-middle"
        },
        {
          key: "sconto_voucher",
          label: "Sconto",
          class: "text-center align-middle"
        },
        {
          key: "final_price",
          label: "Prezzo finale",
          class: "text-center align-middle"
        },
        {
          key: "created_at",
          label: "Data Creazione",
          class: "text-center align-middle"
        },
        {
          key: "valid_from",
          label: "Valido da",
          class: "text-center align-middle"
        },
        {
          key: "valid_until",
          label: "Valido a",
          class: "text-center align-middle"
        },
        { key: "enabled", label: "Attivo", class: "text-center align-middle" },
        { key: "azioni", label: "", class: "text-center align-middle" }
      ],
      selectedVoucher: null
    }
  },
  computed: {
    statusChangeModalTitle() {
      return this.selectedVoucher
        ? this.selectedVoucher.enabled
          ? "Confermi di voler disattivare il voucher?"
          : "Confermi di voler attivare il voucher?"
        : ""
    },
    statusChangeModalMessage() {
      return this.selectedVoucher
        ? this.selectedVoucher.enabled
          ? "Se confermi, il voucher selezionato verrà disattivato."
          : "Se confermi, il voucher selezionato verrà attivato."
        : ""
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      const self = this
      adminService
        .getAvailableSubscriptionVouchers()
        .then(function (res) {
          self.vouchers = res.data.data || []
        })
        .catch(function (err) {
          self.errorAlert("Non è stato possibile recuperare le sottoscrizioni")
          self.vouchers = []
        })
    },
    showStatusChangeModal(voucher) {
      this.selectedVoucher = voucher
      this.$bvModal.show("confirm-status-change")
    },
    updateEnabled() {
      const updatedStatus = !this.selectedVoucher.enabled
      adminService
        .updateVoucherStatus(this.selectedVoucher.hashid, updatedStatus)
        .then(() => {
          this.selectedVoucher.enabled = updatedStatus
          this.successAlert("Stato del voucher aggiornato con successo")
          this.fetchData()
        })
        .catch(() => {
          this.errorAlert("Errore nell'aggiornamento dello stato del voucher")
        })
        .finally(() => {
          this.selectedVoucher = null
          this.$bvModal.hide("confirm-status-change")
        })
    },
    cancelStatusChange() {
      this.selectedVoucher = null
      this.$bvModal.hide("confirm-status-change")
    },
    showDeleteThis(voucher) {
      this.selectedVoucher = voucher
      this.$bvModal.show("confirm-delete")
    },
    cancelDelete() {
      this.selectedVoucher = null
      this.$bvModal.hide("confirm-delete")
    },
    deleteSelectedItem() {
      const self = this
      adminService
        .deleteVoucher(this.selectedVoucher.hashid)
        .then(function (res) {
          self.successAlert("Voucher eliminato correttamente")
          self.fetchData()
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile eliminare il voucher")
        })
        .finally(() => {
          this.selectedVoucher = null
          this.$bvModal.hide("confirm-delete")
        })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style>
#voucher-sottoscrizioni-table tbody tr td {
  vertical-align: middle !important;
  text-align: center !important;
}

#voucher-sottoscrizioni-table th {
  text-align: center !important;
}
</style>
