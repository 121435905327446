<template>
  <div class="px-0 px-md-4">
    <b-row class="mb-2">
      <b-col class="pl-0">
        <h4 class="text-left pb-1 mb-lg-4">Gestione Aziende</h4>
      </b-col>
      <b-col class="text-right pr-0">
        <b-button @click="$router.push({ name: 'nuova azienda' })">
          <b-icon icon="plus"></b-icon>
          nuova azienda
        </b-button>
      </b-col>
    </b-row>
    <div class="mb-2">
      <CompactSearch
        v-on:new-selection="updatePreferenze($event)"
        :pagefilter="filterSetted.page"
        :takefilter="filterSetted.take"
        class=""
      />
    </div>

    <div class="d-flex justify-content-between my-2 py-1">
      <div
        class="align-self-center pb-1"
        :class="$root.isMobile ? 'order-2' : ''"
      >
        <span class="mr-1" v-if="total > 0"
          >{{ fromPage }}-{{ toPage }} di
        </span>
        <span class=""> {{ total }}</span>
      </div>
      <div v-show="selectedCompanies.length > 0">
        <b-button-group>
          <!-- <b-button class="py-2">Scarica</b-button> -->
          <b-dropdown
            dropright
            variant="outline-secondary"
            text="Scarica"
            class=""
          >
            <b-dropdown-item @click="modalDownload('prestazioni')"
              >Report Prestazioni
            </b-dropdown-item>
            <b-dropdown-item @click="modalDownload('ordini')"
              >Report Ordini
            </b-dropdown-item>
            <b-dropdown-item @click="modalDownload('attività')"
              >Report Attività
            </b-dropdown-item>
          </b-dropdown>
          <!-- <b-button class="py-2">Invia</b-button> -->
          <b-dropdown
            variant="outline-secondary"
            dropright
            text="Assegna"
            class=""
          >
            <b-dropdown-item @click="modalAssegna('attività')">
              Attività
            </b-dropdown-item>
            <b-dropdown-item @click="modalAssegna('questionario')">
              Questionario
            </b-dropdown-item>
            <b-dropdown-item @click="modalAssegna('prestazioni')">
              Prestazione
            </b-dropdown-item>
            <b-dropdown-item @click="modalAssegna('progetto')">
              Progetto
            </b-dropdown-item>
          </b-dropdown>

          <b-dropdown
            variant="outline-secondary"
            dropright
            text="Disabilita"
            class=""
          >
            <b-dropdown-item @click="modalDisabilita('attività')">
              Attività
            </b-dropdown-item>
            <b-dropdown-item @click="modalDisabilita('questionario')">
              Questionario
            </b-dropdown-item>
            <b-dropdown-item @click="modalDisabilita('prestazioni')">
              Prestazione
            </b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </div>
      <b-form-select
        v-capitalize-first-select
        size=""
        :class="$root.isMobile ? 'order-1' : ''"
        class="col-3 ml-4 mb-0 align-self-start"
        name="vedi"
        v-model="filterSetted.take"
        :options="takeOptions"
      >
      </b-form-select>
    </div>
    <div class="">
      <b-table
        borderless
        hover
        responsive
        :fields="fields"
        :items="companies"
        :small="$root.isMobile"
        class="general_border puntofarmaCard py-2 mt-2 text-center"
        foot-clone
        selectable
        select-mode="multi"
        @row-selected="onRowSelected"
        ref="companyTable"
      >
        <template #head(selection)="data">
          <span class="text-info">{{ data.label }}</span>
          <b-form-checkbox
            @change="toggleSelection"
            :checked="companies && selectedCompanies.length == companies.length"
          ></b-form-checkbox>
        </template>
        <template #cell(selection)="{ rowSelected }">
          <!-- <b-form-checkbox :checked="rowSelected"></b-form-checkbox> -->
          <span>
            <b-icon
              variant="secondary"
              :icon="rowSelected ? 'check-circle-fill' : 'circle'"
            ></b-icon>
          </span>
        </template>
        <template #cell(enabled)="row">
          <p class="mb-0 text-center">
            <b-icon
              icon="circle-fill"
              :variant="row.item.enabled == 1 ? 'success' : 'danger'"
            ></b-icon>
          </p>
        </template>
        <template #cell(updated_at)="row">
          <span>{{ row.item.updated_at | moment("DD/MM/YYYY HH:mm") }}</span>
        </template>
        <!-- <template #cell(denominazione)="row">
          <b-form-checkbox :checked="rowSelected"></b-form-checkbox>
          <span>{{ row.index }}</span>
        </template> -->
        <template #cell(azioni)="row">
          <div class="d-flex text-center">
            <b-button
              size="sm"
              variant="secondary px-3 mx-1"
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? "Chiudi" : "Scheda" }}
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              type="button"
              variant="secondary"
              @click="
                $router.push({
                  name: 'dettaglio azienda',
                  params: { company: row.item.hashid },
                })
              "
              >Panoramica</b-button
            >
          </div>
        </template>
        <template #row-details="row">
          <article
            v-if="row && row.item"
            class="bg-white border border-info general_border p-4 text-left"
            style="max-width: 800px"
          >
            <h3 class="py-3 bg-whitesmoke px-3 general_border">
              Dettaglio {{ row.item.denominazione }}
            </h3>
            <b-row class="pt-3 ml-n3" cols="2">
              <b-col class="pl-3 pr-0">
                <h5 class="text-secondary pl-2">
                  <b-icon icon="house-fill" variant="secondary"></b-icon>
                  sede
                </h5>
                <section
                  class="general_border pl-2 mb-4"
                  style="max-width: 350px"
                >
                  <p class="mb-0">
                    {{ row.item.indirizzo }} {{ row.item.cap }},
                    {{ row.item.frazione }} {{ row.item.comune }}
                  </p>
                  <p class="mb-0">
                    {{ row.item.provincia }} {{ row.item.regione }}
                  </p>
                  <p class="mb-0">
                    <span class="fw-525 text-lowercase">codice asl: </span>
                    {{ row.item.codice_asl }}
                    <span class="fw-525 ml-2 text-lowercase">comunale: </span>
                    {{ row.item.comunale == 1 ? "sì" : "no" }}
                    <span class="fw-525 ml-2 text-lowercase">azienda: </span>
                    {{ row.item.azienda == 1 ? "sì" : "no" }}
                  </p>
                </section>

                <h5 class="text-secondary pl-2">
                  <b-icon icon="person-fill" variant="secondary"></b-icon>
                  titolare
                </h5>
                <section
                  class="general_border pl-2 mb-4"
                  style="max-width: 350px"
                >
                  <p class="mb-0 text-capitalize">
                    {{ row.item.nome }} {{ row.item.cognome }}
                  </p>
                  <p class="mb-0 text-uppercase">
                    <span class="fw-525 text-lowercase">codice fiscale: </span>
                    {{ row.item.codice_fiscale }}
                    <span class="d-flex flex-wrap">
                      <span class="no-wrap fw-525 text-lowercase"
                        >partita iva:</span
                      >
                      {{ row.item.partita_iva }}
                    </span>
                  </p>
                  <p class="text-uppercase">
                    <span class="fw-525 text-lowercase">codice titolare: </span>
                    {{ row.item.codice_titolare }}

                    <span class="d-flex flex-wrap">
                      <span class="fw-525 text-lowercase"
                        >codice tracciabilità:
                      </span>
                      {{ row.item.codice_tracciabilita }}
                    </span>
                  </p>
                </section>

                <h5 class="text-secondary pl-2">
                  <b-icon icon="telephone-fill" variant="secondary"></b-icon>
                  contatti
                </h5>
                <section class="general_border pl-2" style="max-width: 350px">
                  <p class="text-uppercase">
                    <span class="fw-525 text-lowercase">email: </span>
                    {{ row.item.email }}
                    <span class="fw-525 ml-2 text-lowercase">pec: </span>
                    {{ row.item.emailpec }}<br />
                    <span class="fw-525 text-lowercase">telefono: </span>
                    {{ row.item.telefono }}
                    <span class="fw-525 ml-2 text-lowercase">fax: </span>
                    {{ row.item.fax }}
                    <span class="fw-525 text-lowercase">contatto DPO: </span>
                    {{ row.item.contatto_dpo }}
                  </p>
                </section>
              </b-col>
              <b-col class="pl-3 pr-0">
                <h5 class="text-secondary pl-2">
                  <b-icon icon="clock-fill" variant="secondary"></b-icon>
                  apertura
                </h5>
                <section class="general_border pl-2" style="max-width: 350px">
                  <p v-if="row.item.apertura && row.item.apertura.length > 0">
                    <span v-for="(gg, index) in row.item.apertura" :key="index">
                      <span class="fw-525">{{ Object.keys(gg)[0] }}: </span>
                      <strong v-if="gg[Object.keys(gg)[0]] == 'chiuso'"
                        >Chiuso</strong
                      >
                      <strong v-else>
                        {{ gg[Object.keys(gg)[0]]["mattina"] }}
                        {{ gg[Object.keys(gg)[0]]["pomeriggio"] }}</strong
                      >
                      <br />
                    </span>
                  </p>
                  <p v-else>Non inseriti</p>
                  <div class="py-2">
                    <b-button
                      type="button"
                      variant="outline-secondary"
                      @click="
                        $router.push({
                          name: 'dettaglio azienda',
                          params: { company: row.item.hashid },
                        })
                      "
                      >Vai a Panoramica</b-button
                    >
                  </div>
                </section>
              </b-col>
            </b-row>
          </article>
        </template>
      </b-table>
      <p class="mb-0" v-if="companies !== null && companies.length == 0">
        Nessun risultato.
      </p>
      <b-pagination
        v-model="filterSetted.page"
        :total-rows="total"
        :per-page="perPage"
        align="center"
        :size="$root.isMobile ? 'sm' : ''"
      ></b-pagination>
      <!-- <p>Selezionati: {{ selectedCompanies }}</p> -->
      <!-- <div v-if="selectedCompanies.length > 0"> -->
    </div>

    <b-modal
      size="xl"
      centered
      ref="modal-scarica"
      :title="modalTitle"
      hide-footer
      body-class="pt-0"
      title-class="px-2"
    >
      <CompanyReport
        :azienda="selectedCompanies"
        v-if="modalKind == 'prestazioni'"
      />

      <div
        v-else-if="modalKind == 'attività'"
        class="py-4 bg-extra-light border border-info general_border px-4"
      >
        <b-form-group
          size="sm"
          class="text-left px-0"
          label="Scegli attività per il report"
        >
          <b-form-select
            v-capitalize-first-select
            name="activity"
            class=" "
            v-model="selectedActivity"
          >
            <b-form-select-option :value="null">-</b-form-select-option>
            <b-form-select-option
              v-for="(activity, hash) in activities"
              :key="hash"
              :value="hash"
              >{{ activity }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-button
          variant="secondary"
          class="mt-4"
          :disabled="selectedActivity == null"
          @click="downloadActivityReport"
        >
          Scarica excel</b-button
        >
      </div>
      <h5 v-else class="text-secondary">Funzionalità in sviluppo</h5>
    </b-modal>

    <b-modal
      size="xl"
      centered
      ref="modal-assegna"
      :title="modalTitle"
      hide-footer
      body-class="pt-0"
      title-class="px-2"
    >
      <div v-if="modalKind == 'attività'" class="px-2 pt-1 pb-4 text-left">
        <p class="d-flex align-items-center mb-4 general_border py-2">
          <b-icon
            icon="info-circle"
            variant="secondary"
            class="mr-2"
            font-scale="1.5"
          ></b-icon>
          L'attività scelta e i suoi questionari attivi saranno disponibili per
          le aziende selezionate.
        </p>
        <section
          class="bg-extra-light border border-info general_border px-4 py-4"
        >
          <b-form-group
            size="sm"
            class="text-left"
            label="Scegli attività da assegnare"
          >
            <b-form-select
              v-capitalize-first-select
              name="activity"
              class=" "
              v-model="selectedActivity"
            >
              <b-form-select-option :value="null">-</b-form-select-option>
              <b-form-select-option
                v-for="(activity, hash) in activities"
                :key="hash"
                :value="hash"
                >{{ activity }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-button
            variant="secondary text-white "
            class="mt-4"
            :disabled="selectedActivity == null"
            @click="assignActivity(true)"
            >Conferma</b-button
          >
        </section>
      </div>
      <div
        v-else-if="modalKind == 'questionario'"
        class="px-2 pb-4 pt-2 text-center"
      >
        <p class="d-flex align-items-center mb-4 general_border py-2">
          <b-icon
            icon="info-circle"
            variant="secondary"
            class="mr-2"
            font-scale="1.5"
          ></b-icon>
          Il questionario selezionato sarà disponibile per le aziende
          selezionate.
        </p>
        <section
          class="bg-extra-light border border-info general_border px-4 py-4 text-left"
        >
          <b-form-group
            size="sm"
            class="text-left"
            label="Scegli attività del questionario da assegnare per le aziende selezionate"
          >
            <b-form-select
              v-capitalize-first-select
              name="activity"
              class=" "
              v-model="surveyFilter.activity"
              @change="fetchSurveys"
            >
              <b-form-select-option :value="null">-</b-form-select-option>
              <b-form-select-option
                v-for="(activity, hash) in activities"
                :key="hash"
                :value="activity"
                >{{ activity }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group
            v-if="surveys"
            size="sm"
            class="text-left my-2 mt-4"
            label="Seleziona questionario da assegnare per le aziende selezionate"
          >
            <b-form-select
              v-capitalize-first-select
              name="survey"
              class=" "
              v-model="selectedSurvey"
            >
              <b-form-select-option :value="null">-</b-form-select-option>
              <b-form-select-option
                v-for="(survey, hash) in surveys"
                :key="hash"
                :value="hash"
                >{{ survey }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-button
            variant="secondary"
            class="mt-4"
            :disabled="selectedSurvey == null"
            @click="assignSurvey(true)"
          >
            Conferma</b-button
          >
        </section>
      </div>
      <div
        v-else-if="modalKind == 'progetto'"
        class="px-2 pb-4 pt-2 text-center"
      >
        <p class="d-flex align-items-center mb-4 general_border py-2">
          <b-icon
            icon="info-circle"
            variant="secondary"
            class="mr-2"
            font-scale="1.5"
          ></b-icon>
          Assegna adesione al progetto per le strutture selezionate
        </p>
        <section
          class="bg-extra-light border border-info general_border px-4 py-4 text-left"
        >
          <b-form-group
            size="sm"
            class="text-left"
            label="Scegli il progetto interessato"
            label-for="project"
          >
            <b-form-select
              v-capitalize-first-select
              class="mb-3 mt-2 text-capitalize"
              name="project"
              v-model="form.project_id"
            >
              <b-form-select-option
                v-for="project in projects"
                :value="project.hashid"
                :key="project.hashid"
                >{{ project.name }}</b-form-select-option
              >
              <b-form-select-option :value="null"
                >Nessun progetto</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <b-button
            variant="secondary"
            class="mt-4"
            :disabled="form.project_id == null"
            @click="assignProject"
          >
            Conferma</b-button
          >
        </section>
      </div>
      <h5 v-else class="text-secondary">Funzionalità in sviluppo</h5>
    </b-modal>

    <b-modal
      size="xl"
      centered
      ref="modal-disabilita"
      :title="modalTitle"
      hide-footer
      body-class="pt-0"
      title-class="px-2"
    >
      <div v-if="modalKind == 'attività'" class="px-2 pt-1 pb-4 text-left">
        <p class="d-flex align-items-center mb-4 general_border py-2">
          <b-icon
            icon="info-circle"
            variant="secondary"
            class="mr-2"
            font-scale="1.5"
          ></b-icon>
          L'attività selezionata ed i suoi questionari non saranno più
          disponibili per le aziende selezionate.
        </p>
        <section
          class="bg-extra-light border border-info general_border px-4 py-4 text-left"
        >
          <b-form-group
            size="sm"
            class="text-left"
            label="Scegli attività da disabilitare per le aziende selezionate"
          >
            <b-form-select
              v-capitalize-first-select
              name="activity"
              class=" "
              v-model="selectedActivity"
            >
              <b-form-select-option :value="null">-</b-form-select-option>
              <b-form-select-option
                v-for="(activity, hash) in activities"
                :key="hash"
                :value="hash"
                >{{ activity }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-button
            variant="secondary"
            class="mt-4"
            :disabled="selectedActivity == null"
            @click="assignActivity(false)"
          >
            Conferma</b-button
          >
        </section>
      </div>

      <div
        v-else-if="modalKind == 'questionario'"
        class="px-2 pt-1 pb-4 text-left"
      >
        <p class="d-flex align-items-center mb-4 general_border pt-0">
          <b-icon
            icon="info-circle"
            variant="secondary"
            class="mr-2"
            font-scale="1.5"
          ></b-icon>
          Il questionario selezionato non sarà più disponibile per le aziende
          selezionate.
        </p>
        <section
          class="bg-extra-light border border-info general_border px-4 py-4 text-left"
        >
          <b-form-group
            size="sm"
            class="text-left"
            label="Scegli attività del questionario da disabilitare per le aziende selezionate"
          >
            <b-form-select
              v-capitalize-first-select
              name="activity"
              class=" "
              v-model="surveyFilter.activity"
              @change="fetchSurveys"
            >
              <b-form-select-option :value="null">-</b-form-select-option>
              <b-form-select-option
                v-for="(activity, hash) in activities"
                :key="hash"
                :value="activity"
                >{{ activity }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group
            v-if="surveys"
            size="sm"
            class="text-left my-2 mt-4"
            label="Scegli attività del questionario da disabilitare per le aziende selezionate"
          >
            <b-form-select
              v-capitalize-first-select
              name="survey"
              class=" "
              v-model="selectedSurvey"
            >
              <b-form-select-option :value="null">-</b-form-select-option>
              <b-form-select-option
                v-for="(survey, hash) in surveys"
                :key="hash"
                :value="hash"
                >{{ survey }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-button
            variant="secondary"
            class="mt-4"
            :disabled="selectedSurvey == null"
            @click="assignSurvey(false)"
          >
            Conferma</b-button
          >
        </section>
      </div>
      <h5 v-else class="text-secondary">Funzionalità in sviluppo</h5>
    </b-modal>
  </div>
</template>
<script>
import { supportService, adminService } from "@/_services";
import { mapState, mapActions } from "vuex";
import CompactSearch from "@/components/admin/CompactSearch.vue";
import CompanyReport from "@/components/admin/CompanyReport.vue";
export default {
  name: "GestioneAziende",
  components: { CompactSearch, CompanyReport },
  data() {
    return {
      companies: null,
      fields: [
        { key: "selection", label: "" },
        // { key: "ff_username", label: "Username" },
        { key: "denominazione", label: "Denominazione", class: "text-justify" },
        { key: "cognome", label: "Cognome", class: "text-capitalize" },
        { key: "regione", label: "Regione", class: "text-capitalize" },
        { key: "provincia", label: "Provincia", class: "text-capitalize" },
        { key: "comune", label: "Comune", class: "text-capitalize" },
        // { key: "codice_fiscale", label: "Codice Fiscale" },
        { key: "codice_tracciabilita", label: "Codice Tracciabilità" },
        { key: "partita_iva", label: "Partita Iva" },
        { key: "enabled", label: "Abilitato" },
        { key: "updated_at", label: "Ultimo login" },
        { key: "azioni", label: "" },
      ],
      filterSetted: {
        page: 1,
        take: 10,
      },
      total: 0,
      fromPage: 0,
      toPage: 0,
      perPage: 0,
      selectedCompanies: [],
      takeOptions: [10, 25, 50, 100],
      modalKind: null,
      modalTitle: "",
      activityFilter: {
        inarray: true,
      },
      surveyFilter: {
        activity: null,
        inarray: true,
      },
      activities: null,
      surveys: null,
      selectedActivity: null,
      selectedSurvey: null,
      companyArray: [],
      canSelectHasChanged: false,
      form: {
        project_id: null,
      },
    };
  },
  computed: {
    canSelect: function () {
      return (
        this.companies &&
        this.companies.length &&
        this.selectedCompanies &&
        this.selectedCompanies.length < 1
      );
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utility", ["projects"]),
  },
  watch: {
    canSelect: {
      handler(newValue, previousValue) {
        this.canSelectHasChanged = true;
      },
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utility", ["getProjects"]),
    updatePreferenze(risultati) {
      this.companies = risultati.data;
      this.filterSetted.page = risultati.current_page;
      this.total = risultati.total;
      this.fromPage = risultati.from;
      this.toPage = risultati.to;
      this.perPage = risultati.per_page;
    },
    onRowSelected(items) {
      this.selectedCompanies = items;
    },
    toggleSelection(ev) {
      // console.log("event", ev);
      if (ev) {
        this.$refs.companyTable.selectAllRows();
      } else {
        this.$refs.companyTable.clearSelected();
      }
    },
    modalDownload(kind) {
      this.modalKind = kind;
      this.modalTitle = "Scarica report " + kind;
      this.$refs["modal-scarica"].show();
    },
    modalAssegna(kind) {
      this.modalKind = kind;
      this.modalTitle = "Assegna " + kind;
      this.$refs["modal-assegna"].show();
      var companies = [];
      this.selectedCompanies.map(function (el) {
        companies.push(el.hashid);
      });
      this.companyArray = companies;
    },
    modalDisabilita(kind) {
      this.modalKind = kind;
      this.modalTitle = "Disabilita " + kind;
      this.$refs["modal-disabilita"].show();
      var companies = [];
      this.selectedCompanies.map(function (el) {
        companies.push(el.hashid);
      });
      this.companyArray = companies;
    },
    fetchActivities() {
      var self = this;
      supportService
        .getActivities(this.activityFilter)
        .then(function (res) {
          self.activities = res.data.data;
          self.canSelectHasChanged = false;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
      //
    },
    assignActivity(state) {
      var dataForm = {
        hash: this.selectedActivity,
        form: {
          state: state,
          companies: this.companyArray,
        },
      };
      var msg =
        state == true
          ? "Attività assegnata con successo!"
          : "Attività disabilitata con successo!";
      var self = this;
      adminService
        .enableActivityFor(dataForm)
        .then(function (res) {
          self.$refs["modal-assegna"].hide();
          self.$refs["modal-disabilita"].hide();
          window.scrollTo(0, 0);
          self.successAlert(msg);
        })
        .catch(function (err) {
          self.$refs["modal-assegna"].hide();
          self.$refs["modal-disabilita"].hide();
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile completare l'operazione");
          console.log(err);
        });
    },
    assignSurvey(state) {
      var dataForm = {
        hash: this.selectedSurvey,
        form: {
          state: state,
          companies: this.companyArray,
        },
      };
      var msg =
        state == true
          ? "Questionario assegnato con successo!"
          : "Questionario disabilitato con successo!";
      var self = this;
      adminService
        .enableSurveyFor(dataForm)
        .then(function (res) {
          self.$refs["modal-assegna"].hide();
          self.$refs["modal-disabilita"].hide();
          window.scrollTo(0, 0);
          self.successAlert(msg);
        })
        .catch(function (err) {
          self.$refs["modal-assegna"].hide();
          self.$refs["modal-disabilita"].hide();
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile assegnare i questionari");
          console.log(err);
        });
    },
    assignProject() {
      const self = this;
      adminService
        .assignProjectToCompany(this.companyArray[0], this.form)
        .then(function (res) {
          self.$refs["modal-assegna"].hide();
          window.scrollTo(0, 0);
          self.successAlert("Progetto assegnato con successo!");
        })
        .catch(function (err) {
          self.$refs["modal-assegna"].hide();
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile assegnare il progetto");
          console.log(err);
        });
    },
    fetchSurveys() {
      var self = this;
      supportService
        .getSurveys(this.surveyFilter)
        .then(function (response) {
          self.surveys = response.data.data;
          // self.filterSetted.page = response.data.data.current_page;
          // self.total = response.data.data.total;
          // self.fromPage = response.data.data.from;
          // self.toPage = response.data.data.to;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
    },
    downloadActivityReport() {
      // console.log("comp", this.selectedCompanies);
      // console.log("act", this.selectedActivity);
      var companies = this.selectedCompanies.map(function (a) {
        return a.hashid;
      });
      var dataToSend = {
        hash: this.selectedActivity,
        form: { company: companies },
      };
      var self = this;
      supportService
        .getActivityReportForCompanies(dataToSend)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Report_attività.xlsx";
          link.click();
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile scaricare il report per l'attività"
          );
          console.log(err);
        });
    },
    // checkRow(index, selected) {
    //   this.$refs.companyTable.selectRow(index);
    //   console.log("SELECTED", this.$refs.companyTable);
    //   if (selected) {
    //     this.$refs.companyTable.selectRow(index);
    //   } else {
    //     this.$refs.companyTable.unselectRow(index);
    //   }
    // },
  },
  mounted() {
    this.fetchActivities();
    this.getProjects();
  },
};
</script>
