<template>
  <div>
    <FloatingIframe
      v-if="openBancaDati"
      :openBancaDati="openBancaDati"
      @close-banca-dati="openBancaDati = null"
    />
    <!-- <FloatingIframeOld/> -->
    
    <div class="w-sm-100 d-md-flex">
      <IdleTracker
        v-if="user.session_timeout_minutes && user.session_timeout_minutes > 0"
        :userSessionTimeout="user.session_timeout_minutes"
      />
      <section id="nav-desktop" style="z-index: 5">
        <NavigationOperatoreCRoom
          v-if="company && company.level === 5"
          :wlistener="innerWidth"
        />

        <NavigationOperatore
          v-else
          :wlistener="innerWidth"
          @open-banca-dati="openBancaDati = $event"
        />
      </section>

      <div class="w-100">
      <OperatoreLeftHeader />

      <!-- <section
        id="main-operatore"
        class="bg-extra-light pt-4 px-2 px-md-3 px-lg-4"
      > -->
      <section id="main-operatore" class="pt-4 px-2 px-md-3 px-lg-4">
        <router-view></router-view>
      </section>
    </div>
    </div>

  </div>
</template>
<script>
import { mapState, mapActions } from "vuex"
import NavigationOperatore from "@/components/layout/NavigationOperatore.vue"
import NavigationOperatoreCRoom from "@/components/layout/NavigationOperatoreCRoom.vue"
import IdleTracker from "@/components/utilities/IdleTracker.vue"
import FloatingIframe from "@/components/dashboard/FloatingIframe.vue"
import OperatoreLeftHeader from "@/components/layout/OperatoreLeftHeader.vue";

export default {
  name: "OperatoreMain",
  components: {
    NavigationOperatore,
    NavigationOperatoreCRoom,
    IdleTracker,
    FloatingIframe,
    OperatoreLeftHeader
    // FloatingIframeOld
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      openBancaDati: null
    }
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    welcomeName() {
      if (this.user && this.user.anagrafica) {
        return (
          this.user.anagrafica.name.toLowerCase() +
          " " +
          this.user.anagrafica.surname.toLowerCase()
        )
      }
      return this.user.username
    }
  },
  methods: {
    ...mapActions("company", ["logUserCompanyIn", "companyLogin"]),
    innerWidthChanged() {
      // console.log("resize handler");
      this.innerWidth = window.innerWidth
    }
  },
  created() {
    if (this.user.type !== 1) {
      this.$router.push({ name: "Home" })
    }
    window.addEventListener("resize", this.innerWidthChanged)
  },
  mounted() {
    this.logUserCompanyIn()
    if (
      this.user.expired_password == 1 &&
      this.$route.name !== "profilo operatore"
    ) {
      this.$router.push({
        name: "profilo operatore",
        query: { scope: "expired" }
      })
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler)
  }
}
</script>
<style lang="scss" scoped>
#nav-desktop {
  transition: flex 500ms;
}

@media all and (min-width: 992px) {
  #main-operatore {
    flex: 1;
    min-width: 0;
  }

  #nav-desktop {
    flex: 0 0 100%;
    min-width: 100%;
  }
}

@media all and (max-width: 1199px) {
  #main-operatore {
    flex: 1;
    min-width: 0;
  }

  #nav-desktop {
    flex: 0 0 79px;
    min-width: 0;
  }
}

@media all and (min-width: 1200px) {
  #main-operatore {
    flex: 1;
    min-width: 0;
  }

  #nav-desktop {
    flex: 0 0 15em;
    min-width: 0;
  }
}

@media all and (min-width: 813px) {
  #main-operatore {
    min-height: calc(100vh - 37.75px);
  }
}

@media all and (min-width: 448px) and (max-width: 812px) {
  #main-operatore {
    min-height: calc(100vh - 147px);
  }
}

@media all and (min-width: 312px) and (max-width: 447px) {
  #main-operatore {
    min-height: calc(100vh - 81.25px);
  }
}

@media all and (max-width: 311px) {
  #main-operatore {
    min-height: calc(100vh - 95px);
  }
}
</style>
