<template>
  <!-- <b-row
    :class="{
      'margin-top-higher': !onlyOne,
    }"
  > -->
  <b-row>
    <b-row v-if="!onlyOne" class="w-100 mb-n2 general_border bg-white">
      <b-col
        class="pb-2 pt-1 text-center general_border point font-size-08rem white-space-nowrap"
        :class="
          pickedFilter === 'value1'
            ? 'bg-secondary text-white'
            : 'bg-opacity-0 text-secondary'
        "
        @click="pickedFilter = 'value1'"
      >
        <span class="d-none d-lgx-inline">{{ firstLabel }}</span>
        <span class="d-lgx-none">{{ shortFirstLabel }}</span>
      </b-col>
      <b-col
        class="pb-2 pt-1 text-center general_border point font-size-08rem white-space-nowrap"
        :class="
          pickedFilter === 'value2'
            ? 'bg-secondary text-white'
            : 'bg-opacity-0 text-secondary'
        "
        @click="pickedFilter = 'value2'"
      >
        <span class="d-none d-lgx-inline">{{ secondLabel }}</span>
        <span class="d-lgx-none">{{ shortSecondLabel }}</span>
      </b-col>
    </b-row>

    <b-col class="col-12 px-0">
      <b-input-group size="sm" class="px-0 col-12">
        <b-form-input
          size="sm"
          v-if="pickedFilter === 'value1' && !onlySecond"
          class="m-0"
          v-model="value1"
          type="search"
          :placeholder="firstPlaceholder"
        >
        </b-form-input>
        <b-form-input
          size="sm"
          v-else-if="pickedFilter === 'value2' && !onlyFirst"
          class="m-0"
          v-model="value2"
          type="search"
          :placeholder="secondPlaceholder"
        >
        </b-form-input>
        <b-icon
          icon="search"
          class="search-icon"
          variant="secondary"
          font-scale="1.2"
        ></b-icon>
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "LabeledSearchInputComponent",
  props: [
    "onlyFirst",
    "onlySecond",
    "firstLabel",
    "shortFirstLabel",
    "firstValue",
    "firstPlaceholder",
    "firstValueUppercase",
    "secondLabel",
    "shortSecondLabel",
    "secondValue",
    "secondPlaceholder",
    "secondValueUppercase",
  ],
  data() {
    return {
      pickedFilter: null,
    };
  },
  watch: {
    pickedFilter: {
      handler: function (newVal) {
        if (newVal === "value1") {
          this.$emit("switched-to-first");
        } else {
          this.$emit("switched-to-second");
        }
      },
    },
  },
  computed: {
    value1: {
      get() {
        return this.firstValue;
      },
      set(value) {
        const valueToEmit = this.firstValueUppercase
          ? value.toUpperCase()
          : value;
        this.$emit("first-value-changed", valueToEmit);
      },
    },
    value2: {
      get() {
        return this.secondValue;
      },
      set(value) {
        const valueToEmit = this.secondValueUppercase
          ? value.toUpperCase()
          : value;
        this.$emit("second-value-changed", valueToEmit);
      },
    },
    onlyOne: function () {
      return this.onlyFirst || this.onlySecond;
    },
  },
  created() {
    this.pickedFilter = "value1";
    if (this.onlySecond) {
      this.pickedFilter = "value2";
    }
  },
};
</script>
<style lang="scss" scoped>
.selected-search-filter {
  background-color: $secondary;
}

.margin-top-higher {
  margin-top: 1.3rem;
}

.bg-opacity-0 {
  background-color: rgba(0, 0, 0, 0);
}

.font-size-08rem {
  font-size: 0.8rem;
}
</style>
