<template>
  <div class="text-secondary">
    <template v-if="$route.params.section === 'voucher'">
      <VoucherSottoscrizioni></VoucherSottoscrizioni>
    </template>
    <h6 v-else>Funzionalità in sviluppo</h6>
  </div>
</template>
<script>
import VoucherSottoscrizioni from "@/components/admin/sottoscrizioni/VoucherSottoscrizioni.vue";
export default {
  name: "MainSottoscrizioniAdmin",
  components: { VoucherSottoscrizioni },
  props: [],
  data() {
    return {};
  },
  created() {},
};
</script>
