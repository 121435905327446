<template>
  <b-container fluid class="m-0 p-0">
    <b-row
      cols="1"
      cols-md="2"
      class="justify-content-bewteen py-3"
      align-v="center"
    >
      <b-col class="align-self-end">
        <h4 class="text-prim-grad-1 text-left py-1 mb-0">
          Prenotazioni ricorrenti
        </h4>
      </b-col>
      <b-col class="text-left text-md-right">
        <b-button @click="createNewReservation()">
          <b-icon icon="plus"></b-icon> nuova prenotazione ricorrente
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-n3" id="prenotazioni-ricorrenti-wrapper" align-v="stretch">
      <b-col
        v-if="isFetchDataCompleted && userRecurrentReservations.length < 1"
        class="mt-5 pl-4 d-flex justify-content-start align-items-center col-12"
      >
        <p class="text-left pl-1">Nessuna prenotazione ricorrente presente.</p>
      </b-col>

      <template v-else>
        <PrenotazioneRicorrenteCard
          v-for="(reservation, index) in userRecurrentReservations"
          @edit-freq="showEdit($event)"
          @delete-freq="showDelete($event)"
          @suspend-freq="showSuspend($event)"
          @unsuspend-freq="showUnsuspend($event)"
          :key="index"
          :res="reservation"
        />
      </template>
    </b-row>
    <b-modal
      id="patient-recurring-reservation"
      :title="recurringModalTitle"
      hide-footer
      size="lg"
      body-class="pt-0 px-2"
      centered
    >
      <p class="px-3">
        Le prenotazioni ricorrenti permettono di inserire automaticamente
        appuntamenti per teleconsulto o per prestazione sanitaria con la
        frequenza indicata.
      </p>
      <b-form @submit.prevent="submitRecurrentReservation()">
        <b-row class="d-flex flex-wrap mt-n1 mt-md-n2">
          <b-form-group
            v-if="isOperatoreControlRoom"
            label="Presso*"
            label-for="company"
            class="text-left col-12 col-md-6 my-1 my-md-2"
          >
            <b-form-select
              v-capitalize-first-select
              id="company"
              v-model="recurrentReservation.company_id"
              required
              :disabled="isEditingReservation"
            >
              <b-form-select-option
                v-for="company in patientCompanies"
                :key="company.hashid"
                :value="company.hashid"
                >{{ company.denominazione }} ({{
                  company.sottotipo
                }})</b-form-select-option
              >
              <b-form-select-option :value="null" disabled
                >Scegli...</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <b-form-group
            v-if="!isOperatoreControlRoom || recurrentReservation.company_id"
            label="Operatore"
            label-for="operator"
            class="text-left col-12 col-md-6 my-1 my-md-2"
          >
            <b-form-select
              v-capitalize-first-select
              id="operator"
              v-model="recurrentReservation.operator_id"
              required
            >
              <b-form-select-option
                v-for="operator in pickedCompanyOperators"
                :key="operator.hashid"
                :value="operator.hashid"
                >{{ operator.anagrafica.name }}
                {{ operator.anagrafica.surname }}</b-form-select-option
              >
              <b-form-select-option :value="null" disabled
                >Scegli...</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <template
            v-if="!isOperatoreControlRoom || recurrentReservation.company_id"
          >
            <b-form-group
              label="Tipo di Servizio*"
              label-for="service"
              class="text-left col-12 col-md-6 my-1 my-md-2"
            >
              <b-form-select
                v-capitalize-first-select
                id="service"
                v-model="recurrentReservation.orderable_type"
                required
                :disabled="isEditingReservation"
              >
                <b-form-select-option value="prestazione_sanitaria"
                  >Prestazione</b-form-select-option
                >
                <b-form-select-option
                  value="teleconsulto"
                  :disabled="isTeleconsultoBookingBlocked"
                  >Teleconsulto</b-form-select-option
                >
                <b-form-select-option :value="null" disabled
                  >Scegli...</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <SelectCompanyHealthServices
              v-if="
                recurrentReservation.orderable_type === 'prestazione_sanitaria'
              "
              v-model="recurrentReservation.orderable_id"
              class="col-12 col-md-6 my-1 my-md-2"
              required
              label="Prestazione*"
              :disableOptionFunction="
                (option) =>
                  !option.data?.disponibilita ||
                  option.data?.disponibilita?.length < 1 ||
                  alreadyBookedHS.includes(option.value)
              "
              :disabled="isEditingReservation"
            />

            <b-form-group
              label="Frequenza Inserimento*"
              class="text-left col-12 col-md-6 my-1 my-md-2 d-flex justify-content-start align-items-start"
            >
              <div class="d-flex mt-n1">
                <b-form-input
                  id="frequency"
                  class="col-6 mr-2"
                  v-model="recurrentReservation.every_num"
                  type="number"
                  required
                ></b-form-input>
                <b-form-select
                  v-capitalize-first-select
                  id="every_um"
                  class="col-6 ml-1"
                  v-model="recurrentReservation.every_um"
                  required
                >
                  <b-form-select-option
                    v-for="frequency in frequencyOptions"
                    :key="frequency.value"
                    :value="frequency.value"
                    >{{ frequency.text }}</b-form-select-option
                  >
                  <b-form-select-option :value="null" disabled
                    >Scegli...</b-form-select-option
                  >
                </b-form-select>
              </div>
            </b-form-group>
            <b-form-group
              label="Inserisci prenotazione*"
              description="Quanti giorni prima il sistema dovrà inserire in agenda la prenotazione"
              label-for="orderable_id"
              class="text-left col-12 col-md-6 my-1 my-md-2"
            >
              <!-- <b-input-group class="border-0">

              <b-form-input
                id="days_in_advance"
                v-model="recurrentReservation.days_in_advance"
                type="number"
                required
              ></b-form-input>
              
              </b-input-group> -->
              <b-input-group
                append="
                  Giorni prima
                "
                class="mb-2 mr-sm-2 mb-sm-0 text-lowercase"
              >
                <b-form-input
                  id="days_in_advance"
                  class="input-with-right-append"
                  v-model="recurrentReservation.days_in_advance"
                  type="number"
                  step="1"
                  min="1"
                  required
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </template>
        </b-row>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center col-12 px-2"
        >
          <span> *parametri obbligatori </span>
          <b-button
            type="submit"
            variant="secondary"
            class="mt-3"
            :disabled="!validData"
          >
            <span v-if="isEditingReservation">Salva modifiche</span>
            <span v-else>Salva</span>
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="suspend-recurrent-res"
      title="Sospendi Prenotazione Ricorrente"
      centered
      @ok="doSuspend()"
      @cancel="$bvModal.hide('suspend-recurrent-res')"
      ok-title="Sì, sospendi"
      ok-variant="popup-button"
      cancel-title="No, annulla"
      cancel-variant="popup-button"
      footer-border-variant="white"
    >
      <div>
        <h6>Confermi di voler sospendere la prenotazione ricorrente?</h6>
      </div>
    </b-modal>
    <b-modal
      id="unsuspend-recurrent-res"
      title="Riattiva Prenotazione Ricorrente"
      centered
      @ok="doUnsuspend()"
      @cancel="$bvModal.hide('unsuspend-recurrent-res')"
      ok-title="Sì, riattiva"
      ok-variant="popup-button"
      cancel-title="No, annulla"
      cancel-variant="popup-button"
      footer-border-variant="white"
    >
      <div>
        <h6>Confermi di voler riattivare la prenotazione ricorrente?</h6>
      </div>
    </b-modal>
    <b-modal
      id="delete-recurrent-res"
      title="Elimina Prenotazione Ricorrente"
      centered
      @ok="doDelete()"
      @cancel="$bvModal.hide('delete-recurrent-res')"
      ok-title="Sì, elimina"
      ok-variant="popup-button"
      cancel-title="No, annulla"
      cancel-variant="popup-button"
      footer-border-variant="white"
    >
      <div>
        <h6>Confermi di voler eliminare la prenotazione ricorrente?</h6>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { reservationService } from "@/_services";
import { croomService } from "@/_services";
import PrenotazioneRicorrenteCard from "@/components/profile/sanitario/PrenotazioneRicorrenteCard.vue";
import SelectCompanyHealthServices from "@/components/prenotazioni/form/SelectCompanyHealthServices.vue";

export default {
  name: "PrenotazioniRicorrentiPaziente",
  props: ["subject", "userinfo"],
  components: { PrenotazioneRicorrenteCard, SelectCompanyHealthServices },
  data() {
    return {
      userRecurrentReservations: [],
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill",
      },
      statusModalIcons: {
        confermato: "check-circle",
        ricevuto: "archive",
        annullato: "x-circle",
        rifiutato: "x-circle",
        "in attesa di risposta": "question-circle",
        evaso: "calendar2-check",
      },
      statusModalVariants: {
        confermato: "secondary",
        ricevuto: "primary-darker",
        annullato: "danger",
        rifiutato: "danger",
        "in attesa di risposta": "warning",
        evaso: "primary-darker",
      },
      frequencyOptions: [
        { value: 2, text: "giorni" },
        { value: 1, text: "settimane" },
        { value: 0, text: "mesi" },
      ],
      patientCompanies: [],
      recurrentReservation: {
        user_id: null,
        company_id: null,
        operator_id: null,
        orderable_type: null,
        orderable_id: null,
        days_in_advance: 2,
        every_num: 1,
        every_um: 1,
      },
      selectedReservationHash: null,
      isTeleconsultoBookingBlocked: false,
      alreadyBookedHS: [],
      isEditingReservation: false,
      editingReservationHashid: null,
      isFetchDataCompleted: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    isOperatoreControlRoom() {
      return this.user.company.level === 5;
    },
    recurringModalTitle() {
      if (!this.isEditingReservation) {
        return `Nuova prenotazione ricorrente per ${this.userinfo.anagrafica.name} ${this.userinfo.anagrafica.surname}`;
      } else {
        return `Modifica prenotazione ricorrente per ${this.userinfo.anagrafica.name} ${this.userinfo.anagrafica.surname}`;
      }
    },
    validData() {
      return (
        this.recurrentReservation.company_id &&
        this.recurrentReservation.orderable_type &&
        !(
          this.recurrentReservation.orderable_type ===
            "prestazione_sanitaria" && !this.recurrentReservation.orderable_id
        ) &&
        this.recurrentReservation.days_in_advance &&
        this.recurrentReservation.every_num &&
        this.recurrentReservation.every_um !== null &&
        !isNaN(this.recurrentReservation.every_um)
      );
    },
    pickedCompanyOperators() {
      if (this.patientCompanies.length < 1) return [];
      else
        return this.patientCompanies.find(
          (company) => company.hashid === this.recurrentReservation.company_id
        ).operators;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    submitRecurrentReservation() {
      var self = this;
      this.recurrentReservation.user_id = this.subject.hashid;

      if (this.isEditingReservation) {
        const updateData = { ...this.recurrentReservation };
        delete updateData.orderable_id;
        delete updateData.orderable_type;
        reservationService
          .updatePrenotazioneRicorrenteUser(
            updateData,
            this.editingReservationHashid
          )
          .then(function () {
            self.successAlert(
              "Prenotazione ricorrente modificata con successo"
            );
            self.editingReservationHashid = null;
            self.isEditingReservation = false;
            self.$bvModal.hide("patient-recurring-reservation");
            self.getPrenotazioniRicorrenti();
            self.resetRecurrentReservation();
          })
          .catch(function (error) {
            self.errorAlert("Non è stato possibile modificare la prenotazione");
            console.log(error);
          });
      } else {
        reservationService
          .storePrenotazioneRicorrenteUser(this.recurrentReservation)
          .then(function () {
            self.successAlert("Prenotazione ricorrente creata con successo");
            self.$bvModal.hide("patient-recurring-reservation");
            self.getPrenotazioniRicorrenti();
          })
          .catch(function (error) {
            self.errorAlert("Non è stato possibile creare la prenotazione");
            console.log(error);
          });
      }
    },
    getPatientCompanies() {
      const self = this;
      const params = {
        // grouped: true,
      };
      croomService
        .getUserCircoloCura(this.subject.hashid, params)
        .then(function (response) {
          self.patientCompanies = response.data.data;
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile ottenere il circolo di curo");
          console.log(error);
        });
    },
    getPrenotazioniRicorrenti() {
      this.isFetchDataCompleted = false;
      var self = this;
      reservationService
        .getPrenotazioniRicorrentiUser(this.subject.hashid)
        .then(function (response) {
          self.userRecurrentReservations = response.data.data;
          self.blockAlreadyBooked();
          self.isFetchDataCompleted = true;
        })
        .catch(function (error) {
          self.errorAlert(
            "Non è stato possibile ottenere le prenotazioni ricorrenti del paziente"
          );
          console.log(error);
        });
    },
    showEdit(hashid) {
      this.selectedReservationHash = hashid;
      this.isEditingReservation = true;
      const reservation = this.userRecurrentReservations.find(
        (res) => res.hashid === hashid
      );
      this.setEditReservationData(reservation);
      console.log("reservation", reservation);

      this.$bvModal.show("patient-recurring-reservation");
    },
    setEditReservationData(reservation) {
      this.recurrentReservation = {
        user_id: this.subject.hashid,
        company_id: reservation.company.hashid,
        operator_id: reservation.operator ? reservation.operator.hashid : null,
        orderable_type: reservation.orderable_type,
        orderable_id: reservation.orderable
          ? reservation.orderable.hashid
          : null,
        days_in_advance: reservation.days_in_advance,
        every_num: reservation.every_num,
        every_um: reservation.every_um,
      };
      this.editingReservationHashid = reservation.hashid;
    },
    showDelete(hashid) {
      this.selectedReservationHash = hashid;
      this.$bvModal.show("delete-recurrent-res");
    },
    doDelete() {
      const self = this;
      reservationService
        .deletePrenotazioneRicorrenteUser(this.selectedReservationHash)
        .then(function (response) {
          self.successAlert("Prenotazione ricorrente eliminata con successo");
          self.selectedReservationHash = null;
          self.$bvModal.hide("delete-recurrent-res");
          self.getPrenotazioniRicorrenti();
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile eliminare la prenotazione");
          console.log(error);
        });
    },
    showSuspend(hashid) {
      this.selectedReservationHash = hashid;
      this.$bvModal.show("suspend-recurrent-res");
    },
    doSuspend() {
      const self = this;
      reservationService
        .changeStatusPrenotazioneRicorrenteUser(
          this.selectedReservationHash,
          false
        )
        .then(function (response) {
          self.successAlert("Prenotazione ricorrente sospesa con successo");
          self.selectedReservationHash = null;
          self.$bvModal.hide("suspend-recurrent-res");
          self.getPrenotazioniRicorrenti();
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile sospendere la prenotazione");
          console.log(error);
        });
    },
    showUnsuspend(hashid) {
      this.selectedReservationHash = hashid;
      this.$bvModal.show("unsuspend-recurrent-res");
    },
    doUnsuspend() {
      const self = this;
      reservationService
        .changeStatusPrenotazioneRicorrenteUser(
          this.selectedReservationHash,
          true
        )
        .then(function (response) {
          self.successAlert("Prenotazione ricorrente riattivata con successo");
          self.selectedReservationHash = null;
          self.$bvModal.hide("suspend-recurrent-res");
          self.getPrenotazioniRicorrenti();
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile riattivare la prenotazione");
          console.log(error);
        });
    },
    blockAlreadyBooked() {
      if (this.userRecurrentReservations.length > 0) {
        this.isTeleconsultoBookingBlocked = this.userRecurrentReservations.some(
          (res) => res.orderable_type === "teleconsulto"
        );
        this.alreadyBookedHS = this.userRecurrentReservations
          .filter((res) => res.orderable_type === "prestazione_sanitaria")
          .map((res) => res.orderable.hashid);
      }
    },
    disableHealthServiceOption(option) {
      return function (option) {
        return (
          !option.data.disponibilita ||
          option.data.disponibilita.length < 1 ||
          this.alreadyBookedHS.includes(option.value)
        );
      };
    },
    resetRecurrentReservation() {
      this.recurrentReservation = {
        user_id: null,
        company_id: null,
        operator_id: null,
        orderable_type: null,
        orderable_id: null,
        days_in_advance: 2,
        every_num: 1,
        every_um: 1,
      };
      if (!this.isOperatoreControlRoom) {
        this.recurrentReservation.company_id = this.user.company.hashid;
      }
    },
    createNewReservation() {
      this.isEditingReservation = false;
      this.resetRecurrentReservation();
      this.$bvModal.show("patient-recurring-reservation");
    },
  },
  mounted() {
    this.getPatientCompanies();
    if (!this.isOperatoreControlRoom) {
      this.recurrentReservation.company_id = this.user.company.hashid;
    }
  },
  created() {
    this.getPrenotazioniRicorrenti();
  },
};
</script>
<style lang="scss" scoped>
#prenotazioni-ricorrenti-wrapper {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

input.input-with-right-append.form-control {
  border-right: none !important;
  border-left: 1px solid $border-color !important;
  border-top: 1px solid $border-color !important;
  border-bottom: 1px solid $border-color !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: $input-border-radius !important;
  border-bottom-left-radius: $input-border-radius !important;
  box-shadow: none !important;
}
</style>
