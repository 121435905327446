<template>
  <div class="col-12 px-0">
    <b-col class="col-12 align-content-center py-2 py-md-3 py-xl-4">
      <div class="mx-auto col-10 col-md-4 col-xl-3 py-2">
        <b-img
          :src="require('@/assets/concura-logo.svg')"
          fluid-grow
          alt="logo concura"
        ></b-img>
      </div>
    </b-col>
    <b-overlay :show="loading">
      <div class="col-10 mx-auto text-center">
        <h6 class="text-left pb-1">
          Termini e Condizioni e Informativa Privacy
        </h6>
        <p class="text-justify informativa__p">
          Per concludere la registrazione è necessario prendere visione ed
          accettare
          <a
            target="_blank"
            class="text-underlined point"
            :href="infoLinks.paziente"
          >
            <u>condizioni generali di contratto</u></a
          >
          e
          <a
            target="_blank"
            class="text-underlined point"
            href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
          >
            <u>Informativa Privacy</u></a
          >
        </p>
        <b-row cols="1" class="text-left">
          <b-form-checkbox
            class="text-font-light-grey"
            name="charge_consense"
            :value="true"
            :unchecked-value="false"
            v-model="form.charge_consense"
            :state="form.charge_consense == true"
          >
            <span class="text-dark"
              >Ho letto e accetto la
              <a
                target="_blank"
                class="text-underlined point"
                href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
              >
                <u>Informativa Privacy</u></a
              >
            </span>
          </b-form-checkbox>
          <b-form-checkbox
            class="text-font-light-grey"
            name="charge_consense"
            :value="false"
            :unchecked-value="false"
            v-model="form.charge_consense"
            :state="false"
          >
            Non accetto
          </b-form-checkbox>
        </b-row>
        <p class="text-justify informativa__p my-4"></p>
        <b-row cols="1" class="text-left">
          <b-form-checkbox
            class="text-font-light-grey"
            name="data_consense"
            :value="true"
            :unchecked-value="false"
            v-model="form.data_consense"
            :state="form.data_consense == true"
          >
            <span class="text-dark"
              >Ho letto e accetto
              <a
                target="_blank"
                class="text-underlined point"
                :href="infoLinks.paziente"
              >
                <u>Termini e Condizioni d'uso del servizio</u></a
              >
            </span>
          </b-form-checkbox>
          <b-form-checkbox
            class="text-font-light-grey"
            name="data_consense"
            :value="false"
            :unchecked-value="false"
            v-model="form.data_consense"
            :state="false"
          >
            Non accetto
          </b-form-checkbox>
        </b-row>
        <p class="text-justify informativa__p my-4">e</p>
        <b-row cols="1" class="text-left">
          <b-form-checkbox
            class="text-font-light-grey"
            name="share_consense"
            :value="true"
            :unchecked-value="false"
            v-model="form.share_consense"
            :state="form.share_consense == true"
          >
            <span class="text-dark"
              >Ai sensi dell’articolo 1341 e ss c.c. accetto espressamente le
              previsioni di cui agli articoli 2, 7, 8, 10, 12 dei presenti
              <a
                target="_blank"
                class="text-underlined point"
                :href="infoLinks.paziente"
              >
                <u>Termini e Condizioni d'uso</u></a
              >
            </span>
          </b-form-checkbox>
          <b-form-checkbox
            class="text-font-light-grey"
            name="share_consense"
            :value="false"
            :unchecked-value="false"
            v-model="form.share_consense"
            :state="false"
          >
            Non accetto
          </b-form-checkbox>
        </b-row>

        <h6 class="text-left pt-4 pb-1">
          Autorizzazione alle comunicazioni commerciali
        </h6>
        <b-row cols="1" class="text-left">
          <b-form-checkbox
            class="text-font-light-grey"
            name="marketing_consense"
            :value="true"
            :unchecked-value="false"
            v-model="form.marketing_consense"
            :state="form.marketing_consense == true"
          >
            <span class="text-dark"
              >Acconsento alla ricezione di comunicazioni commerciali per
              finalità promozionali come da
              <a
                target="_blank"
                class="text-underlined point"
                href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
              >
                <u>Informativa Privacy</u></a
              >
            </span>
          </b-form-checkbox>
          <b-form-checkbox
            class="text-font-light-grey"
            name="marketing_consense"
            :value="false"
            :unchecked-value="false"
            v-model="form.marketing_consense"
            :state="false"
          >
            Non accetto
          </b-form-checkbox>
        </b-row>
        <b-row class="my-4">
          <b-col>
            <b-button v-if="allOk" @click="sendConsenses">
              <span v-if="!loading">Conferma le mie scelte</span>
              <b-spinner v-else></b-spinner>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <!-- <div class="col-10 mx-auto text-center">
        <h6 class="pb-1">Termini e Condizioni e Informativa Privacy</h6>
        <p class="informativa__p">
          Per completare la registrazione è necessario prendere visione ed
          accettare le condizioni del
          <a
            target="_blank"
            class="text-underlined point"
            :href="infoLinks.paziente"
          >
            <u>documento di Termini e Condizioni d'uso del servizio</u></a
          >
          e
          <a
            target="_blank"
            class="text-underlined point"
            href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
          >
            <u>Informativa Privacy</u></a
          >
        </p>
        <b-row cols="1" class="">
          <b-form-checkbox
            class="text-font-light-grey"
            name="data_consense"
            :value="true"
            :unchecked-value="false"
            v-model="form.data_consense"
            :state="form.data_consense == true"
          >
            <span class="text-dark"
              >Ho letto e accetto
              <a
                target="_blank"
                class="text-underlined point"
                :href="infoLinks.paziente"
              >
                <u>Termini e Condizioni d'uso del servizio</u></a
              >
              e
              <a
                target="_blank"
                class="text-underlined point"
                href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
              >
                <u>Informativa Privacy</u></a
              >
            </span>
          </b-form-checkbox>
          <b-form-checkbox
            class="text-font-light-grey"
            name="data_consense"
            :value="false"
            :unchecked-value="false"
            v-model="form.data_consense"
            :state="false"
          >
            Non accetto
          </b-form-checkbox>
        </b-row>
        <p class="informativa__p my-4">e</p>
        <b-row cols="1" class="">
          <b-form-checkbox
            class="text-font-light-grey"
            name="share_consense"
            :value="true"
            :unchecked-value="false"
            v-model="form.share_consense"
            :state="form.share_consense == true"
          >
            <span class="text-dark"
              >Ai sensi dell’articolo 1341 e ss c.c. accetto espressamente le
              previsioni di cui agli articoli 2, 7, 8, 10, 12 dei presenti
              <a
                target="_blank"
                class="text-underlined point"
                :href="infoLinks.paziente"
              >
                <u>Termini e Condizioni d'uso</u></a
              >
            </span>
          </b-form-checkbox>
          <b-form-checkbox
            class="text-font-light-grey"
            name="share_consense"
            :value="false"
            :unchecked-value="false"
            v-model="form.share_consense"
            :state="false"
          >
            Non accetto
          </b-form-checkbox>
        </b-row>

        <h6 class="pt-4 pb-1">Autorizzazione alle comunicazioni commerciali</h6>
        <b-row cols="1" class="">
          <b-form-checkbox
            class="text-font-light-grey"
            name="marketing_consense"
            :value="true"
            :unchecked-value="false"
            v-model="form.marketing_consense"
            :state="form.marketing_consense == true"
          >
            <span class="text-dark"
              >Acconsento alla ricezione di comunicazioni commerciali per
              finalità promozionali come da
              <a
                target="_blank"
                class="text-underlined point"
                href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
              >
                <u>Informativa Privacy</u></a
              >
            </span>
          </b-form-checkbox>
          <b-form-checkbox
            class="text-font-light-grey"
            name="marketing_consense"
            :value="false"
            :unchecked-value="false"
            v-model="form.marketing_consense"
            :state="false"
          >
            Non accetto
          </b-form-checkbox>
        </b-row>
        <b-row class="my-4">
          <b-col>
            <b-button v-if="allOk" @click="sendConsenses">
              <span v-if="!loading">Conferma le mie scelte</span>
              <b-spinner v-else></b-spinner>
            </b-button>
          </b-col>
        </b-row>
      </div> -->
    </b-overlay>
  </div>
</template>
<script>
import { cittadinoService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "ConsenseComponent",
  computed: {
    allOk: function () {
      // if (this.user.type === 1) {
      //   return (
      //     this.form.data_consense &&
      //     this.form.charge_consense &&
      //     this.form.share_consense
      //   );
      // }
      return (
        this.form.data_consense &&
        this.form.share_consense &&
        this.form.charge_consense
      );
    },
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      form: {
        data_consense: false,
        share_consense: false,
        notification_consense: true,
        charge_consense: false,
        marketing_consense: true,
        // notification_consense: false,
      },
      loading: false,
      infoLinks: {
        paziente: "https://concura.online/wp-content/uploads/2024/07/TC.pdf",
        operatore: "https://concura.online/wp-content/uploads/2024/07/TC.pdf",
      },
    };
  },
  methods: {
    ...mapActions("utente", ["unMe", "me"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    sendConsenses() {
      this.loading = true;
      // if (this.user.type === 1) {
      //   this.form.charge_consense = undefined;
      // }
      const formData = {
        hashid: this.user.hashid,
        data: this.form,
      };
      const self = this;
      cittadinoService
        .updateUser(formData)
        .then(function (res) {
          // //console.log(res);
          self.handleUpdate();
          // localStorage.removeItem("user");
        })
        .catch(function (err) {
          //console.log("err", err);
          self.loading = false;
          self.errorAlert("Non è stato possibile memorizzare le preferenze");
          self.resetAll();
        });
    },
    handleUpdate() {
      this.me();
      var self = this;
      setTimeout(function () {
        self.loading = false;
        self.$emit("consense-ok");
      }, 7000);
    },
    resetAll() {
      this.form = {
        data_consense: false,
        charge_consense: false,
        share_consense: false,
        // notification_consense: false,
      };
    },
  },
  created() {
    if (this.user) {
      this.form.data_consense = this.user.data_consense == 1;
      this.form.share_consense = this.user.share_consense == 1;
      this.form.charge_consense = this.user.charge_consense == 1;
    }
  },
};
</script>
