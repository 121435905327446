<template>
  <b-container fluid>
    <b-form @submit.prevent="saveTherapy">
      <b-row cols="1" class="my-2">
        <h4 class="text-prim-grad-1 text-left pb-1">
          Patologia per cui è stata indicata la terapia
        </h4>
        <div class="text-left">
          <b-row cols="2" cols-md="4" class="mt-n2" align-v="stretch">
            <b-col class="mt-4">
              <div
                class="p-2 mr-3 general_border text-center point h-100"
                @click="hasPathology = 1"
                :class="
                  hasPathology === 1
                    ? 'bg-secondary text-white'
                    : 'bg-grey text-dark-grey'
                "
              >
                indica patologia
              </div>
            </b-col>
            <b-col class="mt-4">
              <div
                class="p-2 mr-3 general_border text-center point h-100"
                @click="hasPathology = 0"
                :class="
                  hasPathology === 0
                    ? 'bg-secondary text-white'
                    : 'bg-grey text-dark-grey'
                "
              >
                non indicare
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="hasPathology === 1"
            class="my-2 mt-4 col-12 px-0 align-items-center align-content-center flex-wrap my-2"
          >
            <PatologieComponent
              :subject="subject"
              v-on:selected-pathology="selectPathology($event)"
            />
          </b-row>
        </div>
      </b-row>
      <b-row cols="1" class="pt-2" :class="hasPathology ? '' : 'mt-3'">
        <h4 class="text-prim-grad-1 text-left pb-4">Farmaco indicato</h4>
        <div class="text-left mb-4">
          <b-row cols="1" class="puntofarmaCard general_border p-4 mx-0">
            <b-col class="px-0 px-md-2">
              <b-form-group
                label="Cerca farmaco"
                class="col-12 px-0 col-md-10 pl-0"
              >
                <b-form-input
                  v-capitalize-first
                  class=" "
                  type="search"
                  name="pharmasearch"
                  placeholder="Cerca per nome o aic.."
                  v-model="prodSearch.q"
                  v-debounce:500ms="searchProds"
                  :debounce-events="'keyup'"
                >
                </b-form-input>
                <b-icon
                  icon="search"
                  class="search-icon"
                  variant="secondary"
                  font-scale="1.2"
                ></b-icon>
              </b-form-group>
              <b-spinner
                v-show="isSearching"
                class="ml-4"
                variant="primary"
                label="Loading..."
              ></b-spinner>
            </b-col>

            <b-col v-if="products" class="px-0 px-md-2 pt-2">
              <b-form-group
                label="Seleziona farmaco*"
                class="col-12 col-md-10 pl-0"
              >
                <b-form-select
                  v-capitalize-first-select
                  v-model="selectedProduct"
                  class=""
                >
                  <b-form-select-option
                    v-for="(prod, ind) in products"
                    :key="ind"
                    :value="prod"
                    >{{ prod.Nome }}
                  </b-form-select-option>
                  <b-form-select-option :value="null" disabled
                    >---</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col v-if="showNotFound">
              <p class="text-danger">Prodotto non trovato</p>
            </b-col>
          </b-row>
        </div>
      </b-row>
      <b-row cols="1" class="mt-4" v-if="selectedProduct">
        <h4 class="text-prim-grad-1 text-left pb-1">
          Frequenza, modalità e dosaggio somministrazione
        </h4>
        <div class="text-left">
          <div class="mt-4 puntofarmaCard p-4">
            <h6
              v-if="selectedProduct.Nome"
              class="text-dark-grey d-flex align-items-center border general_border border-whitesmoke text-uppercase px-1 py-2 bg-whitesmoke"
              style="width: 100%"
            >
              <span class="pl-2">
                {{ selectedProduct.Nome }}
              </span>
            </h6>
            <b-row class="align-items-end px-1 my-2">
              <b-col class="col-6 col-md-2 align-items-end px-0 px-md-2">
                <b-form-group class="" label="Dosaggio*">
                  <b-form-input
                    class=" "
                    type="number"
                    step="0.25"
                    min="0.25"
                    v-model="form.posology_num"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="ml-1 col-11 col-md-7 px-0 px-md-2">
                <b-form-group label="" class="">
                  <b-form-select
                    v-capitalize-first-select
                    id="posology-type-select"
                    v-model="form.posology_type"
                    class=" "
                  >
                    <b-form-select-option
                      v-for="type in posologyTypes"
                      :key="type"
                      :value="type"
                      >{{ type }}
                    </b-form-select-option>
                    <b-form-select-option :value="null" disabled
                      >---</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-end px-1 my-2">
              <b-col class="ml-2 col-12 col-md-3 col-lg-2 pl-0">
                <b-form-group class="mt-2" label="Frequenza*">
                  <b-form-radio-group
                    v-model="form.posology_every_type"
                    name="posology-every-type-options"
                  >
                    <b-form-radio :value="0">al dì</b-form-radio>
                    <b-form-radio :value="2">al bisogno</b-form-radio>
                    <b-form-radio :value="1">ogni</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col
                v-if="form.posology_every_type === 1"
                class="ml-0 col-11 col-md-7 d-flex align-items-center"
              >
                <b-form-group class="my-auto ml-0">
                  <b-input-group class="align-items-center">
                    <b-form-input
                      class=" "
                      type="number"
                      step="1"
                      min="1"
                      max="36"
                      v-model="form.posology_every_num"
                    >
                    </b-form-input>
                    <!-- <span class="ml-3">Ore</span>
                  <b-input-group-append></b-input-group-append> -->
                  </b-input-group>
                </b-form-group>
                <b-form-group class="my-auto ml-4" label="">
                  <b-form-radio-group
                    v-model="form.posology_every_um"
                    name="posology-every-um-options"
                  >
                    <b-form-radio :value="1">ore</b-form-radio>
                    <b-form-radio :value="0">giorni</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="px-1 my-2">
              <b-col class="ml-2 col-11 col-md-10 px-0 pl-md-0">
                <b-form-group
                  label="Per via*"
                  class="col-12 col-md-5 col-lg-3 px-0"
                >
                  <b-form-select
                    v-capitalize-first-select
                    v-model="form.posology_via"
                    class="max-width-150px"
                  >
                    <b-form-select-option
                      v-for="via in posologyVias"
                      :key="via"
                      :value="via"
                      >{{ via }}
                    </b-form-select-option>
                    <b-form-select-option :value="null" disabled
                      >---</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col>
              <b-form-group label="Da assumere">
                <div class="d-flex flex-wrap">
                  <b-form-checkbox
                    v-model="form.mattina"
                    :disabled="
                      blockMoreThanOneAssunzioni &&
                      (form.meta_mattina ||
                        form.pranzo ||
                        form.pomeriggio ||
                        form.sera ||
                        form.notte)
                    "
                    class="mr-4"
                    >Mattina</b-form-checkbox
                  >

                  <b-form-checkbox
                    v-model="form.meta_mattina"
                    :disabled="
                      blockMoreThanOneAssunzioni &&
                      (form.mattina ||
                        form.pranzo ||
                        form.pomeriggio ||
                        form.sera ||
                        form.notte)
                    "
                    class="mr-4"
                    >Meta mattina</b-form-checkbox
                  >

                  <b-form-checkbox
                    v-model="form.pranzo"
                    :disabled="
                      blockMoreThanOneAssunzioni &&
                      (form.mattina ||
                        form.meta_mattina ||
                        form.pomeriggio ||
                        form.sera ||
                        form.notte)
                    "
                    class="mr-4"
                    >Pranzo</b-form-checkbox
                  >

                  <b-form-checkbox
                    v-model="form.pomeriggio"
                    :disabled="
                      blockMoreThanOneAssunzioni &&
                      (form.mattina ||
                        form.meta_mattina ||
                        form.pranzo ||
                        form.sera ||
                        form.notte)
                    "
                    class="mr-4"
                    >Pomeriggio</b-form-checkbox
                  >

                  <b-form-checkbox
                    v-model="form.sera"
                    :disabled="
                      blockMoreThanOneAssunzioni &&
                      (form.mattina ||
                        form.meta_mattina ||
                        form.pranzo ||
                        form.pomeriggio ||
                        form.notte)
                    "
                    class="mr-4"
                    >Sera</b-form-checkbox
                  >

                  <b-form-checkbox
                    v-model="form.notte"
                    :disabled="
                      blockMoreThanOneAssunzioni &&
                      (form.mattina ||
                        form.meta_mattina ||
                        form.pranzo ||
                        form.pomeriggio ||
                        form.sera)
                    "
                    class="mr-4"
                    >Notte</b-form-checkbox
                  >
                </div>
              </b-form-group>

              <p
                class="text-danger fs-5"
                v-if="
                  blockMoreThanOneAssunzioni &&
                  (form.mattina ||
                    form.meta_mattina ||
                    form.pranzo ||
                    form.pomeriggio ||
                    form.sera ||
                    form.notte)
                "
              >
                Con questa frequenza è possibile selezionare al massimo un
                momento di assunzione
              </p>
            </b-col>
          </div>
        </div>
      </b-row>

      <b-row cols="1" class="mt-4 pt-4">
        <h4 class="text-prim-grad-1 text-left pb-4 pt-1 mt-1">
          Durata della terapia
        </h4>
        <div class="text-left puntofarmaCard p-4 mt-1">
          <b-row
            cols="1"
            cols-md="4"
            class="mt-n2 justify-content-between"
            align-v="stretch"
          >
            <b-col class="mt-4 d-flex align-items-center text-center">
              <div
                class="p-2 mr-3 general_border text-center point h-100"
                @click="form.type = 0"
                :class="
                  form.type === 0
                    ? 'bg-secondary text-white'
                    : 'bg-grey text-dark-grey'
                "
              >
                indeterminata
              </div>
            </b-col>
            <b-col class="mt-4 d-flex align-items-center">
              <div
                class="p-2 mr-3 general_border text-center point h-100"
                @click="form.type = 1"
                :class="
                  form.type === 1
                    ? 'bg-secondary text-white'
                    : 'bg-grey text-dark-grey'
                "
              >
                periodica/ciclica
              </div>
            </b-col>
            <b-col class="mt-4 d-flex align-items-center">
              <div
                class="p-2 mr-3 general_border text-center point h-100"
                @click="form.type = 2"
                :class="
                  form.type === 2
                    ? 'bg-secondary text-white'
                    : 'bg-grey text-dark-grey'
                "
              >
                occasionale
              </div>
            </b-col>
          </b-row>

          <b-row
            v-if="form.type === 1"
            cols="2"
            class="mt-4 col-12 px-0 align-items-center"
          >
            <b-col class="col-md-6 col-12 px-0 px-md-2">
              <b-row cols="2" class="align-items-end">
                <b-col class="col-md-5 col-12 px-0 px-md-2">
                  <b-form-group label="Per " class="">
                    <b-form-input
                      class=" "
                      type="number"
                      step="1"
                      min="1"
                      max="60"
                      v-model="form.for_num"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="ml-2 col-md-6 col-12 px-0 px-md-2">
                  <b-form-radio-group
                    v-model="form.for_um"
                    name="for-ums-options"
                    class="my-3"
                  >
                    <b-form-radio :value="0">mesi</b-form-radio>
                    <b-form-radio :value="1">settimane</b-form-radio>
                    <b-form-radio :value="2">giorni</b-form-radio>
                  </b-form-radio-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="col-md-6 col-12 px-0 px-md-2">
              <b-row cols="2" class="align-items-end">
                <b-col class="col-md-5 col-12 px-0 px-md-2">
                  <b-form-group label="Ogni " class="">
                    <b-form-input
                      class=" "
                      type="number"
                      step="1"
                      min="1"
                      max="60"
                      v-model="form.every_num"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="ml-2 col-md-6 col-12 px-0 px-md-2">
                  <b-form-radio-group
                    v-model="form.every_um"
                    name="every-ums-options"
                    class="my-3"
                  >
                    <b-form-radio :value="0">mesi</b-form-radio>
                    <b-form-radio :value="1">settimane</b-form-radio>
                    <b-form-radio :value="2">giorni</b-form-radio>
                  </b-form-radio-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row
            cols="1"
            cols-md="2"
            class="text-left mt-3"
            v-if="form.type >= 1"
          >
            <b-col class="px-0 px-md-2">
              <b-form-group
                label="A partire dal*"
                label-for="start_at"
                class="text-left mx-auto"
              >
                <b-form-input
                  class="my-3"
                  name="start_at"
                  type="date"
                  v-model="form.start_at"
                  required
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col class="px-0 px-md-2" v-if="form.type > 1">
              <b-form-group
                label="Fino al*"
                label-for="end_at"
                class="text-left mx-auto"
              >
                <b-form-input
                  class="my-3"
                  name="end_at"
                  type="date"
                  v-model="form.end_at"
                  required
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-row>
      <template v-if="selectedProduct">
        <b-row cols="1" class="my-3 pt-2" v-if="!subject">
          <b-col class="px-0">
            <b-form-group
              label=""
              description="**se selezioni questa opzione soltanto tu potrai vedere questa terapia"
              label-for="private"
              class="text-left"
            >
              <b-form-checkbox
                id="private"
                v-model="form.private"
                name="private"
                :value="true"
                :unchecked-value="false"
              >
                visibile solo a me**
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row cols="1">
          <h4 class="text-prim-grad-1 text-left pb-4 pt-1 mt-5">Dettagli</h4>
          <b-col class="px-0">
            <b-row class="puntofarmaCard p-4" cols="2">
              <b-col>
                <b-form-group
                  label="Prescrittore terapia*"
                  label-for="prescrittore"
                  class="text-left mx-auto"
                >
                  <b-form-select
                    v-capitalize-first-select
                    class="my-3"
                    name="prescrittore"
                    type="date"
                    v-model="form.prescrittore"
                    required
                  >
                    <b-form-select-option
                      v-for="ikey in irstKeys.prescr"
                      :key="ikey.descrizione"
                      :value="ikey.descrizione"
                      >{{ ikey.descrizione }}</b-form-select-option
                    >
                    <b-form-select-option :value="null"
                      >indica chi ha prescritto la terapia</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col v-if="subject">
                <b-form-group
                  label="Fonte informativa*"
                  label-for="fonte"
                  class="text-left mx-auto"
                >
                  <b-form-select
                    v-capitalize-first-select
                    class="my-3"
                    name="fonte"
                    type="date"
                    v-model="form.fonte_informativa"
                    required
                  >
                    <b-form-select-option
                      v-for="ikey in irstKeys.fonte"
                      :key="ikey.descrizione"
                      :value="ikey.descrizione"
                      >{{ ikey.descrizione }}</b-form-select-option
                    >
                    <b-form-select-option :value="null"
                      >indica fonte informativa</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Effetti collaterali"
                  label-for="effetti_collaterali"
                  class="text-left mx-auto"
                >
                  <b-form-textarea
                    v-capitalize-first
                    class="my-3"
                    name="effetti_collaterali"
                    type="text"
                    placeholder="eventuali effetti collaterali attribuite al farmaco.."
                    v-model="form.effetti_collaterali"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Reazioni allergiche"
                  label-for="reazione_allergica"
                  class="text-left mx-auto"
                >
                  <b-form-textarea
                    v-capitalize-first
                    class="my-3"
                    name="reazione_allergica"
                    type="text"
                    placeholder="eventuali reazioni allergiche attribuite al farmaco.."
                    v-model="form.reazione_allergica"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Interazioni"
                  label-for="interazioni"
                  class="text-left mx-auto"
                >
                  <b-form-textarea
                    v-capitalize-first
                    class="my-3"
                    name="interazioni"
                    type="text"
                    placeholder="possibili interazioni con farmaci o alimenti individuate.."
                    v-model="form.interazioni"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row cols="1" class="mt-4 pt-3">
          <b-col class="px-0">
            <h4 class="text-left pb-3 mt-3">Annotazioni</h4>
            <b-form-group label-for="details" class="text-left text-dark-grey">
              <b-form-textarea
                v-capitalize-first
                class="my-3"
                name="details"
                type="text"
                v-model="form.details"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row cols="1" class="my-3">
          <b-col class="px-0 px-md-2">
            <b-button type="submit" variant="secondary">Salva terapia</b-button>
          </b-col>
        </b-row>
      </template>
    </b-form>
  </b-container>
</template>
<script>
import * as moment from "moment/moment"
import { utilityService, cittadinoService } from "@/_services"
import PatologieComponent from "@/components/profile/sanitario/PatologieComponent.vue"
import { mapState, mapActions } from "vuex"
export default {
  name: "NewTherapy",
  props: ["subject"],
  components: { PatologieComponent },
  data() {
    return {
      form: {
        type: 0,
        start_at: null,
        end_at: null,
        details: null,
        own: true,
        private: false,
        subjectable_id: null,
        subjectable_type: null,
        chronic: true,
        pathology_id: null,
        every_num: 1,
        every_um: 0,
        for_num: 1,
        for_um: 0,
        posology_num: 1,
        posology_type: "Compresse",
        posology_via: "Orale",
        posology_every_num: 1,
        posology_every_um: 0,
        posology_every_type: 0,
        product_name: null,
        product_aic: null,
        prescrittore: null,
        fonte_informativa: null,
        effetti_collaterali: null,
        reazione_allergica: null,
        interazioni: null,
        mattina: false,
        meta_mattina: false,
        pranzo: false,
        pomeriggio: false,
        sera: false,
        notte: false
      },
      hasPathology: 0,
      everyUms: {
        0: "mesi",
        1: "settimane",
        2: "giorni"
      },
      everyPosologyTypes: {
        0: "dì",
        1: "ore",
        2: "bisogno"
      },
      everyPosologyUms: {
        1: "ore",
        2: "giorni"
      },
      posologyTypes: [
        "Compresse",
        "Capsule",
        "Gocce",
        "Inalazioni",
        "Buste",
        "Supposte",
        "Scatole",
        "Cerotti",
        "Fiale",
        "Fleboclisi",
        "Ovuli",
        "Sacche",
        "Flaconi",
        "Confezioni",
        "Kilogrammi",
        "Kit",
        "Millilitri",
        "Milligrammi"
      ],
      posologyVias: [
        "Orale",
        "Transdermica",
        "Nasale",
        "Endovena",
        "Intramuscolo",
        "Oftalmica",
        "Rettale",
        "Uretale",
        "Vaginale",
        "Sottocute"
      ],
      pathologies: null,
      typesColors: ["ter-4", "ter-1", "ter-2"],
      prodSearch: {
        q: null
      },
      products: null,
      selectedProduct: null,
      isSearching: false,
      showNotFound: false,
      irstKeys: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    blockMoreThanOneAssunzioni() {
      return this.posologyIsAlDi || this.posologyIsOgniNgiorni
    },
    posologyIsAlDi() {
      return this.form.posology_every_type === 0
    },
    posologyIsOgniNgiorni() {
      return (
        this.form.posology_every_type === 1 && this.form.posology_every_um === 0
      )
    }
  },
  watch: {
    prodSearch: {
      handler(val) {
        if (val.q == null || val.q.length < 3) {
          this.products = null
          this.selectedProduct = null
        }
      },
      deep: true
    },
    selectedProduct: {
      handler(val) {
        if (val !== null) {
          this.form.product_name = val.Nome
          this.form.product_aic = val.Aic
          this.form.product_principio_attivo = val.PrincipioAttivo
          this.form.product_codice_principio_attivo = val.CodicePrincipioAttivo
        }
      },
      deep: true
    },
    "form.posology_every_type": function (newVal, oldVal) {
      this.checkMaxAssunzioni()
    },
    "form.posology_assunzioni": function (newVal, oldVal) {
      this.checkMaxAssunzioni()
    },
    "form.posology_every_um": function (newVal, oldVal) {
      this.checkMaxAssunzioni()
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    formatData(data) {
      return moment(data).format("DD MMM YYYY")
    },
    selectPathology(pathology) {
      this.form.pathology_id = pathology.hashid
      this.form.chronic = pathology.type === 0
      this.form.type = pathology.type !== 0 ? 2 : 0
    },
    searchProds() {
      if (this.prodSearch.q && this.prodSearch.q.length >= 3) {
        this.isSearching = true
        var self = this
        this.showNotFound = false
        utilityService
          .products(this.prodSearch)
          .then(function (response) {
            // console.log(response.data instanceof Array);
            self.products = response.data
            if (!(response.data instanceof Array)) {
              self.products = [response.data]
            }
            self.isSearching = false
          })
          .catch(function (error) {
            self.showNotFound = true
            self.isSearching = false
          })
      } else if (!this.selectedProduct) {
        this.products = null
      }
    },
    saveTherapy() {
      //flatten assunzioni

      var self = this
      cittadinoService
        .storeTherapy(this.form)
        .then(function (response) {
          self.$emit("save-therapy", response.data.data)
          self.successAlert("Nuova terapia salvata")
        })
        .catch(function (error) {
          console.log(error)
          self.errorAlert("Si è verificato un errore durante il salvataggio")
        })
    },
    getKeys() {
      var filters = {
        key: "prescr,fonte"
      }
      var self = this
      utilityService
        .getIrstKeys(filters)
        .then(function (res) {
          self.irstKeys = res.data.data
        })
        .catch(function (err) {
          {
            self.errorAlert("Si è verificato un errore.")
          }
        })
    },
    checkMaxAssunzioni() {
      if (this.blockMoreThanOneAssunzioni) {
        const assunzioni = [
          this.form.mattina,
          this.form.meta_mattina,
          this.form.pranzo,
          this.form.pomeriggio,
          this.form.sera,
          this.form.notte
        ]

        if (assunzioni.filter((a) => a).length > 1) {
          this.form.mattina = false
          this.form.meta_mattina = false
          this.form.pranzo = false
          this.form.pomeriggio = false
          this.form.sera = false
          this.form.notte = false
        }
      }
    }
  },
  created() {
    if (this.subject) {
      this.form.subjectable_id = this.subject.hashid
      this.form.subjectable_type = this.subject.type
      this.form.own = false
    }
    this.getKeys()
  },
  mounted() {
    var today = moment()
    this.form.start_at = today.format("YYYY-MM-DD")
    if (this.$route.query.pax != undefined) {
      this.hasPathology = 1
    }
  }
}
</script>

<style scoped>
#posology-type-select {
  max-width: 150px;
}
</style>
