<template>
  <div>
    <b-button @click="$bvModal.show('confirm-send')">Pagamento</b-button>
    <b-modal
      id="confirm-send"
      title="Conferma operazione"
      centered
      @ok="sendPaymentLink"
      ok-title="Sì, conferma e invia"
      ok-variant="popup-button"
      cancel-title="No, annulla"
      cancel-variant="popup-button"
      footer-border-variant="white"
    >
      <div class="px-2">
        <h5>Inoltra all'utente link per il pagamento via email</h5>
        <p class="p-4 text-justify">
          Cliccando su "Conferma" inoltrerai all'indirizzo email utilizzato
          dall'utente per la registrazione un collegamento al sistema di
          pagamento sicuro Nexi, affinché l'utente possa finalizzare in
          autonomia l'acquisto. <br />
          Il link inviato ha una validità di 4 ore a partire dalla sua
          generazione, ed una volta scaduto dovrà essere rigenerato.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { paymentService } from "@/_services";
import { mapActions, mapState } from "vuex";

export default {
  name: "InviaLinkOperatoreCR",
  props: {
    reshash: {
      type: String,
      required: true,
    },
    userhash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        payment_type: 0,
        total_items: 1,
        user_id: this.userhash,
        purchasable_type: "prenotazione",
        purchasable_id: this.reshash,
        pay_by_link: true,
      },
      fetching: false,
    };
  },
  methods: {
    sendPaymentLink() {
      this.form.pay_by_link = true;
      this.fetching = true;
      const self = this;
      paymentService
        .storeUserPurchaseAndGetPaymentConfig(this.form)
        .then(function (response) {
          self.fetching = false;
          self.$bvModal.hide("confirm-send");
          self.successAlert(
            "Un link per il pagamento è stato inviato al tuo indirizzo email."
          );
          //   self.payment = { esito: "SENT", codiceEsito: 777 }
        })
        .catch(function (error) {
          console.log(error);
          self.fetching = false;
          self.$bvModal.hide("confirm-send");
          self.errorAlert(
            "Non è stato possibile inizializzare il pagamento. Contattare l'assistenza"
          );
        });
    },
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
  },
  computed: {},
  mounted() {},
};
</script>
