<template>
  <div>
    <b-row
      align-v="center"
      class="justify-content-end pr-4"
      v-if="currentSubscription"
    >
      <b-icon
        icon="info-circle"
        class="point"
        font-scale="2"
        @click="$bvModal.show('show-subscription-info')"
      ></b-icon>
      <span class="ml-2">Sottoscrizione attiva: </span>
      <strong class="ml-1">{{ currentSubscription.nome }}</strong>
    </b-row>

    <b-modal
      id="show-subscription-info"
      size="xl"
      title="Piani ConCura"
      scrollable
      centered
      hide-footer
    >
      <template v-if="currentSubscription">
        <div
          v-if="innerWidth >= 1600"
          class="d-flex align-items-center justify-content-center"
        >
          <img
            src="@/assets/Centro-Servizi-ConCura-4.svg"
            alt="Piani ConCura"
            style="width: 100%"
          />
        </div>
        <div v-else class="d-flex align-items-center justify-content-center">
          <img
            v-if="currentSubscription.slug === 'con-cura-pazienti-12'"
            src="@/assets/ConCura-Free.svg"
            alt="Con Cura Free"
          />
          <img
            v-if="currentSubscription.slug === 'con-cura-easy-24'"
            src="@/assets/ConCura-Easy.svg"
            alt="Con Cura Easy"
          />
          <img
            v-if="currentSubscription.slug === 'con-cura-smart-24'"
            src="@/assets/ConCura-Smart.svg"
            alt="Con Cura Smart"
          />
          <img
            v-if="currentSubscription.slug === 'con-cura-monitor-24'"
            src="@/assets/ConCura-Monitoraggio.svg"
            alt="Con Cura Monitoraggio"
          />
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cittadinoService } from "@/_services";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "HeaderRecapSottoscrizioniPaziente",
  data() {
    return {
      innerWidth: window.innerWidth,
      currentSubscription: null,
      pendingSubscription: null,
      availableSubscriptions: null,
      collapses: {},
      currentCollapse: false,
      allCollapsesAreClosed: true,
      convenzioni: [
        {
          name: "ConCura & UnoBravo",
          description: null,
          logo: require(`@/assets/unobravo_logo.svg`),
        },
        {
          name: "ConCura & Parentsmile",
          description: null,
          logo: require(`@/assets/parentsmile_logo.jpg`),
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    getUserSubscriptions() {
      const self = this;
      const filters = { service_ref: 8 };
      cittadinoService
        .getOwnSubscriptions(filters, this.$route.query.hashid)
        .then(function (res) {
          const subscriptions = res.data.data;
          self.currentSubscription = subscriptions.attivo
            ? subscriptions.attivo.abbonamento
            : self.getFreeSubscription();
        })
        .catch(function (err) {
          console.error(err);
          self.errorAlert("Non è stato possibile completare l'operazione.");
        });
    },
    getFreeSubscription() {
      const freeSubscription = {
        nome: "ConCura FREE",
        slug: "con-cura-pazienti-12",
        prezzo: 0,
        descrizione: "Sottoscrizione gratuita",
        dettaglio:
          '<p class="m-0">Prenotazione veloce per visite e prestazioni: 10€ </p><p class="m-0">Servizio di Televisita o Teleconsulto: 10€</p><p class="m-0">Prenotazione farmaci: 4€</p>',
      };
      return freeSubscription;
    },
  },
  created() {
    this.getUserSubscriptions();
    this.getFreeSubscription();
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .price_huge_font,
  .fs-5 {
    /* display: block; */
    text-align: right;
    margin-top: 1rem;
  }
}
</style>
