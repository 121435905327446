<template>
  <!-- <b-row style="min-height: 90vh"> -->
  <!-- <b-row cols="1" class="justify-content-center" :style="bgStyle">
    <b-col class="col-12 px-0 pr-lg-4 py-lg-4 mx-xl-4"> -->
  <b-row cols="1" class="justify-content-center align-items-center" :style="bgStyle">
    <b-col class="col-12 text-center">
      <b-col class="col-6 col-sm-4 col-md-3 col-lg-2 mx-auto">
        <b-img :src="require('@/assets/concura-logo.svg')" fluid-grow alt="logo concura"></b-img>
      </b-col>
    </b-col>
    <b-col class="mx-auto col-12 col-md-10 mt-2">
      <b-container>
        <b-overlay :show="status.loggingIn || $route.query.access_token !== undefined" spinner-variant="secondary"
          rounded="sm" class="puntofarmaCard col-sm-12 col-12 col-md-8 col-lgx-6 col-xl-6 mx-auto">
          <b-form @submit.prevent="onSubmit" class="py-5 px-4 whitesmoke">
            <b-row cols="1" cols-md="2" class="d-flex justify-content-center justify-content-md-between mb-5">
              <b-col class="d-none d-md-block">
                <b-button type="submit" class="col-12">Accedi</b-button>
              </b-col>
              <b-col>
                <b-button class="col-6 col-md-12" variant="outline-secondary"
                  @click="$router.push('/register')">Registrati</b-button>
              </b-col>
            </b-row>
            <p class="text-right text-secondary text-center">
              Se hai bisogno di assistenza, chiamaci al:
              <span class="font-weight-bolder point underlined" @click="callSupport">800770272</span>
            </p>
            <b-form-group class="col-12 my-3 pt-4 px-0 text-left">
              <b-form-input name="email" type="text" v-model="form.username" placeholder="email@mail.it"
                autocomplete="username" required></b-form-input>
            </b-form-group>
            <b-form-group class="col-12 my-3 px-0 text-left">
              <b-form-input name="password" :type="passwordClear ? 'text' : 'password'" v-model="form.password"
                placeholder="Password" autocomplete="current-password" required></b-form-input>
              <b-icon :icon="passwordClear ? 'eye' : 'eye-slash'" font-scale="1.3" class="eye_icon point"
                @click="passwordClear = !passwordClear"></b-icon>
            </b-form-group>
            <p class="text-right text-secondary mb-3 pt-2 point" @click="$router.push({ name: 'recupero password' })">
              Ho dimenticato la password
            </p>
            <p v-if="status.loggedIn == false" class="text-danger">
              <br />
              Credenziali errate.
            </p>
            <b-row class="d-flex justify-content-center pt-md-3">
              <b-col class="col-10 col-md-6">
                <b-button type="submit" variant="secondary w-100">Accedi</b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-overlay>
        <div>
          <b-modal id="modal-1" title="Recupera password" hide-footer centered>
            <p class="text-left">
              Inserisci l'indirizzo email utilizzato in fase di registrazione.
            </p>
            <b-form-input class="my-3" name="email" type="email" v-model="recoverEmail" placeholder="Inserisci email..."
              required></b-form-input>
            <div class="col-12 text-center">
              <p v-if="status.recoverSent === true">
                Mail inviata! Controlla la tua posta elettronica.
              </p>
              <p v-else-if="status.recoverSent === false" class="text-danger">
                Indirizzo non valido
              </p>
              <b-button :disabled="!canSubmit" @click="submitRecover" type="button"
                variant="secondary mt-2 mx-auto col-lg-4 col-sm-7">Invia</b-button>
            </div>
            <br />
          </b-modal>
        </div>
      </b-container>
    </b-col>
  </b-row>
  <!-- </b-col>
  </b-row> -->
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "LoginPage",
  data() {
    return {
      form: {
        // email: null,
        username: null,
        password: null,
      },
      recoverEmail: null,
      canSubmit: false,
      bgStyle: {
        backgroundImage: `url(${require("@/assets/concura_bg_logo.svg")})`,
        backgroundPosition: "3vw bottom",
        backgroundSize: "80vh",
        backgroundRepeat: "no-repeat",
        minHeight: "90vh",
      },
      passwordClear: false,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions("utente", ["login", "logout", "recoverPassword", "me"]),
    onSubmit() {
      // console.log(this.status);
      if (this.form) {
        this.login(this.form)
          .then((res) => {
            // console.log("after login ok", res);
          })
          .catch((error) => {
            console.log("after login error", error);
          });
      }
    },
    submitRecover() {
      this.recoverPassword({ email: this.recoverEmail })
        .then((res) => {
          this.$root.$emit("bv::hide::modal", "modal-1");
        })
        .catch((error) => {
          // this.$root.$emit("bv::hide::modal", "modal-1");
        });
    }, 
    callSupport() {
      window.location.href = 'tel:800770272';
    }
  },
  watch: {
    recoverEmail(val) {
      this.canSubmit = val !== null && val.indexOf("@") > 1;
    },
  },
  mounted() {
    if (this.$route.query.access_token) {
      var self = this;
      this.me(this.$route.query.access_token);
      // .then(function () {
      //   self.$router.push({ name: "Home" });
      // });
    }
  },
};
</script>
<style>
header.modal-header {
  border-color: transparent;
}
</style>
<style lang="scss" scoped>
// ::placeholder {
//   color: #e0e0e0 !important;
// }

input {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.eye_icon {
  position: absolute;
  right: 10px;
  top: 1rem;
  color: #9c9c9c;
}
</style>
