<template>
  <p v-if="notFound">Non è stato possibile recuperare informazioni.</p>
  <p v-else-if="blockView">
    Il profilo dell'utente non è ancora stato verificato o completato.
  </p>
  <b-container fluid v-else-if="paziente">
    <b-form @submit.prevent="onSubmit">
      <div class="text-left">
        <div>
          <h4 class="text-left text-uppercase pb-2 mb-2">
            Relazione Dimissioni
          </h4>
          <b-row>
            <b-col>
              <h5 class="text-uppercase">Paziente</h5>
              <p>
                <span class="text-capitalize">
                  {{ paziente.anagrafica.title }}
                  {{ paziente.anagrafica.name }}
                  {{ paziente.anagrafica.surname }}
                </span>

                <br />
                <span class="text-uppercase">{{ paziente.anagrafica.cf }}</span>
                <br />
                <span class="text-capitalize"
                  >{{ paziente.anagrafica.address }} -
                  {{ paziente.anagrafica.comune }}</span
                >
                <br />
                <span>Tel. {{ paziente.anagrafica.tel_number }}</span>
              </p>
            </b-col>
            <b-col></b-col>
            <b-col>
              <h5 class="text-uppercase">Medico curante</h5>
              <p>
                <span class="text-capitalize">{{
                  paziente.anagrafica.medico_base
                }}</span>
                <br />
                <span>Tel. </span> <br />
                <span>Email </span>
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h5 class="text-uppercase">Piano di cura</h5>
              <p>
                <span v-if="userPlan" class="text-capitalize">{{
                  userPlan.name
                }}</span
                ><span v-else>-</span>
              </p>
            </b-col>
            <b-col>
              <h5 class="text-uppercase">Diagnosi principale</h5>
              <p>-</p>
            </b-col>
            <b-col>
              <h5 class="text-uppercase">Diagnosi secondarie</h5>
              <p>-</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="11" xl="10" class="mb-4">
              <h5 class="text-uppercase">Presentazione clinica*</h5>
              <b-textarea
                required
                v-model="form.presentazione_clinica"
                rows="5"
              ></b-textarea>
            </b-col>
            <b-col cols="12" lg="11" xl="10" class="mb-4">
              <h5 class="text-uppercase">
                Sintesi del decorso*
                <span class="text-lowercase"
                  >(andamento delle condizioni cliniche durante il monitoraggio
                  domiciliare, eventi significativi)</span
                >
              </h5>
              <b-textarea
                required
                v-model="form.sintesi_decorso"
                rows="5"
              ></b-textarea>
            </b-col>
            <b-col cols="12" lg="11" xl="10" class="mb-4">
              <h5 class="text-uppercase">
                Condizioni cliniche alla dimissione dal servizio*
              </h5>
              <b-textarea
                required
                v-model="form.condizioni_cliniche_dimissione"
                rows="5"
              ></b-textarea>
            </b-col>
            <b-col cols="12" lg="11" xl="10" class="mb-4">
              <h5 class="text-uppercase">Programma alla dimissione*</h5>
              <b-textarea
                required
                v-model="form.programma_dimissione"
                rows="5"
              ></b-textarea>
            </b-col>
          </b-row>
          <b-col class="mb-2">
            <h5 class="text-uppercase">Terapie</h5>
            <b-row cols="1" cols-md="2" cols-lg="3">
              <b-col
                v-for="therapy in userTherapies"
                :key="therapy.hashid"
                class="my-1"
              >
                <h6 class="mb-0">
                  {{ therapy.product_name }}
                  <span
                    class="text-lowercase"
                    v-if="therapy.product_principio_attivo"
                    >({{ therapy.product_principio_attivo }})</span
                  >
                </h6>
                <p class="mb-0">
                  {{ posNum(therapy) }}
                  {{
                    posNum(therapy) > 1
                      ? therapy.posology_type
                      : typesMap[therapy.posology_type]
                  }}
                  {{ therapy.posologia.tipo }}
                  <span v-if="therapy.posologia.frequenza">
                    {{ therapy.posology_every_num }}
                    {{ therapy.posologia.frequenza }}
                  </span>
                  per via
                  <span class="text-lowercase"
                    >{{ therapy.posology_via }}
                  </span>
                </p>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" lg="11" xl="10" class="mb-4">
            <h5 class="text-uppercase">
              Altre raccomandazioni*
              <span class="text-lowercase">
                (controlli clinici successivi, esami di laboratorio, etc)</span
              >
            </h5>
            <b-textarea
              required
              v-model="form.note_referto"
              rows="5"
            ></b-textarea>
          </b-col>

          <b-col class="col-12 text-left"><p>*campi obbilgatori</p></b-col>

          <b-row class="mb-4 text-center justify-content-center">
            <b-col cols="12" lg="11" xl="10" class="mb-4">
              <b-button
                type="button"
                @click="$router.go(-1)"
                variant="secondary"
                class="mx-1"
              >
                Annulla
              </b-button>

              <b-button class="mx-1" type="submit">
                <span v-if="needsVerification"
                  >Autorizza ed emetti referto</span
                >
                <span v-else>Emetti referto</span>
              </b-button>
            </b-col>
            <b-col></b-col>
          </b-row>
        </div>
      </div>
    </b-form>

    <b-modal
      id="verificaReferto"
      title="Autorizza emissione referto"
      size="xl"
      centered
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <div>
        <OtpValidation
          :user="user"
          :cause="1"
          :validatable="user.hashid"
          @otp-validated="handleOtpValidation($event)"
          class="text-center"
        >
          <template v-slot:instruction>
            <h5 class="my-4">
              La struttura richiede che l'operazione venga confermata con la
              verifica di un codice OTP inviato al tuo numero di telefono.
              <br />
              <br />
              Clicca su "invia codice di verifica" per inviare il codice OTP.
            </h5>
          </template>
        </OtpValidation>
        <div class="text-center mt-3 pb-4">
          <h6>oppure</h6>
          <b-button
            class="mt-2"
            type="button"
            @click="$bvModal.hide('verificaReferto')"
            >Annulla operazione</b-button
          >
        </div>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  reportService,
  cittadinoService,
  monitoringService,
} from "@/_services";
import OtpValidation from "@/components/utilities/OtpValidation.vue";

export default {
  name: "HomeRefertoDimissioni",
  components: { OtpValidation },
  data() {
    return {
      paziente: null,
      notFound: false,
      blockView: false,
      userTherapies: [],
      form: {
        presentazione_clinica: null,
        referto_type: "dimission",
        condizioni_cliniche_dimissione: null,
        programma_dimissione: null,
        note_referto: null,
        sintesi_decorso: null,
        subjectable_id: null,
        subjectable_type: null,
      },
      typesMap: {
        Compresse: "compressa",
        Capsule: "capsula",
        Gocce: "goccia",
        Inalazioni: "inalazione",
        Buste: "busta",
        Supposte: "supposta",
        Scatole: "scatola",
        Cerotti: "cerotto",
        Fiale: "fiala",
        Fleboclisi: "fleboclisi",
        Ovuli: "ovulo",
        Sacche: "sacca",
        Flaconi: "flacone",
        Confezioni: "confezione",
        Kilogrammi: "kilogrammo",
        Kit: "kit",
        Millilitri: "millilitro",
        Milligrammi: "milligrammo",
        Applicazioni: "applicazione",
        UI: "UI",
      },
      userPlan: null,
      loading: false,
      showValidation: false,
    };
  },
  computed: {
    needsVerification() {
      if (this.company) {
        return this.company.clinical_report_verification === 1;
      }
      return false;
    },
    ...mapState("company", ["company"]),
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    onSubmit() {
      if (this.needsVerification) {
        this.$bvModal.show("verificaReferto");
      } else {
        this.storeClinicalReport();
      }
    },
    getUserPlans() {
      const self = this;
      monitoringService
        .getUserTreatmentPlans(this.$route.query.userhash)
        .then(function (res) {
          self.userPlan = res.data;
          self.loading = false;
        })
        .catch(function (err) {
          console.log(err);
          self.loading = false;
          self.errorAlert(
            "Non è stato possibile prelevare la lista dei piani del paziente."
          );
        });
    },
    fetchUserTherapies() {
      const self = this;
      const filters = {
        user: this.subject.hashid,
      };
      cittadinoService
        .getTherapies(filters)
        .then(function (response) {
          self.userTherapies = [
            ...response.data.data.active,
            ...response.data.data.ended,
          ];
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le terapie");
        });
    },
    fetchUser() {
      const self = this;
      const userhash = this.$route.query.userhash;
      this.form.subjectable_id = userhash;
      this.form.subjectable_type = this.$route.query.usertype;

      cittadinoService
        .getUserById(userhash)
        .then(function (res) {
          self.paziente = res.data.data;
          self.subject = {
            hashid: res.data.data.hashid,
            type: "user_in_app",
          };
          self.verifyCredentials(res.data.data);
          self.fetchUserTherapies();
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          self.notFound = true;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    storeClinicalReport() {
      const self = this;
      reportService
        .storeClinicalReport(this.form)
        .then(function (res) {
          self.successAlert("Report emesso correttamente");
          self.downloadResource(res.data.data.resource);
          self.$bvModal.hide("verificaReferto");
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Errore elaborazione report");
          // self.resetAndClose();
        });
    },
    downloadResource(resource) {
      const fName = resource.name;
      const self = this;
      cittadinoService
        .downloadResource(resource.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".pdf";
          link.click();
          self.$bvModal.hide("verificaReferto");
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Non è stato possibile scaricare la risorsa");
        });
    },
    posNum(therapy) {
      return therapy.posology_num * 1;
    },
    verifyCredentials(user) {
      // console.log(user);
      if (!user.number_verified_at) {
        return (this.blockView = true);
      }
      // if (!user.email && !user.second_number_verified_at) {
      //   this.doBackupNumber = true;
      //   return (this.blockView = true);
      // }
      this.blockView = false;
    },
    handleOtpValidation(verified) {
      if (verified) {
        this.storeClinicalReport();
      } else {
        this.showValidation = false;
      }
    },
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
  },
  created() {
    const userhash = this.$route.query.userhash;
    if (userhash) {
      this.fetchUser(userhash);
      this.getUserPlans();
    } else {
      this.notFound = true;
    }
  },
};
</script>

<style scoped></style>
