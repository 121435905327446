<template>
  <div class="verification pt-4 mt-4">
    <b-col class="col-12 align-content-center py-2 py-md-3 py-xl-4">
      <div class="mx-auto col-10 col-md-4 col-xl-3 py-2">
        <b-img
          :src="require('@/assets/concura-logo.svg')"
          fluid-grow
          alt="logo concura"
        ></b-img>
      </div>
    </b-col>
    <div v-if="$route.params.status === 'success'">
      <h5>Registazione avvenuta con successo!</h5>
      <div class="col-10 mx-auto">
        <h1 class="mt-3 text-center pb-1">Benvenuto</h1>
        <h6 class="text-center my-4 py-3 pl-1">
          Grazie per aver confermato il tuo indirizzo email. <br />
        </h6>
        <b-button
          type="button"
          variant="secondary text-white  mx-2 mt-4 "
          @click="logAndHome"
          >Vai alla Homepage
        </b-button>
      </div>
    </div>
    <div v-else-if="$route.params.status === 'already'">
      <h5>Email già verificata.</h5>
      <div class="col-10 mx-auto">
        <h1 class="mt-3 text-center pb-1">Benvenuto</h1>
        <h6 class="text-center my-4 py-3 pl-1">
          Hai già confermato il tuo indirizzo email. <br />
          Se non lo hai ancora fatto, procedi con la compilazione della tua
          scheda anagrafica.
        </h6>
        <b-button
          type="button"
          variant="secondary text-white  mx-2 mt-4 "
          @click="$router.push({ name: 'Profilo' })"
          >Scheda anagrafica
        </b-button>
      </div>
    </div>
    <div v-else>
      <h5></h5>
      <div class="col-10 mx-auto">
        <h1 class="mt-3 text-center pb-1">Conferma la tua email</h1>
        <h6 class="text-center my-4 py-3 pl-1">
          <span class="text-danger" v-if="$route.query.mailtoken"
            >Il link utilizzato non è più valido. <br
          /></span>
          Se non hai ricevuto la mail di conferma controlla sotto la cartella
          spam della tua casella di posta elettronica oppure
          <span class="point" @click="submitResend"
            >richiedi una nuova mail di conferma</span
          >
        </h6>
        <b-button
          @click="submitResend"
          type="button"
          variant="secondary text-white  mx-2 mt-4 "
          >Invia nuova mail
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import { mapState, mapActions } from "vuex";
export default {
  name: "EmailVerification",
  components: {
    // HelloWorld,
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions("utente", ["resend", "me"]),
    submitResend() {
      this.resend().then(function (hh) {
        console.log("thend");
      });
    },
    logAndHome() {
      if (this.$route.query.access_token) {
        this.me(this.$route.query.access_token);
      } else {
        this.$router.push({ name: "Home" });
      }
    },
  },
  mounted() {},
};
</script>
