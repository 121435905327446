<template>
  <div
    class="col-12 mt-1 mx-0"
    v-if="user.permessi.includes('visualizzare anagrafica azienda')"
  >
    <div
      class="d-flex justify-content-between align-items-center pb-1 mb-4 page-title"
    >
      <h4 class="mb-0">Libreria</h4>

      <b-button
        variant="secondary border-white"
        class="ml-auto"
        @click="showNewModal()"
      >
        <b-icon icon="plus"></b-icon> nuova libreria
      </b-button>
    </div>
    <p class="text-justify">
      Personalizza la libreria aggiungendo i collegamenti alle banche dati ed ai
      siti più utilizzati dalla struttura; le voci qui impostati saranno
      disponibili per tutti gli operatori della struttura.
    </p>

    <div class="mt-3 shadow_2 bg-white general_border p-4">
      <b-table
        borderless
        class="text-center"
        hover
        responsive
        table-variant="white "
        :fields="fields"
        :items="voci"
        :small="$root.isMobile"
      >
        <template #cell(open_iframe)="row">
          <span v-if="row.item.open_iframe">Sì</span>
          <span v-else>No</span>
        </template>
        <template #cell(azioni)="row">
          <b-row class="mt-n2">
            <b-iconstack
              v-b-popover.hover.top="'Modifica libreria'"
              font-scale="2.2"
              class="point m-1"
              @click="editlibreria(row.item)"
            >
              <b-icon
                stacked
                icon="circle-fill"
                variant="mdm-light-grey"
              ></b-icon>
              <b-icon
                stacked
                icon="pencil-fill"
                scale="0.5"
                variant="secondary"
              ></b-icon>
            </b-iconstack>
            <b-iconstack
              v-b-popover.hover.top="'Imposta Preferito'"
              font-scale="2.2"
              class="point m-1"
              @click="togglePreferito(row.item)"
            >
              <b-icon
                stacked
                icon="circle-fill"
                variant="mdm-light-grey"
              ></b-icon>
              <b-icon
                stacked
                :icon="row.item.preferito ? 'heart-fill' : 'heart'"
                scale="0.5"
                variant="secondary"
              ></b-icon>
            </b-iconstack>
            <b-iconstack
              v-b-popover.hover.top="'Elimina libreria'"
              font-scale="2.2"
              class="point m-1"
              @click="
                ;(selectedItem = row.item), $refs.deleteLibreriaModal.show()
              "
            >
              <b-icon
                stacked
                icon="circle-fill"
                variant="mdm-light-grey"
              ></b-icon>
              <b-icon
                stacked
                icon="trash-fill"
                scale="0.5"
                variant="secondary"
              ></b-icon>
            </b-iconstack>
          </b-row>
        </template>
      </b-table>

      <p v-if="voci && voci.length == 0">Nessuna libreria qui</p>
      <!-- <b-pagination
        v-model="filterSetted.page"
        :total-rows="total"
        :per-page="perPage"
        align="center"
        :size="$root.isMobile ? 'sm' : 'lg'"
      ></b-pagination> -->

      <b-modal
        ref="newLibreriaModal"
        title="Aggiungi nuova libreria"
        centered
        hide-footer
      >
        <b-form @submit.prevent="storeNew" class="px-3">
          <b-form-group
            label="Etichetta*"
            label-for="input-label"
            description="Il nome della libreria che comparirà nel menù. Massimo 15 caratteri"
            description-class="bg-primary"
          >
            <b-form-input
              id="input-label"
              v-model="form.label"
              maxlength="15"
              required
              placeholder="Inserisci etichetta"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            description="Seleziona se vuoi che la libreria venga visualizzata all'interno del portale. In caso contrario, verrà aperta una nuova scheda del browser. Nota: non tutti i siti supportano la visualizzazione all'interno del portale, ma è sempre possibile modificare la scelta successivamente."
            label-for="input-iFrame"
            class="mt-5"
          >
            <b-form-checkbox id="input-iFrame" v-model="form.open_iframe">
              Mostra all'interno del portale
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            label="Indirizzo Web*"
            label-for="input-url"
            description="Inserisci l'indirizzo web della pagina che vuoi inserire."
            class="mt-5"
          >
            <b-form-input
              id="input-url"
              v-model="form.url"
              type="url"
              pattern="https?://.*"
              required
              placeholder="https://esempio.com"
            ></b-form-input>
            <b-form-invalid-feedback>
              Inserisci un URL valido che inizia con http o https
            </b-form-invalid-feedback>
          </b-form-group>

          <p class="d-flex justify-content-end mb-0">
            <b-button
              class="text-right"
              type="submit"
              variant="popup-button mt-4"
              >Salva</b-button
            >
          </p>
        </b-form>
      </b-modal>
      <b-modal
        ref="editLibreriaModal"
        title="Modifica libreria"
        centered
        hide-footer
      >
        <b-form @submit.prevent="update" class="px-3" v-if="selectedItem">
          <b-form-group
            label="Etichetta*"
            label-for="input-label"
            description="Il nome della libreria che comparirà nel menù. Massimo 15 caratteri"
          >
            <b-form-input
              id="input-label"
              v-model="selectedItem.label"
              maxlength="15"
              required
              placeholder="Inserisci etichetta"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            description="Seleziona se vuoi che la libreria venga visualizzata all'interno del portale. In caso contrario, verrà aperta una nuova scheda del browser. Nota: non tutti i siti supportano la visualizzazione all'interno del portale, ma è sempre possibile modificare la scelta successivamente."
            label-for="input-iFrame"
            class="mt-5"
          >
            <b-form-checkbox
              id="input-iFrame"
              v-model="selectedItem.open_iframe"
            >
              Mostra all'interno del portale
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            label="Indirizzo Web*"
            label-for="input-url"
            description="Inserisci l'indirizzo web della pagina che vuoi inserire."
            class="mt-5"
          >
            <b-form-input
              id="input-url"
              v-model="selectedItem.url"
              type="url"
              pattern="https?://.*"
              required
              placeholder="https://esempio.com"
            ></b-form-input>
            <b-form-invalid-feedback>
              Inserisci un URL valido che inizia con http o https
            </b-form-invalid-feedback>
          </b-form-group>

          <p class="d-flex justify-content-end mb-0">
            <b-button
              class="text-right"
              type="submit"
              variant="popup-button mt-4"
              >Salva</b-button
            >
          </p>
        </b-form>
      </b-modal>
      <b-modal
        ref="deleteLibreriaModal"
        title="Elimina libreria"
        centered
        hide-footer
      >
        <b-form
          @submit.prevent="deleteLibreria"
          class="px-3"
          v-if="selectedItem"
        >
          <p class="text-center">
            Sei sicuro di voler eliminare la libreria
            <b>{{ selectedItem.label }}</b
            >?
          </p>
          <p class="d-flex justify-content-end mb-0">
            <b-button
              @click="$refs.deleteLibreriaModal.hide()"
              class="text-right"
              variant="popup-button mt-4 mr-3"
              >No, annulla</b-button
            >
            <b-button
              class="text-right"
              type="submit"
              variant="popup-button mt-4"
              >Sì, conferma</b-button
            >
          </p>
        </b-form>
      </b-modal>
    </div>
  </div>
  <div v-else>
    <p class="text-center">Non hai i permessi per visualizzare questa pagina</p>
  </div>
</template>
<script>
import { companyService } from "@/_services"
import { mapState, mapActions, mapMutations } from "vuex"
export default {
  name: "BancaDati",
  // components: { SurveyShower },
  data() {
    return {
      filterSetted: {
        // search: null,
        // page: 1,
        // take: 10
      },
      voci: null,
      fields: [
        { key: "label", label: "Etichetta" },
        { key: "url", label: "Indirizzo Web" },
        { key: "open_iframe", label: "Visualizza all'interno del portale" },
        { key: "azioni", label: "" }
      ],
      takeOptions: [10, 25, 50, 100],
      total: 0,
      fromPage: 0,
      toPage: 0,
      perPage: 0,
      selectedRow: null,
      modalTitle: null,
      selectedItem: null,
      form: {
        label: "",
        open_iframe: false,
        url: ""
      }
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["company"])
  },
  watch: {
    filterSetted: {
      handler(prev, post) {
        this.fetchData()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    ...mapMutations({
      updateCompanyBancaDati: "company/updateCompanyBancaDati"
    }),
    fetchData() {
      var self = this
      companyService
        .getCompanyBancaDati(this.filterSetted)
        .then(function (response) {
          self.voci = response.data.data
          self.updateCompanyBancaDati(response.data.data)
          // self.filterSetted.page = response.data.current_page
          // self.total = response.data.total
          // self.fromPage = response.data.from
          // self.toPage = response.data.to
          // self.perPage = response.data.per_page
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le voci")
        })
    },
    selectThis(row) {
      this.selectedRow = row
      this.modalTitle = row.item.name
      this.$refs["modal-showsurvey"].show()
    },
    showNewModal() {
      this.$refs.newLibreriaModal.show()
    },
    storeNew() {
      var self = this
      companyService
        .storeCompanyBancaDati(this.form)
        .then((response) => {
          self.$refs.newLibreriaModal.hide()
          self.successAlert("Libreria aggiunta con successo")
          self.fetchData()
        })
        .catch((error) => {
          self.errorAlert("Non è stato possibile aggiungere la libreria")
        })
    },
    editlibreria(libreria) {
      this.selectedItem = JSON.parse(JSON.stringify(libreria))
      this.$refs.editLibreriaModal.show()
    },
    resetForm() {
      this.form = {
        label: "",
        open_iframe: false,
        url: ""
      }
    },

    deleteLibreria() {
      var self = this
      companyService
        .deleteCompanyBancaDati(this.selectedItem.hashid)
        .then((response) => {
          self.$refs.deleteLibreriaModal.hide()
          self.successAlert("Libreria eliminata con successo")
          self.fetchData()
        })
        .catch((error) => {
          self.errorAlert("Non è stato possibile eliminare la libreria")
        })
    },
    update() {
      var self = this
      companyService
        .updateCompanyBancaDati(this.selectedItem)
        .then((response) => {
          self.$refs.editLibreriaModal.hide()
          self.successAlert("Libreria modificata con successo")
          self.fetchData()
        })
        .catch((error) => {
          self.errorAlert("Non è stato possibile modificare la libreria")
        })
    },
    togglePreferito(row) {
      this.selectedItem = JSON.parse(JSON.stringify(row))
      this.selectedItem.preferito = !this.selectedItem.preferito
      this.update()
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style>
.text-muted {
  text-align: justify !important;
}
</style>
