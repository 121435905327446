<template>
  <b-row cols="1" class="pls__btn" :class="menuOpen ? ' bg-white-faded' : ''">
    <transition-group name="slide" mode="out-in">
      <b-col v-if="menuOpen" key="neworder"
        class="bg-secondary general_border shadow_2 p-1  col-12 col-lg-10 col-xl-8 ml-auto mr-0 pr-0">

        <b-row key="maindivmen" class="flex-column justify-content-end bg-whitesmoke general_border py-4 px-2">
          <b-row class="justify-content-between px-2" align-v="center">
            <h4 class="text-left">Inserisci una prenotazione</h4>
            <!-- <b-button variant="outline-secondary" @click="$router.push({ name: 'Pazienti' })">

              vai alla lista
              pazienti
              <b-icon icon="arrow-up-right"></b-icon>
            </b-button> -->
          </b-row>
          <!-- <p class="text-left mx-2">Cerca l'utente per cui vuoi inserire la prenotazione. In alternativa,
            <span>
              <router-link class="text-secondary no__hover text-right col-7 px-0 col-md-6" :to="{
                name: 'Pazienti'
              }">
                vai alla lista
                pazienti
                <b-icon icon="arrow-up-right" variant="secondary"></b-icon>
              </router-link>
            </span>
          </p> -->
          <SearchUtente class="px-2" :type="'paziente'" custombtn="Prenota per il paziente"
            v-on:invite-user="goToScheda($event)" v-on:arruola-user="doRegister($event)" />
        </b-row>
      </b-col>
      <b-col key="openbtn" class="pr-0">
        <div class="d-flex justify-content-end mt-2">
          <span class="ml-2 point general_border" @click="menuOpen = !menuOpen">
            <b-iconstack font-scale="4">
              <b-icon stacked icon="circle" variant="secondary" class="border border-secondary"></b-icon>
              <b-icon stacked icon="circle-fill" variant="mdm-light-grey"></b-icon>
              <b-icon stacked :icon="menuOpen ? 'x' : 'plus'" scale="0.8" variant="secondary"></b-icon>
            </b-iconstack>
          </span>
        </div>
      </b-col>
    </transition-group>
  </b-row>
</template>
<script>
import SearchUtente from "@/components/utilities/SearchUtente.vue";
import SchedaArruolamento from "@/components/pazienti/SchedaArruolamento.vue";
export default {
  name: "CalendarAddButton",
  components: { SearchUtente, SchedaArruolamento },
  data() {
    return {
      menuOpen: false,
      // arruolamento:false,
      // precompiled: null,
    };
  },
  methods: {
    undoArruolamento() {
      this.arruolamento = false;
      this.precompiled = null;
    },
    goToScheda(hashid) {
      console.log(hashid);
      this.$router.push({
        name: "prenota servizio",
        params: { hash: hashid },
      });
    },
    doRegister(params) {
      console.log(params);
      // this.arruolamento = true;
      // this.precompiled = params;
      this.$router.push({
        name: "pazienti",
        params: params,
        query: { action: "arruola" }
      });
    },
  },
  created() { },
};
</script>
<style>
.bg-white-faded {
  background-color: rgba(255, 255, 255, 0.459);
  padding-top: 1rem;
}
</style>