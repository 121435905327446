<template>
  <div>

    <b-button @click="$bvModal.show('set-date-modal')" v-if="company.level === 5">Imposta data e struttura
      sanitaria</b-button>
    <b-button @click="$bvModal.show('set-date-modal')" v-else>Imposta data</b-button>
    <b-modal id="set-date-modal" size="lg" :title="titleDescription" centered
    @show="getCompaniesForRequestedServiceByType" 
    @ok="sendDataInizio" 
    ok-title="Imposta struttura e data e conferma"
    :ok-disabled="!data || !orario" 
    ok-variant="popup-button"
    cancel-title="Annulla" 
    cancel-variant="popup-button"
    footer-border-variant="white">
      <div class="px-4">
        <p class="text-justify" v-if="company.level === 5">
          Imposta struttura, data e ora della prenotazione.
        </p>
        <p class="text-justify" v-else>
          Imposta data e ora della prenotazione.
        </p>

        <b-form-group label="Data e ora dell'appuntamento">
          <b-row>
            <b-col cols="6">
              <b-form-datepicker v-model="data" :min="todayDate" placeholder="Scegli una data"
                required></b-form-datepicker>
            </b-col>
            <b-col cols="4">
              <b-form-input v-model="orario" placeholder="Scegli un orario" required format="HH:mm"
                type="time"></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group label="Seleziona una struttura sanitaria" v-if="company.level === 5">
          <b-row>
            <b-col cols="6">
              <b-form-input v-model="filterCompany"
                placeholder="Filtra tra le strutture disponibili"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="6">
              <b-form-select v-model="selectedCompany" :options="filteredCompanies"
                placeholder="Scegli una struttura"></b-form-select>
            </b-col>
          </b-row>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { reservationService } from "@/_services";
import moment from "moment";
import { mapActions, mapState } from "vuex"

export default {
  name: "SetDateOperatoreCr",
  props: {
    reshash: {
      type: String,
      required: true,
    },
    filters: {
      type: String,
      required: true,
    },
    tipoPrenotazione: {
      type: String,
      required: true,
    },
  },
  computed: {
    companyOptions() {
      return [
        { value: null, text: "Scegli una struttura", disabled: true },
        ...this.companies.map(company => ({
          value: company.hashid,
          text: company.ff_denominazione
        }))
      ];
    },
    filteredCompanies() {
      if (!this.filterCompany) {
        return this.companyOptions;
      }
      const search = this.filterCompany.toLowerCase();
      return this.companyOptions.filter(company => company.text.toLowerCase().includes(search));
    },
    titleDescription() {
      if (this.company.level===5){
        return "Data, ora e struttura della prenotazione"
      } else {
        return "Data e ora della prenotazione"
      }
    },
    ...mapState("company", ["company"]),
  },
  data() {
    const today = moment().format("YYYY-MM-DD");
    return {
      form: {
        data_inizio: null,
        hashid: this.reshash,
        company_id: null,
      },
      data: null,
      orario: null,
      selectedCompany: null,
      fetching: false,
      todayDate: today,
      companies: [],
      filterCompany:null,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    sendDataInizio() {
      const self = this;
      this.fetching = true;
      const orarioWithoutSeconds = this.orario.split(":").slice(0, 2).join(":");
      this.form.data_inizio =
        moment(this.data).format("YYYY-MM-DD") + " " + orarioWithoutSeconds;
      if (this.selectedCompany === null) {
        delete this.form.company_id;
      } else {
        this.form.company_id = this.selectedCompany;
      }
      {
        reservationService
          .updateReservation(this.form)
          .then(() => {
            self.fetching = false;
            self.$bvModal.hide("set-date-modal");
            self.successAlert(
              "Data, ora e struttura della prenotazione impostate con successo"
            );
            self.$emit("update-reservation", self.form.data_inizio);
          })
          .catch(() => {
            // console.log(self.form)
            self.errorAlert(
              "Errore durante l'impostazione della data, ora e struttura della prenotazione"
            );
            self.fetching = false;
          });
      }
    },

    getCompaniesForRequestedHealthService() {
      var self = this;
      const filters = {
        match: this.filters,
      };
      reservationService
        .getCompaniesForRequestedHealthService(filters)
        .then(function (res) {
          self.companies = res.data.data.map(el =>el.company);
        })
        .catch(function (error) {
          self.companies = [];
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile trovare aziende per il servizio richiesto"
          );
        });
    },

    getCompaniesForRequestedService(ref) {
      if (this.company.level===5){
      var self = this;
      reservationService
        .getCompaniesForRequestedService(ref)
        .then((res) => {
          self.companies = res.data.data.data;
        })
        .catch(() => {
          self.errorAlert(
            "Non è stato possibile trovare aziende per il servizio richiesto"
          ); 
        });
      }
    },

    getCompaniesForRequestedServiceByType() {
      if (this.tipoPrenotazione === 'prestazione') {
        this.getCompaniesForRequestedHealthService();
      } else if (this.tipoPrenotazione === 'prodotto') {
        this.getCompaniesForRequestedService(1);
      } else if (this.tipoPrenotazione === 'teleconsulto') {
        this.getCompaniesForRequestedService(2);
      }
    },
  },
  watch: {
    filters: {
      handler(newVal) {
        this.getCompaniesForRequestedHealthService();
        this.getCompaniesForRequestedService()
      },

    },
    selectedCompany(newVal) {
      this.form.company_id = newVal;
    }
  },
};
</script>
