<template>
  <div>
    <b-button variant="secondary" @click="$bvModal.show('new-voucher')">
      <b-icon icon="plus" class="mr-1"></b-icon>aggiungi
    </b-button>
    <b-modal
      id="new-voucher"
      title="Genera voucher"
      centered
      hide-footer
      size="lg"
      @close="resetForm"
    >
      <section class="p-2 text-left col-12 text-left">
        <p>
          Selezionare la sottoscrizione per la quale si vuole generare un
          voucher di sconto ed indicare la % di sconto da applicare. Se si
          desidera, è possibile specificare le date di validità del voucher.
        </p>
        <b-form @submit.prevent="submitForm" class="px-2">
          <b-form-group label="Sottoscrizione *">
            <b-form-select
              v-capitalize-first-select
              v-model="selectedSubscription"
              required
            >
              <b-form-select-option
                v-for="subscription in subscriptions"
                :value="subscription"
                :key="subscription.hashid"
                >{{ subscription.nome }}</b-form-select-option
              >
              <b-form-select-option :value="null" disabled
                >Scegli sottoscrizione</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <b-form-group
            class="mt-4"
            label="Riduzione da applicare sul prezzo iniziale €: *"
          >
            <b-form-input
              v-model="form.riduzione"
              placeholder="10,00"
              required
              type="number"
              :min="1"
              :max="selectedSubscription ? selectedSubscription.prezzo : 100"
              :step="0.01"
              :state="
                selectedSubscription
                  ? selectedSubscription.prezzo >= form.riduzione
                  : null
              "
            ></b-form-input>
          </b-form-group>
          <b-form-group class="mt-4" label="Data di inizio validità">
            <b-form-input
              v-model="form.valid_from"
              type="date"
              :min="minDate"
              :max="form.valid_to"
            ></b-form-input>
          </b-form-group>
          <b-form-group class="mt-4" label="Data di fine validità">
            <b-form-input
              v-model="form.valid_to"
              type="date"
              :min="form.valid_from || minDate"
            ></b-form-input>
          </b-form-group>
          <p class="text-muted mt-4">*parametri obbligatori</p>
          <div class="text-right mt-4 mb-0">
            <b-button
              type="button"
              variant="popup-button"
              class="mx-2"
              @click="$bvModal.hide('new-voucher')"
              >Annulla</b-button
            >
            <b-button type="submit" variant="popup-button"
              >Genera Voucher</b-button
            >
          </div>
        </b-form>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { adminService } from "@/_services";

export default {
  name: "VoucherStoreForm",
  data() {
    return {
      form: {
        riduzione: 1,
        valid_from: null,
        valid_to: null,
      },
      subscriptions: null,
      selectedSubscription: null,
      minDate: new Date().toISOString().split("T")[0],
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    submitForm() {
      const self = this;
      adminService
        .generaVoucher(this.form, this.selectedSubscription.hashid)
        .then(function (resp) {
          self.$bvModal.hide("new-voucher");
          self.successAlert("Voucher generato correttamente");
          self.resetForm();
          self.$emit("refresh");
        })
        .catch(function (err) {
          self.errorAlert("Non è stato possibile generare il voucher");
        });
    },
    resetForm() {
      this.form.riduzione = 1;
      this.form.valid_from = null;
      this.form.valid_to = null;
      this.selectedSubscription = null;
    },
    fetchData() {
      const self = this;
      adminService
        .getAvailableSubscriptions()
        .then(function (res) {
          self.subscriptions = res.data.data;
        })
        .catch(function (err) {
          self.errorAlert("Non è stato possibile recuperare le sottoscrizioni");
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
