<template>
  <div
    v-if="healthService && company"
    class="general_border_17 d-flex flex-column general_hover point shadow_2 fs-6 position-relative mr-3"
    :class="isSelected ? 'bg-secondary text-white' : 'bg-white text-secondary'"
    style="
      min-width: 280px;
      width: 280px;
      border-radius: 8px;
      min-height: 200px;
      height: 200px;
    "
    @click="selectThis"
  >
    <div
      :class="{
        'd-flex flex-column align-items-center text-center h-100': true,
        invisible: showInfo,
        visible: !showInfo
      }"
    >
      <div class="d-flex justify-content-center w-100 position-relative">
        <img
          v-if="company.profile_pic"
          :src="company.profile_pic"
          alt="Profile Picture"
          class="company-img"
        />
        <img
          v-else
          src="@/assets/no_avatar.svg"
          alt="No Avatar"
          class="company-img"
        />
      </div>
      <div class="text-center p-2 mt-3 flex-grow-1">
        <p class="mb-2 fs-1rem">
          {{ company.denominazione }}
        </p>

        <div>
          <p class="fw-bolder">
            {{ healthService.name }}
          </p>

          <p>
            {{ healthService.description }}
          </p>
          <p v-if="costoPrevistoInSede" class="mb-1 fw-bolder">
            {{ costoPrevistoInSede }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column p-2 align-items-center text-center position-absolute w-100 h-100"
      :class="{ visible: showInfo, invisible: !showInfo }"
      style="top: 0; left: 0"
    >
      <div class="d-flex justify-content-center align-items-center flex-grow-1">
        <h6 :class="isSelected ? 'text-white' : 'text-secondary'">
          <span class="font-weight-bold">Indirizzo:</span><br />
          {{ company.indirizzo }}, {{ company.frazione }}<br />
          <span class="text-capitalize">{{ company.comune }}</span>
        </h6>
      </div>
    </div>
    <div
      class="d-flex justify-content-end position-absolute w-100"
      style="top: 10px; right: 10px"
    >
      <span @click.stop="toggleInfo">
        <b-icon
          font-scale="1.5"
          icon="info-circle"
          :variant="isSelected ? 'white' : 'secondary'"
        ></b-icon>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  name: "CompanyReservationCard",
  props: [
    "healthService",
    "isSelected",
    "showHeart",
    "suggest",
    "pickedHSName",
    "company"
  ],
  computed: {
    ...mapState("utente", ["status", "user"]),
    priceInEuros() {
      return parseFloat(this.healthService.prezzo).toLocaleString("it-IT", {
        style: "currency",
        currency: "EUR"
      })
    },
    trimmedHSName() {
      if (this.pickedHSName) {
        return this.pickedHSName.length > 60
          ? this.pickedHSName.substring(0, 60) + "..."
          : this.pickedHSName
      }
      return null
    },
    costoPrevistoInSede: function () {
      if (this.healthService && this.healthService.estremi_fattura) {
        return parseFloat(
          this.healthService.estremi_fattura.imponibile +
            this.healthService.estremi_fattura.ivatot
        ).toLocaleString("it-IT", {
          style: "currency",
          currency: "EUR"
        })
      }
      return null
    }
  },
  data() {
    return {
      showInfo: false
    }
  },
  methods: {
    selectThis() {
      this.$emit("selected-new-health-service", this.healthService)
    },
    toggleInfo() {
      this.showInfo = !this.showInfo
    }
  }
}
</script>

<style lang="scss" scoped>
.company-img {
  min-height: 60px;
  min-width: 60px;
  max-width: 150px;
  max-height: 100px;
  border-radius: $border-radius;
  margin-top: 20px;
  object-fit: contain;
}

.general_border_17 {
  border-radius: 8px !important;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

</style>
