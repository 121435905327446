import { render, staticRenderFns } from "./PatientAnnotations.vue?vue&type=template&id=97d9144c&"
import script from "./PatientAnnotations.vue?vue&type=script&lang=js&"
export * from "./PatientAnnotations.vue?vue&type=script&lang=js&"
import style0 from "./PatientAnnotations.vue?vue&type=style&index=0&id=97d9144c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports