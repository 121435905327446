<template>
  <div class="text-secondary">
      Funzionalità in sviluppo
  </div>
</template>
<script>
export default {
  name: "AdminAlerts",
  props: [],
  data() {
    return {
    };
  },
  created() {
  },
};
</script>