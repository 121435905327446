<template>
  <div class="w-100 d-flex justify-content-center" v-if="reservation">
    <div
      class="bg-white general_border d-flex flex-column position-relative p-3 pb-0 mt-0 p-lgx-4 w-100"
    >
      <section id="detail-header" class="d-flex border-bottom border-primary">
        <div id="header-info" class="flex-grow-1 text-left">
          <div class="d-flex align-items-center">
            <b-icon
              font-scale="2"
              variant="secondary"
              :icon="icons[reservation.icona]"
              class="mr-3"
            ></b-icon>
            <div
              v-if="reservation.tipo_ordine !== 'teleconsulto'"
              id="header-info-first-row"
            >
              <template v-if="reservation.orderable.prestazione">
                <h5 class="my-0 fw-bolder text-secondary text-capitalize">
                  {{ reservation.orderable.prestazione.name }}
                </h5>
                <h6 class="mt-2 mb-0 text-secondary font-weight-normal">
                  {{ reservation.orderable.prestazione.description }}
                </h6>
              </template>
              <template v-else-if="reservation.tipo_ordine === 'prodotto'">
                <h5 class="my-0 text-secondary fw-bolder">
                  Richiesta prodotti
                </h5>
                <h6
                  class="mt-2 mb-0 text-secondary"
                  v-if="reservation.status == '1'"
                >
                  (da confermare)
                </h6>
              </template>
            </div>
            <div v-else id="header-info-first-row">
              <h5 class="my-0 text-secondary fw-bolder">Teleconsulto</h5>
              <h6
                class="mt-2 mb-0 text-secondary"
                v-if="reservation.status == '1'"
              >
                (da confermare)
              </h6>
            </div>
          </div>

          <div
            id="header-info-second-row"
            class="pt-1 pb-3 d-flex flex-wrap flex-start mt-n2 w-100"
          >
            <div class="header-info-info-container mt-2">
              <p
                class="font-weight-bolder m-0 mt-2 detail-modal-card-titles"
                v-if="reservation.tipo_ordine === 'prodotto'"
              >
                DATA E ORA (STIMATA)
              </p>
              <p
                class="font-weight-bolder m-0 mt-2 detail-modal-card-titles"
                v-else
              >
                DATA E ORA
              </p>
              <p
                class="m-0 detail-modal-card-titles"
                v-if="reservation.data_inizio"
              >
                {{ reservation.data_inizio | moment("DD.MM.YY HH:mm") }}
              </p>
              <p class="m-0 detail-modal-card-titles" v-else>Da definire</p>
            </div>
            <div class="header-info-info-container mt-2">
              <p class="font-weight-bolder m-0 mt-2">TIPO</p>
              <p class="m-0 detail-modal-card-titles">
                {{ reservation.tipo_ordine.toUpperCase() }}
              </p>
            </div>
            <div
              class="header-info-info-container mt-2 detail-modal-card-titles"
            >
              <p class="font-weight-bolder m-0 mt-2">CODICE RICHIESTA</p>
              <p class="m-0 detail-modal-card-titles">
                {{ reservation.codice_richiesta }}
              </p>
            </div>
            <div
              class="header-info-info-container mt-2 detail-modal-card-titles"
            >
              <p class="font-weight-bolder m-0 mt-2">NUMERO RICHIESTA</p>
              <p class="m-0 detail-modal-card-titles">
                {{ reservation.numero }}
              </p>
            </div>
            <div
              class="header-info-info-container mt-2 detail-modal-card-titles"
              v-if="company.level === 5"
            >
              <p class="font-weight-bolder m-0 mt-2">PRESA IN CARICO</p>
                <b-form-group>
                  <b-form-checkbox
                    v-model="handled"
                    switch
                    size="lg"
                    name="presa-in-carico"
                    class="mb-2"
                    @change="markAsHandled"
                  >
                  </b-form-checkbox>
                </b-form-group>
            </div>
            <div
              v-if="showCompany"
              class="header-info-info-container mt-2 detail-modal-card-titles"
            >
              <p class="font-weight-bolder m-0 mt-2">PRESSO</p>
              <p class="m-0 detail-modal-card-titles">
                {{ reservation.company.ff_denominazione }},
                {{ reservation.company.indirizzo }} -
                <span class="text-capitalize">
                  {{ reservation.company.comune }}
                </span>
              </p>
            </div>
            <div id="status-container" class="mt-2 detail-modal-card-titles">
              <p
                class="font-weight-bolder m-0 mt-2"
                v-if="!showAttesaPagamento"
              >
                STATO
              </p>
              <p class="m-0 detail-modal-card-titles d-flex">
                <span
                  v-if="showAttesaPagamento"
                  class="d-flex align-items-center text-capitalize detail-modal-card-titles text-danger font-weight-bolder"
                >
                  ATTESA PAGAMENTO
                </span>
                <span
                  v-else-if="reservation.status > 0 && !reservation.data_inizio"
                  class="d-flex align-items-center text-capitalize detail-modal-card-titles"
                >
                  ATTESA CONFERMA
                </span>
                <span
                  v-else
                  class="d-flex align-items-center text-capitalize detail-modal-card-titles"
                >
                  {{ reservation.stato.toUpperCase() }}</span
                >
                <span>
                  <b-icon
                    v-if="!reservation.data_inizio"
                    class="my-0 py-0 ml-2 detail-modal-card-titles"
                    font-scale="1.8"
                    icon="clock-history"
                    variant="warning"
                  ></b-icon>
                  <b-icon
                    v-else-if="
                      !(reservation.attesa_pagamento && reservation.status > 0)
                    "
                    class="my-0 py-0 ml-2 detail-modal-card-titles"
                    font-scale="1.8"
                    :icon="statusModalIcons[reservation.stato]"
                    :variant="statusModalVariants[reservation.stato]"
                  ></b-icon>
                </span>
              </p>
            </div>
          </div>
        </div>
        <b-icon
          icon="x"
          font-scale="1.5"
          class="point"
          variant="darkgrey"
          @click="$emit('close-modal')"
          style="position: absolute; top: 10px; right: 10"
        ></b-icon>
      </section>
      <section
        id="detail-body"
        class="pt-1 px-1 border-bottom pb-3 pb-lg-4 border-primary"
      >
        <b-row>
          <b-row
            v-if="!schedaPazienteView"
            class="mt-n2 px-0 col-12 col-lg-6"
            align-v="stretch"
          >
            <b-col class="mt-3 align-items-start">
              <div class="d-flex flex-column pr-2 requested-by-wrapper">
                <p class="text-secondary text-left px-1">
                  <span>Richiesto da</span>
                </p>
                <RequestedByCompanyInfoCard
                  v-if="reservation.subjectable.company"
                  :info="info"
                />
                <RequestedByPersonInfoCard :info="info" v-else />
              </div>
            </b-col>
            <b-col
              v-if="isPrenotataPerAltri"
              class="d-flex flex-column mt-3 align-items-start col-12 col-xl-6"
            >
              <div class="d-flex flex-column pr-2 requested-for-wrapper w-100">
                <p class="text-secondary text-left px-1">Per conto di</p>
                <PerContoDiInfoCard
                  :info="info"
                  @close-modal="$emit('close-modal')"
                />
              </div>
            </b-col>
          </b-row>
          <b-row v-else class="mt-n2 px-0 col-12 col-lg-6" align-v="stretch">
            <b-col class="mt-3 align-items-start">
              <div class="d-flex flex-column pr-2 requested-by-wrapper">
                <p class="text-secondary text-left px-1">
                  <span>Richiedente</span>
                </p>
                <RequestedByPersonInfoCard
                  @close-modal="$emit('close-modal')"
                  :info="{
                    requested_by: richiedente
                  }"
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2 col-12 col-lg-6">
            <template>
              <div class="d-flex flex-column pr-2 detail-by-wrapper w-100">
                <p class="text-secondary text-left px-1">
                  Dettagli
                  <span v-if="reservation.tipo_ordine === 'prodotto'">
                    dell'ordine</span
                  >
                </p>
                <!--<div v-if="reservation.tipo_ordine !== 'prodotto'" class="px-1">
                  <template v-if="!editingResOperator">
                    <p class="d-flex">
                      <span class="mr-2">Operatore Assegnato:</span>
                      <span :class="{ 'fw-bolder': reservation.operator }">
                        {{ reservationOperator }}
                      </span>
                      <template v-if="!showCompany">
                        <b-iconstack
                          v-if="
                            reservation.status > 0 && reservation.status !== 4
                          "
                          @click="editingResOperator = true"
                          font-scale="1.5"
                          class="point ml-2"
                        >
                          <b-icon
                            stacked
                            icon="circle-fill"
                            variant="mdm-light-grey"
                          ></b-icon>
                          <b-icon
                            stacked
                            icon="pencil-fill"
                            scale="0.5"
                            variant="secondary"
                          ></b-icon>
                        </b-iconstack>
                      </template>
                    </p>
                  </template>
                  <template v-else>
                    <div class="mb-2">
                      <PickCompanyOperator
                        small
                        asOperator
                        v-model="newOperatorHash"
                      />
                    </div>
                    <b-row class="mb-5 justify-content-center">
                      <b-button
                        size="sm"
                        @click="
                          editingResOperator = false;
                          newOperatorHash = reservation.operator.hashid;
                        "
                        variant="secondary"
                        class="mr-2"
                      >
                        Annulla
                      </b-button>
                      <b-button
                        size="sm"
                        @click="
                          $emit('update-res-operator', newOperatorHash);
                          editingResOperator = false;
                        "
                        variant="secondary"
                      >
                        Salva modifica operatore
                      </b-button>
                    </b-row>
                  </template>
                </div> -->
                
                <b-row>
                  <DetailPrestazione
                    :company_notes="reservation.company_notes"
                    :detail="reservation.orderable"
                    :hash="reservation.hashid"
                    :stato="reservation.status"
                    :quest="reservation.filled_survey"
                    :datainizio="reservation.data_inizio"
                    :nocancel="reservation.user_purchase != null"
                    :accettata="reservation.accettata"
                    :ricetta="reservation.ricetta"
                    v-if="reservation.tipo_ordine === 'prestazione'"
                    @update-request-status="emitHandleStatusUpdate($event)"
                  />
                  <DetailProdotto
                    :company_notes="reservation.company_notes"
                    :dettaglio="reservation.orderable"
                    :hash="reservation.hashid"
                    :stato="reservation.status"
                    :datainizio="reservation.data_inizio"
                    :nocancel="reservation.user_purchase != null"
                    :accettata="reservation.accettata"
                    v-else-if="reservation.tipo_ordine === 'prodotto'"
                    :ricetta="reservation.ricetta"
                    @update-request-status="emitHandleStatusUpdate($event)"
                    @update-request-response="emitSendOrderResponse($event)"
                  />
                  <DetailTeleconsulto
                    :company_notes="reservation.company_notes"
                    :dettaglio="reservation.orderable"
                    :stato="reservation.status"
                    :quest="reservation.filled_survey"
                    :datainizio="reservation.data_inizio"
                    :hash="reservation.hashid"
                    :nocancel="reservation.user_purchase != null"
                    :accettata="reservation.accettata"
                    :ricetta="reservation.ricetta"
                    v-else-if="reservation.tipo_ordine === 'teleconsulto'"
                    @update-request-status="emitHandleStatusUpdate($event)"
                    @update-request-response="emitSendOrderResponse($event)"
                  />
                </b-row>
              </div>
            </template>
          </b-row>
          <template>
            <b-row class="col-6">
                  <b-col>
                    <div class="detail-modal-card p-2 mb-2 mt-4 ml-n3 mr-n2" v-if="reservation.company">
                      <div class="m-0 ml-1 p-0 mt-2">
                        <p class="m-0 p-0 detail-modal-card-titles font-weight-bolder" >
                          Struttura sanitaria
                        </p>
                      <p class="mt-2 p-0 text-left">
                        {{ reservation.company.ff_denominazione }}
                      </p>
                    </div>
                    </div>
                  </b-col>
                </b-row>
            <b-row
              class="mt-3 col-12 long__txt__under__lg"
              v-if="reservation.status > 1"
            >
              <b-col class="px-0 col-6 order-lg-2 order-1">
                <div class="mr-4 p-3 detail-modal-card">
                  <p
                    class="font-size-085rem d-flex text-left justify-content-start align-items-center"
                  >
                    <span
                      class="font-weight-bolder detail-modal-card-titles d-flex justify-content-start text-left"
                      >NOTE OPERATORE</span
                    >
                    <UpdateNotesComponent
                      v-on:note-updated="handleNotesUpdate($event)"
                      class="d__innline ml-2"
                      :hashid="reservation.hashid"
                      :reserdate="reservation.data_inizio"
                    >
                    </UpdateNotesComponent>
                    
                  </p>
                  <p
                    v-if="reservation.company_notes"
                    class="m-0 p-0 text-dark-grey font-size-085rem"
                  >
                    {{ reservation.company_notes }}
                  </p>
                  <p v-else class="m-0 p-0 text-dark-grey font-size-085rem">
                    Nessuna nota
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-3 col-12 long__txt__under__lg">
              <b-col class="px-0 col-6 order-lg-2 order-1">
                <div v-if="reservation.user_notes" class="detail-modal-card p-2 mr-4">
                    <div class="m-0 ml-1 p-0 mt-4">
                        <p class="m-0 p-0 detail-modal-card-titles font-weight-bolder" >
                          NOTE DELL'UTENTE
                        </p>
                      <p class="mt-2 p-0 text-left">
                        {{ reservation.user_notes }}
                      </p>
                    </div>
                </div>
             </b-col>
            </b-row>
            
            <b-row class="mt-3 col-12 px-0">
              <b-col class="text-right px-0">
                <template v-if="company && company.level === 3">
                  <MarkAsHandledButton
                    v-if="
                      !reservation.handled && Math.abs(reservation.status) !== 4
                    "
                    :hashid="reservation.hashid"
                    @do-update="closeAndUpdate()"
                  ></MarkAsHandledButton>

                  <h6
                    v-else-if="
                      reservation.handled == true &&
                      Math.abs(reservation.status) !== 4
                    "
                    class="text-secondary mb-0"
                  >
                    PRENOTAZIONE GESTITA
                    <b-icon font-scale="1.3" icon="check-all"></b-icon>
                  </h6>
                </template>
              </b-col>
            </b-row>
          </template>
        </b-row>
        <!-- TO DO sistemare visualizzazione tasto evadi(manca nel calendario) -->
        <b-row class="mt-4" v-if="schedaPazienteView">
          <b-col class="m-0 col-12 text-md-right">
            <b-button class="m-1" variant="secondary" @click="emitDownloadPdf()"
              >Scarica Promemoria</b-button
            >
          </b-col>
        </b-row>
      </section>
      <section
        id="detail-footer"
        class="pt-2 d-flex justify-content-start flex-wrap w-100 mt-n2 px-0"
      >
        <div
          class="m-0 mb-2 p-0 d-flex mt-2"
          :class="{ 'mr-3': reservation.user_notes }"
        >
          <div class="m-0 ml-1 p-0 mt-2 d-flex flex-column">
            <p class="m-0 p-0 detail-modal-card-titles font-weight-bolder">
              RICHIESTA EFFETTUATA IN DATA<br />
            </p>
            <p class="m-0 p-0 text-capitalize text-left">
              {{ reservation.created_at || moment("DD.MM.YY HH:mm") }}
            </p>
          </div>
        </div>
      
        <div
          class="d-flex flex-wrap justify-self-end ml-auto mr-0 align-self-center mt-2"
        >
          <SetDateOperatoreCR
            @update-reservation="closeAndUpdate()"
            class="mr-2"
            :reshash="reservation.hashid"
            :filters="getFilters(reservation)"
            :tipoPrenotazione="reservation.tipo_ordine"
            v-if="!reservation.data_inizio"
          />
          <template
            v-if="reservation.attesa_pagamento && reservation.status > 0"
          >
            <InviaLinkOperatoreCR
              v-if="showCompany"
              :reshash="reservation.hashid"
              :userhash="reservation.subjectable.hashid"
            ></InviaLinkOperatoreCR>
          </template>
          <b-button
            v-if="
              user.company.level === 5 && reservation.tipo_ordine === 'prodotto'
            "
            @click="downloadPdf()"
            variant="outline-secondary"
          >
            Scarica PDF
          </b-button>
          <ChatComponent
            :stillActive="reservation.status > 0"
            :chatChannel="reservation.hashid"
            :recipient="{
              type: chatRecipientType,
              hashid: chatRecipientHash
            }"
            class="d-inline-block mx-2"
            :expirationDate="reservation.data_inizio"
            @read-messages="emitFetchData()"
          ></ChatComponent>
          <UpdateReservationDate
            class="d-inline-block"
            :hash="reservation.hashid"
            @change-requested="emitHandleUpdateRequest()"
          />
          <template
            v-if="reservation.subjectable && reservation.subjectable.email"
          >
            <b-button
              v-if="
                reservation.tipo_ordine === 'prestazione' &&
                reservation.service_ref !== 5
              "
              variant="outline-secondary"
              class="mx-2"
              @click="
                $router.push({
                  name: 'nuova notifica paziente',
                  params: { hash: reservation.subjectable.hashid },
                  query: {
                    atype: 'type2',
                    hserv: reservation.orderable.health_service.hashid
                  }
                })
              "
              >Pianifica recall {{ reservation.subject_type }}
              <!-- <b-icon icon="arrow-up-right" class="ml-1"></b-icon> -->
            </b-button>
          </template>
          <template>
            <b-button
              v-if="reservation.status !== 4 && reservation.status > 0"
              @click="$bvModal.show('modal-evasione-dettaglio-operatore')"
              variant="outline-secondary"
              class="mx-2"
            >
              Evadi
            </b-button>
          </template>
        </div>
      </section>
    </div>

    <b-modal
      size="lg"
      centered
      id="modal-evasione-dettaglio-operatore"
      title="Evadi Richiesta"
      cancel-title="Annulla"
      cancel-variant="popup-button"
      @cancel="$bvModal.hide('modal-evasione-dettaglio-operatore')"
      ok-title="Conferma"
      ok-variant="popup-button"
      @ok="evadiRichiesta"
    >
      <div>
        <h5>
          Vuoi evadere la richiesta
          <span v-if="reservation.data_inizio"
            >delle
            <span>ore {{ reservation.data_inizio | moment("HH:mm") }}</span>
            <span>
              del giorno
              {{ reservation.data_inizio | moment("DD.MM.YY") }}
            </span></span
          >
          ?
        </h5>
        <p class="text-left text-font-grey text-uppercase my-4">
          Richiesta n° #<span class="text-secondary">{{
            reservation.numero
          }}</span
          >, codice
          <span class="text-secondary">{{ reservation.codice_richiesta }}</span>
        </p>

        <b-form-input
          name="detail"
          class="mx-auto"
          v-model="reservation.hash"
          type="text"
          hidden
        >
        </b-form-input>
        <b-form-group
          label="Aggiungi Annotazione"
          label-for="detail"
          class="text-left text-secondary"
        >
          <b-form-input
            v-capitalize-first
            name="detail"
            class="mx-auto"
            v-model="reservation.details"
            type="text"
          >
          </b-form-input>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { reservationService, utilityService } from "@/_services"
import DetailPrestazione from "@/components/prenotazioni/dettaglio/DetailPrestazione.vue"
import DetailTeleconsulto from "@/components/prenotazioni/dettaglio/DetailTeleconsulto.vue"
import DetailProdotto from "@/components/prenotazioni/dettaglio/DetailProdotto.vue"
import UpdateNotesComponent from "@/components/prenotazioni/dettaglio/UpdateNotesComponent.vue"
import UpdateReservationDate from "@/components/prenotazioni/dettaglio/UpdateReservationDate.vue"
import ChatComponent from "@/components/prenotazioni/dettaglio/ChatComponent.vue"
import RequestedByPersonInfoCard from "@/components/prenotazioni/dettaglio/RequestedByPersonInfoCard.vue"
import PerContoDiInfoCard from "@/components/prenotazioni/dettaglio/PerContoDiInfoCard.vue"
import RequestedForAslInfoCard from "@/components/prenotazioni/dettaglio/RequestedForAslInfoCard.vue"
import RequestedByCompanyInfoCard from "@/components/prenotazioni/dettaglio/RequestedByCompanyInfoCard.vue"
import MarkAsHandledButton from "@/components/prenotazioni/dettaglio/MarkAsHandledButton.vue"
import PickCompanyOperator from "@/components/utilities/PickCompanyOperator.vue"
import InviaLinkOperatoreCR from "@/components/prenotazioni/dettaglio/InviaLinkOperatoreCR.vue"
import SetDateOperatoreCR from "@/components/prenotazioni/dettaglio/SetDateOperatoreCR.vue"

export default {
  name: "DettaglioPrenotazioneOperatore",
  props: {
    reservation: {
      type: Object,
      required: true
    },
    chatRecipientHash: {
      type: String,
      required: true
    },
    chatRecipientType: {
      type: String,
      required: true
    },
    schedaPazienteView: {
      type: Boolean,
      default: false
    },
    richiedente: {
      type: Object,
      required: false
    },
    showCompany: {
      //la prop controlla la visualizzazione del "presso" ma di fatto indica che chi osserva è un admin Control Room
      type: Boolean,
      default: false
    }
  },
  2: {
    reservation: {
      handler: function (val, oldVal) {
        this.newOperatorHash = val.operator ? val.operator.hashid : null
      },
      deep: true
    }
  },
  components: {
    DetailPrestazione,
    DetailTeleconsulto,
    DetailProdotto,
    UpdateReservationDate,
    UpdateNotesComponent,
    ChatComponent,
    RequestedByPersonInfoCard,
    RequestedByCompanyInfoCard,
    RequestedForAslInfoCard,
    PerContoDiInfoCard,
    MarkAsHandledButton,
    PickCompanyOperator,
    InviaLinkOperatoreCR,
    SetDateOperatoreCR
  },
  data() {
    return {
      statusModalIcons: {
        confermato: "check-circle",
        ricevuto: "archive",
        annullato: "x-circle",
        rifiutato: "x-circle",
        "in attesa di risposta": "question-circle",
        evaso: "calendar2-check"
      },
      statusModalVariants: {
        confermato: "secondary",
        ricevuto: "primary-darker",
        annullato: "danger",
        rifiutato: "danger",
        "in attesa di risposta": "warning",
        evaso: "primary-darker"
      },
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill"
      },
      newOperatorHash: "",
      editingResOperator: false,
      handled: this.reservation.handled === 1
    }
  },
  mounted() {
    this.newOperatorHash = this.reservation.operator
      ? this.reservation.operator.hashid
      : null
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    emitHandleStatusUpdate(statusData) {
      this.$emit("handle-status-update", statusData)
    },
    emitSendOrderResponse(responseData) {
      this.$emit("update-request-response", responseData)
    },
    emitFetchData() {
      this.$emit("fetch-data")
    },
    emitHandleUpdateRequest() {
      this.$emit("handle-update-request")
    },
    emitHandleNotesUpdate(notesData) {
      this.$emit("handle-notes-update", notesData)
    },
    emitDownloadPdf() {
      this.$emit("download-pdf")
    },
    emitOpenEvadi() {
      this.$emit("open-evadi")
    },
    handleNotesUpdate(data) {
      this.reservation.company_notes = data.company_notes
      this.$emit("handle-notes-update", data)
    },
    closeAndUpdate() {
      this.$emit("close-modal")
      this.$emit("fetch-data")
    },
    evadiRichiesta() {
      const formData = {
        hash: this.reservation.hashid,
        details: this.reservation.details
      }
      const self = this
      reservationService
        .closeOrder(formData)
        .then(function (response) {
          self.$bvModal.hide("modal-evasione-dettaglio-operatore")
          self.$emit("close-modal")
          self.successAlert("prenotazione evasa con successo")
          self.$emit("fetch-data")
        })
        .catch(function (error) {
          self.$emit("fetch-data")
          self.$bvModal.hide("modal-evasione-dettaglio-operatore")
          self.errorAlert("non è stato possibile evadere la prenotazione")
        })
    },
    downloadPdf() {
      const hash = this.reservation.hashid
      const fName = this.reservation.codice_richiesta
      const self = this
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".pdf"
          link.click()
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile scaricare il promemoria")
        })
    },
    markAsHandled() {
      const self = this
      reservationService
        .markReservationAsHandled(this.reservation.hashid)
        .then(function (response) {
          self.successAlert("Operazione eseguita correttamente")
        })
        .catch(function (error) {
          self.errorAlert("non è stato possibile completare l'operazione")
          // console.log("error fetch prenotazioni", error);
          // self.loading = false;
          self.handled = !self.handled;
        })
    },
    getFilters(reservation) {
      if (reservation.tipo_ordine === 'prestazione') {
        return reservation.orderable.prestazione.name;
      } else if (reservation.tipo_ordine === 'prodotto') {
        return "Ordine"
      } else {
        return "Teleconsulto"
      }
    },
  },
  computed: {
    info: function () {
      if (this.reservation) {
        return {
          subjectable: this.reservation.subjectable,
          requested_by: this.reservation.requested_by,
          subjectableIsUserInApp: this.subjectableIsUserInApp
        }
      }
      return {}
    },
    showAttesaPagamento: function () {
      return this.reservation.attesa_pagamento && this.reservation.status > 0
    },
    subjectableIsUserInApp: function () {
      if (this.reservation) {
        return this.reservation.subjectable_type === "user_in_app"
      } else return false
    },
    // Altri
    isPrenotataPerAltri: function () {
      if (this.reservation && !this.schedaPazienteView) {
        return (
          this.reservation.requested_by.anagrafica.cf !==
          this.reservation.subjectable.anagrafica.cf
        )
      }
      return false
    },
    requestedByColClasses: function () {
      if (this.isPrenotataPerAltri) {
        return "col-12 col-lg-7"
      }
      return "col-12"
    },
    reservationOperator() {
      if (this.reservation.operator) {
        const fullName =
          this.reservation.operator.anagrafica.name +
          " " +
          this.reservation.operator.anagrafica.surname
        return this.reservation.operator.anagrafica.title
          ? this.reservation.operator.anagrafica.title + fullName
          : fullName
      } else {
        return "Nessuna Preferenza"
      }
    },
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("company", ["company"]),
    ...mapState("utente", ["status", "user"])
  },
  watch: {
    handled(newVal) {
      this.reservation.handled = newVal ? 1 : 0;
    },
    'reservation.handled': {
      handler(newVal) {
        this.handled = newVal === 1;
      },
      immediate: true
    }
  },
}
</script>
<style scoped>
#res-icon {
  min-width: 50px;
}

.header-info-info-container {
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#detail-body {
  overflow: auto;
  max-height: 485px;
}

#status-container {
  display: flex;
  flex-direction: column;
}

#requested-by-col {
  max-width: 700px;
}

@media all and (min-width: 1300px) {
  #requested-by-col {
    max-width: 800px;
  }
}

@media screen and (min-width: 880px) {
  #status-container {
    margin-left: auto;
  }
}

#requested-for-col {
  max-width: 320px;
  margin-right: 20px;
}
</style>
