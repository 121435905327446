<template>
  <div>
    <b-button
      type="button"
      size="sm"
      variant="secondary m-1"
      @click="$bvModal.show('pick-set-patient-subscription')"
    >
      Richiedi attivazione sottoscrizione
    </b-button>
    <b-modal
      centered
      size="lg"
      hide-footer
      id="pick-set-patient-subscription"
      title="Sottoscrizione"
    >
      <b-form @submit.prevent="emitSubscription" v-if="user && subscriptions">
        <b-form-select
          v-capitalize-first-select
          v-model="selectedSubscription"
          :options="subscriptionsOptions"
          required
          class="mb-3 col-12 col-md-7 col-lg-5"
          placeholder="Seleziona una sottoscrizione"
        >
          <template #first>
            <option :value="null" disabled>Seleziona una sottoscrizione</option>
          </template>
        </b-form-select>

        <div v-if="selectedSubscription" class="p-2">
          <p class="font-weight-bolder">Sottoscrizione selezionata:</p>
          <p>
            {{ pickedSubscription.nome }} - {{ pickedSubscription.prezzo }}€ /
            {{
              pickedSubscription.mesi_frequenza_rinnovo === 1 ? "mese" : "anno"
            }}
          </p>
          <p v-html="pickedSubscription.dettaglio"></p>
          <p class="font-weight-bolder">
            Facendo click su "Conferma" verrà inserita la sottoscrizione per
            <span class="text-capitalize">
              {{ user.anagrafica.name }} {{ user.anagrafica.surname }}</span
            >
          </p>
        </div>

        <b-row class="d-flex justify-content-end">
          <b-button
            variant="popup-button"
            type="submit"
            class="mt-3"
            size="sm"
            :disabled="!selectedSubscription"
          >
            Conferma
          </b-button>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "SetPatientSubscriptionAdmin",
  watch: {
    selectedSubscription() {
      this.pickedSubscription = this.subscriptions.find(
        (subscription) => subscription.slug === this.selectedSubscription
      )
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    subscriptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedSubscription: null,
      pickedSubscription: null
    }
  },
  methods: {
    emitSubscription() {
      this.$emit("set-patient-subscription", this.selectedSubscription)
    }
  },
  computed: {
    subscriptionsOptions() {
      return this.subscriptions.map((subscription) => {
        return {
          value: subscription.slug,
          text: subscription.nome
        }
      })
    }
  },
  mounted() {}
}
</script>

<style scoped></style>
