<template>
  <div class="col-12 px-0 bg-extra-light general_border p-4">
    <div v-if="!hasResult">
      <transition-group name="slideside" mode="out-in">
        <b-form key="geo-search" v-if="!formSearch">
          <b-button
            variant="outline-secondary"
            @click="formSearch = true"
            class="mb-2"
            block
          >
            <b-icon icon="arrow-bar-left"></b-icon
            ><b> Torna alla ricerca per campi </b>
          </b-button>
          <p v-if="results && results.length == 0" class="text-danger mb-0">
            Nessun risultato con questi parametri di ricerca.
          </p>
          <p class="text-left">
            Utilizza il campo "range" per aumentare o diminuire il raggio di
            ricerca
          </p>
          <p class="text-left text-danger" v-if="!userCoordinates">
            Per utilizzare questa funzionalità devi acconsentire alla
            condivisione della tua posizione.
            <b class="point" @click="startGeolocal">Concedi consenso</b>
          </p>
          <b-form-group class="col-12 col-md-8">
            <label for="range-2" class="text-secondary font-weight-bolder"
              >Range:</label
            >
            <b-form-input
              id="range-2"
              v-model="searchFilters.radius"
              type="range"
              :min="50"
              :max="1000"
              step="50"
            >
            </b-form-input>
          </b-form-group>

          <span class="my-2 col-12 col-md-3"
            >{{ searchFilters.radius }} metri</span
          >
          <div class="text-center">
            <b-button variant="secondary " @click="doGeolocalSearch">
              <span v-if="!spinSearch"> Ripeti ricerca</span>
              <b-spinner v-else></b-spinner>
            </b-button>
          </div>
        </b-form>
        <b-form
          key="form-search"
          v-else
          v-on:submit.prevent="submitSearch"
          v-on:reset.prevent="clearFilters"
        >
          <p class="text-left mb-3 mt-1">
            Compila uno o più campi e premi su "cerca" per visualizzare i
            risultati della ricerca
          </p>
          <div>
            <div class="col-12 d-flex flex-wrap px-0">
              <b-form-group
                label="Regione"
                label-for="regione"
                class="text-left mx-auto col-12 col-md-6"
              >
                <b-form-select
                  v-capitalize-first-select
                  class="my-3 text-capitalize"
                  :options="regioni"
                  name="regione"
                  v-model="selected.regione"
                  @change="getProvince(selected)"
                  required
                >
                  <b-form-select-option :value="null"
                    >Seleziona Regione</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
              <b-form-group
                label="Provincia"
                label-for="provincia"
                class="text-left mx-auto col-12 col-md-6"
              >
                <b-form-select
                  v-capitalize-first-select
                  class="my-3 text-capitalize"
                  :options="province"
                  name="provincia"
                  v-model="selected.provincia"
                  @change="getComuni(selected)"
                  required
                >
                  <b-form-select-option :value="null"
                    >Seleziona Provincia</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-12 d-flex flex-wrap px-0">
              <b-form-group
                label="Comune"
                label-for="comune"
                class="text-left mx-auto col-12 col-md-6"
              >
                <b-form-select
                  v-capitalize-first-select
                  class="my-3 text-capitalize"
                  name="comune"
                  v-model="selected.comune"
                  :key="
                    comuni && comuni.length
                      ? comuni[0].comune + comuni.length
                      : null
                  "
                  required
                >
                  <b-form-select-option
                    v-for="(com, ind) in comuni"
                    :key="ind"
                    :value="com"
                    @change="setData"
                    >{{ com.comune }}</b-form-select-option
                  >
                  <b-form-select-option :value="null"
                    >Seleziona Comune</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
              <b-form-group
                label="Frazione"
                label-for="frazione"
                class="text-left mx-auto col-12 col-md-6"
              >
                <b-form-input
                  class="my-3 text-capitalize"
                  name="frazione"
                  type="text"
                  placeholder="Frazione..."
                  v-model="filters.frazione"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12 d-flex flex-wrap px-0">
              <b-form-group
                label="Denominazione"
                label-for="denominazione"
                class="text-left mx-auto col-12"
              >
                <b-form-input
                  v-capitalize-first
                  class="my-3"
                  name="Denominazione"
                  type="text"
                  placeholder="Denominazione..."
                  v-model="filters.denominazione"
                  minlength="5"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12 d-flex flex-wrap px-0">
              <b-form-group
                label="Cognome"
                label-for="surname"
                class="text-left mx-auto col-12 col-md-6"
              >
                <b-form-input
                  v-capitalize-first
                  class="my-3"
                  name="surname"
                  type="text"
                  placeholder="Cognome..."
                  v-model="filters.surname"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="CAP"
                label-for="cap"
                class="text-left mx-auto col-12 col-md-6"
              >
                <b-form-input
                  v-capitalize-first
                  class="my-3"
                  name="cap"
                  type="text"
                  placeholder="CAP..."
                  v-model="filters.cap"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <p v-if="results && results.length == 0" class="text-danger">
              Nessun risultato con questi parametri di ricerca
            </p>
            <div class="col-12 my-2 text-center">
              <b-button type="reset" variant="warning text-white fw-bolder m-2"
                >Pulisci campi</b-button
              >
              <b-button
                :disabled="
                  selected.regione == null ||
                  selected.provincia == null ||
                  selected.comune == null
                "
                type="submit"
                variant="secondary text-white fw-bolder m-2"
                >Cerca</b-button
              >
            </div>
          </div>
        </b-form>
      </transition-group>
    </div>
    <div v-else>
      <div class="col-12 pb-3 pl-2 pt-3">
        <span class="fw-bolder text-secondary point" @click="cancelSelection">
          <b-icon
            icon="arrow-left-circle-fill"
            class="mr-1"
            variant="primary"
          ></b-icon>
          <span v-if="formSearch">Torna alla ricerca</span>
          <span v-else>Aumenta il raggio di ricerca</span>
        </span>
      </div>
      <div v-if="results && results.length > 0" class="col-12 px-0">
        <p class="text-left" v-if="$route.name == 'CittadinoHome'">
          Clicca sulla farmacia che vuoi impostare come nuova preferita e
          conferma la scelta premendo su "Conferma Scelta"
        </p>
        <p class="text-left" v-else>
          Clicca sulla farmacia che vuoi selezionare e conferma la scelta
          premendo su "Conferma Scelta"
        </p>
        <div class="col-12 d-flex flex-wrap pb-4 px-0">
          <div
            class="col-sm-12 col-md-6"
            v-for="(company, ind) in results"
            :key="ind"
          >
            <CompanyCard
              :azienda="company"
              :iselected="enlight(company)"
              v-on:company-selection="selezionaCompany($event)"
              class="mr-1 mt-3"
            />
          </div>
        </div>
        <div v-if="selectedCompany" class="col-12">
          <div class="col-12 text-center mt-4 pt-2">
            <b-button
              @click="cancelSelection"
              type="button"
              variant="outline-secondary fw-bolder m-2"
              >Annulla</b-button
            >
            <b-button
              @click="confirmSelection"
              type="button"
              variant="secondary text-white fw-bolder m-2"
              >Conferma Scelta</b-button
            >
          </div>
        </div>
      </div>
      <div v-else-if="results && results.length == 0" class="col-12">
        <p class="text-left">Nessun risultato per questa ricerca</p>
      </div>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services"
import { mapState, mapActions, mapGetters } from "vuex"
import CompanyCard from "@/components/prenotazioni/CompanyCard.vue"
export default {
  name: "SearchCompany",
  components: { CompanyCard },
  data() {
    return {
      filters: {
        comune: null,
        provincia: null,
        regione: null,
        frazione: null,
        cap: null,
        denominazione: null,
        surname: null
      },
      selected: {
        comune: null,
        provincia: null,
        regione: null
      },
      results: null,
      hasResult: false,
      selectedCompany: null,
      form: { preferito: true, company: null },
      formSearch: true,
      userCoordinates: null,
      searchFilters: {
        lat: null,
        long: null,
        radius: 100
      },
      spinSearch: false
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    ...mapState("utente", ["status", "user"]),
    ...mapGetters({
      getCompany: "company/getCompany"
    })
  },
  watch: {
    selected: {
      handler(val) {
        this.setData()
      },
      deep: true
    },
    selectedCompany: function (val) {
      if (val) {
        this.form.company = val.hashid
      }
    }
  },
  methods: {
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    setData() {
      if (this.selected.comune) {
        this.filters.comune = this.selected.comune.comune
      }
      this.filters.regione = this.selected.regione
      this.filters.provincia = this.selected.provincia
    },
    submitSearch() {
      if (
        this.selected.regione !== null &&
        this.selected.provincia !== null &&
        this.selected.comune !== null
      ) {
        var self = this
        supportService
          .searchCompany(this.filters)
          .then(function (response) {
            self.results = response.data.data.data
            // console.log("RISULTATI");
            // console.log(response.data.data.data);
            if (response.data.data.data.length > 0) {
              self.hasResult = true
            }
          })
          .catch(function (error) {
            // console.log(error);
          })
      }
    },
    clearFilters() {
      this.filters.comune = null
      this.filters.provincia = null
      this.filters.regione = null
      this.filters.frazione = null
      this.filters.cap = null
      this.filters.denominazione = null
      this.filters.surname = null
      this.results = null
      this.selected.regione = null
      this.selected.provincia = null
      this.selected.comune = null
      this.hasResult = false
      // if (doRefresh) {
      //   this.submitSearch();
      // }
    },
    enlight(comp) {
      return this.selectedCompany && comp.hashid === this.selectedCompany.hashid
        ? true
        : false
    },
    selezionaCompany(azienda) {
      this.selectedCompany = azienda
    },
    confirmSelection() {
      // var form = {
      //   company: this.selectedCompany.hashid,
      //   preferito: this.preferito,
      // };
      // console.log(this.selectedCompany.denominazione);
      this.$emit("company-saved", {
        name: this.selectedCompany.denominazione,
        hashid: this.selectedCompany.hashid
      })
    },
    cancelSelection() {
      this.clearFilters()
    }
  },
  created() {
    // if (!this.regioni) {
    this.getRegioni()
    // }
  },
  mounted() {
    // console.log(this.user.anagrafica);
    if (this.user.type === 0) {
      this.selected.regione = this.user.anagrafica.regione
      var self = this
      this.getProvince(this.selected).then(function () {
        self.selected.provincia = self.user.anagrafica.provincia
        self.getComuni(self.selected)
        // var innerSelf = self;
        // self.getComuni(self.selected).then(function () {
        //   //
        //   innerSelf.selected.comune = innerSelf.user.anagrafica.comune;
        // });
      })
    }
  }
}
</script>
