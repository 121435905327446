<template>
  <b-container fluid class="m-0 p-0">
    <b-row class="mt-n3 mb-4 mb-sm-0">
      <b-col
        class="col-12 col-sm-3 col-lg-2 px-0 mt-3 d-flex align-items-center"
      >
        <h5 class="text-left text-secondary py-1 mb-0 fw-600 mr-5">
          Patologie
        </h5>
      </b-col>
      <b-col class="col-8 col-sm-9 col-lg-10 text-left px-0 mt-3">
        <b-button @click="$bvModal.show('new-pathology')">
          <b-icon icon="plus"></b-icon> nuova patologia
        </b-button>
      </b-col>
    </b-row>
    <div class="my-3">
      <b-row
        align-v="stretch"
        cols="1"
        cols-sm="2"
        cols-lg="3"
        class="my-2 ml-n2 col-12 px-0 align-items-center align-content-center"
      >
        <span class="text-left" v-if="pathologies && pathologies.length === 0"
          >-</span
        >

        <b-col class="px-2 py-2" v-for="(pat, ind) in pathologies" :key="ind">
          <div
            class="d-flex text-left pl-2 py-3 col-12 puntofarmaCard general_border flex-column text-secondary"
            :class="
              selectedPathology && pat.hashid === selectedPathology.hashid
                ? 'border border-secondary border__2 bg-mdm-light-secondary'
                : ' h-100'
            "
            @click="selectPathology(pat, false, ind)"
          >
            <div class="m-0 p-0 d-flex">
              <div
                class="m-0 p-0 d-none d-sm-flex flex-column justify-content-center align-items-center"
                style="flex: 0 0 100px; min-width: 0"
              >
                <b-icon
                  :icon="pathologyTypesIcons[pat.type]"
                  font-scale="2"
                ></b-icon>
                <span class="text-center text-capitalize fontsize075rem pt-2">{{
                  pat.tipo
                }}</span>
              </div>
              <!-- :class="'border-' + typesColors[pat.type]" -->
              <div class="m-0 p-0 pl-2" style="flex: 1">
                <h5
                  class="mb-0 pt-1 text-uppercase long__txt point"
                  @click="selectPathology(pat, true, ind)"
                >
                  {{ pat.name }}
                </h5>

                <!-- Riga per il tipo che spunta solo in visualizzazione mobile -->
                <p
                  class="d-sm-none mb-0 mt-auto pt-1 text-capitalize d-flex justify-items-between"
                >
                  <!-- :class="'text-' + typesColors[pat.type]" -->
                  <span class="mr-auto">{{ pat.tipo }}</span>
                </p>

                <p class="mb-0 mt-auto pt-1 d-flex justify-items-between">
                  <!-- :class="'text-' + typesColors[pat.type]" -->

                  <span class="mr-auto text-secondary">
                    <span v-if="pat.start_at && !pat.end_at">
                      inizio: {{ formatDataOnlyNumbers(pat.start_at) }}
                    </span>
                    <span v-else-if="pat.start_at">
                      dal {{ formatDataOnlyNumbers(pat.start_at) }}
                    </span>

                    <span v-if="pat.end_at">
                      al {{ formatDataOnlyNumbers(pat.end) }}
                    </span>
                  </span>
                  <span
                    class="ml-auto point"
                    @click="selectPathology(pat, true, ind)"
                  >
                    <b-iconstack title="Vedi" font-scale="2.2" class="point">
                      <b-icon
                        stacked
                        icon="circle-fill"
                        variant="mdm-light-grey"
                      ></b-icon>
                      <b-icon
                        stacked
                        icon="zoom-in"
                        scale="0.5"
                        variant="secondary"
                      ></b-icon>
                    </b-iconstack>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <template
            v-if="selectedPathology && pat.hashid === selectedPathology.hashid"
          >
            <b-row
              class="mt-3 justify-content-center"
              v-if="
                ($route.name == 'profilo sanitario' ||
                  $route.name == 'scheda paziente') &&
                $route.path != '/cittadino/profilosanitario/terapie/nuova'
              "
            >
              <b-button
                type="button"
                variant="secondary"
                class=""
                @click="goToNewTherapy"
                >Crea terapia per {{ selectedPathology.name }}</b-button
              >
            </b-row>
          </template>
        </b-col>
      </b-row>
    </div>
    <b-modal
      size="xl"
      id="new-pathology"
      centered
      hide-footer
      title="Nuova patologia"
    >
      <b-form @submit.prevent="onSubmit" class="bg-extra-light">
        <!-- <div class="col-12 d-flex flex-wrap justify-content-center"> -->
        <div class="general_border py-4 px-3 mb-2">
          <h6 class="text-left pb-1">Cerca patologia</h6>
          <div class="text-left">
            <b-form-group
              label=""
              class="col-12 px-0 px-md-2 col-md-10"
              description="*fonte: classificazione ICD-10 aggiornata"
            >
              <b-form-input
                class=" "
                v-capitalize-first
                type="search"
                name="search-pathology"
                placeholder="Cerca patologia per nome o codice..."
                v-model="filters.term"
                v-debounce:500ms="doSearch"
                :debounce-events="'keyup'"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group
              v-if="results.length > 0"
              label="Seleziona patologia"
              label-for="pathology-name"
              class="text-left text-font-grey col-12 col-md-10 mb-2 mt-4"
            >
              <b-form-select
                v-capitalize-first-select
                v-if="searchType > 0"
                class="my-3"
                name="pathology-name"
                v-model="form.name"
              >
                <b-form-select-option
                  v-for="(res, ind) in results"
                  :key="ind"
                  :value="res.valore"
                  >{{ res.valore }}
                </b-form-select-option>
                <b-form-select-option :value="null">-- </b-form-select-option>
              </b-form-select>
              <b-form-select
                v-capitalize-first-select
                v-else
                class="my-3"
                name="name"
                v-model="icdSelected"
              >
                <b-form-select-option
                  v-for="(res, ind) in results"
                  :key="ind"
                  :value="res"
                  >{{ res.full_code }} {{ res.name }} ({{
                    res.category_name
                  }})</b-form-select-option
                >
                <b-form-select-option :value="null">-- </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
        </div>
        <div v-if="form.name || notFound">
          <b-row cols="1" class="text-left mx-auto mt-3">
            <b-col>
              <b-form-group class="text-left" label="Nome patologia*">
                <b-form-input
                  v-capitalize-first
                  class="col-12"
                  v-model="form.name"
                  type="text"
                  required
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <!-- <p class="mt-2">Seleziona la tipologia*</p> -->
              <b-form-group label="Tipologia*" class="mt-3">
                <b-form-radio-group
                  v-model="form.type"
                  name="type"
                  class="d-flex my-3"
                >
                  <b-form-radio
                    v-for="(tipo, ind) in types"
                    :key="ind"
                    :value="tipo.value"
                    >{{ tipo.label }}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            cols="1"
            cols-md="2"
            class="text-left mx-auto mt-3"
            v-if="form.type < 2"
          >
            <b-col>
              <b-form-group
                label="Data di inizio"
                label-for="start_at"
                class="text-left mx-auto"
              >
                <b-form-input
                  class="my-3"
                  name="start_at"
                  type="date"
                  v-model="form.start_at"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col v-if="form.type === 1">
              <b-form-group
                label="Data di fine"
                label-for="end_at"
                class="text-left mx-auto"
              >
                <b-form-input
                  class="my-3"
                  name="end_at"
                  type="date"
                  v-model="form.end_at"
                  :state="form.start_at == null ? null : validDate"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row cols="1" class="text-left mx-auto mt-3">
            <b-col class="" v-if="form.own && user.type === 0">
              <b-form-group
                label=""
                description="**se selezioni questa opzione soltanto tu potrai vedere questa patologia"
                label-for="private"
                class="text-left"
              >
                <b-form-checkbox
                  id="private"
                  v-model="form.private"
                  name="private"
                  variant="text-secondary"
                  :value="true"
                  :unchecked-value="false"
                >
                  visibile solo a me**
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col class="mt-2">
              <b-form-group
                label="Annotazioni"
                label-for="details"
                class="text-left mt-2"
              >
                <b-form-textarea
                  v-capitalize-first
                  class=" "
                  name="details"
                  type="text"
                  v-model="form.details"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="mx-auto px-2 pb-4 text-right">
            <p class="text-left pl-4 mt-3">*Campi obbligatori</p>
            <b-button
              type="button"
              variant="popup-button mr-3"
              @click.prevent="handleAnnulla"
              >Annulla</b-button
            >
            <b-button
              type="submit"
              :disabled="form.name == null || validDate === false"
              variant="popup-button"
              >Salva
            </b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      size="xl"
      id="edit-pathology"
      centered
      hide-footer
      :title="selectedPathology ? selectedPathology.name : ''"
    >
      <b-form class="pt-2" :class="noEdit ? '' : 'bg-extra-light'">
        <div v-if="selectedPathology">
          <b-row cols="1" class="text-left mx-auto mt-3">
            <b-col v-if="!noEdit">
              <h6 class="text-left pb-1">Cerca patologia</h6>
              <b-form-group
                label=""
                class="col-12 px-0 px-md-2 col-md-10 mb-3"
                description="*fonte: classificazione ICD-10 aggiornata"
              >
                <b-form-input
                  v-capitalize-first
                  class=" "
                  name="search-pathology"
                  type="search"
                  placeholder="Cerca patologia per nome o codice..."
                  v-model="filters.term"
                  v-debounce:500ms="doSearch"
                  :debounce-events="'keyup'"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                v-if="results.length > 0"
                label="Seleziona patologia"
                label-for="pathology-name"
                class="text-left col-12 col-md-10 mb-2 pb-2 mt-2"
              >
                <b-form-select
                  v-capitalize-first-select
                  v-if="searchType > 0"
                  class="my-3"
                  name="pathology-name"
                  v-model="selectedPathology.name"
                >
                  <b-form-select-option
                    v-for="(res, ind) in results"
                    :key="ind"
                    :value="res.valore"
                    >{{ res.valore }}
                  </b-form-select-option>
                  <b-form-select-option :value="null">-- </b-form-select-option>
                </b-form-select>
                <b-form-select
                  v-capitalize-first-select
                  v-else
                  class="my-3"
                  name="name"
                  v-model="icdSelected"
                >
                  <b-form-select-option
                    v-for="(res, ind) in results"
                    :key="ind"
                    :value="res"
                    >{{ res.full_code }} {{ res.name }}({{
                      res.category_name
                    }})</b-form-select-option
                  >
                  <b-form-select-option :value="null">-- </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Nome patologia*" class="text-left">
                <b-form-input
                  v-capitalize-first
                  class="col-12"
                  v-model="selectedPathology.name"
                  type="text"
                  required
                  placeholder="..."
                  :readonly="noEdit"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col v-if="noEdit && selectedPathology.classificazione">
              <p class="text-left pb-1 pt-3">
                Codice Patologia (ICD-10):
                {{ selectedPathology.classificazione }}
              </p>
            </b-col>
            <b-col class="mt-3">
              <p>Tipologia {{ !noEdit ? "*" : "" }}</p>
              <b-form-radio-group
                v-model="selectedPathology.type"
                name="type"
                class="d-flex my-3"
                :readonly="noEdit"
                :disabled="noEdit"
              >
                <b-form-radio
                  v-for="(tipo, ind) in types"
                  :key="ind"
                  :value="tipo.value"
                  >{{ tipo.label }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <b-row
            cols="1"
            cols-md="2"
            class="text-left mx-auto mt-3"
            v-if="selectedPathology.type < 2"
          >
            <b-col>
              <b-form-group
                label="Data di inizio"
                label-for="start_at"
                class="text-left mx-auto"
              >
                <b-form-input
                  class="my-3"
                  name="start_at"
                  type="date"
                  v-model="selectedPathology.start_at"
                  :readonly="noEdit"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col v-if="selectedPathology.type === 1">
              <b-form-group
                label="Data di fine"
                label-for="end_at"
                class="text-left mx-auto"
              >
                <b-form-input
                  class="my-3"
                  name="end_at"
                  type="date"
                  v-model="selectedPathology.end_at"
                  :readonly="noEdit"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row cols="1" class="text-left mx-auto mt-3">
            <b-col class="" v-if="selectedPathology.own && user.type === 0">
              <b-form-group
                label=""
                description="**se selezioni questa opzione soltanto tu potrai vedere questa patologia"
                label-for="private"
                class="text-left"
              >
                <b-form-checkbox
                  id="private"
                  v-model="selectedPathology.private"
                  name="private"
                  :value="1"
                  :unchecked-value="0"
                  :readonly="noEdit"
                  :disabled="noEdit"
                >
                  visibile solo a me**
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col class="mt-2">
              <b-form-group
                label="Annotazioni"
                label-for="details"
                class="text-left mt-2"
              >
                <b-form-textarea
                  v-capitalize-first
                  class=" "
                  name="details"
                  type="text"
                  v-model="selectedPathology.details"
                  :readonly="noEdit"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            class="mx-auto px-2 pb-4 mb-4 text-right mt-4"
            v-if="selectedPathology.own && noEdit"
          >
            <b-button
              type="button"
              variant="outline-secondary m-2  "
              @click.prevent="noEdit = false"
              >Modifica</b-button
            >
          </div>
          <div class="mx-auto px-2 pb-4 text-right" v-if="!noEdit">
            <p class="text-left pl-4 mt-3">*Campi obbligatori</p>
            <b-button
              type="button"
              variant="popup-button mr-3"
              @click.prevent="handleAnnulla"
              >Annulla</b-button
            >
            <b-button
              type="button"
              @click.prevent="doUpdate"
              :disabled="selectedPathology.name == null"
              variant="popup-button"
              >Salva
            </b-button>
          </div>
          <h6 class="pl-3 mb-0 mt-1 text-capitalize">
            inserita il {{ formatData(selectedPathology.created_at) }}
          </h6>
        </div>
      </b-form>
    </b-modal>
  </b-container>
</template>
<script>
import * as moment from "moment/moment"
import { utilityService, cittadinoService } from "@/_services"
import { mapState, mapActions } from "vuex"
export default {
  name: "PatologieComponent",
  props: ["subject"],
  data() {
    return {
      pathologyTypesIcons: ["calendar4", "lightning", "brightness-high"],
      filters: {
        term: null
      },
      searchType: 0,
      results: [],
      form: {
        name: null,
        type: 0,
        start_at: null,
        end_at: null,
        details: null,
        own: true,
        private: false,
        subjectable_id: null,
        subjectable_type: null
      },
      types: [
        { value: 0, label: "cronica" },
        { value: 1, label: "acuta" },
        { value: 2, label: "stagionale" }
      ],
      notFound: false,
      pathologies: null,
      // typesColors: ["ter-4", "ter-1", "ter-2"],
      selectedPathology: null,
      noEdit: true,
      patIndex: null,
      icdSelected: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"]),
    validDate() {
      if (this.form.end_at == null || this.form.end_at == "") {
        return null
      }
      return moment(this.form.start_at).isSameOrBefore(moment(this.form.end_at))
        ? null
        : false
    }
  },
  watch: {
    icdSelected: {
      handler(val) {
        // console.log(val);
        if (this.noEdit === true) {
          this.form.name = val.name
          // this.form.name = val.name + "(" + val.category_name + ")";
          this.form.icd10_pathology_id = val.hashid
        } else if (this.selectedPathology) {
          this.selectedPathology.name = val.name
          // val.name + "(" + val.category_name + ")";
          this.selectedPathology.classificazione_id = val.hashid
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    searchIcd() {
      var self = this
      utilityService
        .searchPatologiaIcd(this.filters)
        .then(function (response) {
          self.results = response.data.data
        })
        .catch(function (error) {
          console.log(error)
          self.results = []
        })
    },
    formatData(data) {
      return moment(data).format("DD MMM YYYY")
    },
    formatDataOnlyNumbers(data) {
      return moment(data).format("DD/MM/YYYY")
    },
    doSearch() {
      if (this.searchType === 0) {
        this.searchIcd()
      } else if (this.searchType === 1) {
        this.searchCronica()
      } else {
        this.searchRara()
      }
    },
    searchRara() {
      var self = this
      utilityService
        .searchPatologiaRara(this.filters)
        .then(function (response) {
          self.results = response.data.resultObj
        })
        .catch(function (error) {
          console.log(error)
          self.results = []
        })
    },
    searchCronica() {
      var self = this
      utilityService
        .searchPatologiaCronica(this.filters)
        .then(function (response) {
          self.results = response.data.resultObj
        })
        .catch(function (error) {
          console.log(error)
          self.results = []
        })
    },
    selectPathology(pathology, doShow, ind) {
      this.selectedPathology = pathology
      if (doShow) {
        this.$bvModal.show("edit-pathology")
      }
      this.patIndex = ind
      this.$emit("selected-pathology", pathology)
    },
    onSubmit() {
      var self = this
      cittadinoService
        .storePathology(this.form)
        .then(function (response) {
          self.successAlert("Patologia memorizzata con successo!")
          self.$bvModal.hide("new-pathology")
          self.fetchPathologies()
          self.clearForm()
        })
        .catch(function (error) {
          var msg = "Non è stato possibile salvare la patologia"
          console.log(error)
          self.errorAlert(msg)
          self.$bvModal.hide("new-pathology")
        })
    },
    doUpdate() {
      var self = this
      cittadinoService
        .updatePathology(this.selectedPathology)
        .then(function (response) {
          self.successAlert("Modifiche memorizzate con successo!")
          self.$bvModal.hide("edit-pathology")
          self.fetchPathologies()
          self.clearForm()
          self.noEdit = true
        })
        .catch(function (error) {
          var msg = "Non è stato possibile salvare le modifiche"
          console.log(error)
          self.errorAlert(msg)
          self.$bvModal.hide("edit-pathology")
          self.noEdit = true
        })
    },
    fetchPathologies() {
      if (this.subject) {
        return this.fetchUserPathologies()
      }
      this.fetchOwnPathologies()
    },
    fetchOwnPathologies() {
      var self = this
      cittadinoService
        .getPathologies()
        .then(function (response) {
          self.pathologies = response.data.data
          if (self.$route.query.pax != undefined) {
            var ndx = parseInt(self.$route.query.pax)
            self.hasPathology = 1
            self.selectPathology(response.data.data[ndx], false, ndx)
          }
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le patologie")
        })
    },
    fetchUserPathologies() {
      var self = this
      var filters = {
        user: this.subject.hashid
      }
      cittadinoService
        .getPathologies(filters)
        .then(function (response) {
          self.pathologies = response.data.data
          if (self.$route.query.pax != undefined) {
            var ndx = parseInt(self.$route.query.pax)
            self.hasPathology = 1
            self.selectPathology(response.data.data[ndx], false, ndx)
          }
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le patologie")
        })
    },
    clearForm() {
      this.form.name = null
      this.form.details = null
      this.filters.term = null
      this.form.start_at = null
      this.form.end_at = null
      this.notFound = false
    },
    handleAnnulla() {
      if (!this.noEdit) {
        this.noEdit = true
        this.selectedPathology = null
        return this.$bvModal.hide("edit-pathology")
      }
      this.$bvModal.hide("new-pathology")
      this.clearForm()
    },
    goToNewTherapy() {
      var destination = {
        path: "/cittadino/profilosanitario/terapie/nuova",
        query: {
          pax: this.patIndex
        }
      }
      if (this.subject) {
        destination = {
          path: "/operatore/pazienti/terapia",
          query: {
            sub: this.subject.hashid,
            type: this.subject.type,
            pax: this.patIndex
          }
        }
      }
      this.$router.push(destination)
    }
  },
  created() {
    if (this.subject) {
      this.form.subjectable_id = this.subject.hashid
      this.form.subjectable_type = this.subject.type
      this.form.own = false
    }
    this.fetchPathologies()
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      // console.log("Modal is about to be shown", bvEvent, modalId);
      this.clearForm()
    })
  }
}
</script>
<style>
.border__2 {
  border-width: 2px !important;
}
</style>
