<template>
  <div class="py-3" v-if="user.permessi.includes('somministrare questionari')">
    <!--  -->
    <div v-if="loading" class="col-12 text-center">
      <b-spinner></b-spinner>
    </div>
    <div v-else>
      <div class="d-flex justify-content-between">
        <router-link
          class="text-secondary no__hover underlined"
          :to="{ name: 'attivita', params: { section: 'questionari' } }"
        >
          <b-icon icon="arrow-left-circle-fill" variant="secondary"></b-icon>
          Questionari
        </router-link>

        <router-link
          v-if="selectedUser"
          class="text-secondary no__hover underlined"
          :to="{
            name: 'scheda paziente',
            params: { hash: selectedUser.hashid },
          }"
          target="_blank"
        >
          Scheda Paziente
          <b-icon icon="arrow-right-circle-fill" variant="secondary"></b-icon>
        </router-link>
      </div>
      <div v-if="survey && !complete" class="py-3">
        <h4 class="fw-bolder text-left pb-1">
          {{ survey.name }}
        </h4>
        <p class="text-justify text-medium-grey fw-bolder">
          {{ survey.description }}
        </p>
        <!-- se invece non è prof2 -->
        <template v-if="needToSelectUser">
          <div v-if="!selectedUser" class="mt-4 text-left">
            <!-- se in chiaro deve selezionare il paziente -->
            <!-- se invece non è prof2 -->
            <template v-if="!arruolamento">
              <h6 class="text-left">
                Seleziona il paziente a cui si intende somministrare il
                questionario:
              </h6>
              <SearchUtente
                class="text-center ml-0 mt-4"
                :fullResponse="true"
                v-on:select-user="selectUserForSurvey($event)"
                v-on:arruola-user="doRegister($event)"
              />
            </template>
            <b-row cols="1" class="my-2" v-else>
              <h4 class="text-left">Registra Paziente</h4>
              <p class="text-left text-font-light-grey fs-5"></p>
              <SchedaArruolamento
                :params="precompiled"
                v-on:cancel-operation="undoArruolamento"
                v-on:arruolamento-done="goOn($event, userdata)"
              />
            </b-row>
          </div>
          <template v-else-if="selectedUser">
            <!-- in chiaro, ha selezionato il paziente -->
            <div
              class="shadow_2 general_border bg-white text-left p-4 py-2 mb-4"
            >
              <h6 class="text-left">Paziente selezionato:</h6>
              <b-row class="flex-wrap">
                <b-col class="col-3 col-md-2 text-center">
                  <span class="">
                    <b-avatar
                      :src="selectedUser.profile_pic"
                      size="66px"
                      variant="white"
                      class="general_border"
                      rounded="lg"
                    ></b-avatar>
                  </span>
                </b-col>
                <b-col class="px-0">
                  <p class="mb-0 font-weight-bolder">NOME E COGNOME</p>
                  <p class="anagraphic-data-text mb-1 mg-lg-2">
                    {{ selectedUser.anagrafica.name }}
                    {{ selectedUser.anagrafica.surname }}
                  </p>
                </b-col>
                <b-col class="px-0">
                  <p class="mb-0 font-weight-bolder">CODICE FISCALE</p>
                  <p class="anagraphic-data-text mb-1 mg-lg-2">
                    {{ selectedUser.anagrafica.cf.toUpperCase() }}
                  </p>
                </b-col>
                <b-col class="px-0" v-if="selectedUser.email">
                  <p class="mb-0 font-weight-bolder">EMAIL</p>
                  <p class="anagraphic-data-text mb-1 mg-lg-2 long__txt">
                    {{ selectedUser.email }}
                  </p>
                </b-col>
                <b-col class="px-0" v-else>
                  <p class="mb-0 font-weight-bolder">TELEFONO</p>
                  <p class="anagraphic-data-text mb-1 mg-lg-2 long__txt">
                    {{ selectedUser.anagrafica.tel_number }}
                  </p>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-button
                  size="sm"
                  variant="outline-secondary spacing_1 "
                  @click="selectedUser = null"
                  >Annulla selezione
                </b-button>
                <b-button
                  v-if="canEditAnagrafica"
                  size="sm"
                  variant="secondary spacing_1 mx-2"
                  @click="$bvModal.show('anagraphic_editor')"
                  >Modifica anagrafica
                </b-button>
              </b-row>
            </div>
            <template v-if="userIsVerified">
              <div v-if="survey.info">
                <!-- deve accettare l'informativa -->
                <div v-if="!proceed" class="px-lg-3">
                  <h6>Informativa</h6>
                  <p class="text-justify" v-html="survey.info"></p>
                  <!-- <p class="text-justify">{{ survey.info }}</p> -->
                  <b-form-checkbox
                    class="text-font-light-grey"
                    name="data_consense"
                    :value="true"
                    :unchecked-value="false"
                    v-model="consent"
                    :state="consent == true"
                  >
                    Ho letto l'informativa e do il mio consenso
                  </b-form-checkbox>
                  <b-button
                    variant="secondary text-white spacing_1 mt-4"
                    :disabled="!consent"
                    @click="proceed = true"
                  >
                    Procedi</b-button
                  >
                </div>
                <div v-else>
                  <!-- nessuna informativa -->
                  <compila-questionario
                    :survey="survey"
                    v-on:filled-form="submitForm($event)"
                  />
                </div>
              </div>
              <div v-else>
                <!-- nessuna informativa -->
                <compila-questionario
                  :survey="survey"
                  v-on:filled-form="submitForm($event)"
                />
              </div>
            </template>
            <template v-else>
              <OtpValidation
                :user="selectedUser"
                :cause="1"
                :validatable="selectedUser.hashid"
                :second="selectedUser.number_verified_at ? true : false"
                :forceable="true"
                @otp-validated="handleOtpValidation($event)"
                class="text-center"
              >
                <template v-slot:instruction>
                  <h1>
                    <b-icon
                      icon="exclamation-circle-fill"
                      variant="danger"
                    ></b-icon>
                  </h1>
                  <template v-if="selectedUser.number_verified_at">
                    <h2 class="text-center">
                      Poiché il paziente non possiede un indirizzo email, è
                      necessario che venga verificato anche il numero di
                      telefono di riserva.
                    </h2>

                    <h5 class="my-3 text-center">
                      Clicca su "invia codice di verifica" per inviare il codice
                      OTP di verifica via SMS al numero di riserva del paziente
                    </h5>
                  </template>
                  <template v-else>
                    <h2 class="text-center">
                      Per proseguire è necessario che venga verificato il numero
                      del paziente selezionato.
                    </h2>
                    <h5 class="my-3 text-center">
                      Clicca su "invia codice di verifica" per inviare il codice
                      OTP di verifica via SMS
                    </h5>
                  </template>
                </template>
              </OtpValidation>
            </template>
          </template>
        </template>
        <template v-else-if="needToSelectPseudo">
          <!-- questionario pseudoanonimo -->
          <div v-if="!selectedPseudonimo" class="text-left">
            <h6 class="text-left mb-2">Inserisci pseudonimo*:</h6>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-input
                    class=" "
                    v-capitalize-first
                    v-model="pseudonimo"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="mt-1">
                <b-button
                  v-if="pseudonimo && pseudonimo.length > 5"
                  type="button"
                  variant="secondary "
                  @click="selectedPseudonimo = pseudonimo"
                  >Conferma</b-button
                >
              </b-col>
            </b-row>
          </div>
          <template v-else>
            <h6 class="text-left text-capitalize">
              Paziente:
              <span class="text-prim-grad-1">{{ selectedPseudonimo }}</span>
            </h6>
            <compila-questionario
              :survey="survey"
              v-on:filled-form="submitForm($event)"
            />
          </template>
        </template>
        <template v-else>
          <compila-questionario
            :survey="survey"
            v-on:filled-form="submitForm($event)"
          />
        </template>
      </div>
      <div v-else-if="complete">
        <h2 class="fw-bolder">Operazione completata.</h2>
        <template v-if="needToSelectUser">
          <h6 class="text-justify py-3">
            Cliccando sul tasto "scarica pdf per il paziente" puoi scaricare il
            questionario appena compilato in versione stampabile, comprensivo di
            informativa (laddove presente) e di spazio dedicato per la firma.
            Potrai comunque scaricare il pdf per il paziente e il questionario
            in altri formati accendendo alla reportistica dedicata al
            questionario.
            <br />
            Per pianificare un follow up, vai alla scheda del paziente e clicca
            sul tasto "+" che trovi nella sezione "prenotazioni".
          </h6>
          <b-row class="mt-3">
            <b-col>
              <b-button
                variant="secondary text-white  spacing_1"
                @click="goToScheda"
                >Vai a scheda paziente</b-button
              >
            </b-col>
            <b-col>
              <b-button
                variant="secondary text-white spacing_1"
                @click="downloadPatientVersion()"
              >
                <b-icon icon="download"></b-icon>
                scarica pdf per il paziente
              </b-button>
            </b-col>
          </b-row>
        </template>
      </div>
    </div>
    <b-modal
      id="anagraphic_editor"
      size="xl"
      title="Modifica anagrafica paziente"
      body-class="pt-0"
      scrollable
      hide-footer
    >
      <p class="mb-0 px-1">
        Se necessario, modifica le informazioni anagrafiche del paziente
        essenziali per abilitarne l'utenza prestando particolare attenzione ai
        dati di contatto ( email e numero di telefono), per i quali è richiesta
        la validazione.
      </p>
      <PazienteAnagraficaEditor
        :user="selectedUser"
        @cancel-operation="$bvModal.hide('anagraphic_editor')"
        @edit-done="handleEditDone"
      ></PazienteAnagraficaEditor>
    </b-modal>
  </div>
  <div v-else>
    <h4 class="fw-bolder">Operazione non consentita.</h4>
  </div>
</template>
<script>
import { supportService, cittadinoService } from "@/_services";
import { mapState, mapActions } from "vuex";
import CompilaQuestionario from "@/components/attivita/CompilaQuestionario.vue";
import SearchUtente from "@/components/utilities/SearchUtente.vue";
import SchedaArruolamento from "@/components/pazienti/SchedaArruolamento.vue";
import OtpValidation from "@/components/utilities/OtpValidation.vue";
import PazienteAnagraficaEditor from "@/components/profile/PazienteAnagraficaEditor.vue";
export default {
  name: "CompilaQuestionario",
  components: {
    "compila-questionario": CompilaQuestionario,
    SearchUtente,
    SchedaArruolamento,
    OtpValidation,
    PazienteAnagraficaEditor,
  },
  computed: {
    userIsVerified() {
      if (this.selectedUser) {
        if (!this.selectedUser.number_verified_at) {
          return false;
        }
        // if (!this.selectedUser.email) {
        //   return this.selectedUser.second_number_verified_at != null;
        // }
        return true;
      }
      return false;
    },
    canEditAnagrafica() {
      if (this.selectedUser && this.selectedUser.is_my_patient) {
        if (
          this.selectedUser.email_verified_at ||
          this.selectedUser.number_verified_at
        ) {
          return false;
        }
        return this.selectedUser.all_consenses === false;
      }
      if (this.selectedUser && this.selectedUser.created_by_me) {
        if (
          this.selectedUser.email_verified_at ||
          this.selectedUser.number_verified_at
        ) {
          return false;
        }
        return this.selectedUser.all_consenses === false;
      }
      return false;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      hash: this.$route.params.quest,
      survey: null,
      consent: false,
      proceed: false,
      complete: false,
      surveTypes: {
        0: "anonimo",
        1: "pseudoanonimo",
        2: "in chiaro",
      },
      needToSelectUser: true,
      needToSelectPseudo: true,
      selectedUser: null,
      selectedPseudonimo: null,
      pseudonimo: null,
      arruolamento: false,
      precompiled: null,
      filledSurveyHashid: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      supportService
        .getSurveyById(this.hash)
        .then(function (res) {
          //console.log("FILLED FORM", res.data.data);
          self.survey = res.data.data;
          self.needToSelectUser = res.data.data.type === 2;
          self.needToSelectPseudo = res.data.data.type === 1;
          self.consent = self.survey.info == null;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          // console.log(err);
        });
      //
    },
    selectUserForSurvey(user) {
      // console.log(user);
      this.selectedUser = user;
      // this.$router.push({ name: "scheda Paziente", params: { hash: hashid } });
    },
    goToScheda() {
      this.$router.push({
        name: "scheda paziente",
        params: { hash: this.selectedUser.hashid },
      });
    },
    doRegister(params) {
      this.arruolamento = true;
      this.precompiled = params;
    },
    undoArruolamento() {
      this.arruolamento = false;
      this.precompiled = null;
    },
    goOn(userdata) {
      this.selectedUser = userdata;
      this.fetchUser(userdata.hashid);
      this.arruolamento = false;
    },
    handleOtpValidation(hasVerified) {
      if (hasVerified) {
        return this.fetchUser(this.selectedUser.hashid);
      }
    },
    submitForm(form) {
      var formData = {
        response: JSON.stringify(form.template),
        survey_id: form.hashid,
        user_id: this.selectedUser ? this.selectedUser.hashid : undefined,
        pseudonimo: this.selectedPseudonimo
          ? this.selectedPseudonimo
          : undefined,
      };
      var self = this;
      supportService
        .storeFilledSurvey(formData)
        .then(function (res) {
          //console.log(res);
          self.complete = true;
          self.filledSurveyHashid = res.data.data.hashid;
          window.scrollTo(0, 0);
          self.successAlert("Questionario inviato correttamente.");
          // self.survey = res.data.data;
          // self.consent = self.survey.info == null;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inviare il questionario");
          // console.log(err);
        });
    },
    downloadPatientVersion() {
      var self = this;
      supportService
        .downloadFilledSurveyPatientVersion(this.filledSurveyHashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            "patient_" +
            self.survey.name +
            "_" +
            self.survey.activity.name +
            ".pdf";
          link.click();
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    fetchUser(hashid = null) {
      var userHashid = this.$route.query.user;
      if (hashid !== null) {
        var userHashid = hashid;
      }
      const self = this;
      cittadinoService
        .getUserById(userHashid)
        .then(function (res) {
          self.selectedUser = res.data.data;
          self.loading = false;
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    handleEditDone() {
      this.fetchUser(this.selectedUser.hashid);
      this.$bvModal.hide("anagraphic_editor");
    },
  },
  created() {
    //console.log(this.$route.params.quest);
    if (this.$route.params.quest) {
      //console.log(this.$route.params.quest);
      this.hash = this.$route.params.quest;
      this.fetchData();
    }
    if (this.$route.query.user) {
      this.loading = true;
      this.fetchUser(this.$route.query.user);
    }

    // this.fetchData();
  },
};
</script>
