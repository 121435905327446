sospendi profilo

<template>
  <div>
    <div class="ml-lgx-2">
      <h3 class="mt-2 text-left">
        <b-icon
          icon="battery-charging"
          variant="secondary"
          font-scale="1.2"
          class="mr-1"
        ></b-icon>
        Stato Profilo
      </h3>
      <section class="puntofarmaCard p-2 p-md-3 p-lg-4 mt-2 general_border">
        <b-row>
          <b-col class="py-2">
            <b-row>
              <template v-if="subscriptions">
                <div v-if="subscriptions.pending" class="text-left px-2">
                  <h5>
                    Sottoscrizione in attesa di pagamento:
                    <span class="text-secondary"
                      >{{ subscriptions.pending.abbonamento.nome }}
                      {{ subscriptions.pending.abbonamento.durata }} mesi</span
                    >
                    <p class="text-left" v-if="loggedUserIsAdminCRoom">
                      <b-button
                        @click.prevent="
                          $bvModal.show('confirm-send-subscription')
                        "
                        type="button"
                        variant="secondary m-1 mt-2"
                        size="sm"
                      >
                        Invia link pagamento
                      </b-button>
                    </p>
                  </h5>
                  <p class="text-justify">
                    {{ subscriptions.pending.abbonamento.descrizione }}
                  </p>
                </div>
                <div v-else-if="subscriptions.attivo" class="text-left px-2">
                  <h5>
                    Sottoscrizione attiva:
                    <span class="text-secondary">
                      <!-- {{ subscriptions.attivo.abbonamento.nome }}
                      {{ subscriptions.attivo.abbonamento.durata }} mesi -->
                    </span>
                    <span class="text-secondary"
                      >{{ subscriptions.attivo.abbonamento.nome }}
                      <!-- {{
                        subscriptions.attivo.abbonamento.mesi_frequenza_rinnovo
                      }} -->
                      <!-- {{ frequenzaRinnovo(subscriptions) }}</span -->
                    </span>
                  </h5>
                  <p class="text-justify">
                    {{ subscriptions.attivo.abbonamento.descrizione }}
                  </p>
                </div>
                <b-row
                  align-v="center"
                  class="px-2"
                  v-else-if="subscriptions.disponibile"
                >
                  <h6 class="text-left mb-0">
                    Sottoscrizione non attiva
                    <b-icon icon="x-circle" variant="danger"></b-icon>
                  </h6>
                  <SetPatientSubscriptionCR
                    v-if="loggedUserIsAdminCRoom || canMonitor"
                    :user="user"
                    :subscriptions="subscriptions?.lettura"
                    @set-patient-subscription="
                      requestSubscriptionForUser($event)
                    "
                  />

                  <b-button
                    v-else
                    type="button"
                    size="sm"
                    variant="secondary  m-1 "
                    @click="$bvModal.show('subscribe-user')"
                  >
                    Richiedi attivazione monitoraggio
                  </b-button>
                </b-row>
              </template>
              <b-spinner v-else></b-spinner>
            </b-row>
            <b-row>
              <PercorsoCuraPaziente :userid="user.hashid" class="pl-2 mt-2" />
            </b-row>
          </b-col>
          <b-col class="py-2">
            <template v-if="!user.profilo_sospeso">
              <b-row align-v="center" v-if="!user.profilo_sospeso">
                <h6 class="text-left mb-0">
                  Profilo attivo
                  <b-icon icon="check-circle" variant="secondary"></b-icon>
                </h6>
              </b-row>
              <p class="text-left">
                <b-button
                  type="button"
                  size="sm"
                  variant="outline-secondary mt-3 mr-2"
                  @click="$bvModal.show('confirm-suspension')"
                >
                  Sospendi profilo
                </b-button>
                <b-button
                  size="sm"
                  class="mt-3"
                  @click="
                    $router.push({
                      name: 'dimissioni paziente',
                      query: { userhash: user.hashid, usertype: 'user_in_app' }
                    })
                  "
                  >Emetti referto dimissioni</b-button
                >
              </p>
            </template>
            <b-row align-v="center" v-else>
              <h6 class="text-left mb-0">
                Profilo disattivato
                <b-icon icon="x-circle" variant="danger"></b-icon>
              </h6>
              <b-button
                type="button"
                size="sm"
                variant="secondary  m-1 "
                @click="$bvModal.show('confirm-activation')"
              >
                Riattiva profilo
              </b-button>
            </b-row>
          </b-col>
        </b-row>
      </section>
    </div>

    <b-modal
      id="confirm-suspension"
      size="lg"
      centered
      title="Conferma operazione"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="popup-button mr-2"
      ok-title="Sì, Conferma"
      ok-variant="popup-button"
      footer-class="border-0"
      @ok="suspendUserProfile(true)"
      @cancel="$bvModal.hide('confirm-suspension')"
    >
      <h6>
        Sei sicuro di voler sospendere il profilo di {{ user.anagrafica.name }}
        {{ user.anagrafica.surname }}?
      </h6>
      <p>
        Una volta sospeso il profilo non sarà più possibile inserire né
        modificare nessun dato sulla scheda del paziente.
      </p>
    </b-modal>

    <b-modal
      id="confirm-activation"
      size="lg"
      centered
      title="Conferma operazione"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="popup-button mr-2"
      ok-title="Sì, Conferma"
      ok-variant="popup-button"
      footer-class="border-0"
      @ok="suspendUserProfile(false)"
      @cancel="$bvModal.hide('confirm-activation')"
    >
      <h6>
        Sei sicuro di voler riattivare il profilo di {{ user.anagrafica.name }}
        {{ user.anagrafica.surname }}?
      </h6>
      <p>
        Una volta riattivato il profilo sarà nuovamente possibile inserire e
        modificare dati sulla scheda del paziente.
      </p>
    </b-modal>
    <b-modal
      id="subscribe-user"
      size="lg"
      centered
      title="Conferma operazione"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="popup-button mr-2"
      ok-title="Sì, Conferma"
      ok-variant="popup-button"
      footer-class="border-0"
      @ok="requestMonitoringForUser"
      @cancel="$bvModal.hide('subscribe-user')"
    >
      <h6 class="px-2">
        Sei sicuro di voler richiedere l'attivazione del servizio di
        monitoraggio per
        <span class="text-capitalize">
          {{ user.anagrafica.name }} {{ user.anagrafica.surname }}</span
        >?
      </h6>
      <p class="px-2 text-justify">
        Confermando l'operazione verrà avviata la procedura di attivazione del
        servizio di monitoraggio: un operatore del Centro Servizi si metterà in
        contatto con il paziente per guidarlo nella finalizzazione
        dell'acquisto.<br />Quando il paziente avrà completato il pagamento, il
        servizio verrà automaticamente attivato e lo schema di monitoraggio
        abilitato. <br />
        Nel caso non lo avessi ancora fatto, dopo aver confermato questa
        operazione, potrai selezionare un percorso di cura per il paziente.
      </p>
      <!-- <p class="px-2 text-justify">
        Con questa operazione verrà avviato il processo di attivazione della
        sottoscrizione al servizio di monitoraggio: il paziente verrà assegnato
        ad un operatore del Centro Servizi che lo guiderà nella procedura di
        acquisto del servizio. <br />

        Inoltre, se ancora non impostato, dopo aver confermato l'operazione
        potrai selezionare, tra le opzioni disponibili, un percorso di cura
        idoneo alle esigenze del paziente; diversamente, verrà attivato il
        monitoraggio "standard" dei principali parametri<br />

        Per il monitoraggio dei principali parametri verranno impostati i
        valori-soglia e le frequenze di rilevazione di default.
      </p> -->
    </b-modal>

    <b-modal
      id="confirm-send-subscription"
      title="Conferma operazione"
      centered
      @ok="sendPaymentLink"
      ok-title="Sì, conferma e invia"
      ok-variant="popup-button"
      cancel-title="No, annulla"
      cancel-variant="popup-button"
      footer-border-variant="white"
    >
      <div class="px-2">
        <h5>Inoltra all'utente link per il pagamento via email</h5>
        <p class="p-4 text-justify">
          Cliccando su "Conferma" inoltrerai all'indirizzo email utilizzato
          dall'utente per la registrazione un collegamento al sistema di
          pagamento sicuro Nexi, affinché l'utente possa finalizzare in
          autonomia l'acquisto. <br />
          Il link inviato ha una validità di 4 ore a partire dalla sua
          generazione, ed una volta scaduto dovrà essere rigenerato.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { cittadinoService, croomService } from "@/_services"
import { mapState, mapActions } from "vuex"
import PercorsoCuraPaziente from "@/components/profile/PercorsoCuraPaziente.vue"
import SetPatientSubscriptionCR from "@/components/profile/SetPatientSubscriptionCR.vue"
import { paymentService } from "@/_services"

export default {
  name: "SospendiProfilo",
  props: ["user", "canMonitor"],
  components: { PercorsoCuraPaziente, SetPatientSubscriptionCR },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState({
      loggedUser: (state) => state.utente.user
    }),
    loggedUserIsAdminCRoom() {
      return (
        this.loggedUser &&
        this.loggedUser.permessi.includes("gestire liste pazienti")
      )
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    sendPaymentLink() {
      this.form.pay_by_link = true
      this.form.purchasable_id = this.subscriptions?.pending?.hashid
      const self = this
      paymentService
        .storeUserPurchaseAndGetPaymentConfig(this.form)
        .then(function (response) {
          self.$bvModal.hide("confirm-send-subscription")
          self.successAlert(
            "Un link per il pagamento è stato inviato al tuo indirizzo email."
          )
          //   self.payment = { esito: "SENT", codiceEsito: 777 }
        })
        .catch(function (error) {
          console.log(error)
          self.$bvModal.hide("confirm-send-subscription")
          self.errorAlert(
            "Non è stato possibile inizializzare il pagamento. Contattare l'assistenza"
          )
        })
    },
    suspendUserProfile(stat) {
      const self = this
      const formData = { status: stat }
      cittadinoService
        .suspendUserProfile(this.user.hashid, formData)
        .then(function (res) {
          self.successAlert("Profilo sospeso correttamente.")
          self.$bvModal.hide("confirm-suspension")
          self.$emit("need-update")
        })
        .catch(function (err) {
          console.log(err)
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile completare l'operazione.")
        })
    },
    getUserSubscriptions() {
      const self = this
      const filters = {
        service_ref: 8
      }
      croomService
        .getUserSubscriptions(this.user.hashid, filters)
        .then(function (res) {
          self.subscriptions = res.data.data
        })
        .catch(function (err) {
          console.log(err)
          self.errorAlert("Non è stato possibile completare l'operazione.")
        })
    },
    requestMonitoringForUser() {
      const self = this
      const filters = this.subscriptions.disponibile
        ? {
            slug: this.subscriptions.disponibile.slug
          }
        : undefined
      croomService
        .requestUserSubscription(this.user.hashid, filters)
        .then(function (res) {
          self.$bvModal.hide("subscribe-user")
          self.successAlert("Attivazione eseguita correttamente!")
          self.getUserSubscriptions()
        })
        .catch(function (err) {
          console.log(err)
          self.$bvModal.hide("subscribe-user")
          self.errorAlert("Non è stato possibile completare l'operazione.")
        })
    },
    requestSubscriptionForUser(subscriptionSlug) {
      var self = this
      croomService
        .requestUserSubscription(this.user.hashid, {
          slug: subscriptionSlug
        })
        .then(function (res) {
          self.successAlert("Attivazione eseguita correttamente!")
          self.getUserSubscriptions()
        })
        .catch(function (err) {
          console.log(err)
          self.errorAlert("Non è stato possibile completare l'operazione.")
        })
    }
  },
  data() {
    return {
      subscriptions: null,
      form: {
        payment_type: 0,
        total_items: 1,
        user_id: this.user.hashid,
        purchasable_type: "sottoscrizione-paziente",
        purchasable_id: null
      }
    }
  },
  created() {
    this.getUserSubscriptions()
  }
}
</script>
