<template>
  <b-container fluid>
    <p class="mb-4">{{ confirmMessage }}</p>

    <div class="mb-0 d-flex flex-wrap mt-n2 align-items-center">
      <span class="d-inline-block mt-2 mr-3"
        >Allega un messaggio per l'utente:
      </span>
      <span
        class="d-inline-block mt-2 shadow_2 bg- p-2 general_border general_hover point"
        :class="
          pickedMessage === null
            ? 'bg-secondary text-white'
            : 'bg-medium-light-grey'
        "
        @click="setNoMessage()"
      >
        Nessun messaggio
      </span>
    </div>
    <PickMessageCarousel
      @picked-message="pickedMessageFromCarousel($event)"
      :messages="messages"
      :tiles-per-page="tilesPerPage"
      ref="pickMessageCarousel"
    />
    <b-form-input
      name="freeText"
      type="text"
      v-capitalize-first
      placeholder="Scrivi messaggio..."
      v-model="customMessage"
    ></b-form-input>

    <div class="d-flex justify-content-end mt-4">
      <b-button
        @click="$emit('refused-to-confirm')"
        type="button"
        variant="popup-button"
        >No, chiudi</b-button
      >
      <b-button
        @click="$emit('cancellation-confirmed', pickedMessage)"
        :disabled="pickedMessage === undefined"
        type="button"
        variant="popup-button ml-2"
        >Sì, procedi</b-button
      >
    </div>
  </b-container>
</template>

<script>
import PickMessageCarousel from "@/components/prenotazioni/form/PickMessageCarousel.vue"
export default {
  name: "PickMessageFormComponent",
  props: {
    messages: {
      type: Object,
      required: true
    },
    confirmMessage: {
      type: String,
      required: true
    }
  },
  components: {
    PickMessageCarousel
  },
  watch: {
    customMessage: {
      handler: function (newValue) {
        if (newValue !== null) {
          this.pickedMessage = this.customMessage
          this.$refs.pickMessageCarousel.reset()
        }
      },
      deep: true
    }
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      pickedMessage: null,
      customMessage: null
    }
  },
  computed: {
    tilesPerPage() {
      if (this.innerWidth < 576) {
        return 1
      } else if (this.innerWidth < 992) {
        return 2
      } else if (this.innerWidth < 1200) {
        return 3
      } else {
        return 4
      }
    }
  },
  methods: {
    innerWidthChanged() {
      this.innerWidth = window.innerWidth
    },
    setNoMessage() {
      this.pickedMessage = null
      this.customMessage = null
      this.$refs.pickMessageCarousel.reset()
    },
    pickedMessageFromCarousel(message) {
      this.pickedMessage = message
      this.customMessage = null
    }
  },
  created() {
    window.addEventListener("resize", this.innerWidthChanged)
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler)
  }
}
</script>
