<template>
  <section class="px-4 pb-4">
    <div
      id="dashboard-header"
      class="d-flex justify-content-start align-items-center flex-wrap"
    >
      <h2 class="mr-4" id="current-hour">{{ currentHour }}</h2>
      <b-form-datepicker
        label-help="Utilizza i cursori per navigare tra le date"
        start-weekday="1"
        id="dashboard-operatore-datepicker"
        v-model="currentDate"
        @input="fetchStatistics"
        class="mb-2 col-5 col-md-7 col-lg-6 pl-2"
        :date-format-options="dateFormat"
        locale="it"
      ></b-form-datepicker>
      <span class="ml-4">
        <b-icon
          id="dashboard-explain"
          icon="info-circle"
          font-scale="2"
        ></b-icon>
      </span>

      <b-tooltip
        :placement="$root.isMobile ? 'bottom' : 'rightbottom'"
        target="dashboard-explain"
        :triggers="$root.isMobile ? 'click' : 'hover'"
      >
        <template v-if="showStatusOnTooltip">
          <p class="text-justify">
            <b>In attesa</b> sono le prenotazioni che attendono conferma a
            seguito dell'inoltro o di modifiche da approvare <br />
            <br />
            <b>Confermate </b> sono le prenotazioni che sono state confermate
            dopo l'inoltro o a seguito dell'approvazione di modifiche <br />
            <br />
            <b>Annullate</b> sono le prenotazioni non più valide per cui è stato
            richiesto l'annullamento. <br />
            <br />
            <b>Evase</b> sono le prenotazioni per cui è stato completato il
            processo di gestione. <br />
            <br />

            <span class="underlined point" @click="showStatusOnTooltip = false"
              >Torna Indietro</span
            >
          </p>
        </template>
        <template v-else>
          <p class="text-justify">
            Visualizza le prenotazioni di un giorno specifico e i bottoni di
            azione rapida.
          </p>

          <p class="text-justify">
            Le prenotazioni sono suddivise a seconda dello stato in cui si
            trovano.
          </p>

          <p class="mb-0">
            <b-icon icon="reply"></b-icon>
            <span class="underlined point" @click="showStatusOnTooltip = true">
              Leggi di più sugli stati
            </span>
          </p>
        </template>
      </b-tooltip>
    </div>
    <h1
      class="page-title py-4 text-center text-md-left text-secondary"
      id="welcome-text"
    >
      {{ welcomeText }}
    </h1>
    <div
      v-if="
        user.type === 1 &&
        user.permessi.includes('gestire ordini e prestazioni Facile')
      "
    >
      <b-row v-if="statistics" align-v="stretch">
        <b-col class="col-12 col-xl-8 my-0 my-xl-1 px-0">
          <b-row class="flex-wrap" cols="1" cols-md="3">
            <b-col v-for="card in activeCards" :key="card.index" class="p-0">
              <OperatoreDashboardServiziCard
                :card-data="card.card"
                @navigate="navigateToCalendar($event)"
              />
            </b-col>
          </b-row>
          <b-row class="flex-wrap cols-lgx-5" align-v="stretch">
            <template v-if="services[4]">
              <b-col class="p-0 col-12 col-md-4 col-lgx-equal mt-2">
                <div
                  @click="searchPatientBeforeInsertingIt()"
                  :class="buttonClasses"
                >
                  <div
                    class="d-flex flex-column align-items-center justify-content-center h-100"
                  >
                    <b-icon
                      icon="plus-circle"
                      font-scale="1.8rem"
                      class="mb-2"
                    ></b-icon>
                    <p class="mb-0">Carica un nuovo paziente</p>
                  </div>
                </div>
              </b-col>
              <b-col class="p-0 col-12 col-md-4 col-lgx-equal mt-2">
                <div @click="placeNewHS()" :class="buttonClasses">
                  <div
                    class="d-flex flex-column align-items-center justify-content-center h-100"
                  >
                    <img
                      src="@/assets/dashboard-operatore/dashboardPrestazioni.svg"
                      height="28.8px"
                      width="28.8px"
                      class="mb-2"
                    />
                    <p class="mb-0">Inserisci nuova</p>
                  </div>
                </div>
              </b-col>
              <b-col class="p-0 col-12 col-md-4 col-lgx-equal mt-2">
                <div @click="placeNewTlc()" :class="buttonClasses">
                  <div
                    class="d-flex flex-column align-items-center justify-content-center h-100"
                  >
                    <img
                      src="@/assets/dashboard-operatore/dashboardTeleconsulto.svg"
                      height="28.8px"
                      width="28.8px"
                      class="mb-2"
                    />
                    <p class="mb-0">Inserisci nuovo</p>
                  </div>
                </div>
              </b-col>
              <b-col class="p-0 col-12 col-md-6 col-lgx-equal mt-2">
                <div :class="buttonClasses" @click="placeNewTherapy()">
                  <div
                    class="d-flex flex-column align-items-center justify-content-center h-100"
                  >
                    <b-icon
                      icon="plus-circle"
                      font-scale="1.8rem"
                      class="mb-2"
                    ></b-icon>
                    <p class="mb-0">Inserisci una terapia</p>
                  </div>
                </div>
              </b-col>
              <b-col class="p-0 col-12 col-md-6 col-lgx-equal mt-2">
                <div :class="buttonClasses" @click="navigateToCreateNewHS()">
                  <div
                    class="d-flex flex-column align-items-center justify-content-center h-100"
                  >
                    <b-icon
                      icon="plus-circle"
                      font-scale="1.8rem"
                      class="mb-2"
                    ></b-icon>
                    <p class="mb-0">Crea un nuovo servizio</p>
                  </div>
                </div>
              </b-col>
            </template>
          </b-row>
        </b-col>
        <b-col
          v-if="services[1] || services[2]"
          class="col-12 col-xl-4 my-0 my-xl-1 px-0 pt-xl-3 mt-3 pt-md-2 mt-lgx-3"
        >
          <OperatoreDashboardImpegniCard
            @navigate="navigateToCalendar($event)"
            :impegni="statistics.prossimi"
          />
        </b-col>
      </b-row>

      <b-modal
        size="lg"
        centered
        id="newReservationModal"
        ref="newReservationModal"
        content-class="puntofarmaCardNew pb-3 px-3 pt-2"
        title="Scegli il paziente per cui prenotare"
        hide-footer
      >
        <p>
          Inserisci il codice fiscale o l'indirizzo email del paziente per cui
          vuoi effettuare la prenotazione.
        </p>

        <SearchUtenteDashboard
          v-on:arruola-user="navigateToArruola($event)"
          type="paziente"
          :newReservationType="newReservationType"
          actionType="createNewReservation"
        />
      </b-modal>

      <b-modal
        size="lg"
        centered
        id="newTherapyModal"
        ref="newTherapyModal"
        content-class="puntofarmaCardNew pb-3 px-3 pt-2"
        title="Scegli il paziente per cui aggiungere la terapia"
        hide-footer
      >
        <p>
          Inserisci il codice fiscale o l'indirizzo email del paziente per cui
          vuoi creare una terapia.
        </p>

        <SearchUtenteDashboard
          v-on:arruola-user="navigateToArruola($event)"
          type="paziente"
          :newReservationType="newReservationType"
          actionType="createNewTherapy"
        />
      </b-modal>

      <b-modal
        size="lg"
        centered
        content-class="puntofarmaCardNew pb-3 px-3 pt-2"
        id="newPatient"
        ref="newPatient"
        title="Cerca paziente"
        hide-footer
      >
        <p>
          Verifica che il paziente non sia già presente nel sistema prima di
          procedere all'inserimento
        </p>

        <SearchUtenteDashboard
          v-on:arruola-user="navigateToArruola($event)"
          type="paziente"
          :newReservationType="newReservationType"
          actionType="insertNewPatient"
        />
      </b-modal>
    </div>
  </section>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { reservationService } from "@/_services";
import { companyService } from "@/_services";
import OperatoreDashboardServiziCard from "@/components/dashboard/OperatoreDashboardServiziCard.vue";
import OperatoreDashboardImpegniCard from "@/components/dashboard/OperatoreDashboardImpegniCard.vue";
import SearchUtenteDashboard from "@/components/utilities/SearchUtenteDashboard.vue";

export default {
  name: "CompanyHomeDashboard",
  components: {
    SearchUtenteDashboard,
    OperatoreDashboardServiziCard,
    OperatoreDashboardImpegniCard,
  },
  data() {
    return {
      showStatusOnTooltip: false,
      innerWidth: window.innerWidth,
      newReservationType: undefined,
      statistics: null,
      currentHour: undefined,
      selectedStatusPick: null,
      timer: null,
      updatingData: false,
      loading: true,
      services: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      },
      buttonClasses: `d-flex flex-column align-items-center py-4 mb-3 mr-md-3 puntofarmaCardNew point h-100`,
      greeting: "Ciao",
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"]),
    welcomeText() {
      if (this.innerWidth < 900) return `${this.greeting}!`;
      else if (this.user.anagrafica) {
        return `${this.greeting}, ${this.user.anagrafica.name} ${this.user.anagrafica.surname}`;
      } else return `${this.greeting}!`;
    },
    dateFormat() {
      if (this.innerWidth < 500)
        return {
          day: "numeric",
          month: "short",
          year: "numeric",
        };
      else if (this.innerWidth < 650)
        return {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
        };
      else
        return {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
        };
    },
    companyCards() {
      return [
        {
          serviceIndex: 1,
          key: 1,
          card: {
            service: "prodotti",
            stats: this.statistics?.prodotti ? this.statistics.prodotti : null,
            icon: "dashboardOrdini",
          },
        },
        {
          serviceIndex: 1,
          key: 2,
          card: {
            service: "prestazioni",
            stats: this.statistics?.prestazioni
              ? this.statistics.prestazioni
              : null,
            icon: "dashboardPrestazioni",
          },
        },
        {
          serviceIndex: 2,
          key: 3,
          card: {
            service: "teleconsulti",
            stats: this.statistics?.teleconsulti
              ? this.statistics.teleconsulti
              : null,
            icon: "dashboardTeleconsulto",
          },
        },
      ];
    },
    activeCards() {
      if (this.services) {
        return this.companyCards.filter(
          (card) => this.services[card.serviceIndex]
        );
      } else return this.companyCards;
    },
  },
  watch: {
    $route(val) {
      // console.log("val route change", val);
      if (
        val.query.scope &&
        this.filterOptions.service.includes(val.query.scope)
      ) {
        // console.log("val route change", val);
        this.filterSetted.service = val.query.scope;
        this.filterSetted.status = "in corso";
        this.filterSetted.prestazione = null;
      } else {
        this.filterSetted.service = undefined;
        this.filterSetted.status = "in corso";
        this.filterSetted.prestazione = null;
      }
    },
    currentHour(val) {
      if (val) {
        this.setGreeting();
      }
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    innerWidthChanged() {
      // console.log("resize handler");
      this.innerWidth = window.innerWidth;
    },
    navigateToCalendar(navigationInfo) {
      const reservationType = navigationInfo.service;
      const reservationStatus = navigationInfo.status;
      const servicerif = reservationType === "teleconsulto" ? 2 : 1;
      const search = navigationInfo.search ? navigationInfo.search : null;
      let routeName = servicerif == 1 ? "prenotazioni" : "teleconsulto";
      //to do: handle rif 5 per gli appuntamenti se il level è>1
      this.$router.push({
        name: routeName,
        params: {
          section: "prenotazioni",
        },
        query: {
          startWithCalendar: "yes",
          startWithDate: this.currentDate,
          service: reservationType,
          status: reservationStatus,
          servicerif: servicerif,
          search: search,
        },
      });
    },

    searchPatientBeforeInsertingIt() {
      this.$bvModal.show("newPatient");
    },
    navigateToArruola(params) {
      this.$router.push({
        name: "pazienti",
        query: {
          action: "arruola",
          comingFrom: "dashboard",
        },
        params: params,
      });
    },
    navigateToCreateNewHS() {
      this.$router.push({
        name: `impostazioniOperatore`,
        params: { section: "servizi" },
        query: { action: "createNewHS" },
      });
    },
    placeNewHS() {
      this.newReservationType = "prestazione";
      this.$bvModal.show("newReservationModal");
    },
    placeNewTlc() {
      this.newReservationType = "teleconsulto";
      this.$bvModal.show("newReservationModal");
    },
    placeNewTherapy() {
      this.$bvModal.show("newTherapyModal");
    },
    setCurrentHour() {
      this.currentHour = moment().format("HH:mm");
    },
    fetchStatistics() {
      this.loading = true;
      var self = this;
      reservationService
        .getStatisticsForSpecificDate(this.currentDate)
        .then(function (response) {
          self.statistics = response.data.data;
        })
        .catch(function (error) {
          // self.$bvModal.hide("resev-details");
          // self.$refs["resev-details"].hide();
          // window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile effettuare l'operazione");
          // self.loading = false;
        });
    },
    verifyService(ref) {
      const self = this;
      const filter = { ref: ref };
      companyService
        .checkIfServiceIsActive(filter)
        .then(function (response) {
          self.services[ref] = response.data.data.active;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setGreeting() {
      const hour = moment().format("HH");
      if (hour >= 6 && hour < 12) {
        this.greeting = "Buongiorno";
      } else if (hour >= 12 && hour < 18) {
        this.greeting = "Buon pomeriggio";
      } else {
        this.greeting = "Buona sera";
      }
    },
  },
  created() {
    this.currentDate = moment().format("YYYY-MM-DD");
    this.fetchStatistics();
    window.addEventListener("resize", this.innerWidthChanged);
  },
  destroyed() {
    window.removeEventListener("resize", this.innerWidthChanged);
  },
  mounted() {
    this.verifyService(1);
    this.verifyService(2);
    this.verifyService(4);
    this.verifyService(5);
    this.timer = setInterval(() => {
      if (this.$refs.dett == undefined) {
        this.updatingData = true;
        this.fetchStatistics();
      }
    }, 60000);
    this.setCurrentHour();
    this.currentHourTimer = setInterval(() => {
      this.setCurrentHour();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    clearInterval(this.currentHourTimer);
  },
};
</script>
<style lang="scss">
#dashboard-operatore-datepicker__value_ {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  font-weight: 600;
  /* color: black; */
  font-size: 20px;
}

button#dashboard-operatore-datepicker {
  padding: 0 !important;
}

div#dashboard-operatore-datepicker__outer_ {
  flex-basis: initial;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #00000022;
  border-radius: $border-radius;
  opacity: 1;
  padding-left: 20px !important;
  transition: all 200ms ease-in;
  border: 0;
}

.form-control#dashboard-operatore-datepicker__outer_ {
  width: 350px;
}

@media (min-width: 1500px) {
  .col-lgx-equal {
    flex: 0 0 20% !important;
  }
}

@media (max-width: 500px) {
  #welcome-text,
  #current-hour {
    font-size: 1.6rem;
  }

  #dashboard-operatore-datepicker__value_ {
    font-size: 0.8rem;
  }

  .form-control#dashboard-operatore-datepicker__outer_ {
    min-width: 140px;
  }
}

@media (min-width: 501px) {
  #dashboard-operatore-datepicker__value_ {
    font-size: 16px;
  }

  .form-control#dashboard-operatore-datepicker__outer_ {
    min-width: 320px;
  }
}

@media (min-width: 600px) {
  #dashboard-operatore-datepicker__value_ {
    font-size: 18px;
  }
}

@media (min-width: 700px) {
  #dashboard-operatore-datepicker__value_ {
    font-size: 20px;
  }
}
</style>
