<template>
  <div class="px-md-2">
    <h1 class="page-title pt-2 pb-4 text-center text-md-left" id="welcome-text">
      Pazienti
    </h1>
    <div v-if="isActive === null">
      <p class="text-center"><b-spinner></b-spinner></p>
    </div>
    <div v-else-if="isActive === true">
      <div v-if="showArruola">
        <b-row cols="1" class="my-2">
          <h4 class="text-prim-grad-1 text-left pb-1 mb-4">
            Registra Paziente
          </h4>

          <p class="text-left text-font-light-grey fs-5"></p>
          <SchedaArruolamento
            :params="precompiled"
            v-on:cancel-operation="undoArruolamento"
            v-on:arruolamento-completed="goToScheda($event)"
          />
        </b-row>
      </div>
      <div v-else>
        <b-row class="mb-4 text-left">
          <b-col
            class="col-12 col-md-6 bg-white p-4 general_border shadow_2 position-relative d-flex flex-column justify-content-start align-items-start"
          >
            <b-icon
              id="search-icon-pazienti"
              icon="search"
              variant="secondary"
              font-scale="10"
            ></b-icon>

            <p>In questa pagina puoi effettuare la ricerca del paziente.</p>
            <p>
              Se presente, si può visualizzare la sua scheda paziente. In caso
              contrario, puoi procedere con la registrazione.
            </p>
          </b-col>
        </b-row>
        <b-row class>
          <b-col
            class="col-md-12 col-lg-9 col-lgx-8 col-xl-7 my-2 bg-white p-4 general_border shadow_2"
          >
            <SearchUtente
              :type="'paziente'"
              v-on:invite-user="goToScheda($event)"
              v-on:arruola-user="doRegister($event)"
            />
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="12" class="px-0">
            <transition name="slidevert" mode="out-in">
              <ListaPazienti v-if="showList" />
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>

    <div v-else>
      <h4>Servizio non abilitato</h4>
      <h6>
        <a href="https://concura.online/contatti/"> <u> Contattaci</u></a>
        per saperne di più o per abilitare il servizio
      </h6>
    </div>

    <b-modal
      size="lg"
      id="scheda-pazienti-modal"
      title="Cerca paziente"
      centered
      hide-footer
      @show="showInstructions = false"
      @hidden="showInstructions = true"
      class="mt-1 mx-0 px-0"
    >
      <!-- hide-header-close -->
      <!-- no-close-on-backdrop -->
      <!-- no-close-on-esc -->

      <b-row cols="1" class="my-2" v-if="!arruolamento && !skipToArruola">
        <SearchUtente
          :type="'paziente'"
          v-on:invite-user="goToScheda($event)"
          v-on:arruola-user="doRegister($event)"
        />
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { companyService } from "@/_services";
import SearchUtente from "@/components/utilities/SearchUtente.vue";
import SchedaArruolamento from "@/components/pazienti/SchedaArruolamento.vue";
import ListaPazienti from "@/components/pazienti/ListaPazienti.vue";
import { supportService } from "@/_services";

export default {
  name: "HomePazienti",
  components: {
    SearchUtente,
    SchedaArruolamento,
    ListaPazienti,
  },
  data() {
    return {
      showArruola: false,
      showInstructions: false,
      skipToArruola: false,
      arruolamento: false,
      precompiled: null,
      isActive: null,
      counter: 0,
      counterValue: 0,
      showList: false,
    };
  },
  computed: {
    ...mapState("company", ["companyLoggedIn", "company"]),
  },
  methods: {
    openModal() {
      this.$bvModal.show("scheda-pazienti-modal");
    },
    goToScheda(hashid) {
      // console.log(hashid);
      this.$router.push({
        name: "scheda paziente",
        params: { hash: hashid },
      });
    },
    doRegister(params) {
      this.arruolamento = true;
      this.showArruola = true;
      this.precompiled = params;
      this.$bvModal.hide("scheda-pazienti-modal");
    },
    undoArruolamento() {
      if (
        this.$route.query.action === "arruola" &&
        Object.keys(this.$route.params).length == 2
      ) {
        {
          this.$router.go(-1);
        }
      }
      this.showArruola = false;
      this.arruolamento = false;
      this.precompiled = null;
    },
    handleStart() {
      if (this.$route.query.action === "arruola") {
        this.skipToArruola = true;
        this.showArruola = true;
        if (Object.keys(this.$route.params).length == 2) {
          this.doRegister(this.$route.params);
        }
      }
      // else {
      //   this.openModal()
      // }
    },
    fetchData() {
      var self = this;
      supportService
        .getTotalPatients()
        .then(function (res) {
          self.counterValue = res.data.data.pazienti;
          self.showList = res.data.data.pazienti > 0;
          self.counter = self.counterValue;
          // self.setCounterTimer();
        })
        .catch(function (err) {
          self.errorAlert(
            "Non è stato possibile aggiornare il totale pazienti"
          );
        });
    },
    verifyService() {
      const self = this;
      const filter = { ref: 4 };
      companyService
        .checkIfServiceIsActive(filter)
        .then(function (response) {
          self.isActive = response.data.data.active;
          if (response.data.data.active === true) {
            self.handleStart();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted() {
    this.verifyService();

    var lib = document.createElement("script");
    lib.setAttribute("id", "ls_lib");
    lib.setAttribute("type", "text/javascript");
    lib.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/fm.liveswitch@latest/fm.liveswitch.js"
    );
    document.head.appendChild(lib);
  },
  created() {
    this.fetchData();
  },
  beforeDestroy() {
    var lib = document.getElementById("ls_lib");
    document.head.removeChild(lib);
  },
};
</script>

<style lang="scss" scoped>
#search-icon-pazienti {
  position: absolute;
  top: -70px;
  right: -100px;
}

@media (max-width: 768px) {
  #search-icon-pazienti {
    position: relative;
    top: 0;
    right: 0;
    margin-bottom: 20px;
    font-size: 2rem !important;
  }
}
</style>
