<template>
  <div
    class="general_border p-4 puntofarmaCard d-flex align-items-center h-100 px-0 text-secondary"
  >
    <span class="device-span" :style="deviceStyle"></span>
    <div
      class="h-100 flex-grow-1 d-flex flex-column justify-content-center justify-content-md-between"
    >
      <b-row>
        <b-col class="col-12 text-left pr-0">
          <h5 class="mb-0 py-1 d-flex justify-content-between">
            <span>
              <span
                >{{ device.device.name }} - {{ device.device.model_code }}</span
              >
              <span class="text-secondary fs-5 mx-4 font-weight-normal">
                <i>#</i> {{ device.product_code }}</span
              >
            </span>
            <span v-if="isBiobeat">
              <b-button
                variant="secondary"
                @click="$emit('request-data-update', device.hashid)"
                class="mr-2"
                >Richiedi aggiornamento dati</b-button
              >
            </span>
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <template v-if="!activeExams || !activeExams.length">
          <b-col class="col-12 mt-2">
            <p class="text-left">
              Nessun esame abilitato su questo dispositivo.
            </p>
          </b-col>
        </template>
        <b-col
          class="col-12 col-md-4 text-left mt-2"
          v-for="exam in activeExams"
          :key="exam.hashid"
        >
          <div class="d-flex align-items-center">
            <div class="blinking-circle"></div>
            <span>{{ exam.name }}</span>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="user.permessi.includes('configurare dispositivi utente')">
        <b-col class="text-right pr-0">
          <b-iconstack
            @click="showDeleteConfirm()"
            font-scale="2.2"
            class="point mr-3"
          >
            <b-icon
              stacked
              icon="circle-fill"
              variant="mdm-light-grey"
            ></b-icon>
            <b-icon
              stacked
              icon="trash-fill"
              scale="0.5"
              variant="secondary"
            ></b-icon>
          </b-iconstack>
          <b-iconstack @click="showEditModal()" font-scale="2.2" class="point">
            <b-icon
              stacked
              icon="circle-fill"
              variant="mdm-light-grey"
            ></b-icon>
            <b-icon
              stacked
              icon="pencil-fill"
              scale="0.5"
              variant="secondary"
            ></b-icon>
          </b-iconstack>
        </b-col>
      </b-row>
    </div>
    <b-modal
      :id="`del-confirm-${device.hashid}`"
      centered
      title="Conferma operazione"
      ok-title="Sì, conferma"
      cancel-title="No, annulla"
      ok-variant="popup-button"
      cancel-variant="popup-button mr-2"
      footer-border-variant="white"
      @ok="emitDelete()"
    >
      <h5>Vuoi dismettere il dispositivo "{{ device?.device?.name }}"?</h5>
    </b-modal>
    <b-modal
      :id="`edit-device-${device.hashid}`"
      centered
      hide-footer
      :title="`Modifica Dispositivo ${device?.device?.name}`"
      footer-border-variant="white"
      body-class="general_border"
      size="xl"
    >
      <section class="mb-3 px-4 pt-2">
        <h5 class="mb-3">Parametri</h5>

        <b-row
          v-for="exam in device?.examswithpivot"
          :key="exam.hashid"
          class="my-4 flex-wrap"
        >
          <b-col class="col-4">
            {{ exam.name }}
          </b-col>
          <b-col class="col-8 text-left p-1 general_border">
            <span
              class="px-5 general_border general_hover shadow_2 point py-2 mx-2"
              @click="updateExamStatus(exam, true)"
              :class="
                exam.enabled
                  ? 'bg-secondary text-white'
                  : 'bg-white text-secondary'
              "
              >Abilitato</span
            >
            <span
              class="px-5 general_border general_hover shadow_2 point py-2 mx-2"
              @click="updateExamStatus(exam, false)"
              :class="
                !exam.enabled
                  ? 'bg-secondary text-white'
                  : 'bg-white text-secondary'
              "
              >Disabilitato</span
            >
          </b-col>
        </b-row>
      </section>

      <section class="px-4 pb-4">
        <h5 class="mb-3">Codice Prodotto</h5>
        <b-row>
          <b-col class="col-md-4 pl-0">
            <b-form-group>
              <b-form-input
                v-capitalize-first
                :readonly="!editing"
                v-model="newProductCode"
                class="general_border"
                placeholder="Codice Prodotto"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col
            class="col-8 text-left p-1 general_border d-flex align-items-center"
          >
            <b-button
              v-if="!editing"
              @click="editing = true"
              variant="secondary"
            >
              Modifica
            </b-button>
            <template v-else>
              <b-button @click="emitNewProductCode()" variant="secondary">
                Salva
              </b-button>
              <b-button
                @click="revertEdits()"
                variant="outline-secondary"
                class="ml-3"
              >
                Annulla
              </b-button>
            </template>
          </b-col>
        </b-row>
      </section>
      <div class="d-flex justify-content-end bg-white p-3">
        <b-button
          variant="popup-button"
          @click="$bvModal.hide(`edit-device-${device.hashid}`)"
        >
          Chiudi
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "DeviceCardComponent",
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    deviceStyle() {
      const imageUrl = require(`@/assets/dispositivi/${this.device.device.type}.png`);
      return {
        "background-image": `url(${imageUrl})`,
        "background-size": "contain",
        "background-repeat": "no-repeat",
        "background-position": "center",
      };
    },
    activeExams() {
      return this.device.examswithpivot.filter((exam) => exam.enabled);
    },
    isBiobeat() {
      return this.device.device.name.toLowerCase().includes("biobeat");
    },
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  watch: {},
  data() {
    return {
      editing: false,
      newProductCode: null,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    showDeleteConfirm() {
      this.$bvModal.show(`del-confirm-${this.device.hashid}`);
    },
    showEditModal() {
      this.newProductCode = this.device.product_code;
      this.$bvModal.show(`edit-device-${this.device.hashid}`);
    },
    emitDelete() {
      console.log("emitDelete");
      this.$emit("delete-device", this.device);
    },
    updateExamStatus(exam, status) {
      console.log("updateExamStatus", exam, status);
      if (exam.enabled !== status) {
        this.$emit("update-exam-status", {
          deviceHash: this.device.hashid,
          examHash: exam.hashid,
          status,
        });
      }
    },
    emitNewProductCode() {
      if (this.newProductCode !== this.device.product_code) {
        this.$emit("update-product-code", {
          deviceHash: this.device.hashid,
          newProductCode: this.newProductCode,
        });
        this.editing = false;
      }
    },
    revertEdits() {
      this.newProductCode = this.device.product_code;
      this.editing = false;
    },
  },
  mounted() {},
  created() {},
};
</script>
<style lang="scss" scoped>
.device-span {
  min-width: 100px;
  min-height: 100px;
  height: 100px;
  border-radius: 10%;
  margin-right: 5px;
}

.blinking-circle {
  min-width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: green;
  margin-right: 10px;
  animation: blinking 3s infinite;
}

input.form-control:read-only {
  border-bottom: 1px solid darkgrey;
  border-radius: 0px !important;
  box-shadow: none !important;
  color: $body-color;
}

@keyframes blinking {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
</style>
