<template>
  <!-- <div class="fix__top__right"> -->
  <div class="w-100">
    <p
      class="d-flex align-items-center grey-on-hover mb-0 py-1"
      key="nm-closed"
      @click="openNotifications"
      style="
        white-space: nowrap;
        flex-shrink: 0;
        text-overflow: clip;
        overflow-x: hidden;
      "
    >
      <b-iconstack font-scale="1.3" class="point ml-1">
        <b-icon stacked icon="circle-fill" variant="white" scale="0.6"></b-icon>
        <b-icon stacked icon="bell-fill" scale="0.8" variant="white"></b-icon>
        <b-icon stacked icon="bell" scale="0.8" variant=""></b-icon>
        <b-icon stacked icon="circle" scale="1.05" variant=""></b-icon>
      </b-iconstack>
      <b-badge
        v-show="msgCount > 0"
        class="align-self-start notification__count"
        variant="danger"
        >{{ msgCount }}
      </b-badge>
      <span class="mb-0 ml-2 point">Notifiche</span>
    </p>
    <transition-group name="slideside" mode="out-in">
      <b-row
        v-if="isOpen"
        class="w-100 col-12 align-content-start px-0"
        key="nm-opened"
        style="
          z-index: 99999;
          position: fixed;
          top: 0px;
          left: 0px;
          height: 100vh;
        "
      >
        <b-col
          class="px-0 col-12 col-md-7 col-lg-6 col-lgx-5 col-xl-4 bg-white h-100 general_border"
        >
          <b-row
            class="px-0 d-flex justify-content-between align-items-start mt-4 mt-2 px-4 mx-4"
          >
            <h1>Notifiche {{ msgCountText }}</h1>
            <b-icon
              class="point mr-2"
              variant="secondary"
              icon="x"
              font-scale="3"
              @click="isOpen = false"
            ></b-icon>
          </b-row>
          <b-col class="px-3 mt-3" style="height: 95vh; overflow-y: scroll">
            <b-col
              v-for="(msg, ind) in messages"
              :key="ind"
              class="d-flex align-items-center my-2 text-left bg-line-grey general_border border-secondary py-3"
            >
              <b-icon icon="bell" variant="secondary mr-3" font-scale="3"></b-icon>
              <div>
                <h6 class="font-weight-bolder">
                  {{ msg.title }}
                </h6>
                <p class="text-font-grey mb-0">{{ msg.body }}</p>
              </div>
            </b-col>
            <b-col
              v-for="(msg, ind) in readMessages"
              :key="'r-' + ind"
              class="my-2 d-flex align-items-center text-left border general_border border-secondary shadow_2 py-3"
            >
              <b-icon icon="bell" variant="secondary mr-3" font-scale="3"></b-icon>
              <div>
                <h6 class="font-weight-bolder">
                  {{ msg.title }}
                </h6>
                <p class="text-font-grey mb-0">{{ msg.body }}</p>
                <p class="text-font-grey mb-0" v-if="msg.extraData">
                  {{ msg.extraData }}
                </p>
              </div>
            </b-col>
            <template v-if="noTokenMsg">
              <b-col key="empty-list" class="my-2 text-left pt-3">
                <h5 class="text-secondary"></h5>
                <p class="text-font-grey">
                  Per ricevere notifiche è necessario autorizzarne la ricezione
                  sul browser.
                </p>
              </b-col>
            </template>
            <template v-else-if="msgCount == 0 && readMessages.length == 0">
              <b-col key="empty-list" class="my-2 text-left pt-3">
                <p class="text-font-grey">Nessuna nuova notifica.</p>
              </b-col>
            </template>
          </b-col>
        </b-col>
        <b-col class="d-none d-md-block bg__g h-100" @click="isOpen = false">
        </b-col>
      </b-row>
    </transition-group>
  </div>

  <!-- </div> -->
</template>
<script>
import { mapState, mapActions } from "vuex"
import { getAuth, onAuthStateChanged, getRedirectResult } from "firebase/auth"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { cittadinoService } from "@/_services"
export default {
  name: "NotificationCenter",
  data() {
    return {
      token: null,
      messages: [],
      readMessages: [],
      isOpen: false,
      noTokenMsg: false,
      attempts: 0
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"]),
    msgCount: function () {
      return this.messages.length
    },
    msgCountText: function () {
      return this.msgCount > 0 ? ` (${this.msgCount})` : ""
    }
  },
  watch: {
    token: function (now, prev) {
      // console.log("token changed");
      // console.log("prev", prev);
      // console.log("now", now);
      if (now) {
        this.storeToken()
      }
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    openNotifications() {
      // console.log(this.token);
      this.isOpen = true
      var self = this
      setTimeout(function () {
        // self.readMessages.push(...self.messages);
        var prev = self.readMessages
        self.readMessages = self.messages.concat(prev)
        self.messages = []
      }, 1000)
    },
    firebaseAuth() {
      const auth = getAuth(this.$root.firebaseApp)
      onAuthStateChanged(auth, (user) => {
        // console.log("auth", auth);
        // console.log("user", user);
        // console.log("onAuthStateChanged", user);
      })
    },
    storeToken() {
      var form = {
        token: this.token,
        client: "web"
      }
      var self = this
      cittadinoService
        .storeFirebaseToken(form)
        .then(function (res) {
          // console.log("store ok");
        })
        .catch(function (err) {
          // console.log(err);
          self.initPushWithTimeout()
        })
    },
    messagingInit() {
      const messaging = getMessaging()
      var vapkey = process.env.VUE_APP_FBS_VAPIDKEY
      var self = this
      getToken(messaging, {
        vapidKey: vapkey
      })
        .then((currentToken) => {
          if (currentToken) {
            // console.log("current TOken", currentToken);
            self.token = currentToken
            self.noTokenMsg = false
            // Send the token to your server and update the UI if necessary
            // ...
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            )
            // ...
          }
        })
        .catch((err) => {
          self.noTokenMsg = true
          console.log("An error occurred while retrieving token. ", err)
          // ...
        })
      // onMessage(messaging, (payload) => {
      //   console.log("Message received in onMessage component fbs. ", payload);
      //   // if (payload.data) {
      //   //   self.addMessage(payload.data);
      //   //   // // self.messages.push(payload.data);
      //   //   // self.messages.splice(0, 0, payload.data);
      //   //   // self.infoAlert("Nuova notifica");
      //   // }
      // });
    },
    addMessage(data) {
      this.messages.splice(0, 0, data)
      this.$bvToast.toast(data.body, {
        title: data.title,
        solid: true,
        variant: "primary"
      })
      // this.infoAlert("Nuova notifica");
    },
    initPushWithTimeout() {
      if (this.attempts > 3) {
        return false
      }
      var self = this
      setTimeout(function () {
        self.attempt++
        self.messagingInit()
      }, 3000)
    }
  },
  created() {
    // console.log(this.$root.firebaseApp);
    this.firebaseAuth()
  },
  mounted() {
    this.initPushWithTimeout()
    navigator.serviceWorker.addEventListener("message", (event) => {
      var payload = event.data
      if (event.data.data) {
        payload = event.data.data
      } else if (event.data.notification) {
        payload = event.data.notification
      }
      this.addMessage(payload)
      console.log("Push Notification Recieved message event in main", event) // refresh code goes here
    })
  }
}
</script>
<style>
.fix__top__right {
  position: absolute;
  top: 1rem;
  /* right: 1rem; */
  z-index: 999999;
}

.right__1 {
  right: -2rem;
}

.right__0 {
  right: 0;
  height: calc(100% - 2rem);
  box-shadow: -2px 10px 3px #9f9f9f36;
  overflow-y: scroll;
}

.notification__count {
  margin-left: -0.5rem;
  margin-bottom: 1rem;
}

.bg__g {
  background-color: rgba(58, 58, 58, 0.9);
}

.grey-on-hover:hover {
  color: #575757;
  font-weight: 500;
}
</style>
