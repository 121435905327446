<template>
  <div>
    <PopupModal></PopupModal>
    <BotManWidgetHandler />
    <div class="w-sm-100 d-md-flex">
      <section id="nav-desktop" style="z-index: 5">
        <NavigationCittadino :wlistener="innerWidth" />
      </section>

      <section id="main-operatore">
        <CittadinoLeftHeader />
        <div class="px-4 pt-2">
          <router-view> </router-view>
        </div>
      </section>
      <CallSupportModal
        :onlysupport="true"
        style="position: fixed; bottom: 21px; right: 97px"
      />
      <CallSupportModal />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import NavigationCittadino from "@/components/layout/NavigationCittadino.vue";
import BotManWidgetHandler from "@/components/utilities/BotManWidgetHandler.vue";
import CallSupportModal from "@/components/cittadino/CallSupportModal.vue";
import CittadinoLeftHeader from "@/components/layout/CittadinoLeftHeader.vue";
import PopupModal from "@/components/dashboard/PopupModal.vue";

export default {
  name: "CittadinoMain",
  components: {
    NavigationCittadino,
    BotManWidgetHandler,
    CallSupportModal,
    CittadinoLeftHeader,
    PopupModal
  },
  data() {
    return {
      innerWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    innerWidthChanged() {
      // console.log("resize handler");
      this.innerWidth = window.innerWidth;
    },
  },
  created() {
    if (this.user.type !== 0) {
      this.$router.push({ name: "Home" });
    }


    // this.setupWidgetVariables()
    window.addEventListener("resize", this.innerWidthChanged);
  },
  mounted() {
    if (
      this.user.expired_password == 1 &&
      this.$route.name !== "impostazioniCittadino"
    ) {
      this.$router.push({
        name: "impostazioniCittadino",
        query: { scope: "expired" },
      });
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>
<style lang="scss" scoped>
#nav-desktop {
  transition: flex 500ms;
}

@media all and (min-width: 992px) {
  #main-operatore {
    flex: 1;
    min-width: 0;
  }

  #nav-desktop {
    flex: 0 0 100%;
    min-width: 100%;
  }
}

@media all and (max-width: 1199px) {
  #main-operatore {
    flex: 1;
    min-width: 0;
  }

  #nav-desktop {
    flex: 0 0 79px;
    min-width: 0;
  }
}

@media all and (min-width: 1200px) {
  #main-operatore {
    flex: 1;
    min-width: 0;
  }

  #nav-desktop {
    flex: 0 0 15em;
    min-width: 0;
  }
}

@media all and (min-width: 813px) {
  #main-operatore {
    min-height: calc(100vh - 60px);
  }
}

@media all and (min-width: 448px) and (max-width: 812px) {
  #main-operatore {
    min-height: calc(100vh - 59.5px);
  }
}

@media all and (min-width: 312px) and (max-width: 447px) {
  #main-operatore {
    min-height: calc(100vh - 170px);
  }
}

@media all and (max-width: 311px) {
  #main-operatore {
    min-height: calc(100vh - 95px);
  }
}
</style>
