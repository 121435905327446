<template>
  <div class="pb-1">
    <div v-if="user.type === 1">
      <h4 class="text-left my-auto page-title">Servizi</h4>
      <div class="text-left mt-3">
        <b-button-group class="general_border" size="sm">
          <!-- <h4 class="text-left mr-4 my-auto">Servizi</h4> -->
          <b-button
            v-if="servicesStatus[1]"
            key="b-prestazioni"
            class="text-capitalize buttonbar py-3 px-4"
            :size="$root.isMobile ? 'sm' : ''"
            @click="activePanel = 'prestazioni'"
            :variant="
              activePanel === 'prestazioni' ? 'secondary' : 'outline-secondary '
            "
          >
            <b-icon icon="app-indicator"></b-icon>
            <span v-if="buttonGroupOpen" key="b-prestazioni-t">
              prestazioni</span
            >
          </b-button>
          <!-- disattivato per il solo prestazioni -->
          <b-button
            key="b-servizi"
            v-if="servicesStatus[1] || servicesStatus[2]"
            class="text-capitalize buttonbar py-3 px-4"
            :size="$root.isMobile ? 'sm' : ''"
            @click="activePanel = 'servizi'"
            :variant="
              activePanel === 'servizi' ? 'secondary' : 'outline-secondary '
            "
          >
            <b-icon icon="distribute-vertical"></b-icon>
            <span v-if="buttonGroupOpen" key="b-servizi-t"> altri servizi</span>
          </b-button>
          <b-button
            key="b-orari"
            class="text-capitalize buttonbar py-3 px-4"
            :size="$root.isMobile ? 'sm' : ''"
            @click="activePanel = 'apertura'"
            :variant="
              activePanel === 'apertura' ? 'secondary' : 'outline-secondary '
            "
          >
            <b-icon icon="clock"></b-icon>
            <span v-if="buttonGroupOpen" key="b-orari-t"> orari apertura</span>
          </b-button>
          <template v-if="servicesStatus[1] || servicesStatus[2]">
            <b-button
              key="b-proh"
              class="text-capitalize buttonbar py-3 px-4"
              :size="$root.isMobile ? 'sm' : ''"
              @click="activePanel = 'prohibidates'"
              :variant="
                activePanel === 'prohibidates'
                  ? 'secondary'
                  : 'outline-secondary '
              "
            >
              <b-icon icon="calendar2-x"></b-icon>
              <span v-if="buttonGroupOpen" key="b-proh-t">
                indisponibilità</span
              >
            </b-button>
            <b-button
              key="b-others"
              class="text-capitalize buttonbar py-3 px-4"
              :size="$root.isMobile ? 'sm' : ''"
              @click="activePanel = 'others'"
              :variant="
                activePanel === 'others' ? 'secondary' : 'outline-secondary '
              "
            >
              <b-icon icon="tools"></b-icon>
              <span v-if="buttonGroupOpen" key="b-proh-t"> Altro</span>
            </b-button>
            <span key="btab_handler" class="d-flex align-content-center">
              <b-icon
                key="btab_handler-ic"
                animation="cylon"
                :icon="buttonGroupOpen ? ' arrow-bar-left' : 'arrow-bar-right'"
                font-scale="1.5"
                class="ml-2 my-auto point"
                @click="buttonGroupOpen = !buttonGroupOpen"
              >
              </b-icon>
            </span>
          </template>
        </b-button-group>
      </div>
      <div class="mx-auto">
        <transition-group name="list" mode="in-out">
          <div
            class="mt-2 mb-4 py-3"
            v-if="activePanel === 'apertura'"
            key="apertura"
          >
            <h4 class="text-left pb-1">Orari di apertura al pubblico</h4>

            <SettingOrari
              :orari="company.company_openings"
              v-on:store-openings="submitOpenings($event)"
            />
          </div>
          <div v-if="activePanel === 'servizi' && showServizi" key="servizi">
            <div
              class="mt-2 mb-4"
              v-for="(aperture, servizio) in company.servizi_attivi_orari"
              :key="servizio"
            >
              <div class="py-3" v-if="company.servizi_attivi[servizio]">
                <b-row>
                  <b-col class="col-12 px-0">
                    <b-row class="justify-content-between">
                      <b-col col class="px-0 col-12 col-md-7">
                        <h4 class="text-prim-grad-1 text-left pb-1">
                          Servizio
                          <span
                            v-if="servizio === 'prenotazioni'"
                            class="text-capitalize"
                          >
                            prenotazione ordini</span
                          >
                          <span v-else class="text-capitalize">{{
                            servizio
                          }}</span>
                        </h4>
                        <p class="text-left">
                          Orari, giorni e intervalli di disponibilità per il
                          servizio.
                        </p>
                      </b-col>
                      <b-col class="px-0 col-md-4 col-12 text-right">
                        <ServiceStatus
                          :statoservizio="
                            company.stato_servizi[servizio] == true ? 1 : 0
                          "
                          :servicename="servizio"
                          v-on:store-service-status="submitStatusChange($event)"
                        />
                      </b-col>
                    </b-row>
                    <b-row
                      class="justify-content-end"
                      v-if="company.stato_servizi[servizio] == true"
                    >
                      <b-col class="px-0 col-md-4 col-12 text-right">
                        <ServiceProxySchedulingStatus
                          :servicename="servizio"
                          :statoservizio="
                            company.stato_proxy_servizi[servizio] == true
                              ? 1
                              : 0
                          "
                          v-on:store-service-status="
                            submitProxyStatusChange($event)
                          "
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="col-12 px-0">
                    <div
                      v-if="
                        company.stato_servizi[servizio] == true &&
                        company.stato_proxy_servizi[servizio] == false
                      "
                    >
                      <!-- <transition name="fade" mode="out-in" appear> -->
                      <SettingServiceOrari
                        :orari="company.servizi_attivi_orari[servizio]"
                        :servicename="servizio"
                        v-on:store-service-openings="
                          submitServiceOpenings($event)
                        "
                      />
                      <!-- </transition> -->
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div
            class="mt-2 mb-4 py-3"
            v-if="activePanel === 'prestazioni'"
            key="prestazioni"
          >
            <PrestazioniSanitarie />
          </div>
          <div
            class="mt-2 mb-4 py-3"
            v-if="activePanel === 'prohibidates'"
            key="prohibidates"
          >
            <ProhibidatesComponent />
          </div>
          <div
            class="mt-2 mb-4 py-3"
            v-if="activePanel === 'others'"
            key="others"
          >
            <OtherSettings />
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { companyService } from "@/_services";
import SettingOrari from "@/components/impostazioni/SettingOrari.vue";
import ServiceStatus from "@/components/impostazioni/ServiceStatus.vue";
import SettingServiceOrari from "@/components/impostazioni/SettingServiceOrari.vue";
import AnagraficaCompany from "@/components/impostazioni/AnagraficaCompany.vue";
// import AnagraficaCompanyDF from "@/components/impostazioni/AnagraficaCompanyDF.vue";
import PrestazioniSanitarie from "@/components/impostazioni/PrestazioniSanitarie.vue";
import ProhibidatesComponent from "@/components/impostazioni/ProhibidatesComponent.vue";
import OtherSettings from "@/components/impostazioni/OtherSettings.vue";
import AccessoEPermessi from "@/components/profile/AccessoEPermessi.vue";
import ServiceProxySchedulingStatus from "@/components/impostazioni/ServiceProxySchedulingStatus.vue";

export default {
  name: "ServiziCompany",
  components: {
    AccessoEPermessi,
    SettingOrari,
    SettingServiceOrari,
    ServiceStatus,
    AnagraficaCompany,
    PrestazioniSanitarie,
    ProhibidatesComponent,
    OtherSettings,
    ServiceProxySchedulingStatus,
  },
  computed: {
    showOrari() {
      return this.company && this.company.company_openings ? true : false;
    },
    showServizi() {
      return this.company && this.company.servizi_attivi_orari ? true : false;
    },
    showStati() {
      return this.company && this.company.stato_servizi ? false : true;
    },
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    // ...mapGetters("company", ["getCompany", "getStatoServizi"]),
    ...mapGetters({
      getStatoServizi: "company/getStatoServizi",
      getServiceOpenings: "company/getServiceOpenings",
      getCompany: "company/getCompany",
    }),
  },
  data() {
    return {
      serviceStatus: null,
      serviceToUpdate: null,
      activePanel: "apertura",
      buttonGroupOpen: true,
      servicesStatus: {
        1: false,
        2: false,
      },
    };
  },
  watch: {
    activePanel: function (value) {
      // console.log("active panel", value);
      // if (value === "servizi" || value === "prestazioni") {
      this.logUserCompanyIn();
      // this.company.servizi_attivi_orari = this.getServiceOpenings;
      // }
    },
    servicesStatus: function (val) {
      console.log("servicesStatus", val);
      if (val[1]) {
        this.activePanel = "prestazioni";
      } else if (val[2]) {
        this.activePanel = "servizi";
      } else {
        this.activePanel = "apertura";
      }
    },
  },
  methods: {
    ...mapActions("utente", ["me"]),
    ...mapActions("company", [
      "storeOpenings",
      "storeServiceOpenings",
      "updateServiceStatus",
      "logUserCompanyIn",
      "updateServiceProxyStatus",
    ]),
    submitOpenings(dataToSubmit) {
      this.storeOpenings(dataToSubmit).then(function () {
        window.scrollTo(0, 0);
      });
    },
    submitServiceOpenings(dataToSubmit) {
      // console.log("service data to submit", dataToSubmit);
      var serviceHash = this.company.servizi_attivi[dataToSubmit.service_name];
      dataToSubmit.service_hash = serviceHash;
      this.storeServiceOpenings(dataToSubmit).then(function () {
        window.scrollTo(0, 0);
      });
    },
    submitStatusChange(dataToSubmit) {
      // console.log("service status data to submit", dataToSubmit);
      const serviceHash =
        this.company.servizi_attivi[dataToSubmit.service_name];
      dataToSubmit.service_hash = serviceHash;
      const self = this;
      this.updateServiceStatus(dataToSubmit).then(function () {
        window.scrollTo(0, 0);
        self.logUserCompanyIn();
      });
    },
    submitProxyStatusChange(dataToSubmit) {
      const serviceHash =
        this.company.servizi_attivi[dataToSubmit.service_name];
      dataToSubmit.service_hash = serviceHash;
      const self = this;
      this.updateServiceProxyStatus(dataToSubmit).then(function () {
        window.scrollTo(0, 0);
        self.logUserCompanyIn();
      });
    },
    verifyService(ref) {
      const self = this;
      const filter = { ref: ref };
      companyService
        .checkIfServiceIsActive(filter)
        .then(function (response) {
          self.servicesStatus[ref] = response.data.data.active;
          if (ref === 1 && response.data.data.active) {
            self.activePanel = "prestazioni";
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  // created() {
  //   this.me();
  // },
  mounted() {
    this.verifyService(1);
    this.verifyService(2);
  },
};
</script>
