<template>
  <div>
    <h6 v-if="score !== null" class="fw-bolder mt-2">
      Punteggio totalizzato: {{ score }}
    </h6>
    <h6 class="mt-2">Domande</h6>
    <div v-for="(domanda, ind) in quest.domande" :key="ind" class="my-1">
      <h6 class="">{{ ind + 1 }}. {{ domanda.q }}:</h6>
      <div
        class="panel__box__shadow bg-light-panel border border-light-panel min_border text-justify px-2"
        v-if="domanda.o == 'file'"
      >
        <div v-if="Array.isArray(domanda.a)">
          <ImageShower
            v-for="(hh, kk) in domanda.a"
            :key="kk"
            :hash="hh"
            :indx="kk + 1"
          />
        </div>
        <p v-else>--- nessun file caricato</p>
      </div>
      <p
        class="panel__box__shadow bg-light-panel border border-light-panel min_border text-justify p-2"
        v-else
      >
        <strong>{{ domanda.a }} <br /></strong>
      </p>
    </div>
    <h6 class="">Informativa</h6>
    <p
      v-if="response.info"
      class="bg-sec-4 border-sec-4 min_border text-justify px-2"
    >
      {{ response.info }}
    </p>
    <p v-else>---</p>
    <!-- {{ survey.template }} -->
  </div>
</template>
<script>
import ImageShower from "@/components/utilities/ImageShower.vue";
export default {
  name: "FilledSurveyShower",
  props: ["response", "score"],
  components: { ImageShower },
  data() {
    return {
      quest: null,
    };
  },
  created() {
    this.quest = JSON.parse(this.response);
  },
};
</script>
