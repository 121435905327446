<template>
  <div v-if="user.type === 1">
    <template v-if="isActive === null">
      <p class="text-center"><b-spinner></b-spinner></p>
    </template>
    <template v-else-if="isActive === true">
      <AlertEventDetail
        v-if="$route.params.section === 'detail' && $route.params.hash"
      ></AlertEventDetail>
      <CRoomHomeDashboard v-else></CRoomHomeDashboard>
    </template>
    <template v-else>
      <h4>Servizio non abilitato</h4>
      <h6>
        <a href="https://concura.online/contatti/"> <u> Contattaci</u></a>
        per saperne di più o per abilitare il servizio
      </h6>
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { companyService } from "@/_services";
import CRoomHomeDashboard from "@/components/croom/sections/CRoomHomeDashboard.vue";
import AlertEventDetail from "@/components/croom/sections/AlertEventDetail.vue";
export default {
  name: "ControlRoomMain",
  components: {
    CRoomHomeDashboard,
    AlertEventDetail,
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      isActive: null,
    };
  },
  mounted() {
    this.verifyService();
  },
  methods: {
    verifyService() {
      const self = this;
      const filter = { ref: 8, client: 2 };
      companyService
        .checkIfServiceIsActive(filter)
        .then(function (response) {
          self.isActive = response.data.data.active;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
