var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data && _vm.company)?_c('div',{staticClass:"general_border_17 d-flex flex-column general_hover point shadow_2 fs-6 position-relative mr-3",class:_vm.isSelected ? 'bg-secondary text-white' : 'bg-white text-secondary',staticStyle:{"min-width":"280px","width":"280px","border-radius":"8px","min-height":"200px"},on:{"click":_vm.selectThis}},[(_vm.company.convenzionata && _vm.showConvenzionataBadge)?_c('div',{staticClass:"res-card-logo-container py-2"},[_c('img',{attrs:{"src":require("@/assets/concura-logo-con-white.svg"),"alt":"appLogo","width":"30px"}})]):_vm._e(),_c('div',{class:{
      'd-flex flex-column align-items-center text-center h-100': true,
      invisible: _vm.showInfo,
      visible: !_vm.showInfo
    }},[_c('div',{staticClass:"d-flex justify-content-center w-100 position-relative"},[(_vm.company.profile_pic)?_c('img',{staticClass:"company-img",attrs:{"src":_vm.company.profile_pic,"alt":"Profile Picture"}}):_c('img',{staticClass:"company-img",attrs:{"src":require("@/assets/no_avatar.svg"),"alt":"No Avatar"}})]),_c('div',{staticClass:"text-center p-2 mt-3 flex-grow-1"},[_c('p',{staticClass:"mb-2 fs-1rem"},[_vm._v(" "+_vm._s(_vm.company.denominazione)+" ")]),(_vm.pickedHSName)?_c('div',[_c('p',{staticClass:"fw-bolder"},[_vm._v(" "+_vm._s(_vm.trimmedHSName)+" ")]),(_vm.data.prestazione.description)?_c('p',[_vm._v(" "+_vm._s(_vm.data.prestazione.description)+" ")]):_vm._e(),(_vm.costoPrevistoInSede)?_c('p',{staticClass:"mb-1 fw-bolder"},[_vm._v(" "+_vm._s(_vm.costoPrevistoInSede)+" ")]):_vm._e()]):_vm._e()]),_c('p',{staticClass:"mb-0 mt-auto pb-3"},[(_vm.showHeart)?_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toggle-preferito', _vm.company)}}},[_c('b-icon',{staticClass:"text-center",attrs:{"font-scale":"1.3","icon":_vm.user.farmacia_preferita &&
            _vm.company.hashid === _vm.user.farmacia_preferita.hashid
              ? 'heart-fill'
              : 'heart',"variant":_vm.isSelected ? 'white' : 'secondary'}})],1):_vm._e(),(_vm.suggest)?_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('add-suggestion', _vm.company)}}},[_c('b-button',{staticClass:"text-left my-auto"},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" aggiungi ")],1)],1):_vm._e()])]),_c('div',{staticClass:"d-flex flex-column p-2 align-items-center text-center position-absolute w-100 h-100",class:{ visible: _vm.showInfo, invisible: !_vm.showInfo },staticStyle:{"top":"0","left":"0"}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center flex-grow-1"},[_c('h6',{class:_vm.isSelected ? 'text-white' : 'text-secondary'},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Indirizzo:")]),_c('br'),_vm._v(" "+_vm._s(_vm.company.indirizzo)+", "+_vm._s(_vm.company.frazione)),_c('br'),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.company.comune))])])])]),_c('div',{staticClass:"d-flex justify-content-end position-absolute w-100",staticStyle:{"top":"10px","right":"10px"}},[_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleInfo.apply(null, arguments)}}},[_c('b-icon',{attrs:{"font-scale":"1.5","icon":"info-circle","variant":_vm.isSelected ? 'white' : 'secondary'}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }