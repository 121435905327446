<template>
  <div>
    <b-button
      :size="btnSize"
      variant="secondary"
      @click="$bvModal.show('refertoEditor' + reportId)"
      >Emetti Referto</b-button
    >
    <b-modal
      :id="'refertoEditor' + reportId"
      title="Genera referto firmato"
      size="xl"
      centered
      hide-footer
    >
      <b-form
        id="refertoForm"
        v-show="!showValidation"
        @submit.prevent="onSubmit"
        class="px-3"
      >
        <b-form-group class="px-3">
          <b-form-textarea
            v-capitalize-first
            v-model="form.note_referto"
            placeholder="Le tue annotazioni..."
            class="p-2"
            rows="10"
            required
          >
          </b-form-textarea>
        </b-form-group>
        <div class="text-right mt-3 pt-3">
          <b-button variant="popup-button mr-3" @click="resetAndClose"
            >Annulla</b-button
          >

          <b-button variant="popup-button" type="submit">
            <span v-if="needsVerification">Autorizza ed emetti referto</span>
            <span v-else>Emetti referto</span>
          </b-button>
        </div>
      </b-form>
      <div v-show="showValidation">
        <OtpValidation
          :user="user"
          :cause="1"
          :validatable="user.hashid"
          @otp-validated="handleOtpValidation($event)"
          class="text-center"
        >
          <template v-slot:instruction>
            <h5 class="my-4">
              La struttura richiede che l'operazione venga confermata con la
              verifica di un codice OTP inviato al tuo numero di telefono.
              <br />
              <br />
              Clicca su "invia codice di verifica" per inviare il codice OTP.
            </h5>
          </template>
        </OtpValidation>
        <div class="text-center mt-3 pb-4">
          <h6>oppure</h6>
          <b-button class="mt-2" type="button" @click="showValidation = false"
            >Annulla operazione</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { reportService, cittadinoService } from "@/_services"
import { mapState, mapActions } from "vuex"
import OtpValidation from "@/components/utilities/OtpValidation.vue"
export default {
  name: "ClinicalReportGenerator",
  props: ["reportType", "reportId", "subjectable", "buttonSize"],
  components: { OtpValidation },
  data() {
    return {
      form: {
        note_referto: null,
        referto_type: this.reportType,
        referto_id: this.reportId,
        subjectable_id: null,
        subjectable_type: null
      },
      showValidation: false
    }
  },
  computed: {
    btnSize() {
      if (this.buttonSize) {
        return this.buttonSize
      }
      return "md"
    },
    needsVerification() {
      if (this.company) {
        return this.company.clinical_report_verification === 1
      }
      return false
    },
    ...mapState("company", ["company"]),
    ...mapState("utente", ["status", "user"])
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    onSubmit() {
      if (this.needsVerification) {
        this.showValidation = true
      } else {
        this.storeClinicalReport()
      }
    },
    resetAndClose() {
      this.form.note_referto = null
      this.$bvModal.hide("refertoEditor" + this.reportId)
    },
    storeClinicalReport() {
      const self = this
      reportService
        .storeClinicalReport(this.form)
        .then(function (res) {
          // console.log(res);
          self.successAlert("Report emesso correttamente")
          self.downloadResource(res.data.data.resource)
          self.resetAndClose()
          self.showValidation = false
        })
        .catch(function (err) {
          console.log(err)
          self.errorAlert("Errore elaborazione report")
          // self.resetAndClose();
        })
    },
    downloadResource(resource) {
      const fName = resource.name
      const self = this
      cittadinoService
        .downloadResource(resource.hashid)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".pdf"
          link.click()
          self.showValidation = false
        })
        .catch(function (err) {
          console.log(err)
          self.errorAlert("Non è stato possibile scaricare la risorsa")
        })
    },
    handleOtpValidation(verified) {
      if (verified) {
        this.storeClinicalReport()
      } else {
        this.showValidation = false
      }
    }
  },
  created() {
    if (this.subjectable) {
      this.form.subjectable_id = this.subjectable.id
      this.form.subjectable_type = this.subjectable.type
    }
  }
}
</script>
