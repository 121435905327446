<template>
  <b-row>
    <b-col class="py-1 col-12 px-0">
      <!-- <span class="">Prestazione aperta al pubblico</span> -->
      <b-form-checkbox
        class="fs-5"
        name="servicestatus"
        :value="0"
        :unchecked-value="1"
        v-model="formData.form.private"
        switch
        variant="secondary bg-secondary"
        size="lg"
        @change="handleChange"
      >
        Prestazione {{ statoservizio === 0 ? "" : "non " }}aperta al pubblico
      </b-form-checkbox>
    </b-col>
    <b-modal
      centered
      hide-footer
      ref="modal-confirmupdate"
      title="Conferma Operazione"
      header-text-variant="text-center"
      content-class="text-center"
      @hidden="cancelOp"
    >
      <p class="mb-4 text-center">
        Sei sicuro di voler
        {{ formData.form.private ? "chiudere" : "aprire" }} al pubblico
        {{ servicename }}?
      </p>
      <div class="text-right">
        <b-button type="button" variant="popup-button mr-3" @click="cancelOp"
          >Annulla</b-button
        ><b-button variant="popup-button" type="button" @click="submitChanges"
          >Conferma
        </b-button>
      </div>
    </b-modal>
  </b-row>
</template>
<script>
export default {
  name: "ServicePrivacy",
  props: ["servicename", "statoservizio"],
  computed: {},
  data() {
    return {
      serviceStatus: null,
      serviceToUpdate: null,
      formData: {
        form: {
          private: this.statoservizio,
        },
        service_name: this.servicename,
        service_hash: null,
      },
    };
  },
  methods: {
    handleChange() {
      // console.log("form changed", this.formData.form);
      this.$refs["modal-confirmupdate"].show();
    },
    submitChanges() {
      if (this.formData) {
        var data = this.formData;
        this.$emit("store-service-status", data);
      }
      this.$refs["modal-confirmupdate"].hide();
    },
    cancelOp() {
      this.$refs["modal-confirmupdate"].hide();
      this.formData.form.private = this.statoservizio;
    },
  },
  watch: {
    statoservizio(val) {
      if (val) {
        // console.log("service stato", val);
        this.serviceStatus = val;
        this.formData.form.private = val;
      }
    },
    servicename(val) {
      if (val) {
        // console.log("service name val", val);
        this.serviceToUpdate = val;
      }
    },
  },
  // created() {
  //   console.log("servicename", this.servicename);
  //   console.log("statoservizio", this.statoservizio);
  // },
  mounted() {},
};
</script>