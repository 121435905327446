<template>
  <div class="col-12 px-0 px-md-4">
    <h4 class="text-prim-grad-1 text-left pb-2 mb-4">Raccolte</h4>

    <transition-group name="list" mode="out-in" appear>
      <div v-if="activePanel === 'attivita'" key="attivita">
        <transition name="slideside">
          <p
            v-if="!showDetails"
            class="text-left py-3 mb-4 bg-whitesmoke px-3 general_border d-flex align-items-center"
          >
            <b-icon
              icon="info-circle"
              variant="primary"
              font-scale="1.5"
              class="mr-2"
            ></b-icon>
            Clicca su una riga per vederne i dettagli. Per creare una nuova
            raccolta clicca su "nuova raccolta".
          </p>
        </transition>

        <div class="d-flex justify-content-between">
          <b-button
            variant="secondary  border-white"
            class="ml-0 mr-auto"
            @click="$refs['modal-newactivity'].show()"
          >
            <b-icon icon="plus" class=""></b-icon> nuova raccolta
          </b-button>

          <b-input-group size="sm" class="mt-2 col-lg-4 col-sm-6 ml-auto mr-0">
            <b-form-input
              v-capitalize-first
              class="align-items-center align-content-center"
              v-model="filterSetted.search"
              type="search"
              placeholder="Cerca raccolta.."
              @input="fetchData"
            >
            </b-form-input>

            <b-input-group-append>
              <span class="mx-2">
                <b-icon icon="search"></b-icon>
              </span>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="mt-5 puntofarmaCard p-4">
          <b-table
            borderless
            hover
            responsive
            :fields="fields"
            :items="activities"
            :small="$root.isMobile"
            selectable
            select-mode="single"
            @row-selected="onRowSelected"
            ref="actable"
            class="text-left"
          >
            <template #cell(name)="row">
              <span class="text-justify text-capitalize">{{
                row.item.name
              }}</span>
            </template>

            <template #cell(description)="row">
              <span class="text-justify">{{ row.item.description }}</span>
            </template>

            <template #cell(created_by)="row">
              <span class="text-justify" v-if="row.item.created_by == null"
                >admin</span
              >
              <router-link
                v-else
                :to="{
                  name: 'dettaglio azienda',
                  params: { company: row.item.created_by }
                }"
                class="text-secondary"
                v-b-tooltip.hover
                title="vai a panoramica"
                >{{ row.item.creator_company }}
              </router-link>
            </template>
            <template #cell(tot_surveys)="row">
              <span class="text-justify"
                >creati {{ row.item.tot_surveys }} <br /></span
              ><span class="text-justify">somm. {{ row.item.filled }}</span>
            </template>
            <template #cell(active)="row">
              <b-form-checkbox
                v-model="row.item.enabled"
                name="check-button"
                :state="row.item.enabled == 1"
                :value="1"
                :unchecked-value="0"
                switch
                @change="selectChangeStatus(row)"
              >
              </b-form-checkbox>
            </template>
            <!-- <template #cell(azioni)="row">
              <b-button
                variant="info info_gradient   px-3"
                size="sm"
                @click="$refs.actable.selectRow(row.index)"
                >Dettaglio</b-button
              >
            </template> -->
          </b-table>
          <p v-if="activities && activities.length == 0">
            Nessuna attività qui
          </p>
          <b-modal
            ref="modal-newactivity"
            title="Crea Nuova Raccolta"
            size="lg"
            centered
            hide-footer
          >
            <NuovaAttivita
              v-on:activity-created="handleNewActivity($event)"
              class="px-4 py-3"
            />
          </b-modal>
          <b-modal
            ref="modal-statusconfirm"
            title="Conferma operazione"
            size="lg"
            hide-footer
            centered
            @hidden="cancelOp"
          >
            <div v-if="selectedItem !== null">
              <h5 class="">
                Sei sicuro di voler
                {{
                  activities[selectedItem].enabled === 1
                    ? "attivare "
                    : "disattivare "
                }}
                "{{ activities[selectedItem].name }}" ?
              </h5>
              <p v-if="activities[selectedItem].enabled === 1">
                Attivando l'attività sarà disponibile per le aziende a cui è
                stata assegnata.
              </p>
              <p v-else>
                Disattivando l'attività la stessa non sarà più disponibile per
                le aziende a cui era stata assegnata.
              </p>
              <div class="mt-4 text-right">
                <b-button class="m-1 popup-button" @click="cancelOp"
                  >No, annulla</b-button
                >
                <b-button class="m-1 popup-button" @click="changeStatus"
                  >Sì, procedi</b-button
                >
              </div>
            </div>
          </b-modal>
        </div>
        <div v-if="showDetails">
          <ActivityDetails
            v-if="selectedActivity"
            :activity="selectedActivity"
            :asAdmin="true"
          />
          <span ref="activitydetail"></span>
          <div class="text-left pb-4">
            <b-button @click="showAbilitate" variant="secondary "
              >Vedi elenco abilitate</b-button
            >
            <b-modal
              id="modal-comp-list"
              :title="'Elenco Abilitate ' + selectedActivity.name"
              hide-footer
              size="xl"
            >
              <p
                class="text-left text-capitalize"
                v-for="(comp, ind) in abilitate"
                :key="ind"
              >
                <strong>{{ ind + 1 }}. {{ comp.denominazione }}</strong
                >, {{ comp.regione }}, {{ comp.provincia }}, {{ comp.comune }}
              </p>
            </b-modal>
          </div>
        </div>
      </div>
      <!-- <div v-if="activePanel === 'questionari'" key="questionari">
        <h6
          class="
            text-prim-grad-1
            
            text-left
            pb-1
            border-bottom border-line-grey
          "
        >
          Questionari
        </h6>
        <p class="text-justify text-medium-grey ">
          Clicca su una riga per vederne i dettagli. Per creare un nuovo
          questionario clicca su "nuovo questionario".
        </p>
         <AdminQuestionari /> 
      </div> -->
    </transition-group>
  </div>
</template>
<script>
import { supportService, adminService } from "@/_services"
import { mapState, mapActions } from "vuex"
import NuovaAttivita from "@/components/admin/attivita/NuovaAttivita.vue"
import AdminQuestionari from "@/components/admin/attivita/sections/AdminQuestionari.vue"
import ActivityDetails from "@/components/attivita/ActivityDetails.vue"
export default {
  name: "AdminHomeAttivita",
  components: { NuovaAttivita, AdminQuestionari, ActivityDetails },
  data() {
    return {
      filterSetted: { search: null },
      fields: [
        { key: "name", label: "Nome" },
        { key: "description", label: "Descrizione" },
        { key: "created_by", label: "Creato Da" },
        { key: "tot_surveys", label: "Questionari" },
        // { key: "data", label: "Data", sortable: true },
        { key: "active", label: "Stato" }
        // { key: "azioni", label: "" },
      ],
      activities: null,
      activePanel: "attivita",
      selectedActivity: null,
      showDetails: false,
      selectedItem: null,
      abilitate: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    })
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      var self = this
      supportService
        .getActivities(this.filterSetted)
        .then(function (res) {
          self.activities = res.data.data
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le attività")
          // console.log(err);
        })
      //
    },
    handleSearch() {
      if (this.filterSetted.search && this.filterSetted.search.length >= 3) {
        this.fetchData()
      }
    },
    handleNewActivity(resp) {
      this.$refs["modal-newactivity"].hide()
      window.scrollTo(0, 0)
      if (resp == true) {
        this.successAlert("Attività creata con successo!")
        this.fetchData()
      } else {
        this.errorAlert("Non è stato possibile creare l'attività")
      }
    },
    selectChangeStatus(row) {
      console.log(row)
      this.selectedItem = row.index
      this.$refs["modal-statusconfirm"].show()
    },
    cancelOp() {
      this.$refs["modal-statusconfirm"].hide()
      this.fetchData()
    },
    changeStatus() {
      var formData = {
        hash: this.activities[this.selectedItem].hashid,
        form: {
          enabled: this.activities[this.selectedItem].enabled
        }
      }
      var self = this
      adminService
        .updateActivityStatus(formData)
        .then(function (res) {
          self.$refs["modal-statusconfirm"].hide()
          window.scrollTo(0, 0)
          self.successAlert("Stato attività aggiornato con successo!")
          self.fetchData()
        })
        .catch(function (err) {
          console.log(err)
          self.$refs["modal-statusconfirm"].hide()
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile aggiornare lo stato attività")
          self.fetchData()
        })
    },
    onRowSelected(items) {
      this.selectedActivity = items[0]
      this.showDetails = true
      this.$nextTick(function () {
        this.$refs["activitydetail"].scrollIntoView()
      })
    },
    showAbilitate() {
      this.abilitate = null
      this.getAbilitate()
      this.$bvModal.show("modal-comp-list")
    },
    getAbilitate() {
      // console.log(this.selectedActivity)
      var self = this
      var dataFilter = {
        hash: this.selectedActivity.hashid
      }
      adminService
        .getAbilitateForActivity(dataFilter)
        .then(function (res) {
          self.abilitate = res.data.data
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le attività")
          console.log(err)
        })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
