<template>
  <b-row>
    <b-col>
      <b-form-group
        label="Cerca la visita o la prestazione di cui hai bisogno"
        label-class="text-secondary text-left"
      >
        <b-input-group>
          <b-form-input
            v-model="filter.name"
            placeholder="Inserisci il nome della prestazione"
            @input="
              fetchAvailableHS()
              pickedHS = null
            "
            @keyup.delete="
              fetchAvailableHS()
              pickedHS = null
            "
          ></b-form-input>
        </b-input-group>
        <b-list-group class="list-container" v-if="!pickedHS">
          <b-list-group-item
            v-for="(item, index) in mappedList"
            :key="item"
            @click="selectPrestazione(item)"
            :class="{
              'bg-secondary text-white': index === 0 && !goneOverOthers
            }"
            @mouseover="
              index === 0 ? (goneOverOthers = false) : (goneOverOthers = true)
            "
            class="list-group-item-custom py-2"
          >
            {{ item }}
          </b-list-group-item>
        </b-list-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import { reservationService } from "../../../_services"

export default {
  name: "PrestazioneSelection",
  props: [
    "company",
    "preselected",
    "hidetitles",
    "donotexclude",
    "startingValue"
  ],
  data() {
    return {
      list: null,
      pickedHS: null,
      filter: {
        name: ""
      },
      goneOverOthers: false
    }
  },
  computed: {
    mappedList() {
      if (this.list) {
        let listWithDupes = this.list.map((el) => {
          if (typeof el === "string") {
            return el
          } else {
            return el.name
          }
        })
        return listWithDupes.filter(
          (item, index) => listWithDupes.indexOf(item) === index
        )
      } else {
        return []
      }
    }
  },
  methods: {
    fetchAvailableHS() {
      if (this.filter.name.length < 2) {
        this.list = []
        return
      }
      const self = this
      reservationService
        .getPlatformHealthServices(this.filter)
        .then(function (res) {
          self.list = res.data.data
          //filter
        })
        .catch(function (err) {
          console.log(err)
        })
    },
    selectPrestazione(item) {
      this.pickedHS = item
      this.filter.name = item
      this.$emit("selected-prestazione", item)
    }
  },
  created() {
    this.startingValue ? (this.filter.name = this.startingValue) : null
    this.fetchAvailableHS()
    // this.$emit("selected-prestazione", this.pickedHS)
  }
}
</script>

<style lang="scss">
.list-container {
  max-height: 208px;
  overflow-y: auto;
}

.list-group-item-custom {
  cursor: pointer;
}

.list-group-item-custom:hover {
  background-color: $secondary;
  color: white;
}
</style>
