<template>
  <div>
    <b-row align-v="stretch">
      <b-col class="px-0 col-12 col-lgx-6">
        <div class="mr-lgx-2 d-flex flex-column h-100">
          <h3 class="mt-2 pt-4 text-left">
            <b-icon
              icon="envelope"
              variant="secondary"
              font-scale="1.2"
              class="mr-1"
            ></b-icon>
            Dati di Accesso
          </h3>
          <section
            class="bg-white general_border puntofarmaCard p-2 p-md-3 p-lg-4 h-100"
          >
            <p
              v-if="user.temporary_password"
              class="text-left mb-1 text-danger"
            >
              Modifica la tua password temporanea
            </p>
            <p v-if="user.expired_password" class="text-left mb-1 text-danger">
              La tua password è scaduta: aggiornala per proseguire.
            </p>
            <div ref="changePasswordDiv" class="col-12 d-flex flex-wrap px-0">
              <!-- <b-form-group
                label="Email"
                label-for="email"
                class="text-left fs-6 mx-auto col-12 col-lg-6"
              >
                <b-form-input
                  class="my-3 "
                  name="email"
                  type="text"
                  readonly
                  v-model="user.email"
                ></b-form-input>
              </b-form-group> -->
              <b-form-group
                label="Username"
                label-for="email"
                class="text-left mx-auto col-12 col-lg-6"
              >
                <b-form-input
                  class="my-3"
                  name="email"
                  type="text"
                  readonly
                  v-model="user.username"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Password"
                label-for="password"
                class="text-left mx-auto col-12 col-lg-6"
                :class="
                  user.temporary_password || user.expired_password
                    ? 'text-danger'
                    : ''
                "
              >
                <b-button
                  type="submit"
                  variant="secondary mt-2 "
                  @click="$refs['modal-pswchange'].show()"
                  >Cambia Password</b-button
                >
                <!-- <b-form-input
            class="my-3 "
            name="password"
            type="password"
            placeholder="password..."
            v-model="password"
            readonly
          ></b-form-input> -->
              </b-form-group>
            </div>
          </section>
        </div>
      </b-col>
      <b-col class="px-0 col-12 col-lgx-6">
        <div class="ml-lgx-2">
          <h3 class="mt-2 pt-4 text-left">
            <b-icon
              icon="clipboard-check"
              variant="secondary"
              font-scale="1.2"
              class="mr-1"
            ></b-icon>
            Termini e Condizioni e Informativa Privacy
          </h3>
          <section
            class="bg-white general_border puntofarmaCard p-2 p-md-3 p-lg-4 mt-2"
          >
            <!-- <div
              v-if="user.type === 1"
              class="col-12 d-flex flex-wrap general_border"
            >
              <b-col>
                <p class="text-left">
                  <b-icon icon="check-circle-fill" variant="success"></b-icon>
                  Hai accettato le
                  <a
                    target="_blank"
                    class="text-underlined point"
                    :href="infoLinks.operatore"
                  >
                    <u>Condizioni generali di contratto</u></a
                  >
                  e
                  <a
                    target="_blank"
                    class="text-underlined point"
                    href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
                  >
                    <u>Informativa Privacy</u></a
                  >
                </p>
              </b-col>
              <b-col>
                <p class="text-left">
                  <b-icon icon="check-circle-fill" variant="success"></b-icon>
                  Hai accettato espressamente, ai sensi dell’articolo 1341 e ss
                  c.c. , le previsioni di cui agli articoli 8, 9, 10, 17, 18 del
                  <a
                    target="_blank"
                    class="text-underlined point"
                    :href="infoLinks.operatore"
                  >
                    <u> Contratto</u></a
                  >
                </p>
              </b-col>
            </div>
            <div
              v-else-if="user.type === 0"
              class="col-12 d-flex flex-wrap general_border py-4"
            > -->
            <div class="col-12 d-flex flex-wrap general_border py-4">
              <b-col>
                <p class="text-left">
                  <span v-if="user.data_consense">
                    <b-icon icon="check-circle-fill" variant="success"></b-icon>
                    Hai accettato
                  </span>
                  <span v-else>
                    <b-icon icon="x-circle-fill" variant="danger"></b-icon>
                    Hai revocato il consenso a
                  </span>
                  <a
                    target="_blank"
                    class="text-underlined point"
                    :href="infoLinks.paziente"
                  >
                    <u>Termini e Condizioni d'uso del servizio</u></a
                  >
                  <b-button
                    size="sm"
                    class="ml-2"
                    @click="revocaConsensoPer('data_consense')"
                    >Modifica</b-button
                  >
                </p>
              </b-col>
              <b-col>
                <p class="text-left">
                  <span v-if="user.charge_consense">
                    <b-icon icon="check-circle-fill" variant="success"></b-icon>
                    Hai accettato
                  </span>
                  <span v-else>
                    <b-icon icon="x-circle-fill" variant="danger"></b-icon>
                    Hai revocato il consenso a
                  </span>
                  <a
                    target="_blank"
                    class="text-underlined point"
                    href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
                  >
                    <u>Informativa Privacy</u></a
                  >
                  <b-button
                    size="sm"
                    class="ml-2"
                    @click="revocaConsensoPer('charge_consense')"
                    >Modifica</b-button
                  >
                </p>
              </b-col>
            </div>
            <b-row class="px-3">
              <b-col>
                <p class="text-left">
                  <span v-if="user.share_consense">
                    <b-icon icon="check-circle-fill" variant="success"></b-icon>
                    Hai accettato espressamente, ai sensi dell’articolo 1341 e
                    ss c.c. , le previsioni di cui agli articoli 2, 7, 8, 10, 12
                    dei presenti del
                  </span>
                  <span v-else>
                    <b-icon icon="x-circle-fill" variant="danger"></b-icon>
                    Hai revocato il consenso agli articoli 2, 7, 8, 10, 12 dei
                    presenti del
                  </span>
                  <a
                    target="_blank"
                    class="text-underlined point"
                    :href="infoLinks.paziente"
                  >
                    <u>Termini e Condizioni d'uso</u></a
                  >
                  <b-button
                    size="sm"
                    class="ml-2"
                    @click="revocaConsensoPer('share_consense')"
                    >Modifica</b-button
                  >
                </p>
              </b-col>
              <b-col>
                <p class="text-left mb-0">
                  <template v-if="user.marketing_consense">
                    <b-icon icon="check-circle-fill" variant="success"></b-icon>
                    Hai acconsentito alla ricezione di comunicazioni commerciali
                    per finalità promozionali come da
                    <a
                      target="_blank"
                      class="text-underlined point"
                      href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
                    >
                      <u>Informativa Privacy</u></a
                    >
                  </template>
                  <template v-else>
                    <b-icon icon="x-circle-fill" variant="danger"></b-icon>
                    Non hai acconsentito alla ricezione di comunicazioni
                    commerciali per finalità promozionali come da
                    <a
                      target="_blank"
                      class="text-underlined point"
                      href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
                    >
                      <u>Informativa Privacy</u></a
                    >
                  </template>
                  <b-button size="sm" class="ml-2" @click="startConsenseEdit"
                    >Modifica</b-button
                  >
                </p>
              </b-col>
            </b-row>
          </section>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="edit_marketing_consense"
      title="Consenso comunicazioni commerciali"
      size="lg"
      hide-footer
      centered
    >
      <b-form
        id="change_marketing_permission"
        @submit.prevent="onConsenseSubmit"
        class="px-4 py-2"
      >
        <b-row cols="1" class="text-left">
          <b-form-checkbox
            class="text-font-light-grey"
            name="marketing_consense"
            :value="true"
            :unchecked-value="false"
            v-model="consenseForm.marketing_consense"
          >
            <span class="text-dark"
              >Acconsento alla ricezione di comunicazioni commerciali per
              finalità promozionali come da
              <a
                target="_blank"
                class="text-underlined point"
                href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
              >
                <u>Informativa Privacy</u></a
              >
            </span>
          </b-form-checkbox>
          <b-form-checkbox
            class="text-font-light-grey"
            name="marketing_consense"
            :value="false"
            :unchecked-value="false"
            v-model="consenseForm.marketing_consense"
          >
            Non accetto
          </b-form-checkbox>
        </b-row>
        <b-row class="justify-content-end mt-4 px-4">
          <b-button
            class="btn-popup-button ml-2 mr-0"
            @click="$bvModal.hide('edit_marketing_consense')"
            >Annulla</b-button
          >
          <b-button class="btn-popup-button ml-2 mr-0" type="submit"
            >Salva modifiche</b-button
          >
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      ref="modal-pswchange"
      title="Modifica la tua  password"
      size="lg"
      hide-footer
      centered
    >
      <b-form @submit.prevent="submitPswChange">
        <div class="col-12 d-flex flex-wrap">
          <b-form-group
            label="Password Attuale*"
            label-for="oldpsw"
            class="text-left mx-auto col-12"
          >
            <b-form-input
              v-model="form.old_password"
              placeholder="Password attuale..."
              class="my-3"
              name="oldpsw"
              :type="oldPasswordClear ? 'text' : 'password'"
              required
            ></b-form-input>
            <b-icon
              :icon="oldPasswordClear ? 'eye' : 'eye-slash'"
              font-scale="1.3"
              class="eye_icon point"
              @click="oldPasswordClear = !oldPasswordClear"
            ></b-icon>
          </b-form-group>
        </div>
        <div class="col-12 d-flex flex-wrap">
          <b-form-group
            label="Password*"
            label-for="password"
            class="text-left mx-auto col-12"
          >
            <b-form-input
              class="my-3"
              name="password"
              :type="passwordClear ? 'text' : 'password'"
              v-model="form.password"
              placeholder="Password..."
              :state="form.password == null ? null : validatePassword"
              required
            >
            </b-form-input>

            <b-icon
              :icon="passwordClear ? 'eye' : 'eye-slash'"
              font-scale="1.3"
              class="eye_icon point"
              @click="passwordClear = !passwordClear"
            ></b-icon>
          </b-form-group>
          <b-form-group
            label="Conferma password*"
            label-for="password"
            class="text-left mx-auto"
            :class="$root.isMobile ? '' : 'col'"
          >
            <b-form-input
              class="my-3"
              name="password_confirmation"
              :type="passwordConfirmationClear ? 'text' : 'password'"
              v-model="form.password_confirmation"
              placeholder="Conferma password..."
              :state="form.password == null ? null : validateConfirm"
              required
            ></b-form-input>

            <b-icon
              :icon="passwordConfirmationClear ? 'eye' : 'eye-slash'"
              font-scale="1.3"
              class="eye_icon point"
              @click="passwordConfirmationClear = !passwordConfirmationClear"
            ></b-icon>
          </b-form-group>
          <p class="text-left text-medium-grey fs-6">
            La password non può includere il nome né il cognome e deve contenere
            almeno 8 caratteri, di cui almeno un numero, una maiuscola, una
            minuscola ed un
            <span
              v-b-tooltip
              title="Devi usare almeno un caratterere tra ! @ # $ % ^ & * ) ( + = . _ -"
              >carattere speciale</span
            >. La nuova password deve essere diversa da quella attuale.
          </p>
        </div>
        <div
          class="col-12 text-center d-flex justify-content-end my-3"
          :class="$root.isMobile ? 'flex-wrap' : ''"
        >
          <b-button
            type="button"
            class="mr-3"
            variant="popup-button"
            @click="resetForm"
            >Annulla Operazione</b-button
          >
          <b-button
            type="submit"
            variant="popup-button"
            :disabled="!enableSecondBtn"
          >
            Conferma Modifica</b-button
          >
        </div>
      </b-form>
    </b-modal>

    <b-modal
      id="revoca_permesso"
      centered
      :title="revoca[revocaConsenso]"
      ok-title="Sì, revoca permesso"
      ok-variant="popup-button"
      cancel-title="No, annulla"
      cancel-variant="popup-button"
      footer-border-variant="white"
      @ok="submitRevoca"
      @cancel="$bvModal.hide('revoca_permesso')"
    >
      <h6>
        Il consenso è necessario per la fruizione della piattaforma. <br />
        Revocando il consenso, non potrai più utilizzare le funzionalità della
        piattaforma.
      </h6>
      <p>Desideri procedere con la revoca del consenso?</p>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { cittadinoService } from "@/_services";

export default {
  name: "AccessoEPermessi",
  components: {},
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    validatePassword() {
      if (this.form.password) {
        if (
          this.form.old_password &&
          this.form.old_password === this.form.password
        ) {
          return false;
        }
        this.has_minimum_lenth = this.form.password.length > 8;
        this.has_number = /\d/.test(this.form.password);
        this.has_lowercase = /[a-z]/.test(this.form.password);
        this.has_uppercase = /[A-Z]/.test(this.form.password);
        this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.form.password);
        // var regName = new RegExp(this.user.anagrafica.name);
        // var regSurname = new RegExp(this.user.anagrafica.surname);
        // this.has_name = !regName.test(this.form.password);
        // this.has_surname = !regSurname.test(this.form.password);
        const regName = new RegExp(this.user.anagrafica.name.toLowerCase());
        const regSurname = new RegExp(
          this.user.anagrafica.surname.toLowerCase()
        );
        this.has_name = !regName.test(this.form.password.toLowerCase());
        this.has_surname = !regSurname.test(this.form.password.toLowerCase());
        return (
          this.has_minimum_lenth &&
          this.has_number &&
          this.has_lowercase &&
          this.has_uppercase &&
          this.has_name &&
          this.has_surname &&
          this.has_special
        );
      }
      return false;
    },
    validateConfirm() {
      if (this.form.password) {
        return this.form.password_confirmation === this.form.password;
      }
      return false;
    },
    enableSecondBtn() {
      if (this.form.old_password !== null) {
        if (this.validatePassword && this.validateConfirm) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  data() {
    return {
      password: "password...",
      form: {
        old_password: null,
        password: null,
        password_confirmation: null,
      },
      infoLinks: {
        paziente: "https://concura.online/wp-content/uploads/2024/07/TC.pdf",
        operatore: "https://concura.online/wp-content/uploads/2024/07/TC.pdf",
      },
      privacyPolicyLinks: {
        paziente: "https://concura.online/wp-content/uploads/2024/07/PP.pdf",
        operatore: "https://concura.online/wp-content/uploads/2024/07/PP.pdf",
      },
      consenseForm: {
        marketing_consense: false,
      },
      oldPasswordClear: false,
      passwordClear: false,
      passwordConfirmationClear: false,
      revocaConsenso: null,
      revoca: {
        data_consense:
          "Vuoi revocare il consenso a Termini e Condizioni d'uso del servizio?",
        share_consense:
          "Vuoi revocare il consenso agli articoli 2, 7, 8, 10, 12 dei presenti del Termini e Condizioni d'uso",
        charge_consense: "Vuoi revocare il consenso per l'Informativa Privacy?",
      },
    };
  },
  methods: {
    ...mapActions("utente", [
      "me",
      "changePassword",
      "setAnagrafica",
      "setTemporaryPasswordFalse",
      "setExpiredPasswordFalse",
    ]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    resetForm() {
      this.$refs["modal-pswchange"].hide();
      this.form = {
        old_password: null,
        password: null,
        password_confirmation: null,
      };
    },
    submitRevoca() {
      var form = {};
      form[this.revocaConsenso] = false;
      const self = this;
      cittadinoService
        .updateConsense(form)
        .then(function (res) {
          self.me();
          self.$bvModal.hide("revoca_permesso");
          self.successAlert("Modifiche effettuate con successo");
          self.waitAndRedirect();
        })
        .catch(function (err) {
          self.$bvModal.hide("revoca_permesso");
          self.errorAlert("Non è stato possibile effettuare la modifica");
        });
    },
    waitAndRedirect() {
      const self = this;
      window.setTimeout(function () {
        self.$router.push({ name: "Home" });
      }, 3000);
    },
    submitPswChange() {
      const self = this;
      this.changePassword(this.form).then(function () {
        window.scrollTo(0, 0);
        self.resetForm();
        self.setTemporaryPasswordFalse();
        self.setExpiredPasswordFalse();
        self.me();
      });
    },
    revocaConsensoPer(consenso) {
      this.revocaConsenso = consenso;
      this.$bvModal.show("revoca_permesso");
    },
    startConsenseEdit() {
      this.consenseForm.marketing_consense = this.user.marketing_consense;
      this.$bvModal.show("edit_marketing_consense");
    },
    onConsenseSubmit() {
      const self = this;
      cittadinoService
        .updateConsense(this.consenseForm)
        .then(function (res) {
          self.me();
          self.$bvModal.hide("edit_marketing_consense");
          self.successAlert("Modifiche effettuate con successo");
        })
        .catch(function (err) {
          self.$bvModal.hide("edit_marketing_consense");
          self.errorAlert("Non è stato possibile effettuare la modifica");
        });
    },
  },
  watch: {},
  created() {
    this.me();
    if (this.$route.query.scope === "temporary") {
      this.$nextTick(function () {
        this.$refs["changePasswordDiv"].scrollIntoView();
        this.infoAlert("Devi ancora modificare la tua password temporanea");
      });
    }
    if (this.$route.query.scope === "expired") {
      this.$nextTick(function () {
        this.$refs["changePasswordDiv"].scrollIntoView();
        this.infoAlert("La tua password è scaduta e deve essere cambiata");
      });
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
input.form-control:read-only {
  border-bottom: 1px solid darkgrey;
  border-radius: 0px !important;
  box-shadow: none !important;
  color: $body-color;
}

.eye_icon {
  position: absolute;
  right: 53px;
  top: 3.3rem;
  color: #9c9c9c;
}
</style>
