<template>
  <div  v-if="user && user.permessi.includes('gestire attività e questionari')" >
    <div class="d-flex justify-content-between">
      <router-link class=" text-secondary no__hover" :to="{ name: 'attivita',params:{section:'questionari'} }">
        <b-icon icon="arrow-left-circle-fill" variant="secondary"></b-icon>
        Questionari
      </router-link>
    </div>
    <div v-if="!showOk" class="px-4">
      <EditQuestionario v-if="survey" :prevSurvey="survey" v-on:send-questionario="storeNew($event)" />
    </div>
    <div v-else>
      <h2 class="">Operazione completata.</h2>
    </div>
  </div>
  <div v-else>
    <h4>Funzionalità non abilitata per questa utenza.</h4>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import EditQuestionario from "@/components/attivita/EditQuestionario.vue";
export default {
  name: "ModificaQuestionario",
  components: { EditQuestionario },
  data() {
    return {
      survey: null,
      showOk: false,
      // noActivities: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchSurvey() {
      var self = this;
      supportService
        .getSurveyById(this.$route.params.quest)
        .then(function (res) {
          self.survey = res.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare il questionario");
          // self.noActivities = true;
          // console.log(err);
        });
    },
    storeNew(data) {
      // console.log("DATA", data);
      var dataToSend = { hash: this.survey.hashid, form: data };
      var self = this;
      supportService
        .editQuestionario(dataToSend)
        .then(function (res) {
          // console.log(res);
          self.successAlert("Questionario modificato correttamente!");
          self.showOk = true;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile creare il nuovo questionario");
          // console.log(err);
        });
    },
  },
  created() {
    if (this.$route.params.quest) {
      this.fetchSurvey();
    }
  },
};
</script>