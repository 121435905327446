<template>
  <b-row class="calendar" align-v="stretch">
    <b-col cols="12" class="px-0">
      <b-container
        fluid
        class="bg-secondary general_border px-1 pt-3 pb-4 px-md-4"
      >
        <div class="month-header">
          <span class="pb-3" id="preferenze-picker-calendar-content">
            <span
              @click="navigateMonth(-1)"
              :style="{ visibility: canNavigateBack ? 'visible' : 'hidden' }"
              class="point"
              style="font-size: 2rem"
            >
              <
            </span>
            <span class="mx-5 font-weight-bolder text-uppercase">
              {{ formattedCurrentMonth }}
            </span>
            <span
              @click="navigateMonth(1)"
              class="point"
              style="font-size: 2rem"
            >
              >
            </span>
          </span>
        </div>
        <div class="weeks-container mt-3">
          <!-- Days of the week header -->
          <div class="week days-header text-white pt-2">
            <div
              v-for="(day, index) in daysOfWeek"
              :key="'header-' + index"
              class="day-header"
            >
              {{ day }}
            </div>
          </div>
          <div
            v-for="(week, weekIndex) in weeksOfMonth"
            :key="'week-' + weekIndex"
            class="week"
          >
            <div
              v-for="(day, dayIndex) in week"
              :key="'day-' + dayIndex"
              class="day point"
              :class="{
                'is-selected': isSelected(day) && dateIsInTheSameMonth(day.date),
                'is-past': day.isPast
              }"
              @click="handleDayClick(weekIndex, dayIndex)"
            >
              <div class="day-number d-flex align-items-center">
                <!-- only if same month -->
                <span 
                v-if="dateIsInTheSameMonth(day.date)"
                >
                {{ day.date | moment("D") }}

                </span>
                <b-icon
                  v-if="isSelected(day) && windowWidth >= 630 && dateIsInTheSameMonth(day.date)"
                  icon="trash"
                  class="mt-3 mr-1"
                  @click.stop="handleIconClick(day)"
                />
              </div>
            </div>
          </div>
        </div>
        <b-modal
          centered
          title="Scegli la fascia oraria che preferisci"
          id="time-slot-modal"
          @hidden="cancelSelection"
          hide-footer
        >
          <b-container class="d-flex h-100 justify-content-center" fluid>
            <section class="slot-container">
              <div class="slot-card" @click="selectTimeSlot('mattina')">
                Mattina
              </div>

              <div class="slot-card" @click="selectTimeSlot('pomeriggio')">
                Pomeriggio
              </div>

              <div class="slot-card" @click="selectTimeSlot('tutto il giorno')">
                Tutto il giorno
              </div>
            </section>
          </b-container>
        </b-modal>
      </b-container>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment"

export default {
  name: "PreferenzaCalendar",
  props: {
    lastPreferenze: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentMonth: moment().startOf("month").format("YYYY-MM"),
      selections: [],
      selectedTimeSlot: null,
      windowWidth: window.innerWidth,
      tempSelectedDay: null,
    }
  },
  computed: {
    canNavigateBack() {
      return moment(this.currentMonth).isAfter(moment(), "month")
    },
    formattedCurrentMonth() {
      return moment(this.currentMonth).format("MMMM YYYY")
    },
    weeksOfMonth() {
      let today = moment()
      let startOfMonth = moment(this.currentMonth)
      let startOfWeek = startOfMonth.clone().startOf("week")
      let endOfMonth = startOfMonth.clone().endOf("month")
      let endOfWeek = endOfMonth.clone().endOf("week")
      let weeks = []
      while (startOfWeek.isBefore(endOfWeek)) {
        let week = []
        for (let i = 0; i < 7; i++) {
          let day = startOfWeek.clone().add(i, "days")
          let dayKey = day.format("YYYY-MM-DD")
          week.push({
            date: day.format("YYYY-MM-DD"),
            isPast: day.isBefore(today, "day") || day.isAfter(endOfMonth, "day")
          })
        }
        weeks.push(week)
        startOfWeek.add(1, "week")
      }
      return weeks
    },
    daysOfWeek() {
      return this.windowWidth >= 630
        ? ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"]
        : ["L", "M", "M", "G", "V", "S", "D"]
    }
  },
  watch: {
    selections: {
      handler() {
        this.$emit("selections-changed", this.selections)
      },
      deep: true
    }
  },
  methods: {
    navigateMonth(direction) {
      this.currentMonth = moment(this.currentMonth)
        .add(direction, "months")
        .format("YYYY-MM")
    },
    isSelected(day) {
      return this.selections.some((s) => s.day === day.date)
    },
    handleDayClick(weekIndex, dayIndex) {
      let day = this.weeksOfMonth[weekIndex][dayIndex]
      if (this.windowWidth >= 630 && this.isSelected(day)) {
        return
      }
      if (!day.isPast && day.date) {
        this.tempSelectedDay = day.date
        this.$bvModal.show("time-slot-modal")
      }
    },
    handleIconClick(day) {
      this.deselectDay(day.date)
    },
    toggleSelection(day) {
      const index = this.selections.findIndex((s) => s.day === day)
      if (index >= 0) {
        this.selections.splice(index, 1)
      } else {
        this.selections.push({ day, timeSlot: this.selectedTimeSlot })
      }
    },
    deselectDay(day) {
      const index = this.selections.findIndex((s) => s.day === day)
      if (index >= 0) {
        this.selections.splice(index, 1)
      }
    },
    selectTimeSlot(slot) {
      this.selectedTimeSlot = slot
      this.$bvModal.hide("time-slot-modal")
      this.confirmSelection()
    },
    confirmSelection() {
      if (this.selectedTimeSlot && this.tempSelectedDay) {
        this.toggleSelection(this.tempSelectedDay)
        this.tempSelectedDay = null
      }
    },
    cancelSelection() {
      this.tempSelectedDay = null
    },
    dateIsInTheSameMonth(date) {
      return moment(date).format("YYYY-MM") === this.currentMonth
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth
    })

    if (
      Array.isArray(this.lastPreferenze) &&
      this.lastPreferenze &&
      this.lastPreferenze.length > 0
    ) {
      this.selections = JSON.parse(JSON.stringify(this.lastPreferenze))
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.windowWidth = window.innerWidth
    })
  }
}
</script>

<style scoped lang="scss">
.calendar {
  color: white;
  max-width: 800px;
}
.month-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.weeks-container {
  display: flex;
  flex-direction: column;
}
.week {
  display: flex;
  justify-content: space-between;
}
.day-header {
  width: 80px;
  text-align: center;
  @media (max-width: 630px) {
    width: 30px;
    font-size: 0.8rem;
  }
}
.day {
  &.is-past {
    pointer-events: none;
    .day-number {
      color: #b0c4de;
    }
  }

  &.is-selected {
    .day-number {
      background-color: white;
      color: #6c757d;
      border: 2px solid #6c757d;
    }
  }
}

.day-number {
  width: 80px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  background-color: $secondary;
  box-sizing: border-box;

  @media (max-width: 630px) {
    padding: 0;
    width: 30px;
    height: 30px;
  }
}
.is-selected .day-number {
  background-color: white;
  color: #6c757d;
  border: 2px solid #6c757d;
  @media (max-width: 630px) {
    padding: 0px 1px;
    border-radius: 50%;
  }
}
.b-icon {
  position: absolute;
  right: 3px;
  top: 3px;
  cursor: pointer;
}
#preferenze-picker-calendar-content {
  border-bottom: 1px solid white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slot-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100px;
  background-color: white;
  border-radius: $border-radius;
}

.slot-card:hover {
  background-color: $primary;
  color: white;
  cursor: pointer;
  transition: all 0.25s;
}

.slot-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  background-color: $secondary;
  align-items: center;
  min-height: 500px;
  padding: 2rem 4rem;
  border-radius: $border-radius;
  height: 100%;
  @media (max-width: 630px) {
    padding: 2rem;
  }
}
</style>
