<template>
  <b-container fluid v-if="user" class="mb-4">
    <b-row :class="buttonGroupOpen ? 'min-width-75' : ''">
      <div class="text-left mt-3" :class="buttonGroupOpen ? 'w-100' : ''">
        <b-button-group
          class=""
          :class="buttonGroupOpen ? 'w-100' : ''"
          size="sm"
        >
          <!-- <h4 class="text-left mr-4 my-auto">Servizi</h4> -->
          <b-button
            key="b-prenotazioni"
            class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'prenotazioni'"
            :variant="
              activePanel === 'prenotazioni'
                ? 'secondary'
                : 'outline-secondary '
            "
          >
            <template v-if="!buttonGroupOpen">
              <b-icon icon="app-indicator" v-if="!buttonGroupOpen"></b-icon
              ><br />
            </template>
            <span v-if="buttonGroupOpen" key="b-prenotazioni-t">
              Prenotazioni</span
            >
          </b-button>
          <b-button
            key="b-rilevazioni"
            class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'rilevazioni'"
            :variant="
              activePanel === 'rilevazioni' ? 'secondary' : 'outline-secondary '
            "
          >
            <template v-if="!buttonGroupOpen">
              <b-icon icon="bar-chart"></b-icon><br />
            </template>
            <span v-if="buttonGroupOpen" key="b-rilevazioni-t">
              Rilevazioni</span
            >
          </b-button>
          <b-button
            key="b-patologie"
            class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'patologie'"
            :variant="
              activePanel === 'patologie' ? 'secondary' : 'outline-secondary '
            "
          >
            <template v-if="!buttonGroupOpen">
              <b-icon icon="bookmark"></b-icon><br />
            </template>
            <span v-if="buttonGroupOpen" key="b-patologie-t"> Patologie</span>
          </b-button>
          <b-button
            key="b-terapie"
            class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'terapie'"
            :variant="
              activePanel === 'terapie' ? 'secondary' : 'outline-secondary '
            "
          >
            <template v-if="!buttonGroupOpen">
              <b-icon icon="bookmarks"></b-icon><br />
            </template>
            <span v-if="buttonGroupOpen" key="b-terapie-t"> Terapie</span>
          </b-button>
          <b-button
            key="b-notifications"
            class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'notifications'"
            :variant="
              activePanel === 'notifications'
                ? 'secondary'
                : 'outline-secondary '
            "
          >
            <template v-if="!buttonGroupOpen">
              <b-icon icon="bell"></b-icon><br />
            </template>
            <span v-if="buttonGroupOpen" key="b-notifications-t">
              Notifiche</span
            >
          </b-button>
          <b-button
            key="b-note"
            class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'note'"
            :variant="
              activePanel === 'note' ? 'secondary' : 'outline-secondary '
            "
          >
            <template v-if="!buttonGroupOpen">
              <b-icon icon="clipboard"></b-icon><br />
            </template>
            <span v-if="buttonGroupOpen" key="b-notes-t"> Note</span>
          </b-button>

          <template v-if="canMonitor">
            <b-button
              key="b-alertevents"
              class="text-capitalize buttonbar custom-button-group-measures"
              @click="activePanel = 'alertevents'"
              :variant="
                activePanel === 'alertevents'
                  ? 'secondary'
                  : 'outline-secondary '
              "
            >
              <template v-if="!buttonGroupOpen">
                <b-icon icon="exclamation-triangle"></b-icon><br />
              </template>
              <span v-if="buttonGroupOpen" key="b-alertevents-t"> Alert</span>
            </b-button>
            <b-button
              key="b-dispositivi"
              class="text-capitalize buttonbar custom-button-group-measures general-right-border"
              @click="activePanel = 'devices'"
              :variant="
                activePanel === 'devices' ? 'secondary' : 'outline-secondary '
              "
            >
              <template v-if="!buttonGroupOpen">
                <b-icon icon="watch"></b-icon><br />
              </template>
              <span v-if="buttonGroupOpen" key="b-alertevents-t">
                Dispositivi
              </span>
            </b-button>
          </template>
          <span key="btab_handler" class="d-flex align-content-center">
            <b-icon
              key="btab_handler-ic"
              :icon="buttonGroupOpen ? ' arrow-bar-left' : 'arrow-bar-right'"
              font-scale="1.5"
              class="ml-2 my-auto point"
              @click="buttonGroupOpen = !buttonGroupOpen"
            >
            </b-icon>
          </span>
        </b-button-group>
      </div>
      <b-col
        class="col-12 col-md-12 col-xl-9 general_border text-center pr-xl-5 pt-2"
      >
      </b-col>
      <b-col> </b-col>
    </b-row>
    <b-container
      class="p-4 col-12 mt-4 shadow_2 bg-white general__border"
      :class="{ 'd-xl-none': !blockView }"
    >
      <b-row>
        <p
          class="m-0 p-0 text-left text-secondary underlined point"
          style="font-size: 1.5rem"
          @click="
            $router.push({
              name: 'profilo paziente',
              params: {
                hash: user.hashid
              }
            })
          "
        >
          <b-avatar
            variant="white"
            :src="user.profile_pic"
            rounded="sm"
          ></b-avatar>
          <span class="ml-3 text-capitalize"
            >{{ user.anagrafica.name }} {{ user.anagrafica.surname }}</span
          >
          <span class="ml-2">
            <b-icon
              icon="file-earmark-person-fill"
              variant="secondary"
            ></b-icon>
          </span>
        </p>
        <h6
          style="font-size: 1.1rem"
          class="mt-2 ml-auto"
          v-if="userHasMonitoraggio"
        >
          <b-icon icon="patch-check-fill" variant="success"></b-icon>
          Monitoraggio attivo
        </h6>
        <!-- <b-col> -->
        <b-button
          v-if="canEditAnagrafica"
          class="ml-auto mr-0"
          variant="secondary"
          @click="$bvModal.show('anagraphic_editor')"
        >
          Modifica anagrafica
        </b-button>
        <!-- </b-col> -->
      </b-row>
      <hr />
      <b-row class="m-0 p-0 text-left">
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-secondary">Genere </span>
          <span class="ml-1">{{ user.anagrafica.gender }}</span>
        </b-col>
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-secondary">Regione </span>
          <span class="ml-1 text-capitalize">{{
            user.anagrafica.regione
          }}</span>
        </b-col>
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-secondary">Indirizzo </span>
          <span class="ml-1 text-capitalize">{{
            user.anagrafica.address ? user.anagrafica.address : "Non indicato"
          }}</span>
        </b-col>
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-secondary">Data di Nascita </span>
          <span class="ml-1" v-if="user.anagrafica.birthday"
            >{{ user.anagrafica.birthday | moment("DD/MM/YYYY") }}
          </span>
          <span class="ml-1" v-else>Non indicata</span>
        </b-col>
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-secondary">Provincia </span>
          <span class="ml-1 text-capitalize">{{
            user.anagrafica.provincia
          }}</span>
        </b-col>
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-secondary">Telefono </span>
          <span class="ml-1">{{
            user.anagrafica.tel_number
              ? user.anagrafica.tel_number
              : "Non indicato"
          }}</span>
        </b-col>
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-secondary">Codice Fiscale </span>
          <span class="ml-1 text-uppercase">{{ user.anagrafica.cf }}</span>
        </b-col>
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-secondary">Comune </span>
          <span class="ml-1 text-capitalize"
            >{{ user.anagrafica.comune }}
          </span>
        </b-col>
        <b-col v-if="user.email" class="col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-secondary">Email </span>
          <span class="ml-1">{{ user.email }}</span>
        </b-col>
        <b-col v-else class="col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-secondary">Telefono di riserva </span>
          <span class="ml-1">{{ user.anagrafica.tel_number_secondary }}</span>
        </b-col>
      </b-row>
    </b-container>
    <template v-if="!blockView">
      <b-row cols="2" class="mb-4 mt-4 general_border">
        <div class="col-12 col-md-12 col-xl-9 general_border px-0 mt-0 pt-2">
          <transition-group
            name="slideside"
            mode="out-in"
            tag="div"
            class="pr-xl-5"
          >
            <b-row v-if="activePanel === 'rilevazioni'" key="rilevazioni">
              <RilevazioniComponent
                :subject="subject"
                :ismonitor="canMonitor"
                :activesub="userHasValidSubscription"
              />
              <ScoresComponent :subject="subject" />
              <UserLastSurveys
                :subject="subject"
                :subjectAnagrafica="user?.anagrafica"
              />
            </b-row>
            <b-row v-if="activePanel === 'patologie'" key="patologie">
              <PatologieComponent :subject="subject" />
              <EsenzioniComponent :subject="subject" />
            </b-row>
            <b-row v-if="activePanel === 'terapie'" key="terapie">
              <AdherenceIndex v-if="subject" />
              <TerapieComponent :subject="subject" />
            </b-row>
            <b-row v-if="activePanel === 'prenotazioni'" key="prenotazioni">
              <PrenotazioniPaziente :subject="subject" :userinfo="user" />
              <PrenotazioniRicorrentiPaziente
                v-if="canMonitor"
                :subject="subject"
                :userinfo="user"
                :activesub="userHasValidSubscription"
              />
            </b-row>
            <b-row
              v-if="activePanel === 'notifications'"
              key="notifications"
              class="text-left"
            >
              <SettedAlerts :subject="subject" />
            </b-row>
            <b-row v-if="activePanel === 'note'" key="note" class="text-left">
              <PatientAnnotations
                :subjectHash="subject.hashid"
                :hidetop="false"
                :ismonitor="canMonitor"
              />
            </b-row>
            <b-row
              v-if="activePanel === 'alertevents'"
              key="alertevents"
              class="text-left"
            >
              <UserAlertEvents
                :subject="subject"
                :activesub="userHasValidSubscription"
              />
            </b-row>
            <b-row v-if="activePanel === 'devices'" key="devices">
              <PatientDevices
                :subjectHash="subject.hashid"
                :canMonitor="canMonitor"
              />
            </b-row>
          </transition-group>
        </div>
        <div
          class="text-left d-none d-xl-block col-xl-3 p-2 long__txt"
          style="font-size: 1.15rem; letter-spacing: 0.05rem"
        >
          <div class="shadow_2 bg-white general__border p-3">
            <b-row cols="1" class="px-1 font-size-085rem">
              <p
                style="font-size: 1.5rem"
                class="mb-0 text-secondary underlined point"
                @click="
                  $router.push({
                    name: 'profilo paziente',
                    params: {
                      hash: user.hashid
                    }
                  })
                "
              >
                <b-avatar
                  variant="white"
                  :src="user.profile_pic"
                  rounded="sm"
                ></b-avatar>
                <span class="ml-3 text-capitalize"
                  >{{ user.anagrafica.name }}
                  {{ user.anagrafica.surname }}</span
                >
                <span class="ml-2">
                  <b-icon
                    icon="file-earmark-person-fill"
                    variant="secondary"
                  ></b-icon>
                </span>
              </p>
              <h6
                style="font-size: 1.1rem"
                class="mt-2 ml-2"
                v-if="userHasMonitoraggio"
              >
                <b-icon icon="patch-check-fill" variant="success"></b-icon>
                Monitoraggio attivo
              </h6>
              <hr class="mb-3 mt-1" />
              <b-col class="px-0 mb-2">
                <span class="text-secondary">Genere <br /></span>
                <span>{{ user.anagrafica.gender }} </span>
              </b-col>
              <b-col class="px-0 mb-2">
                <span class="text-secondary">Data di nascita</span> <br />
                <span v-if="user.anagrafica.birthday"
                  >{{ user.anagrafica.birthday | moment("DD/MM/YYYY") }}
                </span>
                <span v-else>Non indicata</span>
              </b-col>
              <b-col class="px-0 mb-2">
                <span class="text-secondary">Codice Fiscale</span> <br />
                <span class="text-uppercase">{{ user.anagrafica.cf }} </span>
              </b-col>
              <b-col class="px-0 mb-2">
                <span class="text-secondary">Regione</span> <br />
                <span class="text-capitalize"
                  >{{ user.anagrafica.regione }}
                </span>
              </b-col>
              <b-col class="px-0 mb-2">
                <span class="text-secondary">Provincia</span> <br />
                <span class="text-capitalize"
                  >{{ user.anagrafica.provincia }}
                </span>
              </b-col>
              <b-col class="px-0 mb-2">
                <span class="text-secondary">Comune</span> <br />
                <span class="text-capitalize"
                  >{{ user.anagrafica.comune }}
                </span>
              </b-col>
              <b-col class="px-0 mb-2">
                <span class="text-secondary">Indirizzo</span> <br />
                <span class="text-capitalize"
                  >{{
                    user.anagrafica.address
                      ? user.anagrafica.address
                      : "Non indicato"
                  }}
                </span>
              </b-col>
              <b-col class="px-0 mb-2">
                <span class="text-secondary">Telefono</span> <br />
                <span
                  >{{
                    user.anagrafica.tel_number
                      ? user.anagrafica.tel_number
                      : "Non indicato"
                  }}
                </span>
              </b-col>
              <b-col v-if="user.email" class="px-0 mb-4">
                <span class="text-secondary">Email</span> <br />
                <span>{{ user.email }} </span>
              </b-col>
              <b-col v-else class="px-0 mb-4">
                <span class="text-secondary">Telefono di riserva</span> <br />
                <span>{{ user.anagrafica.tel_number_secondary }} </span>
              </b-col>
              <!-- <b-col class="mb-2">
            <strong>Indice Aderenza </strong><br />
            <b-iconstack font-scale="1.2">
              <b-icon stacked icon="circle-fill" variant="success"></b-icon>
              <b-icon
                stacked
                icon="circle"
                variant="success"
              ></b-icon> </b-iconstack
            ><span class="ml-2">ottimale</span><br />
            <b-iconstack font-scale="1.2">
              <b-icon stacked icon="circle-fill" variant="white"></b-icon>
              <b-icon
                stacked
                icon="circle"
                variant="warning"
              ></b-icon> </b-iconstack
            ><span class="ml-2">discreto</span><br />
            <b-iconstack font-scale="1.2">
              <b-icon stacked icon="circle-fill" variant="white"></b-icon>
              <b-icon
                stacked
                icon="circle"
                variant="danger"
              ></b-icon> </b-iconstack
            ><span class="ml-2">inadeguato</span>
          </b-col> -->
            </b-row>
          </div>
        </div>
      </b-row>
    </template>
    <div v-else class="py-4 text-center">
      <OtpValidation
        :user="user"
        :cause="1"
        :validatable="user.hashid"
        :second="doBackupNumber"
        :phoneNumber="user.anagrafica.tel_number"
        :hashId="user.hashid"
        :forceable="true"
        @otp-validated="handleOtpValidation($event)"
        @number-verified="handleNumberVerified"
      >
        <template v-slot:instruction>
          <h1>
            <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
          </h1>
          <h2>
            E' necessario verificare il numero di telefono
            {{ doBackupNumber ? " di riserva " : "" }} del paziente.
          </h2>
          <h5 class="my-3">
            Clicca su "invia codice di verifica" per inviare il codice OTP di
            verifica via SMS
          </h5>
        </template>
      </OtpValidation>
    </div>

    <b-modal
      id="anagraphic_editor"
      size="xl"
      title="Modifica anagrafica paziente"
      body-class="pt-0"
      scrollable
      hide-footer
    >
      <p class="mb-0 px-1">
        Se necessario, modifica le informazioni anagrafiche del paziente
        essenziali per abilitarne l'utenza prestando particolare attenzione ai
        dati di contatto ( email e numero di telefono), per i quali è richiesta
        la validazione.
      </p>
      <PazienteAnagraficaEditor
        :user="user"
        @cancel-operation="$bvModal.hide('anagraphic_editor')"
        @edit-done="handleEditDone"
      ></PazienteAnagraficaEditor>
    </b-modal>
  </b-container>
  <h5 v-else-if="notFound">Utente Non trovato</h5>
</template>
<script>
import { mapState, mapActions } from "vuex"
import { cittadinoService, companyService, croomService } from "@/_services"
import RilevazioniComponent from "@/components/profile/sanitario/RilevazioniComponent.vue"
import PatologieComponent from "@/components/profile/sanitario/PatologieComponent.vue"
import PatientDevices from "@/components/profile/sanitario/PatientDevices.vue"
import AdherenceIndex from "@/components/profile/sanitario/AdherenceIndex.vue"
import TerapieComponent from "@/components/pazienti/TerapieComponent.vue"
import PrenotazioniPaziente from "@/components/pazienti/PrenotazioniPaziente.vue"
import PrenotazioniRicorrentiPaziente from "@/components/pazienti/PrenotazioniRicorrentiPaziente.vue"
import SettedAlerts from "@/components/profile/sanitario/SettedAlerts.vue"
import PatientAnnotations from "@/components/profile/sanitario/PatientAnnotations.vue"
import ScoresComponent from "@/components/profile/sanitario/ScoresComponent.vue"
import UserLastSurveys from "@/components/profile/sanitario/UserLastSurveys.vue"
import UserAlertEvents from "@/components/profile/sanitario/UserAlertEvents.vue"
import OtpValidation from "@/components/utilities/OtpValidation.vue"
import EsenzioniComponent from "@/components/profile/sanitario/EsenzioniComponent.vue"
import PazienteAnagraficaEditor from "@/components/profile/PazienteAnagraficaEditor.vue"

export default {
  name: "SchedaPaziente",
  components: {
    RilevazioniComponent,
    PatologieComponent,
    TerapieComponent,
    PrenotazioniPaziente,
    AdherenceIndex,
    SettedAlerts,
    PatientAnnotations,
    ScoresComponent,
    OtpValidation,
    UserAlertEvents,
    PatientDevices,
    EsenzioniComponent,
    PazienteAnagraficaEditor,
    PrenotazioniRicorrentiPaziente,
    UserLastSurveys
  },
  data() {
    return {
      user: null,
      activePanel: "prenotazioni",
      subject: null,
      notFound: false,
      buttonGroupOpen: true,
      availableSections: [
        "prenotazioni",
        "rilevazioni",
        "patologie",
        "terapie",
        "notifications",
        "alertevents"
      ],
      blockView: false,
      doBackupNumber: false,
      canMonitor: false,
      userHasValidSubscription: false,
      userHasMonitoraggio: false
    }
  },
  computed: {
    canEditAnagrafica() {
      if (this.user && this.user.is_my_patient) {
        if (this.user.email_verified_at || this.user.number_verified_at) {
          return false
        }
        return this.user.all_consenses === false
      }
      if (this.user && this.user.created_by_me) {
        if (this.user.email_verified_at || this.user.number_verified_at) {
          return false
        }
        return this.user.all_consenses === false
      }
      return false
    },
    ...mapState({
      alert: (state) => state.alert
    })
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchUser() {
      const self = this
      cittadinoService
        .getUserById(this.$route.params.hash)
        .then(function (res) {
          self.user = res.data.data
          self.subject = {
            hashid: res.data.data.hashid,
            type: "user_in_app"
          }
          self.verifyCredentials(res.data.data)
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err)
          self.notFound = true
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le informazioni.")
        })
    },
    handleNumberVerified() {
      this.fetchUser()
    },
    verifyCredentials(user) {
      // console.log(user);
      if (!user.number_verified_at) {
        return (this.blockView = true)
      }
      // if (!user.email && !user.second_number_verified_at) {
      //   this.doBackupNumber = true;
      //   return (this.blockView = true);
      // }
      this.blockView = false
    },
    handleOtpValidation(hasVerified) {
      if (hasVerified) {
        return this.fetchUser()
      }
      this.$router.push({ name: "pazienti" })
    },
    verifyMonitoringService() {
      const self = this
      const filter = { ref: 8, client: 2 }
      companyService
        .checkIfServiceIsActive(filter)
        .then(function (response) {
          self.canMonitor = response.data.data.active
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    verifyUserSubscription() {
      const self = this
      croomService
        .getUserSubscriptionStatus(this.$route.params.hash, { all: true })
        .then(function (response) {
          self.userHasValidSubscription = response.data.data.subscription
          self.userHasMonitoraggio = response.data.data.monitoring
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    handleEditDone() {
      this.fetchUser()
      this.$bvModal.hide("anagraphic_editor")
    }
  },
  created() {
    this.fetchUser()
    this.verifyMonitoringService()
    this.verifyUserSubscription()
    // this.verifyUserSubscription(true);
    if (
      this.$route.query.section &&
      this.availableSections.includes(this.$route.query.section)
    ) {
      this.activePanel = this.$route.query.section
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-button-group-measures {
  max-width: 150px;
  min-width: 50px;
  height: 50px;
}
</style>
