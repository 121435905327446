<template>
  <b-container fluid class="px-0">
    <h4 class="text-left text-uppercase px-3">
      {{ selectedCompany.denominazione }}
    </h4>
    <b-row class="px-3 mb-4">
      <b-form-group label="Cerca la visita o la prestazione di cui ha bisogno">
        <b-form-input v-model="hsFilter"> </b-form-input>
      </b-form-group>
    </b-row>
    <section class="w-100">
      <b-spinner
        v-if="isLoading"
        class="mx-2 px-1"
        variant="primary"
        label="Loading..."
      ></b-spinner>
      <b-row v-else class="my-1 px-0 w-100">
        <div
          class="d-flex flex-nowrap border-extra-light general_border pt-2 px-3 pb-4"
          style="max-width: 75vw; overflow-x: auto"
        >
          <template v-if="filteredHealthServices.length > 0">
            <PickHealthServiceFromCompanyCard
              :key="ind"
              v-for="(healthService, ind) in filteredHealthServices"
              class="h-100"
              :healthService="healthService"
              :company="selectedCompany"
              :is-selected="
                selectedHealthService?.hashid === healthService.hashid
              "
              @selected-new-health-service="handleSelectedHealthService($event)"
            />
          </template>
          <template v-else>
            <div class="text-center w-100">
              <p class="text-secondary">Nessuna prestazione disponibile</p>
            </div>
          </template>
        </div>
      </b-row>
    </section>
  </b-container>
</template>

<script>
import PickHealthServiceFromCompanyCard from "@/components/prenotazioni/paziente/reservationSettingSteps/PickHealthServiceFromCompanyCard.vue"
import { supportService } from "@/_services"
import { mapState, mapActions } from "vuex"

export default {
  name: "PickHealthServiceFromCompany",
  data() {
    return {
      isLoading: false,
      healthServices: [],
      selectedHealthService: null,
      hsFilter: ""
    }
  },
  components: {
    PickHealthServiceFromCompanyCard
  },
  props: {
    selectedCompany: {
      type: Object,
      required: true
    },
    resData: {
      type: Object,
      required: true
    }
  },
  computed: {
    filteredHealthServices() {
      return this.hsFilter
        ? this.healthServices.filter((hs) =>
            hs.name.toLowerCase().includes(this.hsFilter.toLowerCase())
          )
        : this.healthServices
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    getHealthServicesForAnotherCompany() {
      this.isLoading = true
      supportService
        .getCompanyHealthServices(this.selectedCompany?.hashid)
        .then((res) => {
          this.healthServices = res.data.data
          //se era già impostata una prestazione, riseleziona quella, altrimenti la resetta
          if (this.resData?.order?.health_service_id) {
            if (
              this.healthServices.find(
                (hs) => hs.hashid === this.resData.order.health_service_id
              )
            ) {
              this.selectedHealthService = this.healthServices.find(
                (hs) => hs.hashid === this.resData.order.health_service_id
              )
            } else {
              this.$emit("selected-new-health-service", null)
            }
          }
        })
        .catch((err) => {
          this.errorAlert("Errore nel caricamento delle prestazioni sanitarie")
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleSelectedHealthService(healthService) {
      this.selectedHealthService = healthService
      this.$emit("selected-new-health-service", healthService)
    }
  },
  created() {
    this.getHealthServicesForAnotherCompany()
  }
}
</script>

<style scoped>
.fixed__scrollable__list {
  overflow-x: scroll;
  padding-bottom: 20px;
}
</style>
