var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 puntofarmaCardNew d-flex flex-column h-100"},[_c('section',{staticClass:"d-flex flex-grow-1 general_border_11 m-4 mx-2 position-relative",class:_vm.noPrenotazioni ? 'border px-4' : '',attrs:{"id":"prenotazioni-container"}},[_c('b-row',{staticClass:"d-flex mx-4 pt-2 mt-n2 pr-xl-2 w-100",attrs:{"id":"prenotazioni-row"}},[(!_vm.noPrenotazioni || _vm.loading)?[_c('b-skeleton-wrapper',{staticClass:"col-12 px-2",attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('section',{staticClass:"mt-3 mr-lg-3"},[_c('p',{staticClass:"puntofarmaCard p-4 general_border ml-n2 mr-lg-3"},[_c('b-skeleton',{attrs:{"width":"100%","height":"50px"}})],1),_c('p',{staticClass:"puntofarmaCard p-4 general_border ml-n2 mr-lg-3"},[_c('b-skeleton',{attrs:{"width":"100%","height":"50px"}})],1)])]},proxy:true}],null,false,651218215)},_vm._l((_vm.prenotazioni),function(prenotazione){return _c('b-col',{key:prenotazione.hashid,staticClass:"prenotazione-col px-0 col-12 col-md-6 col-lg-12 m-0 general_border_11 mb-4"},[_c('div',{staticClass:"d-flex prenotazione-card general_border_11 p-3 mr-0 mr-md-4 mr-lg-0 h-100"},[(
                  prenotazione.attesa_pagamento && prenotazione.status > 0
                )?_c('span',{staticClass:"text-danger fw-bolder"},[_vm._v(" attesa "),_c('br'),_vm._v(" pagamento")]):_c('img',{staticClass:"icon",attrs:{"src":require(`@/assets/dashboard-operatore/${_vm.getImageName(
                    prenotazione.tipo_ordine
                  )}.svg`)}}),_c('div',{staticClass:"font-weight-bolder",staticStyle:{"width":"110px"}},[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.getTitoloCard(prenotazione))+" ")]),(prenotazione.data_inizio)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getData(prenotazione.data_inizio))+" ")]):_vm._e()]),_c('b-iconstack',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Vai alla prenotazione'),expression:"'Vai alla prenotazione'",modifiers:{"hover":true,"top":true}}],staticClass:"point",attrs:{"font-scale":"2"},on:{"click":function($event){return _vm.$router.push({
                    name: 'agenda',
                  })}}},[_c('b-icon',{attrs:{"stacked":"","icon":"circle-fill","variant":"mdm-light-grey"}}),_c('b-icon',{attrs:{"stacked":"","icon":"reply-fill","scale":"0.8","shift-v":"1","shift-h":"1","variant":"secondary"}}),_c('b-icon',{attrs:{"stacked":"","icon":"circle","variant":"mdm-light-grey"}})],1)],1)])}),1)]:(!_vm.loading)?_c('b-col',{staticClass:"p-2 text-left"},[_vm._v(" Nessuna prenotazione per oggi. ")]):_vm._e()],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }