<template>
  <div class="col-12 px-0">
    <h4 class="text-left pb-1">Indice di Aderenza</h4>
    <div class="col-12 pl-0">
      <b-row align-v="center" cols="2" class="jumbotron bg-transparent border bolder__border py-3"
        :class="'border-' + colorCalc">
        <b-col>
          <h1 v-if="showGauge" :class="'text-' + colorCalc" class="display-3">
            {{ calcIndex }}%
          </h1>
        </b-col>
        <b-col>
          <div class="text-dark text-left fs-6 pl-2 mb-2">
            <p class="mb-0"><b>Riferimenti:</b></p>
            <p class="mb-0"><b> 0%</b>: non determinabile</p>
            <p class="mb-0"><b>1-40%</b>: scarso</p>
            <p class="mb-0"><b>40-80%</b>:medio</p>
            <p class="mb-0"><b>>80%</b>: ottimale</p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<style scoped>
.fs-4 {
  font-size: 70%;
}

.bolder__border {
  border-width: 3px !important;
}
</style>

<script>
import { mapState, mapActions } from "vuex";
import { supportService } from "@/_services";
export default {
  name: "AdherenceIndex",
  computed: {
    colorCalc: function () {
      if (this.calcIndex >= 80) {
        return "secondary";
      }
      if (this.calcIndex >= 40) {
        return "warning";
      }
      if (this.calcIndex < 1) {
        return "medium-grey";
      }
      return "danger";
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  watch: {
    userhash: function (value) {
      // console.log(value);
    },
  },
  data() {
    return {
      showGauge: false,
      hash: null,
      calcIndex: 0,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    getAdherenceIndex() {
      var self = this;
      supportService
        .getAdherenceIndex(this.hash)
        .then(function (res) {
          self.showGauge = true;
          self.calcIndex = res.data.data.index;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile elaborare l'indice di aderenza"
          );
          self.showGauge = true;
        });
    },
  },
  mounted() {
    if (this.$route.params.hash) {
      this.hash = this.$route.params.hash;
      this.getAdherenceIndex();
    }
  },
};
</script>
