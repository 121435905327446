<template>
  <div class="">
    <div class="d-flex justify-content-between">
      <router-link
        class="text-secondary no__hover"
        :to="{ name: 'attivita', params: { section: 'questionari' } }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="secondary"></b-icon>
        Questionari
      </router-link>
    </div>
    <div class="px-4">
      <div class="py-3" v-if="template">
        <h4 class="text-prim-grad-1 text-left pb-1">
          Questionari {{ template.name }} ({{ template.activity.name }})
          somministrati
        </h4>

        <p class="text-justify text-medium-grey">
          Clicca su una "vedi" per visualizzare l'esito del questionario. Per
          scaricare l'elenco completo in formato pdf, clicca su "scarica tutto
          in formato pdf".
        </p>
        <b-row
          cols="3"
          class="mb-3 flex-wrap align-items-center align-content-center px-0"
        >
          <b-col class="d-flex col-12 text-left my-2">
            <b-button
              variant="secondary text-white "
              class="m-1"
              @click="getReport"
              :disabled="total == 0"
              >Scarica tutto in formato pdf</b-button
            >
            <b-button
              variant="secondary text-white "
              class="m-1"
              @click="getReportXsl"
              :disabled="total == 0"
              >Scarica tutto in formato excel</b-button
            >
          </b-col>
          <b-col class="col-12 col-md-5 col-lg-4 text-right my-2 mr-0 ml-auto">
            <b-form-input
              v-capitalize-first
              v-if="template.type > 1"
              class="text-uppercase"
              placeholder="Codice fiscale..."
              maxlength="16"
              minlength="16"
              type="search"
              name="cfsearch"
              v-model="filterSetted.cf"
            ></b-form-input>
          </b-col>
        </b-row>
        <div class="d-flex align-items-center">
          <b-form-group size="sm" class="text-left col-2 col-lg-1">
            <b-form-select
              v-capitalize-first-select
              class="align-items-center align-content-center border border-info"
              name="vedi"
              v-model="filterSetted.take"
              :options="takeOptions"
            >
            </b-form-select>
          </b-form-group>
          <span class="mr-1" v-if="total > 0"
            >{{ fromPage }}-{{ toPage }} di
          </span>
          <span class=""> {{ total }}</span>
        </div>
        <div class="mt-2">
          <b-table
            borderless
            hover
            responsive
            table-variant="white"
            :fields="fields"
            :items="surveys"
            :small="$root.isMobile"
          >
            <template #cell(created_at)="row">
              <span class="text-left">{{
                row.item.created_at | moment("DD/MM/YYYY")
              }}</span>
            </template>
            <template #cell(user_mail)="row">
              <b-row align-v="center" class="justify-content-center">
                <span class="ml-auto mr-1">
                  <template v-if="row.item.user_fullname">
                    <span class="text-left">{{ row.item.user_fullname }}</span>
                    -
                  </template>
                  <span class="text-left" v-if="row.item.user_cf">
                    {{ row.item.user_cf }}</span
                  >
                  <br />
                  {{ row.item.user_mail ? row.item.user_mail : "---" }}</span
                >
                <span v-if="row.item.user_hashid" class="mr-auto ml-1">
                  <router-link
                    class="text-secondary no__hover"
                    target="_blank"
                    :to="{
                      name: 'scheda paziente',
                      params: { hash: row.item.user_hashid }
                    }"
                  >
                    <b-iconstack
                      font-scale="2.5"
                      class="point m-1"
                      v-b-popover.hover.top="'Apri scheda paziente'"
                    >
                      <b-icon
                        stacked
                        icon="circle-fill"
                        variant="mdm-light-grey"
                      ></b-icon>
                      <b-icon
                        stacked
                        icon="arrow-up-right-circle-fill"
                        scale="0.5"
                        variant="secondary"
                      ></b-icon>
                    </b-iconstack>
                  </router-link> </span
              ></b-row>
            </template>
            <template #cell(azioni)="row">
              <b-row align-v="center">
                <b-iconstack
                  font-scale="3"
                  class="point m-1"
                  v-b-popover.hover.top="'Vedi'"
                  @click="showThis(row)"
                >
                  <b-icon
                    stacked
                    icon="circle-fill"
                    variant="mdm-light-grey"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="zoom-in"
                    scale="0.5"
                    variant="secondary"
                  ></b-icon>
                </b-iconstack>
                <template
                  v-if="
                    user.permessi.includes('gestire attività e questionari')
                  "
                >
                  <b-iconstack
                    font-scale="3"
                    class="point m-1"
                    v-if="!row.item.created_by"
                    v-b-popover.hover.top="'Modifica'"
                    @click="
                      $router.push({
                        name: 'attivita',
                        params: {
                          quest: row.item.hashid,
                          section: 'questionario',
                          action: 'edit'
                        }
                      })
                    "
                  >
                    <b-icon
                      stacked
                      icon="circle-fill"
                      variant="mdm-light-grey"
                    ></b-icon>
                    <b-icon
                      stacked
                      icon="pencil-square"
                      scale="0.5"
                      variant="secondary"
                    ></b-icon>
                  </b-iconstack>

                  <b-iconstack
                    font-scale="3"
                    class="point m-1"
                    v-b-popover.hover.top="'Elimina'"
                    @click="showDeleteThis(row)"
                  >
                    <b-icon
                      stacked
                      icon="circle-fill"
                      variant="mdm-light-grey"
                    ></b-icon>
                    <b-icon
                      stacked
                      icon="trash"
                      scale="0.5"
                      variant="secondary"
                    ></b-icon>
                  </b-iconstack>
                </template>

                <b-dropdown
                  size="sm"
                  variant="mdm-light-grey text-secondary"
                  right
                  class="secondary-dropdown m-1 py-2 cippalippa"
                  v-b-popover.hover.top="'Scarica'"
                >
                  <template #button-content>
                    <b-icon
                      variant="secondary"
                      icon="download"
                      font-scale="1.9"
                    ></b-icon>
                  </template>
                  <b-dropdown-item
                    class="secondary-li-hover"
                    @click="downloadOne(row)"
                    >PDF Standard</b-dropdown-item
                  >
                  <b-dropdown-item
                    class="secondary-li-hover"
                    @click="downloadPatientVersion(row)"
                    >PDF per il paziente</b-dropdown-item
                  >
                </b-dropdown>
              </b-row>
            </template>
          </b-table>
          <p v-if="surveys && surveys.length < 1">Nessun questionario qui</p>
          <b-pagination
            v-model="filterSetted.page"
            :total-rows="total"
            :per-page="10"
            align="center"
            :size="$root.isMobile ? 'sm' : 'lg'"
          ></b-pagination>
          <b-modal
            ref="show-filleditem"
            centered
            hide-footer
            title="Questionario"
            size="xl"
          >
            <FilledSurveyShower
              v-if="selectedItem"
              :response="selectedItem.response"
              :score="selectedItem.score"
            />
          </b-modal>

          <b-modal
            id="confirm-delete"
            centered
            title="Confermi di voler eliminare il questionario?"
            ok-title="Sì, elimina"
            ok-variant="popup-button"
            cancel-title="No, annulla"
            cancel-variant="popup-button"
            footer-border-variant="white"
            @ok="deleteSelectedItem"
            @cancel="cancelDelete"
          >
            <h6 v-if="selectedItem" class="px-2 py-3">
              Se confermi, il questionario selezionato compilato in data
              {{ selectedItem.created_at | moment("DD/MM/YYYY") }} verrà
              eliminato definitivamente.
            </h6>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services"
import { mapState, mapActions } from "vuex"
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue"
export default {
  name: "ReportQuestionariCompilati",
  components: { FilledSurveyShower },
  data() {
    return {
      filterSetted: {
        take: 10,
        page: 1,
        cf: null
      },
      surveys: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      fields: [
        { key: "created_at", label: "Data" },
        { key: "user_mail", label: "Utente" },
        // { key: "active", label: "Stato" },
        { key: "azioni", label: "" }
      ],
      takeOptions: [10, 25, 50, 100],
      template: null,
      selectedItem: null
    }
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert
    })
  },
  watch: {
    filterSetted: {
      handler(prev, post) {
        if (prev.cf && prev.cf.length < 16) {
          return
        }
        this.fetchData()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      const self = this
      const dat = {
        hash: this.$route.params.quest,
        filters: this.filterSetted
      }
      supportService
        .getFilledForSurvey(dat)
        .then(function (res) {
          // console.log(res);
          self.surveys = res.data.data.data.data
          self.filterSetted.page = res.data.data.data.current_page
          self.total = res.data.data.data.total
          self.fromPage = res.data.data.data.from
          self.toPage = res.data.data.data.to
          self.template = res.data.data.survey
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    showThis(row) {
      this.selectedItem = row.item
      this.$refs["show-filleditem"].show()
    },
    showDeleteThis(row) {
      this.selectedItem = row.item
      this.$bvModal.show("confirm-delete")
    },
    cancelDelete() {
      this.selectedItem = null
      this.$bvModal.hide("confirm-delete")
    },
    getReport() {
      const self = this
      supportService
        .surveyReport(this.$route.params.quest)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download =
            self.template.name + "_" + self.template.activity.name + ".pdf"
          link.click()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    getReportXsl() {
      var self = this
      supportService
        .surveyXlsReport(this.$route.params.quest)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download =
            self.template.name + "_" + self.template.activity.name + ".xlsx"
          link.click()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    downloadOne(row) {
      const self = this
      const selected = row
      supportService
        .downloadFilledSurvey(selected.item.hashid)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download =
            self.template.name + "_" + self.template.activity.name + ".pdf"
          link.click()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    downloadPatientVersion(row) {
      const self = this
      const selected = row
      supportService
        .downloadFilledSurveyPatientVersion(selected.item.hashid)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download =
            "patient_" +
            selected.item.user_cf +
            "_" +
            self.template.name +
            ".pdf"
          link.click()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    deleteSelectedItem() {
      const self = this
      supportService
        .deleteFilledSurvey(this.selectedItem.hashid)
        .then(function (res) {
          self.successAlert("Questionario eliminato correttamente")
          self.fetchData()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile eliminare il questionario")
        })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
<style>
.cippalippa > button.dropdown-toggle {
  padding: 0.5rem 0.3rem !important;
}
</style>
