<template>
  <div v-if="company" class="px-0 px-md-4 col-12 d-flex">
    <section class="col-lgx-10 col-xl-8">
      <h4>{{ company.denominazione }}</h4>
      <div class="mb-4">
        <section class="d-flex justify-content-start">
          <section
            class="bg-whitesmoke border border-info general_border d-flex px-4 py-2"
          >
            <span class="mr-2">Stato Azienda</span>
            <p
              class="d-flex align-items-center text-left font-weight-bolder fs-5 mb-0"
              v-if="company.enabled"
            >
              <b-icon
                icon="circle-fill"
                variant="success"
                font-scale="1.5"
                class="mr-1"
              ></b-icon>
              Abilitata
            </p>
            <p class="text-left font-weight-bolder fs-5 mb-0" v-else>
              <b-icon
                icon="circle-fill"
                variant="danger"
                font-scale="1.5"
                class="mr-1"
              ></b-icon>
              Non abilitata
            </p>
          </section>
        </section>
      </div>
      <div class="mb-4">
        <h4 id="scheda-azienda" class="pl-4 text-left pb-1">Scheda Azienda</h4>

        <section class="general__border shadow_2">
          <article class="bg-white general_border p-4 text-left">
            <b-row class="ml-n4" cols="2">
              <b-col class="pl-3 pr-0">
                <h5 class="text-secondary pl-2">
                  <b-icon icon="house-fill" variant="secondary"></b-icon>
                  sede
                </h5>
                <section class="general_border pl-2 mb-4">
                  <p class="mb-0">
                    {{ company.indirizzo }} {{ company.cap }},
                    {{ company.frazione }}
                    {{ company.comune }}
                  </p>
                  <p class="mb-0">
                    {{ company.provincia }} {{ company.regione }}
                  </p>
                  <p class="mb-0">
                    <span class="fw-525 text-lowercase">codice asl: </span>
                    {{ company.codice_asl }}
                    <span class="fw-525 ml-2 text-lowercase">comunale: </span>
                    {{ company.comunale == 1 ? "sì" : "no" }}
                    <span class="fw-525 ml-2 text-lowercase">azienda: </span>
                    {{ company.azienda == 1 ? "sì" : "no" }}
                  </p>
                </section>

                <h5 class="text-secondary pl-2">
                  <b-icon icon="person-fill" variant="secondary"></b-icon>
                  titolare
                </h5>
                <section class="general_border pl-2 mb-4">
                  <p class="mb-0 text-capitalize">
                    {{ company.nome }} {{ company.cognome }}
                  </p>
                  <p class="mb-0 text-uppercase">
                    <span class="fw-525 text-lowercase">codice fiscale: </span>
                    {{ company.codice_fiscale }}
                    <span class="d-flex flex-wrap">
                      <span class="no-wrap fw-525 text-lowercase"
                        >partita iva:</span
                      >
                      {{ company.partita_iva }}
                    </span>
                  </p>
                  <p class="text-uppercase">
                    <span class="fw-525 text-lowercase">codice titolare: </span>
                    {{ company.codice_titolare }}

                    <span class="d-flex flex-wrap">
                      <span class="fw-525 text-lowercase"
                        >codice tracciabilità:
                      </span>
                      {{ company.codice_tracciabilita }}
                    </span>
                  </p>
                </section>

                <h5 class="text-secondary pl-2">
                  <b-icon icon="telephone-fill" variant="secondary"></b-icon>
                  contatti
                </h5>
                <section class="general_border pl-2">
                  <p class="text-uppercase">
                    <span class="fw-525 text-lowercase">email: </span>
                    {{ company.email }}
                    <span class="fw-525 ml-2 text-lowercase">pec: </span>
                    {{ company.emailpec }}<br />
                    <span class="fw-525 text-lowercase">telefono: </span>
                    {{ company.telefono }}
                    <span class="fw-525 ml-2 text-lowercase">fax: </span>
                    {{ company.fax }}
                    <strong class="fw-525 ml-2 text-lowercase"
                      >contatto DPO:
                    </strong>
                    {{ company.contatto_dpo }}
                  </p>
                </section>
              </b-col>
              <b-col class="pl-3 pr-0">
                <h5 class="text-secondary pl-2">
                  <b-icon icon="clock-fill" variant="secondary"></b-icon>
                  apertura
                </h5>
                <section class="general_border pl-2">
                  <p v-if="company.apertura && company.apertura.length > 0">
                    <span v-for="(gg, index) in company.apertura" :key="index">
                      <span class="fw-525">{{ Object.keys(gg)[0] }}: </span>
                      <strong v-if="gg[Object.keys(gg)[0]] == 'chiuso'"
                        >Chiuso</strong
                      >
                      <strong v-else>
                        {{ gg[Object.keys(gg)[0]]["mattina"] }}
                        {{ gg[Object.keys(gg)[0]]["pomeriggio"] }}</strong
                      >
                      <br />
                    </span>
                  </p>
                  <p v-else>Non inseriti</p>
                </section>
              </b-col>
              <h5 class="text-secondary pl-3">
                <b-icon icon="people-fill" variant="secondary"></b-icon>
                operatori
              </h5>
              <p v-if="company.operators_limit">
                <u>
                  Numero max di operatori per l'azienda:
                  {{ company.operators_limit }}</u
                >
              </p>
            </b-row>
            <b-col
              v-for="operator in company.operators"
              class="my-2"
              :key="operator.hashid"
            >
              <p class="mb-1">
                <span class="text-uppercase font-weight-bold">
                  {{ operator.anagrafica.name }}
                  {{ operator.anagrafica.surname }}
                </span>
                <span class="ml-1">
                  <b-icon
                    v-if="operator.abilitato"
                    icon="check-circle-fill"
                    variant="success"
                  ></b-icon>
                  <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
                </span>
                <span class="ml-1">
                  {{ operator.email }}
                </span>
              </p>
              <span class="ml-1">
                <span class="text-secondary">Email verificata:</span>
                <span v-if="operator.email_verified_at">
                  Sì <b-icon icon="check-circle" variant="success"></b-icon>
                </span>
                <span v-else>
                  No <b-icon icon="x-circle" variant="danger"></b-icon>
                </span>
              </span>
              <span class="ml-3">
                <span class="text-secondary">Password:</span>
                <span v-if="operator.temporary_password == 1">
                  temporanea <b-icon icon="x-circle" variant="danger"></b-icon>
                </span>
                <span v-else>
                  personalizzata
                  <b-icon icon="check-circle" variant="success"></b-icon>
                </span>
              </span>
              <hr />
            </b-col>
            <b-row> </b-row>
          </article>
        </section>
      </div>
      <div class="mt-4 pt-4">
        <h4 id="servizi-attivi" class="pl-4 text-left pb-1">Servizi attivi</h4>
        <section class="">
          <div v-if="company.servizi_attivi" class="text-left">
            <div v-for="(servizio, ind) in company.servizi_attivi" :key="ind">
              <div
                v-if="company.prenotazioni.servizi[ind]"
                class="mb-3 p-4 general__border shadow_2"
              >
                <h6 class="text-secondary text-capitalize">
                  {{ ind }}
                </h6>
                <p class="text-left text-medium-grey fs-5">
                  Report attività {{ ind }}.
                </p>
                <ProgressAttivita :valori="company.prenotazioni.servizi[ind]" />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="mt-4 pt-4">
        <h4 id="prestazioni-sanitarie" class="pl-4 text-left pb-1">
          Prestazioni Sanitarie
        </h4>

        <div v-if="companyHealthServices" class="text-left col-12 px-0">
          <b-row
            cols="1"
            v-for="(prestazione, ind) in companyHealthServices"
            :key="ind"
            class="general__border shadow_2 p-4 border border-light-panel general_border my-3"
          >
            <b-row cols="1" class="">
              <b-row class="justify-content-between">
                <h5 class="text-capitalize">
                  {{ prestazione.name }}
                  <span v-if="prestazione.active == 1">
                    <b-icon icon="check-circle-fill" variant="success"></b-icon>
                  </span>
                  <span v-else>
                    <b-icon icon="x-circle-fill" variant="danger"></b-icon>
                  </span>
                </h5>
                <span class="">
                  Planning
                  <span class="text-uppercase">
                    {{ prestazione.planning }}</span
                  >
                </span>
              </b-row>
              <b-col v-if="prestazione.questionario">
                <p>
                  <span class="text-secondary"> Questionario associato</span>:
                  {{ prestazione.questionario.name }}
                </p>
              </b-col>
            </b-row>
            <b-row cols="3" class="pl-2 pb-2">
              <div class="pl-2">
                <p
                  v-if="
                    prestazione.disponibilita &&
                    prestazione.disponibilita.length > 0
                  "
                >
                  <b-button
                    variant="secondary  "
                    class="mb-1"
                    size="sm"
                    v-b-toggle="'collapse-' + prestazione.hashid"
                  >
                    Disponibilità</b-button
                  >
                </p>
              </div>
              <div>
                <b-button
                  variant="secondary  "
                  class="mb-1"
                  size="sm"
                  v-b-toggle="'attivita-' + prestazione.hashid"
                >
                  Attività</b-button
                >
              </div>
              <div>
                <b-button
                  variant="info  "
                  class="mb-1"
                  size="sm"
                  v-b-toggle="'report-' + prestazione.hashid"
                  >Report
                </b-button>
              </div>
            </b-row>
            <b-row cols="1" class="pl-2">
              <b-collapse :id="'collapse-' + prestazione.hashid">
                <b-row>
                  <b-col>
                    <p class="text-left text-font-grey mb-0">
                      Orari disponibilità
                    </p>
                    <span
                      v-for="(gg, index) in prestazione.disponibilita"
                      :key="index"
                    >
                      <strong>{{ Object.keys(gg)[0] }}: </strong>
                      <span v-if="gg[Object.keys(gg)[0]] == 'chiuso'"
                        >Chiuso</span
                      >
                      <span v-else>
                        {{ gg[Object.keys(gg)[0]]["mattina"] }}
                        {{ gg[Object.keys(gg)[0]]["pomeriggio"] }}
                        <strong>
                          max
                          {{ gg[Object.keys(gg)[0]]["al_giorno"] }}
                          al dì
                        </strong>
                      </span>
                      <br />
                    </span>
                  </b-col>
                  <b-col v-if="prestazione.days_openings">
                    <p class="text-left text-font-grey mb-0">
                      Giorni disponibilità
                    </p>
                    <span
                      v-for="(dd, index) in prestazione.days_openings"
                      :key="index"
                    >
                      {{ dd.day | moment("D.MM.YY") }} <strong> | </strong>
                    </span>
                  </b-col>
                </b-row>
              </b-collapse>
              <b-collapse :id="'attivita-' + prestazione.hashid">
                <p class="text-left text-font-grey fs-5 mb-0">Attività</p>
                <ProgressAttivita
                  :valori="company.prenotazioni.servizi[prestazione.name]"
                />
              </b-collapse>
              <b-collapse :id="'report-' + prestazione.hashid">
                <p class="text-left text-font-grey fs-5 mb-0 mt-4">Report</p>
                <CompanyReport :azienda="company" :servizio="prestazione" />
              </b-collapse>
            </b-row>
          </b-row>
        </div>
        <p v-else>Nessuna Prestazione</p>
      </div>
      <div class="mt-4 pt-4">
        <h4 class="pl-4 text-left pb-1" id="attivita-questionari">
          Attività e questionari
        </h4>

        <div v-if="company.questionari" class="text-left">
          <div
            class="mb-2 general__border shadow_2 p-4"
            v-for="(att, ind) in company.questionari"
            :key="ind"
          >
            <h6 class="text-secondary text-capitalize">{{ ind }}</h6>
            <p class="text-left text-medium-grey fs-5">Report {{ ind }}.</p>
            <ProgressQuestionari :valori="company.questionari[ind]" />
          </div>
        </div>
        <p v-else>Nessuna attività.</p>
      </div>
      <div class="my-3 py-4 mt-4">
        <h4 id="report" class="pl-4 text-left pb-1">
          Report Attività Prenotazioni e Ordini
        </h4>

        <section class="general__border shadow_2 p-4 mb-2">
          <h6 class="text-secondary text-left">Complessivo</h6>
          <ProgressAttivita :valori="company.prenotazioni.general" />
        </section>
        <section class="general__border shadow_2 p-4 mb-2">
          <h6 class="text-secondary text-left">Ordini Prodotto</h6>
          <ProgressAttivita :valori="company.prenotazioni.servizi.prodotto" />
        </section>
        <section class="general__border shadow_2 p-4">
          <h6 class="text-secondary text-left mb-2">
            Prenotazioni Prestazione
          </h6>
          <ProgressAttivita
            :valori="company.prenotazioni.servizi.prestazione"
          />
        </section>
      </div>
    </section>
    <section>
      <section
        class="ml-4 pt-5"
        style="position: sticky; top: 0; margin-top: 90px"
      >
        <section
          class="p-4 bg-white general_border border border-secondary shadow_2"
        >
          <p>
            <a href="#scheda-azienda" class="text-secondary">Scheda Azienda</a>
          </p>
          <p>
            <a href="#servizi-attivi" class="text-secondary">Servizi Attivi</a>
          </p>
          <p>
            <a href="#prestazioni-sanitarie" class="text-secondary"
              >Prestazioni Sanitarie</a
            >
          </p>
          <p>
            <a href="#attivita-questionari" class="text-secondary"
              >Attività e questionari</a
            >
          </p>
          <p class="mb-0">
            <a href="#report" class="text-secondary">Report</a>
          </p>
        </section>
      </section>
    </section>
  </div>
</template>
<script>
import { supportService, companyService } from "@/_services"
import { mapState, mapActions, mapGetters } from "vuex"
import CompanyReport from "@/components/admin/CompanyReport.vue"
import ProgressAttivita from "@/components/admin/ProgressAttivita.vue"
import ProgressQuestionari from "@/components/admin/ProgressQuestionari.vue"
export default {
  name: "DettaglioAzienda",
  components: { CompanyReport, ProgressAttivita, ProgressQuestionari },
  data() {
    return {
      companyHash: this.$route.params.company,
      company: null
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    getOwnHealthServices() {
      var self = this
      companyService
        .getOwnHealthServices()
        .then((res) => {
          self.companyHealthServices = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    fetchData() {
      var self = this
      supportService
        .getCompany(this.$route.params.company)
        .then(function (response) {
          self.company = response.data.data
          self.getOwnHealthServices()
        })
        .catch(function (error) {
          //
          self.errorAlert("Non trovata.")
        })
    }
  },
  created() {
    // console.log("HERE");
    // if (this.$route.params.company) {
    this.fetchData()
    // }
  }
}
</script>
