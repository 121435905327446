<template>
  <!-- <div class="px-4 px-md-2 mb-4" v-if="
    company &&
    (company.servizi_attivi['teleconsulto'] ||
      company.servizi_attivi['connessa'])
  "> -->
  <div class="px-4 px-md-2 mb-4">
    <h1
      class="text-left pb-1 mt-1 mb-4 d-flex justify-content-start align-items-center page-title"
    >
      Teleconsulti
      <b-icon
        icon="info-circle"
        id="status-explaination"
        variant="secondary"
        class="ml-3 mr-4"
        font-scale="1"
      ></b-icon>
      <b-tooltip
        placement="bottom"
        target="status-explaination"
        :triggers="$root.isMobile ? 'click' : 'hover'"
      >
        <!-- <p class="text-justify">Filtra le prenotazioni per stato. Gli stati disponibili sono:</p> -->
        <p class="text-justify">
          <b>In attesa</b>: prenotazioni per cui si attende conferma a seguito
          dell'inoltro o di modifiche della prenotazioni
        </p>
        <p class="text-justify">
          <b>Confermate</b>: prenotazioni già confermate e senza modifiche in
          fase di approvazione
        </p>
        <p class="text-justify">
          <b>Annullate</b>: prenotazioni non più valide per cui è stato
          richiesto l'annullamento.
        </p>
        <p class="mb-0 text-justify">
          <b>Evase</b>: prenotazioni per cui è stato completato il processo di
          gestione.
        </p>
      </b-tooltip>
    </h1>
    <div v-if="prenotazioni" class="">
      <div v-if="user.permessi.includes('gestire ordini e prestazioni Facile')">
        <b-row class="pt-0 pb-2 mx-auto mb-3 align-items-center">
          <b-row class="d-inline-flex mt-n3 w-100 align-items-center">
            <b-col
              v-for="statusOption in shortStatusOptions"
              :key="statusOption.text"
              class="mt-3 col-6 col-sm-3 col-lg-2 point align-self-start px-0"
            >
              <div
                :class="{
                  'bg-secondary text-white':
                    filterSetted.status === statusOption.value,
                  'bg-white text-black':
                    filterSetted.status !== statusOption.value,
                }"
                class="h-100 py-1 py-md-2 py-lg-3 shadow_2 general_border general_hover mr-3"
                @click="handleStatusOptionChange(statusOption.value)"
              >
                {{ statusOption.text }}
              </div>
            </b-col>
            <b-col class="px-0 mt-3 col-2">
              <b-button-group class="general_border">
                <b-button
                  class="text-capitalize buttonbar"
                  size="sm"
                  @click="showCalendar = false"
                  :variant="
                    showCalendar == false ? 'secondary ' : 'outline-secondary'
                  "
                >
                  <b-icon icon="list" class="mx-2"></b-icon>
                  <span v-if="buttonGroupOpen"></span>
                </b-button>
                <b-button
                  class="text-capitalize buttonbar"
                  size="sm"
                  @click="showCalendar = true"
                  :variant="
                    showCalendar == true ? 'secondary ' : 'outline-secondary'
                  "
                >
                  <b-icon icon="calendar3-week-fill" class="mx-2"></b-icon>
                  <span v-if="buttonGroupOpen"></span>
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </b-row>
        <b-row
          class="mb-3 mt-n3 flex-wrap align-items-center"
          align-v="stretch"
        >
          <b-col class="filter-column mt-3 col-12 col-sm-6 col-md-4 col-xl-3">
            <div class="pl-0">
              <p :class="{ 'mb-1 pb-2': company.level === 1 }">Cerca</p>
              <LabeledSearchInput
                :only-second="company.level !== 1"
                first-label="Codice Fiscale"
                short-first-label="C. Fiscale"
                :first-value="filterSetted.cf"
                :first-value-uppercase="true"
                first-placeholder="Codice Fiscale..."
                @first-value-changed="filterSetted.cf = $event"
                @switched-to-first="filterSetted.search = null"
                second-label="Codice Richiesta"
                short-second-label="C. Richiesta"
                :second-value="filterSetted.search"
                :second-value-uppercase="true"
                second-placeholder="Codice Richiesta..."
                @second-value-changed="filterSetted.search = $event"
                @switched-to-second="filterSetted.cf = null"
              />
            </div>
          </b-col>
          <b-col
            class="filter-column mt-3 col-12 col-sm-6 col-md-3 col-lg-2"
            id="periodo-col"
            v-if="!showCalendar"
          >
            <div class="pl-sm-4">
              <p>Periodo</p>
              <b-form-group class="col-12 px-0 my-auto">
                <b-form-select
                  v-capitalize-first-select
                  size="sm"
                  class="text-left general_border"
                  name="show"
                  v-model="filterSetted.range"
                  :options="showOptions"
                >
                </b-form-select>
              </b-form-group>
            </div>
          </b-col>
          <b-col
            class="filter-column mt-3 col-12 col-sm-6 col-md-2 col-lg-2"
            v-if="showCalendar"
          >
            <div class="pl-sm-4">
              <p>Visualizzazione</p>
              <b-form-group class="m-0 d-flex align-items-center">
                <b-form-checkbox
                  class="d-flex align-items-center"
                  size="sm"
                  name="stato"
                  :value="false"
                  :unchecked-value="true"
                  v-model="compactView"
                >
                  <span class="d-flex align-items-center">Per esteso</span>
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-col>
          <b-col
            v-if="!showCalendar"
            class="filter-column mt-3 col-12 col-sm-6 col-md-2"
          >
            <div class="pl-sm-4">
              <p>Mostrane</p>
              <b-form-group class="m-0 col-md-9 p-0 text-left">
                <b-select
                  class="text-left general_border"
                  size="sm"
                  name="vedi"
                  v-model="filterSetted.take"
                  :options="takeOptions"
                >
                </b-select>
              </b-form-group>
            </div>
          </b-col>
        </b-row>

        <b-container fluid class="col-12 px-0 py-2">
          <b-row class="">
            <b-col class="ml-auto general_border px-0 puntofarmaCard p-4">
              <Calendario
                v-if="showCalendar"
                hideFilters="true"
                :servicerif="2"
                :statusFilter="filterSetted.status"
                :searchFilter="filterSetted.search"
                :cfFilter="filterSetted.cf"
                :compactView="compactView"
                :startWithDate="startWithDate"
              />
              <template v-else>
                <div class="m-0 p-0 general_border puntofarmaCard"></div>
                <div
                  v-if="loading || updatingData"
                  class="d-flex w-100 justify-content-center"
                >
                  <p v-if="updatingData" class="mx-1">
                    <b-spinner
                      small
                      variant="secondary"
                      type="grow"
                      label="Aggiornamento in corso"
                      class="ml-2"
                    ></b-spinner>
                    <span>Aggiornamento in corso...</span>
                  </p>
                  <p v-else-if="loading">
                    <b-spinner
                      variant="secondary"
                      label="loading"
                      class="d-flex justify-content-center"
                    ></b-spinner>
                  </p>
                </div>

                <div class="general_border_17">
                  <!-- <Calendario :servicerif="1"></Calendario> -->
                  <b-table
                    @sort-changed="sortChanged"
                    class="general_border_17"
                    borderless
                    hover
                    responsive
                    :fields="adjustedFieldsByUserType"
                    :items="prenotazioni"
                    :small="$root.isMobile"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="filterSetted.indesc"
                  >
                    <template #cell(icona)="row" v-if="!$route.query.scope">
                      <b-icon
                        :icon="icons[row.item.icona]"
                        font-scale="1.1"
                      ></b-icon>
                      <span
                        class=""
                        v-if="row.item.operator && row.item.operator.anagrafica"
                        ><br />
                        op:
                        {{
                          row.item.operator.anagrafica.title
                            ? row.item.operator.anagrafica.title +
                              row.item.operator.anagrafica.name +
                              " " +
                              row.item.operator.anagrafica.surname
                            : row.item.operator.anagrafica.name +
                              " " +
                              row.item.operator.anagrafica.surname
                        }}
                      </span>
                    </template>
                    <template #cell(codice_richiesta)="row">
                      <span class="text-font-grey">
                        {{ row.item.codice_richiesta }}</span
                      >
                    </template>
                    <template #cell(data)="row">
                      <span class="text-font-grey" v-if="row.item.data_inizio">
                        {{
                          row.item.data_inizio | moment("DD/MM/YY HH:mm")
                        }}</span
                      >
                      <span class="text-font-grey" v-else> da definire</span>
                    </template>
                    <template #cell(created_at)="row">
                      <span class="text-font-grey">
                        {{
                          row.item.created_at | moment("DD/MM/YY HH:mm")
                        }}</span
                      >
                    </template>

                    <template #cell(utente)="row">
                      <span class="text-uppercase">
                        {{ row.item.subjectable.anagrafica.name }}
                        {{ row.item.subjectable.anagrafica.surname }} </span
                      ><span
                        class="text-font-grey"
                        v-if="row.item.subjectable.anagrafica.tel_number"
                        ><br />
                        tel:
                        {{ row.item.subjectable.anagrafica.tel_number }}</span
                      >
                    </template>
                    <template #cell(stato)="row">
                      <template v-if="row.item.status > 0">
                        <span
                          class="pt-1 text-uppercase"
                          v-if="row.item.attesa_pagamento"
                          >attesa pagamento</span
                        >
                        <span
                          class="pt-1 text-uppercase"
                          v-else-if="!row.item.data_inizio"
                          >attesa conferma</span
                        >
                        <span class="pt-1 text-uppercase" v-else>{{
                          row.item.stato
                        }}</span>
                      </template>
                      <span class="pt-1 text-uppercase" v-else>{{
                        row.item.stato
                      }}</span>
                    </template>
                    <template #cell(unreadMessages)="row">
                      <span
                        class="text-secondary ml-1"
                        v-if="row.item.unreadMessages"
                        ><b-icon
                          icon="chat-dots-fill"
                          font-scale="1.4"
                        ></b-icon>
                        <b-badge class="fs_badge ml-1" variant="danger">{{
                          row.item.unreadMessages
                        }}</b-badge>
                      </span>
                    </template>
                    <template #cell(azioni)="row">
                      <b-row class="justify-content-center mb-n1">
                        <b-col class="px-0 mx-1">
                          <b-iconstack
                            @click="showDetail(row.item)"
                            v-b-popover.hover.top="'Vedi dettagli'"
                            font-scale="2.5"
                            class="point mt-1"
                          >
                            <b-icon
                              stacked
                              icon="circle-fill"
                              variant="mdm-light-grey"
                            ></b-icon>
                            <b-icon
                              stacked
                              icon="eye"
                              scale="0.5"
                              variant="secondary"
                            ></b-icon>
                          </b-iconstack>
                        </b-col>
                        <b-col class="px-0 mx-1">
                          <b-iconstack
                            v-if="
                              row.item.status >= 1 &&
                              Math.abs(row.item.status) !== 4
                            "
                            @click="openCancel(row.item)"
                            v-b-popover.hover.top="'Annulla'"
                            font-scale="2.5"
                            class="point mt-1"
                          >
                            <b-icon
                              stacked
                              icon="circle-fill"
                              variant="mdm-light-grey"
                            ></b-icon>
                            <b-icon
                              stacked
                              icon="x-circle"
                              scale="0.5"
                              variant="secondary"
                            ></b-icon>
                          </b-iconstack>
                        </b-col>
                        <b-col class="px-0 mx-1">
                          <!-- Bottone evasione -->
                          <b-iconstack
                            v-if="row.item.status !== 4 && row.item.status > 0"
                            @click="openEvadi(row.item)"
                            v-b-popover.hover.top="'Evadi'"
                            font-scale="2.5"
                            class="point mt-1"
                          >
                            <b-icon
                              stacked
                              icon="circle-fill"
                              variant="mdm-light-grey"
                            ></b-icon>
                            <b-icon
                              stacked
                              icon="check"
                              scale="0.5"
                              variant="secondary"
                            ></b-icon>
                          </b-iconstack>
                        </b-col>
                      </b-row>
                      <!-- <div class="d-flex">
                        <b-button
                          size="sm"
                          variant="info px-3 fs-6 mx-1"
                          @click="showDetail(row.item)"
                        >
                          {{ row.detailsShowing ? "Chiudi" : "Visualizza" }}
                        </b-button>
                        <b-button
                          v-if="row.item.status !== 4 && row.item.status > 0"
                          @click="openEvadi(row.item)"
                          size="sm"
                          variant="secondary text-white   px-3 fs-6 mx-1"
                        >
                          Evadi
                        </b-button>
                      </div> -->
                    </template>
                    <template #row-details="row">
                      <b-card
                        :header="
                          'Dettaglio richiesta #' + row.item.codice_richiesta
                        "
                        header-text-variant="info"
                        header-bg-variant="white"
                        header-tag="h4"
                        class="text-left text-font-grey"
                      >
                        <b-card-text>
                          <p class="text-font-grey text-right mb-0">
                            <b-spinner
                              variant="secondary"
                              label="loading"
                              v-if="loading"
                            ></b-spinner>
                            <strong class="ml-1 text-secondary"
                              >Richiesta:
                            </strong>
                            <span class="text-prim-grad-1">{{
                              row.item.filled_survey
                                ? row.item.filled_survey.survey_name
                                : "teleconsulto"
                            }}</span>
                          </p>
                          <h5 class="text-secondary">Cliente</h5>
                          <p class="mb-0">
                            Richiesto da
                            <strong class="text-capitalize"
                              >{{ row.item.requested_by.anagrafica.name }}
                              {{ row.item.requested_by.anagrafica.surname }}
                            </strong>
                            , codice fiscale
                            <strong class="text-uppercase"
                              >{{ row.item.requested_by.anagrafica.cf }}
                            </strong>
                          </p>
                          <p class="mb-0">
                            email:
                            <strong>{{ row.item.requested_by.email }} </strong>
                            , telefono:
                            <strong
                              >{{ row.item.requested_by.anagrafica.tel_number }}
                            </strong>
                          </p>
                          <p class="mb-0">
                            indirizzo:
                            <strong
                              >{{ row.item.requested_by.anagrafica.address }}
                            </strong>
                            ,
                            <strong class="text-capitalize"
                              >{{ row.item.requested_by.anagrafica.cap }}
                              {{ row.item.requested_by.anagrafica.comune }}
                              {{ row.item.requested_by.anagrafica.provincia }}
                              {{ row.item.requested_by.anagrafica.regione }}
                            </strong>
                          </p>
                          <!-- <p
                  class="mb-0 "
                  v-if="
                    row.item.requested_by.anagrafica.cf ===
                    row.item.subjectable.anagrafica.cf
                  "
                >
                  per sé stesso.
                </p> -->
                          <p
                            v-if="
                              row.item.requested_by.anagrafica.cf !==
                              row.item.subjectable.anagrafica.cf
                            "
                            class="mb-0 mt-1"
                          >
                            Per conto di
                            <strong
                              class="text-capitalize"
                              v-if="row.item.subjectable.anagrafica"
                              >{{ row.item.subjectable.anagrafica.name }}
                              {{ row.item.subjectable.anagrafica.surname }},
                            </strong>
                            <strong v-else class="text-capitalize"
                              >{{ row.item.subjectable.name }}
                              {{ row.item.subjectable.surname }},
                            </strong>
                            codice fiscale
                            <strong class="text-uppercase"
                              >{{
                                row.item.subjectable.anagrafica
                                  ? row.item.subjectable.anagrafica.cf
                                  : row.item.subjectable.cf
                              }}
                            </strong>
                          </p>
                          <!-- <p class="mb-0" v-if="row.item.subjectable.anagrafica">
                  indirizzo:
                  <strong
                    >{{ row.item.subjectable.anagrafica.address }}
                  </strong>
                  ,
                  <strong class="text-capitalize"
                    >{{ row.item.subjectable.anagrafica.cap }}
                    {{ row.item.subjectable.anagrafica.comune }}
                    {{ row.item.subjectable.anagrafica.provincia }}
                    {{ row.item.subjectable.anagrafica.regione }}
                  </strong>
                </p> -->
                          <h5 class="text-secondary mt-1">Richiesta</h5>
                          <p class="mb-1">
                            <strong class="text-secondary"
                              >Codice Richiesta: </strong
                            >{{ row.item.codice_richiesta }}
                          </p>
                          <p class="mb-1">
                            <strong class="text-secondary"
                              >Numero Richiesta: </strong
                            >#{{ row.item.numero }}
                          </p>
                          <p class="mb-2">
                            <strong class="text-secondary"
                              >Giorno e ora:
                            </strong>
                            <strong>{{
                              row.item.data_inizio | moment("DD.MM.YY HH:mm")
                            }}</strong>
                            <strong v-if="row.item.data_fine">
                              -
                              {{ row.item.data_fine | moment("HH:mm") }}</strong
                            >
                          </p>
                          <DetailTeleconsulto
                            :dettaglio="row.item.orderable"
                            :stato="row.item.status"
                            :quest="row.item.filled_survey"
                            :datainizio="row.item.data_inizio"
                            :hash="row.item.hashid"
                            v-if="row.item.tipo_ordine === 'teleconsulto'"
                            v-on:update-request-status="
                              handleStatusUpdate(row.item, $event)
                            "
                            v-on:update-request-response="
                              sendOrderResponse(row.item, $event)
                            "
                          />

                          <p class="mb-0">
                            Data Operazione:
                            <strong>{{
                              row.item.created_at || moment("DD.MM.YY HH:mm")
                            }}</strong>
                          </p>
                          <p class="mb-0" v-if="row.item.user_notes">
                            Note dell'utente:
                            <strong>{{ row.item.user_notes }}</strong>
                          </p>
                          <p class="mb-0" v-if="row.item.company_notes">
                            Note Operatore:
                            <strong>{{ row.item.company_notes }}</strong>
                          </p>
                        </b-card-text>
                        <!-- <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul> -->
                      </b-card>
                    </template>
                  </b-table>
                </div>
                <p
                  class="mb-0"
                  v-if="prenotazioni !== null && prenotazioni.length == 0"
                >
                  Nessuna richiesta qui.
                </p>

                <div class="d-flex ml-4">
                  <span class="" v-if="total > 0"
                    >{{ fromPage }}-{{ toPage }} di {{ total }}
                  </span>
                </div>

                <b-pagination
                  class="customPagination general_border mt-2"
                  v-model="filterSetted.page"
                  :total-rows="total"
                  :per-page="10"
                  align="center"
                  @change="paginationChanged"
                  :size="$root.isMobile ? 'sm' : 'lg'"
                ></b-pagination>
              </template>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <b-modal
        size="lg"
        centered
        ref="modal-evasione"
        title="Evadi Richiesta"
        hide-footer
      >
        <div v-if="toEvadere">
          <p class="text-left text-font-grey">
            Numero Richiesta: #{{ toEvadere.numero }} <br />
          </p>
          <p class="text-left text-font-grey">
            Codice Richiesta:
            {{ toEvadere.codice_richiesta }} <br />
          </p>

          <b-form-input
            name="detail"
            class="mx-auto"
            v-model="evasione.hash"
            type="text"
            hidden
          >
          </b-form-input>
          <b-form-group
            label="Aggiungi Annotazione"
            label-for="detail"
            class="text-left text-secondary"
          >
            <b-form-input
              v-capitalize-first
              name="detail"
              class="mx-auto"
              v-model="evasione.details"
              type="text"
            >
            </b-form-input>
          </b-form-group>
          <div class="text-right my-2 pt-4">
            <!-- <b-button
            type="button"
            class="mx-2"
            variant="outline-secondary"
            @click="$refs['modal-evasione'].hide()"
            >Annulla</b-button
          > -->
            <b-button
              type="button"
              class="mx-2"
              variant="popup-button"
              @click="evadiThis"
              >Conferma</b-button
            >
          </div>
        </div>
      </b-modal>
      <b-modal
        size="xl"
        centered
        id="modal-resdetails-tlc"
        ref="modal-resdetails-tlc"
        hide-footer
        hide-header
        scrollable
        body-class="p-0"
        @hidden="selectedRow = null"
      >
        <b-spinner
          variant="secondary"
          label="loading"
          v-if="loading"
        ></b-spinner>
        <DettaglioPrenotazioneOperatore
          v-if="selectedRow"
          :chat-recipient-hash="selectedRow.subjectable.hashid"
          chat-recipient-type="user"
          :reservation="selectedRow"
          :show-company="user.permessi.includes('gestire liste pazienti')"
          @close-modal="closeModal()"
          @fetch-data="fetchData()"
          @handle-update-request="handleUpdateRequest()"
          @handle-status-update="handleStatusUpdate($event)"
          @update-request-response="sendOrderResponse($event)"
          @update-res-operator="updateResOperator($event)"
        />
      </b-modal>
      <b-modal
        size="xl"
        hide-footer
        centered
        id="modal-delete-confirm"
        title="Conferma Annullamento"
      >
        <PickMessageForm
          :messages="cancellationMessages"
          confirm-message="Sei sicuro di voler annullare questo appuntamento?"
          @cancellation-confirmed="cancelReservation($event)"
          @refused-to-confirm="$bvModal.hide('modal-delete-confirm')"
        />
      </b-modal>
    </div>
  </div>
  <!-- <div v-else class="my-4">
    <h4 class="text-danger">
      Per utilizzare le funzionalità di teleconsulto è necessario acquistare
      l'abbonamento .
    </h4>
  </div> -->
</template>
<script>
import { mapState, mapActions } from "vuex";
import { reservationService } from "@/_services";
import Calendario from "@/components/prenotazioni/calendario/Calendario.vue";
import DettaglioPrenotazioneOperatore from "@/components/prenotazioni/DettaglioPrenotazioneOperatore.vue";
import PickMessageForm from "@/components/prenotazioni/form/PickMessageForm.vue";
import { CANCELLATION_MESSAGES } from "@/_utils/constants.js";
import LabeledSearchInput from "@/components/prenotazioni/calendario/LabeledSearchInput.vue";

export default {
  name: "HomeTeleconsulto",
  components: {
    Calendario,
    DettaglioPrenotazioneOperatore,
    PickMessageForm,
    LabeledSearchInput,
  },
  data() {
    return {
      pickedFilter: "codiceFiscale",
      per_page: 10,
      shortStatusOptions: [
        { value: "tutte", text: "Tutte" },
        { value: "in attesa", text: "In Attesa" },
        { value: "confermate", text: "Confermate" },
        { value: "annullate", text: "Annullate" },
        { value: "evase", text: "Evase" },
      ],
      cancellationMessages: CANCELLATION_MESSAGES,
      statusExplaination: {
        "In corso": "Le prenotazioni ricevute o confermate dall'operatore",
        "In attesa":
          "Le prenotazioni che hanno subìto variazioni che devono essere confermate",
        Annullate:
          "Le prenotazioni non più attive per cui è stato chiesto un annullamento",
        Evase: "Le prenotazioni la cui gestione stata è completata",
      },
      startWithDate: null,
      actionName: null,
      toCancellare: null,
      selectedPrestazione: undefined,
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill",
      },
      statusModalVariants: {
        confermato: "secondary",
        ricevuto: "primary-darker",
        annullato: "danger",
        rifiutato: "danger",
        "in attesa di risposta": "warning",
        evaso: "primary-darker",
      },
      statusModalIcons: {
        confermato: "check-circle",
        ricevuto: "archive",
        annullato: "x-circle",
        rifiutato: "x-circle",
        "in attesa di risposta": "question-circle",
        evaso: "calendar2-check",
      },
      showFilters: true,
      compactView: true,
      takeOptions: [10, 25, 20, 100],
      showOptions: [
        { value: "oggi", text: "oggi" },
        { value: "domani", text: "domani" },
        { value: "settimana", text: "questa settimana" },
        { value: "mese", text: "questo mese" },
        { value: "30g", text: "ultimi 30 giorni" },
        { value: "next30", text: "prossimi 30 giorni" },
        { value: "anno", text: "quest'anno" },
        { value: null, text: "tutti" },
      ],
      statusOptions: [
        { value: "tutte", text: "Tutte" },
        { value: "in corso", text: "In corso" },
        { value: "in attesa", text: "In attesa" },
        { value: "annullate", text: "Annullate" },
        { value: "evase", text: "Evase" },
      ],
      showCalendar: false,
      prenotazioni: null,
      fields: [
        { key: "icona", label: "" },
        { key: "tipo", label: "" },
        { key: "codice_richiesta", label: "C.Richiesta" },
        { key: "data", label: "Data Pren." },
        { key: "created_at", label: "Data Richiesta", sortable: true },
        { key: "utente", label: "Cliente" },
        { key: "stato", label: "Stato" },
        { key: "unreadMessages", label: "" },
        { key: "azioni", label: "" },
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      // statusPositive: {
      //   1: "",
      //   2: "",
      //   3: "",
      //   4: "",
      //   5: "",
      //   6: "",
      // },
      // statusNegative: {
      //   1: "",
      //   2: "",
      //   3: "",
      //   4: "",
      // },
      filterOptions: {
        status: ["in corso", "in attesa", "evase", "annullate"],
        service: ["prestazione", "ritiro", "prodotto", "tutte"],
        search: null,
      },
      filterSetted: {
        status: "in attesa",
        service: undefined,
        search: undefined,
        cf: undefined,
        position_ref: 2,
        page: 1,
        take: 10,
        range: "mese",
        indesc: true,
        prestazione: null,
        unread: true,
        orderBy: "created_at",
      },
      sortBy: "data",
      countPrev: 0,
      count: 0,
      dataToCancel: null,
      typeToCancel: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      timer: null,
      updatingData: false,
      loading: true,
      evasione: {
        details: null,
        hash: null,
      },
      toEvadere: null,
      titleLabels: {
        prestazione: "Prestazioni",
        ritiro: "Ritiri",
        prodotto: "Ordini",
        tutte: "Tutte",
      },
      selectedRow: null,
      buttonGroupOpen: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"]),
    adjustedFieldsByUserType() {
      if (this.user.company.level === 5) {
        this.fields.splice(2, 0, {
          key: "company.denominazione",
          label: "Azienda",
        });
      }
      return this.fields;
    },
  },
  watch: {
    filterSetted: {
      handler(prev, post) {
        // console.log("changed prev", prev.status, post);
        // console.log("changed post", post.status);
        // if (!this.showCalendar) this.fetchData();
        this.fetchData();
      },
      deep: true,
    },
    $route(val) {
      // console.log("val route change", val);
      if (
        val.query.scope &&
        this.filterOptions.service.includes(val.query.scope)
      ) {
        // console.log("val route change", val);
        this.filterSetted.service = val.query.scope;
        this.filterSetted.status = "in corso";
        this.filterSetted.prestazione = null;
      } else {
        this.filterSetted.service = undefined;
        this.filterSetted.status = "in corso";
        this.filterSetted.prestazione = null;
      }
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    closeModal() {
      this.$bvModal.hide("modal-resdetails-tlc");
    },
    fetchData() {
      // console.log("fetching prenotazioni");
      this.loading = true;
      var self = this;
      reservationService
        .fetchPrenotazioni(this.filterSetted)
        .then(function (response) {
          // console.log("response fetch prenotazioni", response);
          self.prenotazioni = response.data.data.data;
          self.per_page = response.data.data.per_page;
          self.filterSetted.page = response.data.data.current_page;
          self.total = response.data.data.total;
          self.fromPage = response.data.data.from;
          self.toPage = response.data.data.to;
          if (
            !self.$route.query.scope &&
            self.filterSetted.status == "in corso" &&
            self.filterSetted.range == null &&
            !self.filterSetted.search &&
            !self.filterSetted.cf
          ) {
            self.count = response.data.data.total;
            var diff = self.count - self.countPrev;
            if (self.countPrev !== 0 && self.countPrev < self.count) {
              self.infoAlert(diff + " nuove richieste");
            }
            self.countPrev = self.count;
          }
          self.updatingData = false;
          self.loading = false;
        })
        .catch(function (error) {
          self.updatingData = false;
          self.errorAlert("non è stato possibile prelevare le richieste");
          // console.log("error fetch prenotazioni", error);
          self.loading = false;
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    cancelReservation(message = null) {
      this.$bvModal.hide("modal-delete-confirm");
      let data = {
        confirmed: false,
        message,
        action: this.actionName,
      };
      this.handleStatusUpdate(data, this.toCancel);
    },
    handleStatusOptionChange(value) {
      this.filterSetted.status = value;
    },
    openCancel(item) {
      this.toCancel = item;
      // console.log(this.toCancel);
      this.$bvModal.show("modal-delete-confirm");
      if (item.status === 1) this.actionName = "reject";
      else if (Number.isInteger(item.status) && item.status > 1)
        this.actionName = "cancel";
      // console.log(this.actionName);
    },

    handleStatusUpdate(statusData, reservation = this.selectedRow) {
      var self = this;
      if (!statusData.confirmed) {
        this.loading = true;
        if (this.company.level === 5 && reservation.accettata === null) {
          var form = {
            message: statusData.message || "",
            accettata: false,
          };
          reservationService
            .CRRejectOrder(form, reservation.hashid)
            .then(function (re) {
              self.$bvModal.hide("modal-resdetails-tlc");
              window.scrollTo(0, 0);
              self.successAlert("Richiesta aggiornata con successo");
              self.fetchData();
            })
            .catch(function (error) {
              self.$bvModal.hide("modal-resdetails-tlc");
              window.scrollTo(0, 0);
              self.errorAlert("non è stato possibile effettuare l'operazione");
              self.loading = false;
            });
        } else {
          var form = statusData.message ? { message: statusData.message } : {};
          reservationService
            .companyCancelOrReject(form, statusData.action, reservation.hashid)
            .then(function (re) {
              self.$bvModal.hide("modal-resdetails-tlc");
              window.scrollTo(0, 0);
              self.successAlert("Richiesta aggiornata con successo");
              self.fetchData();
            })
            .catch(function (error) {
              self.$bvModal.hide("modal-resdetails-tlc");
              window.scrollTo(0, 0);
              self.errorAlert("non è stato possibile effettuare l'operazione");
              self.loading = false;
            });
        }
      } else {
        this.loading = true;
        if (this.company.level === 5 && reservation.accettata === null) {
          reservationService
            .CRConfirmOrder(reservation.hashid)
            .then(function (re) {
              self.$bvModal.hide("modal-resdetails-tlc");
              window.scrollTo(0, 0);
              self.successAlert("Richiesta aggiornata con successo");
              self.fetchData();
            })
            .catch(function (error) {
              self.$bvModal.hide("modal-resdetails-tlc");
              window.scrollTo(0, 0);
              self.errorAlert("non è stato possibile effettuare l'operazione");
              self.loading = false;
            });
        } else {
          reservationService
            .companyConfirmOrder(reservation.hashid)
            .then(function (re) {
              self.$bvModal.hide("modal-resdetails-tlc");
              window.scrollTo(0, 0);
              self.successAlert("Richiesta aggiornata con successo");
              self.fetchData();
            })
            .catch(function (error) {
              self.$bvModal.hide("modal-resdetails-tlc");
              window.scrollTo(0, 0);
              self.errorAlert("non è stato possibile effettuare l'operazione");
              self.loading = false;
            });
        }
      }
    },
    evadiThis(hash = null) {
      // console.log("richiesta da evadere ", hash);
      hash = this.evasione.hash;
      var self = this;
      reservationService
        .closeOrder(this.evasione)
        .then(function (response) {
          self.$refs["modal-evasione"].hide();
          self.evasione.details = null;
          self.evasione.hash = null;
          window.scrollTo(0, 0);
          self.successAlert("prenotazione evasa con successo");
          // console.log("response fetch prenotazioni", response);
          self.fetchData();
        })
        .catch(function (error) {
          self.$refs["modal-evasione"].hide();
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile evadere la prenotazione");
          // console.log("error fetch prenotazioni", error);
        });
      //call evadi
      //then alert success/error
      //then fetch
    },
    openEvadi(item) {
      this.toEvadere = item;
      this.evasione.hash = item.hashid;
      this.$refs["modal-evasione"].show();
    },
    showDetail(item) {
      this.selectedRow = item;
      this.$bvModal.show("modal-resdetails-tlc");
      // console.log("pagination changed");
    },
    paginationChanged() {},
    sendOrderResponse(data, reservation = this.selectedRow) {
      var form = {
        hashid: reservation.hashid,
        company_notes: data.company_notes,
        data_inizio: data.newDateTime,
        order: data.detail,
        status: 5,
      };
      // console.log(form);
      var self = this;
      reservationService
        .updateReservation(form)
        .then(function (response) {
          // console.log(response);
          self.$bvModal.hide("modal-resdetails-tlc");
          window.scrollTo(0, 0);
          self.successAlert("risposta inviata con successo");
          self.fetchData();
        })
        .catch(function (error) {
          // console.log(error);
          self.$bvModal.hide("modal-resdetails-tlc");
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile inviare la risposta");
        });
    },
    sortChanged(newSorting) {
      const eventOrder = !!newSorting.sortDesc;
      if (this.filterSetted.indesc !== eventOrder) {
        this.filterSetted.indesc = eventOrder;
      }
    },
    handleUpdateRequest() {
      this.$bvModal.hide("modal-resdetails-tlc");
      this.successAlert("richiesta inviata con successo");
      this.fetchData();
    },
    updateResOperator(operatorHash) {
      var form = {
        hashid: this.selectedRow.hashid,
        company_user_id: operatorHash,
      };
      // console.log(form);
      var self = this;
      reservationService
        .updateReservation(form)
        .then(function (response) {
          // console.log(response);
          // self.$bvModal.hide("modal-resdetails-prenotazioni")
          window.scrollTo(0, 0);
          self.successAlert("preferenza operatore aggiornata con successo");
          self.selectedRow.operator = response.data.data.operator;
          self.fetchData();
        })
        .catch(function (error) {
          // console.log(error);
          // self.$bvModal.hide("modal-resdetails-prenotazioni")
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile salvare la modifica");
        });
    },
  },
  created() {
    // console.log("created prenotazioni", reservationService);
    if (
      this.$route.query.scope &&
      this.filterOptions.service.includes(this.$route.query.scope)
    ) {
      // console.log("changing scope");
      this.filterSetted.service = this.$route.query.scope;
    }

    if (this.$route.query) {
      if (this.$route.query.status)
        this.filterSetted.status = this.$route.query.status;

      if (this.$route.query.service)
        this.filterSetted.service = this.$route.query.service;

      if (this.$route.query.search)
        this.filterSetted.search = this.$route.query.search;

      if (this.$route.query.startWithCalendar) this.showCalendar = true;

      if (this.$route.query.startWithDate)
        this.startWithDate = this.$route.query.startWithDate;

      if (this.$route.query.servicerif)
        this.servicerif = this.$route.query.servicerif;
    }
    this.fetchData();
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.$refs.dett == undefined) {
        // console.log("updating...");
        this.updatingData = true;
        this.fetchData();
      }
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
<style>
thead {
  border-bottom: 1px solid #e0ecde !important;
}

.modal-xl {
  min-width: 80vw !important;
}

@media screen and (max-width: 1000px) {
  .second-line-column-reserv-modal {
    margin-right: 1rem;
  }
}

@media screen and (min-width: 1000px) {
  .second-line-column-reserv-modal {
    margin-right: 2rem;
  }
}

@media screen and (min-width: 992px) {
  #periodo-col {
    min-width: 200px;
  }
}
</style>
