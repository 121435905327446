<template>
  <b-container fluid class="pb-4">
    <div class="d-flex justify-content-between">
      <b-row cols="1" class="text-left mb-4">
        <router-link
          class="text-secondary no__hover"
          :to="{
            name: 'scheda paziente',
            params: { hash: $route.params.hash }
          }"
        >
          <b-icon icon="arrow-left-circle-fill" variant="secondary"></b-icon>
          Scheda paziente
        </router-link>
      </b-row>
    </div>

    <div v-if="reservationConfirmed && newReservation">
      <h2>Prenotazione conclusa</h2>
      <div>
        <h6
          class="text-prim-grad-1 text-left pb-1 border-bottom border-line-grey"
        >
          Riepilogo
        </h6>
        <div>
          <div class="mt-2">
            <p class="text-left text-capitalize">
              Tipo ordine: {{ form.type }}
              <strong v-if="selectedPrestazione">
                {{ selectedPrestazione.name }}</strong
              >
            </p>
            <p class="text-left" v-if="form.data_inizio">
              Data selezionata:
              <strong>
                {{ form.data_inizio | moment("DD/MM/YY HH:mm") }}</strong
              >
            </p>
          </div>
        </div>
        <div
          class="pb-3 text-left"
          v-if="newReservation.resources && newReservation.resources.length > 0"
        >
          <h6
            class="text-prim-grad-1 text-left pb-1 border-bottom border-line-grey"
          >
            Documenti Scaricabili
          </h6>
          <p class="text-left">
            Sono presenti documenti correlati a questo ordine, puoi
            visualizzarli cliccando su ciascuno di essi. I documenti contenenti
            campi da compilare vanno scaricati, stampati, compilati e presentati
            in sede di appuntamento.
          </p>
          <a
            v-for="(allegato, ind) in newReservation.resources"
            :key="ind"
            :href="allegato.full_path"
            target="__blank"
            class="mr-1"
          >
            <b-badge
              variant="secondary"
              class="my-2 py-1 px-2"
              v-b-tooltip.hover
              :title="'apri ' + allegato.name + ' in un\'altra scheda'"
              >{{ allegato.name }}</b-badge
            >
          </a>
        </div>
        <div class="col-12 text-center" v-if="newReservation.data_inizio">
          <b-button variant="secondary m-2" @click="downloadCalendar"
            >Scarica calendar
          </b-button>
          <b-button variant="secondary m-2" @click="downloadPdf"
            >Scarica promemoria
          </b-button>
        </div>
      </div>
    </div>
    <b-container fluid class="pt-2 m-0" v-else-if="subject">
      <b-row cols="1" class="my-1 py-2">
        <h4 class="text-prim-grad-1 text-left pb-1">
          Prenota servizio
          <template v-if="subject.anagrafica">
            per
            <span class="text-capitalize">
              {{ subject.anagrafica.name + " " + subject.anagrafica.surname }}
            </span>
          </template>
          <span v-else> il paziente</span>
        </h4>
        <b-col v-if="canSelectCompany">
          <b-row
            v-if="subjectCircoloCura"
            align-v="stretch"
            class="d-flex flex-nowrap overflow-auto flex-md-wrap"
          >
            <div
              v-for="(azienda, ind) in subjectCircoloCura"
              :key="ind"
              class="mr-4 mb-4"
            >
              <CompanyCard
                class="h-100"
                :azienda="azienda"
                :is-selected="enlight(azienda)"
                :show-heart="false"
                @company-selection="selectCompany($event)"
              />
            </div>
            <b-col class="my-2">
              <div class="align-content-center h-100 d-flex">
                <b-button
                  class="text-left my-auto"
                  @click="$bvModal.show('searchCompany')"
                >
                  <b-icon icon="plus"></b-icon> cerca nuovo
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <div class="mt-4 p-4 puntofarmaCard">
          <b-row
            cols="2"
            class="d-flex align-items-center text-left col-12 col-md-8"
          >
            <b-col>
              <b-form-group>
                <b-form-radio
                  v-model="type"
                  class=""
                  aria-describedby="prestazione"
                  name="res-type"
                  value="prestazione"
                  >prestazione sanitaria</b-form-radio
                >
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-form-radio
                  v-model="type"
                  aria-describedby="teleconsulto"
                  name="res-type"
                  value="teleconsulto"
                  :disabled="selectTcOptionDisabled"
                  >teleconsulto</b-form-radio
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="form.type === 'prestazione'"
            cols="1"
            class="mt-4 mb-2 mx-0 col-12 col-md-8"
          >
            <!-- <PrestazioneSelection
              v-if="form.type === 'prestazione'"
              :company="selectedCompany"
              v-on:selected-prestazione="setRequestPrestazione($event)"
            /> -->
            <!-- 
              " -->
            <SelectCompanyHealthServices
              :key="selectedCompany.hashid"
              :companyHash="selectedCompany.hashid"
              v-model="selectedPrestazioneHash"
              :showPriceAndIVA="true"
              ref="selectCompanyHealthServices"
              label="Scegli una prestazione"
              :disableOptionFunction="
                (option) =>
                  !option.data?.active ||
                  (!option.data?.proxy_scheduling &&
                    (!option.data?.disponibilita ||
                      option.data?.disponibilita.length < 1))
              "
            />
          </b-row>
        </div>
      </b-row>

      <b-row
        cols="1"
        v-if="showDisponibilita"
        class="my-2"
        ref="calendarchoice"
      >
        <h4 class="text-left pb-1 mt-2">Data ed ora appuntamento</h4>

        <div class="mt-2 p-4 puntofarmaCard">
          <b-row class="my-0 py-0">
            <b-col class="text-left col-12 col-md-8">
              <b-form-group label="">
                <b-form-radio
                  v-model="form.data_inizio"
                  aria-describedby="estemporaneo"
                  name="este-time"
                  :value="nowDate"
                  @change="calendarChoice = !calendarChoice"
                >
                  <span v-if="type == 'teleconsulto'">
                    Avvia teleconsulto adesso</span
                  >
                  <span v-else>
                    Imposta appuntamento per oggi
                    {{ nowDate | moment("DD/MM/YYYY or\\e H:mm") }}</span
                  >
                </b-form-radio>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="my-0 py-0" v-if="!showPreferenzeAppuntamento">
            <b-col class="text-left col-12 col-md-8">
              <b-form-group label="">
                <b-form-radio
                  v-model="calendarChoice"
                  aria-describedby="incalendario"
                  name="calendar-time"
                  :value="true"
                >
                  Scegli tra date di disponibilità in calendario</b-form-radio
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="my-0 py-0" v-else>
            <b-col class="text-left col-12 col-md-8">
              <b-form-group label="">
                <b-form-radio
                  v-model="preferenzaChoice"
                  aria-describedby="conpreferenza"
                  name="preference-time"
                  :value="true"
                >
                  Indica preferenze appuntamento</b-form-radio
                >
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-row>

      <b-row cols="1" v-if="showDisponibilita" class="my-2">
        <template v-if="showPreferenzeAppuntamento">
          <h4 class="text-left pb-1 mt-2">Seleziona preferenze</h4>
          <b-col class="mt-2 p-4 puntofarmaCard">
            <PreferenzeSelection
              @preference-setted="handlePreferenceSettings"
            ></PreferenzeSelection>
          </b-col>
        </template>
        <template v-else-if="calendarChoice">
          <h4 class="text-left pb-1 mt-2">Disponibilità</h4>
          <b-col class="mt-2 p-4 puntofarmaCard">
            <DatetimeSelection
              :notitle="true"
              :company="selectedCompany.hashid"
              :service="form.service_id"
              :hservice="health_service_id"
              :restype="form.type"
              @date-selected="form.data_inizio = $event"
            ></DatetimeSelection>
          </b-col>
        </template>
      </b-row>

      <b-row cols="1" v-if="form.data_inizio" class="my-2">
        <h4 class="mt-5 text-prim-grad-1 text-left pb-1">Conferma</h4>
        <div class="mt-4 p-4 puntofarmaCard">
          <b-col>
            <div class="mt-2">
              <p class="text-left text-capitalize">
                <span class="text-secondary">Tipo Prenotazione: </span>
                <span class=""
                  >{{ form.type }}
                  <span class="" v-if="selectedPrestazione">
                    {{ selectedPrestazione.name }}</span
                  ></span
                >
              </p>
              <p class="text-left">
                <span class="text-secondary">Data ed ora selezionate:</span>
                <span class="">
                  {{ form.data_inizio | moment("DD/MM/YYYY hh:mm") }}</span
                >
              </p>
              <b-form-group
                label="Se vuoi, aggiungi una nota per il paziente"
                label-for="subtype"
                class="text-left"
              >
                <b-form-input
                  name="subtype"
                  class="mx-auto"
                  v-model="form.company_notes"
                  required
                >
                </b-form-input>
              </b-form-group>
            </div>
          </b-col>
          <b-col ref="orderConfirm" class="text-center">
            <b-button variant="outline-secondary" class="m-2" @click="resetAll"
              >Annulla</b-button
            >
            <b-button
              variant="secondary text-white "
              class="m-2"
              @click="submitRequest"
            >
              <span v-if="!sending">Procedi</span>
              <b-spinner v-else small></b-spinner>
            </b-button>
          </b-col>
        </div>
      </b-row>
      <template v-else-if="form.user_notes">
        <h4 class="text-left pb-1 mt-3">Conferma</h4>
        <b-row class="mt-2 p-4 puntofarmaCard" cols="1">
          <b-col>
            <div class="mt-2">
              <p class="text-left text-capitalize">
                <span class="text-secondary">Tipo Prenotazione: </span>
                <span class=""
                  >{{ form.type }}
                  <span class="" v-if="selectedPrestazione">
                    {{ selectedPrestazione.name }}</span
                  ></span
                >
              </p>
              <p class="text-left">
                <span class="text-secondary">Preferenze indicate:</span>
                <span class=""> {{ form.user_notes }}</span>
              </p>
              <b-form-group
                label="Se vuoi, aggiungi una nota per il paziente"
                label-for="subtype"
                class="text-left"
              >
                <b-form-input
                  name="subtype"
                  class="mx-auto"
                  v-model="form.company_notes"
                  required
                >
                </b-form-input>
              </b-form-group>
            </div>
          </b-col>
          <b-col ref="orderConfirm" class="text-center">
            <b-button variant="outline-secondary" class="m-2" @click="resetAll"
              >Annulla</b-button
            >
            <b-button
              variant="secondary text-white "
              class="m-2"
              @click="submitRequest"
            >
              <span v-if="!sending">Procedi</span>
              <b-spinner v-else small></b-spinner>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-container>

    <b-modal
      size="xl"
      centered
      id="searchCompany"
      title="Cerca"
      body-class="p-0 pb-4"
      hide-footer
    >
      <SearchCompany
        v-on:new-selection="updatePreferenze($event)"
        class="px-3 py-3 mt-n4"
      />
    </b-modal>
  </b-container>
</template>
<script>
import * as moment from "moment/moment"
import { mapState, mapActions } from "vuex"
import SelectCompanyHealthServices from "@/components/prenotazioni/form/SelectCompanyHealthServices.vue"
import { reservationService, cittadinoService, croomService } from "@/_services"
import FillSurvey from "@/components/utilities/FillSurvey.vue"
import SubjectSelection from "@/components/prenotazioni/form/SubjectSelection.vue"
import PrestazioneSelection from "@/components/prenotazioni/form/PrestazioneSelection.vue"
import TeleconsultoSurveySelection from "@/components/prenotazioni/form/TeleconsultoSurveySelection.vue"
import DatetimeSelection from "@/components/prenotazioni/form/DatetimeSelection.vue"
import CompanyCard from "@/components/prenotazioni/CompanyCard.vue"
import SearchCompany from "@/components/home/SearchCompany.vue"
import PreferenzeSelection from "@/components/prenotazioni/form/PreferenzeSelection.vue"

export default {
  name: "NuovaPrenotazionePaziente",
  components: {
    FillSurvey,
    SubjectSelection,
    PrestazioneSelection,
    TeleconsultoSurveySelection,
    SelectCompanyHealthServices,
    DatetimeSelection,
    CompanyCard,
    SearchCompany,
    PreferenzeSelection
  },
  computed: {
    checkPrecedente: function () {
      var today = moment(this.today).week()
      var start = moment(this.filters.from, "D/M/YY").week()
      return start <= today
    },
    canSelectCompany: function () {
      //l'operatore control room può prenotare per conto del paziente
      if (this.user) {
        return this.user.permessi.includes("gestire liste pazienti")
      }
      return false
    },
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"])
  },
  watch: {
    form: {
      handler(val) {
        if (val.data_inizio !== null) {
          const self = this
          this.$nextTick(function () {
            self.$refs["orderConfirm"].scrollIntoView()
          })
          this.preferenzaChoice = false
        }
      },
      deep: true
    },
    preferenzaChoice: function (val) {
      if (val) {
        this.form.data_inizio = null
      }
    },
    calendarChoice: function (val) {
      if (val && !this.selectedPrestazione.proxy_scheduling) {
        this.form.data_inizio = null
      }
    },
    type: function (val) {
      this.form.type = val
      this.calendarChoice = true
      this.setDetail()
      if (val == "teleconsulto") {
        this.isTc = true
        this.form.service_id =
          this.selectedCompany.servizi_attivi["teleconsulto"]
        // this.setupCalendar();
        this.showDisponibilita = true
        this.showPreferenzeAppuntamento = this.selectedCompany
          .stato_proxy_servizi["teleconsulto"]
          ? true
          : false
      } else {
        this.form.service_id =
          this.selectedCompany.servizi_attivi["prenotazioni"]
        this.isTc = false
        this.showDisponibilita = false
      }
      this.validateService()
    },
    selectedPrestazione: function (val) {
      if (val && this.type === "prestazione") {
        this.showDisponibilita = true
        this.showPreferenzeAppuntamento = val.proxy_scheduling
        this.preferenzaChoice = val.proxy_scheduling
      }
    },
    selectedPrestazioneHash: function (val) {
      if (val && this.$refs.selectCompanyHealthServices) {
        const prestazione =
          this.$refs.selectCompanyHealthServices.originalData.find(
            (el) => el.hashid === val
          )

        this.setRequestPrestazione(prestazione)
      }
    }
  },
  data() {
    return {
      subject: null,
      type: "prestazione",
      form: {
        company_id: null,
        service_id: null,
        data_inizio: null,
        data_fine: null,
        tipologia: "ordine",
        type: "prestazione",
        company_notes: null,
        user_notes: null,
        filled_survey_id: null,
        order: null,
        subject_id: null,
        subject_type: "user_in_app"
      },
      durata: 15,
      health_service_id: null,
      details: null,
      selectedCompany: null,
      selectedPrestazione: null,
      selectedPrestazioneHash: null,
      start: null,
      end: null,
      month: null,
      today: null,
      fields: [],
      disponibilita: null,
      selectedSlot: null,
      selectedCol: null,
      filters: {
        from: null,
        to: null
      },
      showDisponibilita: false,
      reservationConfirmed: false,
      sending: false,
      newReservation: null,
      isTc: false,
      selectTcOptionDisabled: false,
      surveyToFill: null,
      nowDate: null,
      calendarChoice: true,
      subjectCircoloCura: null,
      showPreferenzeAppuntamento: false,
      preferenzaChoice: false
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    ...mapActions("utente", ["me"]),
    validateService() {
      console.log("")
      if (!this.isTc) {
        this.form.service_id =
          this.selectedCompany.servizi_attivi["prenotazioni"]
        this.showPreferenzeAppuntamento = this.selectedCompany
          .stato_proxy_servizi["prenotazioni"]
          ? true
          : false
        if (!this.showPreferenzeAppuntamento) {
          if (this.selectedCompany.stato_servizi["prenotazioni"] == undefined) {
            window.scrollTo(0, 0)
            this.errorAlert("Servizio non attivato sulle impostazioni.")
          } else if (
            this.form.type === "prodotto" &&
            (this.selectedCompany.stato_servizi["prenotazioni"] == 0 ||
              this.selectedCompany.servizi_attivi_orari["prenotazioni"]
                .length == 0)
          ) {
            window.scrollTo(0, 0)
            this.errorAlert(
              "Non ha impostato gli orari di disponibilità per il servizio."
            )
          }
        }
      } else {
        this.form.service_id =
          this.selectedCompany.servizi_attivi["teleconsulto"]
        this.selectTcOptionDisabled = false
        this.showPreferenzeAppuntamento = this.selectedCompany
          .stato_proxy_servizi["teleconsulto"]
          ? true
          : false
        if (!this.showPreferenzeAppuntamento) {
          if (this.selectedCompany.stato_servizi["teleconsulto"] == undefined) {
            window.scrollTo(0, 0)
            this.errorAlert("Servizio non attivato sulle impostazioni.")
            this.selectTcOptionDisabled = true
          } else if (
            this.selectedCompany.stato_servizi["teleconsulto"] == 0 ||
            this.selectedCompany.servizi_attivi_orari["teleconsulto"].length ==
              0
          ) {
            window.scrollTo(0, 0)
            this.errorAlert(
              "Non ha impostato gli orari di disponibilità per il servizio."
            )
            this.selectTcOptionDisabled = true
          }
        }
        // if (
        //   this.selectedCompany.servizi_attivi["teleconsulto"] == undefined ||
        //   this.selectedCompany.servizi_attivi_orari["teleconsulto"].length == 0
        // ) {
        //   window.scrollTo(0, 0);
        //   this.errorAlert(
        //     "La farmacia non ha impostato gli orari per il servizio o non ha attivato il servizio."
        //   );
        //   this.selectTcOptionDisabled = true;
        // }
      }
    },
    formatDisponibilita() {
      const data = {
        hash: this.selectedPrestazione.hashid,
        params: this.filters
      }
      const self = this
      reservationService
        .disponibilitaPrestazione(data)
        .then(function (res) {
          self.disponibilita = [res.data.data]
          // if (res.data.data.length == 0) {
          //   window.scrollTo(0, 0);
          //   self.errorAlert(
          //     "Nessuna disponibilità per la settimana e la prestazione scelta"
          //   );
          // }

          let availability = false

          if (res.data.data.length != 0) {
            Object.values(self.disponibilita[0]).forEach((element) => {
              if (element.length > 0) availability = true
            })
          }

          if (!availability) {
            self.errorAlert(
              "Nessuna disponibilità per la settimana e la prestazione scelta"
            )
          }
        })
        .catch(function (error) {
          self.disponibilita = []
          window.scrollTo(0, 0)
          self.errorAlert(
            "Non è stato possibile trovare disponibilità per questo servizio"
          )
        })
    },
    formatDisponibilitaService() {
      const data = {
        hash: this.form.service_id,
        company: this.form.company_id,
        params: this.filters
      }
      const self = this
      reservationService
        .disponibilitaServizio(data)
        .then(function (res) {
          self.disponibilita = [res.data.data]
          // if (res.data.data.length == 0) {
          //   window.scrollTo(0, 0);
          //   self.errorAlert(
          //     "Nessuna disponibilità per la settimana e la prestazione scelta"
          //   );
          // }
          let availability = false

          if (res.data.data.length != 0) {
            Object.values(self.disponibilita[0]).forEach((element) => {
              if (element.length > 0) availability = true
            })
          }

          if (!availability) {
            self.errorAlert("Nessuna disponibilità per la settimana scelta")
          }
        })
        .catch(function (error) {
          self.disponibilita = []
          window.scrollTo(0, 0)
          self.errorAlert(
            "Non è stato possibile trovare disponibilità per questo servizio"
          )
        })
    },
    resetAll() {
      switch (this.$route.params.type) {
        case "nuovo ordine":
          this.form.type = "prodotto"
          break
        case "nuova prenotazione":
          this.form.type = "prestazione"
          break
        case "nuovo teleconsulto":
          this.form.type = "teleconsulto"
          break
      }
      this.setDetail()
    },
    submitRequest() {
      this.sending = true
      var order = this.form
      if (this.form.type === "prestazione") {
        order.order = { health_service_id: this.health_service_id }
      } else if (this.form.type === "teleconsulto") {
        order.order = { durata: this.durata }
      }
      if (this.showPreferenzeAppuntamento) {
        this.form.tipologia = "appuntamento"
        this.form.data_inizio = undefined
      } else {
        var dataF = this.form.data_inizio
        order.data_inizio = dataF.format("YYYY-MM-DD HH:mm")
      }
      const self = this
      reservationService
        .storeReservation(order)
        .then(function (res) {
          window.scrollTo(0, 0)
          self.successAlert("Prenotazione inviata con successo!")
          self.newReservation = res.data.data
          self.reservationConfirmed = true
          self.sending = false
        })
        .catch(function (error) {
          window.scrollTo(0, 0)
          var msg = "Non è stato possibile inviare la richiesta"
          if (error.data.errors && error.data.errors.duplicate) {
            msg = error.data.errors.duplicate
            self.setupCalendar()
          }
          self.errorAlert(msg)
          self.sending = false
        })
    },
    downloadPdf() {
      const hash = this.newReservation.hashid
      const fName = this.newReservation.codice_richiesta
      const self = this
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".pdf"
          link.click()
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile scaricare il promemoria")
        })
    },
    downloadCalendar() {
      const hash = this.newReservation.hashid
      const fName = this.newReservation.codice_richiesta
      const self = this
      reservationService
        .scaricaCalendar(hash)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".ics"
          link.click()
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile scaricare il promemoria")
        })
    },
    getDuration() {
      const formData = {
        hash: this.form.service_id,
        filters: {
          company: this.form.company_id,
          day: this.selectedCol
        }
      }
      const self = this
      reservationService
        .getServiceOpeningForDay(formData)
        .then(function (res) {
          // console.log(res);
          self.durata = res.data.data[0].interval
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Si è verificato un errore.")
          console.log(err)
        })
    },
    setDetail() {
      this.form.data_inizio = null
      this.form.data_fine = null
      this.selectedSlot = null
      this.selectedCol = null
      this.showDisponibilita = false
      this.selectedPrestazione = null
      this.health_service_id = null
      this.form.filled_survey_id = null
    },
    setRequestSubject(subject) {
      this.form.subject_id = subject.subject_id
      this.form.subject_type = subject.subject_type
    },
    setRequestType(type) {
      this.form.type = type
      this.setDetail()
    },
    setRequestPrestazione(prestazione) {
      this.selectedPrestazione = prestazione
      this.health_service_id = this.selectedPrestazione.hashid
      this.form.data_inizio = null
      this.showDisponibilita = true
      this.showPreferenzeAppuntamento =
        this.selectedPrestazione.proxy_scheduling
      this.preferenzaChoice = this.selectedPrestazione.proxy_scheduling
      this.calendarChoice = !this.selectedPrestazione.proxy_scheduling
      // this.setupCalendar();
    },
    setRequestTcSurvey(survey) {
      // console.log(survey);
      this.surveyToFill = survey
      this.setupCalendar()
    },
    fetchUser() {
      const self = this
      cittadinoService
        .getUserById(this.$route.params.hash)
        .then(function (res) {
          self.subject = res.data.data
          self.setRequestSubject({
            subject_id: res.data.data.hashid,
            subject_type: "user_in_app"
          })
          if (self.canSelectCompany) {
            self.fetchUserCircoloCura()
          }
        })
        .catch(function (err) {
          console.log(err)
          self.notFound = true
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le informazioni.")
        })
    },
    formatFullDate(date) {
      return moment(date).format("dddd DDD/MM/YYYY hh:mm")
    },
    fetchUserCircoloCura() {
      const self = this
      croomService
        .getUserCircoloCura(this.$route.params.hash, {})
        .then(function (res) {
          self.subjectCircoloCura = res.data.data
        })
        .catch(function (err) {
          console.log(err)
          self.notFound = true
          self.errorAlert("Non è stato possibile recuperare le informazioni.")
        })
    },
    enlight(comp) {
      return this.selectedCompany && comp.hashid === this.selectedCompany.hashid
        ? true
        : false
    },
    updatePreferenze(nuovaPreferita) {
      this.$bvModal.hide("searchCompany")
      this.selectCompany(nuovaPreferita)
      this.form.company_id = nuovaPreferita.hashid
      this.form.service_id = this.isTc
        ? nuovaPreferita.servizi_attivi["teleconsulto"]
        : nuovaPreferita.servizi_attivi["prenotazioni"]
    },
    selectCompany(data) {
      this.selectedCompany = data
      this.selectedPrestazione = null
      this.surveyToFill = null
      this.showDisponibilita = false
      this.showPreferenzeAppuntamento = false
      this.form.data_inizio = null
      this.selectedSlot = null
      this.selectedCol = null
      this.form.company_id = data.hashid
      const self = this
      this.$nextTick(function () {
        self.validateService()
        self.setDetail()
      })
    },
    handlePreferenceSettings(data) {
      this.form.user_notes = data
    }
  },
  created() {
    if (this.$route.query && this.$route.query.reservationType) {
      if (this.$route.query.reservationType === "teleconsulto") {
        this.type = "teleconsulto"
      }
    }
    this.fetchUser()
    this.start = moment().startOf("week")
    this.end = moment().endOf("week")
    this.today = moment()
    this.month = moment().month()
    this.filters.from = moment().startOf("week").format("D/M/YY")
    this.filters.to = moment().endOf("week").format("D/M/YY")
  },
  mounted() {
    this.selectedCompany = this.user.company
    this.form.company_id = this.user.company.hashid
    this.validateService()
    this.nowDate = moment().add(5, "minutes")
  }
}
</script>
<style lang="scss">
.select__dis:disabled {
  background-color: #fff !important;
  color: inherit !important;
}
.table-text-grey {
  background-color: #fff !important;
}
</style>
