<template>
  <div>
    <!-- <h5 class="text-left">
      Soglie sul parametro <slot name="examname"></slot>
    </h5> -->
    <b-overlay :show="loading" rounded="sm">
      <b-row v-if="orderedCustoms">
        <b-col cols="12" class="px-0">
          <h6>
            Valori di soglia sul parametro <slot name="examname"></slot> per il
            paziente
          </h6>
        </b-col>
        <p class="text-justify">
          Configurando le soglie di criticità dei valori su un parametro quando
          il paziente inserisce un valore fuori norma viene generato un alert
          del corrispettivo livello di severità. I valori al di sotto della
          soglia ritenuta "normale" si trovano sul lato sinistro della tabella,
          sulla destra invece le soglie per i valori in eccesso. Clicca sul
          tasto-icona matita per modificare il valore-soglia del corrispondente
          livello di severità. E' possibile impostare da un minimo di 1 ad un
          massimo di 7 soglie.
        </p>
        <b-table
          borderless
          stickyColumn
          :fields="fields"
          :items="[orderedCustoms]"
          :ref="exam + '_reftable'"
          class="text-center"
        >
          <template #cell(-3)="row">
            <ThresholdEditor
              :reference="row.value"
              :editable="true"
              @save-edits="handleReferenceEdit($event, row)"
              @remove-reference="doRemoveReference($event)"
            ></ThresholdEditor>
            <ThresholdNotesEditor
              :reference="row.value"
              :editable="true"
              :soglia="'l3'"
              @save-edits="updateReferenceNotes($event, row.value.hashid)"
            ></ThresholdNotesEditor>
          </template>
          <template #cell(-2)="row">
            <ThresholdEditor
              :reference="row.value"
              :editable="true"
              @save-edits="handleReferenceEdit($event, row)"
              @remove-reference="doRemoveReference($event)"
            ></ThresholdEditor>
            <ThresholdNotesEditor
              :reference="row.value"
              :editable="true"
              :soglia="'l2'"
              @save-edits="updateReferenceNotes($event, row.value.hashid)"
            ></ThresholdNotesEditor>
          </template>
          <template #cell(-1)="row">
            <ThresholdEditor
              :reference="row.value"
              :editable="true"
              @save-edits="handleReferenceEdit($event, row)"
              @remove-reference="doRemoveReference($event)"
            ></ThresholdEditor>
            <ThresholdNotesEditor
              :reference="row.value"
              :editable="true"
              :soglia="'l1'"
              @save-edits="updateReferenceNotes($event, row.value.hashid)"
            ></ThresholdNotesEditor>
          </template>
          <template #cell(0)="row">
            <ThresholdEditor
              :reference="row.value"
              :editable="true"
              @save-edits="handleReferenceEdit($event, row)"
              @remove-reference="doRemoveReference($event)"
            ></ThresholdEditor>
            <ThresholdNotesEditor
              :reference="row.value"
              :editable="true"
              :soglia="'vn'"
              @save-edits="updateReferenceNotes($event, row.value.hashid)"
            ></ThresholdNotesEditor>
          </template>
          <template #cell(1)="row">
            <ThresholdEditor
              :reference="row.value"
              :editable="true"
              @save-edits="handleReferenceEdit($event, row)"
              @remove-reference="doRemoveReference($event)"
            ></ThresholdEditor>
            <ThresholdNotesEditor
              :reference="row.value"
              :editable="true"
              :soglia="'h1'"
              @save-edits="updateReferenceNotes($event, row.value.hashid)"
            ></ThresholdNotesEditor>
          </template>
          <template #cell(2)="row">
            <ThresholdEditor
              :reference="row.value"
              :editable="true"
              @save-edits="handleReferenceEdit($event, row)"
              @remove-reference="doRemoveReference($event)"
            ></ThresholdEditor>
            <ThresholdNotesEditor
              :reference="row.value"
              :editable="true"
              :soglia="'h2'"
              @save-edits="updateReferenceNotes($event, row.value.hashid)"
            ></ThresholdNotesEditor>
          </template>
          <template #cell(3)="row">
            <ThresholdEditor
              :reference="row.value"
              :editable="true"
              @save-edits="handleReferenceEdit($event, row)"
              @remove-reference="doRemoveReference($event)"
            ></ThresholdEditor>
            <ThresholdNotesEditor
              :reference="row.value"
              :editable="true"
              :soglia="'h3'"
              @save-edits="updateReferenceNotes($event, row.value.hashid)"
            ></ThresholdNotesEditor>
          </template>
        </b-table>
      </b-row>
      <b-row
        v-else-if="orderedDefaults"
        align-v="center"
        class="justify-content-between"
      >
        <template>
          <h6>
            Valori di default per il parametro <slot name="examname"></slot>
          </h6>
          <p class="text-justify">
            Di seguito i valori-soglia disponibili per il parametro. Clicca su
            "utilizza valori di default per il monitoraggio" per impostare per
            il monitoraggio del paziente i valori di default; dopo il
            salvataggio potrai, se necessario, personalizzare i parametri in
            base alle esigenze del paziente.
          </p>
          <b-table
            borderless
            stickyColumn
            :fields="fields"
            :items="[orderedDefaults]"
            :ref="exam + '_reftable'"
            class="text-center"
          >
            <template #cell(-3)="row">
              <ThresholdEditor
                :reference="row.value"
                @save-edits="handleReferenceEdit($event, row)"
              ></ThresholdEditor>
            </template>
            <template #cell(-2)="row">
              <ThresholdEditor
                :reference="row.value"
                @save-edits="handleReferenceEdit($event, row)"
              ></ThresholdEditor>
            </template>
            <template #cell(-1)="row">
              <ThresholdEditor
                :reference="row.value"
                @save-edits="handleReferenceEdit($event, row)"
              ></ThresholdEditor>
            </template>
            <template #cell(0)="row">
              <ThresholdEditor
                :reference="row.value"
                @save-edits="handleReferenceEdit($event, row)"
              ></ThresholdEditor>
            </template>
            <template #cell(1)="row">
              <ThresholdEditor
                :reference="row.value"
                @save-edits="handleReferenceEdit($event, row)"
              ></ThresholdEditor>
            </template>
            <template #cell(2)="row">
              <ThresholdEditor
                :reference="row.value"
                @save-edits="handleReferenceEdit($event, row)"
              ></ThresholdEditor>
            </template>
            <template #cell(3)="row">
              <ThresholdEditor
                :reference="row.value"
                @save-edits="handleReferenceEdit($event, row)"
              ></ThresholdEditor>
            </template>
          </b-table>
        </template>
        <b-col cols="12" class="mt-4">
          <b-row class="justify-content-center">
            <b-button
              variant="outline-secondary"
              class="mx-2"
              @click="$emit('close-editmodal')"
              >Annulla</b-button
            >
            <b-button
              variant="secondary"
              class="mx-2"
              @click="storeDefaultAsCustom"
              >Utilizza valori di default per il monitoraggio</b-button
            >
          </b-row>
        </b-col>
      </b-row>
      <b-row v-else align-v="center" class="justify-content-between">
        <h6>
          Non ci sono valori di default per il parametro
          <slot name="examname"></slot>
        </h6>
        <b-button size="sm" class="" @click="newCustomSet">
          <b-icon icon="plus"></b-icon> soglie personalizzate</b-button
        >
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import { monitoringService } from "@/_services";
import { mapState, mapActions } from "vuex";
import ThresholdEditor from "@/components/utilities/ThresholdEditor.vue";
import ThresholdNotesEditor from "@/components/utilities/ThresholdNotesEditor.vue";
export default {
  name: "SoglieRilevazioni",
  props: ["exam", "subject"],
  components: { ThresholdEditor, ThresholdNotesEditor },
  data() {
    return {
      params: {
        user: this.subject,
      },
      defaults: null,
      customs: null,
      orderedDefaults: null,
      orderedCustoms: null,
      fields: [
        { key: "-3", label: "Alta severità", class: "bg-severity-3" },
        { key: "-2", label: "Media severità", class: "bg-severity-2" },
        { key: "-1", label: "Bassa severità", class: "bg-severity-1" },
        { key: "0", label: "Normale", class: "bg-severity-none" },
        { key: "1", label: "Bassa severità", class: "bg-severity-1" },
        { key: "2", label: "Media severità", class: "bg-severity-2" },
        { key: "3", label: "Alta severità", class: "bg-severity-3" },
      ],
      // referencesToStore: [],
      referencesToUpdate: [],
      loading: true,
    };
  },
  watch: {
    defaults: function (value) {
      if (value && value.length > 0) {
        const orderedValues = {
          "-3": value.find((el) => el.threshold_level === -3),
          "-2": value.find((el) => el.threshold_level === -2),
          "-1": value.find((el) => el.threshold_level === -1),
          0: value.find((el) => el.threshold_level === 0),
          1: value.find((el) => el.threshold_level === 1),
          2: value.find((el) => el.threshold_level === 2),
          3: value.find((el) => el.threshold_level === 3),
        };
        this.orderedDefaults = orderedValues;
        // return this.defaults.sort((first, next) => first.threshold_level < next.threshold_level).map(function (el) { return { [el.threshold_level]: el } })
      }
    },
    customs: function (value) {
      if (value && value.length > 0) {
        const orderedValues = {
          "-3": value.find((el) => el.threshold_level === -3),
          "-2": value.find((el) => el.threshold_level === -2),
          "-1": value.find((el) => el.threshold_level === -1),
          0: value.find((el) => el.threshold_level === 0),
          1: value.find((el) => el.threshold_level === 1),
          2: value.find((el) => el.threshold_level === 2),
          3: value.find((el) => el.threshold_level === 3),
        };
        this.orderedCustoms = orderedValues;
        // return this.defaults.sort((first, next) => first.threshold_level < next.threshold_level).map(function (el) { return { [el.threshold_level]: el } })
      }
    },
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      const self = this;
      monitoringService
        .getThresholds(this.exam, this.params)
        .then(function (response) {
          self.defaults = response.data.data.defaults;
          self.customs = response.data.data.customs;
          self.loading = false;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
    handleReferenceEdit(edits, reference) {
      console.log("edits", edits);
      console.log("reference", reference);
      if (reference.value && reference.value.hashid) {
        reference.value.min_ref = parseFloat(edits.min_ref);
        reference.value.max_ref = parseFloat(edits.max_ref);
        this.updateReference(reference.value);
      } else {
        this.loading = true;
        const formData = {
          user: this.subject,
          references: [
            {
              min_ref: parseFloat(edits.min_ref),
              max_ref: parseFloat(edits.max_ref),
              threshold_level: parseInt(reference.field.key),
            },
          ],
        };
        this.storeReference(formData);
      }
    },
    updateReference(reference) {
      const self = this;
      const formData = {
        min_ref: reference.min_ref,
        max_ref: reference.max_ref,
      };
      monitoringService
        .updateUserThreshold(reference.hashid, formData)
        .then(function (response) {
          // self.fetchData();
          self.successAlert("Valori modificati correttamente");
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile modificare il valore");
        });
    },
    updateReferenceNotes(edits, hashid) {
      const self = this;
      monitoringService
        .updateUserThresholdNotes(hashid, edits)
        .then(function (response) {
          // self.fetchData();
          self.successAlert("Note aggiornate correttamente");
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile aggiornare le note");
        });
    },
    doRemoveReference(referenceHash) {
      this.loading = true;
      const self = this;
      monitoringService
        .deleteUserThreshold(referenceHash)
        .then(function (response) {
          self.fetchData();
          self.successAlert("Soglia eliminata correttamente");
          self.$emit("need-update");
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile eliminare il valore");
        });
    },
    newCustomSet() {
      for (let x = -3; x <= 3; x++) {
        this.customs.push({
          min_ref: null,
          max_ref: null,
          threshold_level: x,
        });
      }
      console.log(this.customs);
    },
    storeDefaultAsCustom() {
      this.loading = true;
      const formData = {
        user: this.subject,
        references: this.defaults.map((el) => ({
          min_ref: el.min_ref,
          max_ref: el.max_ref,
          threshold_level: el.threshold_level,
        })),
      };
      this.storeReference(formData);
    },
    storeReference(formData) {
      const self = this;
      monitoringService
        .storeUserThresholds(this.exam, formData)
        .then(function (response) {
          self.fetchData();
          self.successAlert("Valori impostati correttamente");
          self.$emit("need-update");
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {},
};
</script>
<style>
thead {
  border-bottom: none !important;
}
</style>
