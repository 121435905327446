<template>
  <b-form-group
    :label="label ? label : ''"
    :class="formGroupClasses"
    v-if="!(healthServicesOptions.length > 0 && noAvailableOptions)"
  >
    <b-form-select
      :size="size"
      v-model="computedHash"
      v-bind="{ ...requiredAttr, ...disabledAttr }"
    >
      <template v-for="option in filteredOptions">
        <b-form-select-option
          :key="option.value"
          :value="option.value"
          :disabled="disableOptionFunction(option)"
        >
          {{ option.text }}
        </b-form-select-option>
      </template>
      <template v-if="isLoading">
        <b-form-select-option disabled :value="null">
          Caricamento in corso...
        </b-form-select-option>
      </template>
    </b-form-select>
  </b-form-group>
  <p class="d-flex justify-content-start" v-else>
    Non ci sono prestazioni attive e disponibili
  </p>
</template>

<script>
import { companyService, supportService } from "../../../_services"
import { mapState, mapActions } from "vuex"

export default {
  name: "SelectCompanyHealthServices",
  props: {
    value: {
      type: String,
      default: null,
      required: false
    },
    formGroupClasses: {
      type: String,
      default: "text-left text-secondary"
    },
    label: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    appendToEndOptions: {
      type: Array,
      default: () => []
    },
    prependToStartOptions: {
      type: Array,
      default: () => []
    },
    hideDefaultOption: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "md"
    },
    showDescriptions: {
      type: Boolean,
      default: false
    },
    excludeArray: {
      type: Array,
      default: () => []
    },
    disableOptionFunction: {
      type: Function,
      default: () => false
    },
    startWithHash: {
      type: String,
      default: null
    },
    showPriceAndIVA: {
      type: Boolean,
      default: false
    },
    companyHash: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      healthServicesOptions: [],
      defaultOption: { text: "scegli prestazione", value: null },
      hashInitialized: false,
      originalData: [],
      isLoading: true
    }
  },
  watch: {
    value() {
      this.$emit("selected-new-health-service", this.selectedHSObject ?? null)
    }
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert
    }),
    computedHash: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit("input", newValue)
        this.$emit("selected-new-health-service", this.selectedHSObject)
      }
    },
    selectedHSObject() {
      return this.value
        ? this.originalData.find((el) => el.hashid === this.value)
        : null
    },
    requiredAttr() {
      return this.required ? { required: true } : {}
    },
    disabledAttr() {
      return this.disabled ? { disabled: true } : {}
    },
    filteredOptions() {
      return this.optionsWithAppended.filter(
        (option) => !this.excludeArray.includes(option.value)
      )
    },
    optionsWithAppended() {
      if (this.hideDefaultOption) {
        return [
          ...this.prependToStartOptions,
          ...this.healthServicesOptions,
          ...this.appendToEndOptions
        ]
      } else {
        return [
          this.defaultOption,
          ...this.prependToStartOptions,
          ...this.healthServicesOptions,
          ...this.appendToEndOptions
        ]
      }
    },
    noAvailableOptions() {
      return this.healthServicesOptions.every(
        (option) =>
          this.excludeArray.includes(option.value) ||
          this.disableOptionFunction(option)
      )
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    getOwnHealthServices() {
      this.isLoading = true
      companyService
        .getOwnHealthServices()
        .then((res) => {
          this.originalData = res.data.data

          //costruisco le opzioni per la select
          this.healthServicesOptions = res.data.data.map((el) => ({
            text: this.setOptionText(el),
            value: el.hashid,
            data: el
          }))

          //se c'è un hash passato come prop e corrisponde ad una prestazione sanitaria
          //allora lo setto come valore iniziale
          if (
            this.startWithHash &&
            this.originalData.find((el) => el.hashid === this.startWithHash) !==
              undefined
          ) {
            this.$emit("input", this.startWithHash)
            this.$emit("selected-new-health-service", this.startWithHash)
          }
        })
        .catch((err) => {
          this.errorAlert("Errore nel caricamento delle prestazioni sanitarie")
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setOptionText(healthService) {
      let name = healthService.name
      let description = healthService.description
      if (this.showDescriptions && description) {
        name = `${name} - ${description}`
      }
      if (this.showPriceAndIVA && healthService.prezzo > 0) {
        const iva =
          healthService.iva > 0
            ? " - iva " + healthService.iva + "% esclusa"
            : " - iva inclusa"
        name =
          name +
          " - " +
          healthService.prezzo.toLocaleString("it-IT", {
            style: "currency",
            currency: "EUR"
          }) +
          iva
      }
      return name
    },
    getHealthServicesForAnotherCompany() {
      this.isLoading = true
      supportService
        .getCompanyHealthServices(this.companyHash)
        .then((res) => {
          this.originalData = res.data.data

          //costruisco le opzioni per la select
          this.healthServicesOptions = res.data.data.map((el) => ({
            text: this.setOptionText(el),
            value: el.hashid,
            data: el
          }))

          //se c'è un hash passato come prop e corrisponde ad una prestazione sanitaria
          //allora lo setto come valore iniziale
          if (
            this.startWithHash &&
            this.originalData.find((el) => el.hashid === this.startWithHash) !==
              undefined
          ) {
            this.$emit("input", this.startWithHash)
            this.$emit("selected-new-health-service", this.startWithHash)
          }
        })
        .catch((err) => {
          this.errorAlert("Errore nel caricamento delle prestazioni sanitarie")
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  created() {
    if (
      this.companyHash &&
      this.user.permessi.includes("gestire liste pazienti")
    ) {
      this.getHealthServicesForAnotherCompany()
    } else this.getOwnHealthServices()
  }
}
</script>
