<template>
  <div>
    <RiepilogoPrenotazioneDaCROperator
      :newReservation="newReservation"
      :selectedData="selectedData"
      :productOrder="selectedData.productOrder"
      :fileOrder="selectedData.fileOrder"
    >
    </RiepilogoPrenotazioneDaCROperator>

    <p class="d-flex justify-content-start">
      <b-button
        @click="
          $router.push({
            name: 'CompanyHome'
          })
        "
        variant="outline-secondary"
        class="mr-3"
        >Vai in Homepage</b-button
      >
      <b-button
        @click="navigateToReservation()"
        variant="outline-secondary"
        class="mr-3"
        >Vedi prenotazione</b-button
      >
      <b-button
        @click="
          $router.push({
            name: 'centri erogatori'
          })
        "
        variant="secondary"
        class="mr-3"
        >Contatta i centri</b-button
      >
    </p>
  </div>
</template>

<script>
import RiepilogoPrenotazioneDaCROperator from "@/components/prenotazioni/paziente/reservationSettingSteps/RiepilogoPrenotazioneDaCROperator.vue"

export default {
  name: "Riepilogo",
  props: {
    selectedData: {
      type: Object,
      required: true
    },
    newReservation: {
      type: Object,
      required: true
    }
  },
  components: {
    RiepilogoPrenotazioneDaCROperator
  },
  methods: {
    navigateToReservation() {
      let routeName =
        this.newReservation.tipo_ordine === "teleconsulto"
          ? "teleconsulto"
          : "prenotazioni"
      this.$router.push({
        name: routeName,
        params: {
          section: "prenotazioni"
        }
      })
    }
  }
}
</script>

<style scoped></style>
