<template>
  <div class="pb-2" v-if="form !== null">
    <b-row cols="2" class="justify-items-between" align-v="center">
      <b-col class="text-left px-0">
        <h4 class="text-left pb-2">Nuova Azienda</h4>
      </b-col>
      <b-col>
        <b-form-checkbox-group v-model="selectedArea">
          <b-form-checkbox
            v-for="(areaGroup, name) in subtypes"
            :key="name"
            :value="name"
            >{{ name }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-col>
    </b-row>
    <!-- <h4 class="text-left pb-2">Anagrafica Azienda</h4> -->
    <b-form
      @submit.prevent="submitAnagrafica"
      id="anagrafica_form"
      class="bg-white general_border pb-3 text-left"
    >
      <!-- <h5 class="mt-2 pt-4">
        <b-icon icon="person"></b-icon> Titolare
      </h5> -->
      <b-row cols="1" cols-md="2" cols-xl="3" class="px-3 pt-2">
        <b-col>
          <b-form-group label="Nome Titolare" label-for="name" class="">
            <b-form-input
              autofocus
              class="my-3"
              name="name"
              type="text"
              placeholder="nome..."
              v-model="form.nome"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Cognome Titolare" label-for="surname" class="">
            <b-form-input
              class="my-3"
              name="surname"
              type="text"
              placeholder="cognome..."
              v-model="form.cognome"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- <h5 class="mt-2 pt-4">
        <b-icon icon="credit-card2-front"></b-icon> Informazioni fiscali
      </h5> -->
      <b-row cols="1" cols-md="2" cols-xl="3" class="px-3">
        <b-col>
          <b-form-group
            label="Denominazione"
            label-for="denominazione"
            class=""
          >
            <b-form-input
              class="my-3"
              name="denominazione"
              type="text"
              placeholder="denominazione..."
              v-model="form.denominazione"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Codice Tracciabilità"
            label-for="codice_tracciabilita"
          >
            <b-form-input
              class="my-3"
              name="codice_tracciabilita"
              type="text"
              placeholder="codice tracciabilità..."
              v-model="form.codice_tracciabilita"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Codice asl" label-for="codice_asl">
            <b-form-input
              class="my-3"
              name="codice_asl"
              type="text"
              placeholder="codice asl..."
              v-model="form.codice_asl"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Codice Fiscale" label-for="codice_fiscale">
            <b-form-input
              class="my-3"
              name="codice_fiscale"
              type="text"
              placeholder="codice fiscale..."
              v-model="form.codice_fiscale"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Partita Iva" label-for="partita_iva">
            <b-form-input
              class="my-3"
              name="partita_iva"
              type="text"
              placeholder="partita iva..."
              v-model="form.partita_iva"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Contatto DPO" label-for="contatto_dpo">
            <b-form-input
              class="my-3"
              name="contatto_dpo"
              type="text"
              placeholder="contatto dpo..."
              v-model="form.contatto_dpo"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Tipologia Azienda" label-for="company_subtype">
            <b-form-select
              v-capitalize-first-select
              v-if="subtypes"
              class="my-2"
              name="company_subtype"
              v-model="form.subtype_id"
              required
            >
              <b-form-select-option
                v-for="(subtype, ind) in subtypes[selectedArea]"
                :key="ind"
                :value="subtype.hashid"
              >
                {{ subtype.name }}</b-form-select-option
              >
              <b-form-select-option :value="null">
                Seleziona tipologia</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- <h5 class="mt-2 pt-4">
        <b-icon icon="geo"></b-icon> Indirizzo
      </h5> -->
      <b-row cols="1" cols-md="2" cols-xl="3" class="px-3">
        <b-col>
          <b-form-group label="Regione" label-for="regione">
            <b-form-select
              v-capitalize-first-select
              class="my-3"
              :options="regioni"
              name="regione"
              v-model="selected.regione"
              @change="getProvince(selected)"
              required
            >
              <b-form-select-option :value="null"
                >Seleziona Regione</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Provincia" label-for="provincia">
            <b-form-select
              v-capitalize-first-select
              class="my-3"
              :options="province"
              name="provincia"
              v-model="selected.provincia"
              @change="getComuni(selected)"
              required
            >
              <b-form-select-option :value="null"
                >Seleziona Provincia</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Comune" label-for="comune">
            <b-form-select
              v-capitalize-first-select
              class="my-3"
              name="comune"
              v-model="selected.comune"
              :key="
                comuni && comuni.length
                  ? comuni[0].comune + comuni.length
                  : null
              "
              required
            >
              <b-form-select-option
                v-for="(com, ind) in comuni"
                :key="ind"
                :value="com"
                @change="setDomicilioData"
                :selected="findComune(com)"
                >{{ com.comune }}</b-form-select-option
              >
              <b-form-select-option :value="null"
                >Seleziona Comune</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Frazione" label-for="frazione">
            <b-form-input
              class="my-3"
              name="frazione"
              type="text"
              placeholder="frazione..."
              v-model="form.frazione"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Indirizzo e numero civico" label-for="indirizzo">
            <b-form-input
              class="my-3"
              name="indirizzo"
              type="text"
              placeholder="indirizzo..."
              v-model="form.indirizzo"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="CAP" label-for="cap">
            <b-form-input
              class="my-3"
              name="cap"
              type="number"
              step="any"
              placeholder="CAP..."
              v-model="form.cap"
              minlength="5"
              maxlength="5"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Latitudine" label-for="latitudine">
            <b-form-input
              class="my-3"
              name="latitudine"
              type="number"
              step="any"
              placeholder="lat..."
              v-model="form.latitudine"
              minlength="5"
              maxlength="5"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Longitudine" label-for="longitudine">
            <b-form-input
              class="my-3"
              name="longitudine"
              type="number"
              step="any"
              placeholder="long..."
              v-model="form.longitudine"
              minlength="5"
              maxlength="5"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- <h5 class="mt-2 pt-4">
        <b-icon icon="mailbox"></b-icon> Contatti
      </h5> -->
      <b-row cols="1" cols-md="2" cols-xl="3" class="px-3">
        <b-col>
          <b-form-group label="Email" label-for="email">
            <b-form-input
              class="my-3"
              name="email"
              type="email"
              placeholder="email..."
              v-model="form.email"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Pec" label-for="emailpec">
            <b-form-input
              class="my-3"
              name="emailpec"
              type="email"
              placeholder="pec..."
              v-model="form.emailpec"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Telefono" label-for="telefono">
            <b-form-input
              class="my-3"
              name="telefono"
              type="text"
              placeholder="telefono..."
              v-model="form.telefono"
              minlength="8"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Sito web" label-for="sito_web">
            <b-form-input
              class="my-3"
              name="sito_web"
              type="text"
              placeholder="sito web..."
              v-model="form.sito_web"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group v-if="projects" label="Progetto" label-for="project">
            <b-form-select
              v-capitalize-first-select
              class="mb-3 mt-2 text-capitalize"
              name="project"
              v-model="form.project_id"
            >
              <b-form-select-option
                v-for="project in projects"
                :value="project.hashid"
                :key="project.hashid"
                >{{ project.name }}</b-form-select-option
              >
              <b-form-select-option :value="null"
                >Nessun progetto</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Referente" label-for="referente_fullname">
            <b-form-input
              class="my-3"
              name="referente_fullname"
              type="text"
              placeholder="referente..."
              v-model="form.referente_fullname"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Email referente" label-for="referente_email">
            <b-form-input
              class="my-3"
              name="referente_email"
              type="text"
              placeholder="email referente..."
              v-model="form.referente_email"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Telefono referente" label-for="referente_telefono">
            <b-form-input
              class="my-3"
              name="referente_telefono"
              type="text"
              placeholder="telefono referente..."
              v-model="form.referente_telefono"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="col-12 d-flex mt-3 text-center">
        <!-- <div class="col-6 text-center"> -->
        <b-button type="submit" variant="secondary mx-auto ">Salva </b-button>
        <!-- </div> -->
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex"
import { adminService, utilityService } from "@/_services"

export default {
  name: "NuovaCompany",
  components: {
    // UploadComponent,
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utility", [
      "searching",
      "comuni",
      "province",
      "regioni",
      "projects"
    ])
  },
  data() {
    return {
      form: {
        nome: null,
        cognome: null,
        denominazione: null,
        codice_tracciabilita: 0,
        codice_asl: null,
        codice_fiscale: null,
        partita_iva: null,
        regione: null,
        provincia: null,
        comune: null,
        frazione: null,
        indirizzo: null,
        cap: null,
        email: null,
        emailpec: null,
        telefono: null,
        sito_web: null,
        settore: "farmaceutico",
        subtype_id: null,
        latitudine: null,
        longitudine: null,
        project_id: null,
        referente_fullname: null,
        referente_email: null,
        referente_telefono: null
      },
      selected: {
        regione: null,
        provincia: null,
        comune: null
      },
      subtypes: null,
      selectedArea: "farmaceutica",
      settori: ["farmaceutico"],
      servizi: {
        adesione_pf: true
      }
    }
  },
  watch: {
    selected: {
      handler(val) {
        // console.log("selected changed");
        if (val.comune !== null) {
          this.form.comune = this.selected.comune.comune
          this.form.provincia = this.selected.comune.provincia
          this.form.regione = this.selected.comune.regione
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("utility", [
      "getComuni",
      "getProvince",
      "getRegioni",
      "getProjects"
    ]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    setDomicilioData() {
      // console.log("on change", this.selected.comune);
      if (this.selected.comune) {
        this.form.comune = this.selected.comune.comune
        this.form.provincia = this.selected.comune.provincia
        this.form.regione = this.selected.comune.regione
      }
    },
    findComune(comune) {
      if (this.form && this.form.comune) {
        if (comune.comune === this.form.comune) {
          this.selected.comune = comune
          return true
        }
      }
      return false
    },
    getSubtypes() {
      var self = this
      utilityService
        .getSubtypes()
        .then(function (res) {
          self.subtypes = res.data.data
        })
        .catch(function (err) {
          console.log(err)
        })
    },
    submitAnagrafica() {
      const self = this
      console.log(this.form)
      adminService
        .saveNewCompany(this.form)
        .then(function (res) {
          window.scrollTo(0, 0)
          self.successAlert("azienda creata con successo")
          self.resetForm()
        })
        .catch(function (error) {
          self.errorAlert("si è verificato un errore")
        })
    },
    resetForm() {
      this.form = {
        nome: null,
        cognome: null,
        denominazione: null,
        codice_tracciabilita: null,
        codice_asl: null,
        codice_fiscale: null,
        partita_iva: null,
        contatto_dpo: null,
        regione: null,
        provincia: null,
        comune: null,
        frazione: null,
        indirizzo: null,
        cap: null,
        email: null,
        emailpec: null,
        telefono: null,
        sito_web: null,
        settore: "farmaceutico",
        subtype_id: null,
        latitudine: null,
        longitudine: null,
        project_id: null
      }
      this.selected = {
        regione: null,
        provincia: null,
        comune: null
      }
    }
  },
  created() {
    this.getRegioni()
    this.getSubtypes()
    this.getProjects()
  }
}
</script>
