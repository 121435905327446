<template>
  <div class="col-12 p-0">
    <b-tabs
      content-class="p-0"
      active-nav-item-class="text-secondary"
      small
      nav-class="text-secondary"
      no-key-nav
      no-nav-style
    >
      <b-tab :active="tabIndex == 0" title-link-class="d-none">
        <b-form key="geo-search" v-if="!formSearch">
          <b-button
            variant="secondary"
            @click="formSearch = true"
            class="mb-2 col-2"
            block
          >
            <b>Torna indietro</b>
          </b-button>
          <p v-if="results && results.length == 0" class="text-danger mb-0">
            Nessun risultato con questi parametri di ricerca.
          </p>
          <p class="text-left" text-variant="secondary">
            Utilizza il campo "range" per aumentare o diminuire il raggio di
            ricerca
          </p>
          <p class="text-left text-danger" v-if="!userCoordinates">
            Per utilizzare questa funzionalità devi acconsentire alla
            condivisione della tua posizione.
            <b class="point" @click="startGeolocal">Concedi consenso</b>
          </p>
          <b-form-group class="col-12 col-md-8">
            <label for="range-2" class="text-secondary">Range:</label>
            <b-form-input
              id="range-2"
              v-model="searchFilters.radius"
              type="range"
              :min="50"
              :max="1000"
              step="50"
            >
            </b-form-input>
          </b-form-group>

          <span class="my-2 col-12 col-md-3"
            >{{ searchFilters.radius }} metri</span
          >
          <div class="text-center">
            <b-button variant="secondary" @click="doGeolocalSearch">
              <span v-if="!spinSearch"> Ripeti ricerca</span>
              <b-spinner v-else></b-spinner>
            </b-button>
          </div>
        </b-form>
        <b-form
          key="form-search"
          v-else
          v-on:submit.prevent="submitSearch"
          v-on:reset.prevent="clearFilters"
        >
          <b-button
            variant="outline-secondary"
            @click="startGeolocal"
            class="mb-2 col-6"
            block
            ><b> Cerca utilizzando la mia posizione attuale </b>
            <b-icon icon="geo-alt-fill"></b-icon>
          </b-button>
          <p class="text-left text-secondary">
            Compila uno o più campi e premi su "cerca" per visualizzare i
            risultati della ricerca
          </p>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group
                label="Regione*"
                label-for="regione"
                class="text-left"
              >
                <b-form-select
                  v-capitalize-first-select
                  class="my-3 text-capitalize"
                  :options="regioni"
                  name="regione"
                  v-model="selected.regione"
                  @change="getProvince(selected)"
                  required
                >
                  <b-form-select-option :value="null"
                    >Seleziona Regione</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group
                label="Provincia*"
                label-for="provincia"
                class="text-left"
              >
                <b-form-select
                  v-capitalize-first-select
                  class="my-3 text-capitalize"
                  :options="province"
                  name="provincia"
                  v-model="selected.provincia"
                  @change="getComuni(selected)"
                  required
                >
                  <b-form-select-option :value="null"
                    >Seleziona Provincia</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group
                label="Comune*"
                label-for="comune"
                class="text-left"
              >
                <b-form-select
                  v-capitalize-first-select
                  class="my-3 text-capitalize"
                  name="comune"
                  v-model="selected.comune"
                  :key="
                    comuni && comuni.length
                      ? comuni[0].comune + comuni.length
                      : null
                  "
                  required
                >
                  <b-form-select-option
                    v-for="(com, ind) in comuni"
                    :key="ind"
                    :value="com"
                    @change="setData"
                    >{{ com.comune }}</b-form-select-option
                  >
                  <b-form-select-option :value="null"
                    >Seleziona Comune</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Denominazione"
                label-for="denominazione"
                class="text-left"
              >
                <b-form-input
                  class="my-3"
                  v-capitalize-first
                  name="denominazione"
                  type="text"
                  placeholder="Denominazione..."
                  v-model="filters.denominazione"
                  minlength="5"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <div>
            <p>*parametri obbligatori</p>
            <p
              v-if="results && results.length == 0"
              class="text-danger text-center"
            >
              Nessun risultato con questi parametri di ricerca
            </p>
            <div class="col-12 my-2 text-center">
              <!-- <b-button variant="outline-secondary" @click="filters.subtype = null">
                Cambia area
              </b-button> -->
              <b-button type="reset" variant="outline-secondary fw-bolder m-2"
                >Pulisci campi</b-button
              >
              <b-button
                :disabled="
                  selected.regione == null ||
                  selected.provincia == null ||
                  selected.comune == null
                "
                type="submit"
                variant="secondary text-white fw-bolder m-2"
                >Cerca</b-button
              >
            </div>
          </div>
        </b-form>
      </b-tab>
      <b-tab :active="tabIndex == 2" title-link-class="d-none">
        <div v-if="hasResult">
          <div class="col-12 pb-3">
            <span
              class="fw-bolder text-secondary point"
              @click="cancelSelection"
            >
              <!-- <b-icon icon="arrow-left-circle-fill" variant="secondary" class="mr-2"></b-icon> -->
              <span v-if="formSearch">
                <b-button
                  variant="secondary"
                  @click="formSearch = true"
                  class="mb-2 col-lg-3 col-md-4 col-sm-6"
                >
                  <b>Torna indietro</b>
                </b-button></span
              >
              <span v-else>Aumenta il raggio di ricerca</span>
            </span>
          </div>
          <div v-if="results && results.length > 0" class="col-12 px-0">
            <p
              class="text-left text-secondary"
              v-if="$route.name == 'CittadinoHome'"
            >
              Clicca sulla sede che vuoi impostare come nuova preferita e
              conferma la scelta premendo su "Conferma Scelta"
            </p>
            <p class="text-left text-secondary" v-else>
              Clicca sulla sede di tua preferenza e conferma la scelta premendo
              su "Conferma Scelta"
            </p>
            <b-row align-v="stretch">
              <div
                v-for="(company, ind) in results"
                :key="ind"
                class="mr-4 mb-4"
              >
                <CompanyCard
                  class="h-100"
                  :show-heart="true"
                  :azienda="company"
                  :is-selected="enlight(company)"
                  v-on:company-selection="selezionaCompany($event)"
                />
              </div>
            </b-row>
            <div v-if="selectedCompany" class="col-12">
              <b-form-group
                v-if="$route.name !== 'CittadinoHome'"
                label=""
                label-for="preferito"
                class="text-left text-font-grey mt-3 mx-auto col"
              >
                <b-form-checkbox
                  v-model="form.preferito"
                  name="preferito"
                  :value="true"
                  :unchecked-value="false"
                >
                  Imposta come Preferito
                </b-form-checkbox>
              </b-form-group>
              <div class="col-12 text-center mt-4 pt-2">
                <b-button
                  @click="cancelSelection"
                  type="button"
                  variant="popup-button m-2"
                  >Annulla
                </b-button>
                <b-button
                  @click="confirmSelection"
                  type="button"
                  variant="popup-button m-2"
                  >Conferma Scelta</b-button
                >
              </div>
            </div>
          </div>
          <div v-else-if="results && results.length == 0" class="col-12">
            <p class="text-left">Nessun risultato per questa ricerca</p>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { supportService, utilityService } from "@/_services"
import { mapState, mapActions, mapGetters } from "vuex"
import CompanyCard from "@/components/prenotazioni/CompanyCard.vue"
export default {
  name: "SearchCompany",
  components: { CompanyCard },
  props: ["onlySearchFor"],
  data() {
    return {
      filters: {
        comune: null,
        provincia: null,
        regione: null,
        frazione: null,
        cap: null,
        denominazione: null,
        surname: null,
        subtype: null
      },
      selected: {
        comune: null,
        provincia: null,
        regione: null
      },
      results: null,
      hasResult: false,
      selectedCompany: null,
      form: { preferito: true, company: null },
      formSearch: true,
      userCoordinates: null,
      searchFilters: {
        lat: null,
        long: null,
        radius: 100,
        subtype: null
      },
      spinSearch: false,
      subtypes: null,
      tabIndex: 0,
      selectedAreaGroup: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    ...mapState("utente", ["status", "user"]),
    ...mapGetters({
      getCompany: "company/getCompany"
    })
  },
  watch: {
    selected: {
      handler(val) {
        this.setData()
      },
      deep: true
    },
    selectedCompany: function (val) {
      if (val) {
        this.form.company = val.hashid
      }
    },
    filters: {
      handler(val) {
        // if (val.subtype !== null) {
        //   this.tabIndex = 1;
        // } else
        if (val.subtype === null) {
          this.tabIndex = 0
          this.results = null
        } else {
          this.tabIndex = 1
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    isSelected: function (item) {
      if (this.selectedAreaGroup) {
        return this.selectedAreaGroup[0].hashid === item.hashid
      }
    },
    setData() {
      if (this.selected.comune) {
        this.filters.comune = this.selected.comune.comune
      }
      this.filters.regione = this.selected.regione
      this.filters.provincia = this.selected.provincia
    },
    submitSearch() {
      this.tabIndex = 2
      if (
        this.selected.regione !== null &&
        this.selected.provincia !== null &&
        this.selected.comune !== null
      ) {
        // this.filters.subtype = undefined;
        const self = this
        supportService
          .searchCompanyBySubtype(
            this.onlySearchFor
              ? {
                  ...this.filters,
                  "tipo-servizio": this.onlySearchFor
                }
              : this.filters
          )
          .then(function (response) {
            self.results = response.data.data
            // console.log("search len", response.data.data.length)
            if (response.data.data.length > 0) {
              self.hasResult = true
            } else {
              self.hasResult = false
              self.tabIndex = 1
            }
          })
          .catch(function (error) {
            // console.log(error);
          })
      }
    },
    clearFilters() {
      this.filters.comune = null
      this.filters.provincia = null
      this.filters.regione = null
      this.filters.frazione = null
      this.filters.cap = null
      this.filters.denominazione = null
      this.filters.surname = null
      this.results = null
      this.selected.regione = null
      this.selected.provincia = null
      this.selected.comune = null
      this.hasResult = false
      // if (doRefresh) {
      //   this.submitSearch();
      // }
    },
    enlight(comp) {
      return this.selectedCompany && comp.hashid === this.selectedCompany.hashid
        ? true
        : false
    },
    selezionaCompany(azienda) {
      // console.log(azienda);
      this.selectedCompany = azienda
    },
    confirmSelection() {
      // var form = {
      //   company: this.selectedCompany.hashid,
      //   preferito: this.preferito,
      // };
      var self = this
      supportService
        .updateRelationshipWithCompany(this.form)
        .then(function (response) {
          self.$emit("new-selection", self.selectedCompany)
          self.clearFilters()
          window.scrollTo(0, 0)
          self.successAlert("preferenze aggiornate correttamente!")
        })
        .catch(function (error) {
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile aggiornare le preferenze")
        })
    },
    cancelSelection() {
      this.clearFilters()
      if (this.tabIndex == 2) {
        this.tabIndex = 1
      }
    },
    startGeolocal() {
      // this.formSearch = false;
      const self = this
      this.$getLocation()
        .then((coordinates) => {
          // console.log(coordinates);
          self.userCoordinates = coordinates
          self.formSearch = false
          self.searchFilters.lat = coordinates.lat
          self.searchFilters.long = coordinates.lng
        })
        .then(function () {
          self.doGeolocalSearch()
        })
        .catch((err) => {
          // console.log("err", err);
          self.formSearch = false
        })
    },
    doGeolocalSearch() {
      this.spinSearch = true
      this.searchFilters.subtype = this.filters.subtype
      // console.log(this.searchFilters);
      var self = this
      supportService
        .searchCompanyBySubtype(this.searchFilters)
        .then(function (response) {
          self.results = response.data.data
          if (response.data.data.length > 0) {
            self.hasResult = true
            self.tabIndex = 2
          } else {
            self.hasResult = false
          }
          self.spinSearch = false
        })
        .catch(function (error) {
          // console.log(error);
          self.spinSearch = false
        })
    },
    getSubtypes() {
      var self = this
      utilityService
        .getSubtypes()
        .then(function (response) {
          self.subtypes = response.data.data
          // console.log(self.subtypes)
        })
        .catch(function (error) {
          // console.log(error);
        })
    }
  },
  created() {
    // if (!this.regioni) {
    this.getRegioni()
    this.getSubtypes()
    // }
  },
  mounted() {
    // console.log(this.user.anagrafica);
    if (this.user.type === 0) {
      this.selected.regione = this.user.anagrafica.regione
      var self = this
      this.getProvince(this.selected).then(function () {
        self.selected.provincia = self.user.anagrafica.provincia
        self.getComuni(self.selected)
        // var innerSelf = self;
        // self.getComuni(self.selected).then(function () {
        //   //
        //   innerSelf.selected.comune = innerSelf.user.anagrafica.comune;
        // });
      })
    }
  }
}
</script>
